import { observable, action, reaction, makeObservable } from 'mobx';

// import getBase64 from '~/utils/getBase64';
import { findIndex } from 'lodash';

import agent from '../agent';

import userStore from './userStore';

let socket = null;
let connectionPollInterval = null;

class ChatStore {
    socket = null;
    allRooms = null;
    currentRoomId = 0;
    chatCurrentTab = 1;
    currentRooms = {};
    currentEntity = {};
    currentPrivateRoom = {};
    currentPrivateAllRooms = null;
    currentPrivateRooms = {};
    currentPrivateRoomId = null;
    unreads = {};
    totalUnreads = 0;
    privateUnreads = {};
    totalPrivateUnreads = 0;
    loading = {
        rooms: false,
        room: false,
        messages: false,
        privaterooms: false
    };
    roomMembers = {};
    roomMessages = {};

    constructor() {
        makeObservable(this, {
            socket: observable,
            allRooms: observable,
            currentRoomId: observable,
            chatCurrentTab: observable,
            currentRooms: observable,
            currentEntity: observable,
            currentPrivateRoom: observable,
            currentPrivateAllRooms: observable,
            currentPrivateRooms: observable,
            currentPrivateRoomId: observable,
            unreads: observable,
            totalUnreads: observable,
            privateUnreads: observable,
            totalPrivateUnreads: observable,
            loading: observable,
            roomMembers: observable,
            roomMessages: observable,
            setCurrentTab: action,
            setupIo: action,
            recalcUnreads: action,
            recalcPrivateUnreads: action,
            sendMessage: action,
            sendPrivateMessage: action,
            sendDeletedEvent: action,
            setCurrentRoomId: action,
            leaveRoom: action,
            loadRooms: action,
            loadPrivateRooms: action,
            loadRoom: action,
            loadPrivateRoom: action,
            loadMessages: action,
            save: action,
            remove: action,
            createPrivateRoom: action,
            removePrivateRoomData: action,
            setCurrentPrivateRoom: action,
            setCurrentPrivateRoomId: action,
            leavePrivateRoom: action
        });
    }

    setCurrentTab(tabid) {
        this.chatCurrentTab = tabid;
    }

    setupIo(_socket) {
        socket = _socket;
        if (userStore.currentUser) socket.emit('ehlo', { user_id: userStore.currentUser.id, client_id: userStore.currentUser.client_id });

        reaction(
            () => userStore.currentUser,
            currentUser => {
                if (currentUser) {
                    socket.emit('ehlo', { user_id: currentUser.id, client_id: currentUser.client_id });
                }
            }
        );

        socket.removeAllListeners('chat-got-message');
        // socket.removeAllListeners('chat-got-private-message');
        if (userStore.currentUser) {
            socket.on('chat-got-message', data => {

                if (this.currentRoomId === data.room_id) {
                    const sameMessage = findIndex(this.currentRooms[this.currentRoomId].lastMessages, r => {
                        return r.id === data.id;
                    });

                    if (sameMessage < 0) {
                        this.currentRooms[this.currentRoomId].lastMessages.unshift({
                            id: data.id,
                            author: data.author_id,
                            author_id: data.author_id,
                            message: data.message,
                            image_id: data.data && data.data.image_id ? data.data.image_id : 0,
                            timestamp: new Date(),
                        });
                    }
                } else {
                    // recalculate unreads
                    this.recalcUnreads();
                }
            });

            socket.on('chat-got-private-message', data => {
                if (this.currentPrivateRoomId === data.room_id) {
                    const sameMessage = findIndex(this.currentPrivateRooms[this.currentPrivateRoomId].lastMessages, r => {
                        return r.id === data.id;
                    });

                    if (sameMessage < 0) {
                        this.currentPrivateRooms[this.currentPrivateRoomId].lastMessages.unshift({
                            id: data.id,
                            author: data.author_id,
                            author_id: data.author_id,
                            message: data.message,
                            image_id: data.data && data.data.image_id ? data.data.image_id : 0,
                            timestamp: new Date(),
                        });
                    }
                    // this.loadPrivateRooms()
                } else {
                    // recalculate unreads
                    this.recalcPrivateUnreads();
                }
            });

            socket.on('privatchat-room-deleted', data => {
                this.recalcPrivateUnreads();
            })

            socket.on('USERS_UPDATED', data => {
                userStore.clearLookupValues();
            });
        }
    }

    recalcUnreads() {
        return agent.Chat.loadRooms()
            .then(
                action(list => {
                    let total = 0;
                    Object.keys(this.unreads).forEach(key => {
                        delete this.unreads[key];
                    });
                    Object.keys(list.unreads).forEach(key => {
                        this.unreads[key] = list.unreads[key];
                        total += parseInt(list.unreads[key]);
                    });
                    //        this.unreads = list.unreads;
                    this.totalUnreads = total;
                })
            )
            .catch(
                action(err => {
                    throw err;
                })
            );
    }

    recalcPrivateUnreads() {
        return agent.Chat.loadPrivateRooms()
            .then(
                action(list => {

                    let total = 0;
                    Object.keys(this.privateUnreads).forEach(key => {
                        delete this.privateUnreads[key];
                    });
                    Object.keys(list.privateUnreads).forEach(key => {
                        this.privateUnreads[key] = list.privateUnreads[key];
                        total += parseInt(list.privateUnreads[key]);
                    });
                    this.totalPrivateUnreads = total;
                    this.currentPrivateAllRooms = list.rooms;
                })
            )
            .catch(
                action(err => {
                    throw err;
                })
            );
    }

    sendMessage({ author, room, message, data, author_data }) {
        if (!socket) {
            throw new Error('Not connected right now, please try to reload the page');
        }

        if (!message && data.image_id === 0) return;

        socket.emit('chat-new-message', {
            author,
            room,
            message,
            client_id: this.currentRooms[this.currentRoomId].room.client_id,
            data,
            author_data
        });
    }

    sendPrivateMessage({ author, room, message, data, author_data }) {
        if (!socket) {
            throw new Error('Not connected right now, please try to reload the page');
        }
        if (!message && data.image_id === 0) return;

        socket.emit('chat-new-private-message', {
            author,
            room,
            message,
            //client_id: this.currentPrivateRooms[this.currentPrivateRoomId].room.client_id,
            client_id: userStore.currentUser.client_id,
            data,
            author_data
        });

    }

    sendDeletedEvent() {
        if (!socket) {
            throw new Error('Not connected right now, please try to reload the page');
        }
        socket.emit('privatchat-room-deleted');
    }


    setCurrentRoomId(id) {
        this.currentRoomId = id;
        if (!id) this.leaveRoom();
    }

    leaveRoom() {
        if (connectionPollInterval) clearInterval(connectionPollInterval);
    }

    loadRooms() {
        this.loading.rooms = true;
        return agent.Chat.loadRooms()
            .then(
                action(list => {
                    this.allRooms = list.rooms;
                    this.unreads = list.unreads;
                    let total = 0;
                    Object.keys(this.unreads).forEach(key => {
                        total += parseInt(this.unreads[key]);
                    });
                    this.totalUnreads = total;
                    this.loading.rooms = false;
                    return list;
                })
            )
            .catch(
                action(err => {
                    this.loading.rooms = false;
                    throw err;
                })
            );
    }

    loadPrivateRooms() {
        this.loading.privaterooms = true;
        return agent.Chat.loadPrivateRooms()
            .then(
                action(data => {
                    // this.currentEntity = user;
                    this.currentPrivateAllRooms = data.rooms
                    this.privateUnreads = data.privateUnreads;
                    let total = 0;
                    Object.keys(this.privateUnreads).forEach(key => {
                        total += parseInt(this.privateUnreads[key]);
                    });
                    this.totalPrivateUnreads = total;
                    this.loading.privaterooms = false;
                    return data.rooms;
                })
            )
            .catch(
                action(err => {
                    this.updating = false;
                    this.updatingErrors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            );
    }

    loadRoom(id) {
        this.loading.room = true;
        return agent.Chat.loadRoom(id)
            .then(
                action(response => {
                    this.currentRooms[parseInt(id, 10)] = response;
                    this.currentEntity = response;
                    if (this.unreads) this.unreads[id] = 0;
                    this.leaveRoom();
                    if (socket) {
                        socket.emit('chat-still-active', {
                            room: this.currentRoomId,
                            user_id: userStore.currentUser.id,
                        });
                    }
                    let total = 0;
                    Object.keys(this.unreads).forEach(key => {
                        total += parseInt(this.unreads[key]);
                    });
                    //        this.unreads = list.unreads;
                    this.totalUnreads = total;
                    connectionPollInterval = setInterval(() => {
                        if (socket && userStore.currentUser) {
                            socket.emit('chat-still-active', {
                                room: this.currentRoomId,
                                user_id: userStore.currentUser.id,
                            });
                        }
                    }, 5000);
                    this.loading.room = false;
                    return response;
                })
            )
            .catch(
                action(err => {
                    this.loading.room = false;
                    throw err;
                })
            );
    }

    // load private room by room id//
    loadPrivateRoom(id) {
        this.loading.privaterooms = true;
        return agent.Chat.loadPrivateRoom(id)
            .then(
                action(response => {
                    this.currentPrivateRooms[parseInt(id, 10)] = response;
                    this.currentPrivateRoom = response.room
                    // this.currentEntity = response;
                    if (this.privateUnreads) this.privateUnreads[id] = 0;
                    this.leavePrivateRoom();
                    if (socket) {
                        socket.emit('chat-still-active', {
                            room: this.currentPrivateRoomId,
                            user_id: userStore.currentUser.id,
                        });
                    }
                    let total = 0;
                    Object.keys(this.privateUnreads).forEach(key => {
                        total += parseInt(this.privateUnreads[key]);
                    });
                    //        this.unreads = list.unreads;
                    this.totalPrivateUnreads = total;
                    connectionPollInterval = setInterval(() => {
                        if (socket && userStore.currentUser) {
                            socket.emit('chat-still-active', {
                                room: this.currentPrivateRoomId,
                                user_id: userStore.currentUser.id,
                            });
                        }
                    }, 5000);
                    this.loading.privaterooms = false;
                    return response;
                })
            )
            .catch(
                action(err => {
                    this.loading.room = false;
                    throw err;
                })
            );
    }

    loadMessages(id) {
        this.loading.messages = true;
        return agent.Chat.loadMessages(id)
            .then(
                action(response => {
                    this.roomMessages[id] = response;
                    this.loading.messages = false;
                    return response;
                })
            )
            .catch(
                action(err => {
                    this.loading.messages = false;
                    throw err;
                })
            );
    }

    save(values, isAdd) {
        this.updating = true;
        return agent.Chat.saveRoom(values, isAdd)
            .then(
                action(user => {
                    this.currentEntity = user;
                    return user;
                })
            )
            .catch(
                action(err => {
                    this.updating = false;
                    this.updatingErrors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            );
    }

    async remove(id) {
        await agent.Chat.remove(id);
        this.deleteSuccess = true;
        return 1;
    }

    createPrivateRoom(data) {
        this.updating = true;
        return agent.Chat.createPrivateRoom(data)
            .then(
                action(room => {
                    // this.currentEntity = user;
                    if (room) {

                        this.currentPrivateRoom = room.private_room_detail
                        this.loadPrivateRooms();
                        this.currentPrivateRoomId = room.private_room_detail.id
                        return room.private_room_detail
                    }
                })
            )
            .catch(
                action(err => {
                    this.updating = false;
                    this.updatingErrors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            );
    }

    async removePrivateRoomData(id) {
        await agent.Chat.removePrivateRoom(id).then(
            action((response) => {
                return response;
            })
        ).catch(
            action((error) => {
                throw error;
            })
        )
    }


    setCurrentPrivateRoom(data) {
        // this.currentPrivateRoomId = data.id;
        this.currentPrivateRoom = data;
    }

    setCurrentPrivateRoomId(id) {
        this.currentPrivateRoomId = id;
        if (!id) this.leavePrivateRoom();
    }


    leavePrivateRoom() {
        if (connectionPollInterval) clearInterval(connectionPollInterval);
    }
}

const _ChatStore = new ChatStore();
export default _ChatStore;
