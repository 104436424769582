import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import CustomRadio from '../../CustomRadio/CustomRadio';
import withLocalization from '../../../hoc/withLocalization';

const RadioWidgetInternal = inject('projectStore', 'userStore', 'commonStore')(withLocalization(observer(class RadioWidgetInternal extends Component {
    render() {
        const { options, value, onChange, t, vertical, schema, disabled } = this.props;
        const { enumOptions } = options;
        const __disabled = schema?.readOnly !== undefined ? !!schema.readOnly : !!disabled;
        return (
            <div className={`radio-widget ${vertical ? 'vertical' : ''}`}>
                {!!enumOptions
                    && enumOptions.map(radio => (
                        <CustomRadio
                            key={radio.value}
                            onChange={() => onChange(radio.value)}
                            checked={value === radio.value}
                            option={radio.value}
                            label={t(radio.label)}
                            disabled={__disabled}
                        />
                    ))}
            </div>
        );
    }
}
)));

export const RadioWidget = observer(class RadioWidget extends Component {
    render() {
        return <RadioWidgetInternal {...this.props} />;
    }
})
