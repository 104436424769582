import Card from 'react-bootstrap/Card';
import './customCard.css';

function RectangularCard(props) {
    const controlledClasses = `${props.backgoundClass? props.backgoundClass: 'rectangle-card-bg-white'} ${props.isFullImage ? 'border-789BD0' : ''}`;
    const classes = props.className?props.className:"";
    const style = props.style? props.style:{};
    return (
        <Card
            className={`card-block rectangle-card ${controlledClasses} ${classes}`}
            style={style}
        >
            {
                props.isFullImage ? (
                    <>
                        {props.children}
                    </>
                ) : (
                    <Card.Body>
                        {props.children}
                    </Card.Body>
                )
            }

        </Card>
    );
}

export default RectangularCard;