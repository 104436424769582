import { observable, action, makeObservable } from 'mobx';
import moment from 'moment/min/moment-with-locales';
import _ from 'lodash';
import agent from '../agent';


let addressThrottleTimer = null;

class ClientStore {
    constructor() {
        makeObservable(this, {
            currentList: observable,
            currentEntity: observable,
            availableActiveTaskInClient: observable,
            updatingErrors: observable,
            updating: observable,
            loading: observable,
            requestParams: observable,
            deleteSuccess: observable,
            lastListLoadTime: observable,
            filters: observable,
            appliedFilters: observable,
            setLoading: action,
            setFilter: action,
            onFilter: action,
            resetLastListLoadTime: action,
            loadList: action,
            returnDefaultNew: action,
            load: action,
            save: action,
            setDefaultDataForTip: action,
            getMultipleAttachments: action,
            addAttachments: action,
            renewClient: action,
            remove: action,
            getmultipleAccountsForClient: action,
            checkIfGroupInUse: action,
            resetClientData: action,
            performRecalcGpsForClient: action,
            recalcGpsForClient: action,
            getActiveTaskWithMembersForExtraPayments: action
        });
    }

    currentList = [];
    currentEntity = {};
    availableActiveTaskInClient = [];
    updatingErrors = null;
    updating = false;
    loading = false;
    requestParams = {};
    deleteSuccess = false;
    lastListLoadTime = null;
    filters = {
        biztype: '',
        name: '',
        status: 'active'
    };
    appliedFilters = {
        biztype: '',
        name: '',
        status: 'active'
    };

    setLoading(value) {
        this.loading = value;
    }

    setFilter(name, value) {
        const filters = Object.assign({}, this.filters);
        filters[name] = value;
        this.filters = filters;
    }

    onFilter() {
        this.appliedFilters = Object.assign({}, this.filters);
    }

    resetLastListLoadTime() {
        this.lastListLoadTime = new Date();
    }

    resetClientData(data) {
        this.currentEntity.client.data = data
    }

    loadList(params) {
        this.loading = true;
        return agent.Clients.list(params)
            .then(
                action(list => {
                    this.requestParams = params;
                    list.time = new Date();
                    this.currentList = list;
                    this.lastListLoadTime = list.time;
                    this.loading = false;
                    return list;
                })
            )
            .catch(
                action(err => {
                    this.loading = false;
                    throw err;
                })
            );
    }

    returnDefaultNew(params) {
        this.currentEntity = {
            client: {
                User: { data: {} },
                data: {},
                max_users: 1000,
                renew_manual: true,
                biztype: 1,
                renew_date: moment(new Date()).add(1, 'years').toISOString(),
                ...params,
            },
        };
        this.loading = false;

        return this.currentEntity;
    }

    load(id) {
        this.loading = true;
        return agent.Clients.load(id)
            .then(
                action(response => {
                    if (!response.client.User) response.client.User = {};
                    if (!response.client.data) response.client.data = {};
                    if (!response.client.User.data) response.client.User.data = {};
                    this.currentEntity = response;
                    this.setDefaultDataForTip();
                    this.loading = false;
                    return response;
                })
            )
            .catch(
                action(err => {
                    this.loading = false;
                    throw err;
                })
            );
    }

    save(values, isAdd) {
        this.updating = true;
        return agent.Clients.save(values, isAdd)
            .then(
                action(user => {
                    this.currentEntity = user;
                    this.setDefaultDataForTip()
                    return user;
                })
            )
            .catch(
                action(err => {
                    this.updating = false;
                    this.updatingErrors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            );
    }

    setDefaultDataForTip() {
        if (!this.currentEntity.client.data.tipRules && this.currentEntity.client.allow_tip) {
            this.currentEntity.client.data.tipRules = {
                tipEditor: "pm",
                tipAllocation: "selected",
                profiles: [
                    {
                        employee_type: "waiter",
                        percentage: 70
                    },
                    {
                        employee_type: "cook",
                        percentage: 30
                    }
                ]
            }
        }
    }

    getMultipleAttachments(data) {
        return agent.Clients.getMultipleAttachments(data)
            .then(
                action(response => {
                    return response;
                })
            )
            .catch(
                action(err => {
                    throw err;
                })
            );
    }

    addAttachments(data) {
        return agent.Clients.addAttachments(data)
            .then(
                action((response) => {
                    return response;
                })
            )
            .catch(
                action(err => {
                    throw err;
                })
            );
    }

    renewClient(data) {
        return agent.Clients.renewClient(data)
            .then(
                action((response) => {
                    return response;
                })
            )
            .catch(
                action(err => {
                    throw err;
                })
            );
    }

    getTripleTexToken(data) {
        return agent.Clients.getTripleTexToken(this.currentEntity.client.id, data)
            .then(
                action((response) => {
                    return response;
                })
            )
            .catch(
                action(err => {
                    throw err;
                })
            );
    }

    getmultipleAccountsForClient(id) {
        return agent.Clients.getmultipleAccountsForClient(id)
            .then(
                action((response) => {
                    return response;
                })
            )
            .catch(
                action(err => {
                    throw err;
                })
            );
    }

    saveVismaDetails(data) {
        return agent.Clients.saveVismaDetails(data)
            .then(
                action((response) => {
                    return response;
                })
            )
            .catch(
                action(err => {
                    throw err;
                })
            );
    }

    checkIfGroupInUse(code) {
        return agent.UserGroups.checkIfGroupInUse(code)
            .then(
                action((response) => {
                    return response;
                })
            )
            .catch(
                action(err => {
                    throw err;
                })
            );
    }


    async remove(id) {
        await agent.Clients.remove(id);
        this.lastListLoadTime = new Date();
        this.deleteSuccess = true;
        return 1;
    }

    async performRecalcGpsForClient(newAddress) {
        this.gpsNowLoading = true;
        this.currentEntity.client.data.addressCache = newAddress;
        const tsk = _.cloneDeep(this.currentEntity.client);
        try {
            const data = await agent.Tasks.gpsAddress(newAddress, null, false);
            tsk.gps_data = data.result;
            tsk.data.addressCache = newAddress;
            this.currentEntity = { client: tsk };
            this.gpsNowLoading = false;
        } catch (e) {
            this.gpsNowLoading = false;
            throw e;
        }
    }

    async recalcGpsForClient(changes) {
        const entityToAddressString = e => {
            return `${e.address}, ${e.post_place}, ${e.post_number}`;
        };

        if (!changes.address || !changes.post_place) {
            return changes;
        }

        const newAddress = entityToAddressString(changes);

        if (newAddress === this.currentEntity.client.data.addressCache) return changes;

        addressThrottleTimer = new Date().getTime();
        setTimeout(() => {
            // throttle
            if (new Date().getTime() - addressThrottleTimer < 1000) {
                return;
            }
            if (this.currentEntity.client.data.addressCache === newAddress) {
                return;
            }
            this.performRecalcGpsForClient(newAddress);
        }, 1000);
        return changes;
    }

    getActiveTaskWithMembersForExtraPayments() {
        return agent.Clients.getActiveTaskWithMembersForExtraPayments()
            .then(
                action(response => {
                    this.availableActiveTaskInClient = response.tasks;
                    return response;
                })
            )
            .catch(
                action(err => {
                    throw err;
                })
            );
    }
}

const _ClientStore = new ClientStore();
export default _ClientStore;
