import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment/min/moment-with-locales'; // 'moment/min/moment-with-locales';
import { Row, Col, Tooltip, OverlayTrigger } from 'react-bootstrap';

//components
import applicationRouter from '~/hoc/applicationRouter';
import withLocalization from '~/hoc/withLocalization';

//elements
import LoadingSpinner from '~/elements/LoadingSpinner';

//utils
import { statusMap } from '~/utils/normalStatuses';
import { shortDateFormat } from '~/utils/dateFormat';

class AbsencesTotals extends Component {
    constructor(props) {
        super(props)
        this.state = {
            for_date: props.for_date ? moment(props.for_date) : moment(),
            user_id: props.user_id ? props.user_id : null,
            absenceStatus: props.absenceStatus ? props.absenceStatus : null,
            data: null,
            isLoading: true
        }
    }

    async loadData(new_user_id = null) {
        try {
            const { user_id, for_date } = this.state;
            const userId = new_user_id ? new_user_id : user_id;
            const params = {
                user_id: userId,
                from: moment(for_date).format('YYYY-MM-DD')
            }
            const result = await this.props.absenceStore.getAbsenceTotals(params)
            this.setState({ user_id: userId, data: result, isLoading: false }, () => {
                if (this.props.forceReloadForm) this.props.forceReloadForm();
            });
        } catch (err) {
            console.error(err);
            this.props.commonStore.addNotification(this.props.t(err), null, 'error');
        }
    }

    componentDidMount() {
        this.loadData();
    }

    render() {
        const { t, commonStore } = this.props;
        const { isLoading, for_date, absenceStatus, data } = this.state;

        if (isLoading) return <LoadingSpinner />;

        const dateTimeRules = commonStore.config.client && commonStore.config.client.data
            && commonStore.config.client.data.dateTimeRules
            ? commonStore.config.client.data.dateTimeRules : false;

        const { selfClearances, childDays, childDaysUsed, cycleEndDate, cycleStartDate, employee_percent, firstLeaveInCycle } = data;
        const _nextSickLeaveCycleStartDate = moment(firstLeaveInCycle).add(1, 'year').add(1, 'day').format(dateTimeRules.short_date_format);
        const showParentalLeaves = false;

        let statusOffest = 8 + (showParentalLeaves || cycleEndDate ? -6 : 0);
        let statusOffestSM = 9 + (showParentalLeaves ? -4 : 0) + (cycleEndDate ? -4 : 0);
        let statusOffestMD = 9 + (showParentalLeaves && cycleEndDate ? -3 : 0);
        let statusOffestLG = 4 + (showParentalLeaves ? -2 : 0) + (cycleEndDate ? -2 : 0);

        return (
            <Row className="mb-4 align-items-center">
                <Col xs={6} sm={4} md={3} lg={2} className={'mt-2'}>
                    <div className="vacation-panel__item vacation-panel__divider" >
                        <span className="vacation-panel__value">{for_date.year()}</span>
                        <span title={t('Year')} className="vacation-panel__label">{t('Year')}</span>
                    </div>
                </Col>
                {/* {cycleEndDate &&
                    <Col xs={6} sm={4} md={3} lg={2} className={'mt-2'}>
                        <div className="vacation-panel__item vacation-panel__divider" >
                            <span className="vacation-panel__value_small">{shortDateFormat(cycleStartDate, dateTimeRules)}</span>
                            <span className="vacation-panel__value_small">{shortDateFormat(cycleEndDate, dateTimeRules)}</span>
                            <span title={t('Year')} className="vacation-panel__label">{t('Sick Leave Cycle')}</span>
                        </div>
                    </Col>
                } */}
                <Col xs={6} sm={4} md={3} lg={2} className={'mt-2'}>
                    <div className="vacation-panel__item">
                        <span className="vacation-panel__value">{employee_percent}%</span>
                        <span title={t('Employment')} className="vacation-panel__label">{t('Employment')}</span>
                    </div>
                </Col>
                {/* <Col xs={6} sm={4} md={3} lg={2} className={'mt-2'}>
                    <div className="vacation-panel__item">
                        <span className="vacation-panel__value">
                            {selfClearances}&nbsp;
                            {t('of')}&nbsp;4
                            {parseInt(selfClearances) === 4 &&
                                <OverlayTrigger
                                    key="top"
                                    placement="top"
                                    overlay={
                                        <Tooltip id="tooltip-top">
                                            {t("You have used up all the allowed self-certifications. A new self-certification will be available from")}:&nbsp;
                                            {_nextSickLeaveCycleStartDate}
                                        </Tooltip>
                                    }
                                >
                                    <i className="fa fa-exclamation-circle text-danger" aria-hidden="true"></i>
                                </OverlayTrigger>
                            }
                        </span>
                        <span title={t('Self clearances')} className="vacation-panel__label vacation-panel__gradient_underline">{t('Self clearances')}</span>
                    </div>
                </Col> */}
                {/* {showParentalLeaves &&
                    <Col xs={6} sm={4} md={3} lg={2} className={'mt-2'}>
                        <div className="vacation-panel__item">
                            <span className="vacation-panel__value">
                                {childDaysUsed} {t('of')} {childDays}
                            </span>
                            <span title={t('Child sickness')} className="vacation-panel__label vacation-panel__gradient_underline">{t('Child sickness')}</span>
                        </div>
                    </Col>
                } */}
                {!!absenceStatus &&
                    <Col
                        xs={{ span: 4, offset: statusOffest }}
                        sm={{ span: 3, offset: statusOffestSM }}
                        md={{ span: 3, offset: statusOffestMD }}
                        lg={{ span: 2, offset: statusOffestLG }}
                        className={'mt-4'}>
                        <Row className='justify-content-start justify-content-md-around'>
                            <div className={`status-block status-block__fixed-width status-${absenceStatus}`}>
                                {t(statusMap[absenceStatus])}
                            </div>
                        </Row>
                    </Col>
                }
            </Row>
        );
    }
}

export default inject('absenceStore', 'commonStore')(applicationRouter(withLocalization(observer(AbsencesTotals))));
