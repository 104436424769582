import { observable, action, makeObservable } from 'mobx';
import agent from '../agent';

//all API calls are defined in agent.js under visitors for visitors//
//file contains all the observable values and actions to handle visitors//
//import this file in index.js and add it to stores list before using//

const currentVisitorInit = {
    //default values to initiate visitor//
    id: 0,
    full_name: '',
    phone: '',
    visitor_business_name: '',
};

const currentClientVisitorInit = {
    //default values to initiate visitor client//
    id: 0,
    business_name: '',
    business_id: '',
    contact_person_name: '',
    contact_person_email: '',
    username: '',
    phone: '',
};

class visitorStore {
    constructor() {
        makeObservable(this, {
            currentList: observable,
            currentVisitor: observable,
            lastListLoadTime: observable,
            loading: observable,
            filters: observable,
            appliedFilters: observable,
            setFilter: action,
            onFilter: action,
            resetLastListLoadTime: action,
            loadVisitorById: action,
            loadDefaultVisitor: action,
            handleVisitorChange: action,
            getVisitors: action,
            getVisitorsReport: action,
            getVisitorById: action,
            saveVisitorData: action,
            deleteVisitorById: action,
            currentClientVisitor: observable,
            loadDefaultClientVisitor: action,
            saveClientVisitorData: action,
            verifyClientVisitorData: action,
            handleClinetVisitorChange: action,
        });
    }

    //observable values for visitor//

    currentList = [];

    currentVisitor = currentVisitorInit;

    lastListLoadTime = null;

    loading = false;

    filters = {
        name: '',
        from_date: '',
        to_date: '',
        client_id: { value: '', label: 'All' },
    };

    appliedFilters = {
        name: '',
        from_date: '',
        to_date: '',
        client_id: { value: '', label: 'All' },
    };

    //actions for visitor//

    setFilter(name, value) {
        //handles entries in search column//
        //takes 2 arguments, type - string, name, value
        const filter = Object.assign({}, this.filters);
        filter[name] = value;
        this.filters = filter;
    }

    onFilter() {
        //sets appliedFilter to filter values from search column//
        this.appliedFilters = Object.assign({}, this.filters);
    }

    resetLastListLoadTime() {
        //resets the LoadTime to refresh list//
        this.lastListLoadTime = new Date();
    }

    loadVisitorById = async (id) => {
        //sets editable visitor values from list to visitorTable//
        //takes 1 argument, type - integer, id//
        if (id && id > 0) {
            await this.getVisitorById(id).then((data) => {
                this.currentVisitor.id = data.visitor.id;
                this.currentVisitor.full_name = data.visitor.full_name;
                this.currentVisitor.country_code = data.visitor.country_code;
                this.currentVisitor.phone = data.visitor.phone;
                this.currentVisitor.visitor_business_name = data.visitor.visitor_business_name;
                return true;
            });
        }
    };

    loadDefaultVisitor() {
        //initiates currentVisitor for new visitor//
        this.currentVisitor = currentVisitorInit;
    }

    handleVisitorChange = async (name, value) => {
        //handles changes made in the visitor and sets it to currentVisitor//
        //takes 2 arguments, type - string, name, value//
        this.currentVisitor[name] = value;
    };

    getVisitors(params) {
        //calls get method from visitor API//
        //takes 1 arguments, type - object, params//
        //returns visitor list//
        return agent.visitors
            .getVisitors(params)
            .then(
                action((list) => {
                    this.requestParams = params;
                    list.time = new Date();
                    this.currentList = list;
                    this.lastListLoadTime = list.time;
                    return list;
                })
            )
            .catch(
                action((error) => {
                    throw error;
                })
            );
    }

    getVisitorsReport(params) {
        //calls get method from visitor API//
        //takes 1 arguments, type - object, params//
        //returns visitor report//
        return agent.visitors
            .getVisitorsReport(params)
            .then(
                action((response) => {
                    return response;
                })
            )
            .catch(
                action((error) => {
                    throw error;
                })
            );
    }

    getVisitorById(id) {
        //calls get method from visitor API//
        //takes 1 argument, type - integer, id//
        //returns API response//
        return agent.visitors
            .getVisitorById(id)
            .then(
                action((response) => {
                    if (!response.visitor) response.visitor = {};
                    return response;
                })
            )
            .catch(
                action((error) => {
                    throw error;
                })
            );
    }

    saveVisitorData(data, type) {
        //calls post method from visitors API//
        //takes 1 arguments, type - object, visitor//
        //returns API response//
        if (type) {
            return agent.visitors
                .addVisitor(data)
                .then(
                    action((response) => {
                        return response;
                    })
                )
                .catch(
                    action((error) => {
                        throw error;
                    })
                );
        } else {
            return agent.visitors
                .addVisitorPublic(data)
                .then(
                    action((response) => {
                        return response;
                    })
                )
                .catch(
                    action((error) => {
                        throw error;
                    })
                );
        }
    }

    async deleteVisitorById(id) {
        //calls delete method from visitor API//
        //takes 1 argument, type - integer, id//
        let result = await agent.visitors.deleteVisitorById(id);
        return result;
    }

    //observable values for client_visitor//

    currentClientVisitor = currentClientVisitorInit;

    //actions for visitor//

    loadDefaultClientVisitor() {
        //initiates currentVisitor for new visitor//
        this.currentClientVisitor = currentClientVisitorInit;
    }

    saveClientVisitorData(data) {
        //calls post method from visitors API//
        //takes 1 arguments, type - object, visitor//
        //returns API response//
        return agent.visitors
            .addClientVisitorPublic(data)
            .then(
                action((response) => {
                    return response;
                })
            )
            .catch(
                action((error) => {
                    throw error;
                })
            );
    }

    verifyClientVisitorData(data) {
        //calls post method from visitors API//
        //takes 1 arguments, type - object, visitor//
        //returns API response//
        return agent.visitors
            .verifyClientVisitor(data)
            .then(
                action((response) => {
                    return response;
                })
            )
            .catch(
                action((error) => {
                    throw error;
                })
            );
    }

    handleClinetVisitorChange = async (name, value) => {
        //handles changes made in the visitor and sets it to currentVisitor//
        //takes 2 arguments, type - string, name, value//
        this.currentClientVisitor[name] = value;
    };
}

const _visitorStore = new visitorStore();
export default _visitorStore;
