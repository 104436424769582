import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import withLocalization from '../hoc/withLocalization';

class ListErrors extends Component {
    render() {
        const { errors, t } = this.props;
        if (errors && Array.isArray(errors) && errors.length) {
            return (
                <div className="list-errors">
                    {errors.map((error, index) => {
                        return (
                            <span className="list-errors__item" key={`error_${index}`}>
                                {t(error)}
                            </span>
                        );
                    })}
                </div>
            );
        }
        if (errors && typeof errors === 'object' && !Array.isArray(errors)) {
            if (errors.message) {
                return (
                    <div className="list-errors">
                        <span className="list-errors__item">{t(errors.message)}</span>
                    </div>
                );
            }
            return (
                <div className="list-errors">
                    {Object.keys(errors).map(key => {
                        return (
                            <span className="list-errors__item" key={key}>
                                {key === 'error' ? '' : key} {t(errors[key])}
                            </span>
                        );
                    })}
                </div>
            );
        }
        return null;
    }
}

export default withLocalization(observer(ListErrors));
