import React, { Component } from 'react';
import {v4 as uuid} from 'uuid';

class CustomButtonCheckbox extends Component {
    render() {
        const {
            label, onChange, isChecked, isSatSun, isRightPadding, wrapperClassName = '', className, inlineBlock, boldLabel, image,  ...rest
        } = this.props;
        const key = uuid();
        return (
            <div className={`checkbox-button ${wrapperClassName}`}>
                <label className={"round"} htmlFor={key} style={{paddingRight: isRightPadding}}>
                    <input
                        id={key}
                        type="checkbox"
                        onChange={e => onChange(e.target.checked)}
                        checked={isChecked}
                        {...rest}
                    />
                    <span className={isSatSun ? `isSatSun ${className}` : `${className}`} title={label}>
                        {image ? <img src={image} width={25} height={25} className='me-2' /> : null}
                        {label}
                    </span>
                </label>
            </div>
        );
    }
}

export default CustomButtonCheckbox;
