import { observable, action, computed, makeObservable } from 'mobx';

import agent from '../agent';

const DEFAULT_STATE = {
    step: 0,
    knownFields: [],
    csvData: null,
    filteredCSVData: null,
    fileName: '',
    zipName: '',
    mapping: [],
    warnings: [],
};

export class ImportStore {
    isLoading = false;

    step = 0;

    importsRegistry = observable.map();

    zips = [];

    error = null;

    importSent = false;

    newImport = DEFAULT_STATE;

    totalCount = 0;

    constructor() {
        makeObservable(this, {
            isLoading: observable,
            step: observable,
            importsRegistry: observable,
            zips: observable,
            error: observable,
            importSent: observable,
            newImport: observable,
            totalCount: observable,
            imports: computed,
            getNewImport: computed,
            updateNewImport: action,
            resetImport: action,
            applyFilters: action,
            getFilteredRows: computed,
            nextStep: action,
            setMapping: action,
            prevStep: action,
            setSent: action,
            loadZips: action,
            launch: action,
            loadImports: action,
            deleteImport: action,
            createImport: action
        });
    }

    get imports() {
        return this.importsRegistry.values();
    }

    clear() {
        this.importsRegistry.clear();
    }

    getImport(slug) {
        return this.importsRegistry.get(slug);
    }

    get getNewImport() {
        return this.newImport;
    }

    updateNewImport(key, data) {
        this.importSent = false;
        this.error = null;
        const { newImport } = this;
        newImport[key] = data;
        // if (key === 'csvData') {
        // }
    }

    resetImport() {
        this.newImport = DEFAULT_STATE;
        this.importSent = false;
        this.error = null;
    }

    applyFilters(newFilter) {
        this.newImport.filters = newFilter;
        const { csvData } = this.newImport;
        const csvHead = csvData.cols;
        const csvBody = csvData.rows;

        newFilter.forEach(filterItem => {
            for (let i = 0; i < csvHead.length; i++) {
                // if (filterItem.field === csvHead[i]) {
                // }
            }
        });
        this.newImport.filteredCSVData = csvBody;
    }

    get getFilteredRows() {
        return this.newImport.filteredCSVData ? this.newImport.filteredCSVData : this.newImport.csvData.rows;
    }

    nextStep(newStep) {
        this.error = null;
        if (this.newImport.step === 0) {
            if (!this.newImport.csvData) {
                this.error = 'No file uploaded';
            }
            // if (!this.newImport.zipName) {
            //  this.error = 'Please choose zip with images';
            // }

            const findClosest = name => {
                let res = '';
                this.newImport.knownFields.forEach(fld => {
                    if (
                        name.trim().toLowerCase() === fld.field
                        || name.trim().toLowerCase() === fld.name.toLowerCase()
                    ) {
                        res = fld.field;
                    }
                });
                return res;
            };
            this.newImport.mapping = [];
            if (this.newImport.csvData) {
                this.newImport.csvData.cols.forEach(col => {
                    this.newImport.mapping.push(findClosest(col));
                });
            }
        }
        if (this.newImport.step === 1) {
            const requireds = [
                'username',
                'email',
                'phone',
                'firstname',
                'lastname',
                'name',
                'state',
                'city',
                'title',
                'category',
                'location',
                'images',
            ];
            this.newImport.warnings = [];
            requireds.forEach(req => {
                if (this.newImport.mapping.indexOf(req) < 0) {
                    this.newImport.warnings.push(`Missing required field: ${req}`);
                }
            });
        }
        if (!this.error) this.newImport.step = newStep || this.newImport.step + 1;
    }

    setMapping(value, index) {
        this.newImport.mapping[index] = value;
    }

    prevStep() {
        this.error = null;
        this.newImport.step = this.newImport.step - 1;
    }

    setSent(value) {
        this.importSent = value;
    }

    loadZips() {
        agent.Imports.zips().then(
            action(({ zips }) => {
                this.zips = zips;
            })
        );
    }

    launch() {
        const _import = {
            mapping: this.newImport.mapping,
            list: {
                cols: this.newImport.csvData.cols,
                rows: this.getFilteredRows,
            },
            zip: this.newImport.zipName,
        };
        return agent.Imports.create(_import).then(({ imported }) => {
            // agent.Imports.launch(import).then(() => {
            this.loadImports();
            this.setSent(true);
            // this.importsRegistry.set(import.slug, import);
            return imported;
            // });
        });
    }

    $req() {
        return agent.Imports.getAll();
    }

    loadImports() {
        this.isLoading = true;
        return agent.Imports.get()
            .then(
                action(({ imports, importsCount }) => {
                    this.importsRegistry.clear();
                    imports.forEach(i => this.importsRegistry.set(i.createdAt, i));
                    this.totalCount = importsCount;
                })
            )
            .finally(
                action(() => {
                    this.isLoading = false;
                })
            );
    }

    deleteImport(id) {
        this.isLoading = true;
        return agent.Imports.delete(id);
    }

    createImport(i) {
        return agent.Imports.create(i).then(({ imported }) => {
            this.loadImports();
            // this.importsRegistry.set(import.slug, import);
            return imported;
        });
    }
}

const _ImportStore = new ImportStore();
export default _ImportStore;
