import React from "react";
import "./styles.css";

function PageBanner(props) {
  return (
    <div className={`pageImg ${props.customClass}`}>
      <img src={props.src} alt={props.alt} />
    </div>  
  );
}
export default PageBanner;