import ServiceRequests from './containers/Admin/ServiceRequests/ServiceRequests';
import ServiceRequestsForm from './containers/Admin/ServiceRequests/ServiceRequestsForm';
import Setting from './assets/img/setting.svg';

const customerRoutes = [
    {
        path: '/',
        layout: '/customer',
        icon: Setting,
        name: 'Service Requests',
        component: ServiceRequests,
        allowed: user => {
            return true;
        }, 
        hasChildRoutes:true,
        childRoutes:[
            {
                path: '/add',
                layout: '/customer',
                name: 'Add orders',
                component: ServiceRequestsForm,
                allowed: user => {
                    return true;
                },
            },
            {
                path: '/:id',
                layout: '/customer',
                name: 'Update orders',
                component: ServiceRequestsForm,
                allowed: user => {
                    return true;
                },
            }
        ]
    }
];
export default customerRoutes;
