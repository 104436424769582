import ClientLogo1stline from "../../assets/images/clients/client_logo-1stline.png";
import ClientLogo1985 from "../../assets/images/clients/client_logo-1985.png";
import ClientLogoAlibaba from "../../assets/images/clients/client_logo-alibaba.png";
import ClientLogoBajazzo from "../../assets/images/clients/client_logo-bajazzo.png";
import ClientLogoBalkan from "../../assets/images/clients/client_logo-balkan.png";
import ClientLogoCapstoneContractingCorp from "../../assets/images/clients/client_logo-capstone_contracting_corp.png";
import ClientLogoDevaGruppen from "../../assets/images/clients/client_logo-deva_gruppen.png";
import ClientLogoDoctorCrunchy from "../../assets/images/clients/client_logo-doctor_crunchy.png";
import ClientLogoEngebretsenBetongMur from "../../assets/images/clients/client_logo-engebretsen_betong_mur.png";
import ClientLogoFiveBoro from "../../assets/images/clients/client_logo-five_boro.png";
import ClientLogoFolketsKebab from "../../assets/images/clients/client_logo-folkets_kebab.png";
import ClientLogoFoodPartner from "../../assets/images/clients/client_logo-foodpartner.png";
import ClientLogoHotel from "../../assets/images/clients/client_logo-hotel.png";
import ClientLogoIndiaGate from "../../assets/images/clients/client_logo-indiagate.png";
import ClientLogoIndique from "../../assets/images/clients/client_logo-indique.png";
import ClientLogoJKConcrete from "../../assets/images/clients/client_logo-jk_concrete.png";
import ClientLogoJKPet from "../../assets/images/clients/client_logo-jk_pet.png";
import ClientLogoJonk from "../../assets/images/clients/client_logo-jonk.png";
import ClientLogoNoLimit from "../../assets/images/clients/client_logo-no_limit.png";
import ClientLogoNorskArbeidsmannsforbund from "../../assets/images/clients/client_logo-norsk_arbeidsmannsforbund.png";
import ClientLogoPaabrygga from "../../assets/images/clients/client_logo-paabrygga.png";
import ClientLogoPolbetongas from "../../assets/images/clients/client_logo-polbetongas.png";
import ClientLogoRichmond from "../../assets/images/clients/client_logo-richmond.png";
import ClientLogoTheMetropolitian from "../../assets/images/clients/client_logo-the_metropolitian.png";
import ClientLogoTaxi4moss from "../../assets/images/clients/client_logo-the_taxi4moss.png";
import ClientLogoAlanyaKjokken from "../../assets/images/clients/client_logo-alanya_kjokken.png";
import ClientLogoMotherIndia from "../../assets/images/clients/client_logo-mother_india.png";
// import ClientLogoMurkompanietOsfoldAS from "../../assets/images/clients/client_logo-murkompaniet_osfold_as.png";
import ClientLogoKebabFabrikken from "../../assets/images/clients/client_logo-kebab_fabrikken.png";
import ClientLogo4ESecurity from "../../assets/images/clients/client_logo-4_e_security.png";
import ClientLogoMessAndGrill from "../../assets/images/clients/client_logo-messo_and_grill.jpg";
import ClientLogoVaranasi from "../../assets/images/clients/client_logo-varanasi.svg";
import ClientLogoEngFugl from "../../assets/images/clients/client_logo-eng_fugl.png";
import ClientLogoKeser from "../../assets/images/clients/client_logo-keser.png";
import ClientLogoJessBazar from "../../assets/images/clients/client_logo-jessbazar.png";
import ClientLogoZamZam from "../../assets/images/clients/client_logo-zam-zam.png";
import ClientLogoBowlersStempelGull from "../../assets/images/clients/client_logo-bowlers_stempel_gull.png";
import ClientLogoAkebergSkoglunnPolsemakeriAS from "../../assets/images/clients/client_logo-akeberg_skoglunn_polsemakeri_as.png";
import ClientLogoManstadKjottAS from "../../assets/images/clients/client_logo-manstad_kjott_as.png";
import ClientLogoAvempro from "../../assets/images/clients/client_logo-avempro.png";
import ClientLogoJungeltelegrafen from "../../assets/images/clients/client_logo-jungeltelegrafen.png";
import ClientLogoAmigosPizzaAndKebab from "../../assets/images/clients/client_logo-amigos_pizza_and_kebab.png";





const ClientLogos = [
    ClientLogo1stline,
    ClientLogo1985,
    ClientLogoAlibaba,
    ClientLogoBajazzo,
    ClientLogoBalkan,
    ClientLogoCapstoneContractingCorp,
    ClientLogoDevaGruppen,
    ClientLogoDoctorCrunchy,
    ClientLogoEngebretsenBetongMur,
    ClientLogoFiveBoro,
    ClientLogoFolketsKebab,
    ClientLogoFoodPartner,
    ClientLogoHotel,
    ClientLogoIndiaGate,
    ClientLogoIndique,
    ClientLogoJKConcrete,
    ClientLogoJKPet,
    ClientLogoJonk,
    ClientLogoNoLimit,
    ClientLogoNorskArbeidsmannsforbund,
    ClientLogoPaabrygga,
    ClientLogoPolbetongas,
    ClientLogoRichmond,
    ClientLogoTheMetropolitian,
    ClientLogoTaxi4moss,
    ClientLogoAlanyaKjokken,
    ClientLogoMotherIndia,
    // ClientLogoMurkompanietOsfoldAS,
    ClientLogoKebabFabrikken,
    ClientLogo4ESecurity,
    ClientLogoMessAndGrill,
    ClientLogoVaranasi,
    ClientLogoEngFugl,
    ClientLogoKeser,
    ClientLogoJessBazar,
    ClientLogoZamZam,
    ClientLogoBowlersStempelGull,
    ClientLogoAkebergSkoglunnPolsemakeriAS,
    ClientLogoManstadKjottAS,
    ClientLogoAvempro,
    ClientLogoJungeltelegrafen,
    ClientLogoAmigosPizzaAndKebab
];

export default ClientLogos;