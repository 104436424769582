import React, { Component } from 'react';
import SignWidget from './Widgets/SignWidget';
import TextboxWidget from './Widgets/TextboxWidget';
import InitialWidget from './Widgets/InitialWidget';
import applicationRouter from '~/hoc/applicationRouter';
import config from '~/library/config';
import './Recipients/public/index.css';
import { observer, inject } from 'mobx-react';
import withLocalization from '~/hoc/withLocalization';
import LoadingSpinner from '~/elements/LoadingSpinner';
import LabelWidget from './Widgets/LabelWidget';
import RecipientNameWidget from './Widgets/RecipientNameWidget';
import DateWidget from './Widgets/DateWidget';
import SelectBoxWidget from './Widgets/SelectBoxWidget';
import CheckBoxWidget from './Widgets/CheckBoxWidget';
import RadioWidget from './Widgets/RadioWidget';


export class SignDocPdf extends Component {

    constructor(props) {
        super(props);
        this.state = {
            docPages: null,
            widgets: null,
            recipient:null,
            isError: false
        }
    }

    componentDidMount() {
        const { signatureStore } = this.props;
        const search = this.props.router.location.search;
        const id = search.slice(4);
        if (id) {
            signatureStore.getRecipientDoc(id).then((res) => {
                if (res) {
                    this.setState({
                        docPages: res.document_pages,
                        widgets: res.sign_document.widgets,
                        recipient: res.recipient_detail,
                        caseId: res.sign_document.id,
                    });
                }
            })
        }
        else{
            this.setState({isError: true})
        }

    }

    getWidgetFromData(widget, pageId) {
        const { recipient, caseId } = this.state;
        if (widget.pageId === pageId) {
            if (widget.name === 'Signature') {
                if (widget.recipientId !== recipient.id && widget.data) {
                    return (
                        <SignWidget
                            key={widget.widgetId}
                            view={1}
                            recipientDetail={recipient}
                            caseId={caseId}
                            info={widget}
                            locked={true}
                        />
                    );
                } else if (widget.recipientId !== recipient.id && !widget.data) {
                    return;
                } else if (widget.recipientId === recipient.id && widget.data && recipient.status === 'signed') {
                    return (
                        <SignWidget
                            key={widget.widgetId}
                            view={1}
                            recipientDetail={recipient}
                            caseId={caseId}
                            info={widget}
                            locked={true}
                        />
                    );
                } else {
                    return (
                        <SignWidget
                            key={widget.widgetId}
                            edit={1}
                            recipientDetail={recipient}
                            caseId={caseId}
                            info={widget}
                            locked={true}
                        />
                    );
                }

            } else if (widget.name === "Text") {
                if (widget.recipientId !== recipient.id) {
                    return <TextboxWidget key={widget.widgetId} info={widget} view={1} locked={true} />
                } else if (widget.recipientId !== recipient.id && !widget.data) {
                    return;
                } else if (widget.recipientId === recipient.id && widget.data && recipient.status === 'signed') {
                    return <TextboxWidget key={widget.widgetId} info={widget} view={1} locked={true} />;
                } else {
                    return <TextboxWidget key={widget.widgetId} info={widget} edit={1} locked={true} />;
                }
            } else if (widget.name === "Date") {
                if (widget.recipientId !== recipient.id) {
                    return <DateWidget key={widget.widgetId} info={widget} owner={false} view={1} locked={true} />
                } else if (widget.recipientId === recipient.id && recipient.status === "signed") {
                    return <DateWidget key={widget.widgetId} info={widget} owner={false} view={1} locked={true} />
                } else {
                    return <DateWidget key={widget.widgetId} info={widget} owner={true} locked={true} />;
                }
            } else if (widget.name === 'Initial') {
                if (widget.recipientId !== recipient.id && widget.data) {
                    return (
                        <InitialWidget
                            key={widget.widgetId}
                            view={1}
                            recipientDetail={recipient}
                            caseId={caseId}
                            info={widget}
                            locked={true}
                        />
                    );
                } else if (widget.recipientId !== recipient.id && !widget.data) {
                    return;
                } else if (widget.recipientId === recipient.id && widget.data && recipient.status === 'signed') {
                    return (
                        <InitialWidget
                            key={widget.widgetId}
                            view={1}
                            recipientDetail={recipient}
                            caseId={caseId}
                            info={widget}
                            locked={true}
                        />
                    );
                } else {
                    return (
                        <InitialWidget
                            key={widget.widgetId}
                            edit={1}
                            recipientDetail={recipient}
                            caseId={caseId}
                            info={widget}
                            locked={true}
                        />
                    );
                }
                // return <InitialWidget key={widget.widgetId} info={widget} locked={true} />
            } else if (widget.name === 'Label') {
                if (widget.recipientId !== recipient.id) {
                    return <LabelWidget key={widget.widgetId} info={widget} owner={false} view={1} locked={true} />;
                } else if (widget.recipientId === recipient.id && recipient.status === 'signed') {
                    return <LabelWidget key={widget.widgetId} info={widget} owner={false} view={1} locked={true} />;
                } else {
                    return <LabelWidget key={widget.widgetId} info={widget} owner={true} locked={true} />;
                }
            } else if (widget.name === "Recipient") {

                if (widget.recipientId !== recipient.id) {
                    return (
                        <RecipientNameWidget key={widget.widgetId} info={widget} owner={false} view={1} locked={true} />
                    );
                } else if (widget.recipientId === recipient.id && recipient.status === 'signed') {
                    return (
                        <RecipientNameWidget key={widget.widgetId} info={widget} owner={false} view={1} locked={true} />
                    );
                } else {
                    return <RecipientNameWidget key={widget.widgetId} info={widget} owner={true} locked={true} />;
                }
            } else if (widget.name === "Select") {

                if (widget.recipientId !== recipient.id && widget.data) {
                    return <SelectBoxWidget key={widget.widgetId} info={widget} owner={false} view={1} locked={true} />;
                } else if (widget.recipientId === recipient.id && recipient.status === 'signed') {
                    return <SelectBoxWidget key={widget.widgetId} info={widget} owner={false} view={1} locked={true} />;
                } else if (widget.recipientId !== recipient.id && !widget.data) {
                    return;
                } else {
                    return <SelectBoxWidget key={widget.widgetId} info={widget} owner={true} edit={1} locked={true} />;
                }
            } else if (widget.name === "Checkbox") {

                if (widget.recipientId !== recipient.id) {
                    return <CheckBoxWidget key={widget.widgetId} disable={true} info={widget} view={1} locked={true} />;
                } else if (widget.recipientId === recipient.id && recipient.status === 'signed') {
                    return <CheckBoxWidget key={widget.widgetId} info={widget} owner={false} view={1} locked={true} />;
                } else {
                    return <CheckBoxWidget key={widget.widgetId} info={widget} owner={true} edit={1} locked={true} />;
                }
            } else if (widget.name === 'Radio') {
                if (widget.recipientId !== recipient.id) {
                    return <RadioWidget key={widget.widgetId} disable={true} info={widget} view={1} locked={true} />;
                } else if (widget.recipientId === recipient.id && recipient.status === 'signed') {
                    return <RadioWidget key={widget.widgetId} info={widget} owner={false} view={1} locked={true} />;
                } else {
                    return <RadioWidget key={widget.widgetId} info={widget} owner={true} edit={1} locked={true} />;
                }
            }
            else {
                return ''
            }
        } else {
            return '';
        }
    }

    render() {
        const { docPages, isError } = this.state;
        if(isError) return <h1 className='text-center text-danger'>Document Not Found!</h1>
        if (!docPages) {
            return <LoadingSpinner />
        }
        return (
            <div className="signerPage">

                <div className='content2'>
                    {
                        docPages.map((page) => {
                            return (
                                <>
                                    <div className="sign-img">
                                        <img src={`${config.UPLOADS_ROOT}/${page.page_path}`} alt="screen" draggable="false" />
                                        {
                                            this.state.widgets && this.state.widgets.map((widget) => {
                                                return (
                                                    this.getWidgetFromData(widget, page.id)
                                                )
                                            })
                                        }
                                    </div>
                                </>
                            );
                        })
                    }
                </div>

            </div>
        )
    }
}

export default inject('commonStore', 'signatureStore', 'recipientStore')(applicationRouter(withLocalization(observer(SignDocPdf))));