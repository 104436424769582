import React from 'react';
import PropTypes from 'prop-types';

export default function WeekNumber({ date, onClickWeekNumber, weekNumber, checkedWeekNumbers, disabledWeekNumbers }) {
    const props = {
        className: 'react-calendar__tile',
        style: { flexGrow: 1 },
    };

    const children = <span>{weekNumber}</span>;

    return onClickWeekNumber ? (
        <label style={{marginTop:'7px'}}>
            <input
                type="checkbox"
                disabled={disabledWeekNumbers && disabledWeekNumbers.indexOf(parseInt(weekNumber)) >= 0}
                checked={checkedWeekNumbers && checkedWeekNumbers.indexOf(parseInt(weekNumber)) >= 0}
                onChange={() => onClickWeekNumber(weekNumber, date)}
            />
            &nbsp;
            {children}
        </label>
    ) : (
        <div {...props}>{children}</div>
    );
}

WeekNumber.propTypes = {
    date: PropTypes.instanceOf(Date).isRequired,
    onClickWeekNumber: PropTypes.func,
    weekNumber: PropTypes.node.isRequired,
    checkedWeekNumbers: PropTypes.array,
};
