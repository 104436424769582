export const locationToString = location => {
    if (!location) return '';
    if (location.coords) return `${location.coords.latitude},${location.coords.longtitude}`; // Native format
    return `${location.lat},${location.lng}`; // Leaflet
};

export const leafletStringToLocation = location => location && location.split
    ? {
        lat: Number.parseFloat(location.split(',')[0]),
        lng: Number.parseFloat(location.split(',')[1]),
    }
    : null;

export const googleCoordsToLeaflet = location => ({
    lat: location.latitude,
    lng: location.longitude,
});
