import React, { Component } from 'react';
import { Rnd } from 'react-rnd';
import initialWidget from '../../../../assets/img/Initials-widget.svg';
import initialWidgetNor from '../../../../assets/img/no/Initials-widget-no.svg';
import initialWidgetEs from '../../../../assets/img/es/Initials-widget-es.svg';
import initialWidgetGr from '../../../../assets/img/gr/Initials-widget-gr.svg';
import initialWidgetLt from '../../../../assets/img/lt/Initials-widget-lt.svg';
import initialWidgetPl from '../../../../assets/img/pl/Initials-widget-pl.svg';
import initialWidgetRu from '../../../../assets/img/ru/Initials-widget-ru.svg';
import initialWidgetSe from '../../../../assets/img/se/Initials-widget-se.svg';
import { observer, inject } from 'mobx-react';
import withLocalization from '../../../../hoc/withLocalization';
import SignatureModal from './../Recipients/public/signatureModal';


export class InitialWidget extends Component {
    constructor(props) {
        super(props);
        this.state = {
            x: this.props.info.widgetX || 0,
            y: this.props.info.widgetY || 0,
            width: this.props.info.width || 100,
            height: this.props.info.height || 70,
            showSignatureModal: false,
            signature_img: this.props.info.data || null
        }
        this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleSignatureSave = this.handleSignatureSave.bind(this);
    }
    setActiveWidget(widget) {
        const { propertyStore } = this.props;
        propertyStore.setActiveWidget(widget)
        if(this.props.edit && !widget.data && !this.props.view){
            this.setState({ showSignatureModal: true });
          }
    }

    hexToRgbA(hex) {
        var c;
        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
            c = hex.substring(1).split('');
            if (c.length == 3) {
                c = [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c = '0x' + c.join('');
            return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',.4)';
        }
        throw new Error('Bad Hex');
    }

    onDragStop = (evt, position, at) => {
      const { propertyStore, info } = this.props;
      const DEFAULT_WIDTH = 150;
      const DEFAULT_HEIGHT = 70;
      const updatedWidth = (propertyStore.activeWidget && propertyStore.activeWidget.width) ? propertyStore.activeWidget.width : ( this.state.width ? this.state.width : DEFAULT_WIDTH);
      const updatedHeight = (propertyStore.activeWidget && propertyStore.activeWidget.height) ? propertyStore.activeWidget.height : ( this.state.height ? this.state.height : DEFAULT_HEIGHT);
      const widInfo = { x: position.x, y: position.y,width:updatedWidth, height:updatedHeight  };
      propertyStore.updateWidgetRnd(info.widgetId, widInfo)
    }

    onResizeStop = (e, direction, ref, delta, position) => {
        const widInfo = { x: position.x, y: position.y, width: ref.style.width, height: ref.style.height };
        const { propertyStore, info } = this.props;
        propertyStore.updateWidgetRnd(info.widgetId, widInfo)
    }

    handleCloseModal() {
        this.setState({ showSignatureModal: false });
      }

      handleSignatureSave(type, image) {
        const { signatureStore, recipientDetail, caseId, propertyStore } = this.props;
        let data = {
          case_id: caseId,
          sign_type: type,
          sign_data: image,
          recipient_id: recipientDetail.id
        }
        let widgetsData = {
          caseId: signatureStore.caseId,
          widgets: propertyStore.widgets
        }
    
        signatureStore.storeSignature(data).then(res => {
          propertyStore.updateWidgetData(this.props.info, 'data', res.result.sign_data);
         // propertyStore.saveWidget(widgetsData);
          this.setState({
            showSignatureModal: false,
            signature_img: res.result.sign_data
    
          });
        });
      }  
   
    render() {
        const { recipientDetail, caseId } = this.props;
        const { x, y, width, height, showSignatureModal, signature_img } = this.state;
        const widgetColor = this.hexToRgbA(this.props.info ? this.props.info.colorCode : "#ffd65b");
        let loc = sessionStorage.getItem("locale");
        let getLocaleWidget;
        switch(loc){
          case 'en' : getLocaleWidget = initialWidget;break;
          case 'no' : getLocaleWidget = initialWidgetNor;break;
          case 'es' : getLocaleWidget = initialWidgetEs;break;
          case 'gr' : getLocaleWidget = initialWidgetGr;break;
          case 'lt' : getLocaleWidget = initialWidgetLt;break;
          case 'pl' : getLocaleWidget = initialWidgetPl;break;
          case 'ru' : getLocaleWidget = initialWidgetRu;break;
          case 'se' : getLocaleWidget = initialWidgetSe;break;
          default : getLocaleWidget = initialWidgetNor;
        }
        return (
            <>
             {
          this.props.locked && (
            <SignatureModal showModal={showSignatureModal} handleSignatureSave={this.handleSignatureSave} recipientDetail={recipientDetail} caseId={caseId} handleCloseModal={this.handleCloseModal} />
          )
        }
                <Rnd
                    default={{
                        x,
                        y,
                        width,
                        height
                    }}
                    
                    onDragStop={this.onDragStop}
                    onResizeStop={this.onResizeStop}
                    lockAspectRatio="true"
                    bounds="parent"
                    className="signWidgetCon"
                    disableDragging={this.props.locked}
                    enableResizing={!this.props.locked}

                >
                 <div className="signWidgetCon" onClick={() => { this.setActiveWidget(this.props.info) }}>
            <img src={signature_img ? signature_img : getLocaleWidget} alt="initial widget" draggable="false" 
           // style={ this.props.edit ? { resize: 'none' }  : this.props.view ?  { resize: 'none',border: 'none',overflow: 'hidden', background: 'transparent' } :{backgroundColor: widgetColor, resize: 'none'}}
            style={this.props.view ? {backgroundColor: 'none'}:this.props.edit?{backgroundColor: 'none'}:{backgroundColor:widgetColor} }></img>
          </div>
                    {/* <img src={signature_img ? signature_img : initialWidget } alt="initial widget" className="" draggable="false" style={{ backgroundColor: widgetColor }} onClick={() => { this.setActiveWidget(this.props.info) }} /> */}
                </Rnd>
            </>
        )
    }
}

export default inject('propertyStore', 'recipientStore','signatureStore')(withLocalization(observer(InitialWidget)));