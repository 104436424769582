import React, { useEffect, useReducer } from 'react';
import { inject } from 'mobx-react';
import { Col, Row } from 'react-bootstrap';

//components
import applicationRouter from '~/hoc/applicationRouter'
import withLocalization from '~/hoc/withLocalization';
import GenericList from '../../../components/GenericList/GenericList.js';

//elements
import LoadingSpinner from '~/elements/LoadingSpinner';
import Button from '../../../components/CustomButton/CustomButton.jsx';

//assets
import ShieldLockIcon from '../../../assets/img/shield_lock.svg';
import ShieldCheckedIcon from '../../../assets/img/shield_checked.svg';
import ShieldExclamationIcon from '../../../assets/img/shield_exclaimation.svg';
import LoginAsIcon from '../../../assets/img/addUser.svg';
import DeleteIcon from '../../../assets/img/deleting.svg';

//styles
import './styles.css';

export const MultiAccountModes = {
    "ADD": 'add',
    "VERIFY_EMAIL": "verify_email",
    "RENEW_JWT": "renew_jwt",
    "MANAGE": "manage",
    "INFO": "info",
}

const multiAccountReducer = (state, action) => {
    switch (action.type) {
        case 'SET_USERNAME':
            return { ...state, username: action.payload };
        case 'SET_PASSWORD':
            return { ...state, password: action.payload };
        case 'SET_LOADING':
            return { ...state, loading: action.payload };
        case 'SET_IS_ACCOUNT_ADDED':
            return { ...state, isAccountAdded: action.payload };
        case 'SET_LOADING_AND_IS_ACCOUNT_ADDED':
            return {
                ...state,
                loading: action.loading,
                isAccountAdded: action.isAccountAdded
            }
        case 'RESET':
            return {
                ...state,
                username: "",
                password: "",
                loading: false,
                isAccountAdded: false
            }
        default:
            return state;
    }
}

const MultiAccountAdmin = (props) => {
    const { mode, t } = props;
    const [state, dispatch] = useReducer(multiAccountReducer, {
        username: "",
        password: "",
        loading: false,
        isAccountAdded: false
    })
    const { username, password, loading, isAccountAdded } = state;
    let Cols = [
        {
            Header: 'Company Name',
            id: 'secondary_client_name',
            accessor: ({ secondary_client_name }) => <span className='text-capitalize'>{secondary_client_name}</span>,
            maxWidth:100
        },
        {
            Header: 'Account',
            id: 'secondary_admin_name',
            accessor: ({ secondary_admin_name }) => <span className='text-capitalize'>{secondary_admin_name}</span>,
            maxWidth:100
        },
        {
            Header: 'Email',
            id: 'secondary_client_admin_email',
            accessor: ({ secondary_client_admin_email }) => <span>{secondary_client_admin_email}</span>,
            maxWidth: 180
        },
        {
            Header: 'Status',
            id: 'is_email_verified',
            accessor: ({ is_email_verified }) => <span className={is_email_verified ? 'text-success' : 'text-danger'}>
                {t(is_email_verified ? 'verified' : 'Not verified')}
            </span>,
            maxWidth: 80,
            minWidth: 50,
        },
        {
            Header: 'Access',
            id: 'is_jwt_token_expired',
            accessor: ({ is_jwt_token_expired, is_email_verified }) =>
                <span className={is_email_verified ? is_jwt_token_expired ? 'text-warning' : 'text-success' : 'text-muted'}>
                    {t(is_email_verified ? is_jwt_token_expired ? 'Expired' : 'Active' : 'Not given')}
                </span>,
            maxWidth: 80,
            minWidth: 50,
        },
        {
            Header: 'Actions',
            id: 'actions',
            sortable: false,
            accessor: (full) => renderTableActionButtons(full),
            maxWidth: 80,
            minWidth: 50,
        },
    ];

    useEffect(() => {
        dispatch({ type: 'RESET' });
    }, [props.mode])

    useEffect(() => {
        dispatch({ type: 'SET_USERNAME', payload: props.currentMultiAccount })
    }, [props.currentMultiAccount])

    function handleInputChange(e, type) {
        dispatch({ type: type, payload: e.target.value })
    }

    function handleDelete(item) {
        dispatch({ type: 'SET_LOADING', payload: true });
        try {
            props.handleMultiAccountAdminActions('remove', item, () => {
                dispatch({ type: 'SET_LOADING', payload: false });
            });
        }
        catch (err) {
            console.error({ err })
            props.commonStore.addNotification(t(err || 'Error'), null, 'error');
        }
    }

    function handleSubmit(e) {
        e.preventDefault();
        if (!username || !password || !username.trim() || !password.trim()) {
            return props.commonStore.addNotification(t('All fields are required'), null, 'error');
        }

        dispatch({ type: 'SET_LOADING', payload: true });
        const data = {
            user: {
                email: username,
                password: password
            }
        }
        try {
            props.multiAccountAdminStore.addAccountForMultiAccountAccess(data).then(result => {
                dispatch({ type: 'SET_LOADING_AND_IS_ACCOUNT_ADDED', loading: false, isAccountAdded: true });
            }).catch(err => {
                console.error({ err });
                dispatch({ type: 'SET_LOADING', payload: false });
                props.commonStore.addNotification(t('Username or password is incorrect'), null, 'error');
            })
        }
        catch (err) {
            console.error({ err })
            props.commonStore.addNotification(t(err || 'Error'), null, 'error');
        }
    }

    function renderTableActionButtons(full) {
        const { t, userStore } = props;
        const { currentUser } = userStore;
        return (
            <div className="d-flex justify-content-evenly">
                <Button
                    icon_sm
                    fill
                    onClick={() => {
                        if (!full.is_jwt_token_expired) {
                            props.handleMultiAccountAdminActions('switch', full)
                        } else {
                            dispatch({ type: 'SET_LOADING', payload: true });
                            props.handleMultiAccountAdminActions('switch', full, () => {
                                dispatch({ type: 'SET_LOADING', payload: false });
                            })
                        }
                    }}
                    disabled={full.secondary_admin_id === currentUser.id}
                    title={
                        t(full.secondary_admin_id === currentUser.id ? 'Current account' :
                            !full.is_email_verified ? 'No Access' :
                                full.is_jwt_token_expired ? 'Token Expired' : 'Switch account')
                    }
                >
                    <img src={LoginAsIcon} alt={t("Switch")} />
                </Button>
                <Button
                    icon_sm_delete
                    fill
                    onClick={() => handleDelete(full)}
                    disabled={full.secondary_admin_id === currentUser.id}
                    title={
                        t(full.secondary_admin_id === currentUser.id ? 'Current account' : 'Remove account')
                    }
                >
                    <img src={DeleteIcon} alt="remove account" />
                </Button>
            </div>
        );
    }

    if (loading) {
        return <LoadingSpinner style={{ height: '100vh' }} />
    }

    if (mode === MultiAccountModes.VERIFY_EMAIL) {
        return (
            <Row>
                <div className='multi-account-admin-header'>
                    <span>
                        <img
                            src={ShieldExclamationIcon}
                            alt="sheild" />
                    </span>
                    <span className='text-center text-warning mt-2 mb-2'>
                        {t("Email verification is remaining, Please verify the account using the link sent to your email")}.
                    </span>
                    <div className="d-flex justify-content-center mt-2">
                        <Button wd fill
                            onClick={() => props.handleMultiAccountAdminModeChange(MultiAccountModes.MANAGE)}
                        >
                            {t("Manage Accounts")}
                        </Button>
                    </div>
                </div>
            </Row>
        )
    }


    if (mode === MultiAccountModes.ADD || mode === MultiAccountModes.RENEW_JWT) {
        return (
            <Row>
                <div className='multi-account-admin-header'>
                    <span>
                        <img
                            src={isAccountAdded ? ShieldCheckedIcon : ShieldLockIcon}
                            alt="sheild" />
                    </span>
                    <span className='text-center'>
                        {mode === MultiAccountModes.RENEW_JWT ?
                            t(isAccountAdded ? "Account access has been renewed successfully"
                                : "Hi admin, looks like your account has expired, please verify to renew access")
                            :
                            t(isAccountAdded ? t('Account has been added successfully, please verify email and start using it')
                                : "Please login to add account in your multi-access account list")
                        }
                    </span>
                </div>
                {!isAccountAdded ?
                    <Col xs={12}>
                        <Row className='align-items-center'>
                            <Col xs={5}>
                                <label> {t('Email')} / {t('Username')} <span className='text-danger'>*</span></label>
                            </Col>
                            <Col xs={7}>

                                {mode === MultiAccountModes.RENEW_JWT && props.currentMultiAccount ?
                                    <span className='fw-600'>
                                        {props.currentMultiAccount}
                                    </span>
                                    :
                                    <input
                                        type="text"
                                        className="form-control login-input mb_25 mt-12"
                                        placeholder={`${t('Email address')} / ${t('Username')}`}
                                        required
                                        onChange={(e) => handleInputChange(e, 'SET_USERNAME')}
                                        value={username}
                                    />
                                }
                            </Col>
                            <Col xs={5}>
                                <label> {t('Password')} <span className='text-danger'>*</span></label>
                            </Col>
                            <Col xs={7}>
                                <input
                                    type="password"
                                    className="form-control login-input mb_25 mt-12"
                                    placeholder={t('Password')}
                                    required
                                    onChange={(e) => handleInputChange(e, 'SET_PASSWORD')}
                                    value={password}
                                />
                            </Col>
                            <div className="d-flex justify-content-center mt-2">
                                <Button wd fill
                                    onClick={(e) => handleSubmit(e)}
                                >
                                    {t("Submit")}
                                </Button>
                            </div>
                        </Row>
                    </Col>
                    :
                    <div className="d-flex justify-content-center mt-2">
                        <Button wd fill
                            onClick={() => props.handleMultiAccountAdminModeChange(MultiAccountModes.MANAGE)}
                        >
                            {t("Manage Accounts")}
                        </Button>
                    </div>}
            </Row>
        )
    }

    if (mode === MultiAccountModes.INFO) {
        Cols.splice(Cols.length - 1, 1, {
            Header: 'Accessor',
            id: 'primary_admin_name',
            accessor: ({ primary_admin_name }) => <span className='text-capitalize'>{primary_admin_name}</span>,
        }) 
    }

    return (
        <GenericList
            columns={Cols}
            tableHeader={t('Accounts')}
            lastListLoadTime={mode === MultiAccountModes.INFO ? props.clientStore.lastListLoadTime :
                props.multiAccountAdminStore.lastListLoadTime}
            requestData={mode === MultiAccountModes.INFO ?
                () => props.clientStore.getmultipleAccountsForClient(props.clientId) : (params) => props.multiAccountAdminStore.getAccountListForAdmin(params)}
        >
            {mode === MultiAccountModes.MANAGE &&

                <Button fill wd
                    onClick={() => props.handleMultiAccountAdminModeChange(MultiAccountModes.ADD)}
                >
                    <i className="fa fa-plus-circle fa-xl margin-right-10" />
                    {t('Add')}
                </Button>
            }
        </GenericList>
    )

}

export default inject('commonStore', 'multiAccountAdminStore', 'clientStore', 'userStore')(applicationRouter(withLocalization(MultiAccountAdmin)));