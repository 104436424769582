import React, { Component } from 'react';
import config from '../../library/config';
import { inject, observer } from 'mobx-react';
import applicationRouter from '~/hoc/applicationRouter'
import withLocalization from '~/hoc/withLocalization';
import defaultAvatar from '../../assets/img/faces/face-0.jpg';


class UserProfileLogo extends Component {

    render() {
        const { userStore } = this.props;
        const width = this.props.width || "30px";
        const height = this.props.height || "30px";
        const borderRadius = this.props.borderRadius || "50px";
        const imageId = this.props.image_id || this.props.image_id === false ?
            this.props.image_id : userStore.currentUser.image;
        const custom_className = this.props.className || ''
        return (

            <img
                src={imageId ? `${config.API_ROOT}/attachments/${imageId}` : defaultAvatar}
                alt="userImg"
                className={`img-fluid ${custom_className}`}
                style={{
                    width,
                    height,
                    borderRadius
                }}
            />
        )
    }
}

export default inject('commonStore', 'userStore')(applicationRouter(withLocalization(observer(UserProfileLogo))));