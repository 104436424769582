import React from "react";
import "./styles.css";

function IconBox(props) {
  return (
    <div className={`iconBoxCol ${props.customClass} ${props.bgColor}`}>
      <div className={`iconBoxColIcon ${props.color}`}>
        <i><img src={props.src} alt={props.title} /></i>
      </div>
      <div className="iconBoxColInfo">
        <h4>{props.title}</h4>
        <p>{props.description}</p>
      </div>
    </div>   
  );
}
export default IconBox;