import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Col } from 'react-bootstrap';
import withLocalization from '../../hoc/withLocalization';
import FontPicker from "font-picker-react";

//components
import SignatureInitial from './';

//styles
import './signatureInitials.css';

// const options = [{value:'Pacifico',label:'Pacifico'},{value:'Hurricane',label:'Hurricane'},{value:'Dancing Script',label:'Dancing Script'},{value:'cursive',label:'Cursive'}];

export class SignatureInitialWrapper extends Component {

    constructor(props) {
        super(props);
        this.state = {
            //   font:'cursive',
            username: this.props.username,
            initials: this.props.initials,
            activeFontFamily: "Brush Script MT",
        }
    }

    handleImage = (image) => {
        this.props.onChange(image);
    }

    handleFontChange = (value) => {
        this.setState({ activeFontFamily: value });
    }

    render() {
        return (
            <div>
                <div className={'wrapper-row fontpickerSignature'}>
                    <FontPicker
                        families={['Pacifico', 'Hurricane', 'Dancing Script', 'Brush Script MT']}
                        // className={'fontPicker'}
                        apiKey="AIzaSyD3pmNTCQHmrGCROGgRlfVkANoAJiq8L6Q"
                        previews={true}
                        activeFontFamily={this.state.activeFontFamily}
                        onChange={(nextFont) =>
                            this.setState({
                                activeFontFamily: nextFont.family,
                            })
                        }
                    />
                </div>
                <div className={'wrapper-row'} >
                    <Col xs={12} className={'scrollX'}>
                        <SignatureInitial
                            className={'apply-font'}
                            initials={this.props.is_initials ? this.state.initials : this.state.username}
                            font={this.state.activeFontFamily}
                            onChange={this.handleImage} />
                    </Col>
                </div>
            </div>
        )
    }
}

export default inject('commonStore')(withLocalization(observer(SignatureInitialWrapper)));