import React, { Component } from 'react'
import _ from 'lodash';
import moment from "moment";
import { inject, observer } from 'mobx-react';
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import { confirmAlert } from 'react-confirm-alert';

import applicationRouter from '~/hoc/applicationRouter'
import ShiftForm from './ShiftForm';
import BulkShiftForm from './BulkShiftForm';
import CopyForm from './CopyForm';
import withLocalization from '~/hoc/withLocalization';
import withDnDContext from '~/hoc/withDnDContext';
import MonthCustomHeader from './MonthCustomHeader';
import MonthEventComponent from './MonthEventComponent';

//utils
import { getMinutesFromTimeInput } from '../../../utils/timeCalc';

//styles
import "react-big-calendar/lib/css/react-big-calendar.css";

const localizer = momentLocalizer(moment);
class MonthCalendar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editing: false,
            stub: null,
            bulkEditing: false,
            schedulerData: this.props.schedulerData,
            copying: false,
            showUserModal: false,
            userWithFutureShifts: [],
            start_date: '',
            end_date: ''
        }
        this.handleNavigate = this.handleNavigate.bind(this)
    }

    componentDidMount() {
        this.loadData();
    }

    async reloadData(scheduleData) {
        const { commonStore } = this.props;
        const dateTimeRules = commonStore.config.client && commonStore.config.client.data
            && commonStore.config.client.data.dateTimeRules ?
            commonStore.config.client.data.dateTimeRules : false;
        if (!scheduleData) scheduleData = this.state.schedulerData;
        const { scheduleStore } = this.props;
        const params = {
            start: scheduleData.startDate,
            end: scheduleData.endDate,
        };
        let data = await scheduleStore.loadList(params, dateTimeRules);
        data = JSON.parse(JSON.stringify(data));
        let getScheduleData = this.createPayload(data.list);
        this.setState({ schedulerData: getScheduleData })
        return scheduleData;
    }

    async loadData() {
        const { scheduleStore, commonStore } = this.props;
        const dateTimeRules = commonStore.config.client && commonStore.config.client.data
            && commonStore.config.client.data.dateTimeRules ?
            commonStore.config.client.data.dateTimeRules : false;
        const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
        const endOfMonth = moment().endOf('month').format('YYYY-MM-DD');
        const params = {
            start: startOfMonth,
            end: endOfMonth,
        };
        let data = await scheduleStore.loadList(params, dateTimeRules);
        data = JSON.parse(JSON.stringify(data));
        let scheduleData = this.createPayload(data.list);
        this.setState({ schedulerData: scheduleData, start_date: startOfMonth, end_date: endOfMonth });
    }

    closeEditingWindow = (doReload) => {
        this.setState({
            editing: false,
            stub: null,
        }, () => {
            if (doReload)
                this.reloadData();
        });
    };

    afterEditing = async (status) => {
        const { t, scheduleStore, commonStore, userStore } = this.props;
        const { currentUser } = userStore;
        if (status === 'cancel') {
            this.closeEditingWindow(false);
        }
        if (status === 'delete') {
            this.closeEditingWindow(false);
            confirmAlert({
                title: t('Confirm delete'),
                message: t('This will remove the schedule'),
                buttons: [
                    {
                        label: t('Yes'),
                        onClick: async () => {
                            await scheduleStore.remove(scheduleStore.currentEntity.id);
                            this.reloadData();
                        },
                    },
                    {
                        label: t('No')
                    },
                ],
            });
        }
        if (status === 'save') {
            try {
                await scheduleStore.save(scheduleStore.currentEntity, !scheduleStore.currentEntity.id);
                this.closeEditingWindow(true);
            } catch (e) {
                commonStore.addNotification(t(e.message || 'Error'), null, 'error');
            }
        }
        if (status === 'member_accept' || status === 'member_reject') {
            try {
                if (status === 'member_reject' && !scheduleStore.currentEntity.comment) {
                    throw new Error('Comment is required');
                }
                await scheduleStore.memberAction(status, currentUser);
                this.closeEditingWindow(true);
            } catch (e) {
                commonStore.addNotification(t(e.message || 'Error'), null, 'error');
            }
        }
    };

    createPayload(data) {
        const { userStore, scheduleStore } = this.props;
        const { currentUser } = userStore;
        const isMember = currentUser.user_type === 'member';
        let { resources } = scheduleStore;
        resources = _.filter(resources, (r) => !!r.id);
        const userOptions = resources.map((r) => {
            return { value: r.id, label: r.name };
        });
        let scheduleMonthData = [];
        data.forEach(element => {
            let tempPayload;
            let checkSource = element.title.concat(', \n', element.taskName);
            if (isMember) {
                if (element.source === 'vacation') {
                    checkSource = 'Vacation'
                }
                if (element.source === 'absence') {
                    checkSource = 'Absent'
                }
                tempPayload = {
                    'addBonus': element.addBonus,
                    'bgColor': element.bgColor,
                    'title': checkSource,
                    'id': element.id,
                    'resourceId': element.resourceId,
                    'start': element.start,
                    'end': element.end,
                    'workLogStatus': element.workLogStatus,
                    'source': element.source,
                }
            }
            else {
                let getName = userOptions.filter(function (n) {
                    return element.resourceId === n.value;
                })

                checkSource = element.title.concat(', \n', getName[0] ? getName[0].label : 'Pending', ', \n', element.taskName);
                if (element.source === 'vacation') {
                    checkSource = 'Vacation'.concat(', \n', getName[0] ? getName[0].label : '');
                }
                if (element.source === 'absence') {
                    checkSource = 'Absent'.concat(', \n', getName[0] ? getName[0].label : '');
                }

                tempPayload = {
                    'addBonus': element.addBonus,
                    'bgColor': element.bgColor,
                    'title': checkSource,
                    'taskName': element.taskName,
                    'id': element.id,
                    'resourceId': element.resourceId,
                    'start': element.start,
                    'end': element.end,
                    'workLogStatus': element.workLogStatus,
                    'source': element.source,
                    'name': getName[0] ? getName[0].label : 'Pending'
                }
            }
            scheduleMonthData.push(tempPayload)
        });
        return scheduleMonthData;
    }

    handleSelectedEvent = (event) => {
        if (typeof event.id === 'number') {
            this.setState({ editing: event.id, stub: event });
        }
    }


    handleSelectSlot = (event) => {
        const clientConfig = this.props.commonStore.config.client.data;
        let from = getMinutesFromTimeInput(clientConfig.basicRules.startTimeRules.start);
        let to = getMinutesFromTimeInput(clientConfig.basicRules.startTimeRules.end);
        this.setState({
            editing: 0,
            stub: {
                for_date: event.start,
                start_time: from,
                end_time: to,
                assignee_id: 0
            },
        });

    }

    onViewChange = (e) => {
        const { onViewChange, schedulerData } = this.props;
        const viewType = e.value;
        const isEventPerspective = e.value === 1;
        onViewChange(schedulerData, { viewType, isEventPerspective });
    };

    async handleNavigate(date) {
        const { scheduleStore, commonStore } = this.props;
        const startOfMonth = moment(date).startOf('month').format('YYYY-MM-DD');
        const endOfMonth = moment(date).endOf('month').format('YYYY-MM-DD');
        const dateTimeRules = commonStore.config.client && commonStore.config.client.data &&
            commonStore.config.client.data.dateTimeRules ? commonStore.config.client.data.dateTimeRules : false;
        const params = {
            start: startOfMonth,
            end: endOfMonth,
        };
        let data = await scheduleStore.loadList(params, dateTimeRules);
        data = JSON.parse(JSON.stringify(data));
        let scheduleData = this.createPayload(data.list);
        this.setState({ schedulerData: scheduleData })
    }

    newBulkEvent = () => {
        const clientConfig = this.props.commonStore.config.client.data;
        let from = getMinutesFromTimeInput(clientConfig.basicRules.startTimeRules.start);
        let to = getMinutesFromTimeInput(clientConfig.basicRules.startTimeRules.end);
        this.setState({
            bulkEditing: 0,
            stub: {
                start_time: from,
                end_time: to,
            },
        });
    };

    afterBulkEditing = async (status, values) => {
        const { t, scheduleStore, commonStore } = this.props;
        if (status === 'cancel') {
            this.setState({
                bulkEditing: false,
                stub: null,
            });
        }
        if (status === 'save') {
            try {
                await scheduleStore.addBulkShift(values);
                this.setState({
                    bulkEditing: false,
                    stub: null,
                }, () => {
                    this.reloadData();
                });
            } catch (e) {
                commonStore.addNotification(t(e.message || 'Error'), null, 'error');
            }
        }
    };

    handleBulkEvent(props) {
        const { bulkEvent } = props;
        bulkEvent();
    }

    startCopyToNextMonth() {
        this.setState({ copying: true });
    }

    render() {
        const { editing, stub, bulkEditing, copying } = this.state;
        const { schedulerData, userStore, commonStore } = this.props;
        const { currentUser } = userStore;
        const isMember = currentUser.user_type === 'member';
        return (
            <div className="customize_calendar">
                <Calendar
                    localizer={localizer}
                    culture={
                        commonStore && commonStore.locale
                            ? commonStore.locale === 'no' ? 'nb' : commonStore.locale
                            : 'en'
                    }
                    events={this.state.schedulerData}
                    defaultView={Views.MONTH}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: 600 }}
                    selectable
                    scrollToTime={new Date(1970, 1, 1, 6)}
                    defaultDate={new Date()}
                    onDoubleClickEvent={event => this.handleSelectedEvent(event)}
                    onSelectSlot={event => this.handleSelectSlot(event)}
                    popup //show popup
                    onNavigate={this.handleNavigate}
                    components={{
                        toolbar: props => (<MonthCustomHeader {...props} handleBulkEvent={this.newBulkEvent} t={this.props.t} onViewChange={(e) => this.onViewChange(e)} isMember={isMember} handleCopyMonth={() => this.startCopyToNextMonth(this.props)} />),
                        event: (props) => <MonthEventComponent {...props} schedulerData={this.state.schedulerData} />
                    }}
                    eventPropGetter={
                        (event, start, end, isSelected) => {
                            let newStyle = {
                                backgroundColor: "#fff",
                                color: 'black',
                                borderRadius: "0px",
                                border: "none"
                            };

                            if (isSelected) {
                                newStyle.backgroundColor = "white"
                            }
                            return {
                                className: isSelected ? 'selected_event' : 'rbc-event-content',
                                style: newStyle
                            };
                        }
                    }
                />
                {editing !== false && (
                    <ShiftForm
                        id={editing}
                        add={editing === 0}
                        stub={stub}
                        finish={(status) => this.afterEditing(status)}
                        isAvailibiltyFeatureEnabled={this.props.isAvailibiltyFeatureEnabled}
                        currentView={0}
                    />
                )}
                {bulkEditing !== false && (
                    <BulkShiftForm
                        id={bulkEditing}
                        add={bulkEditing === 0}
                        stub={stub}
                        viewModel={schedulerData}
                        start_date={this.state.start_date}
                        end_date={this.state.end_date}
                        finish={(status, obj) => this.afterBulkEditing(status, obj)}
                    />

                )}
                {copying !== false && (
                    <CopyForm finish={() => this.setState({ copying: false })} />
                )}
            </div>
        )
    }
}

export default inject('userStore', 'commonStore', 'scheduleStore')(withLocalization(applicationRouter(withDnDContext(observer(MonthCalendar)))));