import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

//components
import applicationRouter from '../../../hoc/withLocalization';
import withLocalization from '../../../hoc/withLocalization';
import Button from '../../../components/CustomButton/CustomButton.jsx';
import SwitchWidget from '../../../containers/Admin/ControlPanel/SwitchWidget.js';

//elements
import LoadingSpinner from '../../../elements/LoadingSpinner.js'

import { permissionsForShifts } from '../../../utils/constants.js';
import { checkCircleSVG, crossCircleSVG, saveSVG, editSVG, backSVG } from '../../../utils/constants';

const ProjectMemberRules = inject('commonStore', 'projectStore')(applicationRouter(withLocalization(observer(class InternalProjectMemberRules extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            projectManagers: [],
            currentId: null,
            currentManagerPermissions: null
        }
    }

    componentDidMount() {
        const { currentEntity } = this.props.projectStore;
        if (!currentEntity || !currentEntity.project || !currentEntity.project.id) return;
        this.setState({ loading: true })
        this.loadData(currentEntity.project.id);
        if (this.props.onChange) this.props.onChange(null);
    }

    componentWillUnmount = () => {
        this.resetCurrentPermission();
    }

    handlePermissonChange = (permissionName, value) => {
        const { currentManagerPermissions } = this.state;
        if (!currentManagerPermissions) return;
        currentManagerPermissions.permissions[permissionName] = value;
        this.setState({ currentManagerPermissions });
    }

    handleEdit = (pm_id, permissions) => {
        const { currentEntity } = this.props.projectStore;
        const _currentManagerPermissions = {
            project_id: currentEntity.project.id,
            pm_id: pm_id,
            permissions: { ...permissions }
        }
        this.setState({ currentId: pm_id, currentManagerPermissions: _currentManagerPermissions })
    }

    handleSave = () => {
        this.setState({ loading: true }, this.updateUserPermissions);
    }

    updateUserPermissions = async () => {
        const { projectStore, commonStore, t } = this.props;
        const { currentEntity } = projectStore;
        const { currentManagerPermissions } = this.state;
        try {
            await projectStore.setProjectPmShiftPermissions(currentManagerPermissions);
            this.loadData(currentEntity.project.id);
        }
        catch (err) {
            commonStore.addNotification(t('Something went wrong'), null, 'error');
            this.setState({ loading: false });
        }
    }

    resetCurrentPermission = () => {
        this.setState({ currentManagerPermissions: null, currentId: null });
    }

    async loadData(project_id) {
        const { projectStore, commonStore, t } = this.props
        try {
            const { project_managers } = await projectStore.getProjectPmShiftPermissions(project_id);
            this.setState({ projectManagers: project_managers, loading: false, currentId: null, currentPermission: null })
        }
        catch (err) {
            commonStore.addNotification(t('Something went wrong'), null, 'error');
            this.setState({ loading: false, currentId: null, currentPermission: null });
        }
    }

    render() {
        const { projectManagers, currentId, currentManagerPermissions, loading } = this.state;
        const { t, projectStore } = this.props;
        const { currentEntity } = projectStore;
        if (!currentEntity || !currentEntity.project || !currentEntity.project.id) return <div className='py-5 font-size-15 fw-bold '>{t('Please create project first and assign members and then give access to manage the project')}</div>
        if (loading) return <LoadingSpinner />;
        return (
            <div className="table-responsive">

                <table className='table'>
                    <thead>
                        <tr>
                            <th>{t('Manager')}</th>
                            {permissionsForShifts.map((ele) => {
                                return <th key={`pm_rule${ele.label}`}>{t(ele.label)}
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip-top">
                                                {t(ele.description)}.
                                            </Tooltip>
                                        }
                                    >
                                        <i className="fa fa-info-circle text-muted" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                                    </OverlayTrigger></th>
                            })}
                            <th>{t('Actions')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {projectManagers && projectManagers.length ? projectManagers.map((ele, index) => (
                            <tr key={`md_0_${index}`} className='text-center'>
                                <td>{t(ele.full_name)}</td>
                                {permissionsForShifts.map((permissions, index) => (
                                    <th key={`permi_0_${index}`} style={{ borderBottom: '0' }}>
                                        {currentId && currentId === ele.pm_id ?
                                            <SwitchWidget
                                                value={currentManagerPermissions.permissions[permissions.value]}
                                                onChange={checked => this.handlePermissonChange(permissions.value, checked)}
                                            />
                                            :
                                            ele.permissions[permissions.value] ? checkCircleSVG({ width: 20, fillColor: '#16C51D' })
                                                : crossCircleSVG({ width: 20, fillColor: '#FF0000' })
                                        }
                                    </th>
                                ))}
                                <th style={{ borderBottom: '0' }}>
                                    {currentId && currentId === ele.pm_id ?
                                        <>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={<Tooltip id="tooltip-top">{this.props.t('Update Permissions')}</Tooltip>}
                                            >
                                                <Button icon_sm fill
                                                    onClick={this.handleSave}
                                                >
                                                    {saveSVG({ width: 25, fillColor: '#2550AC' })}
                                                </Button>
                                            </OverlayTrigger>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={<Tooltip id="tooltip-top">{this.props.t('Cancel')}</Tooltip>}
                                            >
                                                <Button icon_sm fill ms2
                                                    onClick={this.resetCurrentPermission}
                                                >
                                                    {backSVG({ width: 25, fillColor: '#2550AC' })}
                                                </Button>
                                            </OverlayTrigger>
                                        </>
                                        :
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip id="tooltip-top">{this.props.t(
                                                currentEntity && currentEntity.project && currentEntity.project.assignee_id
                                                    && currentEntity.project.assignee_id === ele.pm_id ? 'Cannot update Project Manager access'
                                                    : 'Change Permissions')}</Tooltip>}
                                        >
                                            <Button icon_sm fill
                                                disabled={!!currentId || (currentEntity && currentEntity.project && currentEntity.project.assignee_id && currentEntity.project.assignee_id === ele.pm_id)}
                                                onClick={() => this.handleEdit(ele.pm_id, ele.permissions)}>
                                                {editSVG({ width: 25, fillColor: '#2550AC' })}
                                            </Button>
                                        </OverlayTrigger>
                                    }
                                </th>
                            </tr>
                        ))
                            :
                            <tr className='text-center'>
                                <td colSpan={3}>
                                    <span className='font-size-15 fw-bold text-muted'>{t('No data found')}</span>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div >
        );
    }
}))));


export const ProjectMemberRulesWidget = observer(class ProjectMemberRulesWidget extends Component {
    render() {
        return <ProjectMemberRules {...this.props} />
    }
})