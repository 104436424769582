import React, { Component, Fragment } from 'react';
import { PropTypes } from 'prop-types';

class BodyView extends Component {
    constructor(props) {
        super(props);
    }

    static propTypes = {
        schedulerData: PropTypes.object.isRequired,
    };

    render() {
        const { schedulerData, filteredRenderData, t } = this.props;
        const { headers, config, behaviors } = schedulerData;
        const cellWidth = schedulerData.getContentCellWidth();

        const tableRows = filteredRenderData.length > 0 ?
            filteredRenderData.map((item) => {
                const rowCells = headers.map((header, index) => {
                    const key = `${item.slotId}_${header.time}`;
                    let style = index === headers.length - 1 ? {} : { width: cellWidth };
                    if (header.nonWorkingTime) style = { ...style, backgroundColor: config.nonWorkingTimeBodyBgColor };
                    if (item.groupOnly) style = { ...style, backgroundColor: config.groupOnlySlotColor };
                    if (behaviors.getNonAgendaViewBodyCellBgColorFunc) {
                        const cellBgColor = behaviors.getNonAgendaViewBodyCellBgColorFunc(
                            schedulerData,
                            item.slotId,
                            header
                        );
                        if (cellBgColor) style = { ...style, backgroundColor: cellBgColor };
                    }
                    if (`${item.slotId}` == '0' || `${item.slotId}` == 'Pending') {
                        style.backgroundColor = '#F2F5FB';
                    }
                    
                    return (
                        <td key={key} style={style}>
                            <div />
                        </td>
                    );
                });

                let { rowHeight } = item;
                return (
                    <Fragment key={item.slotId}>
                        <tr key={item.slotId} className={`tr-${item.slotId}`} style={{ height: rowHeight }}>
                            {rowCells}
                        </tr>
                    </Fragment>
                );
            })
            : <tr><td className='text-danger fw-600 pt-3 fs-5'>{t('No data found')}</td></tr>;

        return <tbody>{tableRows}</tbody>;
    }
}

export default BodyView;
