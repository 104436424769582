import React, { Component } from 'react';
import applicationRouter from '~/hoc/applicationRouter'
import { observer, inject } from 'mobx-react';
import withLocalization from '../../../hoc/withLocalization';
import { ReactComponent as SuccessIcon } from '../../../assets/img/success.svg';
import { Button } from 'react-bootstrap';
export class DocSuccess extends Component {
    componentDidMount() {
        const { signatureStore } = this.props;
        signatureStore.openFullScreenMode();
    }
    componentWillUnmount() {
        this.props.signatureStore.closeFullScreenMode();
        this.props.router.navigate("/admin/cases");
    }

    backToHome(){
        const { signatureStore } = this.props;
        sessionStorage.removeItem('CaseId');
        signatureStore.closeFullScreenMode()
        signatureStore.resetDocList();
        this.props.router.navigate("/admin/cases");
    }
    render() {
        const {t} = this.props;
        return (
            <div className="successPage">
                <SuccessIcon />
                <h1>{t('Your Document Is On Its Way.')}</h1>
                <p>{t('Its your turn to sign.')}</p>
                <Button className='btn btn-fill' onClick={()=>{ this.backToHome() }}>
                    {t('Back To Home')}
                </Button>
            </div>
        );
    }
}

export default inject('signatureStore')(applicationRouter(withLocalization(observer(DocSuccess))));
