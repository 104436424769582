import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

//HOCs and components
import applicationRouter from '~/hoc/applicationRouter'
import withLocalization from '../../../hoc/withLocalization';
import Button from '../../../components/CustomButton/CustomButton.jsx';
import ScheduleManagementCard from './ScheduleManagementCard.js';
import Select from '../../../elements/Select.js';
import LoadingSpinner from '../../../elements/LoadingSpinner';

//utils
import { shortDateFormat } from '../../../utils/dateFormat';

class ShiftSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shiftRequestData: null,
            scheduleManagementData: [],
            reqType: [
                {
                    value: "all",
                    label: "All"
                },
                {
                    value: "sick-leave",
                    label: "Sick Leave"
                },
                {
                    value: "trade-shift",
                    label: "Swap"
                },
                {
                    value: "swap-shift",
                    label: "Trade"
                },
                {
                    value: "grab-shift",
                    label: "Grab"
                },
            ],
            statusType: [
                {
                    value: "all",
                    label: "All"
                },
                {
                    value: "pending",
                    label: "Pending"
                },
                {
                    value: "approved",
                    label: "Approved"
                },
                {
                    value: "rejected",
                    label: "Rejected"
                },
            ],
            isLoading: true,
            curReqType: {
                value: "all",
                label: "All"
            },
            curStatusType: {
                value: "all",
                label: "All"
            }
        }
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        const params = this.getParamsFromState();
        let swapRequest = null;
        swapRequest = this.props.scheduleStore.getSwapShiftRequestsForPM(params);

        if (swapRequest) {
            swapRequest.then((res) => {
                if (res) {
                    this.setState({ scheduleManagementData: res.rows }, () => {
                        this.setState({ isLoading: false });
                    });
                }
            }).catch(err => {
                console.error(err);
                this.setState({ isLoading: false });
            })
        }
    }

    getParamsFromState = () => {
        const { curReqType, curStatusType } = this.state;
        const params = {};
        if(curReqType.value !== 'all') params[curReqType.value.replace('-','_')] = true;
        if(curStatusType.value !== 'all') params[curStatusType.value] = true;
        return params;
    }

    acceptOrRejectSwapShiftRequest = (status) => {
        if (status === 'accept' || status === 'reject') {
            this.setState({ isLoading: true }, () => {
                this.loadData();
            });
        }
    }

    approveSwapShiftAcceptedRequest = (status, swap_id, shift_id, toUser, swap_type, from_user_id, swap_history_id) => {
        if (swap_type !== 'trade-shift') {
            this.setState({ isLoading: true }, () => {
                this.props.scheduleStore.approveOrRejectSwapShiftRequestsFromPM({ swap_id: swap_id, status: status, shift_id: shift_id, toUser: toUser, swap_history_id: swap_history_id }).then(res => {
                    this.loadData();
                }).catch(err => {
                    console.error(err);
                });
            })
        }
        else {
            this.setState({ isLoading: true }, () => {
                this.props.scheduleStore.approveOrRejectTradeShiftRequestsFromPM({ swap_id: swap_id, status: status, from_shift_id: shift_id, toUser: toUser, from_user_id: from_user_id, swap_history_id: swap_history_id }).then(res => {
                    this.loadData();
                }).catch(err => {
                    console.error(err);
                })
            })
        }
    }

    renderTableActionButtons = (full) => {
        const { t, userStore } = this.props;
        const { currentUser } = userStore;
        const isMember = currentUser.user_type === 'member';
        const isPM = currentUser.user_type === 'pm' || currentUser.user_type === 'admin';
        const isRejected = full.rejected_by_users && full.rejected_by_users.includes(currentUser && currentUser.id)
        const isAccepted = full.is_accepted
        return (
            <>
                {(full.swap_status === 'pending-swap') && isMember && !isRejected &&
                    <>
                        <Button btn_reject nb onClick={() => this.acceptOrRejectSwapShiftRequest('reject', full.swap_id, full.shift_id)}>{t('Decline')}</Button>
                        <Button fill nb onClick={() => this.acceptOrRejectSwapShiftRequest('accept', full.swap_id, full.shift_id)}>{t('Accept')}</Button>
                    </>
                }
                {full.swap_status === 'swap-accepted' && isMember && isAccepted &&
                    <Button fill nb style={{ background: '#FFC800' }}>
                        <i className="fa fa-clock" /> {t('Awaiting')}
                    </Button>
                }
                {full.swap_status === 'swap-accepted' && isMember && !isAccepted &&
                    <>
                        <Button btn_reject nb
                            onClick={() => this.acceptOrRejectSwapShiftRequest('reject', full.swap_id, full.shift_id)}>{t('Decline')}</Button>
                        <Button fill nb
                            onClick={() => this.acceptOrRejectSwapShiftRequest('accept', full.swap_id, full.shift_id)}>{t('Accept')}</Button>

                    </>
                }
                {full.swap_status === 'swap-accepted' && isPM &&
                    <>
                        <Button btn_reject nb
                            onClick={() => this.approveSwapShiftAcceptedRequest('rejected', full.swap_id, full.shift_id, full.accepted_id, full.swap_type, full.from_user_id, full.swap_history_id)}>{t('Decline')}</Button>
                        <Button fill nb
                            onClick={() => this.approveSwapShiftAcceptedRequest('approved', full.swap_id, full.shift_id, full.accepted_id, full.swap_type, full.from_user_id, full.swap_history_id)}>{t('Accept')}</Button>

                    </>
                }
            </>
        );
    };


    onRequestTypeChange = (evt) => {
        this.setState({ isLoading: true, curReqType:evt }, () => {
            this.loadData();
        });
    }
    onStatusTypeChange = (evt) => {
        this.setState({ isLoading: true, curStatusType: evt }, () => {
            this.loadData();
        });
    }

    render() {

        const { t, commonStore } = this.props;
        const { reqType, statusType, isLoading, curReqType, curStatusType } = this.state;
        if (isLoading) return <LoadingSpinner />;

        const dateTimeRules = commonStore.config.client && commonStore.config.client.data && commonStore.config.client.data.dateTimeRules ?
            commonStore.config.client.data.dateTimeRules : false;

        const overtimeCalcPeriod = commonStore.config.client && commonStore.config.client.data && commonStore.config.client.data.basicRules
            && commonStore.config.client.data.basicRules.overtimeCalcRules && commonStore.config.client.data.basicRules.overtimeCalcRules.calc_period ?
            commonStore.config.client.data.basicRules.overtimeCalcRules.calc_period : "";
        return (
            <>
                <div className="d-flex justify-content-end">
                    <div>
                        <label>{t('Status')}</label>
                        <Select options={statusType} value={curStatusType} onChange={event => this.onStatusTypeChange(event)} style={{ minWidth: '120px' }} />
                    </div>
                    <div className='ml-10'>
                        <label>{t('Type')}</label>
                        <Select options={reqType} value={curReqType} onChange={event => this.onRequestTypeChange(event)} style={{ minWidth: '120px' }} />
                    </div>
                </div>
                {this.state.scheduleManagementData.length < 1 && (<div className="d-flex justify-content-center no-data-tag">
                        {t('No data found')}
                    </div>)
                }
                {
                    this.state.scheduleManagementData.map((data, indx) => {
                        return (

                            <>
                                {indx > 0 ? this.state.scheduleManagementData[indx - 1].shift_date !== data.shift_date ?
                                    <>
                                        <div className='shftListTag'>Date {shortDateFormat(data.shift_date, dateTimeRules)}</div>
                                    </>
                                    : '' :
                                    <>
                                        <div className='shftListTag'>Date {shortDateFormat(data.shift_date, dateTimeRules)}</div>
                                    </>
                                }
                                <ScheduleManagementCard key={data.swap_id} renderTableActionButtons={this.renderTableActionButtons} scheduleData={data} dateTimeRules={dateTimeRules} t={t} overtimeCalcPeriod={overtimeCalcPeriod} />
                            </>
                        )
                    })
                }
            </>

        );
    }
}

export default inject('commonStore', 'scheduleStore', 'userStore')(applicationRouter(withLocalization(observer(ShiftSettings))));
