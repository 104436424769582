import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Container, Row, Col, Form, Button } from "react-bootstrap";

//components
import withLocalization from '~/hoc/withLocalization';
import applicationRouter from '~/hoc/applicationRouter'
import Header from '../../../newComponents/Header';
import Footer from '../../../newComponents/Footer';
import PageBanner from '../../../newComponents/PageBanner';
import MainHeading from '../../../newComponents/MainHeading';

//elements
import LoadingSpinner from '~/elements/LoadingSpinner';


//styles
import "./styles.css";

//assets
import BannerImg from "../../../assets/images/timeAndRegisterImg.png";
import SubmitIcon from "../../../assets/images/submitIcon.svg";

export class PasswordSetup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLinkValid: true,
            isLoading: true,
            isPasswordAlreadySet: true,
            errorText: {
                securePassword: "",
                confirmSecurePassword: ""
            },
            securePassword: "",
            confirmSecurePassword: "",
            token: "",
            isLoginClicked: false
        };
    }

    componentDidMount() {
        this.loadData();
    }

    async loadData() {
        try {
            const search = this.props.router.location.search;
            if (search.trim() === '') {
                window.location.href = window.location.origin;
            } else {
                const encryptedData = new URLSearchParams(search).get('id');
                const response = await this.props.userStore.checkIfValidLink(encryptedData);
                if (response.message && response.message === "Password already set") {
                    this.setState({ isLinkValid: true, isPasswordAlreadySet: true, isLoading: false });
                } else {
                    this.setState({ isLinkValid: true, isPasswordAlreadySet: false, isLoading: false, token: response.token });
                }
            }
        } catch (err) {
            this.setState({ isLinkValid: false, isLoading: false });
            this.props.commonStore.addNotification(this.props.t(err || 'Error'), null, 'error');
        }
    }

    handleFormChange = (event) => {
        const _errorText = { ...this.state.errorText };
        if (event.target.value.trim() === '') {
            _errorText[event.target.name] = "Can't be blank";
        } else {
            _errorText[event.target.name] = "";
        }
        this.setState({ [event.target.name]: event.target.value, errorText: _errorText })
    }

    verifyPassword = (field) => {
        let _errorText = { ...this.state.errorText };
        if (this.state[field].trim() === '') {
            _errorText[field] = "Can't be blank";
        }
        else if (this.state.securePassword !== this.state.confirmSecurePassword) {
            _errorText['confirmSecurePassword'] = "Passwords do not match";
        }
        this.setState({ errorText: _errorText })
        return true;
    }

    setupPassword = async () => {
        try {
            await this.props.userStore.setSecurePassword({
                password: this.state.securePassword,
                token: this.state.token
            }).then((res) => {
                if (res.message === "Password set successfully") {
                    this.setState({ isPasswordAlreadySet: true, isLoading: false });
                }
            })
        }
        catch (err) {
            this.props.commonStore.addNotification(this.props.t(err || 'Error'), null, 'error');
        }
    }

    submitForm = async (e) => {
        try {
            e.preventDefault();
            const _errorText = { ...this.state.errorText };
            if (this.state.securePassword.trim() === '') {
                _errorText['securePassword'] = "Can't be blank";
                this.setState({ errorText: _errorText })
                return;
            }
            if (this.state.confirmSecurePassword.trim() === '') {
                _errorText['confirmSecurePassword'] = "Can't be blank";
                this.setState({ errorText: _errorText })
                return;
            }
            if (this.state.securePassword !== this.state.confirmSecurePassword) {
                _errorText['confirmSecurePassword'] = "Passwords do not match";
                this.setState({ errorText: _errorText })
                return;
            }
            this.setState({ isLoading: true }, this.setupPassword);
        }
        catch (err) {
            this.props.commonStore.addNotification(this.props.t(err || 'Error'), null, 'error');
        }
    }

    renderForm = () => {
        const { t } = this.props;
        const { errorText, securePassword, confirmSecurePassword, isPasswordAlreadySet, isLoading, isLinkValid } = this.state;
        if (isLoading) return <LoadingSpinner />

        if(!isLinkValid) {
            return (
                <div className="text-center text-danger">
                    <p>{t("This link is not valid, please retry with valid link")}.</p>
                </div>
            )
        }

        if (isPasswordAlreadySet) {
            return (
                <div className="text-center">
                    <p>{t("Thanks for setting up your password, please login and start exploring")}.</p>
                    <div className="formBtn text-center">
                        <Button className="btnCommon btnBig" variant="primary"
                            onClick={() => this.setState({ isLoginClicked: true })}>
                            <i className="d-inline-block pe-2">
                                <img src={SubmitIcon} alt="Submit" />
                            </i> {
                                t("Login")}
                        </Button>
                    </div>
                </div>
            )
        }

        return (
            <>
                <p>{t("To ensure the security of your account, please take a moment to set up a strong and secure password")}.</p>
                <Row>
                    <Col xs={12} md={7}>
                        <Form onSubmit={this.submitForm} autoComplete='off'>
                            <Form.Group className="mb-4" controlId="formPasswordSetupName">
                                <Form.Label>{t("Password")}*</Form.Label>
                                {errorText['securePassword'] &&
                                    <span className='error-block'>{t(errorText['securePassword'])}</span>
                                }
                                <Form.Control
                                    type="password"
                                    value={securePassword}
                                    name="securePassword"
                                    placeholder={t("Please enter a strong password")}
                                    onChange={this.handleFormChange}
                                    onBlur={() => this.verifyPassword("securePassword")}
                                    autoComplete='off' required />
                            </Form.Group>
                            <Form.Group className="mb-4" controlId="formContactMobileNumber">
                                <Form.Label>{t("Confirm password")}*</Form.Label>
                                {errorText['confirmSecurePassword'] &&
                                    <span className='error-block'>{t(errorText['confirmSecurePassword'])}</span>
                                }
                                <Form.Control
                                    type="password"
                                    value={confirmSecurePassword}
                                    name='confirmSecurePassword'
                                    placeholder={t("Please re-enter your password")}
                                    onChange={this.handleFormChange}
                                    onBlur={() => this.verifyPassword("confirmSecurePassword")}
                                    autoComplete='off' required />
                            </Form.Group>
                            <div className="formBtn text-center">
                                <Button className="btnCommon btnBig" variant="primary" type="submit"><i className="d-inline-block pe-4"><img src={SubmitIcon} alt="Submit" /></i> {t("Submit")}</Button>
                            </div>
                        </Form>
                    </Col>
                    <Col className='mt-4 tips-container'>
                        <div>
                            <p>{t("Tips")}</p>
                            <ul>
                                <li>{t('At least 8 characters')}</li>
                                <li>{t('Use unique passwords')}</li>
                                <li>{t('Use both upper and lower case')}</li>
                                <li>{t('Avoid common patterns')}</li>
                                <li>{t('Consider alphanumeric or a passphrase')}</li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </>
        )
    }


    render() {
        const { t } = this.props;
        return (
            <div className="landingPageWrapp frameworkApp">
                <Header isLoginClicked={this.state.isLoginClicked} closeModalCallBack={() => this.setState({ isLoginClicked: false })}/>
                <article className="passwordUpdateBlock grdntBg lp_wrapper">
                    <Container>
                        <Row className="align-items-center mt-5">
                            <Col lg={{ order: 2, span: 8 }} xl={7} xxl={6}>
                                <div className="cntctForm commonForm form-container">
                                    <MainHeading
                                        title={<>{t("Welcome")} {t("To")} <span>{t("Time & Control")}</span></>}
                                        customClass="text-center medium noPara"
                                    />
                                    {this.renderForm()}
                                </div>
                            </Col>
                            <Col lg={{ order: 1, span: 4 }} xl={5} xxl={6} className='banner-container'>
                                <PageBanner
                                    src={BannerImg}
                                    alt="Time Register"
                                />
                            </Col>
                        </Row>
                    </Container>
                </article>
                <Footer />
            </div>
        );
    }
}

export default inject('commonStore', 'userStore')(applicationRouter(withLocalization(observer(PasswordSetup))));