import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import LoadingSpinner from '../../../elements/LoadingSpinner';
import withLocalization from '~/hoc/withLocalization';
import applicationRouter from '~/hoc/applicationRouter'
import * as timeUtils from '../../../utils/dateFormat';
import _ from 'lodash'

export class MonitoringTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isTransformed: false,
            activeId: ""
        }
    }

    addTable = () => {
        const { monitorStore } = this.props;
        const meta = monitorStore.tableMeta;
        let tableData = {};
        for (let x = 1; x < meta.products.length; x++) {
            tableData[x] = [];
            for (let y = 0; y < meta.items.length; y++) {
                // tableData[x].push('');
                tableData[x].push({
                    value: '',
                    userId: 0,
                    uname: '',
                    cellTime: ''

            });
            }
        }
        if (Object.keys(tableData).length) {
            tableData.comment = ""
            tableData.meta = meta
            monitorStore.setTableListData([...monitorStore.tableListData, tableData])
            // this.setState({ tableListData: [...this.state.tableListData, tableData] })
        }
        this.setState({ isTransformed: true });
    }
    deleteTable = (index) => {
        const { monitorStore } = this.props;
        const tableListData = [...monitorStore.tableListData];
        tableListData.splice(index, 1);
        monitorStore.setTableListData(tableListData)
        // this.setState({ tableListData:  });
    }

    handleComment(evt, tabIndex) {
        const { monitorStore } = this.props;
        const tableData = [...monitorStore.tableListData]
        if (evt.type === 'blur') {
            tableData[tabIndex].comment = evt.target.value;
        } else if (evt.type === 'keydown') {
            tableData[tabIndex].comment = evt.target.value;
        }
        monitorStore.setTableListData(tableData)
        // this.setState({ tableListData: tableData });
    }
    handleDataChange(evt, tabIndex, productIndex, itemIndex) {
        const { monitorStore, userStore } = this.props;
        const tableData = [...monitorStore.tableListData]
        const user = userStore.currentUser;
        const uname =  user.first_name + " " + user.last_name;
        if (evt.type === 'blur') {
            if (itemIndex) {
                if (!evt.target.value) {
                    this.setState({ activeId: '' })
                } else {
                    tableData[tabIndex][productIndex][itemIndex].value = evt.target.value;
                    tableData[tabIndex][productIndex][itemIndex].uname = uname;
                    tableData[tabIndex][productIndex][itemIndex].uid = user.id;
                    monitorStore.setTableListData(tableData)
                    this.setState({ activeId: '' })
                }
            }
        } else if (evt.type === 'focus') {
            let clockTime = new Date();
            clockTime = (clockTime.getHours() * 60) + clockTime.getMinutes();
            tableData[tabIndex][productIndex][0] = clockTime;
            tableData[tabIndex][productIndex][itemIndex].cellTime = clockTime;
            monitorStore.setTableListData(tableData)
            // this.setState({ tableListData: tableData})

        } else if (evt.type === 'keydown') {
            if (evt.key === "Enter") {
                if (!evt.target.value) {
                    this.setState({ activeId: '' })
                } else {
                    tableData[tabIndex][productIndex][itemIndex].value = evt.target.value;
                    monitorStore.setTableListData(tableData)
                    this.setState({ activeId: '' })
                }
            }
        }
    }
    async transformDataToNewFormate() {
        const { monitorStore } = this.props;
        const meta = {...monitorStore.tableMeta}
        const tableListData = _.cloneDeep(monitorStore.tableListData);
        for(let i = 0; i < tableListData.length; i++){ 
           for(let x = 1; x < tableListData[i].meta.products.length; x++){
                if(monitorStore.deleteProductIndex.has(x)){
                  delete tableListData[i][x] 
                }
             for(let y = 0; y < tableListData[i].meta.items.length; y++){
                if(tableListData[i].hasOwnProperty(x) && monitorStore.deleteItemIndex.has(y)){

                    tableListData[i][x].splice(y,1)
                }
             }
           }
           tableListData[i].meta = {...monitorStore.tableMeta}
        }
        for(let i = 0; i < tableListData.length; i++){
            for (let x = 1; x < meta.products.length; x++) {
                   if(!tableListData[i].hasOwnProperty(x)){
                       tableListData[i][x] = []
                   }
                for (let y = 0; y < meta.items.length; y++) {
                    if(tableListData[i][x][y] === undefined){
                        tableListData[i][x][y] = '';
                    }
                }
            }
        }
       
        monitorStore.setTableListData(tableListData);
        await this.props.onGridSave('init');
        this.setState({ isTransformed: true });
        
    }
    setActiveId(tabIndex, productIndex, itemIndex) {
        const id = "" + tabIndex + productIndex + itemIndex;
        this.setState({ activeId: id });
    }
    componentDidMount() {
        const { monitorStore } = this.props;
        if (!monitorStore.tableListData.length) {
            this.addTable()
        } else {
            this.transformDataToNewFormate()
        }
    }

    componentWillUnmount() {
        const { monitorStore } = this.props;
        monitorStore.resetCurrentGridDataId()
        monitorStore.setTableListData([]);
        monitorStore.resetDeleteIndex();
    }
    render() {
        const { monitorStore, commonStore,t } = this.props;
        const dateTimeRules = commonStore.config && commonStore.config.client && commonStore.config.client.data && commonStore.config.client.data.dateTimeRules

        if (!this.state.isTransformed) {
            return <LoadingSpinner />
        }

        return (
            
            <>
                {
                    monitorStore.tableListData.map((tabElement, tabIndex) => {
                        return (
                            <div className='mon-tab-list' style={{overflow: this.props.styling}} key={tabIndex}>
                                <div className='m-table' id="m-table-end">
                                    {
                                        tabElement.meta.products.map((element, productIndex) => {
                                            if (!productIndex) {
                                                return (
                                                    <div key={element} className='m-column list-column'>
                                                        <div className='m-block' id="m-block-odd">
                                                            <div className="itemList" data-toggle="tooltip" data-placement="top" title={element}>{element}
                                                        </div>
                                                        </div>
                                                        {
                                                            tabElement.meta.items.map((element, index) => {
                                                                return (
                                                                    <div key={element} className='m-block' id='m-block-even'>
                                                                        <div className='itemList' data-toggle="tooltip" data-placement="top" title={element}>{element}</div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                )
                                            } else {
                                                return (
                                                    <div key={element} className='m-column' id="m-table-end">
                                                        <div className='m-block' id="m-block-odd"><div className="itemList" data-toggle="tooltip" data-placement="top" title={element}>{element}</div></div>
                                                        {

                                                            tabElement[productIndex].map((element, itemIndex) => {
                                                                return (
                                                                    <div key={itemIndex} className='m-block' id="m-block-even">
                                                                        <div>
                                                                            {
                                                                                !itemIndex ? <span>{element && typeof element == ('number' || 'string') ? timeUtils.timeFormat(element, dateTimeRules) : ''}</span> :
                                                                                    (element && element.value && !(this.state.activeId === "" + tabIndex + productIndex + itemIndex)) ?
                                                                                        (<><div className='text__align' onClick={() => { this.setActiveId(tabIndex, productIndex, itemIndex) }}>{element.value} {monitorStore.unit}</div>
                                                                                            <div className="itemList m-grid-extra-style" data-toggle="tooltip" data-placement="top" title={element.uname}
                                                                                            onClick={() => { this.setActiveId(tabIndex, productIndex, itemIndex) }}>
                                                                                                    {element.uname}
                                                                                            </div>
                                                                                        </>)
                                                                                        :
                                                                                        <input
                                                                                            type="text"
                                                                                            autoFocus={this.state.activeId === "" + tabIndex + productIndex + itemIndex ? true : false}
                                                                                            defaultValue={element.value}
                                                                                            maxlength="5"
                                                                                            onFocus={(evt) => {
                                                                                                this.handleDataChange(evt, tabIndex, productIndex, itemIndex)
                                                                                            }}
                                                                                            onBlur={
                                                                                                (evt) => {
                                                                                                    this.handleDataChange(evt, tabIndex, productIndex, itemIndex)
                                                                                                }
                                                                                            }
                                                                                            onKeyDown={
                                                                                                (evt) => {
                                                                                                    this.handleDataChange(evt, tabIndex, productIndex, itemIndex)
                                                                                                }
                                                                                            }
                                                                                        ></input>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                </div>
                                <div style={{ width: ((tabElement.meta.products.length * 100) + 101) + 'px' }}>
                                    <div className='tab-comment'>
                                        <div>
                                            <div className='cmt-label'>{t('Comment')}</div>
                                            {this.props.view ? <span style={{lineBreak:"anywhere"}}>{tabElement.comment}</span>
                                              :
                                             <textarea id="mo-comment" defaultValue={tabElement.comment}
                                             onBlur={(evt)=>{ this.handleComment(evt, tabIndex) }}
                                             onKeyDown={(evt)=>{ this.handleComment(evt, tabIndex)}}></textarea>
                                             }
                                        </div>
                                    </div>
                                    <div className='mot-action'>
                                        {monitorStore.tableListData.length > 1 ? <button className='minus-tab' onClick={() => this.deleteTable(tabIndex)}><li className='fa fa-minus'></li></button> : ''}
                                        <button className='add-tab' onClick={() => this.addTable()}><li className='fa fa-plus'></li></button>
                                    </div>
                                </div>
                            </div>
                        )

                    })

                }




            </>
        )
    }
}

export default inject('monitorStore', 'userStore', 'commonStore')(withLocalization(applicationRouter(observer(MonitoringTable))));