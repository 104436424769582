import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

//components
import withLocalization from '../../../hoc/withLocalization';
import Checkbox from '../../CustomCheckbox/CustomCheckbox.jsx';
import { defaultDays } from '../../../utils/constants.js';

const DaysOfWeekWidgetInternal = inject('commonStore')(withLocalization(observer(class DaysOfWeekWidgetInternal extends Component {
    onCheck(index) {
        const { value, onChange } = this.props;
        let _v = value || '';
        _v = _v ? _v.split(',') : [];
        index = `${index}`;
        if (_v.indexOf(index) >= 0) {
            _v.splice(_v.indexOf(index), 1);
        } else {
            _v.push(index);
        }
        const res = _v.reduce((accum, cValue) => `${accum ? `${accum},` : ''}${cValue}`, '');
        onChange(res);
    }

    handleCheckAll = (evt) => {
        const res = evt ? defaultDays.map((_, index) => index).join(",") : "";
        this.props.onChange(res)
    }

    static isChecked = (value, index, checkAllIndexes) => {
        if (!checkAllIndexes) {
            return (value || '').indexOf(index) >= 0;
        }
        else {
            return !defaultDays.find((_, index) => (value || '').indexOf(index) === -1)
        }
    };

    render() {
        const { value, t, includeCheckAll = false, disabled = false, options, schema } = this.props;
        const __includeCheckAll = options?.includeCheckAll !== undefined ? !!options.includeCheckAll : !!includeCheckAll;
        const __disabled = schema?.readOnly !== undefined ? !!schema?.readOnly : !!disabled;

        return (
            <div className="days-of-week">
                {__includeCheckAll &&
                    <Checkbox
                        wrapperClassName="days-of-week__item"
                        key={"dow_check_all"}
                        isChecked={DaysOfWeekWidgetInternal.isChecked(value, null, true)}
                        onChange={this.handleCheckAll}
                        label={t("Check All")}
                        disabled={__disabled}
                    />
                }
                {defaultDays.map((day, index) => (
                    <Checkbox
                        wrapperClassName="days-of-week__item"
                        key={`${index}`}
                        isChecked={DaysOfWeekWidgetInternal.isChecked(value, index, false)}
                        onChange={() => this.onCheck(index)}
                        label={t(day)}
                        disabled={__disabled}
                    />
                ))}
            </div>
        );
    }
}
)));
export const DaysOfWeekWidget = observer(class DaysOfWeekWidget extends Component {
    render() {
        return <DaysOfWeekWidgetInternal {...this.props} />;
    }
});
