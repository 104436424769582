import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Modal, Row, Col, Dropdown } from 'react-bootstrap';
import { Textarea, NumberInput } from '../../../elements/Input';
import { v4 as uuid } from 'uuid';
import moment from 'moment/min/moment-with-locales';
import { getDay } from 'date-fns';
import { confirmAlert } from 'react-confirm-alert';

//components
import applicationRouter from '~/hoc/applicationRouter'
import withLocalization from '~/hoc/withLocalization';
import GenericList from '~/components/GenericList/GenericList';
import GenericFilter from '../../../components/GenericList/GenericFilter.js';

// Widgets
import { CurrentUserGroupsWidget } from '../../../components/GenericForm/Widgets/UserGroupWidget';
import { MembersWidget } from '../../../components/GenericForm/Widgets/UsersWidget';
import { ProjectsWidget } from '../../../components/GenericForm/Widgets/ProjectsWidget';

//elements
import CustomMultiSelect from '../../../elements/CustomMultiSelect';
import Button from '../../../components/CustomButton/CustomButton.jsx';
import Checkbox from '../../../components/CustomCheckbox/CustomCheckbox';
import LoadingSpinner from '~/elements/LoadingSpinner';

//utils
import { getShortDateFormat, shortDateFormat } from '../../../utils/dateFormat';
import { getGroupName } from '../../../utils/commonMethods.js'
import { getIntegrationIconWithStatus } from '~/utils/integrationUtils';

//assets
import CloseModalIcon from '~/assets/img/CloseApproveModalIcon.svg';
import Edit from '../../../assets/img/editing.svg';
import Delete from '~/assets/img/deleting.svg';
import DatePicker from '../../../components/DatePicker/DatePicker.jsx';
import Sync from '../../../assets/img/Sync.svg'

class ShiftAllowanceManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showForm: false,
            notify: true,
            start_date: moment(new Date()).startOf('week').format('YYYY-MM-DD'),
            end_date: moment(new Date()).endOf('week').format('YYYY-MM-DD'),
            group_id: null,
            users: [],
            amount: 0,
            defaultAmount: this.props.commonStore.config.client.data?.extraPayments?.find(ele => ele.code === 'shift_allowance')?.amount,
            custom_message: null,
            clientGroupMap: getGroupName(this.props.commonStore.config?.client?.data?.extendedRules?.user_groups),
            addIndividual: false,
            editId: null,
            editIndividual: false,
            updatedValues: null,
            isFormUpdated: false,
            userOptions: [],
            allUsers: [],
            project_id: null,
            isMember: this.props.userStore.currentUser.user_type === 'member'
        }
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = async () => {
        const getAllUsers = await this.props.userStore.getUsersWithGroup();
        let formattedUsers = getAllUsers.users.map(ele => {
            return { value: ele.id, label: ele.name, groupIds: ele.user_groups };
        })
        this.setState({ userOptions: formattedUsers, allUsers: formattedUsers, amount: this.state.defaultAmount });
    }

    handleCancel = () => {
        if (!this.state.showForm)
            this.props.finish()
        this.setState({ showForm: false, editId: null, addIndividual: false, project_id: null })
    }

    handleChange = (name, value) => {
        if (name === 'group_id' && !this.state.addIndividual) {
            let filterUserOptions = this.state.allUsers.filter(ele => ele.groupIds.includes(value));
            if (value < 0)
                filterUserOptions = this.state.allUsers;
            let users = filterUserOptions.map(ele => ele.value);
            this.setState({ userOptions: filterUserOptions, users });
        }
        this.setState({ [name]: value }, () => this.compareState())
    }

    handleDateChange = (value) => {
        this.setState({
            start_date: moment(value).format('YYYY-MM-DD'),
            end_date: moment(value).add(6, 'days').format('YYYY-MM-DD')
        });
    }

    handleSave = async () => {
        const { t, commonStore, shiftAllowanceStore } = this.props;
        if (commonStore.config?.biztype?.data?.allow_multiple_branches && !this.state.project_id) {
            return commonStore.addNotification(t('Project must be selected' || 'warning'), null, 'warning');
        }
        if (!this.state.users.length) {
            return commonStore.addNotification(t('Please Select member' || 'Error'), null, 'error');
        }
        if (this.state.editId) {
            await shiftAllowanceStore.save(this.state, false)
            this.setState({ showForm: false, editId: false, group_id: null, users: [], amount: this.state.defaultAmount, custom_message: null });
        }
        else {
            const shiftAllowance = await shiftAllowanceStore.save(this.state, true);
            if (shiftAllowance.warningMessage)
                commonStore.addNotification(t(shiftAllowance.warningMessage || 'warning'), null, 'warning');
            this.setState({ showForm: false, editId: false, group_id: null, users: [], amount: this.state.defaultAmount, custom_message: null });
        }
    }

    compareUsers = () => {
        const arr = this.state.users?.sort((a, b) => a - b);
        const arr1 = this.state.updatedValues?.users?.sort((a, b) => a - b);

        if (arr?.length !== arr1?.length)
            return false
        for (let i = 0; i < arr.length; i++) {
            if (!arr1.includes(arr[i]))
                return false;
        }
        return true;
    }

    compareState = () => {
        const isSame = this.compareUsers()
        if (!isSame
            || this.state.updatedValues.amount !== this.state.amount
            || this.state.updatedValues.group_id !== this.state.group_id
            || this.state.updatedValues.custom_message !== this.state.custom_message
        ) {
            this.setState({ isFormUpdated: true })
        }
        else {
            this.setState({ isFormUpdated: false })
        }
    }

    loadEntireGroup = async (id, details) => {
        const params = { batch_id: details.batch_id }
        const _users = [];
        const loadGroupData = await this.props.shiftAllowanceStore.loadGroup(params)
        if (loadGroupData?.shift_allowance?.length) {
            loadGroupData.shift_allowance.forEach(ele => {
                _users.push(ele.user_id)
            })
            this.setState({
                start_date: details.from_date,
                end_date: details.to_date,
                group_id: details.group_id,
                project_id: details.project_id,
                users: _users,
                amount: details.amount,
                custom_message: details.custom_message,
                showForm: true,
                editId: details.id,
                notify: false,
                updatedValues: {
                    group_id: details.group_id,
                    users: _users,
                    amount: details.amount,
                    custom_message: details.custom_message
                }
            })
        }
    }

    handleTableButtonAction = (id, actionType, details) => {
        const { t } = this.props;
        if (actionType === 'edit') {
            if (details.type === 'individual') {
                return this.setState({
                    showForm: true,
                    addIndividual: true,
                    group_id: details.group_id,
                    amount: details.amount,
                    editId: details.id,
                    custom_message: details.custom_message,
                    editIndividual: true,
                    notify: false,
                    users: [{ value: details.user_id, name: details.name }],
                    project_id: details.project_id
                })
            }
            return confirmAlert({
                title: `${t('Choose Edit Mode')}?`,
                message: `${t("Since this shift allowance was added as a group, you can edit as group and individual also. But if you edit as individual, the system will mark it as individual and it won't be consider as group")}`,
                buttons: [
                    {
                        label: t('Edit Individually'),
                        onClick: () => {
                            this.setState({
                                showForm: true,
                                addIndividual: true,
                                group_id: details.group_id,
                                amount: details.amount,
                                editId: details.id,
                                custom_message: details.custom_message,
                                editIndividual: true,
                                notify: false,
                                users: [{ value: details.user_id, name: details.name }],
                                project_id: details.project_id
                            })
                        },
                    },
                    {
                        label: t('Edit Group'),
                        onClick: () => {
                            this.loadEntireGroup(id, details)
                        },
                    },
                ],
            })
        }
        if (actionType === 'remove') {
            const { schedulerData, shiftAllowanceStore, commonStore, t } = this.props;
            return confirmAlert({
                title: `${t('Confirm to delete')}?`,
                message: `${t("Are you sure to delete an item?")}`,
                buttons: [
                    {
                        label: t('Yes'),
                        onClick: () => {
                            return shiftAllowanceStore.remove(id).then((res) => {
                                commonStore.addNotification(t('Deleted'), null, 'error');
                                shiftAllowanceStore.getShiftAllowanceCount({ fromDate: schedulerData.startDate, toDate: schedulerData.endDate });
                                shiftAllowanceStore.resetLastListLoadTime(new Date());
                            });
                        },
                    },
                    {
                        label: t('No'),
                        onClick: () => { },
                    },
                ],
            })
        }
    }

    handleSyncWithIntegration = async (id) => {
        await this.props.shiftAllowanceStore.syncShiftAllowance(id);
    }

    renderTableActionButtons = (id, all) => {
        const { t, commonStore } = this.props;
        const { config } = commonStore
        const { isMember } = this.state
        return (
            <div className="actions-center actions-center__center">
                {commonStore.config?.integration_details?.product ? all.int_id ?
                    getIntegrationIconWithStatus(config, all, t)
                    :
                    <Button icon_sm fill title={t("Sync with integration")} onClick={() => this.handleSyncWithIntegration(id)}>
                        <img src={Sync} alt='sync' />
                    </Button>
                    :
                    <></>
                }
                <Button icon_sm fill onClick={() => this.handleTableButtonAction(id, 'edit', all)} disabled={isMember}>
                    <img src={Edit} alt="edit" />
                </Button>
                <Button icon_sm_delete fill onClick={() => this.handleTableButtonAction(id, 'remove')} disabled={isMember}>
                    <img src={Delete} alt="delete" />
                </Button>
            </div>
        );
    };

    render() {
        const { t, finish, shiftAllowanceStore, commonStore, userStore } = this.props;
        const { showForm, end_date, start_date, amount, group_id, userOptions, project_id, isMember,
            custom_message, users, clientGroupMap, addIndividual, editId, isFormUpdated, editIndividual } = this.state;
        const { filters, appliedFilters, loading, lastListLoadTime } = shiftAllowanceStore
        const dateTimeRules = commonStore.config.client && commonStore.config.client.data && commonStore.config.client.data.dateTimeRules
            ? commonStore.config.client.data.dateTimeRules
            : false;

        return (
            <Modal dialogClassName="lg" className="lg" size="lg" show backdrop="static" onHide={() => finish()}>
                <Modal.Header className="set_ModalHeader">
                    <Modal.Title>{this.props.t('Shift Allowance Management')}</Modal.Title>
                    <img
                        src={CloseModalIcon}
                        className="cursor-pointer"
                        onClick={() => finish()}
                        alt={'close-icon'}
                    />
                </Modal.Header>
                <Modal.Body style={{ minHeight: 400 }}>
                    {loading ?
                        <LoadingSpinner />
                        :
                        <>
                            <Row xs={12} className='group-management-header'>
                                <div className='d-flex justify-content-end custom-style-userlist'>
                                    {!isMember ? !showForm ?
                                        <>
                                            <Dropdown className="d-inline mx-2">
                                                <Dropdown.Toggle id="dropdown-autoclose-inside">
                                                    <i className="fa fa-plus-circle fa-xl margin-right-10" /> {t('Add By')}
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item
                                                        onClick={() => this.setState({ showForm: true, addIndividual: true })}
                                                        title={t("Individual")}
                                                    >
                                                        <div>
                                                            <i className="fa fa-user me-3" width="25px" height="25px" style={{ color: '#2550ac' }} />
                                                            {this.props.t('Individual')}
                                                        </div>
                                                    </Dropdown.Item>
                                                    <Dropdown.Item
                                                        title={t('Group')}
                                                        onClick={() => this.setState({ showForm: true })}
                                                    >
                                                        <div>
                                                            <i className="fa fa-users me-3" width="25px" height="25px" style={{ color: '#2550ac' }} />
                                                            {this.props.t('Group')}
                                                        </div>
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </>
                                        :
                                        <Button fill wd onClick={() => this.handleSave()} disabled={!isFormUpdated && !editIndividual}>
                                            {t('Save')}
                                        </Button>
                                        :
                                        <></>
                                    }
                                </div>
                            </Row>
                            {showForm && !isMember ?
                                <>
                                    <Row className='mt-2'>
                                        <Col md={6}>
                                            <span>{this.props.t('Select Week')}</span>
                                            <DatePicker
                                                startDate={new Date(start_date)}
                                                endDate={new Date(end_date)}
                                                onChange={(date) => this.handleDateChange(date[0])}
                                                dateFormat={getShortDateFormat(dateTimeRules)}
                                                selectsRange
                                                filterDate={date => getDay(date) === 1}
                                                disabled={editId}
                                            />
                                        </Col>
                                    </Row>
                                    {addIndividual ?
                                        <Row className='mt-4'>
                                            <Col md={6}>
                                                <label>{t('Select Member')}</label>
                                                <MembersWidget
                                                    key={uuid()}
                                                    className="form-control"
                                                    onChange={(value, full) => this.handleChange('users', [full])}
                                                    value={users[0]?.value}
                                                    defaultIfOne
                                                    placeholder={t('Select Member')}
                                                    reactSelectClassname={"custom-select-max-height-200"}
                                                    disabled={editId}
                                                />
                                            </Col>
                                            <Col md={6}>
                                                <label>{t('Select Group')}</label>
                                                <CurrentUserGroupsWidget
                                                    user_id={0 || users[0]?.value}
                                                    value={group_id}
                                                    onChange={(value) => this.handleChange('group_id', value)}
                                                    key={users[0]?.value}
                                                />
                                            </Col>
                                        </Row>
                                        :
                                        <Row className='mt-4'>
                                            <Col md={6}>
                                                <label>{t('Select Group')}</label>
                                                <CurrentUserGroupsWidget
                                                    user_id={0}
                                                    value={group_id}
                                                    onChange={(value) => this.handleChange('group_id', value)}
                                                />
                                            </Col>
                                            <Col md={6}>
                                                <label>{t('Select Member')}</label>
                                                {userOptions.length ? <></> : <span className='text-danger fw-600 font-size-10 ms-2'>({t('Look like there is no member in this group')})</span>}
                                                <CustomMultiSelect
                                                    options={userOptions}
                                                    value={users}
                                                    onChange={(id) => {
                                                        this.handleChange('users', id);
                                                    }}
                                                    className={"custom-multi-select-max-height-150"}
                                                />
                                            </Col>
                                        </Row>
                                    }
                                    <Row className='mt-4'>
                                        <Col md={6}>
                                            <span>{t('Amount')}</span>
                                            <NumberInput
                                                value={amount || 0}
                                                onChange={e => {
                                                    if (Number(e.target.value) < 0)
                                                        return
                                                    this.handleChange('amount', Number(e.target.value))
                                                }}
                                            />
                                        </Col>
                                        <Col md={6} className=' d-flex flex-column'>
                                            <span>{t('Project')}</span>
                                            <div className='mt-12 w-100'>
                                                <ProjectsWidget
                                                    value={project_id || null}
                                                    onChange={(e, f) => {
                                                        this.handleChange('project_id', e);
                                                    }}
                                                    pmMode = {userStore.currentUser.user_type === 'pm' ? 'pm_members' : null}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className='mt-4'>
                                        <Checkbox
                                            isChecked={this.state.notify}
                                            onChange={(checked) => this.setState({ notify: checked })}
                                            label={editId ? this.props.t('Resend Notification') : this.props.t('Send Notification')}
                                        />
                                    </Row>
                                    <Row className='mt-4'>
                                        <Textarea
                                            label={t('Notification text')}
                                            value={custom_message}
                                            style={{ width: '100%', height: '50%' }}
                                            onChange={(e) => this.handleChange('custom_message', e.target.value)}
                                            placeholder={t('Hey there, You got the shift allowance.')}
                                            timelog_modal={"_timelog"}
                                        />
                                    </Row>
                                </>
                                :
                                <GenericList
                                    header={
                                        <GenericFilter
                                            filters={filters}
                                            setFilter={(name, value) => this.props.shiftAllowanceStore.setFilter(name, value)}
                                            onFilter={() => this.props.shiftAllowanceStore.onFilter()}
                                            hasDateFilters={true}
                                            hasCustomFilter={true}
                                            customFilterOptions={[{ value: '', label: 'All' },
                                            { value: 'group', label: 'Group' },
                                            { value: 'individual', label: 'Individual' }]}
                                            customFilterName={{ value: 'shift_allowance_type', label: 'All' }}
                                        />
                                    }
                                    columns={[{
                                        Header: 'Name',
                                        id: 'Name',
                                        accessor: ({ name }) => (
                                            <span>{name}</span>
                                        )
                                    },
                                    {
                                        Header: 'Group',
                                        id: 'group',
                                        maxWidth: 50,
                                        accessor: ({ group_id }) => (
                                            <span>{clientGroupMap.get(group_id)?.name}</span>
                                        )
                                    },
                                    {
                                        Header: 'Type',
                                        id: 'type',
                                        accessor: ({ type }) => (
                                            <span className='text-capitalize'>{type}</span>
                                        )
                                    },
                                    {
                                        Header: 'Date',
                                        id: 'date',
                                        minWidth: 155,
                                        accessor: ({ from_date, to_date }) => (
                                            <span>{`${shortDateFormat(from_date, dateTimeRules)} | ${shortDateFormat(to_date, dateTimeRules)}`}</span>
                                        )
                                    },
                                    {
                                        Header: 'Amount',
                                        id: 'amount',
                                        minWidth: 60,
                                        maxWidth: 80,
                                        accessor: ({ amount }) => (
                                            <span>{amount}</span>
                                        )
                                    },
                                    {
                                        Header: 'Actions',
                                        id: 'actions',
                                        accessor: (all) => this.renderTableActionButtons(all.id, all),
                                    },]}
                                    filters={appliedFilters}
                                    lastListLoadTime={lastListLoadTime}
                                    requestData={(params) => shiftAllowanceStore.loadList(params)}
                                >
                                </GenericList>
                            }
                        </>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <div style={{ textAlign: 'left' }}>
                        <Button wd fill type="submit" onClick={() => this.handleCancel()}>
                            {t('Cancel')}
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        )
    }
}
export default inject('scheduleStore', 'userStore', 'commonStore', 'shiftAllowanceStore')(withLocalization(applicationRouter(observer(ShiftAllowanceManagement))));