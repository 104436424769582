import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

//components
import applicationRouter from '~/hoc/applicationRouter';
import withRemoveDialog from '~/hoc/withRemoveDialog';
import withLocalization from '~/hoc/withLocalization';
import GenericList from '~/components/GenericList/GenericList';
import GenericFilter from '../../../../components/GenericList/GenericFilter';

//elements
import Button from '../../../../components/CustomButton/CustomButton.jsx';

//utils
import { shortDateFormat } from '~/utils/dateFormat';

//styles
import '../../../../styles/css/styles.css';

//assets
import Edit from '~/assets/img/editing.svg';
import Delete from '~/assets/img/deleting.svg';

//this component does the listing for documents//

class DocumentTable extends Component {
    state = {};

    componentDidMount() { }

    handleTableActionButtonAction = (id, type) => {
        //handles action button clicks//
        if (type === 'edit') {
            //edit document
            this.editDocument(id);
        } else if (type === 'delete') {
            //delete document
            this.removeDocument(id);
        } else if (type === 'copy') {
            //delete document
            this.copyDocument(id);
        }
    };

    removeDocument(id) {
        //calls action from documentStore to delete document with id//
        const { commonStore, t } = this.props;

        return this.props.handleDelete(id, (id) => {
            return this.props.documentStore.deleteDocumentById(id).then((res) => {
                // if (res) {
                commonStore.addNotification(t('Document Removed'), null, 'error');
                this.props.documentStore.resetLastListLoadTime();
                this.props.router.navigate('/admin/documents');
                // }
                return res;
            });
        });
    }

    copyDocument(id) {
        //calls action from documentStore to delete document with id//
        const { commonStore, t } = this.props;
        let data = {
            id: id,
        };
        this.props.documentStore.copyDocument(data).then((res) => {
            if (res) {
                commonStore.addNotification(t('Document Copied'), null, 'success');
                this.props.documentStore.resetLastListLoadTime();
            }
            return res;
        });
    }

    editDocument(id) {
        //calls parentHandler to redirect to addEditComponent with given Id//
        this.props.router.navigate(`/admin/documents/${id}`);
    }

    renderTableActionButtons = (id, isadmin) => {
        //renders table edit delete buttons in generic list//

        const { user_type } = this.props.userStore.currentUser;
        return (
            <div>
                {user_type !== 'member' && user_type !== 'pm' ? (
                    <div className="actions-center">
                        <Button
                            icon_sm
                            fill
                            onClick={() => this.handleTableActionButtonAction(id, 'copy')}
                        >
                            <i className="fa fa-copy actionblueicon" />
                        </Button>
                        {(user_type === 'super-admin' || (user_type !== 'super-admin' && !isadmin)) && (
                            <>
                                <Button
                                    icon_sm
                                    fill
                                    onClick={() => this.handleTableActionButtonAction(id, 'edit')}
                                >
                                    <img src={Edit} alt="edit" />
                                </Button>
                                <Button
                                    icon_sm_delete
                                    fill
                                    onClick={() => this.handleTableActionButtonAction(id, 'delete')}
                                >
                                    <img src={Delete} alt="delete" />
                                </Button>
                            </>
                        )}
                    </div>
                ) : null}
            </div>
        );
    };

    render() {
        //renders document list using generic list along with documentFilter//

        const { filters, appliedFilters, loading } = this.props.documentStore;
        const { config } = this.props.commonStore;
        const dateTimeRules =
            config.client && config.client.data && config.client.data.dateTimeRules
                ? config.client.data.dateTimeRules
                : false;
        return (
            <GenericList
                columns={[
                    {
                        Header: 'Document Name',
                        id: 'document_name',
                        accessor: ({ id, document_name }) => (
                            <a
                                className="list-main-column"
                                onClick={() => this.handleTableActionButtonAction(id, 'edit')}
                            >
                                {`${document_name}`}
                            </a>
                        ),
                        isSortable: true,
                        textAlign: 'start',
                    },
                    {
                        Header: 'Created By',
                        id: 'created_by',
                        accessor: ({ created_by }) => <span>{created_by}</span>,
                        isSortable: true,
                    },
                    {
                        Header: 'Updated On',
                        id: 'updated_at',
                        accessor: ({ updated_at }) => (
                            <span>{updated_at && shortDateFormat(updated_at, dateTimeRules)}</span>
                        ),
                        isSortable: true,
                    },
                    {
                        Header: 'Actions',
                        id: 'actions',
                        accessor: (full) => this.renderTableActionButtons(full.id, full.isadmin),
                        maxWidth: 80
                    },
                ]}
                filters={appliedFilters}
                lastListLoadTime={this.props.documentStore.lastListLoadTime}
                header={
                    <GenericFilter
                        filters={filters}
                        setFilter={(name, value) => this.props.documentStore.setFilter(name, value)}
                        onFilter={() => this.props.documentStore.onFilter()}
                        loading={loading}
                    />
                }
                requestData={(params) => this.props.documentStore.getDocuments(params)}
            />
        );
    }
}

export default inject(
    'authStore',
    'userStore',
    'commonStore',
    'documentStore'
)(applicationRouter(withLocalization(withRemoveDialog(observer(DocumentTable)))));
