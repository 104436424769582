export const schema = {
    title: 'Business Type',
    type: 'object',
    required: ['name'],
    properties: {
        id: { type: 'integer' },
        name: { type: ['string'], title: 'Name' },
        data: {
            type: 'object',
            title: 'Settings and rules',
            properties: {
                allow_vacations: {
                    type: 'boolean',
                    'ui:widget': 'TrueFalse',
                    title: 'Allow vacations',
                    default: true,
                },
                allow_absents: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'Allow absents' },
                allow_accepting: {
                    type: 'boolean',
                    'ui:widget': 'TrueFalse',
                    title: 'Allow accepting time / vacations / absents',
                },
                pm_hide_tasks: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'PM hide tasks' },
                pm_hide_vacations: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'PM hide vacations' },
                pm_hide_absences: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'PM hide absents' },
                pm_no_register: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'PM no register time' },
                member_allow_see_projects: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'Members see projects' },
                timelog_hide_kilometers: {
                    type: 'boolean',
                    'ui:widget': 'TrueFalse',
                    title: 'Hide timelog kilometers',
                },
                timelog_hide_other: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'Hide timelog other expenses' },
                timelog_hide_extra: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'Hide timelog extra work' },
                show_times_on_project: {
                    type: 'boolean',
                    'ui:widget': 'TrueFalse',
                    title: 'Show agreement times on project form',
                },
                hide_send_report: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'Hide send report' },
                presence_view: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'Show users presence' },
                admin_hide_absences: {
                    type: 'boolean',
                    'ui:widget': 'TrueFalse',
                    title: 'Hide absents/vacations from admins',
                },
                allow_vacation_not_me: {
                    type: 'boolean',
                    'ui:widget': 'TrueFalse',
                    title: 'Allow absents/vacations made by admins for members',
                },
                reports_not_for_me: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'Allow reports not for me' },
                reports_weekly: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'Weekly report enabled' },
                locked_mode: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'Locked mode' },
                rest_report: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'Restaurant report enabled' },
                pm_allow_editing_all: {
                    type: 'boolean',
                    'ui:widget': 'TrueFalse',
                    title: 'Allow everything edited for PMs/Admins',
                },
                login_as_allowed: {
                    type: 'boolean',
                    'ui:widget': 'TrueFalse',
                    title: 'Allow Login As without confirmation',
                },
                disallow_absences_vacations_not_self: {
                    type: 'boolean',
                    'ui:widget': 'TrueFalse',
                    title: 'Disallow seeing not my absences/vacations',
                },
                timelog_hide_names: {
                    type: 'boolean',
                    'ui:widget': 'TrueFalse',
                    title: 'Disallow member names in timelogs',
                },
                gps_enabled: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'GPS enabled' },
                timelog_no_edit_for_approved: {
                    type: 'boolean',
                    'ui:widget': 'TrueFalse',
                    title: 'Disable edit timelog when approved',
                },
                timelog_no_edit_for_done: {
                    type: 'boolean',
                    'ui:widget': 'TrueFalse',
                    title: 'Disable edit timelog when not draft',
                },
                pm_no_shifts: {
                    type: 'boolean',
                    'ui:widget': 'TrueFalse',
                    title: 'PMs/Admins cant manage others shifts',
                },
                enable_deviations: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'Enable deviations' },
                union_group: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'Union group' },
                allow_multiple_branches: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'Allow Multiple Branches' },
                name_substitutions: {
                    type: 'array',
                    title: 'Text substitutions',
                    default: [],
                    items: {
                        type: 'object',
                        required: ['from', 'to'],
                        properties: {
                            from: { type: 'string' },
                            to: { type: 'string' },
                        },
                    },
                },
                allow_system_document: { type: 'boolean', 'ui:widget': 'TrueFalse',"ui:help": "Go Down for Select Documents", title: 'Enable System Documents' },
            },
        },
    },
};

export const getSchema = () => schema;

export default getSchema;
