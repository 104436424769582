import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

import Header from "../../../newComponents/Header";
import Footer from "../../../newComponents/Footer";
import MainHeading from "../../../newComponents/MainHeading";
import PageBanner from "../../../newComponents/PageBanner";
import Feature from "../../../newComponents/Feature";
import Contact from "../../../newComponents/Contact";

import BannerImg from "../../../assets/images/featureImg.png";
import AppleIcon from "../../../assets/images/appleIcon.svg";
import GoogleIcon from "../../../assets/images/googleIcon.svg";
import ValuableLogo from "../../../assets/images/valuableLogo.png";
import TimeIcon from "../../../assets/images/timeIcon.svg";
import ScheduleIcon from "../../../assets/images/scheduleIcon.svg";
import MessageIcon from "../../../assets/images/messageIcon.svg";

//i18n
import { withTranslation } from "react-i18next"
import { inject, observer } from "mobx-react";

import config from "../../../library/config";

const Features = inject('authStore', 'commonStore')(observer((props) => {
  return (
    <>
      <div className="landingPageWrapp frameworkApp">
        <Header features="current" />
        <article className="pageBannerBlock grdntBg  py-60">
          <Container>
            <Row className="g-4 align-items-center">
              <Col lg={6}>
                <MainHeading
                  title={<>{props.t("Ultimate Features of")} <span>{props.t("Time and Control")}</span></>}
                  description={props.t("We come with a robust app that contains unique features you can't imagine. Try the app and enjoy the multiple solutions for flawless enterprises and productive results.")}
                />
                <div className="appBtns mt-4">
                  <Link onClick={e => config.redirectToAppStore(e, "apple")} className="btnCommon btnBig w-auto me-3 mb-2"><i className="d-inline-block pe-2"><img src={AppleIcon}  alt="Apple" /></i> {props.t("Apple")}</Link>
                  <Link onClick={e => config.redirectToAppStore(e, "google")} className="btnCommon btnTrans btnBig btnBlackTrans w-auto"><i className="d-inline-block pe-2"><img src={GoogleIcon} alt="Apple" /></i> {props.t("Google")}</Link>
                </div>
              </Col>
              <Col lg={6}>
                <PageBanner
                  src={BannerImg}
                  alt="Time Register"
                />
              </Col>
            </Row>
          </Container>
          <div className="valuableLogo">
            <img src={ValuableLogo} alt="" />
          </div>
        </article>
        <article className="homeFeaBlock  py-80">
          <Container>
            {/* <div className="contentInfo text-center">
              <h5 className="mb-1">{props.t("User-Friendly Features Of")}</h5>
            </div> */}
            <MainHeading
              title={<>{props.t("Take control of your business's success")}<br/><span>{props.t("with the Time & Control app")}</span></>}
              customClass="text-center medium noPara"
            />
            <div className="feauList">
              <Row className="g-4">
              <Col md={6} lg={6}>
                  <Feature
                    src={TimeIcon}
                    title={props.t("Time & Attendance")}
                    description={props.t("Now with our software Time-Tracking is so simple to manage and register. You can simply register working hours with a tap using the 'Register' feature of the app. There are 3 different systems used to manage time-tracking. You can choose whichever system you feel is most viable to the way your organization is operated. The ability to set reminders and schedule the time of your employees manually is also possible with this software.")}
                    link="/time-register"
                    color="blue"
                    border="grey"
                  />
                </Col>
                <Col md={6} lg={6}>
                  <Feature
                    src={ScheduleIcon}
                    title={props.t("Scheduling Plan")}
                    description={props.t("Scheduling functionality allows you to make multiple separate schedules along with timetables, assigned shifts, and make any relevant changes from anywhere. This creates a seamless operation for the entire staff and avoids room for errors or schedule gaps. Vacation and Absence feature allow easy recording of vacation time and sick leave, allowing full compliance with local labor laws.")}
                    link="/schedule-plan"
                    color="orange"
                    border="grey"
                  />
                </Col>
                <Col md={6} lg={6}>
                  <Feature
                    src={ScheduleIcon}
                    title={props.t("Digital Signature")}
                    description={props.t("Obtain digital signatures for assured contracts, agreements, invoices, letters, and documents through Time & Control. Accelerating file and document processing with instant signature solutions also saves costs and time for your business. Time and Control offers digital signature, storing, and management capabilities to streamline business operations.")}
                    link="/digital-signature"
                    color="green"
                    border="grey"
                  />
                </Col>
                <Col md={6} lg={6}>
                  <Feature
                    src={ScheduleIcon}
                    title={props.t("Employee Handbook")}
                    description={props.t("Manage your employees, assignments, assurances, payroll, and task executions on a single platform. The Time & Control Employee Guide feature brings much-needed flexibility & efficiency to the HR department and helps to gain impressive visibility from all types of sophisticated gadgets. You can organize all employee-related records, information, and even swap schedules between colleagues.")}
                    link="/employee-guide"
                    color="pink"
                    border="grey"
                  />
                </Col>
                <Col md={6} lg={6}>
                  <Feature
                    src={MessageIcon}
                    title={props.t("Checklists")}
                    description={props.t("Digitize and optimize your company workflow with digital checklists and procedural updates. Do you want complete access to daily activities, monitor the tasks, and improve accountability? Time & Control Obligatory Checklist feature helps you to manage, upload files and save data digitally to make the processes hassle-free.")}
                    link="/checklist"
                    color="blue"
                    border="grey"
                  />
                </Col>
                <Col md={6} lg={6}>
                  <Feature
                    src={ScheduleIcon}
                    title={props.t("Communication")}
                    description={props.t("Our ultimate communication feature allows your workers to interact smoothly with other colleagues within the organization over an intuitive interface. You can obtain all communication onto a single platform and increase work efficiency with the best approaches. Furthermore, It helps to easily manage documents, files, and other records as you communicate with individuals or in groups.")}
                    link="/communication"
                    color="orange"
                    border="grey"
                  />
                </Col>
                <Col md={6} lg={6}>
                  <Feature
                    src={ScheduleIcon}
                    title={props.t("Documents")}
                    description={props.t("Never lose sight of the document you need with the Time & Control Document Management solution. Store all your documents in one place, find and share documents when you need them with other team members, and improve business efficiency. Bring enterprise-level document management and maintenance to your fingertips with our solution.")}
                    link="/documents"
                    color="green"
                    border="grey"
                  />
                </Col>
                <Col md={6} lg={6}>
                  <Feature
                    src={ScheduleIcon}
                    title={props.t("Deviation Control")}
                    description={props.t("With our sophisticated Deviation control system, reallocate duties and affairs, identify the cause of the deviation, and estimate the costs. Likewise, It helps to handle the business workflow and take charge when you have enough time to sustain productivity. With Time and Control, you can identify, monitor, and evaluate the deviation leading to creating mitigating solutions for the future.")}
                    link="/deviation"
                    color="pink"
                    border="grey"
                  />
                </Col>
                <Col md={6} lg={6}>
                  <Feature
                    src={ScheduleIcon}
                    title={props.t("Vacation & Sick-leave")}
                    description={props.t("Track your employee’s leaves, absences, and vacations on the common platform with Time and Control. It helps to Know when your employees will go on leave and redirect tasks to ensure project completion so that your business complies with the regulations & policies. Moreover, you can also get notifications of leave requests on your device, give approval or rejection, and shuffle assignments accordingly.")}
                    link="/vacation-and-absence"
                    color="blue"
                    border="grey"
                  />
                </Col>
                <Col md={6} lg={6}>
                  <Feature
                    src={TimeIcon}
                    title={props.t("Reporting Features")}
                    description={props.t("Instead of time consumed on repetitive tasks every day, your team can quickly and efficiently complete the day using Time & Control’s features. They can easily capture pictures and videos, record notes and data entry, attach/save relevant documents and files as well. It provides refined and accurate reports for all staff as well as upper management.")}
                    link="/reports"
                    color="orange"
                    border="grey"
                  />
                </Col>
              </Row>
            </div>
          </Container>
        </article>
        <Contact/>
        <Footer/>
      </div>
    </>
  );
}))
export default withTranslation()(Features);