import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Col, Row } from 'react-bootstrap';
import { v4 as uuid } from 'uuid';

//components
import withLocalization from '~/hoc/withLocalization';
import RectangularCard from '../../../components/Card/RectangularCard';
import UserProfileLogo from "../../../components/UserProfileLogo";

//elements
import Checkbox from '../../../components/CustomCheckbox/CustomCheckbox.jsx';
import Button from '../../../components/CustomButton/CustomButton.jsx';

//utils
import { shortDateFormat, timeFormat } from '../../../utils/dateFormat'

//assets
import swapLogo from "~/assets/img/swap-right-line.svg";

import './Schedule.css';

const ShiftCard = ({ shift, user, task, dateTimeRules, status, statusBg, cardBgClassname = "rectangle-card-bg-D5DDF2" }) => {
    return (
        <RectangularCard backgoundClass={cardBgClassname} style={{ minHeight: "20px", height: "90px" }}>
            <Col xs={3}>
                <UserProfileLogo image_id={user.user_image ? user.user_image : false} width='50px' height="50px" />
            </Col>
            <Col xs={9}>
                <Row className='shift_detail_container'>
                    <Col xs={{ span: 6, offset: 6 }} className={`status ${statusBg}`}>{status}</Col>
                    <Col xs={12} className='name'>{`${user.first_name} ${user.last_name}`}</Col>
                    <Col xs={12} className='details'>{shortDateFormat(shift.for_date, dateTimeRules)} {timeFormat(shift.start_time,)}-{timeFormat(shift.end_time, dateTimeRules)} </Col>
                    <Col xs={12} className='details'>{task.task_number ? `${task.task_number} | ${task.name}` : task.name} </Col>
                </Row>
            </Col>
        </RectangularCard>
    )
}

const AbsenceOrVacationCard = ({ absenceOrVacation, user, t, dateTimeRules }) => {
    return (
        <RectangularCard backgoundClass="rectangle-card-bg-F6DFDF" style={{ minHeight: "20px", height: "90px" }}>
            <Col xs={3}>
                <UserProfileLogo image_id={user.user_image ? user.user_image : false} width='50px' height="50px" />
            </Col>
            <Col xs={9}>
                <Row className='shift_detail_container'>
                    <Col xs={{ span: 5, offset: 7 }} className='status warning text-capitalize'>{t(absenceOrVacation.type)}</Col>
                    <Col xs={12} className='name'>{`${user.first_name} ${user.last_name}`}</Col>
                    <Col xs={12} className='details'>{shortDateFormat(absenceOrVacation.from_date, dateTimeRules)} - {shortDateFormat(absenceOrVacation.to_date, dateTimeRules)}</Col>
                    <Col xs={12} className='details'>{t("Type")} - {absenceOrVacation.absence_type ? `${absenceOrVacation.absence_type} | ${absenceOrVacation.leave_type}` : absenceOrVacation.leave_type} </Col>
                </Row>
            </Col>
        </RectangularCard>
    )
}

const NewShifts = ({ data, usersMap, tasksMap, t, dateTimeRules }) => {
    return (
        <Row className='justify-content-center'>
            {data.map(_shift => {
                return (
                    <Col xs={4} key={uuid()}>
                        <ShiftCard shift={_shift}
                            status={t("Assigned")}
                            user={usersMap[_shift.assignee_id]}
                            task={tasksMap[_shift.task_id]}
                            dateTimeRules={dateTimeRules}
                            statusBg={'success'}
                        />
                    </Col>)
            })}
        </Row>
    )
}
const IntersectedShifts = ({ data, shiftsOverwritten, usersMap, tasksMap, t, dateTimeRules, submit }) => {
    const [checkboxes, setCheckboxes] = useState([...data.map(() => false)]);
    return (
        <Row className='justify-content-center'>
            {data.map((e, index) => {
                const _new_shift = e.newShift;
                const _old_shifts = e.oldShifts;
                return (
                    <>
                        <Col xs={5} className='d-flex me-2' key={uuid()}>
                            <Checkbox
                                inline
                                isChecked={checkboxes[index]}
                                onChange={evt => {
                                    const _checkboxes = checkboxes;
                                    _checkboxes[index] = evt;
                                    setCheckboxes([..._checkboxes]);
                                }}
                            />
                            <ShiftCard shift={_new_shift}
                                status={t(shiftsOverwritten ? "Assigned" : "Copy Failed")}
                                user={usersMap[_new_shift.assignee_id]}
                                task={tasksMap[_new_shift.task_id]}
                                dateTimeRules={dateTimeRules}
                                statusBg={shiftsOverwritten ? 'success' : 'danger'}
                                cardBgClassname={'rectangle-card-bg-DFF7E9'}
                            />
                        </Col>
                        <Col xs={2} key={uuid()}>
                            <div><img src={swapLogo} alt="swap"></img></div>
                        </Col>
                        <Col xs={4} key={uuid()}>
                            {_old_shifts.map(_old_shift => {
                                return (<ShiftCard
                                    shift={_old_shift}
                                    status={t("Old Shift")}
                                    user={usersMap[_old_shift.assignee_id]}
                                    task={tasksMap[_old_shift.task_id]}
                                    dateTimeRules={dateTimeRules}
                                    statusBg={'warning'}
                                    key={uuid()}
                                />
                                )
                            })}
                        </Col>
                        {/* {(data.length - 1) !== index && <hr />} */}
                    </>
                )
            })}
            <Col xs={8} className='mt-3'>
                <Checkbox
                    inline
                    label={t("Check All")}
                    isChecked={checkboxes.find(e => e === false) > -1 ? false : true}
                    onChange={evt => {
                        const _checkboxes = checkboxes.map(() => evt);
                        setCheckboxes([..._checkboxes]);
                    }}
                />
                <div>
                    <p className="fw-bold" style={{ fontSize: "12px" }}>
                        {t("Note")}:&nbsp;
                        {shiftsOverwritten ?
                            t("If you think this was a mistake then please select the shifts you would like to restore and undo changes")
                            :
                            t("Please select shifts to overwrite and submit")
                        }
                    </p>
                </div>
            </Col>
            <Col xs={4} className='text-end mt-3'>
                <Button wd fill onClick={() => submit(shiftsOverwritten, checkboxes)}>
                    {t(shiftsOverwritten ? "Undo Changes" : "Overwrite Shifts")}
                </Button>
            </Col>
        </Row>
    )
}
const FailedToCopy = ({ data, usersMap, tasksMap, t, dateTimeRules }) => {
    return (
        <Row className='justify-content-center'>
            {data.map(e => {
                const _shift = e.newShift;
                const _absenceOrVacation = e.intersectingAbsencesOrVacationForCurrentShift;
                return (
                    <>
                        <Col xs={4} key={uuid()}>
                            <ShiftCard shift={_shift}
                                status={t("Copy Failed")}
                                user={usersMap[_shift.assignee_id]}
                                task={tasksMap[_shift.task_id]}
                                dateTimeRules={dateTimeRules}
                                statusBg={'danger'}
                            />
                        </Col>
                        <Col xs={2} key={uuid()}>
                            <div className=""><img src={swapLogo} alt="swap"></img></div>
                        </Col>
                        <Col xs={5} key={uuid()}>
                            <AbsenceOrVacationCard absenceOrVacation={_absenceOrVacation}
                                user={usersMap[_absenceOrVacation.user_id]}
                                dateTimeRules={dateTimeRules}
                                t={t}
                            />
                        </Col>
                    </>
                )
            })}
        </Row>
    )
}



function AfterCopyResponse(props) {
    const { afterCopyResponse, dateTimeRules, t, closeModal } = props;
    const { newShifts, overwrittenShifts, possibleShiftsForOverwrite, shiftsFailedToCopy, usersMap, tasksMap } = afterCopyResponse;

    useEffect(() => {
        const tabs = {};
        if (possibleShiftsForOverwrite.length) {
            tabs['possibleShiftsForOverwrite'] = false;
        }
        if (shiftsFailedToCopy.length) {
            tabs['shiftsFailedToCopy'] = false;
        }
        if (Object.keys(tabs).length) {
            props.updateVisitedFailedStatusTabs(tabs);
        }
    }, [])

    const handleTabVisits = (tabName) => {
        const _hasVisitedFailedStatusTabs = props.hasVisitedFailedStatusTabs;
        if (_hasVisitedFailedStatusTabs[tabName] !== undefined) {
            _hasVisitedFailedStatusTabs[tabName] = true;
            props.updateVisitedFailedStatusTabs(_hasVisitedFailedStatusTabs);
        }
    }

    const submit = (shiftsOverwritten, checkboxes) => {
        let _newShifts = [], _oldShifts = [];
        if (shiftsOverwritten) {
            overwrittenShifts.forEach((e, index) => {
                if (checkboxes[index]) {
                    _newShifts.push(e.newShift);
                    _oldShifts.push(...e.oldShifts);
                }
            });
        } else {
            possibleShiftsForOverwrite.forEach((e, index) => {
                if (checkboxes[index]) {
                    _newShifts.push(e.newShift);
                    _oldShifts.push(...e.oldShifts);
                }
            })
        }
        closeModal({
            newShifts: _newShifts,
            oldShifts: _oldShifts,
            overwriteShifts: !shiftsOverwritten
        })
    }

    if (!afterCopyResponse) return <></>;
    return (
        <Tabs defaultActiveKey="success" id="afterCopyResponse" onSelect={(e) => handleTabVisits(e)}>
            <Tab eventKey="success" title={t('New Shifts')}>
                <h5 className='fw-600'>{t('Following shifts has been copied and assigned successfully')}.</h5>
                <NewShifts
                    data={newShifts}
                    usersMap={usersMap}
                    tasksMap={tasksMap}
                    t={t}
                    dateTimeRules={dateTimeRules}
                />
            </Tab>
            {!!shiftsFailedToCopy.length &&
                <Tab eventKey="shiftsFailedToCopy" title={<><i className='fa fa-exclamation-circle text-danger' />&nbsp;{t('Copy Failed')}</>}>
                    <h5 className='fw-600'>{t('These shifts could not be copied as they intersect with absence or vacations')}.</h5>
                    <FailedToCopy
                        data={shiftsFailedToCopy}
                        usersMap={usersMap}
                        tasksMap={tasksMap}
                        t={t}
                        dateTimeRules={dateTimeRules}
                    />
                </Tab>
            }
            {!!overwrittenShifts.length &&
                <Tab eventKey="overwritten" title={t('Overwritten shifts')}>
                    <h5 className='fw-600'>{t('Following shifts has been copied and replaced the old ones')}.</h5>
                    <IntersectedShifts
                        shiftsOverwritten={true}
                        data={overwrittenShifts}
                        usersMap={usersMap}
                        tasksMap={tasksMap}
                        t={t}
                        dateTimeRules={dateTimeRules}
                        submit={submit}
                    />
                </Tab>
            }
            {!!possibleShiftsForOverwrite.length &&
                <Tab eventKey="possibleShiftsForOverwrite" title={<><i className='fa fa-exclamation-circle text-danger' />&nbsp;{t('Existing Shifts')}</>}>
                    <h5 className='fw-600'>{t('These shifts could not be copied as the old shifts timings intersects')}.</h5>
                    <IntersectedShifts
                        shiftsOverwritten={false}
                        data={possibleShiftsForOverwrite}
                        usersMap={usersMap}
                        tasksMap={tasksMap}
                        t={t}
                        dateTimeRules={dateTimeRules}
                        submit={submit}
                    />
                </Tab>
            }
        </Tabs>
    )
}

export default withLocalization(AfterCopyResponse)