import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import withLocalization from '../../../hoc/withLocalization';
import { ReactComponent as SuccessIcon } from '../../../assets/img/success.svg';
import { Button } from 'react-bootstrap';
import applicationRouter from '~/hoc/applicationRouter';
export class SignSuccess extends Component {
    componentDidMount() {
        const { signatureStore } = this.props;
        signatureStore.openFullScreenMode();
    }
    backToHome(){
        const { signatureStore } = this.props;
        sessionStorage.removeItem('CaseId');
        signatureStore.closeFullScreenMode()
        signatureStore.resetDocList();
        this.props.router.navigate("/");
    }
    render() {
        const {t} = this.props;
        return (
            <div className="successPage">
                <SuccessIcon />
                <h1>{t('Thank you for signing the document')}</h1>
                <p>{t('You will receive the document pdf in mail once all recipient sign the document.')}</p>
                <Button className='btn btn-fill' onClick={()=>{ this.backToHome()  }}>
                    {t('Back to home')}
                </Button>
            </div>
        );
    }
}

export default inject('signatureStore')(withLocalization(applicationRouter(observer(SignSuccess))));
