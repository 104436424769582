import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { Swiper, SwiperSlide, } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import 'swiper/swiper.min.css';
// import 'swiper/modules/navigation/navigation.scss';
// import 'swiper/modules/pagination/pagination.scss';
// import 'swiper/modules/scrollbar/scrollbar.scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import 'swiper/scss/scrollbar';

import Header from "../../../newComponents/Header";
import Footer from "../../../newComponents/Footer";
import MainHeading from "../../../newComponents/MainHeading";
import PageBanner from "../../../newComponents/PageBanner";
import CoreBox from "../../../newComponents/CoreBox";
import Contact from "../../../newComponents/Contact";

import BannerImg from "../../../assets/images/reportsImg.png";
import AppleIcon from "../../../assets/images/appleIcon.svg";
import GoogleIcon from "../../../assets/images/googleIcon.svg";
import ValuableLogo from "../../../assets/images/valuableLogo.png";
import Icon1 from "../../../assets/images/icon1.svg";
import Icon2 from "../../../assets/images/icon2.svg";
import Icon3 from "../../../assets/images/icon3.svg";
import Icon4 from "../../../assets/images/icon4.svg";
import Icon5 from "../../../assets/images/icon5.svg";
import reportsImg2 from "../../../assets/images/reportsImg2.jpg";
import SliderImg1 from "../../../assets/images/reportSlide1.jpg";
import SliderImg2 from "../../../assets/images/reportSlide2.jpg";
import SliderImg3 from "../../../assets/images/reportSlide3.jpg";
import SliderImg4 from "../../../assets/images/reportSlide4.jpg";

//i18n
import { withTranslation } from "react-i18next"
import { inject, observer } from "mobx-react";

import config from "../../../library/config";

const Reports = inject('authStore', 'commonStore')(observer((props) => {
  return (
    <>
      <div className="landingPageWrapp frameworkApp">
        <Header features="current" />
        <article className="pageBannerBlock grdntBg noPatt  py-60">
          <Container>
            <Row className="g-4 align-items-center">
              <Col lg>
                <MainHeading
                  title={<>{props.t("Features")} <span>{props.t("Reports")}</span></>}
                  description={props.t("Work with accurate and comprehensive information to affirm invoicing payouts and salaries calculated down to the very last detail. Pay reasonable salaries and bills every time. Stop expending resources on payment discrepancies.")}
                />
                <div className="appBtns mt-4">
                  <Link onClick={e => config.redirectToAppStore(e, "apple")} className="btnCommon btnBig w-auto me-3 mb-2"><i className="d-inline-block pe-2"><img src={AppleIcon}  alt="Apple" /></i> {props.t("Apple")}</Link>
                  <Link onClick={e => config.redirectToAppStore(e, "google")} className="btnCommon btnTrans btnBig btnBlackTrans w-auto"><i className="d-inline-block pe-2"><img src={GoogleIcon} alt="Apple" /></i> {props.t("Google")}</Link>
                </div>
              </Col>
              <Col lg={1}></Col>
              <Col lg="auto">
                <PageBanner
                  src={BannerImg}
                  alt="Reports"
                />
              </Col>
            </Row>
          </Container>
          <div className="valuableLogo">
            <img src={ValuableLogo} alt="" />
          </div>
        </article>
        <article className="coreBusnsBlock brPatt  py-80">
          <Container>
            <Row className="g-4 align-items-center">
              <Col lg={6}>
                <MainHeading
                  title={<>{props.t("Observe Work Done")}<br/><span>{props.t("with Ease")}</span></>}
                  customClass="medium noPara m-0"
                />
              </Col>
              <Col lg={6}>
                <div className="text-left">
                  <p>{props.t("Time & Control gives you access to dashboards and an intelligent management technique to trace, record, and observe every employee's activity, followed by formulating comprehensive reports.")}</p>
                  <p>{props.t("Our auto-reporting solution empowers the creation of custom reports with numerous options to filter the consequence as per your requirements.")}</p>
                </div>
              </Col>
            </Row>
            <Row className="g-4 g-xxl-5 mt-5">
              <Col md={6} lg={4}>
                <CoreBox
                  src={Icon1}
                  title={props.t("Predict Future Succes")}
                  description={props.t("With the employee’s personality assessment, Time & Control help to predict the future success of the employee’s job performance. The auto reports integrate personality traits and past performances to predict future performance with consolation.")}
                  color="blue"
                  customClass="style2"
                />
              </Col>
              <Col md={6} lg={4}>
                <CoreBox
                  src={Icon2}
                  title={props.t("Wired Differently")}
                  description={props.t("The Time & Control Report generation feature analyzes performances, tracks KPIs, and identifies opportunities plus bottlenecks while illustrating the information in evident terminology.")}
                  color="blue"
                />
              </Col>
              <Col md={6} lg={4}>
                <CoreBox
                  src={Icon3}
                  title={props.t("Reward Resources")}
                  description={props.t("Time & Control bring well-structured and organized data with few clicks and lets you scrutinize the nuances with meticulous differentiation. It helps to give authentic rewards while eliminating redundant data representation.")}
                  color="blue"
                  customClass="style2"
                />
              </Col>
              <Col md={6} lg={4}>
                <CoreBox
                  src={Icon4}
                  title={props.t("Spans Many Dimensions")}
                  description={props.t("One report generation system can wear multiple hats to provide users with a detailed and effective analysis with customizations leading to precision reporting.")}
                  color="blue"
                  customClass="style2"
                />
              </Col>
              <Col md={6} lg={4}>
                <CoreBox
                  src={Icon5}
                  title={props.t("Predefined Absence Rules")}
                  description={props.t("Eradicate all the doubts about calculating payouts and completing invoices by adding pre-defined rules tally to the system. This calculates real-time bills for every one added to the system.")}
                  color="blue"
                  customClass="style2"
                />
              </Col>
              <Col md={6} lg={4}>
                <div className="prsnImg borderRadius h-100" style={{backgroundImage: `url(${reportsImg2})`}}></div>
              </Col>
            </Row>
          </Container>
        </article>
        <article className="prfBlock  py-80 pb-0">
          <Container>
            <div className="py-40">
              <Row className="g-4 g-lg-5">
                <Col lg={5}>
                  <MainHeading
                    title={<>{props.t("Enterprise-Ready")}<br/><span>{props.t("Reporting Solutions")}</span></>}                  
                    customClass="noPara m-0"
                  />
                </Col>
                <Col lg={1} className="d-none d-lg-inline-block"></Col>
                <Col lg={6}>
                  <div className="text-left">
                    <p className="mb-2">{props.t("With Time & Control, businesses will achieve 360-degree visibility into their employee payouts and invoicing. With this, you can generate on-demand reports contributing to effective management and analysis of every activity within the organization.")}</p>
                    <p className="mb-2">{props.t("Our reporting system is added with several integrations to collate all data in one place and determine the promising outcomes as per the market trends.")}</p>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
          <div className="prfSldr mt-5">
            <Swiper modules={[Navigation, Pagination, Scrollbar, A11y]} navigation spaceBetween={0} slidesPerView={3.4} centeredSlides={false} loop={true}
              breakpoints={{
                320: {
                  slidesPerView: 2,
                  spaceBetween: 0,
                },
                576: {
                  slidesPerView: 2,
                  spaceBetween: 0,
                },
                768: {
                  slidesPerView: 2.4,
                  spaceBetween: 0,
                },
                992: {
                  slidesPerView: 3,
                  spaceBetween: 0,
                },
                1200: {
                  slidesPerView: 3.4,
                  spaceBetween: 0,
                },
              }}
            >
              <SwiperSlide>
                <div className="prfSldImg" style={{backgroundImage: `url(${SliderImg1})`}}></div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="prfSldImg" style={{backgroundImage: `url(${SliderImg2})`}}></div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="prfSldImg" style={{backgroundImage: `url(${SliderImg3})`}}></div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="prfSldImg" style={{backgroundImage: `url(${SliderImg4})`}}></div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="prfSldImg" style={{backgroundImage: `url(${SliderImg1})`}}></div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="prfSldImg" style={{backgroundImage: `url(${SliderImg2})`}}></div>
              </SwiperSlide>
            </Swiper>
          </div>
        </article>
        <Contact/>
        <Footer/>
      </div>
    </>
  );
}))
export default withTranslation()(Reports);