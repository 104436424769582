import React from "react";
import { Link} from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import Header from "../../../newComponents/Header";
import Footer from "../../../newComponents/Footer";
import MainHeading from "../../../newComponents/MainHeading";
import PageBanner from "../../../newComponents/PageBanner";
import IconBox from "../../../newComponents/IconBox";
import Contact from "../../../newComponents/Contact";

import BannerImg from "../../../assets/images/checklistBnrImg.png";
import AppleIcon from "../../../assets/images/appleIcon.svg";
import GoogleIcon from "../../../assets/images/googleIcon.svg";
import ValuableLogo from "../../../assets/images/valuableLogo.png";
import CheckFeau1 from "../../../assets/images/checkFeau1.png";
import CheckFeau2 from "../../../assets/images/checkFeau2.png";
import CheckFeau3 from "../../../assets/images/checkFeau3.png";
import contractImg1 from "../../../assets/images/contractImg1.png";
import ChecklistImg2 from "../../../assets/images/checklistImg2.png";
import PerformanceIcon from "../../../assets/images/performanceIcon.png";
import DocumentIcon from "../../../assets/images/documentIcon.png";
import InstructionIcon from "../../../assets/images/instructionIcon.png";
import ChecklistIcon from "../../../assets/images/checklistIcon.png";
import ChecklistIcon1 from "../../../assets/images/checklistIcon1.png";
import ChecklistIcon2 from "../../../assets/images/checklistIcon2.png";
import DocumentMainImg from "../../../assets/images/documentsMainImg.jpg";
import DocumentImg1 from "../../../assets/images/vacationFeatureImg.png";

//i18n
import { withTranslation } from "react-i18next"
import { inject, observer } from "mobx-react";

import config from "../../../library/config";

const Checklist = inject('authStore', 'commonStore')(observer((props) => {
  return (
    <>
      <div className="landingPageWrapp frameworkApp">
        <Header features="current" />
        <article className="pageBannerBlock grdntBg noPatt  py-60">
          <Container>
            <Row className="g-4 align-items-center">
              <Col lg={6}>
                <MainHeading
                  title={<>{props.t("Feature")} <span>{props.t("Documents")}</span></>}
                  description={props.t("Streamline document management, storage, and access with Time & Control’s high-efficiency solution. Effective document management lets you take control of the tasks, access documents from the central storage repository, and manage content efficiently.")}
                />
                <div className="appBtns mt-4">
                  <Link onClick={e => config.redirectToAppStore(e, "apple")} className="btnCommon btnBig w-auto me-3 mb-2"><i className="d-inline-block pe-2"><img src={AppleIcon}  alt="Apple" /></i> {props.t("Apple")}</Link>
                  <Link onClick={e => config.redirectToAppStore(e, "google")} className="btnCommon btnTrans btnBig btnBlackTrans w-auto"><i className="d-inline-block pe-2"><img src={GoogleIcon} alt="Apple" /></i> {props.t("Google")}</Link>
                </div>
              </Col>
              <Col lg={6}>
                <PageBanner
                  src={BannerImg}
                  alt="Visitor Register"
                />
              </Col>
            </Row>
          </Container>
          <div className="valuableLogo">
            <img src={ValuableLogo} alt="" />
          </div>
        </article>
        <article className="abtInfoBlock noPatt  py-80">
          <Container>
            <Row className="g-4 align-items-center">
              <Col lg={7} xl={8}>
                <div className="contentInfo">
                  <h5 className="m-0">{props.t("Stay Organized")}</h5>
                </div>
                <MainHeading
                  title={<>{props.t("Quick & Easy Access to")} <span>{props.t("Crucial Documents")}</span></>}
                  description={props.t("Store and track all your digital documents, including PDFs, Word docs, image files, etc. and organize them for quick access from a single portal.")}
                  customClass="medium m-0"
                />
              </Col>
              <Col lg={5} xl={4}>
                <div className="text-left">
                  <div className="d-flex align-items-center gap-3">
                    <div className="iconLeft">
                      <img src={CheckFeau1} alt='CheckFeau1' />
                    </div>
                    <h4 className="m-0">{props.t("Secure storage on the cloud")}</h4>
                  </div>
                  <div className="d-flex align-items-center gap-3 mt-4">
                    <div className="iconLeft">
                      <img src={CheckFeau2} alt='CheckFeau2' />
                    </div>
                    <h4 className="m-0">{props.t("Storage management")}</h4>
                  </div>
                  <div className="d-flex align-items-center gap-3 mt-4">
                    <div className="iconLeft">
                      <img src={CheckFeau3} alt='CheckFeau3' />
                    </div>
                    <h4 className="m-0">{props.t("One-click access")}</h4>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </article>
        <article className="abtInfoBlock  py-60">
          <Container>
            <Row className="g-4 g-xl-5 align-items-center">
              <Col lg={6} xl={6}>
                <div className="text-center">
                  <img src={contractImg1} alt="" />
                </div>
              </Col>
              <Col lg={6} xl={6}>
                <div className="contentInfo">
                  <h5 className="mb-2">{props.t("Controlling Enterprise Documentation")}</h5>
                </div>
                <MainHeading
                  title={<>{props.t("Save Space, Money, and")} <span>{props.t("Time with Digital Documentation")}</span></>}
                  customClass="medium noPara"
                />
                
                <div className="list">
                  <ul>
                    <li>{props.t("Greater control of documents")}</li>
                    <li>{props.t("Better governance and management")}</li>
                    <li>{props.t("Optimize document management costs")}</li>
                    <li>{props.t("Use pre-existing templates")}</li>
                    <li>{props.t("Centralized document management")}</li>
                    <li>{props.t("Gain complete control and visibility")}</li>
                    <li>{props.t("Ability to manage the document lifecycle")}</li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </article>
        <article className="abtInfoBlock noPatt ">
          <div className="hdngBg py-60">
            <Container>
              <div className="contentInfo text-center">
                <h5 className="m-0">{props.t("Better Functionality and Capacity")}</h5>
              </div>
              <MainHeading
                title={<>{props.t("Solve Complex Document Management")}<br/><span>{props.t("Challenges Easily")}</span></>}
                customClass="medium noPara text-center"
              />
            </Container>
          </div>
          <Container>
            <div className="mtop100 py-60 pt-0">
              <Row className="g-4 g-xl-5">
                <Col md={6} lg={6}>
                  <div className="whiteBox">
                    <div className="mb-4">
                      <img src={ChecklistIcon1} alt='ChecklistIcon1' />
                    </div>
                    <MainHeading
                      title={<>{props.t("Store and")} <span>{props.t("Access")}</span></>}
                      customClass="small noPara text-center"
                    />
                    <div className="text-center">
                      <p>{props.t("With Time & Control, you will get unlimited storage to store and secure your documents. Access these documents from anywhere, any device, at any time, as long as you have the login credentials.")}
  </p>
                    </div>
                  </div>
                </Col>
                <Col md={6} lg={6}>
                  <div className="whiteBox">
                    <div className="mb-4">
                      <img src={ChecklistIcon2} alt='ChecklistIcon2'/>
                    </div>
                    <MainHeading
                      title={<>{props.t("Improve Workflows")} <span>{props.t("and Content Management")}</span></>}
                      customClass="small noPara text-center"
                    />
                    <div className="text-center">
                      <p>{props.t("Work with a well-structured document management system giving you better control over the workflow and effective control over contents and archives")}</p>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </article>
        <article className="abtInfoBlock noPatt ">
          <Container>
            <div className="abtMainImg mt-4 mt-lg-5" style={{backgroundImage: `url(${DocumentMainImg})`}}></div>
          </Container>
        </article>
        <article className="whyBlock grdntBg  py-80">
          <Container>
            <div className="contentInfo text-center">
              <h5 className="m-0">{props.t("Optimizing Document Workload")}</h5>
            </div>
            <MainHeading
              title={<>{props.t("Lay a Strong Foundation for")} <span>{props.t("Your Documents and Files")}</span></>}
              description={props.t("Use Time & Control for Easy Management")}
              customClass="medium text-center"
            />
            <Row className="g-4">
              <Col md={6} lg={3}>
                <IconBox
                  src={PerformanceIcon}
                  title={props.t("Store More with Less")}
                  description={props.t("Accumulate an unlimited amount of documents with digitized paperwork.")}
                  color="blue"
                  customClass="style2"
                />
              </Col>
              <Col md={6} lg={3}>
                <IconBox
                  src={DocumentIcon}
                  title={props.t("Optimize Workflows")}
                  description={props.t("Validate tasks, and approve contracts with single-point document management.")}
                  color="orange"
                  customClass="style2"
                />
              </Col>
              <Col md={6} lg={3}>
                <IconBox
                  src={InstructionIcon}
                  title={props.t("Document Lifecycle")}
                  description={props.t("Manage the entire lifecycle of a document on a single platform")}
                  color="green"
                  customClass="style2"
                />
              </Col>
              <Col md={6} lg={3}>
                <IconBox
                  src={ChecklistIcon}
                  title={props.t("Stay Organized")}
                  description={props.t("An intuitive user interface gives seamless access to all documents and collaboration.")}
                  color="green"
                  customClass="style2"
                />
              </Col>
            </Row>
          </Container>
        </article>
        <article className="abtInfoBlock noPatt  py-60">
          <Container>
            <Row className="g-4 g-xl-5 align-items-center">
              <Col lg={6}>
                <div className="text-center">
                  <img src={ChecklistImg2} alt="" />
                </div>
              </Col>
              <Col lg={6}>
                <div className="contentInfo">
                  <h5 className="mb-2">{props.t("Reducing Costs and Increasing Efficiency")}</h5>
                </div>
                <MainHeading
                  title={<>{props.t("Time & Control With")}<br/><span>{props.t("Electronic File Management")}</span></>}
                  customClass="medium noPara"
                />
                <div className="list">
                  <ul>
                    <li>{props.t("Automate document management")}</li>
                    <li>{props.t("Improve business processes")}</li>
                    <li>{props.t("Sort documents with ease")}</li>
                    <li>{props.t("File auditing and document processing")}</li>
                    <li>{props.t("Generate reports for document management")}</li>
                    <li>{props.t("Stay compliant in document management")}</li>
                    <li>{props.t("Securely collaborate and share documents")}</li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </article>
        <article className="rgstrBlock  py-60">
          <Container>
            <div className="rgstrInrBlock grdntBg">
              <Row className="g-4 g-lg-5 align-items-center">
                <Col lg={5}>
                  <div className="rgstrImg">
                    <img src={DocumentImg1} alt="" />
                  </div>
                </Col>
                <Col lg={7}>
                  <MainHeading
                    title={props.t("Automate Workflows with Organized Documents")}
                    customClass="medium noPara m-0"
                  />
                  <div className="contentInfo">
                    <p className="my-3">{props.t("Never lose your documents, maintain workflows, and retain efficiency.")}</p>
                    <h5 className="m-0">{props.t("Contact us Today to Know More!")}</h5>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </article>
        <Contact/>
        <Footer/>
      </div>
    </>
  );
}))
export default withTranslation()(Checklist);