import React from "react";
import "./styles.css";

// function TestimonialItem(props) {
//   return (
//     <div className={`testiCol ${props.customClass}`}>
//       <div className="testiColInfo">
//           <h4>{props.title}</h4>
//           <p>{props.description}</p>
//       </div>
//       <div className="testiColUser">
//         <span>
//           <img src={props.userImg} alt={props.name} />
//         </span>
//         <div>
//           <h5>{props.name}</h5>
//           <p>{props.designation}</p>
//         </div>
//       </div>
//     </div>   
//   );
// }


function TestimonialItem(props) {
  return (
    <div className={`testimonial-client-card`}>
        <img src={props.clientLogo} alt={props.clientName} />
    </div>   
  );
}
export default TestimonialItem;