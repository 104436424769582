import { useState } from "react";
import { Modal, Tab, Tabs } from "react-bootstrap";
import ScheduleSettings from "./ScheduleSettings";
import CloseIcon from "~/assets/img/modal-close-icon.svg";

const Management = (props) => {
    const { t, renderAvailabilityList, setIsAddAvailability, checkAvailibilty, openManagement, closeManagement, userType, isAvailibiltyFeatureEnabled } = props
    const [openSetting, setOpenSetting] = useState(!isAvailibiltyFeatureEnabled);
    const [openAvailibilty, setOpenAvailability] = useState(isAvailibiltyFeatureEnabled);

    const onsettingOpen = (evt) => {
        if (evt === "setting") {
            setOpenSetting(true);
            setOpenAvailability(false);
        }
        else {
            setOpenSetting(false);
            setOpenAvailability(true);
        }
    }

    return (
        <Modal show={openManagement} onHide={() => closeManagement()} className="modal right fade">
            <Modal.Body>
                <div className="scheduleModalHeader">
                    <div className="scheduleModalClosebtn" onClick={() => closeManagement()}><img src={CloseIcon} alt="close icon"></img></div>
                    <div className="mngHeadTxt">{t('Management')}</div>
                </div>
                <div className="scheduleModalBody">
                    <Tabs onSelect={onsettingOpen} className="scheduleManagementTabs">
                        {isAvailibiltyFeatureEnabled &&
                            <Tab eventKey="Availability" title={t('Availability')}>
                                {!!openAvailibilty &&
                                    <>
                                        <div className="avblty-btns">
                                            <button className="btn-fill btn-wd btn btn-grey" onClick={() => checkAvailibilty()}> {t('Request Availability')}</button>
                                            {userType !== 'admin' && <button className="btn-fill btn-wd btn btn-primary" onClick={() => setIsAddAvailability(true)}>{t('Add Availability')} </button>}
                                        </div>
                                        {renderAvailabilityList()}
                                    </>
                                }
                            </Tab>
                        }
                        <Tab eventKey="setting" title={t('Shift Requests')}>
                            {openSetting ? <ScheduleSettings /> : ''}
                        </Tab>
                    </Tabs>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default Management;