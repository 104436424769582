import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { confirmAlert } from 'react-confirm-alert';
import { Modal } from 'react-bootstrap';

// Components
import applicationRouter from '~/hoc/applicationRouter';
import GenericList from '~/components/GenericList/GenericList';
import withRemoveDialog from '~/hoc/withRemoveDialog';
import withLocalization from '~/hoc/withLocalization';
import ElementTreeView from './ElementTreeView';
import { AttachmentsWidget } from '../../../../components/GenericForm/Widgets/AttachmentsWidget';
import GenericFilter from '../../../../components/GenericList/GenericFilter';

//elements
import { Textarea } from '../../../../elements/Input';
import Button from '../../../../components/CustomButton/CustomButton.jsx';

//utils
import { shortDateFormat } from '~/utils/dateFormat';

//assets
import editIcon from '../../../../assets/img/editing.svg';
import deleteIcon from '../../../../assets/img/deleting.svg';
import CloseModalIcon from '../../../../assets/img/CloseApproveModalIcon.svg'


//styles
import '../../../../styles/css/styles.css';
class ElementList extends Component {
    state = {
        showaddelement: false,
        showElemntModal: false,
        currentmodelelement: null,
        currentmodelelementarray: [],
        mode: '',
        name: '',
        comment: '',
        attachments: '',
        sub: false,
        parentElement: null,
        isSubCategory: false,
        showElemntCopyModal: false,
        copyElement: null,
        newCopyElementname: '',
    };

    componentWillMount() {
        this.props.checklistStore.setElementFilter('name', '');
        this.props.checklistStore.onElementFilter();
    }

    Addelementbutton = () => {
        let x = !this.state.showaddelement;
        this.setState({ showaddelement: x });
    };

    closeaddelement = () => {
        this.setState({ showaddelement: false });
    };

    renderTableActionButtons = (id, full) => {
        return (
            <div className="actions-center actions-center__center">
                <Button icon_sm fill onClick={() => this.handleTableButtonAction(id, 'edit')}>
                    <img src={editIcon} alt={'edit button'} />
                </Button>
                <Button icon_sm_delete fill onClick={() => this.handleTableButtonAction(id, 'delete')}>
                    <img src={deleteIcon} alt="delete button" />
                </Button>
                <Button icon_sm fill onClick={() => this.handleTableButtonAction(id, 'copy', full)}>
                    <i className="fa fa-copy actionblueicon" />
                </Button>
            </div>
        );
    };

    handleTableButtonAction = async (id, type, full) => {
        if (type === 'edit') {
            let reqData = {
                parent_id: id,
            };

            await this.props.checklistStore.getElementChildByParentId(reqData).then((element) => {
                this.setState(
                    {
                        currentmodelelement: element.elements,
                        currentmodelelementarray: element.elements.elements,
                        mode: 'Edit Main Catogary',
                        name: element.elements.name,
                        comment: element.elements.comment,
                        attachments: element.elements.attachments,
                    },
                    () => {
                        this.renderAttachments();
                    }
                );
                this.renderElementModal();
            });
        } else if (type === 'add') {
            this.setState({ mode: 'Add Main Catogary' });

            this.setState({
                currentmodelelement: null,
                name: '',
                comment: '',
                attachments: '',
                showElemntModal: true,
                sub: this.state.sub,
            });
        } else if (type === 'delete') {
            const str = `Are you sure , You want to delete ?`;
            confirmAlert({
                title: this.props.t('Confirm to delete'),
                message: str,
                buttons: [
                    {
                        label: this.props.t('Yes'),
                        onClick: () => {
                            return this.props.checklistStore.deleteElementById(id).then((res2) => {
                                this.props.checklistStore.resetLastListLoadTime();
                                this.props.commonStore.addNotification(this.props.t('Deleted'), null, 'success');

                                return res2;
                            });
                        },
                    },
                    {
                        label: this.props.t('No'),
                        onClick: () => { },
                    },
                ],
            });
        } else if (type === 'copy') {
            this.setState(
                {
                    copyElement: full,
                },
                () => {
                    this.renderElementCopyModal();
                }
            );
        }
    };

    renderElementModal = () => {
        this.setState({
            showElemntModal: true,
            sub: this.state.sub,
        });
        if (this.state.currentmodelelement && this.state.currentmodelelement.name) {
            this.setState({
                name: this.state.currentmodelelement.name,
                comment: this.state.currentmodelelement.comment,
                attachments: this.state.currentmodelelement.attachments,
            });
        }
    };

    handleCloseElemntModal = () => {
        this.setState({ name: '', comment: '', sub: false, attachments: '' });
        this.setState({
            showElemntModal: false,
        });
        this.props.checklistStore.resetLastListLoadTime();
    };

    renderElementCopyModal = () => {
        this.setState({
            showElemntCopyModal: true,
            newCopyElementname: this.state.copyElement.name,
        });
    };

    handleCloseElemntCopyModal = () => {
        this.setState({
            showElemntCopyModal: false,
        });
        this.props.checklistStore.resetLastListLoadTime();
    };

    copyElementSave = async () => {
        if (this.state.newCopyElementname === '' || this.state.newCopyElementname === null) {
            this.props.commonStore.addNotification(this.props.t('Please fill Name.'), null, 'error');
            return;
        }
        let reqData = {
            parent_id: this.state.copyElement.id,
            name: this.state.newCopyElementname,
        };

        await this.props.checklistStore.copyElementById(reqData).then((element) => {
            this.props.commonStore.addNotification(this.props.t('Copied Successfully'), null, 'success');
            this.handleCloseElemntCopyModal();
        });
    };

    handleChange = (name, value) => {
        if (name === 'name') {
            this.setState({ name: value });
        } else if (name === 'comment') {
            this.setState({ comment: value });
        } else if (name === 'newCopyElementname') {
            this.setState({ newCopyElementname: value });
        }
    };

    saveParentElement = async () => {
        if (this.state.currentmodelelement !== null) {
            this.setState({ mode: 'Edit Main Catogary' }, () => {
                this.addelementclick();
            });
        } else {
            this.setState({ mode: 'Add Main Catogary' }, () => {
                this.addelementclick();
            });
        }
    };

    handleAddsubelement = async () => {
        if (this.state.mode === 'Add Main Catogary' || this.state.mode === 'Edit Main Catogary') {
            if (this.state.name === undefined || this.state.name === '') {
                this.props.commonStore.addNotification(this.props.t('Please fill Name.'), null, 'error');
                return;
            }
            this.setState({ mode: 'Add Sub Category', isSubCategory: true });
        }
        await this.addelementclick();
        if (this.state.currentmodelelement && this.state.currentmodelelement.id > 0) {
            this.setState({
                currentmodelelement: this.state.currentmodelelement,
                currentmodelelementarray: this.state.currentmodelelement.elements,
                sub: true,
                name: '',
                comment: '',
                attachments: '',
            });
        }
    };

    addelementclick = async () => {
        if (this.state.mode.toLowerCase().includes('add')) {
            let data = {};
            data = {
                name: this.state.name,
                parent_id: 0,
                comment: this.state.comment,
                attachments: this.state.attachments,
            };
            if (
                this.state.mode === 'Add Main Catogary' &&
                this.state.currentmodelelement &&
                this.state.currentmodelelement.id > 0
            ) {
                data.id = this.state.currentmodelelement.id;
            }
            if (data.name === undefined || data.name === '') {
                this.props.commonStore.addNotification(this.props.t('Please fill Name.'), null, 'error');
                return;
            }
            if (this.state.currentmodelelement && this.state.currentmodelelement.id > 0 && this.state.sub === true) {
                data.parent_id = this.state.currentmodelelement.id;
            }
            await this.props.checklistStore.addelement(data).then(async (data) => {
                this.props.commonStore.addNotification(this.props.t('Saved'), null, 'success');
                if (data !== undefined) {
                    var addedElement = JSON.parse(JSON.stringify(data));
                    if (data.parent_id === 0) {
                        addedElement.elements = [];
                        this.setState({ currentmodelelement: addedElement });
                    } else {
                        let reqData = {
                            parent_id: data.parent_id,
                        };

                        await this.props.checklistStore.getElementChildByParentId(reqData).then((element) => {
                            this.setState(
                                {
                                    currentmodelelement: element.elements,
                                    currentmodelelementarray: element.elements.elements,
                                    name: element.elements.name,
                                    comment: element.elements.comment,
                                    attachments: element.elements.attachments,
                                    isSubCategory: false,
                                    mode: 'Edit Main Catogary',
                                },
                                () => {
                                    this.renderAttachments();
                                }
                            );
                        });
                    }
                }
            });
        } else if (this.state.mode.toLowerCase().includes('edit')) {
            let data = this.state.currentmodelelement;
            data.name = this.state.name;
            data.comment = this.state.comment;
            data.attachments = this.state.attachments;
            await this.props.checklistStore.addelement(data).then((data) => {
                this.props.commonStore.addNotification(this.props.t('Updated'), null, 'success');
            });
        }
    };

    handleEditorChange = (e) => {
        this.setState({ comment: e.target.getContent() });
    };

    renderAttachments = (e) => {
        const { checklistStore } = this.props;
        var dataReq = {
            attachmentIds: this.state.attachments,
        };
        checklistStore.getMultipleElements(dataReq).then((elementsResult) => {
            this.setState({ attachmentsList: elementsResult.attachments });
        });
    };

    refreshparentmodel = async () => {
        let reqData = {
            parent_id: this.state.currentmodelelement.id,
        };
        await this.props.checklistStore.getElementChildByParentId(reqData).then(async (elementData) => {
            this.setState(
                {
                    currentmodelelement: elementData.elements,
                    currentmodelelementarray: elementData.elements.elements,
                    mode: 'Edit Main Catogary',
                    name: elementData.elements.name,
                    comment: elementData.elements.comment,
                    attachments: elementData.elements.attachments,
                },
                () => {
                    this.renderAttachments();
                }
            );
            this.handleCloseElemntModal();
            setTimeout(() => {
                this.renderElementModal();
            }, 400);
        });
    };

    render() {
        const { t } = this.props;
        const { loading, elementFilters, elementAppliedFilters } = this.props.checklistStore;
        const { config } = this.props.commonStore;
        const dateTimeRules =
            config.client && config.client.data && config.client.data.dateTimeRules
                ? config.client.data.dateTimeRules
                : false;
        return (
            <div>
                <div>
                    <div className="table-list__header">
                        <Button fill wd onClick={() => this.handleTableButtonAction(0, 'add')}>
                            <i className="fa fa-plus-circle fa-xl margin-right-10" />
                            {this.props.t('Add')}
                        </Button>
                    </div>
                    <GenericList
                        columns={[
                            {
                                Header: 'Name',
                                id: 'name',
                                accessor: ({ name }) => <span>{name}</span>,
                                isSortable: true,
                            },
                            {
                                Header: 'Date',
                                id: 'created_at',
                                accessor: ({ created_at }) => (
                                    <span>{created_at && shortDateFormat(created_at, dateTimeRules)}</span>
                                ),
                                isSortable: true,
                            },
                            {
                                Header: 'User',
                                id: 'user_name',
                                accessor: ({ first_name, last_name }) => (
                                    <span>{`${first_name} ${last_name}`}</span>
                                ),
                            },
                            {
                                Header: 'Actions',
                                id: 'actions',
                                accessor: (full) => this.renderTableActionButtons(full.id, full),
                            },
                        ]}
                        forceReload={this.props.checklistStore.deleteSuccess || this.props.checklistStore.updated}
                        filters={elementAppliedFilters}
                        lastListLoadTime={this.props.checklistStore.lastListLoadTime}
                        header={
                            <GenericFilter
                                filters={elementFilters}
                                setFilter={(name, value) => this.props.checklistStore.setElementFilter(name, value)}
                                onFilter={() => this.props.checklistStore.onElementFilter()}
                                loading={loading}
                            />
                        }
                        requestData={(params) => this.props.checklistStore.getElementTemplateByClientId(params)}
                    />
                </div>

                <Modal
                    className="modal right fade"
                    show={this.state.showElemntModal}
                    onHide={this.handleCloseElemntModal}
                >
                    <Modal.Header>
                        <Modal.Title>
                            {this.state.mode === 'Add Main Catogary' ? t('Add main catagory') : ''}
                            {this.state.mode === 'Edit Main Catogary' ? t('Edit main catagory') : ''}
                            {this.state.mode === 'Add Sub Category' ? t('Add sub catagory') : ''}
                            {this.state.mode === 'Edit Sub Catogary' ? t('Edit sub catagory') : ''}
                            {this.state.mode === 'Add Checklist' ? t('Add Checklist') : ''}
                            {this.state.mode === 'Edit Checklist' ? t('Edit Checklist') : ''}
                        </Modal.Title>
                        <img
                            src={CloseModalIcon}
                            alt='closeIcon'
                            className="cursor-pointer"
                            onClick={this.handleCloseElemntModal}
                        />
                    </Modal.Header>
                    <Modal.Body style={{ maxHeight: 'calc(100vh - 130px)', minHeight: '80vh', overflowY: 'auto' }}>
                        <div className="form-group field field-string">
                            <Textarea
                                label={`${t('Name')} *`}
                                value={this.state.name}
                                style={{ width: '100%' }}
                                name="name"
                                onChange={(e) => this.handleChange('name', e.target.value)}
                                placeholder={t('Enter Name here')}
                            />
                        </div>
                        <div className="attachment-box mt-5" style={{ width: '100%' }}>
                            <span className="attachment-box__label">{t('Upload Files')}</span>
                            <AttachmentsWidget
                                value={this.state.attachments}
                                onChange={(attachIds) => {
                                    this.setState({
                                        attachments: attachIds,
                                    });
                                }}
                                options={{
                                    imageContext: {
                                        existingAttachment: this.state.attachmentsList,
                                        fileType: 'docs',
                                        id: 0,
                                        model: 'Image',
                                    },
                                }}
                            />
                        </div>
                        <br />

                        <Textarea
                            label={t('Comment')}
                            value={this.state.comment}
                            style={{ width: '100%' }}
                            name="comment"
                            onChange={(e) => this.handleChange('comment', e.target.value)}
                            placeholder={t('Enter comments here')}
                        />
                        <div className='mt-3'>
                            <div>
                                <Button fill onClick={this.addelementclick}>
                                    {t('Save')}
                                </Button>
                            </div>
                            <br />
                            {t('After adding a Main Category, add minimum one sub category to create checklist')}

                            <br />
                            <div>
                                {this.state.mode !== 'Add Sub Category' && (
                                    <Button fill wd onClick={this.handleAddsubelement}>
                                        {this.props.t('Add sub catagory')} <i className="fa fa-plus" />
                                    </Button>
                                )}
                            </div>

                            {this.state.mode.toLowerCase().includes('edit') &&
                                this.state.currentmodelelementarray.length > 0
                                ? this.state.currentmodelelementarray.map(item => (
                                    <ElementTreeView
                                        data={item}
                                        key={item.id}
                                        refresh={this.reloadmodel}
                                        closeparentmodel={this.refreshparentmodel}
                                    />
                                ))
                                : null}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleCloseElemntModal}>
                            {t('Close')}
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    className="modal fade"
                    show={this.state.showElemntCopyModal}
                    onHide={this.handleCloseElemntCopyModal}
                >
                    <Modal.Header>
                        <Modal.Title>{t('Copy Element')}</Modal.Title>
                        <img
                            src={CloseModalIcon}
                            alt='closeIcon'
                            className="cursor-pointer"
                            onClick={this.handleCloseElemntCopyModal}
                        />
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-group field field-string">
                            <Textarea
                                label={t('Name')}
                                value={this.state.newCopyElementname}
                                style={{ width: '100%' }}
                                name="newCopyElementname"
                                onChange={(e) => this.handleChange('newCopyElementname', e.target.value)}
                                placeholder={t('Enter Name here')}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button fill onClick={this.copyElementSave}>
                            {'Save'}
                        </Button>
                        <Button variant="secondary" onClick={this.handleCloseElemntCopyModal}>
                            {t('Close')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default inject(
    'checklistStore',
    'commonStore'
)(applicationRouter(withLocalization(withRemoveDialog(observer(ElementList)))));
