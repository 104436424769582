import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

//components
import withLocalization from '../../../hoc/withLocalization';
import CustomMultiSelect from '../../../elements/CustomMultiSelect';

class DocumentTemplateWidget extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: [],
            options: [],
        };
        this.loadData = this.loadData.bind(this);
        this.handleSelectedChanged = this.handleSelectedChanged.bind(this);
    }
    

    async loadData() {
        const { documentStore, user_id, t, commonStore } = this.props;
        await documentStore.getDocumentTemplateAssignList(user_id)
            .then((data) => {
                let optionArray = [];
                data.document_templates.forEach(item => {
                    optionArray.push({
                        label: item.template_name,
                        value: item.id
                    })
                })
                const selectedDocumentIds = data?.assigned_documents && data?.assigned_documents.length ?
                    data.assigned_documents.map(ele => { return ele.document_id })
                    : []
                this.props.handleChange(selectedDocumentIds);
                this.setState({ options: optionArray, selected: selectedDocumentIds })
            })
            .catch(err => {
                commonStore.addNotification(t(err), null, 'error');
            });
    }

    componentDidMount() {
        this.loadData();
    }

    handleSelectedChanged = selected => {
        this.setState({ selected: selected});
        this.props.handleChange(selected);
    };

    render() {
        const { t } = this.props;
        const { options, selected } = this.state;
        return (
            <div className="profile-edit-form__group mt-4 p-4">
                <span className="form-subtitle">{t('Documents')}</span>
                <div className='mt-3'>
                    {options.length ?
                        <CustomMultiSelect
                            options={options}
                            value={selected}
                            onChange={this.handleSelectedChanged}
                        />:
                        <>
                            <span>{t("No data found")}</span>
                        </>
                    }
                </div>
            </div>
        );
    }
}

export default inject('documentStore', 'userStore', 'commonStore')(withLocalization(observer(DocumentTemplateWidget)));