import React, { useState, useRef, useEffect } from 'react';
import './OtpInput.css';

function OTPInput({ length, type, onSubmit, hasError, t, children }) {
    const [otp, setOTP] = useState('');
    const [error, setError] = useState(hasError || '');
    const inputRefs = useRef([]);

    useEffect(() => {
        if(hasError)
            setError(hasError);
    },[hasError]);

    const handleInputChange = (index, value) => {
        let newValue = value;
        let _error = '';
        if (type === 'number' && isNaN(Number(value))) {
            newValue = '';
            _error = t('Only numbers are allowed');
        } else if (type === 'alpha' && !/^[a-zA-Z]+$/.test(value)) {
            newValue = '';
            _error = t('Only alphabets are allowed');
        } else if (type === 'alphanumeric' && !/^[a-zA-Z0-9]+$/.test(value)) {
            newValue = '';
            _error = t('Only alphabets and numbers are allowed');
        }

        const newOTP = otp.split('');
        newOTP[index] = newValue;
        setOTP(newOTP.join(''));
        setError(_error);

        if (!_error && newValue !== '' && index < length - 1) {
            inputRefs.current[index + 1].focus();
        }
    };

    const handlePaste = (event) => {
        event.preventDefault();
        const pastedOTP = event.clipboardData.getData('text/plain').slice(0, length);
        const newOTP = pastedOTP.split('').map((char, index) => {
            if (type === 'number' && isNaN(Number(char))) {
                return '';
            } else if (type === 'alpha' && !/^[a-zA-Z]+$/.test(char)) {
                return '';
            } else if (type === 'alphanumeric' && !/^[a-zA-Z0-9]+$/.test(char)) {
                return '';
            }
            return char;
        });
        setOTP(newOTP.join(''));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (otp.length === length) {
            onSubmit(otp);
        } else {
            setError(t('Please enter a valid OTP'));
        }
    };

    return (
        <div className='otp-container'>
            <form onSubmit={handleSubmit}>
                <div className='otp-input-container'>
                    {Array.from({ length }, (_, index) => (
                        <input
                            key={`otp_key_${index}`}
                            type={type === 'number' ? 'tel' : 'text'}
                            maxLength={1}
                            value={otp[index] || ''}
                            onChange={(e) => handleInputChange(index, e.target.value)}
                            onPaste={handlePaste}
                            onKeyDown={(e) => {
                                if (e.key === 'Backspace' && !otp[index] && index > 0) {
                                    inputRefs.current[index - 1].focus();
                                }
                            }}
                            ref={(ref) => (inputRefs.current[index] = ref)}
                        />
                    ))}
                </div>
                <div className='submit-container'>
                    {error && <span className="error-container">{error}</span>}
                    {children}
                </div>
            </form>
        </div>
    );
}

export default OTPInput;