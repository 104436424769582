import React, { Component } from 'react';
import { find } from 'lodash';

import Select from '../../../elements/Select';
import { minutesToTimeInput, getMinutesFromTimeInput } from '../../../utils/timeCalc';

class TimeSelectWidget extends Component {
    render() {
        let min = 0;
        let max = 1440;
        const step = 30;
        const { value, onChange, ...rest } = this.props;
        min = +min;
        max = +max;
        const options = [];

        while (min < max) {
            options.push({
                label: minutesToTimeInput(min, true),
                value: min,
            });
            min += step;
        }
        options.push({
            label: minutesToTimeInput(max, true),
            value: max,
        });

        const transformOnChange = (selection) => {
            return this.props.onChange(selection.value);
        };

        const currentSelect = find(options, (o) => {
            return o.value == value;
        });

        return (
            <Select
                classNamePrefix="time-select"
                asyncSelect={false}
                className="time-select"
                optionsWorkaround={options}
                options={options}
                currentSelect={currentSelect}
                onChange={transformOnChange}
                {...rest}
            />
        );
    }
}

export default TimeSelectWidget;
