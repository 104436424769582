import superagentPromise from 'superagent-promise';
import _superagent from 'superagent';

//stores
import commonStore from './stores/commonStore';

//utils
import config from './config';

//localization
import i18n from '../i18n';

const superagent = superagentPromise(_superagent, global.Promise);

const { API_ROOT } = config;

const handleErrors = (err, response) => {
    if (!err) {
        return null;
    }
    if (err && err.response && err.response.status === 401) {
        return commonStore.addNotification('Authentication error', null, 'error');
    }
    if(response && response.body && response.body.errors && response.body.errors.error 
        && response.body.errors.error.code && response.body.errors.error.code === 'invalid_token'){
        if (commonStore) commonStore.addNotification(i18n.t('Token Expired'), null, 'error', 'tr', 4000);
        return;
    }

    if (err && response && response.body && response.body.error && typeof response.body.error === 'string') {
        return commonStore.addNotification(i18n.t(response.body.error), null, 'error')
    }
    if(err && response && response.statusText)
        return commonStore.addNotification(i18n.t(response.statusText),null,'error')
    
    return commonStore.addNotification(i18n.t('Unknown error connecting to the server'), null, 'error');
};

const responseBody = (res) => res.body;

const tokenPlugin = (req) => {
    const multiAdminToken = window.sessionStorage.getItem('multiAdminJwt');
    if (multiAdminToken) {
        req.set('authorization', `Token ${multiAdminToken}`);
    }
};


const requests = {
    del: (url) => superagent.del(`${API_ROOT}${url}`).use(tokenPlugin).end(handleErrors).then(responseBody),
    get: (url) =>
        superagent
            .get(`${API_ROOT}${url}`)
            .use(tokenPlugin)
            .end((e, r) => handleErrors(e, r))
            .then(responseBody),
    put: (url, body) =>
        superagent
            .put(`${API_ROOT}${url}`, body)
            .use(tokenPlugin)
            .end((e, r) => handleErrors(e, r))
            .then(responseBody),
    post: (url, body) =>
        superagent
            .post(`${API_ROOT}${url}`, body)
            .use(tokenPlugin)
            .end((e, r) => handleErrors(e, r))
            .then(responseBody),
};

const MultiAccountAdminAgent = {
    getAccountListForAdmin: () => requests.get('/multi_account_administration'),
    getAccountInfo: (id) => requests.get(`/multi_account_administration/${id}`),
    checkMultiAccountAdminVerificationLink: (id) => requests.get(`/multi_account_administration/checkVerificationLink/${id}`),
    addAccountForMultiAccountAccess: (data) => requests.post('/multi_account_administration',data),
    verifyMultiAccountEmail: (data) => requests.put('/multi_account_administration/verify_email',data),
    generateVerificationOtp: (data) => requests.put('/multi_account_administration/generate_verification_otp',data),
    removeAccountFromMultiAccount: (id) => requests.del(`/multi_account_administration/${id}`),
};


export default MultiAccountAdminAgent;
