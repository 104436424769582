import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import SocialIcon from "../Social";
import "./styles.css";

import logo from "../../assets/images/logoDark.svg";

//i18n
import { withTranslation } from "react-i18next"

const isActive = (linkName) => {
  return  window.location.href.split('/')[3].includes('#')? window.location.href.split('/')[3].split('#')[0] === linkName: window.location.href.split('/')[3] === linkName;
}


function Footer(props) {
  return (
    <footer className="ftrBlock lp_wrapper">
      <div className="ftrMain py-40">
        <Container>
          <Row>
            <Col md={5} lg={5}>
              <div className="ftrWdt">
                <div className="ftrLogo">
                  <Link to="/">
                    <img src={logo} alt="" />
                  </Link>
                </div>
                <p className="my-4">{props.t("Thank you for considering Time & Control for your HR & Workforce Management needs!")}</p>
                <SocialIcon />
              </div>
            </Col>
            <Col lg={1} className="d-none d-lg-inline-block"></Col>
            <Col md={7} lg={6}>
              <div className="ftrWdt">
                <h4>{props.t("Quick Links")}</h4>
                <div className="ftrLinks">
                  <Row className="g-4">
                    <Col xs={12} sm={4}>
                      <ul>
                        <li className={`${isActive('')?'current':''}`}>
                          <Link to="/">{props.t("Home")}</Link>
                        </li>
                        <li className={`${isActive('privacy')?'current':''}`}>
                          <Link to="/privacy">{props.t("Privacy Policy")}</Link>
                        </li>
                      </ul>
                    </Col>
                    <Col xs={12} sm={8}>
                      <ul>
                        <li>
                          <a href="/docs/databehandleravtale.pdf" download={true}>{props.t("Download")} {props.t("GDPR agreement")}</a>
                        </li>
                        <li className={`${isActive('terms')?'current':''}`}>
                          <Link to="/terms">{props.t("User Terms")}</Link>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="ftrCopy">
        <Container>
          <p>© 2024 <Link to="/">{props.t("Time & Control.")}</Link> {props.t("All Rights Reserved.")}</p>
        </Container>
      </div>
    </footer>
  );
}
export default withTranslation()(Footer)