import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

//components//
import applicationRouter from '~/hoc/applicationRouter'
import withLocalization from '~/hoc/withLocalization';
import Button from '../../../components/CustomButton/CustomButton.jsx';


class AdminAddLimitBeforeAdd extends Component {
    state = { error: null };

    proceed = async () => {
        const { userStore, commonStore, t } = this.props;
        await userStore.increaseUserLimit();
        commonStore.addNotification(t('Request is sent to system administrator'), null, 'success');
    };

    render() {
        const { userStore, t } = this.props;
        const { addPossibility } = userStore;
        const { error } = this.state;

        return (
            <div className="add-user-form">
                <h2>{t('User limit reached')}</h2>
                {t('Currently you have')}&nbsp;{addPossibility.users}&nbsp;{t('users out of')}&nbsp;{addPossibility.max}
                <br />
                <b>{t('Please confirm you want to request 5 more users')}</b>
                <br />
                <br />
                {!!error && <div className="row error">{error}</div>}
                <Button fill wd onClick={() => this.proceed()}>
                    {t('Request and proceed')}
                </Button>
            </div>
        );
    }
}

export default inject('userStore', 'commonStore')(applicationRouter(withLocalization(observer(AdminAddLimitBeforeAdd))));
