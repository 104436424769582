import React from "react";
import { Link} from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

import Header from "../../../newComponents/Header";
import Footer from "../../../newComponents/Footer";
import MainHeading from "../../../newComponents/MainHeading";
import PageBanner from "../../../newComponents/PageBanner";
import IconBox from "../../../newComponents/IconBox";
import ListFeau from "../../../newComponents/ListFeau";
import Contact from "../../../newComponents/Contact";

import BannerImg from "../../../assets/images/featureImg.png";
import AppleIcon from "../../../assets/images/appleIcon.svg";
import GoogleIcon from "../../../assets/images/googleIcon.svg";
import ValuableLogo from "../../../assets/images/valuableLogo.png";
import ProductivityImg from "../../../assets/images/productivityImg.png";
import EasyAccessImg from "../../../assets/images/easyAccessImg.png";
import ContractsImg from "../../../assets/images/contractsImg.png";
import TradeShiftImg from "../../../assets/images/TradeShiftImg.png";
import contractImg1 from "../../../assets/images/contractImg1.png";
import employeeGuideMainImg from "../../../assets/images/employeeGuideMainImg.png";
import contractImg2 from "../../../assets/images/contractImg2.png";
//i18n
import { withTranslation } from "react-i18next"
import { inject, observer } from "mobx-react";

import config from "../../../library/config";

const EmployeeGuide = inject('authStore', 'commonStore')(observer((props) => {
  return (
    <>
      <div className="landingPageWrapp frameworkApp">
        <Header features="current" />
        <article className="pageBannerBlock grdntBg  py-60">
          <Container>
            <Row className="g-4 align-items-center">
              <Col lg={6}>
                <div className="contentInfo">
                  <h5 className="m-0">{props.t("Do you still use pen and paper?")}</h5>
                </div>
                <MainHeading
                  title={<>{props.t("Employee")} <span>{props.t("Guide")}</span></>}
                  description={props.t("Forget about making space for endless binders and papers to record and store employee data. The ultimate Employee Handbook has made it easy to manage employee contracts effortlessly and synchronize the records such as payroll, contacts, HR, and everything else seamlessly.")}
                />
                <div className="appBtns mt-4">
                  <Link onClick={e => config.redirectToAppStore(e, "apple")} className="btnCommon btnBig w-auto me-3 mb-2"><i className="d-inline-block pe-2"><img src={AppleIcon}  alt="Apple" /></i> {props.t("Apple")}</Link>
                  <Link onClick={e => config.redirectToAppStore(e, "google")} className="btnCommon btnTrans btnBig btnBlackTrans w-auto"><i className="d-inline-block pe-2"><img src={GoogleIcon} alt="Apple" /></i> {props.t("Google")}</Link>
                </div>
              </Col>
              <Col lg={6}>
                <PageBanner
                  src={BannerImg}
                  alt="Employee Handbook"
                />
              </Col>
            </Row>
          </Container>
          <div className="valuableLogo">
            <img src={ValuableLogo} alt="" />
          </div>
        </article>
        <article className="iconFeauBlock  py-80">
          <Container>
            <div className="contentInfo text-center">
              <h5 className="m-0">{props.t("Employee Management Made Easier")}</h5>
              

            </div>
            <MainHeading
              title={<>{props.t("Why Go Digital and Secure")}<br/><span>{props.t("with Time and Control?")}</span></>}
              customClass="medium noPara text-center"
            />
            <Row className="g-4 g-xxl-5">
              <Col md={6} lg={4}>
                <IconBox
                  src={ProductivityImg}
                  title={props.t("Productivity")}
                  description={props.t("Shed all the paper trails and stored documents electronically. With Time and Control, records are easy to store, save, retrieve, and index. Save time, effort, money, and space, and utilize them more productively.")}
                  bgColor="pink"
                />
              </Col>
              <Col md={6} lg={4}>
                <IconBox
                  src={EasyAccessImg}
                  title={props.t("Accessibility")}
                  description={props.t("With digital document storage on the cloud, you can access documents from anywhere and on any device. Just have your login details handy and get the information with ease.")}
                  bgColor="blue"
                />
              </Col>
              <Col md={6} lg={4}>
                <IconBox
                  src={ContractsImg}
                  title={props.t("Contracts")}
                  description={props.t("Create and get e-signatures to store and manage employee contracts with the intelligent feature of Time & Control. It gives you the upper hand in ensuring complete compliance and following legal requirements with contracts or deals.")}
                  bgColor="green"
                />
              </Col>
            </Row>
          </Container>
        </article>
        <article className="abtInfoBlock grdntBg noPatt  py-60">
          <div className="rightImgFull">
            <img src={TradeShiftImg} alt="" />
          </div>
          <Container>
            <Row className="g-4 g-xl-5 align-items-center">
              <Col>
                <div className="my-4 py-4">
                  <div className="contentInfo">
                    <h5 className="mb-2">{props.t("Complete Workforce Management")}</h5>
                  </div>
                  <MainHeading
                    title={<>{props.t("Empowering your HR Department")} <span>{props.t("with Efficiency")}</span></>}
                    description={props.t("Employees are the backbone of every business. However, Employee management is an essential factor for a conducive work environment in any organisation. The Time & Control app gives you comprehensive control and visibility over employee administration, contracts, and tasks.")}
                    customClass="medium"
                  />
                  <div className="listFeaRow">
                    <Row className="g-4">
                      <Col md={6}>
                        <ListFeau
                          title={props.t("Performance Review")}
                          description={props.t("Send accurate performance reviews to your employees based on precise metrics.")}
                          color="blue"
                        />
                      </Col>
                      <Col md={6}>
                        <ListFeau
                          title={props.t("Notify Updates")}
                          description={props.t("Always stay connected to your employees and share updates about tasks and policies immediately.")}
                          color="green"
                        />
                      </Col>
                      <Col md={6}>
                        <ListFeau
                          title={props.t("Easy Management")}
                          description={props.t("Allow your managers to approve/reject leaves, sign contracts, and share tasks, all from one place.")}
                          color="red"
                        />
                      </Col>
                      <Col md={6}>
                        <ListFeau
                          title={props.t("Mobile Ready")}
                          description={props.t("Time and Control app is accessible via your mobile or tablet for easy information access without any global boundaries.")}
                          color="pink"
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </article>
        <article className="abtInfoBlock py-60">
          <Container>
            <Row className="g-4 g-xl-5 align-items-center">
              <Col lg={6} xl={6}>
                <div className="text-center">
                  <img src={contractImg1} alt="" />
                </div>
              </Col>
              <Col lg={6} xl={6}>
                <div className="contentInfo mb-3">
                  <h5>{props.t("Gain Seamless Visibility")}</h5>
                </div>
                <MainHeading
                  title={<>{props.t("Achieve Information Integrity and Structural Efficiency")}</>}
                  customClass="medium noPara mb-4"
                />
                <h5>{props.t("or Key Reasons to Choose Our Smart Employee Handbook")}</h5>
                <br/>
                <div className="list">
                  <ul>
                    <li>{props.t("Store employee information")}</li>
                    <li>{props.t("Generate auto-customized reports")}</li>
                    <li>{props.t("Track employee performance")}</li>
                    <li>{props.t("Integrity and structural efficiency")}</li>
                    <li>{props.t("Index employee history and job")}</li>
                    <li>{props.t("Access information from anywhere")}</li>
                    <li>{props.t("Create a bespoke management structure")}</li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </article>
        <article className="abtInfoBlock noPatt ">
          <div className="hdngBg py-60">
            <Container>
              <div className="contentInfo text-center">
                <h5 className="m-0">{props.t("An Environment-Friendly Solution")}</h5>
              </div>
              <MainHeading
                title={<>{props.t("Why Paperless")}<br/><span>{props.t("Go Paperless with Time and Control")}</span></>}
                customClass="medium noPara text-center"
              />
            </Container>
          </div>
          <Container>
            <div className="abtMainNewImg" style={{backgroundImage: `url(${employeeGuideMainImg})`}}></div>
          </Container>
        </article>
        <article className="whyBlock  py-80">
          <Container>
            <Row className="g-4 align-items-center">
              <Col lg={6} xl={6}>
                <div className="text-left">
                  <p>{props.t("Reduce the paper junk and move to a hassle-free employee management system with Time & Control. However, It ensures that every composition is legally authorized, affirmed, and digitally signed without leaving any paper trail. Additionally, View and Respond to resignations, leave requests, and contractual agreements, manage onboard new employees digitally and view their assignments from a modernized dashboard with mitigation.")}</p>
                </div>
                <div className="appBtns mt-4">
                  <Link to="/" className="btnCommon btnBig w-auto me-3 mb-2"><i className="d-inline-block pe-2"><img src={AppleIcon}  alt="Apple" /></i> {props.t("Apple")}</Link>
                  <Link to="/" className="btnCommon btnTrans btnBig btnBlackTrans w-auto"><i className="d-inline-block pe-2"><img src={GoogleIcon} alt="Apple" /></i> {props.t("Google")}</Link>
                </div>
              </Col>
              <Col lg={6} xl={6}>
                <div className="text-center">
                  <img src={contractImg2} alt="" />
                </div>
              </Col>
            </Row>
          </Container>
        </article>
        <Contact/>
        <Footer/>
      </div>
    </>
  );
}))
export default withTranslation()(EmployeeGuide);