import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Modal, Row, Col } from 'react-bootstrap';
import _get from 'lodash/get';
import moment from 'moment';
import Dropzone from 'react-dropzone';

//components
import withLocalization from '../../../hoc/withLocalization';
import Button from '../../../components/CustomButton/CustomButton.jsx';

//services
import config from '~/library/config';

//utils
import getBase64 from '~/utils/getBase64';

//assets
import deleteIcon from '../../../assets/img/deleting.svg';
import editIcon from '../../../assets/img/editing.svg';
import uploadIcon from '../../../assets/img/uploadIcon.svg';
import CloseModalIcon from '../../../assets/img/CloseApproveModalIcon.svg';

const AttachmentsWidgetMobx = inject('projectStore', 'userStore')(withLocalization(observer(class AttachmentsWidgetMobx extends Component {
    constructor(props) {
        super(props);
        this.state = {
            uiAttachments: [],
            docAttachments: false,
            withTable: false,
            isEditMode: null,
            filename: '',
            viewAttachmentModal: false,
            currentAttachment: null
        };
    }

    handleSave(Id) {
        const { userStore, commonStore, t } = this.props;
        if (this.state.filename.trim()) {
            userStore.updateAttachmentName({ id: Id, filename: this.state.filename })
                .then((res) => {
                    if (res?.attachment?.id) {
                        const uiAttachments = [...this.state.uiAttachments];
                        const index = uiAttachments.findIndex((ele) => ele.id === res.attachment.id)
                        uiAttachments[index] = res.attachment;
                        this.setState({ isEditMode: null, uiAttachments });
                    }
                })
                .catch(error => {
                    commonStore.addNotification(error, null, 'error');
                });
        } else {
            commonStore.addNotification(t(`Document name can't be blank`), null, 'error', 'tr', 3);
        }
    }

    onNameSave = (evt, Id) => {
        const { userStore, commonStore, t } = this.props;
        if (evt.key === 'Enter' || evt.keyCode === 13) {
            if (this.state.filename.trim()) {
                userStore.updateAttachmentName({ id: Id, filename: this.state.filename }).then((res) => {
                    this.setState({ isEditMode: null });
                });
            } else {
                commonStore.addNotification(t(`Document name can't be blank`), null, 'error', 'tr', 3);
            }

        }
    }

    componentDidMount() {
        this.pushAttachmentFromProps();
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.options.imageContext) !== JSON.stringify(this.props.options.imageContext)) {
            this.pushAttachmentFromProps();
        }
    }

    openEditMode = (evt, filename, id) => {
        this.setState({ isEditMode: id, filename: filename })
    }

    onFileNameChange = (evt) => {
        const filename = evt.target.value;
        this.setState({ filename: filename })
    }
    getUrl = file => {
        return `${config.UPLOADS_API_ENDPOINT}/${file}`;
    };

    onUpload = e => {
        const {
            onChange, onUpload, options, userStore
        } = this.props;
        const { files } = e.target;
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const { name } = file;
            getBase64(file)
                .then(attachData => {
                    userStore
                        .fileUpload({
                            to_model: options.imageContext.model,
                            to_id: options.imageContext.id,
                            user_id: userStore.currentUser.id,
                            filename: name,
                            filetype: options.imageContext.fileType,
                            content: attachData,
                            data: options.imageContext.data || {},
                        })
                        .then(newId => {
                            if (onUpload) onUpload(name);
                            const arrayIds = this.props.value ? this.props.value.split(',') : [];
                            arrayIds.push(newId);
                            onChange(`${arrayIds.join(',')}`);
                            const uiAttachment = {
                                id: newId,
                                filename: name,
                                filetype: options.imageContext.fileType,
                            };
                            this.addUiAttachment(uiAttachment);
                        });
                    this.fileInput.value = '';
                })
                .catch(error => {
                    console.error(error);
                });
        }
    };

    addUiAttachment = attachment => {
        const uiAttachments = [...this.state.uiAttachments]
        if (Array.isArray(attachment)) {
            uiAttachments.push(...attachment);
        }
        else uiAttachments.push(attachment);
        this.setState({
            uiAttachments,
        });
    };

    removeUiAttachment = id => {
        const uiAttachments = this.state.uiAttachments.slice(0);
        uiAttachments.filter(attachment => String(attachment.id) !== String(id));
        this.setState({
            uiAttachments,
        });
    };

    removeAttachment = removedId => {
        const { onChange, value } = this.props;
        const arrayIds = value ? value.split(',') : [];
        const newArrayIds = arrayIds.filter(id => String(id) !== String(removedId));
        onChange(`${newArrayIds.join(',')}`);
        this.removeUiAttachment(removedId);
    };

    pushAttachmentFromProps = () => {
        let isWidthTable = false;
        const { existingAttachment } = this.props.options.imageContext;

        if (this.props.options.imageContext.withTable) {
            isWidthTable = true;
        }

        if (existingAttachment) {
            this.setState({
                uiAttachments: existingAttachment,
                withTable: isWidthTable
            });
        }
    };

    isImage = filename => {
        const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif|\.svg|\.webp|\.bmp|\.ico)$/i;
        return !!allowedExtensions.exec(filename);
    };

    downloadAttachment = (_url, name) => {
        fetch(_url, {
            method: "GET",
            headers: {}
        })
            .then(response => {
                response.arrayBuffer().then(function (buffer) {
                    const url = window.URL.createObjectURL(new Blob([buffer]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", name);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                });
            })
            .catch(err => {
                console.error(err);
            });
    };

    handleCloseForm = () => {
        this.setState({ viewAttachmentModal: false, currentAttachment: null })
    }

    renderItem = (attachments, dateTimeRules) => (
        (this.state.withTable) ?
            <>
                <div className="table-responsive w-100">
                    <table className="table" >
                        <thead>
                            <tr>
                                <th style={{ minWidth: '100px', maxWidth: '120px' }}>{this.props.t('Date')}</th>
                                <th style={{ minWidth: '100px', maxWidth: '150px' }}>{this.props.t('Time')}</th>
                                <th style={{ minWidth: '100px', maxWidth: '150px' }}>{this.props.t('Document Name')}</th>
                                <th style={{ minWidth: '100px', maxWidth: '120px' }}>{this.props.t('Actions')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {attachments.map((attachment) => (
                                <tr key={attachment.id}>
                                    <td>{moment(attachment.updated_at).format(dateTimeRules.short_date_format)}</td>
                                    <td>{moment(attachment.updated_at).format(dateTimeRules.time_format)}</td>
                                    <td>
                                        {this.state.isEditMode !== attachment.id ?
                                            <span title={attachment.filename}>
                                                <a href={`${config.API_ROOT}/attachments/${attachment?.id}`}
                                                    target={'_blank'}>{attachment.filename}
                                                </a>
                                            </span>
                                            :
                                            <div className='d-flex justify-content-around'>
                                                <input type="text" className='form-control h-75 w-75'
                                                    value={this.state.filename}
                                                    onKeyUp={(evt) => { this.onNameSave(evt, attachment.id) }}
                                                    onChange={this.onFileNameChange} />
                                                <Button fill icon_sm onClick={() => this.handleSave(attachment.id)}>
                                                    <i className='fa fa-save saveIcon' title='save'></i>
                                                </Button>
                                            </div>
                                        }
                                    </td>
                                    <td>
                                        <div className='actions-center actions-center__center'>
                                            {(this.state.isEditMode !== attachment.id || this.state.isEditMode === attachment.id) ?
                                                <Button fill icon_sm
                                                    onClick={(evt) => { this.openEditMode(evt, attachment.filename, attachment.id) }}>
                                                    <img src={editIcon} alt={"edit button"} />
                                                </Button>
                                                : <></>
                                            }
                                            <Button fill icon_sm onClick={() => this.setState({ currentAttachment: attachment }, () => this.setState({ viewAttachmentModal: true }))}>
                                                <i className="fa fa-eye actionblueicon" />
                                            </Button>
                                            <Button fill icon_sm onClick={() => this.removeAttachment(attachment.id)}>
                                                <img src={deleteIcon} alt="delete button" />
                                            </Button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <Modal
                    size="lg"
                    show={this.state.viewAttachmentModal && this.state.currentAttachment}
                    onHide={this.handleCloseForm}
                    centered
                >
                    <Modal.Header >
                        <Modal.Title className="w-100 text-center ApproveModal__title m-0">
                            {this.props.t('Attachments')}
                        </Modal.Title>
                        <img
                            src={CloseModalIcon}
                            className="cursor-pointer"
                            onClick={this.handleCloseForm}
                        />
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col xs={4} md={2} className='task_template_view_texts'>
                                {this.props.t('Name')}
                            </Col>
                            <Col xs={8} md={10} className='task_template_view_sub_texts'>
                                {this.state.currentAttachment?.filename}
                            </Col>
                            <Col xs={12} >
                                <div style={{ minHeight: '40vh' }} className='d-flex mt-2 justify-content-center align-items-center'>
                                    {this.isImage(this.state.currentAttachment?.filename) ?
                                        <img src={`${config.API_ROOT}/attachments/${this.state.currentAttachment.id}`} style={{ maxWidth: '100%' }} />
                                        :
                                        this.state.currentAttachment?.filename.split('.').pop() === 'pdf' ?
                                            <iframe src={`${config.API_ROOT}/attachments/${this.state.currentAttachment?.id}`} style={{ width: '100%', height: '50vh' }} />
                                            : <p className='task_template_view_sub_texts'>{this.props.t('File format is not supported, please download to preview')} </p>
                                    }
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className='d-flex mt-2 justify-content-end'>
                                    <Button fill
                                        onClick={() => {
                                            this.downloadAttachment(`${config.API_ROOT}/uploads/${this.state.currentAttachment.location}`,
                                                this.state.currentAttachment?.filename)
                                        }}>
                                        {this.props.t('Download')}
                                    </Button>

                                </div>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            </>
            :

            <div className="uploads-item" key={attachments.id}>
                <div className="uploads-item__file">
                    {this.isImage(attachments.filename) ? (
                        <img
                            src={`${config.UPLOADS_API_ENDPOINT}/${attachments.id}`}
                            alt={attachments.filename}
                            className="uploads-item__img"
                            onClick={() => window.open(this.getUrl(attachments.id), '_blank')}
                        />
                    ) : (
                        <i
                            className="uploads-item__icon pe-7s-file"
                            onClick={() => window.open(this.getUrl(attachments.id), '_blank')}
                        />
                    )}
                    {!this.props.disabled && (
                        <i
                            className="uploads-item__delete pe-7s-close-circle"
                            onClick={() => this.removeAttachment(attachments.id)}
                        />
                    )}
                </div>
                <span className="uploads-item__title" title={attachments.filename}>
                    {attachments.filename}
                </span>
            </div>
    );

    onDrop = async (uiAttachments) => {
        const { onChange, onUpload, options, userStore } = this.props;
        const storedUIAttachments = [];
        const arrayIds = this.props.value ? this.props.value.split(',') : [];
        for (let i = 0; i < uiAttachments.length; i++) {
            const file = uiAttachments[i];
            const { name } = file;
            const base64File = await getBase64(file);
            const storedFileId = await userStore.fileUpload({
                to_model: options.imageContext.model,
                to_id: options.imageContext.id,
                user_id: userStore.currentUser.id,
                filename: name,
                filetype: options.imageContext.fileType,
                content: base64File,
                data: options.imageContext.data || {},
            })
            if (onUpload) onUpload(name);
            arrayIds.push(storedFileId);
            const uiAttachment = {
                id: storedFileId,
                filename: name,
                filetype: options.imageContext.fileType,
            };
            storedUIAttachments.push(uiAttachment);
        }
        this.addUiAttachment(storedUIAttachments)
        onChange(`${arrayIds.join(',')}`);
    };

    render() {
        const { value, groups = [], group: userGroup, t, disabled, commonStore } = this.props;
        const { uiAttachments, withTable } = this.state;
        const attachmentsIds = value ? value.split(',') : [];
        const attachments = uiAttachments.filter(item => attachmentsIds.indexOf(String(item.id)) !== -1);
        const dateTimeRules = commonStore?.config?.client?.data?.dateTimeRules ? commonStore.config.client.data.dateTimeRules : false;
        if (!groups.length) {
            return (
                <>
                    <Dropzone onDrop={this.onDrop} >
                        {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps({ className: 'dropzone w-100' })}>
                                {(!disabled && !withTable) &&
                                    <div className="uploads upload-attachments" style={{ cursor: 'pointer' }}>
                                        <div className='attachment-box-align' onMouseDown={() => {
                                            this.fileInput.click()
                                        }}>
                                            <div>
                                                <img src={uploadIcon} alt='uploadIcon'></img>
                                                <div className="uploadTitle mt-10 fw-600">{t("Drag and drop file or")} <span className='text-color-2550AC fw-600'>{t("Browse")}</span></div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {!disabled && (
                                    <input
                                        {...getInputProps()}
                                        style={{ display: 'none' }}
                                        type="file"
                                        ref={fileInput => {
                                            this.fileInput = fileInput;
                                        }}
                                        onChange={this.onUpload}
                                        multiple
                                    />
                                )}
                            </div>
                        )}
                    </Dropzone>
                    {(!disabled && withTable) &&
                        <div className="d-flex" style={{ justifyContent: withTable ? 'flex-end' : 'center' }}>
                            <Button fill wd onMouseDown={() => {
                                this.fileInput.click()
                            }}>{t('Upload New Document')}</Button>
                        </div>
                    }
                    <div className='d-flex justify-content-center flex-box'>
                        {attachments && withTable && this.renderItem(attachments, dateTimeRules)}
                        {attachments && !withTable && attachments.map(attachment => this.renderItem(attachment))}
                    </div>
                </>
            );
        }

        // GROPED ATTACHMENTS
        const allGroupKeys = groups.map(group => group.key);
        const ungroupedItems = attachments.filter(attach => {
            const group = _get(attach, 'data.group', 'ungrouped');
            if (!attach.user_id && allGroupKeys.indexOf(userGroup) !== -1) {
                return false;
            }
            return allGroupKeys.indexOf(group) === -1;
        });

        return (
            <>
                <Dropzone onDrop={this.onDrop}>
                    {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps({ className: 'dropzone' })} className="uploads uploads_with-groups">
                            {(!disabled && !withTable) && (
                                <div className='attachment-box-align' onMouseDown={() => { this.fileInput.click() }}>
                                    <img src={uploadIcon} alt='uploadIcon'></img>
                                    <div className="uploadTitle mt-10 fw-600">{t("Drag and drop file or")} <span className='text-color-2550AC fw-600'>{t("Browse")}</span></div>
                                </div>
                            )}
                            {!disabled && (
                                <input
                                    {...getInputProps()}
                                    style={{ display: 'none' }}
                                    type="file"
                                    ref={fileInput => {
                                        this.fileInput = fileInput;
                                    }}
                                    onChange={this.onUpload}
                                    multiple
                                />
                            )}
                        </div>
                    )}
                </Dropzone>
                {groups.map(group => {
                    const groupedAttachments = attachments.filter(attach => {
                        if (!attach.user_id && userGroup === group.key) {
                            return true;
                        }
                        return attach.data && attach.data.group === group.key;
                    });
                    if (groupedAttachments.length) {
                        return (
                            <div className='w-100'>
                                <div className='d-flex justify-content-center mt-10'>
                                    <div className="input-area" key={group.key}>
                                        <span className="input-area__label">{group.label}</span>
                                        <div className="input-area__content">
                                            {groupedAttachments.map(attachment => this.renderItem(attachment, dateTimeRules))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    }
                    return null;
                })}
                {!!ungroupedItems.length && (
                    <div className="input-area">
                        <span className="input-area__label">{t('Other Attachments')}</span>
                        <div className="input-area__content">
                            {ungroupedItems.map(attachment => this.renderItem(attachment, dateTimeRules))}
                        </div>
                    </div>
                )}
                {(!disabled && withTable) &&
                    <div>
                        <button className='btn btn-fill' onMouseDown={() => this.fileInput.click()}>
                            {t('Upload New Document')}</button>
                    </div>
                }
            </>
        );
    }
})));

export const AttachmentsWidget = observer(class AttachmentsWidget extends Component {
    render() {
        return <AttachmentsWidgetMobx {...this.props} />;
    }
});