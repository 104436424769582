import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import moment from 'moment/min/moment-with-locales';
import _ from 'lodash';
import { v4 as uuid } from 'uuid';


//components
import applicationRouter from '~/hoc/applicationRouter';
import withLocalization from '../../../hoc/withLocalization';
import DatePicker from '../../../components/DatePicker/DatePicker';
import { ProjectsWidget } from '../../../components/GenericForm/Widgets/ProjectsWidget';
import ShowAvailabiliy from './ShowAvailability';
import { TasksWidget } from '../../../components/GenericForm/Widgets/TasksWidget';
import { CurrentUserGroupsWidget } from '../../../components/GenericForm/Widgets/UserGroupWidget';
import CustomMultiSelect from '../../../elements/CustomMultiSelect.js';

//elements
import { Textarea } from '../../../elements/Input';
import Button from '../../../components/CustomButton/CustomButton.jsx';
import TimePickerInput from '../../../components/TimePicker';
import Checkbox from '../../../components/CustomCheckbox/CustomCheckbox';
import CheckboxButton from '../../../components/CustomButtonCheckbox/CustomButtonCheckbox';
import LoadingSpinner from '../../../elements/LoadingSpinner.js';

//utils
import { getMinutesFromTimeInput, minutesToTimeInput } from '../../../utils/timeCalc';
import { longDateFormat } from '../../../utils/dateFormat';

//assets
import CloseModalIcon from '../../../assets/img/CloseApproveModalIcon.svg';


//styles
import './Schedule.css';
import RequiredStar from '../../../elements/RequiredStar.js';

class BulkShiftForm extends Component {
    constructor(props) {
        super(props);
        const { start, end } = props.commonStore.config.client.data.startTimeRules || { start: null, end: null };
        const _start_time = start ? getMinutesFromTimeInput(start) : 9 * 60;
        const _end_time = end ? getMinutesFromTimeInput(end) : 18 * 60;
        this.isShiftAllowanceEnabled = props?.commonStore?.config?.client?.data?.extraPayments?.some(ele => ele.code === 'shift_allowance' && ele.isActive) || false;
        this.isEmergencyShiftEnabled = props?.commonStore?.config?.client?.data?.extraPayments?.some(ele => ele.code === 'emergency_shifts' && ele.isActive) || false;
        this.state = {
            start_date: (new Date(this.props.start_date ? this.props.start_date : this.props.viewModel.startDate)).toString(),
            end_date: (new Date(this.props.end_date ? this.props.end_date : this.props.viewModel.endDate)).toString(),
            assignees: [],
            assigneesBonus: [],
            checkAllAssigneeBonusesInTable: false,
            showAllAssigneeBonusesInTable: false,
            project_id: null,
            task_id: null,
            start_time: _start_time,
            end_time: _end_time,
            comment: '',
            valuesToFilter: [],
            do_notify: false,
            nonWorkingDays: [0, 1, 2, 3, 4, 5, 6],
            defaultDays: [{ day: 'Mon', checked: false }, { day: 'Tue', checked: false },
            { day: 'Wed', checked: false }, { day: 'Thu', checked: false },
            { day: 'Fri', checked: false }, { day: 'Sat', checked: false },
            { day: 'Sun', checked: false }, { day: 'Check All', checked: false }],
            isShowAvailability: false,
            rolesOptions: props.commonStore.config.client.data.roles.map((ele, index) => { return { value: index, label: ele.name, code: ele.code } }),
            selectedRoles: [],
            selectedRolesObject: [],
            user_group: null,
            resources: [],
            isLoading: false,
            isShiftAllowance: false,
            assignEmergencyShiftToEmployeeForTheWeek: false
        };
        this.handleAssigneesChange = this.handleAssigneesChange.bind(this);
        this.handleAssigneeBonusChange = this.handleAssigneeBonusChange.bind(this);
        this.handleCheckAllChange = this.handleCheckAllChange.bind(this);
        this.handleCheck = this.handleCheck.bind(this);
    }

    componentWillMount() {
        const clientConfig = this.props.commonStore.config.client.data;
        let from = getMinutesFromTimeInput(clientConfig.basicRules.startTimeRules.start);
        let to = getMinutesFromTimeInput(clientConfig.basicRules.startTimeRules.end);
        this.setState({
            start_time: from,
            end_time: to,
            isLoading: true
        });
    }

    componentDidMount() {
        this.loadData();
    }

    endOfWeek(date) {
        var lastday = date.getDate() - (date.getDay() - 1) + 6;
        return new Date(date.setDate(lastday));
    }

    applyRoleFilter = (value) => {
        const clientRoles = this.state.rolesOptions;
        let _userOptions = value.map((ele) => clientRoles[ele]);
        this.setState({ selectedRoles: value, selectedRolesObject: _userOptions })
        this.handleAssigneesChange([]);
    }

    handleAssigneesChange(selectedIds) {
        const { assigneesBonus, checkAllAssigneeBonusesInTable, showAllAssigneeBonusesInTable } = this.state;
        let newAssigneesBonus = JSON.parse(JSON.stringify(assigneesBonus));
        // delete deleted values
        newAssigneesBonus = newAssigneesBonus.filter(assigneeBonus => selectedIds.indexOf(assigneeBonus.value) !== -1);
        // add added values
        selectedIds.forEach((id) => {
            if (!newAssigneesBonus.find(assigneeBonus => assigneeBonus.value === id)) {
                newAssigneesBonus.push({ value: id, addBonus: false });
            }
        });
        const newAssigneesBonusWithBonus = newAssigneesBonus.filter(newAssigneeBonus => newAssigneeBonus.addBonus);
        const newAssigneesBonusWithoutBonus = newAssigneesBonus.filter(newAssigneeBonus => !(newAssigneeBonus.addBonus));
        const assigneesBonusWithBonusExists = newAssigneesBonusWithBonus && newAssigneesBonusWithBonus.length;
        const assigneesBonusWithoutBonusExists = newAssigneesBonusWithoutBonus && newAssigneesBonusWithoutBonus.length;
        const assigneesBonusWithBonusDoNotExist = !assigneesBonusWithBonusExists;
        const assigneesBonusWithoutBonusDoNotExist = !assigneesBonusWithoutBonusExists;
        const newAssigneesBonusExist = newAssigneesBonus && newAssigneesBonus.length;
        const newAssigneesBonusEmpty = !newAssigneesBonusExist;
        let newCheckAllAssigneeBonusesInTable = checkAllAssigneeBonusesInTable;
        if (assigneesBonusWithBonusDoNotExist || newAssigneesBonusEmpty) {
            newCheckAllAssigneeBonusesInTable = false;
        } else if (assigneesBonusWithoutBonusDoNotExist) {
            newCheckAllAssigneeBonusesInTable = true;
        }
        this.setState({
            assignees: selectedIds,
            assigneesBonus: JSON.parse(JSON.stringify(newAssigneesBonus)),
            checkAllAssigneeBonusesInTable: newCheckAllAssigneeBonusesInTable,
            showAllAssigneeBonusesInTable: !selectedIds.length ? false : showAllAssigneeBonusesInTable
        });
    }

    handleAssigneeBonusChange(value, checked) {
        const { assigneesBonus, checkAllAssigneeBonusesInTable } = this.state;
        let newAssigneesBonus = JSON.parse(JSON.stringify(assigneesBonus));
        newAssigneesBonus = newAssigneesBonus.map(newAssigneeBonus => {
            if (newAssigneeBonus.value === value) {
                return { ...JSON.parse(JSON.stringify(newAssigneeBonus)), addBonus: checked }
            } else {
                return newAssigneeBonus;
            }
        });
        const newAssigneesBonusWithBonus = newAssigneesBonus.filter(newAssigneeBonus => newAssigneeBonus.addBonus);
        const newAssigneesBonusWithoutBonus = newAssigneesBonus.filter(newAssigneeBonus => !(newAssigneeBonus.addBonus));
        const assigneesBonusWithBonusExists = newAssigneesBonusWithBonus && newAssigneesBonusWithBonus.length;
        const assigneesBonusWithoutBonusExists = newAssigneesBonusWithoutBonus && newAssigneesBonusWithoutBonus.length;
        const assigneesBonusWithBonusDoNotExist = !assigneesBonusWithBonusExists;
        const assigneesBonusWithoutBonusDoNotExist = !assigneesBonusWithoutBonusExists;
        const newAssigneesBonusExist = newAssigneesBonus && newAssigneesBonus.length;
        const newAssigneesBonusEmpty = !newAssigneesBonusExist;
        let newCheckAllAssigneeBonusesInTable = checkAllAssigneeBonusesInTable;
        if (assigneesBonusWithBonusDoNotExist || newAssigneesBonusEmpty) {
            newCheckAllAssigneeBonusesInTable = false;
        } else if (assigneesBonusWithoutBonusDoNotExist) {
            newCheckAllAssigneeBonusesInTable = true;
        }
        this.setState({
            assigneesBonus: JSON.parse(JSON.stringify(newAssigneesBonus)),
            checkAllAssigneeBonusesInTable: newCheckAllAssigneeBonusesInTable,
        });
    }

    handleCheckAllChange(checked) {
        const { assigneesBonus } = this.state;
        let newAssigneesBonus = JSON.parse(JSON.stringify(assigneesBonus));
        newAssigneesBonus.forEach(newAssigneeBonus => {
            newAssigneeBonus.addBonus = checked
        });
        this.setState({
            checkAllAssigneeBonusesInTable: checked,
            assigneesBonus: JSON.parse(JSON.stringify(newAssigneesBonus)),
        });
    }

    async handleRun(action, values) {
        if (action === 'cancel') {
            return this.props.finish(action, values);
        }
        const { t, commonStore } = this.props;
        const { start_date, end_date, project_id, task_id, resources } = this.state;
        const startDateWithTime = new Date(start_date);
        const endDateWithTime = new Date(end_date);
        const startDate = new Date(Date.UTC(startDateWithTime.getUTCFullYear(), startDateWithTime.getUTCMonth(), startDateWithTime.getUTCDate()));
        const endDate = new Date(Date.UTC(endDateWithTime.getUTCFullYear(), endDateWithTime.getUTCMonth(), endDateWithTime.getUTCDate()));

        if (!project_id || !task_id) {
            return commonStore.addNotification(t('Please select project and task'), null, 'warning');
        }
        if (!values.assignees || !values.assignees.length) {
            return commonStore.addNotification(t('Please select employees'), null, 'warning');
        }
        if (values.nonWorkingDays.length === 7) {
            return commonStore.addNotification(t('Please select days to create shift'), null, 'warning');
        }
        if (startDate.getTime() > endDate.getTime()) {
            return commonStore.addNotification(t('From date must be not bigger, than To date'), null, 'warning');
        }

        const checkIfEmployessNoTInTask = resources.filter(resource => !resource.task_ids.includes(parseInt(task_id))).filter(e => values.assignees.includes(e.id));
        if (!checkIfEmployessNoTInTask.length) {
            return this.props.finish(action, values);
        }
        else {
            const str = `${checkIfEmployessNoTInTask.map((e) => e.name).join(', ')} ${checkIfEmployessNoTInTask.length > 1 ? t('are') : t('is')} ${t('not assigned in this tasks, system will auto assign these employee(s). Do you want to proceed?')}`;
            confirmAlert({
                title: t('Employee(s) are not in tasks'),
                message: str,
                buttons: [
                    {
                        label: t('Yes'),
                        onClick: () => {
                            return this.props.finish(action, values);
                        },
                    },
                    {
                        label: t('No'),
                        onClick: () => {
                            return;
                        },
                    },
                ],
            })
        }
    }

    handleChange = (name, value) => {
        const { scheduleStore } = this.props;
        this.setState({ [name]: value });
        scheduleStore.changeEntity(name, value);
    };


    async loadData() {
        const { scheduleStore, stub, commonStore } = this.props;
        try {
            const _roleOptions = [...this.state.rolesOptions, { value: this.state.rolesOptions.length, label: 'Others', code: 'z_none' }];
            const _resources = await scheduleStore.getAvailableAssigneesForBulkShift();
            this.setState({
                rolesOptions: _roleOptions,
                selectedRoles: _roleOptions.map((e, index) => { return index }),
                resources: _resources,
                isLoading: false
            })
            return scheduleStore.returnDefaultNew(stub);
        }
        catch (err) {
            console.error(err);
            commonStore.addNotification()
            this.setState({ isLoading: false })
        }
    }

    checkAll = (value) => {
        const { defaultDays } = this.state;
        defaultDays.forEach(item => {
            item.checked = value;
        })
        this.setState({ defaultDays, nonWorkingDays: [] });
    }

    handleCheck = (index, evt) => {
        const { defaultDays, nonWorkingDays } = this.state;
        let isAllTrue = true;
        let holidays = nonWorkingDays;

        if (evt === false && defaultDays[index].day !== 'Check All') {
            holidays.push(index);
        }

        if (evt === true && defaultDays[index].day !== 'Check All') {
            holidays = holidays.includes(index) ? holidays.filter(e => e !== index) : holidays
        }

        if (defaultDays[index].day === 'Check All') {
            this.checkAll(evt);
        } else {
            defaultDays[index].checked = evt;
            for (let i in defaultDays) {
                if (defaultDays[i].day !== 'Check All' && defaultDays[i].checked === false) {
                    isAllTrue = false;
                    break;
                }
            }
            defaultDays[7].checked = isAllTrue;
            this.setState({ defaultDays, nonWorkingDays: holidays });
        }
    }

    ShowAvailability = () => {
        this.setState({ isShowAvailability: !this.state.isShowAvailability })
    }


    render() {
        const { commonStore, t, userStore } = this.props;
        const { start_date, end_date, assignees, project_id, task_id, start_time,
            end_time, comment, do_notify, assigneesBonus, checkAllAssigneeBonusesInTable, isShiftAllowance,
            assignEmergencyShiftToEmployeeForTheWeek, showAllAssigneeBonusesInTable, nonWorkingDays,
            isShowAvailability, user_group, isLoading, resources } = this.state;
        const { config } = commonStore;
        const dateTimeRules = config.client && config.client.data && config.client.data.dateTimeRules ? config.client.data.dateTimeRules : false;
        const format = dateTimeRules && dateTimeRules.time_format ? dateTimeRules.time_format : 'hh:mm';
        const allowAvailability = config.client && config.client.data && config.client.data.basicRules
            && config.client.data.basicRules.allowAvailability ? config.client.data.basicRules.allowAvailability : false;

        let userOptionsDupWithoutUserGroupFilter = [], userObject = {};
        let _resources = _.filter(resources, (r) => !!r.id);
        if (project_id) {
            _resources = _.filter(_resources, (r) => r.project_ids.indexOf(parseInt(project_id, 10)) >= 0);
        }

        if (this.state.selectedRoles.length && this.state.selectedRoles.length !== this.state.rolesOptions.length) {
            let filter = [];
            this.state.selectedRolesObject.forEach(ele => {
                _resources.forEach(item => {
                    if (item.employee_type === ele.code)
                        filter.push(item)
                })
            })
            _resources = filter;
        }

        userOptionsDupWithoutUserGroupFilter = [..._resources];
        if (user_group > 0) {
            _resources = _resources.filter(ele => ele.user_groups.includes(user_group));
        }
        const userOptions = _resources.map((r) => {
            userObject[r.id] = r.name;
            return { value: r.id, label: r.name };
        });

        const isMember = userStore.currentUser.user_type === 'member';

        return (
            <Fragment>
                <Modal dialogClassName="lg bulk-shift-modal" className="lg" size="lg" show onHide={() => this.handleRun('cancel')}>
                    <Modal.Header className="set_ModalHeader" >
                        {!isShowAvailability ?
                            <Modal.Title className='w-100 text-center custom-modal-title'>{this.props.t('Bulk Shift')}</Modal.Title>
                            :
                            <Modal.Title className='w-100 text-center custom-modal-title'>{this.props.t('Member Availability')}</Modal.Title>
                        }
                        <img
                            src={CloseModalIcon}
                            alt='closeIcon'
                            className="cursor-pointer"
                            onClick={() => this.handleRun('cancel')}
                        />
                    </Modal.Header>
                    <Modal.Body>
                        {isLoading ? <LoadingSpinner />
                            :
                            <>
                                {!isShowAvailability ?
                                    <div className="row">
                                        <div className='row m-0'>
                                            <div className='col-xs-12 col-sm-12 col-md-4 d-flex'>
                                                <div className="col-md-6">
                                                    <div className="form-group field field-string ">
                                                        <label className="control-label fs15">
                                                            {t('From')}
                                                        </label>
                                                        <TimePickerInput
                                                            style={{ width: 150 }}
                                                            value={minutesToTimeInput(start_time, false)}
                                                            format={format === "hh:mm" ? "HH:mm" : format}
                                                            onChange={value => this.handleChange('start_time', getMinutesFromTimeInput(value))}
                                                            className="worklog-input"
                                                            disabled={isMember}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group field field-string">
                                                        <label className="control-label fs15">
                                                            {t('To')}
                                                        </label>
                                                        <TimePickerInput
                                                            style={{ width: 150 }}
                                                            value={minutesToTimeInput(end_time, false)}
                                                            format={format === "hh:mm" ? "HH:mm" : format}
                                                            onChange={value => this.handleChange('end_time', getMinutesFromTimeInput(value))}
                                                            className="worklog-input"
                                                            disabled={isMember}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xs-12 col-sm-12 col-md-4">
                                                <div className="form-group field field-string">
                                                    <label className="control-label">{t('From date')} <RequiredStar /> </label>
                                                    <br />
                                                    <DatePicker
                                                        selected={new Date(start_date)}
                                                        onChange={(date) => this.setState({ start_date: date.toString() })}
                                                        dateFormat={longDateFormat(dateTimeRules)}
                                                        disabled={isMember}
                                                        minDate={new Date().setMonth(new Date().getMonth() - 1)}
                                                        maxDate={new Date().setMonth(new Date().getMonth() + 1)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-xs-12 col-sm-12 col-md-4">
                                                <div className="form-group field field-string">
                                                    <label className="control-label">{t('To date')} <RequiredStar /></label>
                                                    <br />
                                                    <DatePicker
                                                        selected={new Date(end_date)}
                                                        onChange={(date) => this.setState({ end_date: date.toString() })}
                                                        dateFormat={longDateFormat(dateTimeRules)}
                                                        disabled={isMember}
                                                        minDate={new Date().setMonth(new Date().getMonth() - 1)}
                                                        maxDate={new Date().setMonth(new Date().getMonth() + 2)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row m-0 mt-4">
                                            <div className="d-flex">
                                                <label className="control-label fs15 mt-1">
                                                    {t('Weekdays')}<RequiredStar />
                                                </label>
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="tooltip-top">
                                                            {t("Please select the weekdays for which you want to create shifts")}.
                                                        </Tooltip>
                                                    }
                                                >
                                                    <i className="fa fa-info-circle text-muted mt-1 mx-2" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                                                </OverlayTrigger>
                                                {this.state.defaultDays.map((item, index) => (
                                                    <CheckboxButton
                                                        key={uuid()}
                                                        isChecked={item.checked}
                                                        onChange={evt => this.handleCheck(index, evt)}
                                                        label={t(item.day)}
                                                        isRightPadding={10}
                                                        isSatSun={index === 5 ? true : index === 6 ? true : false}
                                                        className={index === 7 ? '' : 'overflow-property'}
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                        <div className="row m-0 mt-4">
                                            <div className="col-md-6">
                                                <div className="form-group field field-string">
                                                    <label className="control-label">{t('Project')}</label>
                                                    <RequiredStar />
                                                    <br />
                                                    <ProjectsWidget
                                                        value={project_id}
                                                        onChange={(e, f) => {
                                                            this.setState({ project_id: e });
                                                            this.handleAssigneesChange([]);
                                                        }}
                                                        applyOnFirstLoad={false}
                                                        disabled={isMember}
                                                        filterByUser={false}
                                                        pmMode = {userStore.currentUser.user_type === 'pm' ? 'manager' : null}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group field field-string">
                                                    <label className="control-label">{t('Task')}</label>
                                                    <RequiredStar />
                                                    <br />
                                                    <TasksWidget
                                                        className="form-control"
                                                        onChange={(value) => {
                                                            this.setState({ task_id: value });
                                                            this.handleAssigneesChange([]);
                                                        }}
                                                        value={task_id}
                                                        defaultIfOne
                                                        defaultByGeneric
                                                        filterByProject={project_id || true}
                                                        disabled={isMember}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row m-0 mt-4'>
                                            <div className='col-12 col-md-6'>
                                                <label className="control-label">{t('Filter employees by title')}</label>
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="tooltip-top">
                                                            {t("Choose a title to see only relevant employees in the employee list")}.
                                                        </Tooltip>
                                                    }
                                                >
                                                    <i className="fa fa-info-circle text-muted ms-2" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                                                </OverlayTrigger>
                                                <CustomMultiSelect
                                                    options={this.state.rolesOptions}
                                                    value={this.state.selectedRoles || []}
                                                    onChange={(selected) => {
                                                        this.applyRoleFilter(selected);
                                                    }}
                                                    className={"custom-multi-select-max-height-150"}
                                                    overrideStrings={{
                                                        selectSomeItems: t('Select Titles'),
                                                        allItemsAreSelected: t('All Titles are selected'),
                                                        search: t('Search'),
                                                        selectAll: t('Select All'),
                                                    }}
                                                />
                                            </div>
                                            <div className='col-12 col-md-6'>
                                                <label className="control-label">{t('Group')}</label>
                                                {!task_id &&
                                                    <span className='text-danger fw-600 font-size-10 ms-2'>
                                                        {t('Please Select Project First')}
                                                    </span>
                                                }
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="tooltip-top">
                                                            {t("Use the 'Select Group' function to automatically create bulk shifts for employees within that group")}.
                                                        </Tooltip>
                                                    }
                                                >
                                                    <i className="fa fa-info-circle text-muted ms-2" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                                                </OverlayTrigger>
                                                {user_group > 0 &&
                                                    <span className='text-danger fw-600 font-size-10 ms-2'>
                                                        {t('Total Employee(s) in the Group')}&nbsp;:&nbsp;{userOptionsDupWithoutUserGroupFilter.filter(ele => ele.user_groups.includes(user_group)).length}
                                                    </span>
                                                }
                                                <CurrentUserGroupsWidget
                                                    user_id={0}
                                                    value={user_group}
                                                    disabled={isMember || !project_id}
                                                    onChange={(value) => {
                                                        this.setState({ user_group: value })
                                                        if (value > 0) {
                                                            const userGroupFilteredUsersIds = userOptionsDupWithoutUserGroupFilter.filter(ele => ele.user_groups.includes(value)).map(ele => ele.id);
                                                            if (userGroupFilteredUsersIds.length) {
                                                                this.props.commonStore.addNotification(`${t('All employees in the group will be included when creating the bulk shift')}.`, null, 'success', 'top-right', 3000);
                                                            }
                                                            else {
                                                                this.props.commonStore.addNotification(`${t('There seems to be an issue displaying assigned employees for this project.')}. ${t('Try selecting a different title or group')}.`, null, 'warning', 'top-right', 3000);
                                                            }
                                                            this.handleAssigneesChange(userGroupFilteredUsersIds)
                                                        }
                                                        else {
                                                            this.props.commonStore.addNotification(`${t('Removed all assignees')}`, null, 'warning');
                                                            this.handleAssigneesChange([]);
                                                        }
                                                    }}
                                                    isMember={false}
                                                />
                                            </div>
                                        </div>
                                        <div className='row m-0 mt-4'>
                                            <div className="col-9 col-md-6">
                                                <div className="form-group field field-string">
                                                    <label className="control-label">{t('Assign to')} <RequiredStar /></label>
                                                    {!task_id &&
                                                        <span className='text-danger fw-600 font-size-10 ms-2'>
                                                            ({t('Please Select Project First')})
                                                        </span>
                                                    }
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip-top">
                                                                {t("Please select employees to assign to work on this shift")}.
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <i className="fa fa-info-circle text-muted ms-2" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                                                    </OverlayTrigger>
                                                    {!userOptions.length &&
                                                        <span className='text-danger fw-600 font-size-10 ms-2'>{t('There seems to be an issue displaying assigned employees for this project.')}</span>
                                                    }
                                                    <CustomMultiSelect
                                                        disabled={isMember || !project_id}
                                                        options={userOptions}
                                                        value={assignees}
                                                        onChange={this.handleAssigneesChange}
                                                        className={"custom-multi-select-max-height-150"}
                                                        overrideStrings={{
                                                            selectSomeItems: t('Select Employees'),
                                                            allItemsAreSelected: t('All Employees are selected'),
                                                            search: t('Search'),
                                                            selectAll: t('Select All Employees'),
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-2'>
                                                <label className="control-label mb-2">{t('Bonus')}</label>
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="tooltip-top">
                                                            {t("Shifts with bonus will enable extra payment to employees for the hours logged under that shift, it will reflect in Payroll reports")}.
                                                        </Tooltip>
                                                    }
                                                >
                                                    <i className="fa fa-info-circle text-muted mt-1 ms-2" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                                                </OverlayTrigger>
                                                <CheckboxButton
                                                    checked={showAllAssigneeBonusesInTable}
                                                    onChange={() => this.setState(state => ({ showAllAssigneeBonusesInTable: !(state.showAllAssigneeBonusesInTable) }))}
                                                    label={<>
                                                        <i className='fa fa-plus-circle me-2'></i>{t('Add bonus')}
                                                    </>}
                                                />
                                            </div>
                                            <div className='col-4 align-content-center addValueDropactive'>
                                                <div className='d-flex'>
                                                    <Checkbox
                                                        isChecked={isShiftAllowance}
                                                        onChange={(checked) => this.handleChange('isShiftAllowance', checked)}
                                                        label={this.props.t('Shift Allowance')}
                                                        disabled={!this.isShiftAllowanceEnabled}
                                                    />
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip-shift_allowance">
                                                                {t("Add shift allowance to give additional allowance to employee for the week. Shift allowance can be viewed and managed from 'Shift Allowance' tab in Schedule header, if not enabled please contact administrator")}.
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <i className="fa fa-info-circle text-muted mt-1 ms-2" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                                                    </OverlayTrigger>
                                                </div>
                                                <div className='d-flex mt-2'>
                                                    <Checkbox
                                                        isChecked={assignEmergencyShiftToEmployeeForTheWeek}
                                                        onChange={(checked) => this.handleChange('assignEmergencyShiftToEmployeeForTheWeek', checked)}
                                                        label={t('Emergency Shift(On-call)')}
                                                        disabled={!this.isShiftAllowanceEnabled}
                                                    />
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip-top">
                                                                {t("Add employees for emegency shifts and let them know that are emergency shift (On Call) for the week and for every emegency shift they will recieve additional payments, if not enabled please contact administrator")}.
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <i className="fa fa-info-circle text-muted mt-1 ms-2" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                                                    </OverlayTrigger>
                                                </div>
                                            </div>
                                            {(assignEmergencyShiftToEmployeeForTheWeek || isShiftAllowance) &&
                                                <div className='row mt-2'>
                                                    <div className='col-12'>
                                                        <spna className={"fw-bold"}>{t("Note")}:</spna>
                                                        {isShiftAllowance &&
                                                            <p className='font-size-12 color-2550AC fw-semibold m-0'>
                                                                <i className='fa fa-arrow-right' />
                                                                {t('Shift Allowance will be added to all employees for the week with default settings you can change it from Shift Allowance tab on header')}.
                                                            </p>
                                                        }
                                                        {assignEmergencyShiftToEmployeeForTheWeek &&
                                                            <p className='font-size-12 color-FF8383 fw-semibold m-0'>
                                                                <i className='fa fa-arrow-right' />
                                                                {t('All the employees will be added to handle emergency shifts(On-call) for the weeks in the selected range and will be notified')}.
                                                            </p>
                                                        }
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        {showAllAssigneeBonusesInTable &&
                                            <div className='row m-0'>
                                                <div className='row m-0 mt-3 mb-2 pb-2' style={{ borderBottom: '2px solid #dee2e6' }}>
                                                    <div className='col-md-1 pb-1'>
                                                        <Checkbox
                                                            isChecked={checkAllAssigneeBonusesInTable}
                                                            onChange={(checked) => this.handleCheckAllChange(checked)}
                                                            wrapperClassName={'bulk-shift-chekbox-style'}
                                                        />
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <span className='fw-bold font-size-15'>
                                                            {t('Employees')}
                                                        </span>
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={
                                                                <Tooltip id="tooltip-top">
                                                                    {t("Select employees to give bonus")}.
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <i className="fa fa-info-circle text-muted mt-1 ms-2" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                                                        </OverlayTrigger>
                                                    </div>
                                                </div>
                                                <div className='row m-0 mb-3'>
                                                    {assigneesBonus.map((assigneeBonus) => (
                                                        <div key={uuid()} className='custom-bulk-shift-card'>
                                                            <Checkbox
                                                                isChecked={assigneeBonus.addBonus}
                                                                onChange={(checked) => this.handleAssigneeBonusChange(assigneeBonus.value, checked)}
                                                                wrapperClassName={'bulk-shift-chekbox-style'}
                                                            />
                                                            <span className='ms-2'>{userObject[assigneeBonus.value] ? userObject[assigneeBonus.value] : ''}</span>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        }
                                        <div className="row m-0 mt-4">
                                            <div className="col-md-12">
                                                <div className="form-group field field-string">
                                                    <label className="control-label">{t('Comment')}</label>
                                                    <Textarea
                                                        label=""
                                                        value={comment || ''}
                                                        style={{ width: '100%' }}
                                                        onChange={(e) => this.setState({ comment: e.target.value })}
                                                        placeholder={t('Enter comments here')}
                                                        timelog_modal={"_timelog"}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <ShowAvailabiliy
                                        start_date={moment(start_date).format('YYYY-MM-DD')}
                                        end_date={moment(end_date).format('YYYY-MM-DD')}
                                        userIDs={assignees.join(',')}
                                    />
                                }
                            </>
                        }
                    </Modal.Body>
                    {!isLoading &&
                        <Modal.Footer className='border-0'>
                            <div className='row m-0 w-100 mb-4'>
                                <div className='col-md-6 d-flex'>
                                    {(!isMember && !isShowAvailability) && (
                                        <Button fill wd
                                            type="submit"
                                            onClick={() => this.handleRun('save', {
                                                start_date, end_date, assignees,
                                                assigneesBonus, project_id, task_id, start_time, end_time, comment,
                                                do_notify, nonWorkingDays, user_group,
                                                isShiftAllowance, assignEmergencyShiftToEmployeeForTheWeek
                                            })}
                                        >
                                            {t('Save')}
                                        </Button>
                                    )}
                                    <div className='ml-30'>
                                        {!isShowAvailability &&
                                            <Button wd type="submit" onClick={() => this.handleRun('cancel')}>
                                                {t('Cancel')}
                                            </Button>
                                        }
                                    </div>
                                </div>
                                <div className='col-md-6 d-flex justify-content-end align-items-center'>
                                    {allowAvailability && !isShowAvailability ?
                                        <Button disabled={!assignees.length} wd type="submit" onClick={this.ShowAvailability}>
                                            {t('Show Availability')}
                                        </Button>
                                        :
                                        <Button wd type="submit" onClick={this.ShowAvailability}>
                                            {t('Back')}
                                        </Button>
                                    }
                                    {!isShowAvailability &&
                                        <div className='ms-2'>
                                            <Checkbox
                                                isChecked={do_notify}
                                                onChange={(checked) => this.setState({ do_notify: checked })}
                                                label={this.props.t('Notify user')}
                                                disabled={isMember}
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                        </Modal.Footer>
                    }
                </Modal>
            </Fragment >
        );
    }
}

export default inject('userStore', 'commonStore', 'scheduleStore')(applicationRouter(withLocalization(observer(BulkShiftForm))));
