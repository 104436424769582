import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

//components
import applicationRouter from '~/hoc/applicationRouter';
import withRemoveDialog from '~/hoc/withRemoveDialog';
import withLocalization from '~/hoc/withLocalization';
import GenericList from '~/components/GenericList/GenericList';
import GenericFilter from '../../../../components/GenericList/GenericFilter';

//elements
import Button from '../../../../components/CustomButton/CustomButton.jsx';

//services
import config from '~/library/config';
import agent from '~/library/agent';

//utils
import { shortDateFormat } from '~/utils/dateFormat';

//assets
import Edit from '~/assets/img/editing.svg';
import Delete from '~/assets/img/deleting.svg';

//styles
import '../../../../styles/css/styles.css';

//this component for listing all document_templates and handles the fucntionality over them//
class DocumentTemplateTable extends Component {
    handleTableActionButtonAction = (id, type) => {
        //handles action button clicks//
        if (type === 'edit') {
            //edit document_template
            this.editDocumentTemplate(id);
        } else if (type === 'delete') {
            //delete document_template
            this.removeDocumentTemplate(id);
        } else if (type === 'report') {
            //generate document_template report
            this.printTemplateReport(id);
        } else if (type === 'status_report') {
            this.printDocumentBookEmployeeReadStatusReport(id);
        }
    };

    printDocumentBookEmployeeReadStatusReport = (id) => {
        //generates read status report for docuemnt_book of employees//
        const { commonStore } = this.props;

        let hydrated = {
            authorization: commonStore.token,
        };
        window.open(`${config.API_ROOT}/document_read/report/${id}${agent.convertToGetParams(hydrated)}`);
    };

    printTemplateReport(id) {
        //generates template_documents report//
        const { commonStore } = this.props;

        let hydrated = {
            authorization: commonStore.token,
        };

        window.open(`${config.API_ROOT}/document_templates/report/${id}${agent.convertToGetParams(hydrated)}`);
    }

    //calls action from documentStore to delete document_template with id//
    removeDocumentTemplate(id) {
        const { commonStore, t } = this.props;

        return this.props.handleDelete(id, (id) => {
            return this.props.documentStore.deleteDocumentTemplateById(id).then((res) => {
                if (res) {
                    commonStore.addNotification(t('Document Book Removed'), null, 'error');
                    this.props.documentStore.resetLastTemplateListLoadTime();
                }
                return res;
            });
        });
    }

    editDocumentTemplate(id) {
        //calls parentHandler to redirect to addEditComponent with given Id//
        this.props.router.navigate(`/admin/documents/templates/${id}`);
    }

    renderTableActionButtons = (id, mailReport, status) => {
        //renders table edit delete buttons in generic list//
        const { t } = this.props;
        const { user_type } = this.props.userStore.currentUser;
        return (
            <div>
                {user_type !== 'member' ? (
                    <div className="actions-center">
                        <OverlayTrigger
                            key="PDF"
                            placement="top"
                            overlay={<Tooltip id="tooltip-top">{t('Download As PDF')}</Tooltip>}
                        >
                            <Button
                                icon_sm
                                fill
                                onClick={() => this.handleTableActionButtonAction(id, 'report')}
                            >
                                <i className="fa fa-print actionblueicon" />
                            </Button>
                        </OverlayTrigger>

                        {mailReport && (
                            <OverlayTrigger
                                key="Report"
                                placement="top"
                                overlay={<Tooltip id="tooltip-top">{t('Download Signed Report')}</Tooltip>}
                            >
                                <Button
                                    icon_sm
                                    fill
                                    onClick={() => this.handleTableActionButtonAction(id, 'status_report')}
                                >
                                    <i className="fa fa-file-alt actionblueicon" />
                                </Button>
                            </OverlayTrigger>
                        )}
                        <Button
                            icon_sm
                            fill
                            onClick={() => this.handleTableActionButtonAction(id, 'edit')}
                        >
                            <img src={Edit} alt="edit" />
                        </Button>
                        <Button
                            icon_sm_delete
                            fill
                            onClick={() => this.handleTableActionButtonAction(id, 'delete')}
                        >
                            <img src={Delete} alt="delete" />
                        </Button>

                    </div>
                ) : (
                    <div>
                        <Button
                            fill
                            wd
                            onClick={() => this.handleTableActionButtonAction(id, 'edit')}
                        >
                            {t('View')}
                        </Button>
                    </div>
                )}
            </div>
        );
    };

    render() {
        //renders document_template list using generic list along with documentFilter//

        const { filters, loading, appliedFilters } = this.props.documentStore;
        const { config } = this.props.commonStore;
        const dateTimeRules =
            config.client && config.client.data && config.client.data.dateTimeRules
                ? config.client.data.dateTimeRules
                : false;

        return (
            <GenericList
                columns={[
                    {
                        Header: 'Document Book',
                        id: 'template_name',
                        accessor: ({ id, template_name }) => (
                            <a
                                className="list-main-column"
                                onClick={() => this.handleTableActionButtonAction(id, 'edit')}
                            >
                                {`${template_name}`}
                            </a>
                        ),
                        isSortable: true,
                        textAlign: 'start'
                    },
                    {
                        Header: 'Created By',
                        id: 'created_by',
                        accessor: ({ created_by }) => <span>{created_by}</span>,
                        isSortable: true,
                    },
                    {
                        Header: 'Updated On',
                        id: 'updated_at',
                        accessor: ({ updated_at }) => (
                            <span>{updated_at && shortDateFormat(updated_at, dateTimeRules)}</span>
                        ),
                        isSortable: true,
                    },
                    {
                        Header: 'Actions',
                        id: 'actions',
                        accessor: (full) => this.renderTableActionButtons(full.id, full.mailReport, full.status),
                        maxWidth: 80
                    },
                ]}
                header={
                    <GenericFilter
                        filters={filters}
                        setFilter={(name, value) => this.props.documentStore.setFilter(name, value)}
                        onFilter={() => this.props.documentStore.onFilter()}
                        loading={loading}
                    />
                }
                filters={appliedFilters}
                lastListLoadTime={this.props.documentStore.lastTemplateListLoadTime}
                requestData={(params) => this.props.documentStore.getDocumentTemplates(params)}
            />
        );
    }
}

export default inject(
    'authStore',
    'userStore',
    'commonStore',
    'documentStore'
)(applicationRouter(withLocalization(withRemoveDialog(observer(DocumentTemplateTable)))));
