import React, { Component } from 'react';
import applicationRouter from '~/hoc/applicationRouter'
import { inject, observer } from 'mobx-react';

import UsersList from './UsersList';
import UsersForm from './UsersForm';

import withRemoveDialog from '~/hoc/withRemoveDialog';
import withLocalization from '~/hoc/withLocalization';

class UsersPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mode: {
                id: 0,
                add: false,
            },
            page: 0,
            isPageAddedOrEdited: false
        }
        this.onAddEdit = this.onAddEdit.bind(this);
        this.goBack = this.goBack.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.getListUrl = this.getListUrl.bind(this);
    }

    onAddEdit(id = 0) {
        const mode = { id: id, add: !id, };
        this.setState({ mode: mode, isPageAddedOrEdited: true });
    }

    //this method is replacing the URL pushing part while add/edit//
    goBack = (page = 0) => {
        const mode = { id: 0, add: false };
        this.setState({ mode: mode, page, isPageAddedOrEdited: true });
    }

    //method to maintain react-table page in parent component//
    handlePageChange = (page, isEdit) => {
        this.setState({ page: page, isPageAddedOrEdited: isEdit });
    }

    getListUrl = () => { return '/admin/superadmins' };

    render() {
        //const mode = this.editingId();
        const { mode, page, isPageAddedOrEdited } = this.state;
        const { userStore } = this.props;
        const userType = userStore.currentUser.user_type;
        if (userType === 'member') {
            return <div>Not Allowed</div>;
        }


        return (
            <>
                {(mode.id === 0 && mode.add === false) ?
                    <UsersList
                        mode="superadmins"
                        onAddEdit={this.onAddEdit}
                        handlePageChange={(page, isEdit) => this.handlePageChange(page, isEdit)}
                        page={page}
                        isPageAddedOrEdited={isPageAddedOrEdited}
                    />
                    :
                    <UsersForm
                        id={mode.id} add={mode.add} mode="superadmins"
                        page={page}
                        goBack={this.goBack}
                        getListUrl={() => this.getListUrl()}
                    />
                }
            </>
        );
    }
}
export default inject('authStore', 'userStore', 'commonStore')(applicationRouter(withLocalization(withRemoveDialog(observer(UsersPage)))));
