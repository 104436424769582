import React, { Component } from 'react';
import ShowMoreText from 'react-show-more-text'

class DescriptionText extends Component {
    constructor (props){
        super(props);
    };

    render() {
        const textStyle = {
            color: this.props.color ? this.props.color :'grey',
            fontSize : this.props.size ? this.props.size : '12px',
        };
        const textLine = this.props.textLine ? parseInt(this.props.textLine) : 1;
        const readMore = this.props.readMore ? this.props.readMore : "Read more";
        const readLess = this.props.readLess ? this.props.readLess : "Read less";
        const dynamicWidth = this.props.width ? parseInt(this.props.width) : 0;        
        return (
            <div style={textStyle}>
                 <ShowMoreText
                        lines = {textLine}
                        more = {readMore}
                        less = {readLess}
                        anchorClass="style-show-more"
                        onClick={this.executeOnClick}
                        expanded={false}
                        width={dynamicWidth}
                 >
                    {this.props.label}
                 </ShowMoreText>

            </div>
        );
    }
}   

export default DescriptionText;
