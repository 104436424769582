import React, { Component } from 'react';
import applicationRouter from '~/hoc/applicationRouter'
import { inject, observer } from 'mobx-react';
import withRemoveDialog from '~/hoc/withRemoveDialog';
import withLocalization from '~/hoc/withLocalization';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/ck-build/ckeditor';

import './style.css';


export class ContractPDF extends Component {

    constructor(props) {
        super(props);
        this.state = {
            editorContent: '',
            key: '',
            theme: 'snow',
            modules: {
                toolbar: false
            }
        }
    }
    componentDidMount() {
        const { contractStore, location } = this.props;
        const search = this.props.location.search;
        const id = search.slice(4);
        contractStore.getPDFData(id).then((res) => {
            this.setState({ editorContent: res.contract_archive_data.body, key: res.contract_archive_data.contract_unique_id });
        });
    }


    render() {
        return (
            <div className='ck-contracts ck-content' style={{ padding: '1em' }}>
                {/* <div className='uniq_id' style={{paddingLeft: '1em'}}>id:{this.state.key}</div> */}
                <div  dangerouslySetInnerHTML={{__html: this.state.editorContent}}></div>
                {/* <CKEditor
                    editor={Editor}
                    data={this.state.editorContent}
                    onFocus={ ( event, editor ) => {
                       event.target.blur();
                    } }
                    onReady={editor => {
                        // editor.blur();
                        const editableElement = editor.ui.view.editable.element;
                        const toolbarElement = editor.ui.view.toolbar.element;
                       
                        editor.on('change:isReadOnly', (evt, propertyName, isReadOnly) => {
                            if (isReadOnly) {
                                editableElement.style.borderColor = 'transparent';
                                toolbarElement.style.display = 'none';
                            } else {
                                toolbarElement.style.display = 'flex';
                            }
                        });
                        

                        this.loadData();
                        editor.enableReadOnlyMode('my-feature-id');
                    }}

                /> */}
                
            </div>
        )
    }
}

export default inject('authStore', 'contractStore', 'commonStore')(applicationRouter(withLocalization(withRemoveDialog(observer(ContractPDF)))));