import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { find } from 'lodash';
import moment from 'moment/min/moment-with-locales';

//components
import withRemoveDialog from '~/hoc/withRemoveDialog';
import applicationRouter from '~/hoc/applicationRouter';
import DatePicker from '../../../components/DatePicker/DatePicker';
import CustomCheckbox from '../../../components/CustomCheckbox/CustomCheckbox';
import { MembersWidget } from '../../../components/GenericForm/Widgets/UsersWidget';

//elements
import Select from '../../../elements/Select';

//utils
import { longDateFormat } from '../../../utils/dateFormat';

class AbsenceReportDownloadModal extends Component {
    state = {
        dateRange: { start: moment(new Date()).startOf('month'), end: moment(new Date()) },
        all_members: false,
        users: [],
    };

    onSelect = (date, field) => {
        const dr = this.state.dateRange;
        dr[field] = moment(date);
        this.setState({ dateRange: dr });
        this.props.handleDownloadFormInputChange(field, this.state.dateRange[field].format('YYYY-MM-DD'));
    };

    onCheckboxChange = (name, value) => {
        this.setState({ all_members: value });
        this.props.handleDownloadFormInputChange('all_members', value);
    };

    onMemberChange = (id, user) => {
        const users = user ? user.map((u) => u.value) : [];
        this.setState({ users });
        this.props.handleDownloadFormInputChange('users', users.join(','));
    };

    changeType = (e) => {
        this.props.handleDownloadFormInputChange('type', e.value);
    };

    render() {
        const { t } = this.props;
        const { currentUser } = this.props.userStore;        
        const options = [{label:t('Paid'),value:'paid'},{label:t('Unpaid'),value:'unpaid'}];        
        const { client } = this.props.commonStore.config;
        const { status } = this.props.absenceStore;
        const statuses = client ? client.data.extendedRules.absent_types : false;
        const dateTimeRules = client.data && client.data.dateTimeRules ? client.data.dateTimeRules : false;
        options.push({
            label: t('All'),
            value: 'all',
        });
        statuses.map(({ code, name }) =>
            options.push({
                label: name,
                value: code,
            })
        );

        const currentSelect = find(options, (o) => {
            return o.value == status;
        });

        return (
            <>
                <div style={{ display: 'flex' }}>
                    <div style={{ paddingRight: '20px' }}>
                        <label>{t('From')}</label>
                        <DatePicker
                            selected={this.state.dateRange.start.toDate()}
                            onChange={(date) => this.onSelect(date, 'start')}
                            dateFormat={longDateFormat(dateTimeRules)}
                        />
                    </div>
                    <div>
                        <label>{t('To')}</label>
                        <DatePicker
                            selected={this.state.dateRange.end.toDate()}
                            onChange={(date) => this.onSelect(date, 'end')}
                            dateFormat={longDateFormat(dateTimeRules)}
                        />
                    </div>
                </div>
                {this.props.userStore.currentUser.user_type != 'member' && (
                    <div style={{ marginTop: '15px' }}>
                        <label>{t('Select Members')}</label>
                        <CustomCheckbox
                            label={t('All members/employees')}
                            onChange={(v) => this.onCheckboxChange('all_members', v)}
                            isChecked={this.state.all_members}
                            inline
                        />
                        <MembersWidget
                            isMulti
                            value={this.state.users}
                            placeholder={t('Select...')}
                            onChange={(id, user) => this.onMemberChange(id, user)}
                        />
                        {t(
                            'Report for all members/employees will be too big. We recommend to not select too many members at a time.'
                        )}
                    </div>
                )}
                <div style={{ marginTop: '15px' }}>
                    <label>{t('Absence types')}</label>
                    <Select
                        classNamePrefix="time-select"
                        className="time-select"
                        options={options}
                        currentSelect={currentSelect}
                        onChange={(e) => this.changeType(e)}
                    />
                </div>
            </>
        );
    }
}
export default inject(
    'authStore',
    'absenceStore',
    'userStore'
)(applicationRouter(withRemoveDialog(observer(AbsenceReportDownloadModal))));
