import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment/min/moment-with-locales';

//components
import applicationRouter from '~/hoc/applicationRouter'
import withLocalization from '~/hoc/withLocalization';
import GenericForm from '~/components/GenericForm/GenericForm';

//elements
import LoadingSpinner from '~/elements/LoadingSpinner';

//form-schema
import getSchema from '~/library/schemas/user';

//utils
import customerFeature from '~/utils/customerFeature';

const uiSchema = {
    'ui:field': 'tabs',
    'ui:tabs': [
        {
            'ui:title': 'Personal Info',
            'ui:layout:hideframe': true,
            id: 'tab1',
            'ui:field': 'layout',
            'ui:layout': [
                {
                    'ui:subtitle': 'Personal information',
                    'ui:className': 'profile-edit-form__group align-items-center',
                    image: { xs: { span: 12, order: 1 }, sm: { span: 4, order: 1 }, md: 3 },
                    first_name: { xs: { span: 12, order: 1 }, sm: { span: 4, order: 1 }, md: 3, className: "mt-2 mt-md-1" },
                    last_name: { xs: { span: 12, order: 1 }, sm: { span: 4, order: 1 }, md: 3, className: "mt-2 mt-md-1" },
                    email: { xs: { span: 12, order: 1 }, sm: { span: 6, order: 1 }, md: 3, className: "mt-2 mt-md-1" },
                    phone: { xs: { span: 12, order: 1 }, sm: { span: 6, order: 1 }, md: { span: 3 }, className: "mt-2" },
                    bank_account_number: { xs: { span: 12, order: 1 }, sm: { span: 6, order: 1 }, md: 3, className: "mt-2" },
                    social_number: { xs: { span: 12, order: 3 }, sm: { span: 6, order: 1 }, md: { span: 3 }, className: "mt-2" },
                    birthday: { xs: { span: 12, order: 3 }, sm: { span: 6, order: 1 }, md: { span: 3 }, className: "mt-2" },
                },
                {
                    'ui:subtitle': 'Assecces',
                    'ui:className': 'profile-edit-form__group',
                    username: { xs: 12, sm: 6, md: 4 },
                    password: { xs: 12, sm: 6, md: 4 },
                    internal_number: { xs: 6, sm: 6, md: 2 },
                },
                {
                    'ui:className': 'profile-edit-form__group',
                    attachments: { md: 12 },
                },
                {
                    'ui:className': 'profile-edit-form__group',
                    data: { md: 12,
                        className:"custom-layout-class"
                     },
                },
            ],
            image: {
                'ui:widget': 'ImageUpload',
            },
            attachments: {
                'ui:widget': 'AttachmentsWidget',
                'ui:imageContext': {
                    model: 'User',
                    fileType: 'docs',
                    id: 0,
                },
            },
            data: {
                classNames: 'no-border-around-lists no-fieldset-title',
                'ui:layout:hideframe': true,
                'ui:layout': [
                    {
                        is_single_parent: { className: 'col-md-4' },
                    },
                ],
                holidays: {
                    'ui:widget': 'hidden',
                },
                enable_pause_button: {
                    'ui:widget': 'hidden',
                },
                disable_manual_log: {
                    'ui:widget': 'hidden',
                },
                is_single_parent: {
                    'ui:widget': 'Radio',
                },
                kids: {
                    classNames: 'form-chooser',
                    items: {
                        name: {
                            classNames: 'col-md-4',
                            'ui:placeholder': 'Name',
                        },
                        date: {
                            classNames: 'col-md-4',
                        },
                        chronic_disease: {
                            classNames: 'col-md-4',
                            'ui:widget': 'Radio',
                        },
                    },
                    'ui:options': {
                        orderable: false,
                    },
                },
                nomines: {
                    classNames: 'form-chooser',
                    items: {
                        name: {
                            classNames: 'col-12 col-md-6',
                            'ui:placeholder': 'Name',
                        },
                        contactNumber: {
                            classNames: 'col-12 col-md-6',
                            'ui:placeholder': '987-785-7865',
                        },
                    },
                    'ui:options': {
                        orderable: false,
                    },
                },
            },
        },
        {
            'ui:title': 'Profile Settings',
            'ui:layout:hideframe': true,
            id: 'tab2',
            'ui:field': 'layout',
            'ui:layout': [
                {
                    address: { xs: 12, sm: 8, md: 4 },
                    post_number: { xs: 6, sm: 4, md: 4 },
                    post_place: { xs: 6, sm: 6, md: 4 },
                    gps_data: { xs: 12, sm: 6, md: 4 },
                    home_payment_enabled: { xs: 12, sm: 12, md: 8, className: 'my-3 my-md-4' },
                },
                {
                    employee_percent: { xs: 12, sm: 6 },
                    hourly_rate: { xs: 12, sm: 6 },
                },
                { data: { md: 12, className:"custom-layout-class" } },
            ],
            gps_data: {
                "ui:readonly": true
            },
            home_payment_enabled: {
                classNames: 'no-fieldset-title',
                'ui:widget': 'CheckBoxWidget',
            },
            data: {
                classNames: 'no-border-around-lists no-fieldset-title mt-3',
                'ui:layout:hideframe': true,
                is_single_parent: {
                    'ui:widget': 'hidden',
                },
                kids: {
                    classNames: 'd-none',
                },
                nomines: {
                    classNames: 'd-none',
                },
                holidays: {
                    'ui:widget': 'DaysOfWeek',
                },
                enable_pause_button: {
                    classNames: 'no-fieldset-title mt-2',
                    'ui:widget': 'CheckBoxWidget',
                },
                disable_manual_log: {
                    classNames: 'no-fieldset-title mt-2',
                    'ui:widget': 'CheckBoxWidget',
                },
            },
        },
    ],
};

class Profile extends Component {
    loadData() {
        const {
            userStore
        } = this.props;
        userStore.load(userStore.currentUser.id, false);
    }

    componentDidMount() {
        this.loadData();
    }

    componentWillMount() {
        this.props.userStore.loading = true;
    }

    componentDidUpdate(previousProps) {
        const { location } = this.props;
        if (location !== previousProps.location) {
            this.loadData();
        }
    }

    onSave(values) {
        const {
            userStore,
            t, commonStore
        } = this.props;
        return userStore.save(values, !values, 'profile').then(result => {
            if (!result.user || !result.user.id) {
                // some error
                commonStore.addNotification(t(result.message || 'Error'), null, 'error');
                return false;
            }
            userStore.pullUser();
            commonStore.addNotification(t('Saved'), null, 'success');
            this.props.router.navigate(this.getListUrl())
            return true;
        });
    }

    validateUser(formData, errors) {
        const { t } = this.props;
        if (formData.birthday && moment(formData.birthday).isSameOrAfter(moment())) {
            errors.birthday.addError(t("Please enter valid birthdate"));
        }
        if (formData.username && formData.username.trim() === '') {
            errors.username.addError(t("is a required property"));
        }
        if (formData && formData.data && formData.data.kids && formData.data.kids.length) {
            formData.data.kids.forEach((kid, index) => {
                if (kid.date && moment(kid.date).isSameOrAfter(moment())) {
                    errors.data.kids[index].date.addError(t("Please enter valid birthdate"));
                }
            });
        }
        return errors;
    }

    prepareSchema(schema, currentEntity) {
        schema['ui:tabs'][0].image = {
            'ui:widget': 'ImageUpload',
            'ui:imageContext': {
                model: 'User',
                fileType: 'logo',
                id: currentEntity ? currentEntity.id : 0,
            },
        };
        schema['ui:tabs'][0].attachments = {
            'ui:widget': 'AttachmentsWidget',
            'ui:imageContext': {
                model: 'User',
                fileType: 'docs',
                id: currentEntity ? currentEntity.id : 0,
                test: '...',
                existingAttachment: currentEntity && currentEntity.Attachments ? currentEntity.Attachments : [],
            },
        };
        if (customerFeature('locked_mode')) {
            //  && mode != 'profile'
            schema['ui:tabs'][0]['ui:layout'][1].generic_pin = { xs: 6, sm: 6, md: 2 };
        }

        return schema;
    }

    getBaseSchema() {
        const { userStore, add } = this.props;
        const { currentEntity } = userStore;
        return getSchema({
            config: this.props.commonStore.config,
            isAdd: add,
            userType: add ? '' : currentEntity.user.user_type,
            mode: 'profile'
        });
    }

    getListUrl() {
        return '/admin';
    }

    async recalcGps(e) {
        return this.props.taskStore.recalcGpsForTask(e, true);
    }

    render() {
        const { userStore } = this.props;
        const { loading, currentEntity } = userStore;
        if (loading) return <LoadingSpinner />;

        return (
            <div className="primary-page profile-edit-form">
                <GenericForm
                    entity={currentEntity.user}
                    uiSchema={this.prepareSchema(uiSchema, currentEntity.user)}
                    schema={this.getBaseSchema()}
                    translationScope="forms.user"
                    onSave={values => this.onSave(values)}
                    recomputeFields={['gps_data', 'addressCache']}
                    forceRefresh={!!currentEntity.user.gps_data}
                    onChange={async data => this.recalcGps(data)}
                    listUrl={this.getListUrl()}
                    isAdding={false}
                    customvalidate={(formData, errors) => this.validateUser(formData, errors)}
                />
            </div>
        );
    }
}

export default inject('userStore', 'commonStore', 'taskStore')(applicationRouter(withLocalization(observer(Profile))));
