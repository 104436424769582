import moment from 'moment';
import React, { Component } from 'react';
import config from '../../../library/config';

class SignatureWrapper extends Component {

    render() {
        const signTitle = config.SERVER_IS_NORWAY ? "Norsktimeregister" : "Time&Control";
        let addHours = config.SERVER_IS_NORWAY ? 2 : -4;
        // last sun of march to last sunday of october : add 2 hours
        // in other days : add 1 hour
        let time = moment().utc().add(addHours,'hours');
        time = time.format('YYYYMMDDhhmmss');
        return (
            <div className="divInlineBlock paddingSign" style={{visibility : "none" , zIndex : -1}}>
                <div className="signWrap signInlineBorder signResponsive">
                    <span className="signBorderLeft"></span>
                    <span className="signBorderRight"></span>
                    <span className="signTitle">{signTitle}</span>
                    <img className="sign font-15" src={this.props.imgSrc} alt="image here" style={{maxWidth:'300px' ,maxHeight:'300px'}}></img>
                    <span className="signUniqueId">{time}</span>
                </div>
            </div>
        );
    }
}
export default SignatureWrapper;
