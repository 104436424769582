import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { inject, observer } from 'mobx-react';

// Components
import withLocalization from '~/hoc/withLocalization';
import applicationRouter from '~/hoc/applicationRouter';
import ForgotPassword from './ForgotPassword';

// assets
import CloseIcon from '../../assets/images/CloseIcon.svg';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cardHidden: true,
            forgotPwdModal: false
        };
    }

    componentWillUnmount() {
        const { authStore } = this.props;
        authStore.reset();
    }

    handleEmailChange = (e) => this.props.authStore.setEmail(e.target.value);

    handlePasswordChange = (e) => this.props.authStore.setPassword(e.target.value);

    handleSubmitForm = (e) => {
        e.preventDefault();
        const { authStore } = this.props;
        authStore
            .login()
            .then(() => {
                const lastPage = sessionStorage.getItem('lastPage');
                if (lastPage) {
                    sessionStorage.removeItem('lastPage');
                    this.props.router.navigate(`${lastPage}`);
                } else {
                    this.props.router.navigate('/admin');
                }
            })
            .catch((e) => {
                console.error(e);
            });
    };


    componentDidMount() {
        setTimeout(() => {
            this.setState({ cardHidden: false });
        }, 700);
    }

    render() {
        const { t } = this.props;
        return (
            <div>
                {!this.state.forgotPwdModal &&
                    <>
                        <form className="login-container">
                            <div className="row">
                                <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                    <div className="form-group business-name">
                                        <label htmlFor="companyName" className="big-text fw-bold">
                                            {t('Email')} / {t('Username')}
                                        </label>
                                        <label className="red-star">*</label>
                                        <p className="small-text st fw-bold">{t('Add email address')} / {t('Username')}</p>
                                        <input
                                            type="text"
                                            className="form-control login-input mb_25 mt-12"
                                            placeholder={`${t('Email address')} / ${t('Username')}`}
                                            required
                                            onChange={(e) => this.handleEmailChange(e)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                    <div className="form-group  business-name ">
                                        <label htmlFor="Password" className="big-text">
                                            {t('Password')}
                                        </label>
                                        <label className="red-star">*</label>
                                        <p className="small-text st">{t('Add password used to have an access to account')}</p>
                                        <input
                                            type="password"
                                            className="form-control login-input mt-12 mb_25"
                                            placeholder={t('Password')}
                                            required
                                            onChange={(e) => this.handlePasswordChange(e)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <button className="btn btn-block fwBold fs18 loginbutton" onClick={this.handleSubmitForm}>
                                {t("Login")}
                            </button>
                        </form>
                        <div className='d-flex w-100 justify-content-between mt-3 mb-3'>
                            <button className="fs18 loginFooter border-none" onClick={() => this.setState({ forgotPwdModal: true })}>{t('Forgot password')}?</button>
                            <Link className="fs18 loginFooter border-none text-decoration-none" to='/loginAsCustomer'>{t('Login as Customer')}?</Link>
                        </div>
                    </>
                }
                {/* forgot password modal */}
                <Modal
                    show={this.state.forgotPwdModal}
                    backdropClassName="dark-backdrop"
                    className="login_modal"
                >
                    <Modal.Header className="setHeader">
                        <Modal.Title className="fs22 loginTitle">{t('Forgot password').toUpperCase()}</Modal.Title>
                        <div className="close-icon" onClick={() => this.setState({ forgotPwdModal: false })}>
                            <img src={CloseIcon} className="close-img" alt="Close Icon"></img>
                        </div>
                    </Modal.Header>

                    <Modal.Body>
                        <ForgotPassword backToLogin={() => this.setState({ forgotPwdModal: false })} />
                    </Modal.Body>
                    <Modal.Footer className="fs18 loginFooter" onClick={() => this.setState({ forgotPwdModal: false }, this.props.handleBackToLogin && this.props.handleBackToLogin())}>
                        <button>{t('Back to login')}</button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default inject('authStore', 'commonStore')(applicationRouter(withLocalization(observer(Login))));
