import React, { Component } from 'react';
import applicationRouter from '~/hoc/applicationRouter'
import { inject, observer } from 'mobx-react';

import TimelogsList from './TimelogsList';

import withRemoveDialog from '~/hoc/withRemoveDialog';

class TimelogsPage extends Component {
    onAdd() {
        this.props.router.navigate('/admin/timelogs/add');
    }
    componentWillMount() {
        const params = new URLSearchParams(this.props.router.location.search);
        const filterByStatus = params.get('status');
        this.props.timelogStore.setFilter('status', filterByStatus || null);
        this.props.timelogStore.onFilter();
    }
    render() {
        return (
            <TimelogsList
                onAdd={() => this.onAdd()}
            />
        );
    }
}
export default inject('authStore', 'timelogStore')(applicationRouter(withRemoveDialog(observer(TimelogsPage))));
