import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment/min/moment-with-locales';

//library//
import agent from '~/library/agent';
import config from '~/library/config';

//components//
import applicationRouter from '~/hoc/applicationRouter'
import withLocalization from '~/hoc/withLocalization';
import MonitoringHistoryTable from './MonitoringHistoryTable';
import MonitorGridFilter from './MonitorGridFilter'

class MonitortingHistory extends Component {

    state = {
        from: moment(new Date()).startOf('month').toDate(),
        to: moment(new Date()).toDate(),
        monitor: '',
        id: ''
    };

    async loadData() {
        const { monitorStore } = this.props;
        let getId = (this.props.router.location.state && this.props.router.location.state.monitoring) || null;
        if (getId) {
            const monitorData = await monitorStore.loadGrid(getId, this.appliedFilters);
            this.setState({
                monitor: [monitorData]
            })
        }
    }

    componentDidMount() {
        this.loadData();
    }

    printMonitorReport(id) {
        //generates monitor report//
        const { commonStore, monitorStore } = this.props;

        let hydrated = {
            authorization: commonStore.token,
            id: id,
            fromDate: monitorStore.filters.fromDate || '',
            toDate: monitorStore.filters.toDate || ''
        }
        window.open(`${config.API_ROOT}/monitor/report/${id}${agent.convertToGetParams(hydrated)}`);
    }

    render() {
        const { t, monitorStore } = this.props;
        let getId = (this.props.router.location.state && this.props.router.location.state.monitoring) || null;
        const { filters } = monitorStore;
        // date = this.state.monitor.createdAt
        return (
            <>
                <div className='monitoring-history-container'>
                    <div className='history-title'>
                        <h5>{t('HISTORY')}</h5>
                        <div>
                            {/* <button className="btn" onClick={() => this.onCancel()}>{t('Preview')}</button> */}
                            <button className="btn btn-fill" onClick={() => this.printMonitorReport(getId)} style={{ marginLeft: "10px" }}>{t('Export as PDF')}</button>
                        </div>
                    </div>
                    <div className='sub-history-container'>
                        <MonitorGridFilter
                            filters={filters}
                            setFilter={(name, value) => this.props.monitorStore.setFilter(name, value)}
                            onFilter={() => this.props.monitorStore.onFilter()}
                            id={getId}
                        />
                    </div>
                    <MonitoringHistoryTable styling='auto' />
                </div>
            </>
        );
    }
}

export default inject('monitorStore', 'userStore', 'commonStore')(withLocalization(applicationRouter(observer(MonitortingHistory))));
