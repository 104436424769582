import React, { Component } from 'react';
import applicationRouter from '~/hoc/applicationRouter'
import { inject, observer } from 'mobx-react';
import _ from 'lodash';

import config from '~/library/config';
import '../../../styles/css/styles.css';
import moment from 'moment/min/moment-with-locales';
// Components
import {AttachmentsWidget} from '../../../components/GenericForm/Widgets/AttachmentsWidget';
import DatePicker from '../../../components/DatePicker/DatePicker';
import Signature from '../../../components/Signature/Signature';
import { Textarea } from '../../../elements/Input';
import { longDateFormat } from '../../../utils/dateFormat';

import withRemoveDialog from '~/hoc/withRemoveDialog';
import withLocalization from '~/hoc/withLocalization';

class ChecklistElementCheck extends Component {
    state = {
        element: {},
        due_date: new Date(),
        attachments: [],
        imageFileExtentions: ['jpg', 'jpeg', 'png', 'svg', 'svgz', 'webp', 'bmp', 'gif', 'j2k', 'ico'],
        attachments_by_user: '',
        attachments_by_user_list: [],
        isEdit: true
    };

    componentDidMount() {
        this.setState(
            {
                element: {
                    ...this.props.data,
                    siginig_required: this.props.siginig_required,
                    photo_required: this.props.photo_required,
                    due_date_required: this.props.due_date_required,
                    due_date: this.state.due_date,
                },
            },
            () => {
                this.handleSaveChecklistElement();
                this.renderAttachments();
            }
        );
        if(this.props.checklist_status && this.props.checklist_status == 'Approved'){
            this.setState({
                isEdit: false
            })
        } 
    }

    renderAttachments = (e) => {
        const { checklistStore } = this.props;
        var dataReq = {
            attachmentIds: this.state.element.attachments,
        };
        checklistStore.getMultipleElements(dataReq).then((elementsResult) => {
            this.setState({ attachments: elementsResult.attachments });
        });
        if (this.state.element.attachments_by_user !== null) {
            dataReq = {
                attachmentIds: this.state.element.attachments_by_user,
            };
            checklistStore.getMultipleElements(dataReq).then((elementsResult) => {
                this.setState({ attachments_by_user_list: elementsResult.attachments });
            });
        }
    };

    displayAttachments = (attachment, index) => {
        const { imageFileExtentions } = this.state;
        let fileType = attachment.filename != null ? attachment.filename.split('.').pop() : 'file';
        if (imageFileExtentions.includes(fileType.toLowerCase())) {
            return (
                <div key={attachment.id}>
                    <img
                        className="checklist-element-attachment"
                        src={`${config.API_ROOT}/attachments/${attachment.id}`}
                    />
                </div>
            );
        } else {
            return (
                <div className="checklist-element-pdf-attachment" key={attachment.id}>
                    <a href={`${config.API_ROOT}/attachments/${attachment.id}`} target="_blank">
                        {attachment.filename}
                    </a>
                </div>
            );
        }
    };

    handleEditorChange = (e) => {
        this.setState(
            {
                element: {
                    ...this.state.element,
                    comment_by_user: e.target.value,
                },
            },
            () => {
                this.handleSaveChecklistElement();
            }
        );
    };

    handleChange = (name, value) => {
        let element = this.state.element;
        this.setState({
            element: {
                ...element,
                comment: value,
            },
        });
        this.handleSaveChecklistElement();
    };

    handleSaveChecklistElement = () => {
        this.props.handleSaveChecklistElement(this.state.element);
    };

    render() {
        const { config, t, isUnionMode, commonStore, isOnlyText } = this.props;
        const { attachments } = this.state;
        const dateTimeRules = commonStore.config.client && commonStore.config.client.data && commonStore.config.client.data.dateTimeRules ? commonStore.config.client.data.dateTimeRules : false;
        return (
            <div>
                {!isOnlyText ? (
                    <div>
                        <div className="custom-form__row">
                            <div className="custom-form__col field field-string">
                                <label className="control-label">
                                    {t('Name')} : {this.state.element.name}
                                </label>
                            </div>
                            <div className="custom-form__col">
                                {this.state.element.status != undefined && !this.state.isEdit ? (
                                    <div>
                                        {' '}
                                        <label className="control-label">{t('Due Date')}</label> :{' '}
                                        {`${moment(this.state.element.updated_at).format(dateTimeRules.short_date_format)} ${moment(this.state.element.updated_at).format(dateTimeRules.time_format == "hh:mm" ? "HH:mm" : "hh:mm A" )}`}
                                    </div>
                                ) : (
                                    <div>
                                        <label className="control-label">{t('Due Date')}</label> : &nbsp;
                                        <DatePicker
                                            selected={this.state.due_date}
                                            onChange={(date) => this.handleChange('due_date', date)}
                                            dateFormat= {longDateFormat(dateTimeRules)}
                                            disabled
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        {this.state.element.comment && (
                            <div className="custom-form__row">
                                <div className="custom-form__col">
                                    <label className="control-label">{t('Description')} : </label>
                                    {this.state.element.comment}
                                </div>
                            </div>
                        )}

                        <div className="custom-form__col">
                            {this.state.attachments && this.state.attachments.length &&
                                this.state.attachments.map((item, index) => this.displayAttachments(item, index))}
                        </div>
                        <div className="custom-form__row" style={{ fontSize: '18px' }}>
                            <div className="custom-form__col">
                                <label className="control-label">{t('User')}:</label>{' '}
                                {this.state.element.updated_by_name}
                            </div>
                        </div>

                        {this.props.siginig_required &&
                        <div className="custom-form__row">
                            <div className="custom-form__col mb-4">
                                {this.state.element.status != undefined && !this.state.isEdit ? (
                                    <div>
                                        <label className="control-label">{t('Signature')} </label>
                                        <img src={this.state.element.signature} width={300} />
                                    </div>
                                ) : (
                                    <div>
                                        <label className="control-label">{t('Signature')} </label>
                                        <Signature
                                            onChange={(signature) => {
                                                this.setState({
                                                    element: {
                                                        ...this.state.element,
                                                        signature: signature,
                                                    },
                                                },()=>{
                                                this.handleSaveChecklistElement();
                                                });
                                            }}
                                            initialSignature={this.state.element.signature}
                                            required
                                            width="100%"
                                            height={250}
                                            error={this.state.element.siginig_required && !this.state.element.signature}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        }
                        {this.state.element.status != undefined && !this.state.isEdit ? (
                            <div className="custom-form__col mb-4 mt-2">
                                <label className="control-label">{t('Uploaded Files')}</label> :
                                {this.state.attachments_by_user_list && this.state.attachments_by_user_list.length &&
                                    this.state.attachments_by_user_list.map((item, index) =>
                                        this.displayAttachments(item, index)
                                    )}
                            </div>
                        ) : (
                            <div className="attachment-box mb-4 mt-5" style={{ width: '100%' }}>
                                {this.state.element.photo_required && !this.state.element.attachments_by_user ? (
                                    <span className="attachment-box__label" style={{ color: 'red' }}>
                                        {t('Upload Files')} *
                                    </span>
                                ) : (
                                    <span className="attachment-box__label">{t('Upload Files')}</span>
                                )}
                                <AttachmentsWidget
                                    value={this.state.element.attachments_by_user}
                                    onChange={(attachIds) => {
                                        this.setState(
                                            {
                                                element: {
                                                    ...this.state.element,
                                                    attachments_by_user: attachIds,
                                                },
                                            },
                                            () => this.handleSaveChecklistElement()
                                        );
                                    }}
                                    options={{
                                        imageContext: {
                                            existingAttachment: this.state.attachments_by_user_list,
                                            fileType: 'docs',
                                            id: 0,
                                            model: 'Image',
                                        },
                                    }}
                                    error={this.state.element.photo_required && !this.state.element.attachments_by_user}
                                />
                            </div>
                        )}
                        {this.state.element.status != undefined && !this.state.isEdit ? (
                            <div className="custom-form__row">
                                <div className="custom-form__col">
                                    <label className="control-label">{t('Comment')}</label> :{' '}
                                    {this.state.element.comment_by_user}
                                </div>
                            </div>
                        ) : (
                            <Textarea
                                label={t('Comment')}
                                style={{ width: '100%' }}
                                value={this.state.element.comment_by_user}
                                name="comment"
                                onChange={(e) => this.handleEditorChange(e)}
                                placeholder={t('Enter comments here')}
                            />
                        )}
                    </div>
                ) : (
                    <>
                        <Textarea
                            label={t('Comment')}
                            style={{ width: '100%' }}
                            value={this.state.element.comment_by_user}
                            name="comment"
                            onChange={(e) => this.handleEditorChange(e)}
                            placeholder={t('Enter comments here')}
                        />
                    </>
                )}
            </div>
        );
    }
}

export default inject('checklistStore', 'authStore', 'userStore', 'commonStore')(
    applicationRouter(withLocalization(withRemoveDialog(observer(ChecklistElementCheck))))
);
