import React, { Component } from 'react';
import getBase64 from '~/utils/getBase64';

const imageTypes = ['image/jpg', 'image/jpeg', 'image/png', 'image/svg+xml', 'image/webp'];
class ImageUploadWidgetGeneral extends Component {
    constructor(props) {
        super(props);
        this.fileInput = React.createRef();
        this.state = {
            image: props.image ? props.image : null,
            showAddButton: props.image ? false : true
        }
    }

    onUpload = (e) => {
        //const { onChange } = this.props;
        const file = e.target.files[0];
        if (file == undefined) return;
        const { name } = file;
        if (!imageTypes.includes(file.type)) {
            return;
        }

        getBase64(file)
            .then(imgData => {
                this.setState({ image: imgData, showAddButton: false })
                this.props.onChange(imgData);
            })
            .catch(error => {
            });
    };

    removeImage(){
        this.setState({image:null,showAddButton:true});
        this.props.onChange(null);
    }

    render() {
        const { image, showAddButton } = this.state;
        return (
            <div className="uploads">
                {image && (
                    <div className="uploads-item uploads-item_single">
                        <div style={{ 'display': 'flex' }}>
                            <img
                                src={image}
                                style={{ width: '100%', maxWidth: '400px', maxHeight: '300px' }}
                                alt="Signature Image"
                            />
                            <i className='fa fa-times remove-btn'  onClick={()=>this.removeImage()}/>
                        </div>
                    </div>
                )}
                {showAddButton && (
                    <button className="uploads__add-button" 
                            type="button" onClick={() => this.fileInput.click()}>
                        <i className="pe-7s-cloud-upload" />
                    </button>
                )}
                <input
                    style={{ display: 'none' }}
                    type="file"
                    accept="image/png,image/jpeg"
                    ref={fileInput => {
                        this.fileInput = fileInput;
                    }}
                    onChange={this.onUpload}
                    multiple={false}                    
                />
            </div>
        );
    }
}

export default ImageUploadWidgetGeneral;
