const DeleteLoader = ({ t }) => {
    return (
        <div className="delete-loader-wrapper">
            <div className="loader-text">
                {t('Deleting records')}
                <div style={{ minWidth: '10vmin', marginLeft: '10px' }}>
                    <span className="typewriter-for-logout"></span>
                </div>
            </div>
        </div>
    )
}

export default DeleteLoader;
