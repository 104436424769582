import React, { Component } from 'react';
import applicationRouter from '~/hoc/applicationRouter';
import { inject, observer } from 'mobx-react';
import moment from 'moment';

//elements
import Button from '../../../components/CustomButton/CustomButton.jsx';
import StatusBox from '../../../elements/StatusBox';

//components
import withRemoveDialog from '~/hoc/withRemoveDialog';
import GenericList from '../../../components/GenericList/GenericList';
import GenericFilter from '../../../components/GenericList/GenericFilter';

// utils
import { serverTimeFormat } from '../../../library/core';

//assets
import Edit from '../../../assets/img/editing.svg';
import Delete from '../../../assets/img/deleting.svg';
class ContractsNew extends Component {
    state = {};
    contractForm = () => {
        this.props.router.navigate('/admin/contractsform');
    };

    handleTableActionButtonAction = (id, type) => {
        //handles action button clicks//
        if (type === 'edit') {
            //edit
            this.props.router.navigate(`/admin/contractsform/${id}`);
        } else if (type === 'delete') {
            //delete
            this.removeDocument(id);
        } else if (type === 'copy') {
            this.copyDocument(id);
        }
    };

    removeDocument(id) {
        const { commonStore } = this.props;
        return this.props.contractStore.deleteContracts(id).then((response) => {
            commonStore.addNotification('contracts Status change', null, 'success');
            this.props.contractStore.resetLastListLoadTime();
            return response;
        });
    }

    editDocument = async (id) => {
        const { contractStore, commonStore } = this.props;
        let data = {
            id: id,
            documentname: id.name,
            modulename: id.module,
            contracttype: id.contract_type_name,
        };
        await contractStore.getContractsById(id, data).then((response) => {
            if (response) {
                commonStore.addNotification('Edit', null, 'success');
            }
            return data;
        });
        this.props.router.navigate(`/admin/contractsform/`);
    };

    renderTableActionButtons = (data) => {
        return (
            <div className="actions-center">
                <Button icon_sm fill onClick={() => this.handleTableActionButtonAction(data.id, 'edit')}>
                    <img src={Edit} alt="edit button" />
                </Button>
                <Button icon_sm_delete fill onClick={() => this.handleTableActionButtonAction(data.id, 'delete')}>
                    <img src={Delete} alt="delete button" />
                </Button>
            </div>
        );
    };

    render() {
        const { userStore, t } = this.props;
        const { filters, appliedFilters, loading } = this.props.contractStore;
        const userType = userStore.currentUser.user_type;

        if (userType !== 'super-admin') {
            return <div>{t('Not Allowed')}</div>;
        }

        return (
            <GenericList
                tableHeader={this.props.t('Contracts')}
                columns={[
                    {
                        Header: 'Date',
                        id: 'for_date',
                        accessor: ({ for_date }) => (
                            <span>{for_date ? moment(for_date).format(serverTimeFormat()) : ''}</span>
                        ),
                        isSortable: true,
                        minWidth: 60,
                        maxWidth: 70
                    },
                    {
                        Header: 'Country',
                        id: 'country',
                        accessor: 'country',
                        isSortable: true,
                        minWidth: 50,
                        maxWidth: 70
                    },
                    {
                        Header: 'Document Name',
                        id: 'name',
                        accessor: ({ name }) => <span>{name}</span>,
                        isSortable: true,
                        minWidth: 150,
                        maxWidth: 180
                    },
                    {
                        Header: 'Contract Type',
                        id: 'contract_type_name',
                        accessor: ({ contract_type_name }) => <span>{contract_type_name}</span>,
                        isSortable: true,
                        minWidth: 80,
                        maxWidth: 120
                    },
                    {
                        Header: 'Module Name',
                        id: 'module',
                        accessor: 'module',
                        minWidth: 80,
                        maxWidth: 100
                    },

                    {
                        Header: 'Status',
                        id: 'status',
                        accessor: ({ status }) => <StatusBox status={status || ''} />,
                        isSortable: true,
                        minWidth: 50,
                        maxWidth: 80
                    },
                    {
                        Header: 'Total Used',
                        id: 'total_used',
                        accessor: 'total_used',
                        isSortable: true,
                        minWidth: 100,
                        maxWidth: 110,
                    },
                    {
                        Header: 'Action',
                        id: 'actions',
                        sortable: false,
                        accessor: (data) => this.renderTableActionButtons(data),
                        maxWidth: 60,
                        minWidth: 60
                    },
                ]}
                filters={appliedFilters}
                lastListLoadTime={this.props.contractStore.lastListLoadTime}
                header={
                    <GenericFilter
                        filters={filters}
                        setFilter={(name, value) => this.props.contractStore.setFilter(name, value)}
                        onFilter={() => {
                            this.props.contractStore.onFilter();
                        }}
                        loading={loading}
                        hasCountryFilter={true}
                    />
                }
                requestData={(params) => this.props.contractStore.getContracts(params)}
            >
                <Button fill wd onClick={this.contractForm}>
                    <i className="fa fa-plus-circle fa-xl margin-right-10" /> {t('Add New Contract')}
                </Button>
            </GenericList>
        );
    }
}
export default inject(
    'userStore',
    'timelogStore',
    'contractStore',
    'documentStore'
)(applicationRouter(withRemoveDialog(observer(ContractsNew))));
