import React, { Component } from 'react';
// import { Stepper, Step } from 'react-form-stepper';
import Stepper from 'react-stepper-horizontal';
import UploadDocs from './UploadDocs';
import { observer, inject } from 'mobx-react';
import withLocalization from '../../../hoc/withLocalization';
import PrepareDoc from './PrepareDoc';
import ReviewSend from './ReviewSend';
import PropertyPanel from './Panels/PropertyPanel';
import WidgetPanel from './Panels/WidgetPanel';
import LoadingSpinner from '~/elements/LoadingSpinner';
import './signdoc.css';
import RecipientsForm from './../Recipients/RecipientsForm';
import Button from '../../../components/CustomButton/CustomButton';

class DocProgress extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeStep: parseInt(sessionStorage.getItem("Step")) || 0,
            steps: [{ title: 'Upload Document' }, { title: 'Select Signers' }, { title: 'Prepare Document' }, { title: 'Review and Send' }],
            docInfo: []
        }

    }
    setBackSteps = (activeStep) => {
        const { recipientStore } = this.props;
        let step = activeStep;
        const signedRecipient = recipientStore.recipientsList ? recipientStore.recipientsList.filter((rec) => rec.signing_type !== "Send Copy") : '';
        if (step === 3) {
            if (signedRecipient.length) {
                this.setState({ activeStep: --step });
                return
            } else {
                this.setState({ activeStep: 1 });
                return
            }
        }
        this.setState({ activeStep: --step });
    }
    async setGoSteps(activeStep) {
        const { signatureStore, recipientStore, propertyStore, commonStore, t } = this.props;
        let step = activeStep;

        if (step === 1) {
            const saved = await this.Save();

            if (!saved) {
                return false
            }
            await recipientStore.fetchAllRecipients(signatureStore.caseId);
            const signedRecipient = recipientStore.recipientsList.filter((rec) => rec.signing_type !== "Send Copy");
            if (signatureStore.documentPages) {
                if (signedRecipient.length) {
                    this.setState({ activeStep: ++step });
                } else {
                    this.setState({ activeStep: 3 });
                }

            } else {
                this.setState({ activeStep: 0 });
            }
        } else if (step === 0) {
            if (signatureStore.documentPages && signatureStore.documentPages.length) {
                this.setState({ activeStep: ++step });
            } else {
                this.uploadDocNotification();
                this.setState({ activeStep: 0 });
            }
        } else if (step === 2) {
            const signWidgets = propertyStore.widgets.filter((value, index) => value.name === "Signature")
                .map((value) => value.recipientId)
                .filter((value, index, self) => self.indexOf(value) === index)

            const signedRecipient = recipientStore.recipientsList.filter((rec) => rec.signing_type !== "Send Copy");
            if (signWidgets.length === signedRecipient.length) {
                const data = { caseId: signatureStore.caseId, widgets: propertyStore.widgets };
                await propertyStore.saveWidget(data);
                this.setState({ activeStep: ++step });
            } else {
                commonStore.addNotification(t('Please add signature widget for all recipient'), null, 'error');
            }
        } else if (step === 3) {
            const data = { caseId: signatureStore.caseId, widgets: propertyStore.widgets };
            await propertyStore.saveWidget(data);
            this.setState({ activeStep: ++step });
        }
        else {
            this.setState({ activeStep: ++step });
        }


    }
    uploadDocNotification() {
        const { commonStore, t } = this.props;
        commonStore.addNotification(t('Please upload document first'), null, 'error');
    }
    async Save() {
        const { recipientStore, signatureStore, commonStore, t } = this.props;
        const { draftRecipientList, isSetSigningOrder } = recipientStore;
        if (!draftRecipientList) {
            commonStore.addNotification(t('Please add at least one recipient'), null, 'error');
            return false;
        }
        let error = false;

        draftRecipientList.forEach(value => {
            if (value.recipientName === "" || value.email === "" || value.select_value === "") {
                error = true;
                commonStore.addNotification(t('Please add require fields'), null, 'error');
                return false;
            }
            if (value.phoneNumber && !this.phoneNumberValidation(value.phoneNumber)) {
                error = true;
                commonStore.addNotification(t('Please add valid Phone Number'), null, 'error');
                return false;
            }
            if (value.email && !this.emailValidation(value.email)) {
                error = true;
                commonStore.addNotification(t('Please add valid email'), null, 'error');
                return false;
            }
            if (draftRecipientList.length >= 2) {
                for (let i = 0; i < draftRecipientList.length; i++) {
                    for (let j = i + 1; j < draftRecipientList.length; j++) {
                        if (draftRecipientList[i].email == draftRecipientList[j].email) {
                            error = true;
                            commonStore.addNotification(t('A user with this email address already exists'), null, 'error');
                            return false;
                        }
                    }
                }
            }
        })

        if (!error) {
            let payload = {
                caseId: signatureStore.caseId,
                set_signing_order: isSetSigningOrder,
                recipients: draftRecipientList
            }
            await recipientStore.save(payload);
            return true;

        }
    }

    emailValidation(emailValue) {
        const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (regex.test(emailValue) === false) {
            return false;
        }
        return true;
    }

    phoneNumberValidation(phoneNumber) {
        const phoneValidate = /^(\+|\d)[0-9]{6,14}$/;
        if (phoneValidate.test(phoneNumber) === false) {
            return false;
        }
        return true;
    }


    getStepperComponent(x) {
        const { signatureStore } = this.props;
        switch (x) {
            case 0:
                signatureStore.closeFullScreenMode();
                return (<UploadDocs></UploadDocs>);
            case 1:
                signatureStore.closeFullScreenMode();
                return (<RecipientsForm></RecipientsForm>);
            case 2:
                signatureStore.openFullScreenMode();
                return (<PrepareDoc />);
            case 3:
                signatureStore.openFullScreenMode();
                return (<ReviewSend></ReviewSend>);
            default:
                return (<div>Default</div>);
        }
    }

    componentDidMount() {
        const { signatureStore } = this.props;
        const caseId = signatureStore.caseId;
        if (!caseId) {
            signatureStore.createCase({ name: 'tid' })
        } else {
            signatureStore.fetchAll(signatureStore.caseId, "Prepare Document")
        }
    }

    componentWillUnmount() {
        sessionStorage.removeItem('CaseId');
        sessionStorage.removeItem('Step');
        sessionStorage.removeItem('Recipients');
        const { signatureStore, recipientStore, propertyStore } = this.props;
        recipientStore.resetRecipient();
        signatureStore.resetDocList();
        propertyStore.resetProperties();
        signatureStore.closeFullScreenMode();
    }

    validateSteps(step) {
        const { signatureStore, recipientStore, t } = this.props;
        const { documentPages } = signatureStore;
        const { recipientsList, draftRecipientList } = recipientStore;

        if (step === 0) {
            return 0;
        } else if (step === 1) {
            if (documentPages) {
                return 1;
            } else {
                return 4;
            }

        } else if (step === 2) {
            if (documentPages) {
                if (draftRecipientList || recipientsList) {
                    const signedRecipient = recipientStore.recipientsList.filter((rec) => rec.signing_type !== "Send Copy");
                    if (signedRecipient.length) {
                        return 2;
                    } else {
                        return 3;
                    }
                } else {
                    return 1;
                }
            } else {
                return 4;
            }
        } else if (step === 3) {
            if (documentPages) {
                if (recipientsList) {
                    return 3;
                } else {
                    return 1;
                }
            } else {
                return 4;
            }
        } else {
            return 0
        }
    }

    static getDerivedStateFromProps(props, state) {
        // let steps = [props.t('Upload Document'),props.t('Select Signers'),props.t('Prepare Document'),props.t('Review and Send')];
        let getTranslatedSteps = [{ title: props.t('Upload Document') }, { title: props.t('Select Signers') }, { title: props.t('Prepare Document') }, { title: props.t('Review and Send') }]
        state.steps = getTranslatedSteps;
        return state.steps;
    }

    render() {
        const { signatureStore, t } = this.props;
        const { isFullScreen, isDocFetching, isDocUploading } = signatureStore;
        let activeStep = this.validateSteps(this.state.activeStep);
        if (isDocFetching) {
            if (activeStep === 4 && activeStep === 3) {
                signatureStore.openFullScreenMode();
            }
            return (<LoadingSpinner />);
        }
        if (activeStep === 4 && activeStep === 3) {
            activeStep = 0;
        }
        sessionStorage.setItem("Step", activeStep)
        return (

            <div className="sign-panel">
                {isFullScreen &&
                    this.state.steps[activeStep].title === this.state.steps[this.state.steps.length - 2].title &&
                    <WidgetPanel></WidgetPanel>
                }
                {isFullScreen &&
                    this.state.steps[activeStep].title === this.state.steps[this.state.steps.length - 1].title &&
                    <div className="review-panel"></div>
                }
                <div className={`progresContainer ${activeStep === 2 ? 'prepareDocCon' : ''}`}>
                    <div className="stepContainer stepper_progress">
                        <Stepper steps={this.state.steps} activeStep={activeStep} className='StepClass' />
                    </div>
                    <div className="stepData">
                        <div className={`stepNav ${activeStep === 2 ? 'prepareDocNav' : ''} `}>
                            <div className="btnbck">
                                {activeStep > 0 &&
                                    <Button wd onClick={() => { this.setBackSteps(activeStep) }}>
                                        {t('Back')}
                                    </Button>}
                            </div>
                            <div className="stepLbl">
                                {this.state.steps[activeStep].title}
                            </div>
                            <div className=" btnNxt">
                                {(activeStep !== this.state.steps.length - 1) &&
                                    <Button fill wd disabled={isDocUploading} onClick={() => { this.setGoSteps(activeStep) }}>
                                        {t('Next')}
                                    </Button>
                                }
                            </div>
                        </div>
                        <div className={`stepContent ${activeStep === 2 ? 'prepareDoc' : ''}`}>
                            {this.getStepperComponent(activeStep)}
                        </div>
                    </div>
                </div>
                {isFullScreen &&
                    this.state.steps[activeStep] === this.state.steps[this.state.steps.length - 2] &&
                    <PropertyPanel></PropertyPanel>
                }
                {isFullScreen &&
                    this.state.steps[activeStep] === this.state.steps[this.state.steps.length - 1] &&
                    <div className="review-panel"></div>
                }
            </div>
        )
    }
}

export default inject('commonStore', 'signatureStore', 'recipientStore', 'propertyStore')(withLocalization(observer(DocProgress)));