
import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import 'swiper/swiper.min.css';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import 'swiper/scss/scrollbar';
import Header from "../../../newComponents/Header";
import Footer from "../../../newComponents/Footer";
import MainHeading from "../../../newComponents/MainHeading";
import Feature from "../../../newComponents/Feature";
import PReason from "../../../newComponents/PReason";
import Testimonial from "../../../newComponents/Testimonial";
import Contact from "../../../newComponents/Contact";

import MainBanner from "../../../assets/images/mainBanner.png";
import InnovationLogo from "../../../assets/images/innovationLogo.png";
import RatingImg1 from "../../../assets/images/rateImg1.png";
import RatingImg2 from "../../../assets/images/rateImg2.png";
import RatingImg3 from "../../../assets/images/rateImg3.png";
import RatingImg4 from "../../../assets/images/rateImg4.png";
import RatingImg5 from "../../../assets/images/rateImg5.png";
import TimeIcon from "../../../assets/images/timeIcon.svg";
import ScheduleIcon from "../../../assets/images/scheduleIcon.svg";
import MessageIcon from "../../../assets/images/messageIcon.svg";
import PReasonIcon1 from "../../../assets/images/pReasonIcon1.png";
import PReasonIcon2 from "../../../assets/images/pReasonIcon2.png";
import pReasonIconImg from "../../../assets/images/pReasonIconImg.png";
import AppleIcon from "../../../assets/images/appleIcon.svg";
import GoogleIcon from "../../../assets/images/googleIcon.svg";
import ScheduleImageEng from "../../../assets/images/scheduleImageEng.png";


//i18n
import { withTranslation } from "react-i18next"
import { inject, observer } from "mobx-react";
import config from "../../../library/config";

const Home = inject('authStore', 'commonStore')(observer((props) => {
  return (
    <>
      <div className="landingPageWrapp frameworkApp">
        <Header home="current" />
        <article className="homeBnrBlock grdntBg  py-80 pb-0">
          <div className="homeBnrTop py-40">
            <Container>
              <Row className="g-4 align-items-center">
                <Col lg={6} xl={7}>
                  {/* <div className="contentInfo">
                    <h5 className="mb-0">{props.t("Welcome to time and control")}</h5>
                  </div> */}
                  <MainHeading
                    title={props.t("Time & Control - Your Solution for HR & Workforce Management, Now and in the Future.")}
                    customClass="medium noPara m-0"
                  />
                  <div className="pe-xxl-5">
                    <div className="text-left pe-xxl-4">
                      <p>{props.t("Simplify timesheet tracking, optimize daily operations, and improve communication for all employees, including administrative and non-desk staff, with our intuitive app that offers seamless adoption.")}</p>
                    </div>
                  </div>
                  <div className="appBtns mt-4 pt-2">
                    <Link onClick={e => config.redirectToAppStore(e, "apple")} className="btnCommon btnBig w-auto me-3 mb-2"><i className="d-inline-block pe-2"><img src={AppleIcon} alt="Apple" /></i> {props.t("Apple")}</Link>
                    <Link onClick={e => config.redirectToAppStore(e, "google")} className="btnCommon btnTrans btnBig btnBlackTrans w-auto"><i className="d-inline-block pe-2"><img src={GoogleIcon} alt="Apple" /></i> {props.t("Google")}</Link>
                  </div>
                  {/* <div className="fdbckUsers">
                    <span>
                      <i><img src={User1} alt="User 1" /></i>
                      <i><img src={User2} alt="User 2" /></i>
                      <i><img src={User3} alt="User 3" /></i>
                      <i><img src={User1} alt="User 4" /></i>
                    </span>
                    <div className="fdbckInfo">
                      <h4>30k</h4>
                      <p>{props.t("Feedback")}</p>
                    </div>
                  </div> */}
                </Col>
              </Row>
            </Container>
          </div>
          <div className="homeBnrImg">
            <img src={MainBanner} alt="Increase Productivity" />
          </div>
        </article>
        <article className="homeBnrLogoBlock">
          <Container>
            <div className="homeBnrBtm">
              <Row className="g-4 align-items-center justify-space-between">
                <Col md={3}>
                  <div className="homeBnrLft">
                    <img src={InnovationLogo} alt="Innovation Norway" />
                  </div>
                </Col>
                <Col md={9}>
                  <div className="d-md-flex gap-4">
                    <div className="homeBnrRght">
                      <img src={RatingImg1} alt="Rating" />
                    </div>
                    <div className="homeBnrRght">
                      <img src={RatingImg2} alt="Rating" />
                    </div>
                    <div className="homeBnrRght">
                      <img src={RatingImg3} alt="Rating" />
                    </div>
                    <div className="homeBnrRght">
                      <img src={RatingImg4} alt="Rating" />
                    </div>
                    <div className="homeBnrRght">
                      <img src={RatingImg5} alt="Rating" />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </article>
        <article className="homeFeaBlock py-80">
          <Container>
            {/* <div className="contentInfo text-center">
              <h5 className="mb-1">{props.t("User-Friendly Features Of")}</h5>
            </div> */}
            <MainHeading
              title={<>{props.t("Take control of your business's success")}<br /><span>{props.t("with the Time & Control app")}</span></>}
              customClass="text-center medium noPara"
            />
            <div className="feauSlider crslCntrl">
              <Row className="justify-content-center">
                <Col md={6} xl={4} className="mt-2 mt-md-4">
                  <Feature
                    src={TimeIcon}
                    title={props.t("Time & Attendance")}
                    description={props.t("Keep track of employee hours and attendance with ease")}
                    link="/time-register"
                    color="blue"
                    border="grey"
                    customClass="heightEqual"
                  />
                </Col>
                <Col md={6} xl={4} className="mt-2 mt-md-4">
                  <Feature
                    src={ScheduleIcon}
                    title={props.t("Scheduling Plan")}
                    description={props.t("Optimize your workforce planning and scheduling process")}
                    link="/schedule-plan"
                    color="orange"
                    border="grey"
                    customClass="heightEqual"
                  />
                </Col>
                <Col md={6} xl={4} className="mt-2 mt-md-4">
                  <Feature
                    src={ScheduleIcon}
                    title={props.t("Digital Signature")}
                    description={props.t("Sign and manage documents securely and hassle-free")}
                    link="/digital-signature"
                    color="green"
                    border="grey"
                    customClass="heightEqual"
                  />
                </Col>
                <Col md={6} xl={4} className="mt-2 mt-md-4">
                  <Feature
                    src={ScheduleIcon}
                    title={props.t("Employee Handbook")}
                    description={props.t("Provide your employees with a comprehensive guide for success")}
                    link="/employee-guide"
                    color="pink"
                    border="grey"
                    customClass="heightEqual"
                  />
                </Col>
                <Col md={6} xl={4} className="mt-2 mt-md-4">
                  <Feature
                    src={MessageIcon}
                    title={props.t("Checklists")}
                    description={props.t("Streamline your workflow and ensure accountability with digital checklists")}
                    link="/checklist"
                    color="blue"
                    border="grey"
                    customClass="heightEqual"
                  />
                </Col>
                <Col md={6} xl={4} className="mt-2 mt-md-4">
                  <Feature
                    src={ScheduleIcon}
                    title={props.t("Communication")}
                    description={props.t("Talk less and do more with our powerful communication platform")}
                    link="/communication"
                    color="orange"
                    border="grey"
                    customClass="heightEqual"
                  />
                </Col>
                <Col md={6} xl={4} className="mt-2 mt-md-4">
                  <Feature
                    src={ScheduleIcon}
                    title={props.t("Documents")}
                    description={props.t("Keep your important files safe, organized, and accessible from anywhere")}
                    link="/documents"
                    color="green"
                    border="grey"
                    customClass="heightEqual"
                  />
                </Col>
                <Col md={6} xl={4} className="mt-2 mt-md-4">
                  <Feature
                    src={ScheduleIcon}
                    title={props.t("Deviation Control")}
                    description={props.t("Maintain productivity and quality control by identifying and addressing deviations")}
                    link="/deviation"
                    color="pink"
                    border="grey"
                    customClass="heightEqual"
                  />
                </Col>
                <Col md={6} xl={4} className="mt-2 mt-md-4">
                  <Feature
                    src={ScheduleIcon}
                    title={props.t("Vacation & Sick-leave")}
                    description={props.t("Effortlessly manage employee time off requests and maintain productivity")}
                    link="/vacation-and-absence"
                    color="blue"
                    border="grey"
                    customClass="heightEqual"
                  />
                </Col>
                <Col md={6} xl={4} className="mt-2 mt-md-4">
                  <Feature
                    src={TimeIcon}
                    title={props.t("Reporting Features")}
                    description={props.t("Gain valuable insights and make informed decisions with comprehensive reporting tools")}
                    link="/reports"
                    color="orange"
                    border="grey"
                    customClass="heightEqual"
                  />
                </Col>
              </Row>
            </div>
          </Container>
        </article>
        <article className="homeBnrBlock py-80">
          <div className="homeBnrTop py-40">
            <Container>
              <Row className="g-4 align-items-center">
                <Col lg={7} xl={6}>
                  <MainHeading
                    title={<>{props.t("Take control of your business's success")} <span>{props.t("with the Time & Control app")}.</span></>}
                    customClass="small noPara m-0"
                  />
                  <div className="contentInfoWithQuotes">
                    <blockquote>
                      <h4 className="m-0">{props.t("Centralize your schedule, team management, and task management, minimize stress, and boost productivity with our intuitive software designed to simplify daily operations")}</h4>
                    </blockquote>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="homeScheduleImg">
            <img src={ScheduleImageEng} alt="ScheduleImage" />
          </div>
        </article>
        <article className="homeReasonsBlock  py-80">
          <Container>
            <MainHeading
              title={<>{props.t("Five Primary Reasons")} <span>{props.t("to Choose Time & Control")}</span></>}
              customClass="text-center medium noPara"
            />
            {/* <Row className="g-4">
              <Col lg={6} xxl={7}>
                <MainHeading
                  title={<>{props.t("Five Primary Reasons")} <span>{props.t("to Choose Time & Control")}</span></>}
                  customClass="medium noPara m-0"
                />
              </Col>
              <Col lg={6} xxl={5}>
                <div className="text-left">
                  <p>{props.t("Empower Your Business Management Time and Control empowers you to take control of all daily operations and drive your business forward. Our user-friendly software provides:")}</p>
                  <div className="list pull-right">
                    <ul>
                      <li>
                        <p>A Consolidated Platform for Efficient Employee Management</p>
                      </li>
                      <li>
                        <p>A Customizable Interface for Optimized Operations</p>
                      </li>
                      <li>
                        <p>Enhanced Security and Management in the Workplace.</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
            </Row> */}
            <Row className="mt-1">
              <Col md={6} className="mt-4">
                <PReason
                  src={PReasonIcon1}
                  link="javascript:void(0);"
                  title={props.t("Manages Everything Under One Roof")}
                  description={props.t("Simplify your business operations with Time & Control. This comprehensive software consolidates all commercial operations, including scheduling, time tracking, timesheet preparation, and task management, into one easy-to-use platform. Time and Control increases productivity, provides accurate tracking and reporting, and improves communication for seamless, efficient business management.")}
                  color="yellow"
                />
              </Col>
              <Col md={6} className="mt-4">
                <PReason
                  src={PReasonIcon2}
                  link="javascript:void(0);"
                  title={props.t("Effortless Team Communication")}
                  description={props.t("Stay connected and informed with Time & Control's communication app. Whether your staff is in the office or out in the field, you can easily provide alerts, updates, and reminders to keep everyone on the same page. Enjoy rapid and unfiltered communication within your organization with ease.")}
                  color="blue"
                />
              </Col>
              <Col md={6} className="mt-4">
                <PReason
                  src={PReasonIcon2}
                  link="javascript:void(0);"
                  title={props.t("Sign Remotely with Confidence")}
                  description={props.t("Eliminate the hassle of traditional document signing with Time & Control. Sign from anywhere, on any device, and keep track of announcements on the go. Our advanced technology ensures secure storage of your private data and includes an electronic certificate with every signed document.")}
                  color="green"
                />
              </Col>
              <Col md={6} className="mt-4">
                <PReason
                  src={PReasonIcon1}
                  link="javascript:void(0);"
                  title={props.t("Efficient Invoicing and Payroll with Time & Control")}
                  description={props.t("Optimize your invoicing and payroll processes with our fast and accurate time tracking solution. Keep your team informed, comply with labor laws, and reduce administrative tasks with ease. Calculate time off and breaks fairly for your employees and stay on top of all your business operations.")}
                  color="orange"
                />
              </Col>
              <Col md={6} className="mt-4">
                <PReason
                  src={PReasonIcon2}
                  link="javascript:void(0);"
                  title={props.t("Productivity at its Best - Optimize Your Workflow Today!")}
                  description={props.t("With its user-friendly interface and time-saving features like auto-login and a comprehensive schedule plan, Time & Control makes managing your payroll and invoicing a breeze. And, with compatibility with top payroll and invoicing software, you can easily send and receive data for even smoother operation.")}
                  color="pink"
                />
              </Col>
              <Col md={6} className="mt-4">
                <div className="prsnImg">
                  <img src={pReasonIconImg} alt="5 Primary Reasons to Choose the Time & Control" />
                </div>
              </Col>
            </Row>
          </Container>
        </article>
        {/* <article className="homeAppBlock py-80">
          <div className="py-60">
            <Container>
              <Row className="g-4 align-items-center">
                <Col lg={6} xl={6} xxl={7}>
                  <MainHeading
                    title={<>{props.t("Optimize Your Business with")} <span>{props.t("Our Tech-Integrated Solution")}</span></>}
                    description={props.t("Whether you own a small or a big-scale business, our application helps to manage everything. It gives you sufficient time to get ahead of hassles and create a path to success. You get everything in one place, from employee management to tracking of workplaces.")}
                    customClass="medium mb-4"
                  />
                  <div className="contentInfo mb-3">
                    <p>{props.t("Key Benefits:")}</p>
                  </div>
                  <div className="list">
                    <ul>
                      <li>{props.t("Optimized Personnel Management")}</li>
                      <li>{props.t("Secure Data Storage")}</li>
                      <li>{props.t("Efficient Time Tracking")}</li>
                      <li>{props.t("Simple Employee Management")}</li>
                      <li>{props.t("Boosted Productivity")}</li>
                      <li>{props.t("Maximize Profits")}</li>
                    </ul>
                  </div>
                  <div className="appBtns mt-4 pt-2">
                    <Link to="/" className="btnCommon btnBig w-auto"><i className="d-inline-block pe-2"><img src={AppleIcon} alt="Apple" /></i> {props.t("Apple")}</Link>
                    <Link to="/" className="btnCommon btnTrans btnBig btnBlackTrans ms-3 w-auto"><i className="d-inline-block pe-2"><img src={GoogleIcon} alt="Apple" /></i> {props.t("Google")}</Link>
                  </div>
                </Col>
                <Col lg={6} xl={6} xxl={5}>
                  <div className="text-left">
                  </div>
                </Col>
              </Row>
            </Container>
            <div className="appimg">
              <img src={AppImg} alt="app img" />
            </div>
          </div>
        </article> */}
        <Testimonial />
        <Contact />
        <Footer />
      </div>
    </>
  )
}))
export default withTranslation()(Home)