import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

//components
import applicationRouter from '~/hoc/applicationRouter'
import withLocalization from '~/hoc/withLocalization';
import TableWidget from '../TableWidget.js';

class VacationTypes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            vacation_types: [],
        }
        this.loadData = this.loadData.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = () => {
        const { currentEntity } = this.props.clientStore;
        if (currentEntity.client.data && currentEntity.client.data.extendedRules) {
            const extendedRules = Object.assign({}, currentEntity.client.data.extendedRules);
            this.setState({
                vacation_types: extendedRules.vacation_types
            })
        }
    }

    render() {
        const { vacation_types } = this.state;
        return (
            <div>
                <TableWidget
                    headers={['code', 'Label']}
                    metaData={[{ field: 'code', type: 'text', placeholder: 'code', required: true, unique: true },
                    { field: 'name', type: 'text', placeholder: 'Label', required: true, unique: true }]}
                    data={vacation_types}
                    onChange={data => this.props.handleUserDataChange('vacation_types', data)}
                />
            </div >
        );
    }
}
export default inject('clientStore')(applicationRouter(withLocalization(observer(VacationTypes))));
