import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

//components
import applicationRouter from '~/hoc/applicationRouter';
import GenericList from '~/components/GenericList/GenericList';
import withLocalization from '~/hoc/withLocalization';

//services
import config from '../../../library/config';
import agent from '../../../library/agent';

//elements
import Button from '~/components/CustomButton/CustomButton.jsx';

//utils
import { shortDateFormat, dateToTimeFormat } from '~/utils/dateFormat';

//assets
import LeftArrow from './../../../assets/img/left-arrow.svg';

//styles
import './signdoc.css';

class SignDocHistory extends Component {
    state = {
        caseId: null,
        caseName: null,
        caseDate: null,
        buttonDisabled: false,
    };

    componentDidMount() {
        const { router } = this.props;
        if (router && router.location && router.location.state && router.location.state.caseId)
            this.setState({ caseId: router.location.state.caseId });
        if (router && router.location && router.location.state && router.location.state.caseName)
            this.setState({ caseName: router.location.state.caseName });
        if (router && router.location && router.location.state && router.location.state.caseDate)
            this.setState({ caseDate: router.location.state.caseDate });
    }
    backToList = () => {
        this.props.router.navigate(
            this.props.router.location.state.parent === 'UserContract' ? '/admin/members' : '/admin/cases'
        );
    };

    downloadCertificate = async () => {
        const { caseId } = this.state;
        let hydrated = {
            id: encodeURIComponent(caseId),
            authorization: this.props.commonStore.token,
        };
        window.open(
            `${config.API_ROOT}/sign_doc_history/${encodeURIComponent(caseId)}${agent.convertToGetParams(hydrated)}`
        );
        this.setState({ buttonDisabled: false });
    };

    setUserLocation(location_info) {
        let locationData = '';
        if (location_info.city) locationData = locationData.concat(location_info.city);
        if (location_info.regionName) locationData = locationData.concat(', ', location_info.regionName);
        if (location_info.country) locationData = locationData.concat(', ', location_info.country);
        if (location_info.zip) locationData = locationData.concat(', ', location_info.zip);

        return (
            <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">{locationData}</Tooltip>}>
                <span>{locationData}</span>
            </OverlayTrigger>
        );
    }

    render() {
        const { caseId, caseName, caseDate, buttonDisabled } = this.state;
        const { t, commonStore } = this.props;
        const dateTimeRules =
            commonStore.config.client && commonStore.config.client.data && commonStore.config.client.data.dateTimeRules
                ? commonStore.config.client.data.dateTimeRules
                : false;
        if (!caseId) {
            return <div>{t('No data found')}</div>;
        }
        return (
            <>
                <div className="historyDoc">
                    <div className="d-flex justify-content-space-between align-items-center">
                        <h4 className='m-0 p-1'>{t('Document History')}</h4>
                        <p style={{ fontSize: '1.1em', color: 'black'}} onClick={this.backToList}>
                            <img src={LeftArrow} alt="left arrow" style={{ cursor: 'pointer' }} /> &nbsp;
                            <span className="hover-underline">{t('Back to Document Listing')}</span>
                        </p>
                    </div>
                    <div className='row'>
                        <div className='col-6'>
                            <p>{t('Document Name')}</p>
                            <h6>{caseName}</h6>
                        </div>
                        <div className='col-6 text-end'>
                            <p>{t('Sent Date')}</p>
                            <h6>{caseDate && shortDateFormat(caseDate, dateTimeRules)}</h6>
                        </div>
                    </div>
                    
                    {caseId != null && (
                        <GenericList
                            columns={[
                                {
                                    Header: 'Date & Time',
                                    id: 'created_at',
                                    accessor: ({ created_at }) => (
                                        <span>{`${shortDateFormat(created_at, dateTimeRules)} ${dateToTimeFormat(created_at, dateTimeRules)}`}</span>
                                    ),
                                },
                                {
                                    Header: 'User',
                                    id: 'user_id',
                                    accessor: ({ username, recipient_name, user_id }) => (
                                        <span>{user_id === '0' || user_id === 0 ? recipient_name : username}</span>
                                    ),
                                },
                                {
                                    Header: 'Device',
                                    id: 'device_info.os.name',
                                    accessor: 'device_info.os.name',
                                },
                                {
                                    Header: 'Location',
                                    id: 'location_info',
                                    accessor: ({ location_info }) => {
                                        if (location_info && location_info.status === 'success') {
                                            return this.setUserLocation(location_info);
                                        } else {
                                            return t("Couldn't detect location");
                                        }
                                    },
                                },
                                {
                                    Header: 'Action',
                                    id: 'action',
                                    accessor: 'action',
                                },
                            ]}
                            lastListLoadTime={this.props.signatureStore.lastListLoadTime}
                            requestData={(params) => this.props.signatureStore.getSignDocHistory({...params,caseId: encodeURIComponent(caseId)})}
                        />
                    )}
                    <Button
                        fill
                        wd
                        onClick={() => this.setState({ buttonDisabled: true }, () => this.downloadCertificate())}
                        disabled={buttonDisabled}
                    >
                        {t('Download Certificate')}
                    </Button>
                </div>
            </>
        );
    }
}
export default inject('signatureStore', 'commonStore')(applicationRouter(withLocalization(observer(SignDocHistory))));
