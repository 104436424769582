import React from 'react';
import { find } from 'lodash';

import Select from '../../elements/Select';
import { minutesToTimeInput } from '../../utils/timeCalc';

const TimeSelect = ({ min = 0, max, step = 15, value, onChange, ...rest }) => {
    min = +min;
    max = +max;
    const options = [];

    while (min < max) {
        options.push({
            label: minutesToTimeInput(min, true),
            value: min,
        });
        min += step;
    }
    options.push({
        label: minutesToTimeInput(max, true),
        value: max,
    });

    const currentSelect = find(options, (o) => {
        return o.value == value;
    });

    return (
        <Select
            classNamePrefix="time-select"
            className="time-select"
            options={options}
            currentSelect={currentSelect}
            onChange={onChange}
            {...rest}
        />
    );
};
export default TimeSelect;
