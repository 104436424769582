import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';

//components
import applicationRouter from '~/hoc/applicationRouter';
import withLocalization from '~/hoc/withLocalization';
import withRemoveDialog from '~/hoc/withRemoveDialog';

//elements
import Button from '../../components/CustomButton/CustomButton.jsx';

//assests
import newlogo from '../../assets/img/new-logo.svg';

//library
import _config from '../../library/config.js'

//styles
import './AdminNavbar.css';
import GenericDropdown from '../GenericLanguageDropdown/GenericDropdown.js';

class CustomerNavbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
            checked: false,
            radioValue: '1',
            optionValue: '1',
            width: window.innerWidth,
            sidebarshow: false,
            show_message_dropdown: false,
            show_notification_dropdown: false,
            show_multi_account_drop_down: false,
            hasMultiAccountAdminAccess: window.sessionStorage.getItem('multiAdminJwt'),
            showMultiAccountAmdinModal: false,
            mode: null,
            currentMultiAccount: null
        };
    }

    render() {
        const { handleLogOut, t, authStore } = this.props;
        return (
            <>
                <div className=" d-flex custom-navbar-wrap customerMain bg-body">
                    <div className='d-flex'>
                        <div className='d-flex align-items-center'>
                            <img className={'ms-md-4 ms-0'} src={newlogo} width={70} height={70} alt="timeandcontrol-logo" id="logoimg" />
                            <div className='custom-customer-separator'></div>
                            {authStore?.custConfig?.clientDetails?.image ? <img className={'ms-2'} src={`${_config.UPLOADS_API_ENDPOINT}/${authStore?.custConfig?.clientDetails?.image}`} width={75} height={50} alt="provider-logo" id="logoimg" />
                                : <div className='conversation-room-initials' title={authStore?.custConfig?.clientDetails?.name}>{authStore?.custConfig?.clientDetails?.name.split('')[0]}</div>}
                        </div>
                    </div>
                    <div className='d-flex w-100 justify-content-end pe-4 mt-md-3' style={{ height: 40 }}>
                        <GenericDropdown />
                        <Button>
                            <Link
                                to="/"
                                className="user-profile-item d-flex align-items-center"
                                onClick={handleLogOut}
                            >
                                <i
                                    className="fas fa-sign-out-alt"
                                    aria-hidden="true"
                                ></i>
                                {window.screen.width > 400 && <div className='ms-2'>{t('Log out')}</div>}
                            </Link>
                        </Button>
                    </div>
                </div>
            </>
        );
    }
}

export default inject('commonStore', 'userStore', 'authStore')(applicationRouter(withLocalization(withRemoveDialog((observer(CustomerNavbar))))));
