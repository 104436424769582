import React, { Component, Fragment } from 'react';
import TimeField from 'react-simple-timefield';

import './InputTime.css';
import { getMinutesFromTimeInput, minutesToTimeInput } from '../../../utils/timeCalc';

function InputTime(props) {
    const { value, onChange, className, disabled, options, schema } = props;
    const __disabled = schema?.readOnly !== undefined ? !!schema.readOnly : !!disabled;
    const valueInInt = options?.valueInInt || false;
    return (
        <Fragment>
            <TimeField
                style={{ width: 115 }}
                value={valueInInt ? minutesToTimeInput(value) : value} // {String}   required, format '00:00' or '00:00:00'
                onChange={e => {
                    onChange(valueInInt ? getMinutesFromTimeInput(e.target.value) : e.target.value);
                }}
                colon=":" // {String}   default: ":"
                disabled={__disabled}
                className={`form-control ${className || ''}`}
            />
        </Fragment>
    );
}

export default InputTime;
