import React, { Component } from 'react';
import applicationRouter from '~/hoc/applicationRouter'
import { inject, observer } from 'mobx-react';
import configs from '~/library/config';
import { serverValidation } from '~/library/core';
import agent from '~/library/agent';

//styles
import '../../../styles/css/styles.css';

//components
// import { Button } from 'react-bootstrap';
import Button from '../../../components/CustomButton/CustomButton.jsx';
import { Input } from '../../../elements/Input';
import withLocalization from '~/hoc/withLocalization';

//this component is used for adding new visitor details//

class VisitorAdd extends Component {
    state = {
        is_registered: false,
        is_admin: false,
        error: '',
    }

    loadData = async () => {
        //calls action based on props to initiate currentVisitor values//
        const { visitorStore } = this.props;
        const { currentVisitor } = this.props.visitorStore
        const { commonStore } = this.props
        let country_code = commonStore.config.client && commonStore.config.client.data && 
                           commonStore.config.client.data.dateTimeRules &&
                           commonStore.config.client.data.dateTimeRules.country_code ? 
                           commonStore.config.client.data.dateTimeRules.country_code : '+47';
        visitorStore.loadDefaultVisitor();
        if(currentVisitor.id === 0){
            this.handleTextChange('phone',country_code);
        }
    }


    componentDidMount() {
        const { currentUser } = this.props.userStore;
        const user_type = currentUser && currentUser.user_type ? currentUser.user_type : false;
        this.loadData();
        if (user_type === "admin" || user_type === "pm") {
            this.setState({ is_admin: true })
        }
    }

    handleTextChange = (name, value) => {
        //calls action to handle text changes//
        this.props.visitorStore.handleVisitorChange(name, value);
    }

    handleButtonClick = () => {
        this.addVisitor();
    }

    handleQRDownload = () => {
        let hydratedPayload = {
            authorization: this.props.commonStore.token
        }
        window.open(`${configs.API_ROOT}/visitors/downloadqrCode/${agent.convertToGetParams(hydratedPayload)}`);
    }

    addVisitor = async () => {
        const { currentVisitor } = this.props.visitorStore;
        const { commonStore, visitorStore, t } = this.props;
        let visitor = {};

        visitor.full_name = currentVisitor.full_name;
        visitor.country_code = currentVisitor.country_code;
        visitor.phone = currentVisitor.phone;
        visitor.visitor_business_name = currentVisitor.visitor_business_name;

        if (!visitor.full_name || !visitor.full_name.trim()) {
            commonStore.addNotification(t('Please Enter Name'), null, 'error');
            this.setState({ error: 'Please Enter Name' })
            return;
        }

        if (!visitor.phone || !visitor.phone.trim()) {
            commonStore.addNotification(t('Please Enter Phone no'), null, 'error');
            this.setState({ error: 'Please Enter Phone no' });
            return;
        }

        if (!visitor.phone.match(serverValidation('phone'))) {
            commonStore.addNotification(t('Invalid phone number'), null, 'error');
            this.setState({ error: 'Invalid phone number' });
            return;
        }

        if (!this.state.is_admin) {
            const pathName = this.props.router.location.pathname;
            visitor.enc = pathName.replace('/visitorRegister/', '');            
        }

        if (!this.state.is_admin && visitor.enc === "/visitorRegister") {
           alert(t('Missing Client id'));
           return;
        }

        await visitorStore.saveVisitorData(visitor, this.state.is_admin).then((data) => {
            if (data.error) {
                alert(t(data.error));
                return;
            }
            if (data) {
                commonStore.addNotification(t('Visitor Added'), null, 'success');
                this.setState({ is_registered: true, error: '' });
                return true;
            }
        }).catch((error) => {
            commonStore.addNotification(error.message || t('Error'), null, 'error');
        })
    }

    goBack() {
        this.loadData();
        this.setState({ is_registered: false }, () => {
            this.props.router.navigate('/admin/guest');
        })
    }


    render() {
        //renders the visitor registration form//
        const { is_registered, is_admin, error } = this.state;
        const { t } = this.props;
        const { currentVisitor } = this.props.visitorStore;
        const { config } = this.props.commonStore;
        const qrcode_path = config && config.client && config.client.qrcode_path ? config.client.qrcode_path : false;
        return (
            <div className={!is_admin ? "row-flex visitor-client-register" : "row"}>
                {is_registered ?
                    <>
                        <div className='col-xs-2 col-sm-12 col-md-3 col-lg-3'>
                        </div>
                        <div className='col-xs-8 col-sm-12 col-md-6 col-lg-6'>
                            <div className='row-flex' style={{ justifyContent: 'center' }}>
                                <i className="fas fa-check-circle fa-5x" style={{ color: "#2769f6", marginTop: '50px' }}></i>
                            </div>
                            <div className='row-flex' style={{ justifyContent: 'center' }}>
                                <h2 className='visitor-registration-msg'>{t('Thank You')}</h2>
                            </div>
                            <div className='row-flex text-center' style={{ justifyContent: 'center' }}>
                                <span style={{ margin: '20px 0' }}>{t('Your registration was successfully done')}</span>
                            </div>
                            <div className='row-flex' style={{ justifyContent: 'center' }}>
                                <Button fill onClick={() => this.goBack()}>{t('Go Back')}</Button>
                            </div>
                        </div>
                        <div className='col-xs-2 col-sm-12 col-md-3 col-lg-3'>
                        </div>
                    </>
                    :
                    <div className='row'>
                        <div className='col-xs-12 col-sm-2 col-md-2 col-lg-2'></div>
                        <div className='col-xs-12 col-sm-8 col-md-8 col-lg-8'>
                            <div className='row-flex text-center' style={{ justifyContent: 'center' }}>
                                <h2 style={{ fontWeight: '600' }}>{t('Visitor Registration')}</h2>
                            </div>
                            <div className={is_admin ? 'row' : 'row-flex'} style={{ justifyContent: 'center', alignItems: 'center' }}>
                                {is_admin &&
                                    <>
                                        <div className='col-xs-12 col-sm-12 col-md-4 col-lg-4'>
                                            {qrcode_path && <img src={`${configs.SOCKET_ROOT}${qrcode_path}`} alt={"QRCode"}></img>}
                                            <div className='row-flex' style={{ justifyContent: 'center' }}>
                                                <Button fill onClick={() => this.handleQRDownload()}>{t('Download QR')}</Button>
                                            </div>
                                        </div>
                                        <div className='col-xs-12 col-sm-12 col-md-1 col-lg-1 text-center'>
                                            <h3>{t('OR')}</h3>
                                        </div>
                                    </>
                                }
                                <div className='col-xs-12 col-sm-12 col-md-7 col-lg-7'>
                                    <div className='row-flex'>
                                        <span className='visitor-registration-msg'>{t('VisitorRegistrationMsg')}</span>
                                    </div>
                                    <div className='row-flex' style={{ justifyContent: 'center' }}>
                                        <form className="custom-form" style={{ margin: '0 2rem' }}>
                                            <div className="custom-form__row">
                                                <Input
                                                    className="custom-form__col"
                                                    placeholder={t('Visitor Name')}
                                                    type="text"
                                                    name="full_name"
                                                    value={currentVisitor.full_name}
                                                    onChange={evt => {
                                                        this.handleTextChange('full_name', evt.target.value);
                                                    }}
                                                />
                                            </div>
                                            <div className="custom-form__row">
                                                <Input
                                                    className="custom-form__col"
                                                    placeholder={t('Phone no.')}
                                                    type="text"
                                                    name="phone"
                                                    value={currentVisitor.phone}
                                                    onChange={evt => {
                                                        this.handleTextChange('phone', evt.target.value);
                                                    }}
                                                />
                                            </div>
                                            <div className="custom-form__row">
                                                <Input
                                                    className="custom-form__col"
                                                    placeholder={t('Business Name')}
                                                    type="text"
                                                    name="visitor_business_name"
                                                    value={currentVisitor.visitor_business_name}
                                                    onChange={evt => {
                                                        this.handleTextChange('visitor_business_name', evt.target.value);
                                                    }}
                                                />
                                            </div>
                                            {error !== '' && <span className='text-danger text-center'>{t(error)}</span>}
                                            <Button fill onClick={() => this.handleButtonClick()} style={{ height: "5rem", margin: "5px 10px", fontSize: "18px!important" }}>
                                                {t('Register Now')}
                                            </Button>
                                        </form>
                                    </div>
                                    <div className='row-flex'>
                                        <span className='visitor-registration-tnc'>{t('VisitorRegistrationTNC')}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-xs-12 col-sm-2 col-md-2 col-lg-2'></div>
                    </div>
                }
            </div>
        )
    }
}
export default inject('visitorStore', 'userStore', 'commonStore')(withLocalization(applicationRouter(observer(VisitorAdd))));


