import React, { Component } from 'react';
import applicationRouter from '~/hoc/applicationRouter'
import { inject, observer } from 'mobx-react';
import { serverValidation } from '~/library/core';

//styles
import '../../../styles/css/styles.css';
import clientRegister from '../../../assets/img/client-register.png'
import email from '../../../assets/img/email.png'

//components
import Button from '../../../components/CustomButton/CustomButton.jsx';
import { Input, NumberInput } from '../../../elements/Input';
import withLocalization from '~/hoc/withLocalization';

//this component is used for adding new visitor details//

class VisitorClientRegister extends Component {
    state = {
        is_registered: false,
        code: '',
        error: '',
        clientVisitorId: 0
    }

    loadData = async () => {
        //calls action based on props to initiate currentVisitor values//
        const { visitorStore } = this.props;
        visitorStore.loadDefaultClientVisitor();
    }

    componentDidMount() {
        this.loadData();
    }

    handleTextChange = (name, value) => {
        //calls action to handle text changes//
        this.props.visitorStore.handleClinetVisitorChange(name, value);
    }

    handleButtonClick = (type) => {
        if (type === 'add')
            this.addVisitor();
        if (type === 'verify')
            this.verifyVisitorClient();
    }

    addVisitor = async () => {
        const { currentClientVisitor } = this.props.visitorStore;
        const { t } = this.props;
        let clientVisitor = {};

        clientVisitor.business_name = currentClientVisitor.business_name;
        clientVisitor.business_id = currentClientVisitor.business_id;
        clientVisitor.contact_person_name = currentClientVisitor.contact_person_name;
        clientVisitor.contact_person_email = currentClientVisitor.contact_person_email;
        clientVisitor.username = currentClientVisitor.username;
        clientVisitor.phone = currentClientVisitor.phone;

        if (!clientVisitor.business_name || !clientVisitor.business_name.trim()) {
            this.setState({ error: 'Please Enter Business Name' })
            return;
        }

        if (!clientVisitor.business_id || !clientVisitor.business_id.length) {
            this.setState({ error: 'Please Enter Business ID' })            
            return;
        }

        if (!clientVisitor.business_id.match(serverValidation('tax'))) {
            this.setState({ error: 'Business ID should contain 9 digits' })
            return;
        }

        if (!clientVisitor.contact_person_name || !clientVisitor.contact_person_name.trim()) {
            this.setState({ error: 'Please Enter Contact Person Name' })
            return;
        }

        if (!clientVisitor.username || !clientVisitor.username.trim()) {
            this.setState({ error: 'Please Enter Username' })
            return;
        }

        if (!clientVisitor.contact_person_email || !clientVisitor.contact_person_email.trim()) {
            this.setState({ error: 'Please Enter Contact Person Email' })
            return;
        }

        if (!clientVisitor.phone || !clientVisitor.phone.trim()) {
            this.setState({ error: 'Please Enter Phone no' })
            return;
        }

        if (!clientVisitor.phone.match(serverValidation('phone'))) {
            this.setState({ error: 'Invalid phone number' })
            return;
        }
        if (!clientVisitor.contact_person_email.match(serverValidation('email'))) {
            this.setState({ error: 'Invalid email' })
            return;
        }

        await this.props.visitorStore.saveClientVisitorData(clientVisitor).then((data) => {
            if (data.error) {
                alert(t(data.error));
                return;
            }
            if (data) {
                this.setState({ is_registered: true, error: '', clientVisitorId: data.id });
                return true;
            }
        }).catch((error) => {
            alert(error.message);
        })
    }

    verifyVisitorClient = async () => {
        const { history } = this.props;
        const { t } = this.props;
        const { code, clientVisitorId } = this.state;

        if (code.length !== 6) {
            this.setState({ error: 'Please enter 6 digit code to verify' });
            return;
        }

        const verificationData = {
            id: clientVisitorId,
            code: Number(code)
        }

        await this.props.visitorStore.verifyClientVisitorData(verificationData).then((data) => {
            if (data.error) {
                alert(t(data.error));
                return;
            }
            this.setState({ is_registered: false, error: '', clientVisitorId: 0 });
            alert(t('Registration successful. Credentials will be sent to your email ID.'));
            history.push('/');
            return true;
        }).catch((error) => {
            alert(error.message);
        })
    }


    render() {
        //renders the visitor registration form//
        const { is_registered, error } = this.state;
        const { t } = this.props;
        const { currentClientVisitor } = this.props.visitorStore;
        return (
            <div className="row-flex visitor-client-register">
                {is_registered ?
                    <>
                        <div className='col-sm-12 col-md-4 col-lg-4'>
                        </div>
                        <div className='col-sm-12 col-md-4 col-lg-4'>
                            <div className='row-flex' style={{ justifyContent: 'center', marginTop: '50px' }}>
                                <img src={email} alt='emailImage' />
                            </div>
                            <div className='row-flex' style={{ justifyContent: 'center' }}>
                                <h2 className='visitor-registration-msg'>{t('Thank You')}</h2>
                            </div>
                            <div className='row-flex text-center'>
                                <span style={{ margin: '20px 0' }}>{t('We have sent you the') + ' '}
                                    <strong>{t('6 digit confirmation code')}</strong>
                                    {' ' + t('to your Email ID, so please enter received code and verify')}
                                </span>
                            </div>
                            <div className='row-flex' style={{ justifyContent: 'center' }}>
                                <NumberInput
                                    placeholder={t('Enter Code')}
                                    name="code"
                                    value={this.state.code}
                                    onChange={evt => {
                                        this.setState({ code: evt.target.value });
                                    }}
                                />
                            </div>
                            <div className='row-flex' style={{ justifyContent: 'center', marginTop: '5px' }}>
                                {error !== "" && <span className='text-danger'>{t(error)}</span>}
                            </div>
                            <div className='row-flex' style={{ justifyContent: 'center', marginTop: '20px' }}>
                                <Button fill onClick={() => this.handleButtonClick('verify')}>{t('Verify Email')}</Button>
                            </div>
                        </div>
                        <div className='col-sm-12 col-md-4 col-lg-4'>
                        </div>
                    </>
                    :
                    <div className="row" style={{ paddingTop: '50px' }}>
                        <div className='col-sm-3 col-md-2'></div>
                        <div className='col-sm-6 col-md-8'>
                            <div className='row-flex' style={{ alignItems: 'center' }}>
                                <div className='col-sm-12 col-md-8'>
                                    <div className='row' style={{ paddingLeft: '23px' }}>
                                        <span className='visitor-registration-msg'>{t('Have your')}</span>
                                        <h3 style={{ fontWeight: '600' }}>{t('Business Visitor Register')}</h3>
                                    </div>
                                    <div className='row' style={{ paddingLeft: '23px' }}>
                                        <span className='visitor-registration-msg'>{t('VisitorClientMessage')}
                                        </span>
                                    </div>
                                    <div className='row'>
                                        <form >
                                            <div className='col-md-6'>
                                                <div className="custom-form__row">
                                                    <Input
                                                        className="custom-form__col"
                                                        placeholder={t('Business Name')}
                                                        type="text"
                                                        name="business_name"
                                                        value={currentClientVisitor.business_name}
                                                        onChange={evt => {
                                                            this.handleTextChange('business_name', evt.target.value);
                                                        }}
                                                    />
                                                </div>
                                                <div className="custom-form__row">
                                                    <Input
                                                        className="custom-form__col"
                                                        placeholder={t('Contact Person Name')}
                                                        type="text"
                                                        name="contact_person_name"
                                                        value={currentClientVisitor.contact_person_name}
                                                        onChange={evt => {
                                                            this.handleTextChange('contact_person_name', evt.target.value);
                                                        }}
                                                    />
                                                </div>
                                                <div className="custom-form__row">
                                                    <Input
                                                        className="custom-form__col"
                                                        placeholder={t('User Name')}
                                                        type="text"
                                                        name="username"
                                                        value={currentClientVisitor.username}
                                                        onChange={evt => {
                                                            this.handleTextChange('username', evt.target.value);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className="custom-form__row">                                                    
                                                    <NumberInput
                                                        className="custom-form__col"
                                                        placeholder={t('Business ID')}
                                                        type="number"
                                                        name="business_id"                                                        
                                                        value={currentClientVisitor.business_id}
                                                        onChange={evt => {
                                                            this.handleTextChange('business_id', evt.target.value);
                                                        }}                                                        
                                                    />
                                                </div>
                                                <div className="custom-form__row">
                                                    <Input
                                                        className="custom-form__col"
                                                        placeholder={t('Contact Person Email')}
                                                        type="text"
                                                        name="contact_person_email"
                                                        value={currentClientVisitor.contact_person_email}
                                                        onChange={evt => {
                                                            this.handleTextChange('contact_person_email', evt.target.value);
                                                        }}
                                                    />
                                                </div>
                                                <div className="custom-form__row">
                                                    <Input
                                                        className="custom-form__col"
                                                        placeholder={t('Phone no.')}
                                                        type="text"
                                                        name="phone"
                                                        value={currentClientVisitor.phone}
                                                        onChange={evt => {
                                                            this.handleTextChange('phone', evt.target.value);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div className="custom-form__row">
                                                <Button fill onClick={() => this.handleButtonClick('add')} style={{ height: "5rem", margin: "5px 10px", fontSize: "18px!important", width: '100%' }}>
                                                    {t('Get Free Link Now')}
                                                </Button>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className="custom-form__row">
                                                {error !== "" && <span className='text-danger'>{t(error)}</span>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-sm-12 col-md-4'>
                                    <img src={clientRegister} alt='register.svg' />
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-3 col-md-2'></div>
                    </div>
                }
            </div>
        )
    }
}
export default inject('visitorStore', 'commonStore')(withLocalization(applicationRouter(observer(VisitorClientRegister))));


