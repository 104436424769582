import React from 'react';
import ObjectField from 'react-jsonschema-form/lib/components/fields/ObjectField';
import { Tabs, Tab } from 'react-bootstrap';

import LayoutField from './LayoutField';
import withLocalization from '../../hoc/withLocalization';

class TabsField extends ObjectField {
    render() {
        const { uiSchema, ...restProps } = this.props;

        const tabs = uiSchema['ui:tabs'];
        const className = uiSchema['ui:className'] || '';

        return (
            <Tabs defaultActiveKey={tabs[0].id} id="tabs" className={className}>
                {tabs.map((tab) => (
                    <Tab eventKey={tab.id} title={this.props.t(tab['ui:title'] || tab.title)} key={tab.id}>
                        <LayoutField uiSchema={tab} {...restProps} />
                    </Tab>
                ))}
            </Tabs>
        );
    }
}

export default withLocalization(TabsField);
