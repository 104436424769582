import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import moment from 'moment';

//components
import GenericList from "../../../components/GenericList/GenericList";
import withLocalization from "../../../hoc/withLocalization";
import applicationRouter from '~/hoc/applicationRouter'
import RecipientsList from "../SignDocs/Recipients/recipientList";

//elements
import Button from "../../../components/CustomButton/CustomButton";

//services
import config from '~/library/config';

//assets
import editIcon from '../../../assets/img/editing.svg';

//styles
import 'bootstrap/dist/css/bootstrap.min.css';
import "../SignDocs/Widgets/CaseList.css";

let nameTimeOut = null;
class UserContractList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showrecipientList: false,
            caseId: null,
            currentStatus: '',
            nameFilter: '',
            recipients_status: undefined,
        }
    }

    navigateToHistory = (data) => {
        this.props.router.navigate('/admin/signdochistory', {
            state: {
                caseId: data.id,
                caseName: data.name,
                caseDate: data.send_date,
                parent: 'UserContract'
            },
        });
    }

    handleDownload = (id) => {
        window.open(`${config.API_ROOT}/sign_documents/completed_signing_document/${encodeURIComponent(id)}`);
    }

    handleDoSignature = (id) => {
        const { signatureStore, commonStore } = this.props;
        signatureStore.generateSignatureLink(id).then((res) => {
            window.open(`${res.Link}`);
        }).catch((err) => {
            commonStore.addNotification(err.message, null, 'error');
        });
    }

    renderTableActionButtons = (id, data) => (
        <div className="actions-center actions-center__center">
            <Button fill icon_sm onClick={() => this.navigateToHistory(data)}
                title={this.props.t('Check History')}
            >
                <i className="fa fa-history fa-lg" />
            </Button>
            {data.status === "pending" && this.props.userStore.currentUser.id !== data.created_by && (
                <Button fill icon_sm onClick={() => this.handleDoSignature(id)}
                    title={this.props.t('Edit')}
                >
                    <img src={editIcon}
                        alt={"edit button"}
                    />
                </Button>
            )
            }
            {data.status === 'completed' && (
                <Button fill icon_sm onClick={() => this.handleDownload(id)}
                    title={this.props.t('Download Signature')}>
                    <i className="fa fa-download fa-lg" />
                </Button>
            )
            }
        </div>
    );

    handlerecipientList = (recipients, id) => {
        if (!recipients) return 0;
        else
            this.setState({ showrecipientList: !this.state.showrecipientList, caseId: id });
    };

    onStatusChange = (value) => {
        const { signatureStore } = this.props;
        signatureStore.onFilter('status', value.value);
        this.setState({ currentStatus: value });
    }

    onNameChange = (e) => {
        const { signatureStore } = this.props;
        this.setState({ nameFilter: e.target.value })
        if (nameTimeOut) {
            clearTimeout(nameTimeOut);
        }
        nameTimeOut = setTimeout(() => {
            signatureStore.onFilter('name', this.state.nameFilter);
        }, 1000);
    }

    sendToSignature(data) {
        const { signatureStore } = this.props;
        if (data.status === "draft") {
            signatureStore.setCaseId(data.id);
            sessionStorage.setItem("CaseId", data.id);
            this.props.router.navigate('/admin/signupload');
        }
    }

    getColorCode(status) {
        if (status === 'pending') {
            return '#ffb300'
        } else if (status === 'draft') {
            return '#c9c9c9'
        } else if (status === 'completed') {
            return '#33c85b'
        } else if (status === 'expired') {
            return '#ff0000'
        } else {
            return '#ffb300'
        }
    }

    render() {
        const { t, user_id } = this.props;
        const { commonStore } = this.props;
        const dateTimeRules = commonStore.config.client && commonStore.config.client.data
            && commonStore.config.client.data.dateTimeRules ? commonStore.config.client.data.dateTimeRules : false;
        return (
            <div className="profile-edit-form__group mt-4 p-4">
                <span className="form-subtitle">{t('Member')} {t('Contracts')}</span>
                {
                    <GenericList
                        columns={[
                            {
                                Header: 'Document Name',
                                id: 'name',
                                isSortable: true,
                                accessor: (data) => (<span onClick={() => { this.sendToSignature(data) }}>{data.name}</span>),
                                textAlign: 'start',
                                minWidth: 180
                            },
                            {
                                Header: 'Created By',
                                id: 'created_by_full_name',
                                accessor: ({ created_by_full_name }) => <span>{created_by_full_name}</span>,
                            },
                            {
                                Header: 'Status',
                                id: 'status',
                                accessor: ({ status, recipients_status, recipients }) => (
                                    <div
                                        className={recipients_status === 0 && recipients === 0 ? 'progress' : 'progress'}
                                        style={{
                                            height: '30px',
                                            color: 'white',
                                            borderRadius: '14px',
                                            verticalAlign: 'middle',
                                            lineHeight: '30px',
                                            userSelect: 'none',
                                        }}
                                    >
                                        <div
                                            className={
                                                status === 'completed'
                                                    ? 'progress-bar'
                                                    : status === 'expired'
                                                        ? 'expired_color'
                                                        : status === 'pending' && recipients_status === '0'
                                                            ? 'pending'
                                                            : status === 'draft'
                                                                ? 'draft'
                                                                : recipients_status === (recipients >= 1 && recipients_status >= 1) ||
                                                                    recipients_status / recipients
                                                                    ? 'progress-bar-primary'
                                                                    : ''
                                            }
                                            role="progressbar"
                                            style={{
                                                width:
                                                    status === 'completed'
                                                        ? '100%'
                                                        : status === 'expired'
                                                            ? '100%'
                                                            : status === 'pending' && recipients_status === '0'
                                                                ? '100%'
                                                                : status === 'draft'
                                                                    ? '100%'
                                                                    : recipients_status === (recipients_status === recipients) ||
                                                                        (recipients >= 1 && recipients_status >= 1) ||
                                                                        recipients_status / recipients
                                                                        ? ((recipients_status / recipients) * 100).toFixed(0) + '%'
                                                                        : '',
                                            }}
                                        >
                                            {status === 'completed'
                                                ? t('Completed')
                                                : status === 'expired'
                                                    ? t('Expired')
                                                    : status === 'pending' && recipients_status === '0'
                                                        ? t('Pending')
                                                        : status === 'draft'
                                                            ? t('Draft')
                                                            : recipients_status === (recipients_status === recipients) ||
                                                                (recipients >= 1 && recipients_status >= 1) ||
                                                                recipients_status / recipients
                                                                ? ((recipients_status / recipients) * 100).toFixed(0) + '%'
                                                                : ''}
                                        </div>
                                    </div>
                                ),
                                maxWidth: 120,
                                isSortable: true,
                            },
                            {
                                Header: 'Recipients',
                                id: 'recipients',
                                accessor: ({ recipients, id }) =>
                                    <div className="d-flex justify-content-center cursor-pointer">
                                        <span
                                            className="recipient-count-badge"
                                            onClick={() => { this.handlerecipientList(recipients, id) }}
                                        >{recipients}
                                        </span>
                                    </div>
                                ,
                                isSortable: true,
                                maxWidth: 120
                            },
                            {
                                Header: 'Sent Date',
                                id: 'send_date',
                                sortable: true,
                                accessor: ({ send_date }) => (
                                    <span>{send_date ? moment(send_date).format(dateTimeRules.short_date_format) : ''}</span>
                                ),
                                maxWidth: 100
                            },
                            {
                                Header: 'Expiring On',
                                id: 'expiry_date',
                                isSortable: true,
                                accessor: ({ expiry_date }) => (
                                    <span>{expiry_date ? moment(expiry_date).format(dateTimeRules.short_date_format) : ''}</span>
                                ),
                                maxWidth: 100
                            },
                            {
                                Header: 'Action',
                                id: 'action',
                                accessor: (data) => this.renderTableActionButtons(data.id, data),
                                maxWidth: 120
                            }
                        ]}
                        lastListloadTime={this.props.signatureStore.lastListloadTime}
                        requestData={(params) => this.props.signatureStore.getSignDocument(params, true, user_id)}
                    />
                }
                <RecipientsList caseId={this.state.caseId} show={this.state.showrecipientList} handlerecipientList={this.handlerecipientList}></RecipientsList>
            </div>
        )
    }

}

export default inject('signatureStore', 'userStore', 'commonStore')(applicationRouter(withLocalization(observer(UserContractList))));
