import React, { Component } from 'react';
import applicationRouter from '~/hoc/applicationRouter'
import { inject, observer } from 'mobx-react';
import _ from 'lodash';

import '../../../styles/css/styles.css';
import { longDateFormat } from '../../../utils/dateFormat';
// Components
import DatePicker from '../../../components/DatePicker/DatePicker';
import Signature from '../../../components/Signature/Signature';

import withRemoveDialog from '~/hoc/withRemoveDialog';
import withLocalization from '~/hoc/withLocalization';


class ChecklistCheckAll extends Component {
    state = {
        due_date: (new Date()),
        signature: ""
    };

    componentDidMount() {
        this.setState({
            checklist_name:this.props.checklist_name
        });
    }

    // handleChange = (name, value) => {
    //     let element = this.state.element;
    //     this.setState({
    //         element: {
    //             ...element,
    //             comment: value,
    //         }
    //     });
    //     this.handleSaveChecklistElement();
    // };

    handleSaveChecklistCheckAll = () => {
        this.props.handleSaveChecklistCheckAll(this.state.element);
    }

    render() {
        const { t, isUnionMode } = this.props;
        const { attachments } = this.state;
        const { config } = this.props.commonStore
        const dateTimeRules = config.client && config.client.data && config.client.data.dateTimeRules ? config.client.data.dateTimeRules : false;
        return (
            <div>
                <div className="custom-form__row">
                    <div className="custom-form__col field field-string">
                        <label className="control-label">{t('Name')} : {this.state.checklist_name}</label>
                    </div>
                    <div className="custom-form__col">
                        <div>
                            <label className="control-label">{t('Due Date')}</label> : &nbsp;
                            <DatePicker
                                selected={this.state.due_date}
                                // onChange={(date) => this.handleChange('due_date', date)}
                                dateFormat= {longDateFormat(dateTimeRules)}
                                disabled
                            /></div>
                    </div>
                </div>
               
                <div className="custom-form__row">
                    <div className="custom-form__col">
                        <div>
                            {/* <label className="control-label">{t('Signature')} </label> */}
                            <Signature
                                onChange={(signature) => {
                                    this.props.handleSingatureChange(signature);
                                    this.setState({
                                            signature: signature,
                                    })
                                }
                                }
                                initialSignature={this.state.signature}
                                required
                                width="100%"
                                height={250}
                                error={!this.state.signature}
                            />
                        </div>
                        {t('Note: It will check all New and Under Work checklists.')}
                    </div>
                </div>

            </div>
        );
    }
}

export default inject('checklistStore', 'authStore', 'userStore', 'commonStore')(
    applicationRouter(withLocalization(withRemoveDialog(observer(ChecklistCheckAll))))
);
