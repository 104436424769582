import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

//components
import applicationRouter from '~/hoc/applicationRouter'
import withRemoveDialog from '~/hoc/withRemoveDialog';
import withLocalization from '~/hoc/withLocalization';

//elements
import CustomMultiSelect from '../../../../elements/CustomMultiSelect';

//styles
import '../../../../styles/css/styles.css';

//this component displays all the documents with checkbox// 
//and the ones in the current template are checked//

class DocumentMultipleSelector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            template_doc_ids: null,//for Ids of documents in document template
            documentList: [],//to load all documents
            selected: [],//for selected documents
            options: [],//for document list
            template_documents: [],//used for rendering documents via quill
            modules: {
                toolbar: false,
            },
        };
        this.loadData = this.loadData.bind(this);
        this.parseTemplateIds = this.parseTemplateIds.bind(this);
        this.handleSelectedOptions = this.handleSelectedOptions.bind(this);
        this.updateDocumentList = this.updateDocumentList.bind(this);
        this.updateListInSelectedOrder = this.updateListInSelectedOrder.bind(this);
    }

    componentDidMount() {
        //setting the template_doc_ids and calls loadData//
        this.setState({
            template_doc_ids: this.props.documents
        }, () => {
            this.loadData();
        });
    }

    //maps and parse ids from template_doc_ids//
    parseTemplateIds = () => {
        let idsArray = []
        if (this.state.template_doc_ids != null && this.state.template_doc_ids != "") {
            idsArray = this.state.template_doc_ids.split(",").map(function (item) {
                return parseInt(item, 10);
            });
        }
        return idsArray;
    }

    //loads documentList and assigns checked values based on template_doc_ids//
    async loadData() {
        let idsArray = this.parseTemplateIds();

        //fetches all documents and assigns checked values based on idsArray//
        //and sets documentList//
        await this.props.documentStore.getDocumentsList().then((data) => {
            if (idsArray.length > 0) {
                data.rows = data.rows.map(function (item) {
                    if (idsArray.includes(item.id)) {
                        item.checked = true;
                        return item;
                    } else {
                        item.checked = false;
                        return item;
                    }
                })
            };
            let optionArray = [];
            data.rows.forEach(item => {
                optionArray.push({
                    label: item.document_name,
                    value: item.id
                })
            })
            this.setState({
                documentList: data.rows,
                options: optionArray,
                selected: idsArray
            },
                this.updateListInSelectedOrder(this.state.documentList, this.state.selected));
        });
    }

    //handlesSelectedOptions
    handleSelectedOptions = (selected) => {
        this.setState({ selected }, () => {
            this.updateDocumentList()
        })
    }

    //update DocumentList with selected values//
    updateDocumentList = () => {
        let idsArray = this.state.selected;
        let updatedData = this.state.documentList;
        updatedData = this.state.documentList.map(function (item) {
            if (idsArray.includes(item.id)) {
                item.checked = true;
                return item;
            } else {
                item.checked = false;
                return item;
            }
        })


        this.setState({
            documentList: updatedData,
            template_doc_ids: idsArray.toString()
        }, () => {
            this.props.documentStore.handleDocumentTemplateChange("template_documents", this.state.template_doc_ids);
            this.updateListInSelectedOrder(this.state.documentList, this.state.selected);
        })
    }

    //render documents on the basis of selection//
    updateListInSelectedOrder = (documentList, selected) => {
        let result = []
        selected.forEach(i => {
            result.push(documentList.filter(item => item.id == i)[0])
        })
        this.setState({ template_documents: result })
    }

    render() {
        //renders documents with checkbox and checked documents//
        const { selected } = this.state;

        return (
            <div>
                <CustomMultiSelect
                    options={this.state.options}
                    value={selected}
                    onChange={selected => this.handleSelectedOptions(selected)}
                />
            </div>
        )
    }
}

export default inject('documentStore')(applicationRouter(withLocalization(withRemoveDialog(observer(DocumentMultipleSelector)))));