import React, { Component } from 'react';
import { Dropdown } from 'react-bootstrap';
import { inject, observer } from 'mobx-react';

//components
import applicationRouter from '~/hoc/applicationRouter';
import withLocalization from '~/hoc/withLocalization';
import { defaultLanguage } from '~/library/core';

//library
import config from '../../library/config';

//assets
import usFlag from '../../assets/images/flags/us.jpg';
import norwegian from '../../assets/images/flags/norwegian.jpg';
import spain from '../../assets/images/flags/spain.jpg';


const languageMap = [
    {
        langName: 'English',
        langSort: 'en',
        flagImg: usFlag,
    },
    {
        langName: 'Norsk',
        langSort: 'no',
        flagImg: norwegian,
    },
    {
        langName: 'Spanish',
        langSort: 'es',
        flagImg: spain,
    },
];

class LanguageDropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            langSelect: config.SERVER_IS_NORWAY ? norwegian : usFlag,
        };
        this.handleLangChange = this.handleLangChange.bind(this);
        this.updateAppLocale = this.updateAppLocale.bind(this);
    }
    langName = (lang) => {
        if (lang === 'no') return 'Norsk';
        if (lang === 'en') return 'English';
        if (lang === 'es') return 'Spanish';
        return defaultLanguage();
    };

    handleLangChange(lang) {
        this.updateAppLocale(lang)
    }

    componentDidMount() {
        const lang = sessionStorage.getItem('locale');
        if (!lang) this.updateAppLocale(defaultLanguage() === 'Norsk' ? 'no' : 'en');
        else this.updateAppLocale(lang)

    }

    updateAppLocale = (lang) => {
        const selectedLang = languageMap.find(item => item.langSort === lang)
        if (!selectedLang) return;
        this.props.commonStore.setAppLang(lang)
        this.setState({ langSelect: selectedLang.flagImg })
    }

    render() {
        const getCurrentLocale = sessionStorage.getItem('locale');
        return (
            <Dropdown className="languageBtnDiv d-inline-block">
                <Dropdown.Toggle
                    className="btn fill dynamic-lg btn-mute languageBtn mt-2 languageFlagtoogle"
                    tag="button"
                >
                    <img src={this.state.langSelect} alt="img" height="30" className="languageFlagImg" />
                </Dropdown.Toggle>

                <Dropdown.Menu className="language-switch dropdown-menu-end">
                    <div className="languages-list">
                        {languageMap.map((langEach, ids) => (
                            <Dropdown.Item
                                active={langEach.langSort === getCurrentLocale}
                                onClick={() => {
                                    this.handleLangChange(`${langEach.langSort}`);
                                }}
                                key={ids}
                                className={`notify-item ${langEach.langSort === getCurrentLocale ? 'active' : 'none'}`}
                            >
                                <img className="me-1" height="12" src={langEach.flagImg} alt="language logo" />

                                <span className="align-middle">{langEach.langName}</span>
                            </Dropdown.Item>
                        ))}
                    </div>
                </Dropdown.Menu>
            </Dropdown>
        );
    }
}

export default inject('commonStore', 'userStore')(applicationRouter(withLocalization(observer(LanguageDropdown))));
