import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
// import applicationRouter from '~/hoc/applicationRouter'
import withLocalization from '~/hoc/withLocalization';
// import withPermissions from '../../../hoc/withPermissions';

import CustomRadio from '../../CustomRadio/CustomRadio';

const ProjectStatusesMobx = inject('projectStore', 'userStore')(withLocalization(observer(class ProjectStatusesMobx extends Component {
    render() {
        const { value, onChange } = this.props;
        const { client } = this.props?.commonStore?.config;
        const statuses = client ? client.data.project_statuses : false;
        if (!statuses) {
            return null;
        }
        return (
            <div className="project-statuses">
                {statuses.map(({ code, name }) => (
                    <CustomRadio
                        number={`${code}_${name}`}
                        name={code}
                        key={code}
                        onChange={() => onChange(code)}
                        checked={value === code}
                        option={code}
                        label={name}
                    />
                ))}
            </div>
        );
    }
})));
export const ProjectStatuses = observer(class ProjectStatuses extends Component {
    render() {
        return <ProjectStatusesMobx {...this.props} mode="managers" />;
    }
});