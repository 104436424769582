import React, { Component } from 'react';
import applicationRouter from '~/hoc/applicationRouter'
import { inject, observer } from 'mobx-react';

import AbsencesList from './AbsencesList';
import withLocalization from '~/hoc/withLocalization';
import withRemoveDialog from '~/hoc/withRemoveDialog';

class AbsencesPage extends Component {
    constructor(props) {
        super(props)
        this.onAdd = this.onAdd.bind(this)
    }

    onAdd(page = 0, isEdit = true) {
        this.props.router.navigate('/admin/absences/add', { state: { page, isEdit } });
    }

    render() {
        return (
            <AbsencesList
                onAdd={this.onAdd}
            />
        );
    }
}
export default inject('authStore', 'absenceStore', 'userStore')(applicationRouter(withLocalization(withRemoveDialog(observer(AbsencesPage)))));
