import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment/min/moment-with-locales'; // 'moment/min/moment-with-locales';
import { Modal, Row, Col } from 'react-bootstrap';

// Components
import applicationRouter from '~/hoc/applicationRouter';
import GenericList from '../../../components/GenericList/GenericList';
import { AttachmentsWidget } from '../../../components/GenericForm/Widgets/AttachmentsWidget';
import withLocalization from '../../../hoc/withLocalization';
import DatePicker from '../../../components/DatePicker/DatePicker';
import withRemoveDialog from '~/hoc/withRemoveDialog';
import GenericFilter from '../../../components/GenericList/GenericFilter';
import MultiAccountAdmin, { MultiAccountModes } from '../Users/MultiAccountAdmin.js';

//services
import configRoots from '~/library/config';
import { serverTimeFormat } from '~/library/core';
import config from '~/library/config';
import agent from '~/library/agent';

//elements
import Button from '../../../components/CustomButton/CustomButton.jsx';
import StatusBox from '../../../elements/StatusBox';

//assets
import CloseModalIcon from '../../../assets/img/CloseApproveModalIcon.svg';
import Edit from '../../../assets/img/editing.svg';
import removeUser from '../../../assets/img/removeUser.svg';
import addUser from '../../../assets/img/addUser.svg';
class ClientsList extends Component {
    state = {
        showAttachmentModal: false,
        currentClientId: 0,
        clientAttachmentIds: '',
        showRenewModal: false,
        showRemoveAttachmentModal: false,
        renew_date: new Date(),
        attachmentList: [],
        expiry_date: new Date(),
        showMultiAccountsForClientModal: false
    };

    printExcelReport = async () => {
        const { filters } = this.props;
        let hydratedPayload = {
            filters: filters,
            authorization: this.props.commonStore.token,
        };
        window.open(`${config.API_ROOT}/clients/report/${agent.convertToGetParams(hydratedPayload)}`);
    };

    handleTableButtonAction = (id, type, data, expiry_date) => {
        if (type === 'upload') {
            this.handleAttachmentUploads(id, data, expiry_date);
            return;
        }
        if (type === 'remove-expiry') {
            this.removeExpiryAttachments(id, data);
            this.handleRenewModalCloseButton();
            this.props.clientStore.resetLastListLoadTime();
            return;
        }
        if (type === 'edit') {
            this.props.router.navigate(`/admin/clients/${id}`, { state: { client: id } });
        }
        if (type === 'remove') {
            return this.props.handleDelete(id, (id) => {
                return this.props.clientStore.remove(id).then((res) => {
                    this.props.clientStore.resetLastListLoadTime();
                    return res;
                });
            });
        }
        if (type === 'loginas') {
            this.props.authStore
                .loginAs(id)
                .then(() => {
                    window.location.href = `${window.location.href.split('/admin')[0]}/admin/?reload`;
                })
                .catch((e) => {
                    this.props.commonStore.addNotification(e.message || this.props.t('Error'), null, 'error');
                });
            return;
        }
        if (type === 'multi-accounts') {
            this.showAllMultiAccountsForClient(id);
        }
    };

    onAdd = (biztypeId) => {
        this.props.router.navigate('/admin/clients/add', { state: { biztypeId: biztypeId } });
    }

    handleAttachmentModalCloseButton = () => {
        this.setState({
            showAttachmentModal: false,
            currentClientId: 0,
            clientAttachmentIds: '',
            attachmentList: [],
            expiry_date: new Date(),
        });
    };

    handleRenewModalCloseButton = () => {
        this.setState({ showRenewModal: false, currentClientId: 0, renew_date: new Date() });
    };

    handleRemoveAttachmentModalCloseButton = () => {
        this.setState({ showRemoveAttachmentModal: false, currentClientId: 0 });
    };

    handleAttachmentUploads = async (id, data, expiry_date) => {
        let attachment_ids = '';
        let attachmentList = [];
        if (data && data.attachment_id !== '') {
            attachment_ids = data.attachment_id;
            await this.props.clientStore.getMultipleAttachments({ attachmentIds: attachment_ids }).then((response) => {
                attachmentList = response.attachments;
            });
        }
        this.setState({
            clientAttachmentIds: attachment_ids,
            showAttachmentModal: true,
            currentClientId: id,
            attachmentList: attachmentList,
            expiry_date: expiry_date ? new Date(expiry_date) : new Date(),
        });
    };

    saveClientAttachments = async () => {
        const { clientStore, t, commonStore } = this.props;
        const { currentClientId, clientAttachmentIds, expiry_date } = this.state;
        if (clientAttachmentIds === '' || clientAttachmentIds === undefined) {
            commonStore.addNotification(t('Please Add Attachments'), null, 'error');
            return;
        }
        let data = {
            id: currentClientId,
            attachment_id: clientAttachmentIds,
            expiry_date: moment(expiry_date).format('YYYY-MM-DD'),
        };
        await clientStore
            .addAttachments(data)
            .then((response) => {
                if (response) {
                    commonStore.addNotification(t('Saved'), null, 'success');
                    this.handleAttachmentModalCloseButton();
                    this.props.clientStore.resetLastListLoadTime();
                    return;
                }
            })
            .catch((error) => {
                commonStore.addNotification(error.message || t('Error'), null, 'error');
            });
    };

    removeExpiryAttachments = async () => {
        const { currentClientId } = this.state;
        const { clientStore, t, commonStore } = this.props;
        let newData = {
            id: currentClientId,
            attachment_id: '',
            expiry_date: null,
        };
        await clientStore
            .addAttachments(newData)
            .then((response) => {
                if (response) {
                    commonStore.addNotification(t('Deleted'), null, 'success');
                    this.props.clientStore.resetLastListLoadTime();
                }
            })
            .catch((error) => {
                commonStore.addNotification(error.message || t('Error'), null, 'error');
            });
        this.handleRemoveAttachmentModalCloseButton();
    };

    renewClientDate = async () => {
        const { clientStore, t, commonStore } = this.props;
        const { currentClientId, renew_date } = this.state;
        let data = {
            id: currentClientId,
            renew_date: moment(renew_date).format('YYYY-MM-DD'),
        };
        await clientStore
            .renewClient(data)
            .then((response) => {
                if (response) {
                    commonStore.addNotification(t('Saved'), null, 'success');
                    return;
                }
            })
            .catch((error) => {
                console.error(error);
                commonStore.addNotification(error.message || t('Error'), null, 'error');
            });
    };

    showAllMultiAccountsForClient = (id) => {
        this.setState({currentClientId: id}, () => this.setState({ showMultiAccountsForClientModal: true }));
    }

    handleMultiAccountsForClientModal = () => {
        this.setState({ showMultiAccountsForClientModal: !this.state.showMultiAccountsForClientModal, currentClientId: 0 });
    }

    renderTableActionButtons = (id, user_id, status, allow_multi_account_administration) => (
        <div className="actions-center">
            {allow_multi_account_administration &&
                <Button
                    icon_sm
                    fill
                    onClick={() => this.handleTableButtonAction(id, 'multi-accounts')}
                    title={this.props.t('Show All Accounts')}
                >
                    <i className="fa fa-users" ></i>
                </Button>
            }
            <Button
                icon_sm
                fill
                onClick={() => this.handleTableButtonAction(user_id, 'loginas')}
                title={this.props.t('Login as')}
            >
                <img src={addUser} alt={'add user'} />
            </Button>
            <Button icon_sm fill onClick={() => this.handleTableButtonAction(id, 'edit')}>
                <img src={Edit} alt={"edit button"} />
            </Button>
            {status !== 'active' && (
                <Button icon_sm_delete fill onClick={() => this.handleTableButtonAction(id, 'remove')}>
                    <img src={removeUser} alt={"remove button"} />
                </Button>
            )}
        </div>
    );

    render() {
        const { t } = this.props;
        const { config } = this.props.commonStore;
        const { filters, appliedFilters, loading } = this.props.clientStore;
        if (!config.biztypes) return null;
        const statusOptions = [
            { code: 'active', name: t('Active') },
            { code: 'inactive', name: t('Inactive') },
        ];
        return (
            <>
                <GenericList
                    columns={[
                        {
                            Header: 'ID',
                            id: 'id',
                            accessor: 'id',
                            maxWidth: 25,
                        },
                        {
                            Header: 'Name',
                            id: 'name',
                            accessor: ({ name }) => <span>{name}</span>,
                            isSortable: true,
                            maxWidth: 150,
                            minWidth: 80,
                        },
                        {
                            Header: 'Type',
                            id: 'biztype_name',
                            accessor: 'biztype_name',
                            maxWidth: 100,
                            minWidth: 80,
                        },
                        {
                            Header: 'Phone',
                            id: 'phone',
                            accessor: 'phone',
                            isSortable: true,
                            maxWidth: 120,
                            minWidth: 80,
                        },
                        {
                            Header: 'Email',
                            id: 'email',
                            accessor: ({ email }) => <span>{email}</span>,
                            isSortable: true,
                            maxWidth: 200,
                            minWidth: 150,
                        },
                        {
                            Header: 'Users',
                            id: 'users_amount',
                            accessor: 'users_amount',
                            isSortable: true,
                            maxWidth: 50,
                        },
                        {
                            Header: 'Expiry date',
                            id: 'expiry_date',
                            accessor: ({ id, data, expiry_date }) => (
                                <span>
                                    {expiry_date != null ? (
                                        <span>
                                            <Button
                                                wd
                                                round
                                                onClick={() =>
                                                    this.handleTableButtonAction(id, 'upload', data, expiry_date)
                                                }
                                            >
                                                {moment(expiry_date).format(serverTimeFormat())}
                                            </Button>
                                            <i
                                                className="fa fa-times"
                                                style={{ cursor: 'pointer', color: '#cc6d6b' }}
                                                onClick={() =>
                                                    this.setState({
                                                        currentClientId: id,
                                                        showRemoveAttachmentModal: true,
                                                    })
                                                }
                                            />
                                        </span>
                                    ) : (
                                        <Button
                                            wd
                                            fill
                                            round
                                            onClick={() =>
                                                this.handleTableButtonAction(id, 'upload', data, expiry_date)
                                            }
                                        >
                                            {this.props.t('Set Expiry')}
                                        </Button>
                                    )}
                                </span>
                            ),
                            minWidth: 200,
                            isSortable: true,
                        },
                        {
                            Header: 'Status',
                            id: 'status',
                            accessor: ({ status }) => <StatusBox status={status || ''} />,
                            maxWidth: 80,
                            minWidth: 70,
                        },
                        {
                            Header: 'Operation',
                            id: 'operation',
                            accessor: ({ id, user_id, status, allow_multi_account_administration }) =>
                                this.renderTableActionButtons(id, user_id, status, allow_multi_account_administration),
                            minWidth: 110,
                            maxWidth: 120
                        },
                    ]}
                    header={
                        <GenericFilter
                            filters={filters}
                            setFilter={(name, value) => this.props.clientStore.setFilter(name, value)}
                            onFilter={() => this.props.clientStore.onFilter()}
                            loading={loading}
                            hasStatusFilter={true}
                            hasBiztypeFilter={true}
                            statusOptions={statusOptions}
                        />
                    }
                    tableHeader={this.props.t('Clients')}
                    filters={appliedFilters}
                    lastListLoadTime={this.props.clientStore.lastListLoadTime}
                    requestData={(params) => this.props.clientStore.loadList(params)}
                >
                    <div className="table-list__header">
                        <Button fill onClick={() => this.printExcelReport()} disabled={loading}>
                            <i className="fa fa-print" />
                        </Button>
                        {config.biztypes.map((biztype) => (
                            <Button fill wd key={biztype.id} onClick={() => this.onAdd(biztype.id)}>
                                <i className="fa fa-plus-circle fa-xl margin-right-10" /> Add {biztype.name}
                            </Button>
                        ))}
                    </div>
                </GenericList>
                <Modal show={this.state.showAttachmentModal} onHide={this.handleAttachmentModalCloseButton}>
                    <Modal.Header>
                        <Modal.Title className="w-100 text-center m-0">
                            {this.props.t('Client')} {this.props.t('Attachments')}
                        </Modal.Title>
                        <img
                            src={CloseModalIcon}
                            className="cursor-pointer"
                            onClick={this.handleAttachmentModalCloseButton}
                        />
                    </Modal.Header>
                    <Modal.Body style={{ minHeight: "400px" }}>
                        <Row>
                            <Col sm={12}>
                                <AttachmentsWidget
                                    value={this.state.clientAttachmentIds}
                                    onChange={(attachIds) => this.setState({ clientAttachmentIds: attachIds })}
                                    options={{
                                        imageContext: {
                                            existingAttachment: [],
                                            fileType: 'docs',
                                            id: 0,
                                            model: 'Client',
                                        },
                                    }}
                                // error={this.state.element.photo_required && !this.state.element.attachments_by_user}
                                />
                                <DatePicker
                                    selected={this.state.expiry_date}
                                    onChange={(date) => this.setState({ expiry_date: date })}
                                    dateFormat="EEEE MMMM dd, yyyy"
                                    label={this.props.t('Expiry date')}
                                />
                            </Col>
                            {this.state.attachmentList && this.state.attachmentList != [] && (
                                <Col sm={12} className="mt-2">
                                    <h4>
                                        {this.props.t('Prev')} {this.props.t('Attachments')}
                                    </h4>
                                    {this.state.attachmentList.map((attachment, index) => (
                                        <div key={index}>
                                            <a
                                                href={`${configRoots.API_ROOT}/attachments/${attachment.id}`}
                                                target="_blank"
                                            >
                                                {attachment.filename}
                                            </a>
                                        </div>
                                    ))}
                                </Col>
                            )}
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.saveClientAttachments} className="btn-wd btn-fill">
                            {this.props.t('Save')}
                        </Button>
                        <Button variant="secondary" onClick={this.handleAttachmentModalCloseButton} className="btn-wd">
                            {this.props.t('Close')}
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.showRenewModal} onHide={this.handleRenewModalCloseButton}>
                    <Modal.Header>
                        <Modal.Title className="w-100 text-center m-0">
                            {this.props.t('Renew')} {this.props.t('Clients')}
                        </Modal.Title>
                        <img
                            src={CloseModalIcon}
                            className="cursor-pointer"
                            onClick={this.handleRenewModalCloseButton}
                        />
                    </Modal.Header>
                    <Modal.Body>
                        <DatePicker
                            selected={this.state.renew_date}
                            onChange={(date) => this.setState({ renew_date: date })}
                            dateFormat="EEEE MMMM dd, yyyy"
                            label={this.props.t('Renew date')}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.renewClientDate} className="btn-wd btn-fill">
                            {this.props.t('Save')}
                        </Button>
                        <Button variant="secondary" onClick={this.handleRenewModalCloseButton} className="btn-wd">
                            {this.props.t('Close')}
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.showRemoveAttachmentModal} onHide={this.handleRemoveAttachmentModalCloseButton}>
                    <Modal.Header>
                        <Modal.Title className="w-100 text-center m-0">
                            {this.props.t('Remove')} {this.props.t('Expiry date')}
                        </Modal.Title>
                        <img
                            src={CloseModalIcon}
                            className="cursor-pointer"
                            onClick={this.handleRemoveAttachmentModalCloseButton}
                        />
                    </Modal.Header>
                    <Modal.Body>{this.props.t('Are you sure to perform this action?')}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.removeExpiryAttachments} className="btn-wd btn-fill">
                            {this.props.t('Yes')}
                        </Button>
                        <Button
                            variant="secondary"
                            onClick={this.handleRemoveAttachmentModalCloseButton}
                            className="btn-wd"
                        >
                            {this.props.t('No')}
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.showMultiAccountsForClientModal}
                    size="lg" onHide={this.handleMultiAccountsForClientModal}>
                    <Modal.Header>
                        <Modal.Title className="w-100 text-center m-0">
                            {this.props.t('Client Multi Account Details')}
                        </Modal.Title>
                        <img
                            src={CloseModalIcon}
                            className="cursor-pointer"
                            onClick={this.handleMultiAccountsForClientModal}
                        />
                    </Modal.Header>
                    <Modal.Body>
                        <MultiAccountAdmin clientId={this.state.currentClientId} mode={MultiAccountModes.INFO} />
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}
export default inject(
    'clientStore',
    'commonStore',
    'authStore'
)(applicationRouter(withLocalization(withRemoveDialog(observer(ClientsList)))));
