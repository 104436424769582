const API_ROOT =
    window.location.hostname === 'localhost' && window.location.port ? 'http://localhost:3000/api' : '/api';

const SOCKET_ROOT = window.location.hostname === 'localhost' && window.location.port ? 'http://localhost:3000/' : '/';

const LOCALES_ROOT =
    window.location.hostname === 'localhost' && window.location.port ? 'http://localhost:3000/locales' : '/locales';

const UPLOADS_ROOT =
    window.location.hostname === 'localhost' && window.location.port ? 'http://localhost:3000/uploads' : '/uploads';

const UPLOADS_API_ENDPOINT =
    window.location.hostname === 'localhost' && window.location.port
        ? 'http://localhost:3000/api/attachments'
        : '/api/attachments';


const APPLE_STORE_LINK = "https://apps.apple.com/us/app/tc-timecontrol/id6446083994";
const GOOGLE_PLAY_STORE_LINK = "https://play.google.com/store/apps/details?id=com.tidogkontroll.tnc";

const SERVER_IS_NORWAY = false;

const redirectToAppStore = (event, store) => {
    event.preventDefault();
    window.open(store === 'apple' ? APPLE_STORE_LINK : GOOGLE_PLAY_STORE_LINK, '_blank');
}

const __config__ = {
    API_ROOT,
    UPLOADS_ROOT,
    UPLOADS_API_ENDPOINT,
    LOCALES_ROOT,
    SOCKET_ROOT,
    SERVER_IS_NORWAY,
    redirectToAppStore
};

export default __config__;