import React, { Component } from 'react';
import applicationRouter from '~/hoc/applicationRouter'
import { inject, observer } from 'mobx-react';
import { Row, Col, Tab } from 'react-bootstrap'
import withLocalization from '~/hoc/withLocalization';
import PayrollIntegrations from './PayrollIntegrations';

class Integrations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subTab: 'payroll_integrations',
        }
        this.handleSubTabChange = this.handleSubTabChange.bind(this);
    }

    handleSubTabChange = (tabName) => {
        this.setState({ subTab: tabName })
    }

    render() {
        const { subTab } = this.state;
        const { t } = this.props;
        return (
            <Tab.Container activeKey={subTab} id="BasicTabContainer" onSelect={(key) => { }} >
                <div className="custom-card">
                    <Row>
                        <Col sm={3}>
                            <ul className="list-group">
                                <li className={subTab === 'payroll_integrations' ? 'list-group-item active cursor-pointer' : 'list-group-item cursor-pointer'}
                                    onClick={() => this.handleSubTabChange('payroll_integrations')}>{t('Payroll Integration')}</li>
                            </ul>
                        </Col>
                        <Col sm={9}>
                            <Tab.Content>
                                <Tab.Pane eventKey="payroll_integrations">
                                    <PayrollIntegrations />
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </div>
            </Tab.Container>
        );
    }
}
export default inject('clientStore', 'commonStore', 'userStore')(applicationRouter(withLocalization(observer(Integrations))));
