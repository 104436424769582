import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment/min/moment-with-locales';

//components
import applicationRouter from '~/hoc/applicationRouter';
import withRemoveDialog from '~/hoc/withRemoveDialog';
import withLocalization from '~/hoc/withLocalization';
import GenericList from '~/components/GenericList/GenericList';
import GenericFilter from '../../../components/GenericList/GenericFilter.js';

//services
import { serverTimeFormat } from '~/library/core';

//elements
import Button from '../../../components/CustomButton/CustomButton.jsx';

//assets
import deleteIcon from '../../../assets/img/deleting.svg';

//styles
import '../../../styles/css/styles.css';

//this component for listing visitors//

class VisitorTable extends Component {
    state = {
        clientOptions: [],
    };

    load_data = async () => {
        const { clientStore, t } = this.props;
        const clients = await clientStore.loadList({});
        const _options = clients.rows.map((obj) => {
            return { value: obj.id, label: obj.name };
        });
        _options.unshift({ value: '', label: t('All') });
        this.setState({ clientOptions: _options });
    };

    componentDidMount() {
        this.load_data();
    }

    handleTableActionButtonAction = (id, type) => {
        if (type === 'delete') {
            //delete Visitor
            this.removeVisitor(id);
        }
    };

    //calls action from visitor to delete visitor with id//
    removeVisitor(id) {
        const { commonStore, t } = this.props;

        return this.props.handleDelete(id, (id) => {
            return this.props.visitorStore.deleteVisitorById(id).then((response) => {
                if (response) {
                    commonStore.addNotification(t('Visitor Removed'), null, 'error');
                    this.props.visitorStore.resetLastListLoadTime();
                }
                return response;
            });
        });
    }

    renderTableActionButtons = (id) => {
        //renders table edit delete buttons in generic list//
        return (
            <div className="actions-center actions-center__center">
                <Button icon_sm_delete fill onClick={() => this.handleTableActionButtonAction(id, 'delete')}>
                    <img src={deleteIcon} alt="delete button" />
                </Button>
            </div>
        );
    };

    render() {
        //renders document_template list using generic list along with documentFilter//

        const { filters, loading, appliedFilters } = this.props.visitorStore;
        const { user_type } = this.props.userStore.currentUser;
        const { t } = this.props;
        const { clientOptions } = this.state;
        return (
            <>
                {user_type === 'super-admin' ? (
                    <div className="table-list">
                        <div className="row-flex input-area__content justify-content-center">
                            <p className="text-danger">{t('Data Prior To 14 Days Will Be Deleted By The System')}</p>
                        </div>

                        <GenericList
                            columns={[
                                {
                                    Header: 'Date',
                                    id: 'created_at',
                                    accessor: ({ created_at }) => (
                                        <span>{created_at && moment(created_at).format(serverTimeFormat())}</span>
                                    ),
                                },
                                {
                                    Header: 'Visitor Name',
                                    id: 'full_name',
                                    accessor: ({ full_name }) => <span>{full_name}</span>,
                                },
                                {
                                    Header: 'Phone',
                                    id: 'phone',
                                    accessor: ({ phone }) => <span>{phone}</span>,
                                },
                                {
                                    Header: 'Business Name',
                                    id: 'visitor_business_name',
                                    accessor: ({ visitor_business_name }) => <span>{visitor_business_name}</span>,
                                },
                                {
                                    Header: 'Client',
                                    id: 'client_name',
                                    accessor: ({ client_name }) => <span>{client_name}</span>,
                                },
                                {
                                    Header: 'Actions',
                                    id: 'actions',
                                    accessor: (full) => this.renderTableActionButtons(full.id),
                                },
                            ]}
                            filters={appliedFilters}
                            lastListLoadTime={this.props.visitorStore.lastListLoadTime}
                            header={
                                <GenericFilter
                                    filters={filters}
                                    setFilter={(name, value) => this.props.visitorStore.setFilter(name, value)}
                                    onFilter={() => this.props.visitorStore.onFilter()}
                                    loading={loading}
                                    hasDateFilters={true}
                                    hasClientFilter={true}
                                    clientOptions={clientOptions}
                                />
                            }
                            requestData={(params) => this.props.visitorStore.getVisitors(params)}
                        />
                    </div>
                ) : (
                    <div>
                        <div className="row-flex input-area__content justify-content-center">
                            <h4 className="text-danger">{t('Unauthorized access! Please Contact Administrator.')}</h4>
                        </div>
                    </div>
                )}
            </>
        );
    }
}

export default inject(
    'userStore',
    'commonStore',
    'visitorStore',
    'clientStore'
)(applicationRouter(withLocalization(withRemoveDialog(observer(VisitorTable)))));
