import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import { Tab, Tabs } from 'react-bootstrap';

import { Input, Textarea } from '../../../elements/Input';
import withLocalization from '../../../hoc/withLocalization';

@inject('commonStore')
@withLocalization
@observer
class DeviationConfigWidgetInternal extends Component {
    saveChange = (key, lang, newValue) => {
        const { value, onChange } = this.props;
        const _value = value ? JSON.parse(value) : {};
        const row = _.find(_value, r => r.key === key);
        const trans = _.find(row.translations, r => r.lang === lang);
        if (!trans) {
            row.translations.push({ lang, value: newValue });
        } else {
            trans.value = newValue;
        }
        onChange(JSON.stringify(_value));
    };

    renderSingleString(string, fullValue, lang) {
        const row = _.find(fullValue, r => r.key === string.key);
        const getTranslation = lang => {
            const r = _.find(row.translations, r => r.lang === lang);
            return r || { value: string.default };
        };
        if (row.isRichText) {
            return (
                <Textarea
                    className="custom-form__col"
                    label={_.startCase(string.key)}
                    placeholder={_.startCase(string.key)}
                    value={getTranslation(lang).value}
                    onChange={(e, v) => this.saveChange(string.key, lang, e.target.value)}
                />
            );
        }
        return (
            <Input
                className="custom-form__col"
                label={_.startCase(string.key)}
                placeholder={_.startCase(string.key)}
                type="text"
                value={getTranslation(lang).value}
                onChange={(e, v) => this.saveChange(string.key, lang, e.target.value)}
            />
        );
    }

    render() {
        const { value } = this.props;
        const _value = value ? JSON.parse(value) : [];
        const { commonStore } = this.props;

        const strings = commonStore.config.deviationStrings;
        return (
            <Tabs id="tabs">
                {strings.languages.map(lang => (
                    <Tab eventKey={lang} title={lang.toUpperCase()} key={lang}>
                        <div className="custom-form">
                            {Object.keys(strings.knownStrings).map(key => (
                                <div className="custom-form__row" key={key}>
                                    {this.renderSingleString(strings.knownStrings[key], _value, lang)}
                                </div>
                            ))}
                        </div>
                    </Tab>
                ))}
            </Tabs>
        );
    }
}

export default inject('commonStore')(withLocalization(observer(DeviationConfigWidgetInternal)));
