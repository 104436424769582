import React, { Component } from 'react';
import applicationRouter from '~/hoc/applicationRouter'
import { inject, observer } from 'mobx-react';

import TasksList from './TasksList';

import withRemoveDialog from '~/hoc/withRemoveDialog';

class TasksPage extends Component {
    constructor(props) {
        super(props)
        this.onAdd = this.onAdd.bind(this)
    }
    onAdd(page = 0, isEdit = true) {
        this.props.router.navigate('/admin/tasks/add', { state: { page, isEdit } });
    }
    componentWillMount() {
        const params = new URLSearchParams(this.props.router.location.search);
        const filterByProject = params.get('project');
        this.props.taskStore.setFilter('project', filterByProject || null);
        this.props.taskStore.onFilter();
    }

    render() {
        return (
            <TasksList
                onTableAction={(id, type) => this.onTableAction(id, type)}
                onAdd={this.onAdd} />
        );
    }
}
export default inject('authStore', 'taskStore')(applicationRouter(withRemoveDialog(observer(TasksPage))));
