import React, { Component } from 'react';
import Select from '../../../../elements/Select';

import signWidgetIcon from '../../../../assets/img/sign-widget-icon.svg';
import initialWidgetIcon from '../../../../assets/img/initial-widget-icon.svg';
import textboxWidgetIcon from '../../../../assets/img/textbox-widget-icon.svg';
import labelWidgetIcon from '../../../../assets/img/label-widget-icon.svg';
import recipientboxWidgetIcon from '../../../../assets/img/recipient-widget-icon.svg';
import dateboxWidgetIcon from '../../../../assets/img/date-widget-icon.svg';
import dropdownWidgetIcon from '../../../../assets/img/dropdown-widget-icon.svg';
import checkboxWidgetIcon from '../../../../assets/img/checkbox-widget-icon.svg';
import radioWidgetIcon from '../../../../assets/img/radiobutton-widget-icon.svg';
import { observer, inject } from 'mobx-react';
import withLocalization from '../../../../hoc/withLocalization';
import rightArrow from '../../../../assets/img/arrow-pointing-to-right.svg';
import _, { property } from 'lodash';
import { options } from 'superagent';


export class PropertyBox extends Component {
    constructor(props) {
        super(props);

        this.state = {
            widgetId: '',
            placeholder: '',
            required: true,
            recipient: '',
            labelTxt: '',
            options: [],
            checkBoxes: [],
            radioButtons: []

        }
    }

    static getDerivedStateFromProps(props, state) {
        const { propertyStore, recipientStore } = props
        const widget = propertyStore.activeWidget;
        if (widget.widgetId !== state.widgetId) {

            const currentRecipient = recipientStore.recipientsList.find((rec) => rec.id === widget.recipientId);
            return {
                widgetId: widget.widgetId,
                placeholder: widget.placeholder,
                required: widget.required,
                // recipient: { value: currentRecipient.id, label: currentRecipient.user_name },
                recipient: { value: currentRecipient.id, label: (<div style={{display: "flex",alignItems: 'center'}}><div className="recipient_color_dot" style={{backgroundColor: propertyStore.activeRecipient.color_code}}></div>{currentRecipient.user_name}</div>) },
                labelTxt: widget.data,
                options: widget.options ? widget.options : [],
                checkBoxes: widget.checkBoxes ? widget.checkBoxes : [],
                radioButtons: widget.radioButtons ? widget.radioButtons : []
            }
        }

        return null

    }

    onChange = (rec) => {
        this.setState({ recipient: rec });
    }
    generateTimeStempNumber() {
        let now = new Date();
        let timestamp = now.getFullYear().toString(); // 2022
        timestamp += (now.getMonth < 9 ? '0' : '') + now.getMonth().toString(); // JS months are 0-based, so +1 and pad with 0's
        timestamp += ((now.getDate < 10) ? '0' : '') + now.getDate().toString();
        timestamp += ((now.getDate < 10) ? '0' : '') + now.getHours().toString();
        timestamp += ((now.getDate < 10) ? '0' : '') + now.getSeconds().toString();

        return timestamp;

    }
    onCheckLabelChange(evt, id) {
        const data = this.state.checkBoxes;
        const index = data.findIndex((ele) => ele.id === id);
        data[index].label = evt.target.value;
        this.setState({ checkBoxes: data });

    }
    onRadioLabelChange(evt, id) {
        const data = this.state.radioButtons;
        const index = data.findIndex((ele) => ele.id === id);
        data[index].label = evt.target.value;
        this.setState({ radioButtons: data });
    }
    deleteWidget(id) {
        const { propertyStore, signatureStore } = this.props;
        propertyStore.deleteWidget(id);
        propertyStore.closeActivePanel();
        const data = { caseId: signatureStore.caseId, widgets: propertyStore.widgets };
        propertyStore.saveWidget(data);
    }
    onPlaceHolderChange = (evt, id) => {
        this.setState({ placeholder: evt.target.value });
    }
    onLabelTxtChange = (evt) => {
        this.setState({ labelTxt: evt.target.value });
    }
    onCheckChange(evt) {
        this.setState({ required: !this.state.required });
    }
    onPropertyChange = () => {

        const { propertyStore, recipientStore, signatureStore } = this.props
        const value = _.cloneDeep(this.state);
        const recipients = recipientStore.recipientsList ? recipientStore.recipientsList : []
        const recipient = recipients.find((rec) => rec.id == this.state.recipient.value)
        if (recipient) {
            value.colorCode = recipient.color_code;
            value.recipientId = recipient.id;
            if (propertyStore.activeWidget.name === "Recipient") {
                value.data = recipient.user_name;
            } else if (propertyStore.activeWidget.name === "Label") {
                value.data = value.labelTxt;
            }

        }
        propertyStore.updateWidgetProperty(value);
        const data = { caseId: signatureStore.caseId, widgets: propertyStore.widgets };
        propertyStore.saveWidget(data);
        propertyStore.closeActivePanel();

    }
    addOptionInput() {
        const opt = this.state.options;
        opt.push("Enter Text");
        this.setState({ options: opt });

    }
    deleteWidgetCheckbox(evt,id){
        const { propertyStore } = this.props
        const data = this.state.checkBoxes.filter((ele)=> ele.id !== id)
        propertyStore.updateWidgetData(propertyStore.activeWidget,'checkBoxes',data)
        this.setState({checkBoxes: data});
    }
    deleteWidgetRadiobtn(evt,id){
        const { propertyStore } = this.props
        const data = this.state.radioButtons.filter((ele)=> ele.id !== id)
        propertyStore.updateWidgetData(propertyStore.activeWidget,'radioButtons',data)
        this.setState({radioButtons: data});
    }
    addCheckboxInput(){
        const { propertyStore } = this.props
        const chkBox = [...this.state.checkBoxes];
        chkBox.push({
            id: this.generateTimeStempNumber(),
            label: `Checkbox ${this.generateTimeStempNumber()}`,
            value: false
        });
        propertyStore.updateWidgetData(propertyStore.activeWidget,'checkBoxes', chkBox)
        this.setState({ checkBoxes: chkBox  });
    }
    addRadioBtnInput() {
        const { propertyStore } = this.props
        const radioBtn = [...this.state.radioButtons];
        radioBtn.push({
            id: this.generateTimeStempNumber(),
            label: `Radio Button ${this.generateTimeStempNumber()}`,
            value: false
        });
        propertyStore.updateWidgetData(propertyStore.activeWidget,'radioButtons',radioBtn)
        this.setState({ radioButtons: radioBtn  });

    }
    onOptionTxtChange(evt, index) {
        const options = [...this.state.options];
        options[index] = evt.target.value;
        this.setState({ options: options });

    }
    prepareOptions = array => {

        const result = array ? array.filter((rec)=> rec.signing_type !== "Send Copy").map(rec => {
            return { value: `${rec.id}`, label:(<div style={{display: "flex",alignItems: 'center'}}><div className="recipient_color_dot" style={{backgroundColor: rec.color_code}}></div>{rec.user_name}</div>)};
        })
            : [];


        return result;
    };

    switchToPage = () => {
        const { propertyStore } = this.props
        propertyStore.closeActivePanel()
    }

    generateBoxTitle(name) {
        const {t} = this.props;
        if (name === "Signature") {
            return (
                <>
                    <img src={signWidgetIcon} alt="signature icon" />
                    <span>{t('Signature')}</span>
                </>
            )
        } else if (name === "Text") {
            return (<>
                <img src={textboxWidgetIcon} alt="textbox icon" />
                <span>{t('Textbox')}</span>
            </>
            )
        } else if (name === "Initial") {
            return (<>
                <img src={initialWidgetIcon} alt="textbox icon" />
                <span>{t('Initial')}</span>
            </>)
        } else if (name === "Date") {
            return (<>
                <img src={dateboxWidgetIcon} alt="date icon" />
                <span>{t('Date')}</span>
            </>)
        } else if (name === "Recipient") {
            return (<>
                <img src={recipientboxWidgetIcon} alt="recipient icon" />
                <span>{t('Recipient')}</span>
            </>)
        } else if (name === "Label") {
            return (<>
                <img src={labelWidgetIcon} alt="label icon" />
                <span>{t('Label')}</span>
            </>)
        } else if (name === "Select") {
            return (<>
                <img src={dropdownWidgetIcon} alt="select box icon" />
                <span>{t('Select Box')}</span>
            </>)
        } else if (name === "Checkbox") {
            return (<>
                <img src={checkboxWidgetIcon} alt="check box icon" />
                <span>{t('Check Box')}</span>
            </>)
        } else if (name === "Radio") {
            return (<>
                <img src={radioWidgetIcon} alt="radio icon" />
                <span>{t('Radio Box')}</span>
            </>)
        }

    }


    generateBoxFields(widget, recipientStore, name) {
        const {t} = this.props;
        const options = recipientStore.recipientsList ? this.prepareOptions(recipientStore.recipientsList) : [];
        return (
            <>

                {(name === "Signature" || name === "Text" || name === "Initial") && <div className="requiredField">
                    <input type="checkbox" checked={this.state.required} onChange={(evt) => { this.onCheckChange(evt) }}></input>
                    <label>{t('Required Field')}</label>
                </div>
                }
                {
                    (name === "Text") && <div>
                        <label>{t('Placeholder:')}</label>
                        <input type="text"
                            value={this.state.placeholder}
                            placeholder="Enter Text" onChange={(evt) => { this.onPlaceHolderChange(evt, widget.widgetId) }}></input>
                    </div>

                }
                {

                    (name === "Label") && <div>
                        <label>{t('Text:')}</label>
                        <input type="text"
                            value={this.state.labelTxt}
                            placeholder="Enter Text" onChange={(evt) => { this.onLabelTxtChange(evt) }}></input>
                    </div>

                }
                {
                    (name === "Select") && <div>
                        <label>{t('options:')}</label>
                        {
                            this.state.options.map((value, index) => {
                                return (<input type="text"
                                    value={value}
                                    placeholder="Enter Text" onChange={(evt) => { this.onOptionTxtChange(evt, index) }}></input>)
                            })
                        }
                        <button onClick={() => { this.addOptionInput() }} style={{ fontSize: '1.5em', padding: '0 .5em', marginBottom: '1em' }} className="btn btn-fill">+</button>
                    </div>

                }

                {
                    (name === "Checkbox") && <div>
                        <label>{t('options:')}</label>
                        {
                            this.state.checkBoxes.map((value, index) => {
                                return (
                                    <div key={value.id}>
                                        <input type="text"
                                            value={value.label}
                                            placeholder="Enter Text" onChange={(evt) => { this.onCheckLabelChange(evt, value.id) }}></input>
                                        { this.state.checkBoxes.length !== 1 &&
                                            <button
                                                className="btn_action"
                                                type="button"
                                                style={{ marginRight: '15px', backgroundColor: 'red', marginBottom: '15px' }}
                                                onClick={(evt)=>this.deleteWidgetCheckbox(evt, value.id)}
                                            >
                                                <i className='fa fa-minus'></i>
                                            </button>
                                        }
                                    </div>)
                            })
                        }
                        <button onClick={() => { this.addCheckboxInput() }} style={{ fontSize: '1.5em', padding: '0 .5em', marginBottom: '1em' }} className="btn btn-fill">+</button>
                    </div>

                }
                {
                    (name === "Radio") && <div>
                        <label>{t('options:')}</label>
                        {
                            this.state.radioButtons.map((value, index) => {
                                return (
                                    <div key={value.id}>
                                        <input type="text"
                                            value={value.label}
                                            placeholder="Enter Text" onChange={(evt) => { this.onRadioLabelChange(evt, value.id) }}></input>
                                        { this.state.radioButtons.length !== 2 &&
                                            <button
                                                className="btn_action"
                                                type="button"
                                                style={{ marginRight: '15px', backgroundColor: 'red', marginBottom: '15px' }}
                                                onClick={(evt)=>this.deleteWidgetRadiobtn(evt, value.id)}
                                            >
                                                <i className='fa fa-minus'></i>
                                            </button>
                                        }
                                    </div>)
                            })
                        }
                        <button onClick={() => { this.addRadioBtnInput() }} style={{ fontSize: '1.5em', padding: '0 .5em', marginBottom: '1em' }} className="btn btn-fill">+</button>
                    </div>

                }
                <div className="recDropDown">
                    <Select
                        value={this.state.recipient}
                        options={options}
                        onChange={(rec) => { this.onChange(rec) }}
                    />
                </div>

            </>
        );
    }
    switchToPage = () => {
        const { propertyStore } = this.props
        propertyStore.closeActivePanel();
    }
    componentWillUnmount() {
        this.switchToPage();
    }
    render() {
        const { recipientStore, propertyStore ,t} = this.props
        const widget = propertyStore.activeWidget;
        const name = widget.name;

        return (
            <>
                <div className="propCon">
                    <div className="propNav">
                        <div>
                            <img src={rightArrow} alt="right arrow" onClick={this.switchToPage} />
                            <span>{t('Back to PDF')}</span>
                        </div>
                    </div>
                    <div className="propContent">
                        <div className="propTitle">
                            {this.generateBoxTitle(widget.name)}
                        </div>
                        <div className="propField">
                            {this.generateBoxFields(widget, recipientStore, name)}

                        </div>
                        <div className="actionbtns">
                            <button className="btn btn-fill" onClick={this.onPropertyChange}>{t('Save')}</button>
                            <button className="btn" onClick={() => { this.switchToPage() }} >{t('Cancel')}</button>
                        </div>
                        <div className="dlt-btn">
                            <button className="btn btn-danger btn-fill" onClick={() => { this.deleteWidget(widget.widgetId) }}>{t('Delete')}</button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default inject('propertyStore', 'recipientStore', 'signatureStore')(withLocalization(observer(PropertyBox)));