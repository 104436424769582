import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import moment from 'moment/min/moment-with-locales';
import { inject, observer } from 'mobx-react';
import applicationRouter from '~/hoc/applicationRouter'
import withLocalization from '~/hoc/withLocalization';


export class ShowNotifications extends Component {
    renderRow(row) {
        const { commonStore } = this.props;
        let dateTimeRules = commonStore.config.client && commonStore.config.client.data &&
            commonStore.config.client.data.dateTimeRules ?
            { ...commonStore.config.client.data.dateTimeRules }
            : { short_date_format: "DD.MM.YYYY", time_format: "hh:mm" };
        return (
            <Link
                to={`/admin/${row?.data?.context?.source || ''}/${row?.data?.context?.id || ''}`}
                className={`notification-item ${row?.is_unread ? "" : "text-muted"}`}
                onClick={() => this.closeNotification("notifications", row.id)}
            >
                <div className={`notification-item ${row?.is_unread ? "unread" : "read"}`}></div>
                <div className="notify-symbol"><i className="btn btn-danger">{row?.data?.context?.source?.split("")[0]?.toUpperCase() || "N"}</i></div>
                <div className="notify-text mx-2">
                    <p className='fw-bold'>{row.message}</p>
                    <span className=''>{moment(new Date(row.created_at)).format(dateTimeRules.short_date_format)}</span>
                    <span className='mx-1'>{moment(new Date(row.created_at)).format(dateTimeRules.time_format)}</span>
                </div>
            </Link>
        );
    }

    closeNotification(type, id) {
        if (type === "notifications") this.props.userStore.hideNotificationDock();
        else this.props.userStore.hideMessageNotificationDock();
        this.props.userStore.readNotifications(id);
    }

    renderMessageRow(row) {
        const { commonStore } = this.props;
        let dateTimeRules = commonStore.config.client && commonStore.config.client.data &&
            commonStore.config.client.data.dateTimeRules ?
            { ...commonStore.config.client.data.dateTimeRules }
            : { short_date_format: "DD.MM.YYYY", time_format: "hh:mm" };
        return (
            row?.data?.context?.source === 'chat' ?
                (
                    <>
                        <Link
                            to={`/admin/chat/${row?.data?.context?.room}` || ""}
                            className={`notification-item ${row?.is_unread ? "" : "text-muted"}`}
                            onClick={() => this.closeNotification("messages", row.id)}
                        >
                            <div className={`notification-item ${row?.is_unread ? "unread" : "read"}`}></div>
                            <div className="notify-symbol"><i className="btn btn-danger">{row?.data?.context?.projectName?.replace('and', '').split(" ").map(obj => obj[0]).join('').substring(0, 3).toUpperCase() || "M"}</i></div>
                            <div className="chat-notifications-list mx-2">
                                <p className='room-name fw-bold'>
                                    {row?.data?.context?.projectName ? `${row.data.context.projectName} : ` : ""}
                                    <span className="msg text-break">{row.message}</span>
                                </p>
                                <span className='timestamp fw-bold'>{moment(new Date(row.created_at)).format(dateTimeRules.short_date_format)}</span>
                                <span className='timestamp fw-bold mx-1'>{moment(new Date(row.created_at)).format(dateTimeRules.time_format)}</span>
                            </div>
                        </Link>
                    </>
                ) :
                (
                    <>
                        {
                            <Link
                                to={row?.data?.context?.isChatRoomDeleted ? "" : `/admin/privatechat/${row?.data?.context?.room || ''}`}
                                className={`notification-item ${row?.is_unread ? "" : "text-muted"}`}
                                onClick={() => this.closeNotification("messages", row.id)}
                            >
                                <div className={`notification-item ${row?.is_unread ? "unread" : "read"}`}></div>
                                <div className="notify-symbol"><i className="btn btn-danger">{row?.data?.context?.roomName?.replace('and', '').split(" ").map(obj => obj[0]).join('').substring(0, 3).toLowerCase() || "M"}</i></div>
                                <div className="chat-notifications-list mx-2">
                                    <p className='room-name fw-bold'>
                                        {row?.data?.context?.roomName ? `${row?.data?.context?.roomName} : ` : ""}
                                        <span className="msg text-break">{row.message}</span>
                                    </p>
                                    <span className='timestamp fw-bold'>{moment(new Date(row.created_at)).format(dateTimeRules.short_date_format)}</span>
                                    <span className='timestamp fw-bold mx-1'>{moment(new Date(row.created_at)).format(dateTimeRules.time_format)}</span>
                                </div>
                            </Link>
                        }
                    </>
                )
        );
    }
    render() {
        const { userStore, t, notificationType } = this.props;
        if (!userStore.dockNotifications) return null;
        if (!userStore.messageNotifications) return null;
        return (
            <>
                {
                    notificationType === "notifications" ?
                        (
                            <>
                                {userStore.dockNotifications.map(row => (
                                    <div key={row.id}>
                                        <div>{this.renderRow(row)}</div>
                                    </div>
                                ))}
                            </>
                        )
                        :
                        (
                            <>
                                {userStore.messageNotifications.map(row => (
                                    <div key={row.id}>
                                        <div>{this.renderMessageRow(row)}</div>
                                    </div>
                                ))}
                            </>
                        )
                }
            </>
        )
    }
}

export default inject('commonStore', 'userStore')(applicationRouter(withLocalization(observer(ShowNotifications))));
