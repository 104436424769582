import { observable, action, makeObservable } from 'mobx';
import agent from '../agent';

class SubscriptionStore {
    constructor() {
        makeObservable(this, {
            currentList: observable,
            currentEntity: observable,
            updatingErrors: observable,
            updating: observable,
            loading: observable,
            requestParams: observable,
            deleteSuccess: observable,
            lastListLoadTime: observable,
            resetLastListLoadTime: action,
            filters: observable,
            appliedFilters: observable,
            setFilter: action,
            onFilter: action,
            loadList: action,
            save: action,
            load: action,
            remove: action
        });
    }

    currentList = [];

    currentEntity = {};

    updatingErrors = null;

    updating = false;

    loading = false;

    requestParams = null;

    deleteSuccess = false;

    lastListLoadTime = null;

    resetLastListLoadTime() {
        this.lastListLoadTime = new Date();
    }
    filters = {
        status: '',
        name: '',
    };

    appliedFilters = {
        status: '',
        name: '',
    };

    setFilter(name, value) {
        const filters = Object.assign({}, this.filters);
        filters[name] = value;
        this.filters = filters;
    }

    onFilter() {
        this.appliedFilters = Object.assign({}, this.filters);
    }

    loadList(params) {
        return agent.subscriptions.list(params)
            .then(
                action(list => {
                    this.requestParams = params;
                    list.time = new Date();
                    this.lastListLoadTime = list.time;
                    this.currentList = list;
                    return list;
                })
            )
            .catch(
                action(err => {
                    throw err;
                })
            );
    }

    save(values, isAdd) {
        this.updating = true;
        return agent.subscriptions.save(values, isAdd)
            .then(
                action(data => {
                    this.currentEntity = data;
                    return data;
                })
            )
            .catch(
                action(err => {
                    this.updating = false;
                    this.updatingErrors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            );
    }

    load(id) {
        this.loading = true;
        return agent.subscriptions.load(id)
            .then(
                action(response => {
                    this.currentEntity = response
                    this.loading = false;
                    return response;
                })
            )
            .catch(
                action(err => {
                    this.loading = false;
                    throw err;
                })
            );
    }

    async remove(id) {
        await agent.subscriptions.remove(id);
        this.deleteSuccess = true;
        this.resetLastListLoadTime()
        return 1;
    }
}

const _SubscriptionStore = new SubscriptionStore();
export default _SubscriptionStore;