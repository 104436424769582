import React from 'react';
import PropTypes from 'prop-types';
import constants from '../constants';

const { STATUS_TEXTS } = constants;

const StatusBox = ({ status }) => (
    <div className={`status-box status-box_default status-box_${status}`}>{STATUS_TEXTS[status] || status}</div>
);

StatusBox.propTypes = {
    status: PropTypes.string.isRequired,
};

export default StatusBox;
