import React, { Component } from 'react';

//components
import ExtraDocList from './ExtraDocsList';

//utils
import customerFeature from '../../../utils/customerFeature';

class ExtraDocPage extends Component {


    render() {
        const docMode = this.props.mode;
        const isUnionMode = customerFeature('union_group');
        return (
            <>
                <ExtraDocList
                    mode={docMode}
                    isUnion={isUnionMode}
                />

            </>
        );
    }
}

export const ExtraDocsContracts = props => <ExtraDocPage {...props} mode="contracts" />;
export const ExtraDocsInternal = props => <ExtraDocPage {...props} mode="internal" />;
export default ExtraDocPage;
