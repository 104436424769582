/* eslint-disable react/jsx-no-target-blank */
import React from 'react';

import { socialLinks } from '../../utils/constants';
import './styles.css';

//i18n
import { withTranslation } from 'react-i18next';

function Social(props) {
    return (
        <div className={`sclIcons ${props.Icon}`}>
            <span className='fw-bold'>{props.t('Social Media')}</span>
            <a href={socialLinks.instagram} target="_blank">
                <i className="fab fa-brands fab fa-instagram" aria-hidden="true"></i>
            </a>
            <a href={socialLinks.linkedin} target="_blank">
                <i className="fab fa-linkedin" aria-hidden="true"></i>
            </a>
        </div>
    );
}
export default withTranslation()(Social);
