import React from "react";
import { Container } from "react-bootstrap";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide, } from 'swiper/react';
import { v4 as uuid } from 'uuid';

//components
import MainHeading from "../MainHeading";
import TestiBox from "../TestimonialItem";

//assets
import ClientLogos from "./clientLogos";

//i18n
import { withTranslation } from "react-i18next"

//styles
import 'swiper/swiper.min.css';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import 'swiper/scss/scrollbar';

const renderSwiperCards = () => {
  return ClientLogos.map(clientLogo => (
    <SwiperSlide key={uuid()}>
      <TestiBox
        clientLogo={clientLogo}
        clientName={`${clientLogo.split('client_logo-')[1]} logo`}
      />
    </SwiperSlide>
  ))
}

function Testimonial(props) {
  return (
    <>
      <article className="testimonialBlock py-80">
        <Container>
          <span className="circlePatt circle1"></span>
          <span className="circlePatt circle2"></span>
          {/* <div className="hdngTag orange text-center mb-2">
            <span>Testimonials</span>
          </div> */}
          <MainHeading
            title={<><span>{props.t("Trusted by 1000's")}</span><br />{props.t("of clients worldwide")}</>}
            customClass="text-center medium noPara"
          />
        </Container>
        <div className="testiSLider crslCntrl">
          <Swiper modules={[Autoplay, Navigation, Pagination, Scrollbar, A11y]}
            navigation spaceBetween={40} slidesPerView={6} centeredSlides={true} loop={true}
            autoplay={{
              delay: 1000,
              disableOnInteraction: false,
            }}
            breakpoints={{
              280: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              320: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              576: {
                slidesPerView: 4,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 5,
                spaceBetween: 20,
              },
              992: {
                slidesPerView: 6,
                spaceBetween: 30,
              },
              1200: {
                slidesPerView: 7,
                spaceBetween: 30
              },
              1400: {
                slidesPerView: 8,
                spaceBetween: 30,
              }
            }}
          >
            {renderSwiperCards()}
          </Swiper>
        </div>
      </article>
    </>
  );
}
export default withTranslation()(Testimonial);