import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

//components
import withLocalization from '~/hoc/withLocalization';
import applicationRouter from '~/hoc/applicationRouter';
import TableWidget from '../TableWidget.js';


class ProjectRules extends Component {
    constructor(props) {
        super(props);
        this.state = {
            projectRules: [],
        }
        this.loadData = this.loadData.bind(this);
        this.handleUserDataChange = this.handleUserDataChange.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = () => {
        const { currentEntity } = this.props.clientStore;
        if (currentEntity.client.data && currentEntity.client.data.project_statuses) {
            const project_statuses = currentEntity.client.data.project_statuses;
            this.setState({
                projectRules: project_statuses
            })
        }
    }

    handleUserDataChange = (name, value) => {
        const { currentEntity } = this.props.clientStore;
        if (currentEntity.client.data.project_statuses) {
            currentEntity.client.data.project_statuses = value;
        }
    }

    render() {
        const { projectRules } = this.state;
        const { t } = this.props;
        return (
            <div>
                <div className="mt-2 custom-card">
                    <h6>{t('Project statuses')}</h6>
                    <TableWidget
                        headers={['code', 'Label']}
                        metaData={[{ field: 'code', type: 'text', placeholder: 'code', required: true, unique: true },
                        { field: 'name', type: 'text', placeholder: 'Label', required: true, unique: true }]}
                        data={projectRules}
                        onChange={data => this.handleUserDataChange('projectRules', data)}
                    />
                </div>
            </div>
        );
    }
}
export default inject('clientStore')(applicationRouter(withLocalization(observer(ProjectRules))));
