import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { find } from 'lodash';
import { v4 as uuid } from 'uuid';

import Select from '../../../elements/Select';
import withLocalization from '../../../hoc/withLocalization';

const WrapperMobx = inject('userStore', 'absenceStore')(withLocalization(observer(class WrapperMobx extends Component {
    constructor(props) {
        super(props);
        this.state = {
            options: [],
            currentUser: 0,
        }
    }

    static getFormattedOptions = (options, translate) => {
        return options.map((option, index) => ({
            value: `${index}`,
            label: `${option.name}${option.chronic_disease === 'yes' ? ` [${translate('chronic')}]` : ''}`,
            full: option,
        }));
    };

    componentDidMount() {
        let options = this.props.userStore.currentUser?.data?.kids || [];
        options = JSON.parse(JSON.stringify(options));
        const userId = this.props.absenceStore.currentEntity.absence.user_id || 0;
        this.setState({ options: WrapperMobx.getFormattedOptions(options, this.props.t), currentUser: userId });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props?.absenceStore?.currentEntity?.absence.user_id &&
            this.props?.absenceStore?.currentEntity?.absence.user_id !== prevState.currentUser) {
            const currentUserId = this.props?.absenceStore?.currentEntity?.absence.user_id;
            this.props.absenceStore.getChildrenDetails(currentUserId).then(kids => {
                let options = kids || [];
                options = JSON.parse(JSON.stringify(options));
                this.setState({
                    options: WrapperMobx.getFormattedOptions(options, this.props.t),
                    currentUser: currentUserId
                });
            });
        }
    }

    componentWillUnmount() {
        this.setState({ options: [], currentUser: 0 });
    }

    render() {
        const { value, onChange, t } = this.props;
        const { options } = this.state;
        const currentSelect = find(options, o => {
            return String(o.value) === String(value);
        });
        return (
            <div className="specail-select-widget">
                {!options.length &&
                    <div className='font-size-12'>
                        <b>{t("Note")}:&nbsp;</b>
                        {t("No children details found, Please add under profile")}
                    </div>
                }
                <Select
                    key={uuid()}
                    options={options}
                    value={currentSelect}
                    onChange={o => {
                        onChange(o.value);
                    }}
                    style={{ width: '100%' }}
                />

            </div >
        );
    }
})));

export const KidsSelect = observer(class KidsSelect extends Component {
    render() {
        return <WrapperMobx {...this.props} />;
    }
});
