const statuses = [
    { code: 'active', name: 'Pending' },
    { code: 'pending_today', name: 'Pending Today' },
    { code: 'auto', name: 'Auto' },
    { code: 'approved', name: 'Approved' },
    { code: 'rejected', name: 'Rejected' },
    { code: 'draft', name: 'At work' },
    { code: 'logs_today', name: 'Gone home' },
];

export const checklist_status  =[
    { code: 'New', name: 'New' },
    { code: 'Under work', name: 'Pending' },
    { code: 'Pending', name: 'Done' },
    { code: 'Approved', name: 'approved' },
];

export const users_statuses = [
    { code: 'working_now', name: 'At work' },
    { code: 'users_not_at_work', name: 'Not at work' },
];

export const statusMap = {
    active: 'Pending',
    auto: 'Auto',
    approved: 'approved',
    rejected: 'rejected',
    draft: 'At work',
};

export const leave_type_status = [
    {code: 'paid', name: 'Paid'},
    {code: 'unpaid', name: 'Unpaid'},   
]

export const absence_and_vacation_statuses = [
    {code: 'active', name: 'Pending'},
    {code: 'approved', name: 'Approved'},
    {code: 'rejected', name: 'Rejected'}
]


export default statuses;
