import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { find, cloneDeep, sortBy } from 'lodash';
import { Row, Col } from 'react-bootstrap';

//components
import withLocalization from '~/hoc/withLocalization';
import applicationRouter from '~/hoc/applicationRouter';
import LoadingSpinner from '~/elements/LoadingSpinner';

//elements
import { Input } from '../../../elements/Input';
import { RadioWidget } from '../../../components/GenericForm/Widgets/RadioWidget';
import CustomMultiSelect from '../../../elements/CustomMultiSelect';
import Button from '../../../components/CustomButton/CustomButton.jsx';
import { UsersFromUserGroupsWidget } from '../../../components/GenericForm/Widgets/UserGroupWidget.js';

//assets
import Edit from '../../../assets/img/editing.svg';
import Delete from '../../../assets/img/deleting.svg';

const timeframeOptions = {
    enumOptions: [
        { value: 'daily', label: 'daily' },
        { value: 'weekly', label: 'weekly' },
        { value: 'monthly', label: 'monthly' },
    ],
};

class ProjectMembersForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: null,
            members: null,
            editingRowId: null,
            usersToAdd: null,
            project: null,
            userGroups: ""
        };
        this.loadData = this.loadData.bind(this);
        this.getSortedMembers = this.getSortedMembers.bind(this);
        this.onSave = this.onSave.bind(this);
        this.getUsersToAdd = this.getUsersToAdd.bind(this);
        this.getUserNameById = this.getUserNameById.bind(this);
        this.handleChangeValue = this.handleChangeValue.bind(this);
        this.handleStartEdit = this.handleStartEdit.bind(this);
        this.handleStopEdit = this.handleStopEdit.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
        this.handleAdd = this.handleAdd.bind(this);
        this.handleAddAndDeleteByIdArrs = this.handleAddAndDeleteByIdArrs.bind(this);
        this.getAddedAndRemovedUsers = this.getAddedAndRemovedUsers.bind(this);
    }

    async loadData() {
        const { projectStore, id, add, userStore } = this.props;
        if (!id) return;
        const project = await projectStore.load(id, add);
        const users = await userStore.loadLookup('members', '');

        this.setState({
            users,
            members: this.getSortedMembers(cloneDeep(project.project.members), users),
            usersToAdd: this.getUsersToAdd(project.project.members, users),
            project: project.project
        });
    }

    getSortedMembers(members, users) {
        members.forEach((member) => {
            const user = find(users, (r) => r.id === member.user_id);
            member.fullname = user ? user.fullname : '???';
        });
        members = members.filter((obj) => obj.fullname !== '???');
        members = sortBy(members, (r) => r.fullname.toUpperCase());
        return members;
    }

    componentDidUpdate(previousProps) {
        const { id } = this.props;
        if (id !== previousProps.id) {
            this.loadData();
        }
    }

    componentDidMount() {
        this.loadData();
    }

    onSave() {
        const { projectStore, id, add, t, commonStore, afterSave } = this.props;
        return projectStore
            .save(
                { id, members: this.state.members },
                add
            )
            .then((result) => {
                if (!result.project || !result.project.id) {
                    commonStore.addNotification(t(result.message || 'Error'), null, 'error');
                    return false;
                }
                commonStore.addNotification(t('Saved'), null, 'success');
                afterSave();
                return true;
            });
    }

    getUsersToAdd(members, users) {
        const toAdd = [];
        const memberIds = [];
        members.forEach((member) => memberIds.push(member.user_id));
        users.forEach((user) => {
            if (memberIds.indexOf(user.id) >= 0) return;
            toAdd.push(user);
        });
        return toAdd;
    }

    getUserNameById(id) {
        const user = find(this.state.users, (r) => r.id === id);
        if (!user) return '???';
        return `${user.fullname}`;
    }

    handleChangeValue(rowIndex, name, value) {
        const members = cloneDeep(this.state.members);
        members[rowIndex][name] = value;
        this.setState({ members });
    }

    handleStartEdit(member) {
        this.setState({ editingRowId: member.user_id });
    }

    handleStopEdit(member) {
        this.setState({ editingRowId: null });
    }

    handleRemove(index) {
        const members = cloneDeep(this.state.members);
        members.splice(index, 1);
        this.setState({ members });
    }

    handleAdd(value) {
        const members = cloneDeep(this.state.members);
        members.unshift({
            user_id: parseInt(value),
            fullname: this.getUserNameById(parseInt(value)),
            additional_pay: 0,
            vacation_days: 25,
            hours: 40,
            timeframe: 'weekly',
        });
        const newToAdd = this.getUsersToAdd(members, this.state.users);
        this.setState({ members, editingRowId: parseInt(value), usersToAdd: newToAdd });
    }

    handleAddAndDeleteByIdArrs(addId, deleteId) {
        const members = cloneDeep(this.state.members).filter((member) => deleteId.indexOf(member.user_id) === -1);
        addId.forEach((id) => {
            members.unshift({
                user_id: parseInt(id),
                fullname: this.getUserNameById(parseInt(id)),
                additional_pay: 0,
                vacation_days: 25,
                hours: 40,
                timeframe: 'weekly',
            });
        });
        const newToAdd = this.getUsersToAdd(members, this.state.users);
        this.setState({ members, editingRowId: null, usersToAdd: newToAdd });
    }

    getAddedAndRemovedUsers = (existingMembers, selectedIds, project) => {
        const oldId = existingMembers && existingMembers.length
            ? existingMembers.map((member) => member.user_id)
            : [];
        const deletedId = oldId.filter((id) => id !== project.assignee_id && selectedIds.indexOf(id) === -1);
        const addedId = selectedIds.filter((id) => oldId.indexOf(id) === -1);
        return { deletedId, addedId };
    }

    checkExistingUsersAndUpdateGroupValues = (existingMembers, usersGroups, allOptions) => {
        const selectedGroups = [];
        const oldId = existingMembers && existingMembers.length
            ? existingMembers.map((member) => member.user_id)
            : [];
        if (!oldId.length) return;
        usersGroups.forEach(userGroup => {
            const usersInGroup = allOptions.filter(e => userGroup.userIds.includes(e.value));
            if (usersInGroup.every(e => oldId.includes(e.value)))
                selectedGroups.push(userGroup.value);
        });
        if (selectedGroups.length) {
            this.setState({ userGroups: selectedGroups.join(',') });
        }
    }

    render() {
        const { users, members, editingRowId, usersToAdd, project, userGroups } = this.state;
        const { id, t, commonStore } = this.props;
        const multiSelectOptions = [
            ...(members
                ? members.map
                    ? members.map((member) => ({ label: member.fullname, value: member.user_id, disabled: member.user_id === project.assignee_id }))
                    : []
                : []),
            ...(usersToAdd
                ? usersToAdd.map
                    ? usersToAdd.map((user) => ({ label: user.fullname, value: user.id, disabled: user.id === project.assignee_id }))
                    : []
                : []),
        ].sort((a, b) => a.label.localeCompare(b.label));
        const multiSelectSelected = members ? (members.map ? members.map((member) => member.user_id) : []) : [];
        if (!users || !members || !id) {
            return <LoadingSpinner />;
        }

        return (
            <div className="projectMemberFormMain">
                <Row className="gx-2 m-1">
                    <Col xs={12} md={6}>
                        <label className="control-label font-size-15 fw-bold">
                            {`${t('Select')} ${t('Employee')}`}
                        </label>
                        <CustomMultiSelect
                            options={multiSelectOptions || []}
                            value={multiSelectSelected || []}
                            onChange={(selectedIds) => {
                                const { deletedId, addedId } = this.getAddedAndRemovedUsers(members, selectedIds, project);
                                this.handleAddAndDeleteByIdArrs(addedId, deletedId);
                            }}
                        />
                    </Col>
                    <Col xs={8} md={4}>
                        <label className="control-label font-size-15 fw-bold">
                            {t('Assign employees from groups')}
                        </label>
                        <UsersFromUserGroupsWidget
                            value={userGroups}
                            onChange={(selected) => {
                                let userIdsInGroups = [];
                                selected.forEach(e => userIdsInGroups.push(...e.userIds));
                                userIdsInGroups = multiSelectOptions.filter(e => userIdsInGroups.includes(e.value)).map(e => e.value);
                                const { deletedId, addedId } = this.getAddedAndRemovedUsers(members, userIdsInGroups, project);
                                if (addedId?.length > 0) {
                                    commonStore.addNotification(t('Members from this group have been added to the project'), null, 'success');
                                    this.handleAddAndDeleteByIdArrs(addedId, []);
                                }
                                else if (deletedId?.length > 0) {
                                    this.handleAddAndDeleteByIdArrs([], deletedId);
                                    commonStore.addNotification(t('Removed group employees from the project'), null, 'warning');
                                }
                                this.setState({ userGroups: selected.map(e => e.value).join(',') });
                            }}
                            updateGroupBasedOnSelectedUsers={(groups) => this.checkExistingUsersAndUpdateGroupValues(members, groups, multiSelectOptions)}
                        />
                    </Col>

                    <Col xs={4} md={2} className='align-content-center align-content-md-end'>
                        <div className="text-end">
                            <Button fill onClick={() => this.onSave()}>
                                {t('Save')}
                            </Button>
                        </div>
                    </Col>
                </Row>
                <div className="projectMemberForm">
                    <table className="table table-hover projectMembersFormTable">
                        <thead>
                            <tr className="table-container-header text-center">
                                <th className='text-start'>{`${t('Employee')} ${t('name')}`}</th>
                                <th>{t('Additional payment')}</th>
                                <th>{t('Hours')}</th>
                                <th>{t('Timeframe')}</th>
                                <th>{t('Operations')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {members.map((member, rowIndex) => (
                                <tr key={`${member.user_id}`}>
                                    <td className='text-start'>{member.fullname}</td>
                                    <td>
                                        {member.user_id === editingRowId && (
                                            <Input
                                                value={member.additional_pay}
                                                onChange={(e) =>
                                                    this.handleChangeValue(rowIndex, 'additional_pay', e.target.value)
                                                }
                                            />
                                        )}
                                        {member.user_id !== editingRowId && <span>{member.additional_pay}</span>}
                                    </td>
                                    <td>
                                        {member.user_id === editingRowId && (
                                            <Input
                                                value={member.hours}
                                                onChange={(e) =>
                                                    this.handleChangeValue(rowIndex, 'hours', e.target.value)
                                                }
                                            />
                                        )}
                                        {member.user_id !== editingRowId && <span>{member.hours}</span>}
                                    </td>
                                    <td>
                                        {member.user_id === editingRowId && (
                                            <RadioWidget
                                                vertical
                                                options={timeframeOptions}
                                                value={member.timeframe}
                                                onChange={(value) =>
                                                    this.handleChangeValue(rowIndex, 'timeframe', value)
                                                }
                                            />
                                        )}
                                        {member.user_id !== editingRowId && <span>{member.timeframe}</span>}
                                    </td>
                                    <td>
                                        {member.user_id === editingRowId && (
                                            <Button onClick={() => this.handleStopEdit(rowIndex)} icon_sm fill>
                                                <i className="fa fa-check" />
                                            </Button>
                                        )}
                                        {member.user_id !== editingRowId && (
                                            <div className="actions-center actions-center__center">
                                                <Button onClick={() => this.handleStartEdit(member)} icon_sm fill>
                                                    <img src={Edit} alt={'edit button'} />
                                                </Button>
                                                <Button onClick={() => this.handleRemove(rowIndex)} icon_sm_delete fill
                                                    disabled={member.user_id === project.assignee_id}
                                                    title={t(member.user_id === project.assignee_id ? "Cannot remove Manager" : "Remove member")}>
                                                    <img src={Delete} alt="delete button" />
                                                </Button>
                                            </div>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default inject('projectStore', 'commonStore', 'userStore')(applicationRouter(withLocalization(observer(ProjectMembersForm))));
