import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { Modal, Table, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';

//components
import withPermissions from '~/hoc/withPermissions';
import withLocalization from '~/hoc/withLocalization';
import applicationRouter from '~/hoc/applicationRouter';
import Checkbox from '../../../components/CustomCheckbox/CustomCheckbox';

//elements
import Button from '../../../components/CustomButton/CustomButton.jsx';
import CustomMultiSelect from '../../../elements/CustomMultiSelect';

//assets
import CloseIcon from "~/assets/img/modal-close-icon.svg";
import ActivateUser from '../../../assets/img/ActivateUser.svg'
import LoadingSpinner from '../../../elements/LoadingSpinner';

class UserImport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userToSync: [],
            userAddIds: [],
            loading: false,
            loadingText: this.props.t('Hold On, This Will take a moment!'),
            showResponseText: '',
            projectList: [],
            issueArray: []
        }
    }

    componentDidMount() {
        this.loadData();
    }

    async loadData() {
        const { userStore } = this.props
        this.setState({ loading: true })
        const getMemberList = await userStore.getAllEmployeeFromIntegration()
        let projectMembers = getMemberList.projects.map(item => item.value);
        let defaultAll = getMemberList.list.map(item => ({
            ...item,
            projectMembers
        }));
        this.setState({ userToSync: defaultAll, projectList: getMemberList.projects, loading: false })
    }

    importMemberFromIntegration = async () => {
        const { userStore, t, commonStore } = this.props;
        const { userAddIds } = this.state;
        const str = t(`Do You want to Import selected Members into TimeAndControl`);
        confirmAlert({
            title: this.props.t('Confirm to Import'),
            message: str,
            buttons: [
                {
                    label: this.props.t('Yes'),
                    onClick: () => {
                        this.setState({ loading: true });
                        const __state = {
                            issueArray: [],
                            loading: true,
                            showResponseText: '',
                            userAddIds: userAddIds
                        }
                        return userStore.saveMemberFromIntegration({ users: userAddIds }).then((res2) => {
                            userStore.resetLastListLoadTime();
                            if (res2.issueArray && res2.issueArray.length) {
                                __state.issueArray = res2.issueArray;
                                __state.loading = true;
                            }
                            else {
                                __state.showResponseText = t("Successfully imported selected Members");
                                __state.loading = false;
                            }
                            return res2;
                        }).catch(error => {
                            console.error({ error });
                            commonStore.addNotification(t(error.message || 'Something went wrong'), null, 'error');
                            __state.loading = false;
                            __state.userAddIds = [];
                        }).finally(() => {
                            this.setState({ ...__state })
                        });
                    },
                },
                {
                    label: this.props.t('No')
                },
            ],
        })
    }

    handleCheckbox = (ele, e) => {
        if (e) {
            this.setState({ userAddIds: [ele, ...this.state.userAddIds] })
        }
        else {
            const updateIds = this.state.userAddIds.filter(item => item.users.id !== ele.users.id)
            this.setState({ userAddIds: updateIds })
        }
    }

    handleMultiSelect = (e, ele) => {
        ele.projectMembers = e
        const updateIds = this.state.userToSync.filter(item => item.users.id !== ele.users.id)
        const _updateIds = this.state.userAddIds.filter(item => item.users.id !== ele.users.id)
        this.setState({ userToSync: [ele, ...updateIds].sort((a, b) => a.users.index - b.users.index) })
        if (ele.isSynced)
            this.setState({ userAddIds: [ele, ..._updateIds] })
    }

    render() {
        const { t, handleModalState, modalState } = this.props;
        const { loading, showResponseText, userToSync, projectList, loadingText, issueArray } = this.state
        return (
            <Modal
                size="lg"
                className="modal fade"
                show={modalState}
                onHide={() => handleModalState('showImportComponent', false, true)}
            >
                <Modal.Header>
                    <Modal.Title>{t('Import Members from')} {this.props.commonStore.config?.integration_details?.product}</Modal.Title>
                    <div className='modal-header__revised__closebtn' onClick={() => handleModalState('showImportComponent', false, true)}>
                        <img
                            src={CloseIcon}
                            className="cursor-pointer"
                            alt='close_button'
                        />
                    </div>
                </Modal.Header>
                <Modal.Body className='mb-2'>
                    {loading ?
                        issueArray.length ?
                            <>
                                <div className='d-flex flex-column'>
                                    <div>
                                        {t('We found some issues while importing the Members')}
                                    </div>
                                </div>
                                <div className='projectMemberForm'>
                                    <Table className='w-100 '>
                                        <thead>
                                            <tr className='w-100'>
                                                <th width={'40%'} className='text-start'>{t('Members')}</th>
                                                <th width={'20%'}>{t('Issues')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {issueArray.map((ele, idx) => (
                                                <tr key={idx} className='text-capitalize mt-1'>
                                                    <td width={'40%'} className='text-start'><div className=''>{ele?.user}</div></td>
                                                    <td width={'60%'} className='timelog_array_details'>
                                                        {ele.message}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                            </>
                            :
                            <>
                                <LoadingSpinner />
                                <div className='text-center'><strong>{loadingText}</strong></div>
                            </>
                        :
                        <Fragment>
                            {showResponseText === '' ?
                                <>
                                    <div className='d-flex flex-column'>
                                        <div>
                                            {t('Please choose the Members you wish to import')}
                                        </div>
                                        <div className='d-flex'>
                                            **{t("If the email matches an existing member in our System, the integration process will continue as planned. Please note that we will not responsible for any differences in member's name or details")}.
                                        </div>
                                    </div>
                                    <div className='projectMemberForm'>
                                        <Table className='w-100 '>
                                            <thead>
                                                <tr className='w-100'>
                                                    <th width={'40%'} className='text-start'>{t('Members')}</th>
                                                    <th width={'20%'}>{t('Is Synced')}</th>
                                                    <th width={'40%'}>{t('Projects')}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {userToSync.map((ele, idx) => (
                                                    <tr key={idx} className='text-capitalize mt-1'>
                                                        <td width={'40%'} className='text-start'><div className=''>{ele?.users?.displayName}</div></td>
                                                        <td width={'20%'} className='timelog_array_details'>
                                                            {ele.isSynced ?
                                                                <img src={ActivateUser} alt='tick' title='Already Synced' />
                                                                :
                                                                <Checkbox
                                                                    isChecked={ele.isSynced || ele?.nowSynced}
                                                                    onChange={checked => this.handleCheckbox(ele, checked)}
                                                                    disabled={ele.isSynced}
                                                                    wrapperClassName={'bulk-shift-chekbox-style'}
                                                                />
                                                            }
                                                        </td>
                                                        <td width={'40%'}>
                                                            <div>
                                                                {ele.isInactive ?
                                                                    <div className='d-flex w-75 m-2 p-1'>
                                                                        <div className='text-center'>
                                                                            {`${t('Member')} ${t('Inactive')} ${t('in')} ${t('Time&control')}`}
                                                                        </div>
                                                                        <OverlayTrigger
                                                                            placement="bottom"
                                                                            overlay={
                                                                                <Tooltip id="tooltip-top">
                                                                                    {t("Employee is already Synced with Time&control, but is inactive. Please navigate to Inactive tab and activate the employee account")}.
                                                                                </Tooltip>
                                                                            }
                                                                        >
                                                                            <i className="fa fa-info-circle text-muted" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                                                                        </OverlayTrigger>
                                                                    </div>
                                                                    :
                                                                    <CustomMultiSelect
                                                                        className={"w-75"}
                                                                        options={projectList}
                                                                        value={ele.projectMembers}
                                                                        onChange={(e) => this.handleMultiSelect(e, ele)}
                                                                        disabled={ele.isSynced}
                                                                        overrideStrings={{
                                                                            selectSomeItems: t('Select Projects'),
                                                                            allItemsAreSelected: t('All Projects are selected'),
                                                                            search: t('Search'),
                                                                            selectAll: t('Select All Projects'),
                                                                            noOptions: t("No Projects found"),
                                                                        }}
                                                                    />
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </div>
                                </>
                                :
                                <>
                                    <div className='d-flex justify-content-center align-items-center flex-column'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="60px" height="60px" viewBox="0 0 1024 1024"><path fill="#2550AC" d="M512 64a448 448 0 1 1 0 896a448 448 0 0 1 0-896zm-55.808 536.384l-99.52-99.584a38.4 38.4 0 1 0-54.336 54.336l126.72 126.72a38.272 38.272 0 0 0 54.336 0l262.4-262.464a38.4 38.4 0 1 0-54.272-54.336L456.192 600.384z"></path></svg>
                                        <div className='text-center'>{showResponseText}</div>
                                    </div>
                                </>
                            }
                        </Fragment>
                    }
                </Modal.Body>
                <Modal.Footer>
                    {showResponseText === '' ?
                        <>
                            <Button fill wd
                                onClick={this.importMemberFromIntegration}
                                disabled={!this.state.userAddIds.length || this.state.issueArray.length}
                            >
                                {t('Import Selected Users')}
                            </Button>
                            <Button wd
                                onClick={() => handleModalState('showImportComponent', false, true)}
                            >
                                {t('Cancel')}
                            </Button>
                        </>
                        :
                        <>
                            <Button wd
                                onClick={() => handleModalState('showImportComponent', false, true)}
                            >
                                {t('OK')}
                            </Button>
                        </>
                    }
                </Modal.Footer>
            </Modal>
        );
    }
}
export default inject('userStore', 'commonStore')(applicationRouter(withPermissions(withLocalization(observer(UserImport)))));
