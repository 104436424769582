import React, { Component } from 'react';

import { inject, observer } from 'mobx-react';

import applicationRouter from '~/hoc/applicationRouter';

import withLocalization from '../../hoc/withLocalization';

class SignUpForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contactForm: {
                company: '',
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                comment: '',
                note: 'Sign Up Contact',
                accept: false,
            },
        };
    }

    handleFormChange = (name, value) => {
        const contactForm = Object.assign({}, this.state.contactForm);
        contactForm[name] = value;
        this.setState({
            contactForm,
        });
    };
  
    contactSubmit = (e) => {
        e.preventDefault();       
        this.props.commonStore.sendContact(this.state.contactForm).then(() => {
            this.props.commonStore.addNotification(
                this.props.t('Your message has been sent, thank you.'),
                null,
                'success'
            );
            this.setState({
                contactForm: {
                    company: '',
                    firstName: '',
                    lastName: '',
                    email: '',
                    phone: '',
                    comment: '',
                    accept: false,
                },
            });
            alert(this.props.t('Your message has been sent, thank you.'));
        });
    };

    render() {
        const { contactForm } = this.state;
        const { t } = this.props;
        return (
            <>
                <form onSubmit={this.handleSubmitForm} className="login-container">
                    {/* <form className='login-container'> */}
                    <div className="row">
                        <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                            <div className="form-group business-name">
                                <label htmlFor="businessName" className="big-text">
                                    {t('Business Name')}
                                </label>
                                <label className="red-star">*</label>
                                <p className="small-text st">{t('Please add your business name')}</p>
                                <input
                                    type="text"
                                    value={contactForm.company}
                                    onChange={(e) => this.handleFormChange('company', e.target.value)}
                                    className="form-control login-input mb_25 mt-12"
                                    placeholder={t('Business Name')}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                            <div className="form-group  business-name ">
                                <label htmlFor="firstName" className="big-text">
                                    {t('First Name')}{' '}
                                </label>
                                <label className="red-star">*</label>
                                <p className="small-text st">{t('Add your first name')}</p>
                                <input
                                    type="text"
                                    value={contactForm.firstName}
                                    onChange={(e) => this.handleFormChange('firstName', e.target.value)}
                                    className="form-control login-input mt-12 mb_25"
                                    placeholder={t('First Name')}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                            <div className="form-group  business-name ">
                                <label htmlFor="lastName" className="big-text">
                                    {t('Last Name')}{' '}
                                </label>
                                <label className="red-star">*</label>
                                <p className="small-text st">{t('Add your last name')}</p>
                                <input
                                    type="text"
                                    value={contactForm.lastName}
                                    onChange={(e) => this.handleFormChange('lastName', e.target.value)}
                                    className="form-control login-input mt-12 mb_25"
                                    placeholder={t('Last Name')}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                            <div className="form-group business-name">
                                <label htmlFor="email" className="big-text">
                                    {t('Email')}
                                </label>
                                <label className="red-star">*</label>
                                <p className="small-text st">{t('Add email address')}</p>
                                <input
                                    type="text"
                                    value={contactForm.email}
                                    onChange={(e) => this.handleFormChange('email', e.target.value)}
                                    className="form-control login-input mb_25 mt-12"
                                    placeholder={t('Email address')}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                            <div className="form-group business-name">
                                <label htmlFor="mobileNumber" className="big-text">
                                    {t('Mobile Number')}
                                </label>
                                <label className="red-star">*</label>
                                <p className="small-text st"> {t('Please enter your mobile number.')}</p>
                                <input
                                    type="text"
                                    value={contactForm.phone}
                                    onChange={(e) => this.handleFormChange('phone', e.target.value)}
                                    className="form-control login-input mb_25 mt-12"
                                    placeholder="Mobile Number"
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                            <div className="form-group business-name">
                                <label htmlFor="comment" className="big-text">
                                    {t('Comment')}
                                </label>
                                <label className="red-star">*</label>
                                <p className="small-text st"> {t('Please enter your comment')}</p>
                                <textarea
                                    className="form-control text_area"
                                    required
                                    value={contactForm.comment}
                                    placeholder={this.props.t('Comments')}
                                    onChange={(e) => this.handleFormChange('comment', e.target.value)}
                                ></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="form-check mb-30">
                        <input
                            type="checkbox"
                            checked={contactForm.accept}
                            value={contactForm.accept}
                            onChange={(checked) => this.handleFormChange('accept', checked)}
                            className="form-check-input"
                            id="exampleCheck1"
                        />
                        <label className="form-check-label2 signupTandC" htmlFor="exampleCheck1">
                            &nbsp; {this.props.t('I accept the')} &nbsp;
                            <a>{t('Terms & Conditions')}</a>
                        </label>
                    </div>
                    <button className="btn btn-block fwBold fs18 loginbutton" onClick={this.contactSubmit}>
                        {t('Sign Up').toUpperCase()}
                    </button>
                </form>
            </>
        );
    }
}
export default inject('authStore', 'commonStore')(applicationRouter(withLocalization(observer(SignUpForm))));
