import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

//components
import applicationRouter from '~/hoc/applicationRouter'
import withLocalization from '~/hoc/withLocalization';
import ShiftSettings from './ShiftSettings.js';


class ScheduleSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subTab: 'shiftSettings',
        }
        this.handleSubTabChange = this.handleSubTabChange.bind(this);
        this.handleUserDataChange = this.handleUserDataChange.bind(this);
    }


    handleUserDataChange = (name, value) => {
        const { currentEntity } = this.props.clientStore;
        if (currentEntity.client.data && currentEntity.client.data.basicRules) {
            currentEntity.client.data.basicRules[name] = value;
        } else {
            let basicRules = {};
            basicRules[name] = value;
            currentEntity.client.data.basicRules = basicRules;
        }
    }

    handleSubTabChange = (tabName) => {
        this.setState({ subTab: tabName })
    }

    render() {
        return <ShiftSettings />
    }
}
export default inject('clientStore', 'commonStore', 'userStore')(applicationRouter(withLocalization(observer(ScheduleSettings))));