import React, { Component } from 'react';
import applicationRouter from '~/hoc/applicationRouter'
import { inject, observer } from 'mobx-react';
import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap'
import withLocalization from '~/hoc/withLocalization';
import SwitchWidget from '../SwitchWidget';

class shiftRequestSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            autoApproveSwapShiftRequests: false,
            enableScheduleShiftsCalculations: false,
            enableSchedulePublishUnpublishFeatue: false,
        }
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = () => {
        const { currentEntity } = this.props.clientStore;
        let { autoApproveSwapShiftRequests, enableScheduleShiftsCalculations, enableSchedulePublishUnpublishFeatue } = this.state;
        if (currentEntity.client.data && currentEntity.client.data.basicRules) {
            const basicRules = Object.assign({}, currentEntity.client.data.basicRules);
            autoApproveSwapShiftRequests = basicRules.autoApproveSwapShiftRequests ? basicRules.autoApproveSwapShiftRequests : false;
            enableScheduleShiftsCalculations = basicRules.enableScheduleShiftsCalculations ? basicRules.enableScheduleShiftsCalculations : false;
            enableSchedulePublishUnpublishFeatue = basicRules.enableSchedulePublishUnpublishFeatue ? basicRules.enableSchedulePublishUnpublishFeatue : false;
            this.setState({ autoApproveSwapShiftRequests, enableScheduleShiftsCalculations, enableSchedulePublishUnpublishFeatue });
        }
    }

    handleSwitch = (evt, name) => {
        this.props.handleUserDataChange(name, evt);
        this.setState({ [name]: evt })
    }

    render() {
        const { t } = this.props;
        const { autoApproveSwapShiftRequests, enableScheduleShiftsCalculations, enableSchedulePublishUnpublishFeatue } = this.state
        return (
            <div>
                <Row style={{ marginTop: '2rem' }}>
                    <Col sm={1}>
                        <SwitchWidget
                            className="mt-1"
                            value={autoApproveSwapShiftRequests}
                            onChange={checked => { this.handleSwitch(checked, "autoApproveSwapShiftRequests") }} />
                    </Col>
                    <Col sm={10}>
                        <label className="control-label me-1">{t('Activate auto Sick Leave Replacement')}.</label>
                        <OverlayTrigger
                            placement="right"
                            overlay={
                                <Tooltip id="tooltip-top">
                                    {t("This function allows for automatic finding of a replacement employee when another employee has registered sick leave")}.
                                </Tooltip>
                            }
                        >
                            <i className="fa fa-info-circle text-muted" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                        </OverlayTrigger>
                    </Col>
                </Row>
                <Row style={{ marginTop: '2rem' }}>
                    <Col sm={1}>
                        <SwitchWidget
                            className="mt-1"
                            value={enableSchedulePublishUnpublishFeatue}
                            onChange={checked => { this.handleSwitch(checked, "enableSchedulePublishUnpublishFeatue") }} />
                    </Col>
                    <Col sm={10}>
                        <label className="control-label me-1">{t('Enable Daily Shift Publish feature')}.</label>
                        <OverlayTrigger
                            placement="right"
                            overlay={
                                <Tooltip id="tooltip-top">
                                    {t("This feature allows the admin/PMs to mark the days of the week as either unpublished or published")}.
                                </Tooltip>
                            }
                        >
                            <i className="fa fa-info-circle text-muted" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                        </OverlayTrigger>
                    </Col>
                </Row>
                <Row style={{ marginTop: '2rem' }}>
                    <Col sm={1}>
                        <SwitchWidget
                            className="mt-1"
                            value={enableScheduleShiftsCalculations}
                            onChange={checked => { this.handleSwitch(checked, "enableScheduleShiftsCalculations") }} />
                    </Col>
                    <Col sm={10}>
                        <label className="control-label me-1">{t('Activate salaries cost calculations')}.</label>
                        <OverlayTrigger
                            placement="right"
                            overlay={
                                <Tooltip id="tooltip-top">
                                    {t("This feature will calculate and forecast the shift costing for the week for the Admins/Pms, based on employee payment settings, shift timings, and automatic overtime calculation")}.
                                </Tooltip>
                            }
                        >
                            <i className="fa fa-info-circle text-muted" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                        </OverlayTrigger>
                    </Col>
                </Row>
            </div>
        )
    }

}
export default inject('clientStore', 'commonStore', 'userStore')(applicationRouter(withLocalization(observer(shiftRequestSettings))))