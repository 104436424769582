import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

//components
import applicationRouter from '~/hoc/applicationRouter';
import withRemoveDialog from '~/hoc/withRemoveDialog';
import withLocalization from '~/hoc/withLocalization';
import DocumentTable from './DocumentTemplate/DocumentsTable';
import DocumentAddEdit from './DocumentTemplate/DocumentAddEdit';
import DocumentTemplateTable from './DocumentTemplate/DocumentTemplateTable';
import DocumentTemplateAddEdit from './DocumentTemplate/DocumentTemplateAddEdit';

//elements
import Button from '../../../components/CustomButton/CustomButton.jsx';

//styles
import '../../../styles/css/styles.css';

//this is the root component for documents//

class Document extends Component {
    state = {
        document_Data: [],
        currentTab: 1,
        id: 0,
        add: true,
        isEmployee: false,
    };

    componentDidMount() {
        const { user_type } = this.props.userStore.currentUser;
        if (user_type === 'admin' || user_type === 'super-admin') {
            this.setState({ isEmployee: false });
        }
        if (this.props.router.location.pathname.includes('templates')) {
            this.templateList();
        }
    }

    onAdd() {
        //redirects path to add document//
        this.props.documentStore.currentDocument.document_description = '';
        this.props.router.navigate('/admin/documents/add');
    }

    onAddTemplate() {
        //redirects path to add document_template//
        this.props.router.navigate('/admin/documents/templates/add');
    }

    templateList() {
        //redirects path to document_templates list//
        this.props.router.navigate('/admin/documents/templates');
    }

    documentList() {
        //redirects path to document list//
        this.props.router.navigate('/admin/documents');
    }

    editingId = () => {
        const path = this.props.router.location.pathname.split('/');
        const type = path.includes('templates') ? 'templates' : 'document';
        const add = path.includes('add');
        const id = parseInt(path.pop());
        return { editing: !!id, add, id, type: type };
    };

    openTab = (tabId) => {
        this.setState({ currentTab: tabId }, () => {
            if (tabId === 1) {
                this.templateList();
            } else {
                this.documentList();
            }
        });
    };

    getComponent = (tab, user_type, t) => {
        const mode = this.editingId();
        if (tab === 1 && mode.type === 'templates')
            return (
                <>
                    {!mode.editing && !mode.add ? (
                        <div className="table-list">
                            {user_type === 'super-admin' ? (
                                <div className="input-area__content">
                                    <p className="text-danger">
                                        {t('Document Book')} {t('Is only accesible by client. Please Use')}{' '}
                                        {t('Document List')}
                                    </p>
                                </div>
                            ) : (
                                <>
                                    <div className="table-list__header">
                                        {user_type !== 'member' && (
                                            <Button fill wd onClick={() => this.onAddTemplate()}>
                                                <i className="fa fa-plus-circle fa-xl margin-right-10" />
                                                {t('Create Document Book')}
                                            </Button>
                                        )}
                                    </div>
                                    <DocumentTemplateTable />
                                </>
                            )}
                        </div>
                    ) : (
                        <DocumentTemplateAddEdit id={mode.id} add={mode.add} />
                    )}
                </>
            );
        else
            return (
                <>
                    {!mode.editing && !mode.add ? (
                        <div className="table-list">
                            <div className="table-list__header">
                                {user_type !== 'member' && (
                                    <Button fill wd onClick={() => this.onAdd()}>
                                        <i className="fa fa-plus-circle fa-xl margin-right-10" /> {t('Create Document')}
                                    </Button>
                                )}
                            </div>
                            <DocumentTable />
                        </div>
                    ) : (
                        <DocumentAddEdit id={mode.id} add={mode.add} onTabJumped={mode.type == 'templates' ? true : false}/>
                    )}
                </>
            );
    };

    render() {
        //renders document and document_template list components//
        const { t } = this.props;
        const { user_type } = this.props.userStore.currentUser;

        return (
            <>
                {user_type !== 'member' && (
                    <div className={'row-flex'}>
                        <button
                            className={this.state.currentTab === 1 ? 'tablink tablink__active-left-doc' : 'tablink tablink__left-doc'}
                            onClick={() => this.openTab(1)}
                        >
                            {t('Document Book')}
                        </button>

                        <button
                            className={this.state.currentTab === 2 ? 'tablink tablink__active-right-doc' : 'tablink tablink__right-doc'}
                            onClick={() => this.openTab(2)}
                        >
                            {t('Document List')}
                        </button>
                    </div>
                )}
                {this.getComponent(this.state.currentTab, user_type, t)}
            </>
        );
    }
}

export default inject(
    'documentStore',
    'authStore',
    'userStore',
    'commonStore'
)(applicationRouter(withLocalization(withRemoveDialog(observer(Document)))));
