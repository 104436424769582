import React, { Component } from 'react';
import applicationRouter from '~/hoc/applicationRouter'
import { inject, observer } from 'mobx-react';

import '../../../styles/css/styles.css';

// Components
import { ProjectsWidget } from '../../../components/GenericForm/Widgets/ProjectsWidget';
import { Textarea } from '../../../elements/Input';
import CustomRadio from '../../../components/CustomRadio/CustomRadio';
import {AttachmentsWidget} from '../../../components/GenericForm/Widgets/AttachmentsWidget';

import withRemoveDialog from '~/hoc/withRemoveDialog';
import withLocalization from '~/hoc/withLocalization';


class ChecklistDeviation extends Component {
    state = {
        element: {},
        due_date: (new Date()),
        project_id:0,
        checklist_name: null,
        attachments: null,
        id:0,
        element_id: 0,
        attachments: "",
        subject: "",
        comments: ""
    };

    componentDidMount() {
        this.setState({
            id: this.props.checklist_id,
            element_id: this.props.data.id,
            subject: this.props.checklist_name,
            comments: this.props.data.name,
            element: this.props.data,
            project_id: this.props.project_id,
            checklist_name: this.props.checklist_name
        }, () => {
            this.handlDeviationData();
        });
    }

    handleChangeComments = (value) => {
        this.setState({
            comments: value
        });
        this.handlDeviationData();
    };

    handlDeviationData = () => {
        let data= {
            id: this.state.id,
            element_id: this.state.element_id,
            attachments: this.state.attachments,
            subject: this.state.subject,
            comments: this.state.comments,
        }
        this.props.handlDeviationData(data);
    }

    render() {
        const { t } = this.props;
        return (
            <div>
                <div className="custom-form__row">
                    <label className="custom-form__col">
                        <ProjectsWidget
                            label={`${t('Project')} *`}
                            value={this.state.project_id}
                            disabled={true}
                        />
                    </label>
                </div>
                <div className="custom-form__row">
                    <div className="custom-form__col">
                        <div className="read-area">
                            <span className="read-area__label">
                                {t('Deviation Subject')}
                            </span>
                            <div className="read-area__content">{this.state.checklist_name}</div>
                        </div>
                    </div>
                    </div>
                <div className="custom-form__row">
                    <Textarea
                        className="custom-form__col"
                        label={`${t('Deviation details')} *`}
                        placeholder={t('Deviation details')}
                        value={this.state.comments}
                        onChange={({ target: { name, value } }) => this.handleChangeComments(value)}
                        name="comments"
                    />
                </div>
                <div className="custom-form__row">
                    <div className="custom-form__col">
                        <div className="input-area">
                            <span className="input-area__label">{t('Urgency')}</span>
                            <div className="input-area__content flex-box">
                                    <CustomRadio
                                        required
                                        label={t("critical")}
                                        value='critical'
                                        withColor="red"
                                        checked={true}
                                        name="urgency"
                                        disabled={true}
                                    />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="custom-form__row">
                    <div className="custom-form__col">
                        <div className="input-area">
                            <span className="input-area__label">{t('Deviation attachments')}</span>
                            <div className="input-area__content">
                            <AttachmentsWidget
                                value={this.state.attachments}
                                onChange={attachIds => {
                                    this.setState({
                                        attachments: attachIds
                                    },() => {
                                        this.handlDeviationData();
                                    })
                                }}
                                options={{
                                    imageContext: {
                                        // existingAttachment: this.state.attachmentsList,
                                        fileType: 'docs',
                                        id: 0,
                                        model: 'Image',
                                    },
                                }}
                            />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default inject('checklistStore', 'authStore', 'userStore', 'commonStore')(
    applicationRouter(withLocalization(withRemoveDialog(observer(ChecklistDeviation))))
);
