import { observable, action, makeObservable } from 'mobx';
import agent from '../agent';

class RecipientStore {
    constructor() {
        makeObservable(this, {
            recipientsList: observable,
            draftRecipientList: observable,
            isSetSigningOrder: observable,
            isLoading: observable,
            setDraftRecipientData: action,
            recipientAgreement: action,
            resetLastListLoadTime: action,
            loadList: action,
            fetchAllRecipients: action,
            resetRecipient: action,
            save: action
        });
    }

    recipientsList = null;

    draftRecipientList = null;

    isSetSigningOrder = false;

    isLoading = false;



    setDraftRecipientData(payload, isSetSigningOrder) {
        this.draftRecipientList = payload;
        this.isSetSigningOrder = isSetSigningOrder;
    }

    recipientAgreement(data) {
        return agent.Signature.recipientAgreement(data)
            .then(
                action((res) => {
                    return res;
                })
            )
            .catch(
                action((err) => {
                    throw err;
                })
            );
    }
    resetLastListLoadTime() {
        this.lastListLoadTime = new Date();
    }

    loadList(params, mode) {
        const _params = Object.assign({}, params);
        _params.mode = mode;
        return agent.Recipients.list(_params)
            .then(
                action((list) => {
                    this.requestParams = params;
                    list.time = new Date();
                    this.lastListLoadTime = list.time;
                    this.currentList = list;
                    return list;
                })
            )
            .catch(
                action((err) => {
                    throw err;
                })
            );
    }

    fetchAllRecipients(id) {
        this.isLoading = true
        return agent.Recipients.fetchAllRecipients(id)
            .then(
                action((response) => {
                    this.loading = false;
                    if (response.rows.length) {
                        this.recipientsList = response.rows;
                        const drafts = [];
                        this.recipientsList.forEach((rec) => {
                            let accessCode = rec.access_code.slice(0, 3);
                            drafts.push({
                                id: rec.id,
                                recipientName: rec.user_name,
                                email: rec.email,
                                phoneNumber: rec.phone,
                                accessCode: accessCode,
                                select_value: rec.signing_type,
                                isChecked: !!rec.access_code,
                                isContract: rec.is_contract
                            });
                        });
                        this.draftRecipientList = drafts;
                    }
                    return response;
                })
            )
            .catch(
                action((error) => {
                    this.loading = false;
                    throw error;
                })
            );
    }

    resetRecipient() {
        this.draftRecipientList = null;
        this.recipientsList = null;
        this.isSetSigningOrder = false;

    }

    save(values) {
        return agent.Recipients.save(values)
            .then(
                action((user) => {
                    // this.currentEntity = user.shift;
                    return user;
                })
            )
            .catch(
                action((err) => {
                    // this.updating = false;
                    this.updatingErrors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            );
    }
}

const _RecipientStore = new RecipientStore();
export default _RecipientStore;
