import React, { Component } from 'react';
import { v4 as uuid } from 'uuid';

class CustomCheckbox extends Component {
    render() {
        const {
            label, onChange, isChecked, inline, wrapperClassName = '', inlineBlock, boldLabel, labelClassName = '', title = null, ...rest
        } = this.props;
        const classes = inline !== undefined ? 'checkbox checkbox-inline' : 'checkbox';
        const key = uuid();

        return (
            <div className={`${classes} ${wrapperClassName}`}
                style={{ display: inlineBlock ? 'inline-block' : 'block' }} title={title}>
                <input
                    id={key}
                    type="checkbox"
                    onChange={e => onChange(e.target.checked)}
                    checked={isChecked}
                    {...rest}
                />
                {boldLabel ?
                    <label htmlFor={key} className={labelClassName}><b>{label}</b></label>
                    : <label htmlFor={key} className={labelClassName}><b>{label}</b></label>}
            </div>
        );
    }
}

export default CustomCheckbox;
