import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { observer, inject } from 'mobx-react';
import withLocalization from '../../../hoc/withLocalization';
import getBase64 from '~/utils/getBase64';
import LoadingSpinner from '~/elements/LoadingSpinner';
import DocList from './DocList';
import uploadIcon from '../../../assets/img/upload-icon.svg';


export class UploadDocs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [],
            uploadInfo: null,
        }
    }

    onfileAccept = (files) => {
        const { signatureStore } = this.props;
        const caseId = signatureStore.caseId;
        if (caseId) {
            getBase64(files[0]).then((file) => {
                const data = { id: caseId, content: file, sequence: 1, filename: files[0].name, filesize: files[0].size }
                signatureStore.uploadDoc(data).then(() => {
                    signatureStore.fetchAll(caseId)
                });
            })
        }
    }

    onfileReject = (files) => {
        const { commonStore, t } = this.props;
        commonStore.addNotification(t('Only .pdf, .png, .jpeg, .jpg file allowed'), null, 'error', 'tr', 4);
    }


    render() {

        const { signatureStore, t } = this.props;
        const { isDocUploading } = signatureStore;
        if (isDocUploading) {
            return (<LoadingSpinner />);
        }
        return (
            <>
                <Dropzone accept={{
                    'image/png': ['.png'],
                    'image/jpg': ['.jpg'],
                    'image/jpeg': ['.jpeg'],
                    'application/pdf': ['.pdf']
                }}
                    onDropAccepted={this.onfileAccept}
                    onDropRejected={this.onfileReject}
                    multiple={false}>
                    {({ getRootProps, getInputProps }) => (
                        <section className="dropzoneContainer">
                            <div {...getRootProps({ className: 'dropzone' })}>
                                <input {...getInputProps()} accept=".png" />
                                <div className="uploads">
                                    <img src={uploadIcon} alt="upload icon"></img>
                                </div>
                                <p>{t('Drop your files here OR')}</p>
                                <div className="btnContainer">
                                    <button className="btn" id="signUpBtn">{t('Upload')}</button>
                                </div>
                            </div>
                        </section>
                    )}
                </Dropzone >
                <div className="docContainer">
                    <DocList/>
                </div>

            </>
        )
    }
}

export default inject('commonStore', 'signatureStore')(withLocalization(observer(UploadDocs)));