import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import CustomRadio from '../../CustomRadio/CustomRadio';
import withLocalization from '../../../hoc/withLocalization';

const BusinessTypesSelectMobx = inject('projectStore', 'userStore','commonStore')(withLocalization(observer(class BusinessTypesSelectMobx extends Component {
    render() {
        const { value, onChange } = this.props;
        const { biztypes } = this.props.commonStore.config;
        const statuses = biztypes;
        if (!statuses) {
            return null;
        }
        return (
            <div className="task-statuses">
                {statuses.map(({ id, name }) => (
                    <CustomRadio
                        number={`${id}_${name}`}
                        name={id}
                        key={id}
                        onChange={() => onChange(id)}
                        checked={`${value}` === `${id}`}
                        option={id}
                        label={name}
                    />
                ))}
            </div>
        );
    }
}
)));

export const BusinessTypesSelect = observer(class BusinessTypesSelect extends Component{
    render() {
        return <BusinessTypesSelectMobx {...this.props} />;
    }
});