import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

import Header from "../../../newComponents/Header";
import Footer from "../../../newComponents/Footer";
import MainHeading from "../../../newComponents/MainHeading";
import PageBanner from "../../../newComponents/PageBanner";
import PReason from "../../../newComponents/PReason";
import Contact from "../../../newComponents/Contact";

import BannerImg from "../../../assets/images/deviationBnrImg.png";
import AppleIcon from "../../../assets/images/appleIcon.svg";
import GoogleIcon from "../../../assets/images/googleIcon.svg";
import ValuableLogo from "../../../assets/images/valuableLogo.png";
import PReasonIcon1 from "../../../assets/images/pReasonIcon1.png";
import PReasonIcon2 from "../../../assets/images/pReasonIcon2.png";
import DeviationMainImg from "../../../assets/images/deviationMainImg.png";
import DeviationImg from "../../../assets/images/deviationImg.png";
import DeviationImg1 from "../../../assets/images/deviationImg1.png";

//i18n
import { withTranslation } from "react-i18next"
import { inject, observer } from "mobx-react";

import config from "../../../library/config";

const Deviation = inject('authStore', 'commonStore')(observer((props) => {
  return (
    <>
      <div className="landingPageWrapp frameworkApp">
        <Header features="current" />
        <article className="pageBannerBlock grdntBg noPatt  py-60">
          <Container>
            <Row className="g-4 align-items-center">
              <Col lg>
                <MainHeading
                  title={<>{props.t("Features")} <span>{props.t("Deviation")}</span></>}
                  description={props.t("Never deviate from the existing plan as you take complete access to the schedule with Time & Control deviation management. Utilize the time you spend on scheduling and rescheduling by assuming the line-up plans and risk assessments to ensure consistent results.")}
                />
                <div className="appBtns mt-4">
                  <Link onClick={e => config.redirectToAppStore(e, "apple")} className="btnCommon btnBig w-auto me-3 mb-2"><i className="d-inline-block pe-2"><img src={AppleIcon}  alt="Apple" /></i> {props.t("Apple")}</Link>
                  <Link onClick={e => config.redirectToAppStore(e, "google")} className="btnCommon btnTrans btnBig btnBlackTrans w-auto"><i className="d-inline-block pe-2"><img src={GoogleIcon} alt="Apple" /></i> {props.t("Google")}</Link>
                </div>
              </Col>
              <Col lg={1}></Col>
              <Col lg="auto">
                <PageBanner
                  src={BannerImg}
                  alt="Visitor Register"
                />
              </Col>
            </Row>
          </Container>
          <div className="valuableLogo">
            <img src={ValuableLogo} alt="" />
          </div>
        </article>
        <article className="coreBusnsBlock brPatt  py-60">
          <Container>
            <div className="contentInfo text-center">
              <h5 className="m-0">{props.t("Deviation")}</h5>
            </div>
            <MainHeading
              title={<>{props.t("Effective Deviation")} <span>{props.t("Control & Management")}</span></>}
              description={props.t("Be Conscious, Informed, and Agile")}
              customClass="medium text-center"
            />
            <Row className="g-0 mt-1">
              <Col md={6}>
                <PReason
                  src={PReasonIcon1}
                  link="javascript:void(0);"
                  title={props.t("Person in Charge")}
                  description={props.t("Consistently check who is working on a specific project and their obligations. This way, even if the plan deviates, you’ll see the source and cause of deviation.")}
                  color="yellow"
                  customClass="noLink brBNone"
                />
              </Col>
              <Col md={6}>
                <PReason
                  src={PReasonIcon2}
                  link="javascript:void(0);"
                  title={props.t("Affairs and Duties")}
                  description={props.t("Identify the employee’s tasks in detail to identify their impact on the complete project. The micro-analysis with Time & Control deviation schedule helps with effective management.")}
                  color="blue"
                  customClass="noLink brBNone"
                />
              </Col>
              <Col md={6}>
                <PReason
                  src={PReasonIcon2}
                  link="javascript:void(0);"
                  title={props.t("Estimation of Extra Costs")}
                  description={props.t("Evaluate the deviation and its impact on the project, you can also identify the changes in the cost structure and create a new estimate for the project")}
                  color="blue"
                  customClass="noLink brNone"
                />
              </Col>
              <Col md={6}>
                <PReason
                  src={PReasonIcon1}
                  link="javascript:void(0);"
                  title={props.t("Future Prediction")}
                  description={props.t("Always stay ahead by identifying the deviation and future success. Implement risk assessment strategies and know how to optimize them with optimal solutions.")}
                  color="yellow"
                  customClass="noLink brNone"
                />
              </Col>
            </Row>
            <div className="abtMainImg brTNone" style={{backgroundImage: `url(${DeviationMainImg})`}}></div>
          </Container>
        </article>
        <article className="abtInfoBlock grdntBg noPatt py-60">
          <div className="rightImgFull">
            <img src={DeviationImg} alt="" />
          </div>
          <Container>
            <Row className="g-4 g-xl-5 align-items-center">
              <Col>
                <div className="my-5 py-5 ps-4">
                
                  <MainHeading
                    title={<>{props.t("Effective Deviation")} <span>{props.t("Management")}</span></>}
                    customClass="medium noPara"
                  />
                  <div className="list">
                    <p>{props.t("At the core of deviation, management is considerate and precise reporting. Time & Control gives you complete management control over the tasks, and its in-built reporting functions constantly generate feedback to help you ensure quality control and output.")}</p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </article>
        <article className="abtInfoBlock py-60">
          <div className="leftImgFull">
            <img src={DeviationImg1} alt="" />
          </div>
          <Container>
            <Row className="g-4 align-items-center">
              <Col>
                
                <MainHeading
                  title={<>{props.t("Identify, Monitor,")} <span>{props.t("Evaluate, and Mitigate")}</span></>}                
                  customClass="medium"
                />
                <div className="list">
                  <ul>
                    <li>{props.t("Enhance business performance")}</li>
                    <li>{props.t("Get meaningful insights")}</li>
                    <li>{props.t("Automate deviation management")}</li>
                    <li>{props.t("Get feedback on every device")}</li>
                    <li>{props.t("Maintain project timeline")}</li>
                    <li>{props.t("Comply with work norms and rules")}</li>
                    <li>{props.t("Estimation of extra costs")}</li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </article>
        <Contact/>
        <Footer/>
      </div>
    </>
  );
}))
export default withTranslation()(Deviation);