import React, { Component } from 'react';

class LogoutPage extends Component {
    render() {
        return (
            <div className='bg-color-F9FAF5'>
                <div className='d-flex justify-content-center align-items-center text-dark min-vh-100'>
                    <div className='logout-screen d-flex w-100 justify-content-center'>
                        <div>
                            {this.props.t('Logging you out, have a nice day')}&nbsp;
                        </div>
                        <div style={{minWidth: '10vmin'}}>
                            <span className="typewriter-for-logout"></span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default LogoutPage;
