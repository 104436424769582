// copy of userprofile, but without almost everything expcept file widget
import React, { Component } from 'react';
import applicationRouter from '~/hoc/applicationRouter'
import { inject, observer } from 'mobx-react';

import LoadingSpinner from '~/elements/LoadingSpinner';
import GenericForm from '~/components/GenericForm/GenericForm';
import withLocalization from '~/hoc/withLocalization';
// import queryString from 'query-string';
// import { cloneDeep } from 'lodash';
import getSchema from '~/library/schemas/user';
// import customerFeature from '~/utils/customerFeature';

// const schema = null;

// const isFilledDisease = (fieldName) => (f) => {
//   return true;
// }


const defaultUiSchema =  {
  'ui:field': 'tabs',
  'ui:tabs': [{
    'ui:title': 'Personal Info',
    'id': 'tab1',
    'ui:field': 'layout',
    'ui:layout:hideframe': true,
    'ui:layout': [
      {
        'ui:className': 'profile-edit-form__group',
        attachments: { md: 12 },
      }
    ],
    attachments: {
      'ui:widget': 'AttachmentsWidget',
      'ui:imageContext': {
        model: 'User',
        fileType: 'docs',
        id: 0
      }
    },
  }

  ],
  attachments: {
    'ui:widget': 'AttachmentsWidget',
    'ui:imageContext': {
      model: 'User',
      fileType: 'docs',
      id: 0
    }
  },
}

class Files extends Component {
  state = ({
    uiSchema: defaultUiSchema,
    schema: null,
    loading: true,
  })

  async loadData() {
    // const {add } = this.props;
    let user_type = this.props.userStore.currentUser.user_type

    await this.props.userStore.load(this.props.userStore.currentUser.id, false);
    this.setState({
      schema: getSchema(
        {
          config: this.props.commonStore.config,
          mode: 'files', 
          userType: user_type 
        }
      ),
      loading:false,
      uiSchema: this.prepareSchema(this.props.userStore.currentEntity.user)
    });
  }

  componentDidMount() {
    this.loadData();
  }

  componentWillMount() {
    this.props.userStore.loading = true;
  }


  componentDidUpdate(previousProps) {
    const { location } = this.props;
    if (location !== previousProps.location) {
      this.loadData();
    }
  }


  onSave(values) {
    const { userStore, add, t, commonStore } = this.props;
    return userStore.save(values, add).then(result => {
      if (!result.user || !result.user.id) {
        // some error
        commonStore.addNotification(t(result.message || 'Error'), null, 'error');
        return false;
      }
      commonStore.addNotification(t('Saved'), null, 'success');
      this.props.router.navigate(this.getListUrl());
      return true;
    })
  }

  prepareSchema(currentEntity) {
    const hardcodedSchema = {
      'ui:field': 'tabs',
      'ui:tabs': [{
        'ui:title': 'Personal Documents',
        'id': 'tab1',
        'ui:field': 'layout',
        'ui:layout:hideframe': true,
        'ui:layout': [
          {
            'ui:className': 'profile-edit-form__group',
            attachments: { md: 12 },
          }
        ],
        attachments: {
          'ui:widget': 'AttachmentsWidget',
          'ui:imageContext': {
            model: 'User',
            fileType: 'docs',
            id: 0
          }
        },
      }

      ],
      attachments: {
        'ui:widget': 'AttachmentsWidget',
        'ui:imageContext': {
          model: 'User',
          fileType: 'docs',
          id: 0
        }
      },
    }

    hardcodedSchema['ui:tabs'][0].attachments = {
      'ui:widget': 'AttachmentsWidget',
      'ui:imageContext': {
        'model': 'User',
        'fileType': 'docs',
        'id': currentEntity ? currentEntity.id : 0,
        'existingAttachment': (currentEntity && currentEntity.Attachments) ? currentEntity.Attachments : []
      }
    };

    return hardcodedSchema;
  }

  getListUrl() {
    return '/admin';
  }

  async recalcGps(e) {
    return this.props.taskStore.recalcGpsForTask(e, true, this.props.commonStore, this.props.t);
  }


  render() {
    const { userStore } = this.props;
    const { currentEntity } = userStore;
    if (this.state.loading) {
      return (
        <LoadingSpinner />
      );
    }
    const { schema } = this.state
    const uiSchemaReady = this.prepareSchema(currentEntity.user)
    return (
      <div className="primary-page profile-edit-form">
        <GenericForm
          entity={currentEntity.user}
          uiSchema={uiSchemaReady}
          schema={schema}
          translationScope="forms.user"
          onSave={values => this.onSave(values)}
          recomputeFields={['gps_data', 'data.addressCache']}
          forceRefresh={!!currentEntity.user.gps_data}
          onChange={async data => this.recalcGps(data)}
          isAdding={false}
        />
      </div>
    );
  }
}

export default inject('userStore', 'commonStore', 'taskStore')(applicationRouter(withLocalization(observer(Files))));
