import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import applicationRouter from '~/hoc/applicationRouter'

//components
import withRemoveDialog from '~/hoc/withRemoveDialog';
import withLocalization from '~/hoc/withLocalization';
import CustomCheckbox from '../../../components/CustomCheckbox/CustomCheckbox';
//styles
import '../../../styles/css/styles.css';

//this component load all the admin documents with checkbox and//
//and the ones in the current template are checked//

class BizTypeDocument extends Component {
    state = {
        bizTypeId: null,
        documentList: [],
        biztypeDocumentIds: [],
    }

    componentDidMount() {
        this.setState({
            bizTypeId: this.props.biztypeId
        }, () => {
            this.loadData();
        })
    }

    //loads the documentList with checkBox and//
    //adds checked values based on biztypeDocumentIds//
    loadData = async () => {
        //calls actions for getting the bizType_document and document lists//

        const { documentStore, biztypeStore } = this.props;

        let documentIds_array = [];

        //fetches all rows from biztype_documents with bizTypeId//
        if (this.state.bizTypeId != null && this.state.bizTypeId != "") {
            await biztypeStore.getbiztypeDocuments(this.state.bizTypeId).then(
                (data) => {
                    data.rows.map(function (item) {
                        documentIds_array.push(item.document_id);
                    })
                })
        }

        //sets all the document_ids in biztypeDocumentIds//
        this.setState({ biztypeDocumentIds: documentIds_array })

        //fetches all the admin documents and sets checked values//
        await documentStore.getAdminDocuments().then(
            (data) => {
                if (documentIds_array.length > 0) {
                    data.rows = data.rows.map(function (item) {
                        if (documentIds_array.includes(item.id)) {
                            item.checked = true;
                            return item;
                        }
                        else {
                            item.checked = false;
                            return item;
                        }
                    })
                }
                this.setState({ documentList: data.rows })
            }
        )
    }

    //handles checkBox changes//
    onCheckboxChange = (id, value) => {

        let documentIds_array = [];
        documentIds_array = this.state.biztypeDocumentIds;

        //based on checked value sets biztypeDocumentIds and //
        //calls functions to update documentList and save biztype_documents//
        if (value == true) {
            documentIds_array.push(id);
            this.setState({ biztypeDocumentIds: documentIds_array }, () => {
                this.updateDocumentList();
                this.addBizTypeDocumentData();
            })
        }
        else {
            if (this.state.biztypeDocumentIds.includes(id)) {
                documentIds_array = documentIds_array.filter(function (item) {
                    return item !== id;
                })
                this.setState({ biztypeDocumentIds: documentIds_array }, () => {
                    this.updateDocumentList();
                    this.addBizTypeDocumentData();
                })
            }
        }
    }

    //updates documentList//
    updateDocumentList() {
        let temp = this.state.documentList;
        let documentIds_array = this.state.biztypeDocumentIds;

        if (documentIds_array.length > 0) {
            temp.map(function (item) {
                if (documentIds_array.includes(item.id)) {
                    item.checked = true;
                    return item;
                }
                else {
                    item.checked = false;
                    return item;
                }
            })
        }
        else {
            temp.map(function (item) {
                item.checked = false;
                return item;
            })


        }
        this.setState({ documentList: temp });
    }

    //calls action to save biztype_documents//
    addBizTypeDocumentData() {
        let dataArray = []
        let bizId = this.state.bizTypeId;

        if (this.state.biztypeDocumentIds.length > 0) {
            this.state.biztypeDocumentIds.map(function (item) {
                dataArray.push({
                    biztype_id: bizId,
                    document_id: item
                })
            })
        }
        let data = {
            biztype_id: bizId,
            data: dataArray
        };

        this.props.biztypeStore.addBizTypeDocument(data);
    }

    render() {
        //renders document list with checkboxs and handles them// 
        const { t } = this.props;
        return (
            <div>
                <div className="custom-form__row">
                    <div className="custom-form__col">
                        {t('System Documents')}
                        {this.state.documentList.length > 0 ? (
                            this.state.documentList.map((item, index) => (
                                <div className="col-md-6">
                                    <CustomCheckbox
                                        key={index}
                                        label={item.document_name}
                                        onChange={value => this.onCheckboxChange(item.id, value)}
                                        isChecked={item.checked}
                                    />
                                </div>
                            ))
                        ):
                        <div>
                            <p>{t('No System Documents found')}</p>
                        </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default inject('documentStore', 'biztypeStore')(applicationRouter(withLocalization(withRemoveDialog(observer(BizTypeDocument)))));