import React, { Component } from 'react';
import { inject , observer } from 'mobx-react';
import withLocalization from '../../../hoc/withLocalization.js';

import Checkbox from '../../CustomCheckbox/CustomCheckbox.jsx';

const CheckBoxWidgets = inject('projectStore', 'userStore','commonStore')(withLocalization(observer(class CheckBoxWidgets extends Component{
// class CheckBoxWidgets extends Component {
    render() {
        const { value, onChange, label } = this.props;
        return (
            <div className="check-box-widget">
                <Checkbox isChecked={value} onChange={onChange} label={label} />
            </div>
        );
    }
}

)));
export const CheckBoxWidget = observer(class CheckBoxWidget extends Component {
    render() {
        return <CheckBoxWidgets  {...this.props}/>;
    }
});
export default CheckBoxWidget;
