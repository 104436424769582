import React from 'react';
import PropTypes from 'prop-types';
import Flags from './Flags';

const BaseFormItem = ({ label, style, className, children }) => (
    <div className={className} style={style}>
        <label className={`input-block ${label ? '' : 'input-block_no-label'}`}>
            {label && (
                <span className="input-block__label" title={label}>
                    {label}
                </span>
            )}
            {children}
        </label>
    </div>
);


export const Input = ({ label, value, onChange, style, className, children, error, ...rest }) => (
    <BaseFormItem label={label} style={style} className={className}>
        <input
            className={`form-control input-block__base-input ${error ? 'input-block__base-input_error' : ''}`}
            type="text"
            value={value || ''}
            onChange={onChange}
            {...rest}
        />
    </BaseFormItem>
);

export const NumberInput = ({ label, value, onChange, style, className, customClassses, children, ...rest }) => (
    <BaseFormItem label={label} style={style} className={className}>
        <input
            className={customClassses ? `${customClassses} form-control input-block__base-input` : "form-control input-block__base-input"}
            type="number"
            value={value}
            onChange={onChange}
            onWheel={(e) => e.target.blur()}
            {...rest}
        />
    </BaseFormItem>
);

export const InputGroup = ({ label, value, flag, code, onChange, style, className, children, ...rest }) => (
    <div className="input-group " style={style}>
        <span className="input-group-addon" style={{ backgroundColor: '#fff', width: '40px', padding: '0', border: '1px solid #447df7 ' }}>
            <Flags flag={flag} />
        </span>
        <input
            type="text"
            className="form-control "
            style={{ marginLeft: '-1px', width: '100%', paddingLeft: '2px' }}
            value={code}
            onChange={onChange}
            {...rest}
        />
    </div>
);

export const Textarea = ({ label, value, onChange, style, className, children, error, timelog_modal, ...rest }) => (
    <BaseFormItem label={label} style={style} className={className}>
        <textarea
            className={`form-control input-block__base-input input-block__base-input_textarea${timelog_modal ? timelog_modal : ' '} ${error ? 'input-block__base-input_error' : ''
                }`}
            value={value || ''}
            onChange={onChange}
            {...rest}
        />
    </BaseFormItem>
);

BaseFormItem.propTypes = {
    label: PropTypes.string,
};

export default Input;
