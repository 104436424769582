import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import applicationRouter from '~/hoc/applicationRouter'
import ReactQuill from 'react-quill';
import { Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import config from '~/library/config';
import { Worker } from '@react-pdf-viewer/core';


//components
import withRemoveDialog from '~/hoc/withRemoveDialog';
import withLocalization from '~/hoc/withLocalization';

//styles
import '../../../../styles/css/styles.css';
import 'react-quill/dist/quill.snow.css';
import { template } from 'lodash';


//this component displays all the documents with checkbox// 
//and the ones in the current template are checked//

class MultipleDocumentsPdfPreview extends Component {
    state = {
        template_id: null,
        documentList: [],
        template_name: null,
        client_data: null,
        document_ids:null
    }

    componentDidMount() {
        let id = window.location.pathname.replace('/multipleDocumentsPdfPreview/', '')
        var vars = id.split("&");
        var tempId = 0;
        var docIds = '';
        var template_name = '';

        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            if (pair[0] == 'tempId') {
                tempId = pair[1];
            } else if (pair[0] == 'docIds') {
                docIds = pair[1];
            } else if (pair[0] == 'tempName') {
                template_name = pair[1];
                template_name = template_name.replace(/_-_/g, ' ');
            }
        }

        this.setState({
            template_id:tempId,
            document_ids: docIds,
            template_name: template_name
        }, () => {
            this.loadData();
        })

    }

    loadData = async () => {
        var reqPayload = {
            template_id: this.state.template_id,
            document_ids: this.state.document_ids
        };
        await this.props.documentStore.getMultipleDoumentsByIds(reqPayload).
            then((data) => {
                    this.setState({
                            documentList: data.rows,
                            client_data: data.client_data
                        })
                }
            )
    }

    loadPdf = (id) => {
        
        let pdf = `${config.API_ROOT}/attachments/${id}`;
        //let pdf = `${config.API_ROOT}/attachments/filedata/${id}`;

        return(
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.3.122/build/pdf.worker.min.js">
        <Viewer
            fileUrl={pdf}                                    
        // plugins={[defaultLayoutPluginInstance]} 
        />
        </Worker>
        )
    }
    render() {
        // const {commonStore} = this.props;
        // const client_data = commonStore.config.client;
        return (
            <div className="">
                <div style={{ pageBreakAfter: 'always', width: '100%', height: '100%', textAlign: 'center', padding: '30px' }}>
                    {this.state.client_data && 
                    <div>
                        <img style={{maxHeight: '80px', maxWidth:'200px',  marginTop: '30%' }}
                            src={`${config.UPLOADS_API_ENDPOINT}/${this.state.client_data.image}`}
                        />
                        <div>{this.state.client_data.name}</div>
                        <div>{this.state.client_data.tax_number}</div>
                        <div>{this.state.client_data.address}</div>
                    </div>
                    }
                    <div style={{}}><h4>{this.state.template_name}</h4></div>
                </div>
                {this.state.documentList.length > 0
                    && (
                        this.state.documentList.map((item, index) => (
                            <div key={index} className="center-block" style={{ pageBreakAfter: 'always' }}>

                                <div style={{ marginBottom: '5px', marginLeft: '5px', color: 'blue', fontSize: '20px' }}>{(index + 1) + ". " + item.document_name}</div>
                                {!item.ispdf ? (<div><ReactQuill
                                    theme={'snow'}
                                    value={item.document_description}
                                    readOnly={true}
                                    modules={{ toolbar: false, }}
                                    style={{ border: '0px', width: '210mm', padding:'10px', color:'black'}}
                                    className="reactqillParent"
                                /></div>) : (
                                    // <div>
                                    //     <p>PDF : {`${config.API_ROOT}/attachments/${item.pdf_attachment}`}</p>
                                    //     <embed style={{width:"100%",height:"290mm"}} src={`${config.API_ROOT}/attachments/${item.pdf_attachment}`} type="application/pdf"/>                                    
                                    // </div>
                                    this.loadPdf(item.pdf_attachment)
                                )}

                            </div>
                        ))
                    )
                }
            </div>
        )
    }

}


export default inject('documentStore')(
    applicationRouter(withLocalization(withRemoveDialog(observer(MultipleDocumentsPdfPreview))))
);