import React, { Component } from 'react';
import { Dropdown } from 'react-bootstrap';
import { inject, observer } from 'mobx-react';

//components
import applicationRouter from '~/hoc/applicationRouter'
import withLocalization from '~/hoc/withLocalization';
import { defaultLanguage } from '~/library/core'

//assests
import uk_flag from '../../assets/img/new_design/jsx_adminnavbar_svg/uk_flag.png'
import norway_Flag from '../../assets/img/new_design/jsx_adminnavbar_svg/norway_flag.png'
import lithuania_flag from '../../assets/img/new_design/jsx_adminnavbar_svg/lithuania_flag.png'
import poland_flag from '../../assets/img/new_design/jsx_adminnavbar_svg/poland_flag.png'
import russian_flag from '../../assets/img/new_design/jsx_adminnavbar_svg/russian_flag.png'
import spain_flag from '../../assets/img/new_design/jsx_adminnavbar_svg/spain_flag.png'
import greek_flag from '../../assets/img/new_design/jsx_adminnavbar_svg/greek_flag.png'

const languageMap = [
    {langName: 'English', langSort: 'en', flagImg: uk_flag},
    {langName: 'Norsk', langSort: 'no', flagImg: norway_Flag},
    {langName: 'Polish', langSort: 'pl', flagImg: poland_flag},
    {langName: 'Lithuanian', langSort: 'lt', flagImg: lithuania_flag},
    {langName: 'Russian', langSort: 'ru', flagImg: russian_flag},
    {langName: 'Spanish', langSort: 'es', flagImg: spain_flag},
    {langName: 'Greek', langSort: 'gr', flagImg: greek_flag},
]

class GenericDropdown extends Component {
    constructor(props) {
        super(props)
        this.state = {
            langSelect: languageMap[1].flagImg
        }
        this.handleLangChange = this.handleLangChange.bind(this)

    }
    componentDidMount() {
        this.updateAppLocale(
            this.props.userStore?.currentUser?.data?.lang ?
                this.props.userStore?.currentUser?.data.lang : 
                defaultLanguage() === 'Norsk' ? 
                'no' : 'en');
    }

    handleLangChange(lang) {
        this.updateAppLocale(lang)
    }

    updateAppLocale = (lang) => {
        const selectedLang = languageMap.find(item => item.langSort === lang)
        if (!selectedLang) return;
        this.props.commonStore.setAppLang(lang)
        this.props.commonStore.setLocale(lang);
        this.setState({ langSelect: selectedLang.flagImg })
    }


    render() {
        const { t } = this.props;
        const getCurrentLocale = sessionStorage.getItem('locale');
        return (
            <div className='header-lang-dropdown'>
                <Dropdown>
                    <Dropdown.Toggle>
                        <img
                            className="img-fluid"
                            src={this.state.langSelect}
                            alt="img"
                            width="25px"
                            height="25px"
                        />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Header>
                            <div>{t('Choose')} {t('Language')}</div>
                        </Dropdown.Header>
                        <div className='languages-list'>
                            {languageMap.map((langEach, ids) => (
                                <Dropdown.Item active={langEach.langSort === getCurrentLocale} onClick={() => { this.handleLangChange(`${langEach.langSort}`) }} key={ids} className="language-item">
                                    <div className='lang-logo'>
                                        <img
                                            className="img-fluid"
                                            src={langEach.flagImg}
                                            alt="language logo"
                                            width="30px"
                                            height="30px"
                                        />
                                    </div>
                                    <div className='lang-name'>
                                        {langEach.langName}
                                    </div>
                                </Dropdown.Item>
                            ))}
                        </div>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        )
    }
}

export default inject('commonStore', 'userStore')(applicationRouter(withLocalization(observer(GenericDropdown))));