import React, { Component } from 'react';



//components
import withLocalization from '~/hoc/withLocalization';
import applicationRouter from '~/hoc/applicationRouter';
import { ExtraDocsInternal, ExtraDocsContracts } from './ExtraDocs';
import Files from '../Users/Files';

class ExtraDocTabs extends Component {
    state = {
        currenttab: 1,
    };

    Opentab = (tabid) => {
        this.setState({ currenttab: tabid }, () => {
            switch (tabid) {
                case 1:
                    return this.props.router.navigate(`/admin/files`);
                case 2:
                    return this.props.router.navigate(`/admin/internal`);
                case 3:
                    return this.props.router.navigate(`/admin/contracts`);
            }
        });
    };

    getComponent = (tab) => {
        switch (tab) {
            case 1:
                return <Files />;
            case 2:
                return <ExtraDocsInternal mode="internal" />;
            case 3:
                return <ExtraDocsContracts mode="contracts" />;
        }
    };

    render() {
        const {t} = this.props;
        return (
            <>
                <div className="tabContainer">
                    <button
                        className={
                            this.state.currenttab === 1 ? 'tablink tablink__active-left' : 'tablink tablink__left'
                        }
                        onClick={() => this.Opentab(1)}
                    >
                        {t('My Files')}
                    </button>
                    <button
                        className={
                            this.state.currenttab === 2 ? 'tablink tablink__active-center' : 'tablink tablink__center'
                        }
                        onClick={() => this.Opentab(2)}
                    >
                        {t('Internal Documents')}
                    </button>
                    <button
                        className={
                            this.state.currenttab === 3 ? 'tablink tablink__active-right' : 'tablink tablink__right'
                        }
                        onClick={() => this.Opentab(3)}
                    >
                        {t('Documents')}
                    </button>
                </div>
                <div className="page"> {this.getComponent(this.state.currenttab)}</div>
            </>
        );
    }
}

export default applicationRouter(withLocalization(ExtraDocTabs));
