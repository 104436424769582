import PropTypes from 'prop-types';
import React from 'react';
import { v4 as uuid } from 'uuid';

const defaultButton = props => <button {...props}>{props.children}</button>;

export default class Pagination extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visiblePages: this.getVisiblePages(props.page, props.pages),
            activePage: props.page + 1
        };
        this.changePage = this.changePage.bind(this);
    }

    static propTypes = {
        pages: PropTypes.number,
        page: PropTypes.number,
        PageButtonComponent: PropTypes.any,
        onPageChange: PropTypes.func,
    };

    componentWillReceiveProps(nextProps) {
        if (this.props.pages !== nextProps.pages) {
            this.setState({
                visiblePages: this.getVisiblePages(null, nextProps.pages),
            });
        }
    }
    filterPages = (visiblePages, totalPages) => {
        return visiblePages.filter(page => page <= totalPages);
    };

    getVisiblePages = (page, total) => {
        if (total < 9) {
            return this.filterPages([1, 2, 3, 4, 5, 6, 7, 8], total);
        }
        if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
            return [1, total-5, total -4, total - 3, total - 2, total - 1, total];
        }
        if (page % 5 >= 0 && page >= 4 && page + 2 < total) {
            return [1, page-1, page, page + 1, page + 2, page + 3, total];
        }
        return [1, 2, 3, 4, 5, total];
    };

    handleTablePaginationButton = (page, type) => {
        let newPage = ''
        if (type === 'previous') {
            newPage = (page);
        }
        if (type === 'next') {
            newPage = (page + 2);
        }
        this.setState({ activePage: newPage, visiblePages: this.getVisiblePages(newPage, this.props.pages) })
        this.props.handlePagination(newPage)
    }

    changePage(page) {
        this.props.handlePagination(page);
        this.setState({ activePage: page, visiblePages: this.getVisiblePages(page, this.props.pages) });
    }

    render() {
        const { PageButtonComponent = defaultButton } = this.props;
        const { visiblePages, activePage } = this.state;
        return (
            <div className="Table-pag">
                <div key={uuid()} className="Table-pag__prevPageWrapper">
                    <PageButtonComponent
                        key={uuid()}
                        className={(activePage === 1 || activePage < 0)
                            ? 'table_pagination table_pagination__disabled' : 'table_pagination'}
                        onClick={() => {
                            if (activePage === this.props.page) return;
                            this.handleTablePaginationButton(this.props.page, 'previous')
                        }}
                        disabled={activePage === 1 || activePage < 0}
                    >
                        <span className='fw-bold'>«</span>
                    </PageButtonComponent>
                </div>
                <div key={uuid()} className="Table-pag__visiblePagesWrapper">
                    {visiblePages.map((page, index, array) => {
                        return (
                            <>
                                {array[index - 1] + 2 <= page && <span key={uuid()}>...</span>}
                                <div key={uuid()} role={"button"}
                                    className={activePage === page ? 'table_pagination table_pagination__active' : 'table_pagination'}
                                    onClick={() => this.changePage(page)}>
                                    {page}
                                </div>
                            </>
                        )
                    })}
                </div>
                <div key={uuid()} className="Table-pag__visiblePagesWrapper__mobile">
                    <div key={uuid()} className={'table_pagination table_pagination__active'}
                        onClick={() => this.changePage(activePage)}>
                        {activePage}
                    </div>
                </div>
                <div key={uuid()} className="Table-pag__nextPageWrapper">
                    <PageButtonComponent
                        key={uuid()}
                        className={(activePage === this.props.pages || this.props.pages <= activePage)
                            ? 'table_pagination table_pagination__disabled' : 'table_pagination'}
                        onClick={() => {
                            if (activePage === this.props.pages) return;
                            this.handleTablePaginationButton(this.props.page, 'next');
                        }}
                        disabled={activePage === this.props.pages || this.props.pages <= activePage}
                    >
                        <span className='fw-bold'>»</span>
                    </PageButtonComponent>
                </div>
            </div>
        );
    }
}