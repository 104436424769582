import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { filter, find } from 'lodash';
import _ from 'lodash';

//components
import withLocalization from '../../../hoc/withLocalization';

//elements
import Select from '../../../elements/Select';
import CustomMultiSelect from '../../../elements/CustomMultiSelect';

const MemberInProjectsWidgetMobx = inject('projectStore', 'userStore')(
    withLocalization(observer(class MemberInProjectsWidgetMobx extends Component {
        constructor(props) {
            super(props);
            this.state = {
                initted: false,
            };
            this.loadOptions = this.loadOptions.bind(this);
            this.prepareOptions = this.prepareOptions.bind(this);
            this.onChange = this.onChange.bind(this);
        }


        componentDidMount() {
            const { mode, projectStore } = this.props;
            projectStore.loadLookup(mode, '');
        }

        componentDidUpdate(prevProps, prevState, snapshot) {
            const {
                applyOnFirstLoad, value, onChange, projectStore
            } = this.props;
            const { initted } = this.state;
            const { loadingLookup, currentProjectsLookup } = projectStore;
            if (
                !loadingLookup
                && currentProjectsLookup.all
                && currentProjectsLookup.all.length
                && applyOnFirstLoad
                && !initted
            ) {
                const rows = this.prepareOptions(currentProjectsLookup.all);
                let row = find(rows, row => `${row.value}` === `${value}`);
                if (!row) {
                    row = rows.length ? rows[0] : null;
                }
                this.setState({ initted: true });
                if (row) {
                    onChange(row.value, row);
                }
            }
        }

        loadOptions = (inputValue, callback) => {
            const { projectStore } = this.props;
            const { currentProjectsLookup } = projectStore;
            projectStore.loadLookup('all', inputValue).then(() => {
                callback(this.prepareOptions(currentProjectsLookup.all));
            });
        };

        prepareOptions = array => {
            const {
                allowAll, showOnlyCurrentPM, t, userStore, filterByUser, allLabel
            } = this.props;
            const { user_type, id: userId } = userStore.currentUser;
            let _array = filterByUser ? filter(array, a => a.assigned_users.indexOf(filterByUser) >= 0) : array;
            _array = _array
                ? _array.map(pm => {
                    return { value: `${pm.id}`, label: `${pm.fullname}` };
                })
                : [];
            if (allowAll) {
                _array.unshift({ value: '', label: t(allLabel || 'All') });
            }

            if (showOnlyCurrentPM && user_type === 'pm') {
                _array = [];
                if (array) {
                    array.forEach(project => {
                        if (project.assignee_id === userId) {
                            _array.push({ value: `${project.id}`, label: `${project.fullname}` });
                        }
                    });
                }
            }
            return _array;
        };

        onChange(id) {
            const { onChange, multiSelect } = this.props;
            if (multiSelect) {
                onChange(id ? id.join(',') : '');
            } else {
                onChange(id ? id.map(r => r.value).join(',') : '');
            }
        }

        render() {
            const {
                value, projectStore, disabled, multiSelect,
            } = this.props;
            const { currentProjectsLookup } = projectStore;
            const options = this.prepareOptions(currentProjectsLookup.all);
            let currentSelect = _.filter(options, o => {
                return value && value.split(',').indexOf(o.value) >= 0;
            });

            //adding member to default project -29/10/2021/
            if (currentSelect.length === 0 && options.length === 1 && multiSelect) {
                currentSelect = options;
                let ids = options.map(o => o.value.toString());
                this.props.userStore.currentEntity.MemberInProjects = ids.join(',');
                this.onChange(ids)
            }

            const selectedUsers = currentSelect.map(select => select.value);
            return (
                <div className={multiSelect ? '' : 'managers-widget'}>
                    {multiSelect && options ?
                        <CustomMultiSelect
                            options={options}
                            value={selectedUsers || []}
                            onChange={this.onChange}
                        />
                        : <Select
                            isMulti
                            options={options}
                            value={currentSelect}
                            isDisabled={disabled}
                            onChange={id => this.onChange(id)}
                            style={{ width: '100%' }}
                        />}
                </div>
            );
        }
    }))
);

export const MemberInProjectsWidget = observer(class MemberInProjectsWidget extends Component {
    render() {
        return <MemberInProjectsWidgetMobx {...this.props} mode="all" />;
    }
});

export const MemberInProjectsWidgetMultiSelect = observer(class MemberInProjectsWidgetMultiSelect extends Component {
    render() {
        return <MemberInProjectsWidgetMobx {...this.props} mode="all" multiSelect />;
    }
});
