import moment from "moment";
import React,{ Component } from "react"
import applicationRouter from '~/hoc/applicationRouter'
import withLocalization from '~/hoc/withLocalization';
import { inject, observer } from 'mobx-react';
import { timeFormat } from '~/utils/dateFormat'
class MemberAvailability extends Component {
    constructor(props){
        super(props);
    }
    render()
    {
        const {obj,commonStore,t} = this.props;
        const dateTimeRules = commonStore.config.client && commonStore.config.client.data && commonStore.config.client.data.dateTimeRules ? commonStore.config.client.data.dateTimeRules : false;
        return(
            <>
                <div className="border-789BD0 border-radius-10 mt-10 p-3">
                    <div className='row'>
                        <table className='col-md-12'>
                            <tr>
                                <th className='col-md-3 text-center'>{t("Date")}</th>     
                                <th className='col-md-3 text-center'>{t("Member Name")}</th>
                                <th className='col-md-3 text-center'>{t("Availability")}</th>
                                <th className='col-md-3 text-center'>{t("Time")}</th>    
                            </tr>
                        </table>
                    </div>
                    <hr className="mt-10 mb-10" style={{borderTop : '1.5px solid #ccc' }}/>
                    {obj && obj.map((obj, index)=>
                        <div className='row' key={index}>
                        <table className='col-md-12'>
                            <tr>
                                {index==0 ?
                                <td className='col-md-3 text-center'>{moment(obj.for_date).format('YYYY-MM-DD')}</td>
                                :
                                <td className='col-md-3 text-center'></td>
                                }
                                <td className='col-md-3 text-center'>{obj.first_name+" "+obj.last_name}</td>
                                {obj.availability_type =='available' &&
                                    <td className='col-md-3 text-center' style={{ color : 'green'}}>{t("Available")}</td>
                                }
                                {obj.availability_type =='not available' &&
                                    <td className='col-md-3 text-center' style={{ color : 'red'}}>{t("Unavailable")}</td>
                                }
                                {( (obj.from==0 || obj.from == null) && (obj.to==0 || obj.to == null) ) ?
                                    <td className='col-md-3 text-center'>{t("Full day")}</td>
                                    :
                                    <td className='col-md-3 text-center'>{t("from")} {timeFormat(obj.from, dateTimeRules)} {t("to")} {timeFormat(obj.to, dateTimeRules)}</td>
                                }
                            </tr>
                        </table>
                    </div>
                    )}
                </div>  
            </>
        )
    }
}
export default inject('timelogStore', 'userStore', 'commonStore')(applicationRouter(withLocalization(observer(MemberAvailability))));