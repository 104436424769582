import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { v4 as uuid } from 'uuid';

//components//
import applicationRouter from '~/hoc/applicationRouter'
import withLocalization from '~/hoc/withLocalization';
import Checkbox from '../../../../components/CustomCheckbox/CustomCheckbox.jsx';

//elements//
import Select from '../../../../elements/Select.js';
import { TripleTexWidget } from '../../../../components/GenericForm/Widgets/TripleTexWidget.js'
import { VismaWidget } from '../../../../components/GenericForm/Widgets/VismaWidget.js'
import TripleTex from '../../../../assets/img/tripletex.svg'
import Visma from '../../../../assets/img/visma.png'


//constants//
const payrollProductOptions = [
    { value: 'tripletex', label: 'TripleTex', title: 'Tripletex', image: TripleTex },
    { value: 'dnbregnskap', label: 'DnBregnskap', title: 'DnBregnskap' },
    { value: 'visma', label: 'Visma', title: 'Visma', image: Visma }
]


class PayrollIntegrations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            payrollProduct: this.props.clientStore.currentEntity?.client?.integration_details?.product
        }
        this.translateLabels = this.translateLabels.bind(this);
    }


    translateLabels = () => {
        const { t } = this.props;
        payrollProductOptions.map(item => {
            item.label = item.image ? (
            <div className='d-flex align-items-center'>
                <img src={item.image} height="30px" width="30px" />
                <div className='ml-10'>{t(item.title)}</div>
            </div>
            )
            : <div>{t(item.title)}</div>
        })
    }

    handleChange = (evt) => {
        this.setState({ payrollProduct: evt })
    }

    handleDropDown = (payrollProduct) => {
        switch (payrollProduct) {
            case 'tripletex':
                return <TripleTexWidget payrollProduct={payrollProduct}/>
            case 'dnbregnskap':
                return (<div className='d-flex justify-content-center mt-5'>{this.props.t('Coming Soon')}</div>)
            case 'visma':
                return <VismaWidget payrollProduct={payrollProduct}/>
            default :
                return (<div className='d-flex justify-content-center mt-5'>{this.props.t('Please Select which integration you want to use')}</div>)
        }
    }

    render() {
        const { payrollProduct } = this.state
        this.translateLabels();
        return (
            <div>
                <Col xs={12} sm={4}>
                    <label className="control-label">{this.props.t('Payroll Product')}</label>
                    <Select
                        options={payrollProductOptions}
                        value={payrollProductOptions.find(item => item.value === payrollProduct)}
                        onChange={evt => this.handleChange(evt.value)}
                        style={{ width: '100%' }}
                    />
                </Col>
                {this.handleDropDown(payrollProduct)}
            </div>
        );
    }
}
export default inject('clientStore', 'commonStore', 'userStore')(applicationRouter(withLocalization(observer(PayrollIntegrations))));