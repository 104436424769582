import React, { Component } from 'react';
import { Routes, Route, NavLink } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';
import { v4 as uuid } from 'uuid';


//components
import applicationRouter from '../hoc/applicationRouter';
import withLocalization from '~/hoc/withLocalization';
import visitorRoutes from '../visitorRoutes';
import AdminNavbar from '../components/Navbars/AdminNavbar.jsx';
import Sidebar from '../components/Sidebar/Sidebar';
import LogoutPage from './Logout';
import { defaultLanguage } from '~/library/core'

//elements
import routes from '../routes';

//styles
import 'react-toastify/dist/ReactToastify.css';

// utils
import customerFeature from '~/utils/customerFeature';

import listIcon from '../assets/img/new_design/jsx_adminnavbar_svg/list.svg';
import whiteListIcon from '../assets/img/new_design/jsx_adminnavbar_svg/white_list.svg';
import briefCaseIcon from '../assets/img/new_design/jsx_adminnavbar_svg/brief_case.svg';
import whiteBriefCaseIcon from '../assets/img/new_design/jsx_adminnavbar_svg/white_briefcase.svg';
import timesheetIcon from '../assets/img/new_design/jsx_adminnavbar_svg/timesheet.svg';
import whiteTimesheetIcon from '../assets/img/new_design/jsx_adminnavbar_svg/white_timesheet.svg';
import checkListIcon from '../assets/img/new_design/jsx_adminnavbar_svg/checklist.svg';
import whiteCheckList from '../assets/img/new_design/jsx_adminnavbar_svg/white_checklist.svg';
import userIcon from '../assets/img/new_design/jsx_adminnavbar_svg/user.svg';
import whiteUser from '../assets/img/new_design/jsx_adminnavbar_svg/white_user.svg';

const extraLinkModules = [
    { name: 'Timesheet', value: '1', svg: timesheetIcon, whiteSvg: whiteTimesheetIcon, route_map: '/admin/timelogs' },
    { name: 'Users', value: '2', svg: userIcon, whiteSvg: whiteUser, route_map: '/admin/members' },
    { name: 'Projects', value: '3', svg: briefCaseIcon, whiteSvg: whiteBriefCaseIcon, route_map: '/admin/projects' },
    { name: 'Checklist', value: '4', svg: checkListIcon, whiteSvg: whiteCheckList, route_map: '/admin/checklist' },
    { name: 'Payroll report', value: '5', svg: listIcon, whiteSvg: whiteListIcon, route_map: '/admin/reports/payroll' },
];

class Admin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            color: 'black',
            hasImage: true,
            navbar: false,
            mini: false,
            fixedClasses: 'dropdown show-dropdown open',
            hideCollapseView: false,
            isLogout: false,
            width: window.screen.width
        };
    }

    getRoutes = (routes) => {
        const { userStore, clientStore } = this.props;
        const { currentUser } = userStore;
        if (!currentUser) return <></>;
        const client = clientStore?.currentEntity?.client
        return routes.map((prop, key) => {
            if (prop.allowed === undefined || prop.allowed(currentUser, client)) {
                if (prop.collapse) {
                    return this.getRoutes(prop.views);
                }
                if (prop.hasChildRoutes) {
                    const parent = { ...prop };
                    if (parent.hasChildRoutes) delete parent.hasChildRoutes;
                    if (parent.childRoutes) delete parent.childRoutes;
                    const combinedRoutes = [parent, ...prop.childRoutes];
                    return this.getRoutes(combinedRoutes);
                }
                if (prop.component !== undefined) {
                    return <Route path={prop.path} key={uuid()} element={<prop.component />} />;
                }
                return null;
            } else {
                return null;
            }
        });
    };

    componentDidMount() {
        window.addEventListener('scroll', this.isSticky);
    }

    isSticky() {
        const screenSize = window.screen;
        if (screenSize && screenSize.width && screenSize.width > 992) {
            const header = document.querySelector('.MainWrapperAdmin');
            const scrollTop = window.scrollY;
            scrollTop >= 150 && header ? header?.classList.add('is-sticky') : header?.classList.remove('is-sticky');
        }
    }

    handleLogoutLang = () => {
        const lang = sessionStorage.getItem('locale');
        if (!(lang === 'no' || lang === 'en' || lang === 'es'))
            sessionStorage.setItem('locale', defaultLanguage() === 'Norsk' ? 'no' : 'en');
    }

    handleLogOut = (e) => {
        if (e) e.preventDefault();
        this.props.userStore.forgetUser();
        this.setState({ isLogout: true })
        // this.props.router.navigate('/');
        setTimeout(() => {
            const host = window.location.hostname;
            const port = window.location.port;
            const url = host === 'localhost' ? `http://${host}:${port}` : `https://${host}`;
            window.location.replace(url)
            this.handleLogoutLang()
        }, "1000");
    };

    handleMiniClick = () => {
        this.setState({ hideCollapseView: true });
        document.getElementById('mySidenav').style.width = '80px';
        document.getElementById('main-panel').style.width = 'calc(100% - 80px)';
        document.getElementById('logoimg').style.width = '50px';
        document.getElementById('logoimg').style.height = '50px';
        document.getElementById('minimizeSidebar').style.display = 'none';
        document.getElementById('minimizeSidebarClose').style.display = 'flex';
        document.getElementById('sidebar-wrapper').style.height = '100%';
        document.getElementById('sidebar-wrapper').style.overflowX = 'hidden';
        document.querySelectorAll('#menuitem-name > .caret').forEach((el) => (el.style.display = 'none'));
    };

    handleCrossClick = () => {
        this.setState({ hideCollapseView: false });
        document.getElementById('mySidenav').style.width = '260px';
        document.getElementById('main-panel').style.width = 'calc(100% - 260px)';
        document.getElementById('logoimg').style.width = '213px';
        document.getElementById('logoimg').style.height = '213px';
        document.getElementById('minimizeSidebar').style.display = 'flex';
        document.getElementById('minimizeSidebarClose').style.display = 'none';
        document.getElementById('sidebar-wrapper').style.height = 'calc(100% - 170px)';
        document.querySelectorAll('#menuitem-name > .caret').forEach((el) => (el.style.display = 'block'));
    };

    enteringOnOverlay = (e) => {
        e.children[1].style.backgroundColor = '#2550AC';
        e.children[1].style.color = '#fff';
        e.children[1].style.borderRadius = '10px';
    };

    getExtralinks = (user_type) => {
        if (!user_type || user_type !== 'member') return extraLinkModules;
        const filteredLinks = extraLinkModules.filter((ele) => ele.name !== 'Users')
        if (!(customerFeature('member_allow_see_projects'))) return filteredLinks.filter(ele => ele.name !== 'Projects')
        return filteredLinks;
    }

    handleScreenSize = () => {
        let screenWidth = window.screen.width
        this.setState({ width: screenWidth });
    }

    render() {
        const { commonStore, authStore, signatureStore, t, userStore } = this.props;
        const { isFullScreen } = signatureStore;

        const extraModuleLinks = this.getExtralinks(userStore?.currentUser?.user_type);
        window.addEventListener("resize", this.handleScreenSize);
        // if(!userStore?.currentUser) return <></>
        if (this.state.isLogout) {
            return <LogoutPage t={t} />
        }
        return (
            <div>
                <ToastContainer />
                {authStore &&
                    commonStore &&
                    commonStore.config &&
                    commonStore.config.client &&
                    (commonStore.config.client.allow_visitor_only === undefined ||
                        !commonStore.config.client.allow_visitor_only) ? (
                    <>
                        {isFullScreen ? (
                            <></>
                        ) : (
                            <Sidebar
                                {...this.props}
                                routes={routes}
                                hasImage
                                handleMiniClick={this.handleMiniClick}
                                handleCrossClick={this.handleCrossClick}
                                hideview={this.state.hideCollapseView}
                            />
                        )}
                        <div
                            className="main-panel"
                            id="main-panel"
                            ref="mainPanel"
                            style={isFullScreen ? { width: '100%' } : {}}
                        >
                            {authStore &&
                                commonStore &&
                                commonStore.connectionError &&
                                !authStore.inProgress &&
                                false && <div style={{ background: 'red', padding: 20 }}>ERROR IN CONNECTION</div>}
                            {/* Collapse sidear */}
                            {isFullScreen ? (
                                <> </>
                            ) : (
                                <div className="collapse-sidebar-arrow">
                                    <OverlayTrigger
                                        placement="right"
                                        style={{ backgroundColor: 'blue' }}
                                        onEntering={this.enteringOnOverlay}
                                        overlay={<Tooltip id="button-tooltip">{t('Collapse')}</Tooltip>}
                                    >
                                        <button
                                            id="minimizeSidebar"
                                            className="btn btn-round shadow btnsidebar"
                                            onClick={this.handleMiniClick}
                                        >
                                            <i className="fa fa-angle-left" aria-hidden="true"></i>
                                        </button>
                                    </OverlayTrigger>
                                </div>
                            )}

                            {/* Expand sidebar */}
                            <div className="expand-sidebar-arrow">
                                <OverlayTrigger
                                    placement="right"
                                    onEntering={this.enteringOnOverlay}
                                    overlay={<Tooltip id="button-tooltip">{t('Expand')}</Tooltip>}
                                >
                                    <button
                                        id="minimizeSidebarClose"
                                        className="btn btn-round shadow btnsidebar"
                                        onClick={this.handleCrossClick}
                                    >
                                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                                    </button>
                                </OverlayTrigger>
                            </div>

                            <AdminNavbar
                                {...this.props}
                                handleMiniClick={this.handleMiniClick}
                                handleLogOut={this.handleLogOut}
                                navbar={this.state.navbar}
                                routes={routes}
                            />
                            <div className="main-content">
                                <Routes>{this.getRoutes(routes)}</Routes>
                            </div>
                        </div>
                        <div>
                            {this.state.width < 992 ? (
                                <div className="header-functions-menu">
                                    {extraModuleLinks.map((headerOption) => (
                                        <>
                                            <NavLink
                                                to={headerOption.route_map}
                                                key={uuid()}
                                                className={({ isActive }) =>
                                                    isActive
                                                        ? 'header-functions-menu-item-mobile-active'
                                                        : 'header-functions-menu-item'
                                                }
                                            >
                                                <img
                                                    src={headerOption.svg}
                                                    alt="navlinks"
                                                    width="20px"
                                                    height="20px"
                                                    className="header-functions-img-mobile"
                                                />
                                                {t(headerOption.name)}
                                            </NavLink>
                                        </>
                                    ))}
                                </div>
                            ) : <></>}
                        </div>
                    </>
                ) : (
                    <>
                        <Sidebar
                            {...this.props}
                            routes={visitorRoutes}
                            hasImage
                            handleMiniClick={this.handleMiniClick}
                            handleCrossClick={this.handleCrossClick}
                            hideview={this.state.hideCollapseView}
                        />
                        <div className="main-panel" id="main-panel" ref="mainPanel">
                            {authStore &&
                                commonStore &&
                                commonStore.connectionError &&
                                !authStore.inProgress &&
                                false && <div style={{ background: 'red', padding: 20 }}>ERROR IN CONNECTION</div>}
                            <AdminNavbar
                                {...this.props}
                                handleMiniClick={this.handleMiniClick}
                                handleLogOut={this.handleLogOut}
                                navbar={this.state.navbar}
                                routes={visitorRoutes}
                            />
                            <div className="main-content">
                                <Routes>{this.getRoutes(visitorRoutes)}</Routes>
                            </div>
                        </div>
                    </>
                )}
            </div>
        );
    }
}

export default inject(
    'userStore',
    'commonStore',
    'authStore',
    'signatureStore',
    'clientStore'
)(withLocalization(applicationRouter(observer(Admin))));
