import React, { Component, Fragment } from 'react';
import applicationRouter from '~/hoc/applicationRouter'
import { inject, observer } from 'mobx-react';
import moment from 'moment/min/moment-with-locales';
import _ from 'lodash';

// HOC
import withLocalization from '../../../hoc/withLocalization';
import withRemoveDialog from '~/hoc/withRemoveDialog';
// Components
import {AttachmentsWidget} from '../../../components/GenericForm/Widgets/AttachmentsWidget';
import Button from '../../../components/CustomButton/CustomButton.jsx';

// Elements
import { Input, Textarea } from '../../../elements/Input';
import LoadingSpinner from '../../../elements/LoadingSpinner';
import Checkbox from '../../../components/CustomCheckbox/CustomCheckbox';

import customerFeature from '../../../utils/customerFeature';

class ExtraDocForm extends Component {
    state = {
        showErrors: false,
    };

    getCurrentIdAndMode = () => {
        const path =  this.props.router.location.pathname.split('/')
        const id = path.pop();   
        const mode = path.pop();
        return { add: id === 'add', id, mode };
    };

    loadData() {
        const { extradocStore} = this.props;
        const {id , add} = this.getCurrentIdAndMode();
        if (add) return extradocStore.returnDefaultNew();
        extradocStore.load(id, add);
    }

    componentWillMount() {
        this.props.extradocStore.loading = true;
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(previousProps) {
        const { location } = this.props;
        if (location !== previousProps.location) {
            this.loadData();
        }
    }
    
    onSave() {
        const {extradocStore} = this.props;
        const {mode,add} = this.getCurrentIdAndMode();
        const preparedData = extradocStore.getPreparedData;
        preparedData.doc_type = mode;
        this.setState({showErrors: true});
        this.saveExtradoc(preparedData, add);
    }

    saveExtradoc = (changes, add) => {
        const {
            extradocStore,t, commonStore
        } = this.props;        
        extradocStore
            .save(changes, add)
            .then(({ id }) => {
                commonStore.addNotification(t('Saved'), null, 'success');
                const { currentEntity: fullExtradoc } = extradocStore;
                if (add) {
                    this.props.router.navigate(`/admin/${fullExtradoc.doc_type == 'internal' ? 'internal' : 'contracts'}/${id}`);
                }else{
                    this.props.router.navigate(`/admin/${fullExtradoc.doc_type == 'internal' ? 'internal' : 'contracts'}`);
                }
            })
            .catch(err => {
                commonStore.addNotification(t(err), null, 'error');
            });
    };

    handleChange = (name, value) => {
        this.props.extradocStore.handleChange(name, value);
    };

    onFileUpload = name => {
        const {
            extradocStore,            
        } = this.props;        
        const { currentEntity: extradoc } = extradocStore;
        if (!extradoc.name) this.props.extradocStore.handleChange('name', name);
    };

    onDelete = () => {
        // const { history } = this.props;
        const {mode} = this.getCurrentIdAndMode();
        const { id } = this.props.extradocStore.currentEntity;
        return this.props.handleDelete(id, id => {
            return this.props.extradocStore.remove(id).then(res => {
                this.props.router.navigate(`/admin/${mode == 'internal' ? 'internal' : 'contracts'}`);
                // history.push(`/admin/${mode == 'contracts' ? 'contracts' : 'internal'}`);
                return res;
            });
        });
    };

    render() {
        const {
            extradocStore,            
            t,
            userStore: { currentUser },            
        } = this.props;
        const isUnion = customerFeature('union_group');
        const { showErrors } = this.state;
        const { loading, currentEntity: extradoc } = extradocStore;
        const userType = currentUser.user_type;
        let isPm = userType === 'admin' || userType === 'pm';
        if (isUnion) {
            if (!extradoc.id) isPm = true; // allow adding
            if (extradoc.created_by === currentUser.id) isPm = true;
        }

        if (loading) {
            return <LoadingSpinner />;
        }
        return (
            <>
                <div className='page'>
                    <div className="page__header">
                        <h1 className="page__title">{t('Registered Document')}</h1>
                    </div>
                    <div className="page__content">
                        <form className="custom-form">
                            <div className="custom-form__row mt-2">
                                <Input
                                    className="custom-form__col"
                                    label={`${t('Name')} *`}
                                    placeholder={t('Name')}
                                    error={showErrors && !extradoc.name}
                                    type="text"
                                    value={extradoc.name}
                                    onChange={({ target: { name, value } }) => this.handleChange(name, value)}
                                    name="name"
                                    disabled={!isPm}
                                />
                                <div className="custom-form__col pt-4">
                                    <Checkbox
                                        isChecked={!(_.get(extradoc, 'data.disableAvailableForEverybody'))}
                                        onChange={(checked) => /* this.handleChange('do_notify', checked */ this.handleChange('data.disableAvailableForEverybody', !checked)}
                                        label={this.props.t('Available for all')}
                                        style={{ marginRight: 30, marginLeft: 30 }}
                                        disabled={!isPm}
                                    />
                                </div>
                            </div>
                            <div className="custom-form__row mt-2">
                                <Textarea
                                    className="custom-form__col"
                                    label={t('Details')}
                                    error={showErrors && !extradoc.description}
                                    placeholder={t('description')}
                                    value={extradoc.description}
                                    onChange={({ target: { name, value } }) => this.handleChange(name, value)}
                                    name="description"
                                    disabled={!isPm}
                                />
                            </div>

                            <div className="custom-form__row">
                                <AttachmentsWidget
                                    value={extradoc.attachments}
                                    onUpload={name => this.onFileUpload(name)}
                                    onChange={attachIds => this.handleChange('attachments', attachIds)}
                                    options={{
                                        checkDate: false,
                                        imageContext: {
                                            existingAttachment: extradoc.Attachments,
                                            fileType: 'docs',
                                            id: 0,
                                            model: 'ExtraDoc',
                                        },
                                    }}
                                    disabled={!isPm}
                                />
                            </div>

                            {isPm && (
                                <div className="custom-form__row mt-2">
                                    <div
                                        className="custom-form__col custom-form__col_grow-0 "
                                        style={{ marginLeft: 'auto' }}
                                    >
                                        <Button onClick={() => this.onSave()} fill wd>
                                            {t(extradoc.id > 0 ? 'Save' : 'Create')}
                                        </Button>
                                    </div>
                                    {extradoc.id && (
                                        <div className="custom-form__col custom-form__col_grow-0 pull-right">
                                            <Button danger onClick={() => this.onDelete()} fill wd>
                                                {t('Delete')}
                                            </Button>
                                        </div>
                                    )}
                                </div>
                            )}
                        </form>
                    </div>
                </div>
            </>
        );
    }
}

export default inject('extradocStore', 'commonStore', 'userStore')(applicationRouter(withLocalization(withRemoveDialog(observer(ExtraDocForm)))));
