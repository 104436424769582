import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

//components
import applicationRouter from '~/hoc/applicationRouter'
import withLocalization from '~/hoc/withLocalization';
import TableWidget from '../TableWidget.js';

class UserGroups extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user_groups: [],
        }
        this.loadData = this.loadData.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = () => {
        const { currentEntity } = this.props.clientStore;
        if (currentEntity.client.data && currentEntity.client.data.extendedRules) {
            const extendedRules = Object.assign({}, currentEntity.client.data.extendedRules);
            this.setState({
                user_groups: extendedRules.user_groups
            })
        }
    }

    validateGroupUsageBeforeDelete = async (code) => {
        const { t } = this.props;
        try {
            const { extraPayments } = this.props.commonStore.config.client.data;
            const isAllGroupsInUse = extraPayments.find(ele => ele.isActive && ele.userGroups === 'all');
            if (isAllGroupsInUse) {
                return { isValid: false, message: t("Can't remove group as its assigned to active extra payment") };
            } else {
                for (let extraPayment of extraPayments) {
                    if (extraPayment.isActive && extraPayment.userGroups.split(',').indexOf(`${code}`) > -1)
                        return { isValid: false, message: t("Can't remove group as its assigned to active extra payment") }
                }
            }
            await this.props.clientStore.checkIfGroupInUse(code);
            return { isValid: true };
        }
        catch (err) {
            console.error({ err })
            return { isValid: false, message: t("Can't remove group as its assigned to an employee") };
        }
    }

    render() {
        const { user_groups } = this.state;
        return (
            <div>
                <TableWidget
                    headers={['Code', 'Group name']}
                    metaData={[{ field: 'code', type: 'number', placeholder: 'Ex. 101', required: true, unique: true },
                    { field: 'name', type: 'text', placeholder: 'Group name', required: true, unique: true }]}
                    data={user_groups}
                    onChange={data => this.props.handleUserDataChange('user_groups', data)}
                    deleteValidator={async (code) => this.validateGroupUsageBeforeDelete(code)}
                />
            </div >
        );
    }
}
export default inject('clientStore', 'commonStore')(applicationRouter(withLocalization(observer(UserGroups))));
