import React from 'react';
import { confirmAlert as confirmAlertLib } from 'react-confirm-alert';
import {v4 as uuid} from 'uuid';

import Button from '../CustomButton/CustomButton.jsx';

export const confirmAlert = props => {
    const handleClick = (onAction, onClose) => {
        if (onAction) {
            onAction();
        }
        onClose();
    };
    const { title = '', message = '', buttons = [] } = props;
    return confirmAlertLib({
        customUI: ({ onClose }) => (
            <div className="react-confirm-alert-body react-confirm-alert-body_custom">
                {title && <h1>{title}</h1>}
                {message && message}
                {!!buttons.length && (
                    <div className="react-confirm-alert-button-group">
                        {buttons.map(button => (
                            <button key={uuid()} onClick={() => handleClick(button.onClick, onClose)}>
                                {button.label}
                            </button>
                        ))}
                    </div>
                )}
            </div>
        ),
    });
};

export const confirmCustomAlert = props => {
    const handleClick = (onAction, onClose) => {
        if (onAction) {
            onAction();
        }
        onClose();
    };
    const { title = '', message = '', buttons = [] } = props;
    return confirmAlertLib({
        customUI: ({ onClose }) => (
            <div className="custom-confirm-box">
                {title && <h4 className="custom-confirm-box__title">{title}</h4>}
                {message && <span className="custom-confirm-box__desc">{message}</span>}
                {!!buttons.length && (
                    <div className="custom-confirm-box__buttons">
                        {buttons.map(button => (
                            <div className="custom-confirm-box__button" key={uuid()}>
                                <Button
                                    fill
                                    wd
                                    danger={button.danger}
                                    onClick={() => handleClick(button.onClick, onClose)}
                                >
                                    {button.label}
                                </Button>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        ),
    });
};
