import React, { Component } from 'react';
import applicationRouter from '~/hoc/applicationRouter'
import { inject, observer } from 'mobx-react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Row, Col } from 'react-bootstrap';
// import LoadingSpinner from '~/elements/LoadingSpinner';
import withLocalization from '~/hoc/withLocalization';
import './Recipients.css';
import DragImage from './../../../assets/img/drag_image.svg';
import PlusRounded from './../../../assets/img/plus_rounded.svg';
import Button from '../../../components/CustomButton/CustomButton';
import { serverValidation } from '../../../library/core'
class RecipientsForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formValues: [{ id: '', recipientName: "", email: "", phoneNumber: '', accessCode: '', select_value: 'Need Sign', isChecked: false, isContract: false }],
            is_add_drag_drop: JSON.parse(sessionStorage.getItem("DisableDragDrop")) != null ? JSON.parse(sessionStorage.getItem("DisableDragDrop")) : true,
        }

        this.handleSignOrder = this.handleSignOrder.bind(this);
    }
    componentDidMount() {
        const { recipientStore, signatureStore } = this.props;
        recipientStore.fetchAllRecipients(signatureStore.caseId).then(() => {
            if (recipientStore.draftRecipientList) {
                this.setState({ formValues: recipientStore.draftRecipientList });
            }
        })

    }
    handleAddFields() {
        const { commonStore, recipientStore, t } = this.props
        let formValues = this.state.formValues;
        let error = false;
        formValues.forEach(value => {

            if (value.recipientName === "" || value.email === "") {
                error = true;
                commonStore.addNotification(t('Please add require fields'), null, 'error');
                return false;
            }
            if (value.email && !this.emailValidation(value.email)) {
                error = true;
                commonStore.addNotification(t('Please add valid email'), null, 'error');
                return false;
            }
            if (!value.phoneNumber.match(serverValidation('phone'))) {
                commonStore.addNotification(t('Invalid phone number'), null, 'error');
                error = true;
                return;
            }
            if (formValues.length >= 2) {
                for (let i = 0; i < formValues.length; i++) {
                    for (let j = i + 1; j < formValues.length; j++) {
                        if (formValues[i].email == formValues[j].email) {
                            error = true;
                            commonStore.addNotification(t('A user with this email address already exists.'), null, 'error');
                            return false;

                        }
                    }
                }
            }
        })

        if (!error) {
            const emptyFormValues = { recipientName: "", email: "", phoneNumber: '', accessCode: '', select_value: 'Need Sign', isChecked: false, isContract: false };
            const tempFormValues = this.state.formValues;
            tempFormValues.push(emptyFormValues);
            recipientStore.setDraftRecipientData(formValues, this.state.is_add_drag_drop);
            this.setState({ formValues: tempFormValues });
        }
    }

    emailValidation(emailValue) {
        const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (regex.test(emailValue) === false) {
            return false;
        }
        return true;
    }

    handleInputChange(index, event) {
        const { recipientStore } = this.props;

        let formValues = this.state.formValues;
        formValues[index][event.target.name] = event.target.value
        recipientStore.setDraftRecipientData(formValues, this.state.is_add_drag_drop);
        this.setState({ formValues });
    }

    handleRemoveFields(i) {
        const { recipientStore, propertyStore, signatureStore } = this.props;
        let formValues = this.state.formValues;
        let id = formValues[i].id;
        if (id) {
            propertyStore.deleteWidgetByRecipientId(id);
            propertyStore.saveWidget({ caseId: signatureStore.caseId, widgets: propertyStore.widgets })
        }
        formValues.splice(i, 1);
        recipientStore.setDraftRecipientData(formValues, this.state.is_add_drag_drop);
        this.setState({ formValues });
    }


    handleDragEnd(results) {
        const { recipientStore } = this.props;
        if (!results.destination) return;
        const { formValues } = this.state;
        let tempFormValues = [...formValues];
        let [selectedRow] = tempFormValues.splice(results.source.index, 1)
        tempFormValues.splice(results.destination.index, 0, selectedRow);
        recipientStore.setDraftRecipientData(tempFormValues, this.state.is_add_drag_drop);
        this.setState({ formValues: tempFormValues });
    }


    handleCheckBoxChange(index, event) {
        const { recipientStore } = this.props;
        let formValues = this.state.formValues;
        const code = Math.floor(100 + Math.random() * 900);
        formValues[index].isChecked = !formValues[index].isChecked
        formValues[index].accessCode = code;
        recipientStore.setDraftRecipientData(formValues, this.state.is_add_drag_drop);
        this.setState({ formValues });
    }

    hideCharacters(str) {
        return str.toString().concat("***");
    }

    handleSignOrder() {
        // const{is_add_drag_drop} = this.state;
        const { recipientStore } = this.props;

        this.setState({ is_add_drag_drop: !this.state.is_add_drag_drop }, () => recipientStore.setDraftRecipientData(this.state.formValues, this.state.is_add_drag_drop));
        sessionStorage.setItem("DisableDragDrop", JSON.stringify(!this.state.is_add_drag_drop));
    }

    render() {
        const { t } = this.props;
        const { formValues, is_add_drag_drop } = this.state;
        // if (loading) {
        //     return <LoadingSpinner />;
        // }
        return (
            <DragDropContext onDragEnd={(results) => this.handleDragEnd(results)}>
                <div className="primary-page" style={{ padding: '0px 8px' }}>
                    <div style={{ marginBottom: '10px' }}>
                        <input
                            type="checkbox"
                            checked={!is_add_drag_drop}
                            className="input_checkbox"
                            onChange={() => this.handleSignOrder()}
                        />{' '}
                        <span className="set_signing_order">{t('Set Signing Order')}</span>
                    </div>
                    <Droppable droppableId="recipients_details">
                        {(provided) => (
                            <div
                                className="container"
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                style={{ width: 'inherit' }}
                            >
                                {formValues.map((inputField, index) => (
                                    <Draggable
                                        draggableId={`id_${index}`}
                                        index={index}
                                        key={index}
                                        isDragDisabled={is_add_drag_drop}
                                    >
                                        {(provided) => (
                                            <div
                                                key={index}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                className="row form_row"
                                            >
                                                {!is_add_drag_drop && (
                                                    <div {...provided.dragHandleProps}>
                                                        <span className="sign_order">{index + 1}</span>
                                                        <div
                                                            className={
                                                                is_add_drag_drop
                                                                    ? 'drag_image_container'
                                                                    : 'drag_image_container_move'
                                                            }
                                                        >
                                                            <img
                                                                src={DragImage}
                                                                alt="Drag_Image"
                                                                className="drag_image"
                                                            // {...provided.dragHandleProps}
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                                <Col md={11}>
                                                    <Row>
                                                        <Col md={4} className="required_field">
                                                            <span className="required">*</span>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="recipientName"
                                                                name="recipientName"
                                                                placeholder={this.props.t("Recipient Name")}
                                                                value={inputField.recipientName}
                                                                onChange={(event) => this.handleInputChange(index, event)}
                                                            />
                                                        </Col>
                                                        <Col className='required_field' md={4}>
                                                            <span className="required">*</span>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="email"
                                                                name="email"
                                                                placeholder={this.props.t("Email")}
                                                                value={inputField.email}
                                                                onChange={(event) => this.handleInputChange(index, event)}
                                                                disabled={inputField.isContract}
                                                            />
                                                        </Col>
                                                        <Col md={4}>
                                                            <input
                                                                type="text"
                                                                className="form-control phoneee"
                                                                id="phoneNumber"
                                                                name="phoneNumber"
                                                                placeholder={this.props.t("Phone Number")}
                                                                value={inputField.phoneNumber}
                                                                onChange={(event) => this.handleInputChange(index, event)}
                                                            />
                                                        </Col>
                                                        <Col className='required_field mt-3' md={4}>
                                                            <span className="required">*</span>
                                                            <select
                                                                name="select_value"
                                                                value={inputField.select_value}
                                                                defaultValue={'Need Sign'}
                                                                onChange={(event) => this.handleInputChange(index, event)}
                                                                className={'default_select form-control'}
                                                                aria-label="Select"
                                                            >
                                                                <option value="Need Sign" style={{ color: '#333333' }}>
                                                                    {t('Need to Sign')}
                                                                </option>
                                                                <option value="Send Copy" style={{ color: '#333333' }}>
                                                                    {t('Send a Copy')}
                                                                </option>
                                                            </select>
                                                        </Col>
                                                        <Col md={4} className="mt-3" style={{ marginLeft: '17px' }}>
                                                            <div style={{ width: '94%' }}>
                                                                <div className="set_checkbox_input">
                                                                    <input
                                                                        type="checkbox"
                                                                        name="accessCode"
                                                                        checked={inputField.isChecked}
                                                                        onChange={(event) =>
                                                                            this.handleCheckBoxChange(index, event)
                                                                        }
                                                                        className="input_checkbox"
                                                                    />
                                                                </div>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="accessCode"
                                                                    name="accessCode"
                                                                    placeholder={this.props.t("Access Code")}
                                                                    value={
                                                                        inputField.isChecked
                                                                            ? this.hideCharacters(inputField.accessCode)
                                                                            : ''
                                                                    }
                                                                    style={{
                                                                        paddingLeft: '50px',
                                                                        cursor: 'not-allowed',
                                                                        color: '#333333',
                                                                    }}
                                                                    onChange={() => { }}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>

                                                {!inputField.isContract && (
                                                    <Col md={1} className="text-center">
                                                        <button
                                                            className="btn_action"
                                                            type="button"
                                                            disabled={index === 0}
                                                            onClick={() => this.handleRemoveFields(index)}
                                                            style={{ marginRight: '15px', backgroundColor: 'red' }}
                                                        >
                                                            <i className="fa fa-minus"></i>
                                                        </button>
                                                    </Col>
                                                )}
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                    <div className="add_signer_container">
                        <Button fill onClick={() => this.handleAddFields()}>
                            <img className="me-2" src={PlusRounded} alt="plus btn" />
                            {t('Add Signers')}
                        </Button>
                    </div>
                </div>
            </DragDropContext>
        );
    }
}

export default inject('userStore', 'commonStore', 'recipientStore', 'signatureStore', 'propertyStore')(applicationRouter(withLocalization(observer(RecipientsForm))));

