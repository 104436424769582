import { observable, action, makeObservable } from 'mobx';
import MultiAccountAdminAgent from '../multiAccountAgent';

class MultiAccountAdminStore {
    constructor() {
        makeObservable(this, {
            currentAccountListForAdmin: observable,
            lastListLoadTime: observable,
            getAccountListForAdmin: action,
            checkMultiAccountAdminVerificationLink: action,
            addAccountForMultiAccountAccess: action,
            verifyMultiAccountEmail: action,
            generateVerificationOtp: action,
            removeAccount: action,
            resetLastListLoadTime: action
        });
    }
    currentAccountListForAdmin = [];
    lastListLoadTime = null;

    resetLastListLoadTime() {
        this.lastListLoadTime = new Date();
    }

    getAccountListForAdmin() {
        return MultiAccountAdminAgent.getAccountListForAdmin()
            .then(
                action((response) => {
                    response.time = new Date();
                    this.lastListLoadTime = response.time;
                    this.currentAccountListForAdmin = response.rows;
                    return response;
                })
            ).catch(
                action((error) => {
                    throw error;
                })
            )
    }

    checkMultiAccountAdminVerificationLink(id) {
        return MultiAccountAdminAgent.checkMultiAccountAdminVerificationLink(id)
            .then(
                action((response) => {
                    return response;
                })
            ).catch(
                action((error) => {
                    throw error;
                })
            )
    }

    verifyMultiAccountEmail(data) {
        return MultiAccountAdminAgent.verifyMultiAccountEmail(data)
            .then(
                action((response) => {
                    return response;
                })
            ).catch(
                action((error) => {
                    throw error;
                })
            )
    }

    generateVerificationOtp(data) {
        return MultiAccountAdminAgent.generateVerificationOtp(data)
            .then(
                action((response) => {
                    return response;
                })
            ).catch(
                action((error) => {
                    throw error;
                })
            )
    }

    addAccountForMultiAccountAccess(data) {
        return MultiAccountAdminAgent.addAccountForMultiAccountAccess(data)
            .then(
                action((response) => {
                    return response;
                })
            ).catch(
                action((error) => {
                    throw error;
                })
            )
    }

    switchAccount(id) {
        return MultiAccountAdminAgent.getAccountInfo(id)
            .then(
                action(response => {
                    return response;
                }))
            .catch(
                action(err => {
                    this.errors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            )
    }

    removeAccount(id) {
        return MultiAccountAdminAgent.removeAccountFromMultiAccount(id)
            .then(
                action(response => {
                    return response;
                }))
            .catch(
                action(err => {
                    this.errors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            )
    }
}

const multiAccountAdminStore = new MultiAccountAdminStore()
export default multiAccountAdminStore;
