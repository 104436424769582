import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

//components
import applicationRouter from '~/hoc/applicationRouter'
import withRemoveDialog from '~/hoc/withRemoveDialog';
import withLocalization from '~/hoc/withLocalization';
import UsersForm from './UsersForm';
import UsersList from './UsersList';

class UsersPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            mode: {
                id: 0,
                add: false
            },
            isPageAddedOrEdited: false,
            page: 0
        }

        this.onAddEdit = this.onAddEdit.bind(this);
        this.goBack = this.goBack.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.getListUrl = this.getListUrl.bind(this);
    }

    onAddEdit(id=0) {
        const mode = { id: id, add: !id, };
        this.setState({ mode: mode, isPageAddedOrEdited: true });
    }

    //this method is replacing the URL pushing part while add/edit//
    goBack = (page = 0) => {
        const mode = { id: 0, add: false };
        this.setState({ mode: mode, page, isPageAddedOrEdited: true });
    }

    //method to maintain react-table page in parent component//
    handlePageChange = (page, isEdit) => {
        this.setState({ page: page, isPageAddedOrEdited: isEdit });
    }

    getListUrl = () => { return '/admin/members' };

    render() {
        // const mode = this.editingId();
        const { mode, page, isPageAddedOrEdited } = this.state;
        return (
            <>
                {(mode.id == 0 && mode.add == false) ?
                    <UsersList
                        mode="members"
                        onAddEdit={this.onAddEdit}
                        handlePageChange={(page, isEdit) => this.handlePageChange(page, isEdit)}
                        page={page}
                        isPageAddedOrEdited={isPageAddedOrEdited}
                    />
                    :
                    <UsersForm
                        id={mode.id} add={mode.add} mode="members"
                        page={page}
                        goBack={this.goBack}
                        getListUrl={()=>this.getListUrl()}
                        onAddEdit={this.onAddEdit}
                    />
                }
            </>
        );
    }
}
export default inject('authStore', 'userStore', 'commonStore')(applicationRouter(withLocalization(withRemoveDialog(observer(UsersPage)))));
