import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

// Components
import withLocalization from '~/hoc/withLocalization';
import ChecklistTemplateList from './CheckListTemplate/ChecklistTemplateList';
import ChecklistTable from './ChecklistTable';
import ElementList from './Element/ElementList';

//styles
import '../../../styles/css/styles.css';

class Checklist extends Component {
    constructor(props){
        super(props);
        this.state = {
            currenttab: 1
        }
    }

    Opentab = (tabid) => {
        this.setState({ currenttab: tabid });
    };

    getComponent = (tab) => {
        switch (tab) {
            case 2:
                return <ChecklistTemplateList />;
            case 3:
                return <ElementList />;
            default:
                return <ChecklistTable />;
        }
    };

    render() {
        const { t } = this.props;
        return (
            <>
                <div className='tabContainer'>
                    <button className={(this.state.currenttab === 1 ? "tablink tablink__active-left" : "tablink tablink__left")} onClick={() => this.Opentab(1)}>
                        {t('My Checklist')}
                    </button>
                    <button className={(this.state.currenttab === 2 ? "tablink tablink__active-center" : "tablink tablink__center")} onClick={() => this.Opentab(2)}>
                        {t('Create checklist')}
                    </button>
                    <button className={(this.state.currenttab === 3 ? "tablink tablink__active-right" : "tablink tablink__right")} onClick={() => this.Opentab(3)}>
                        {t('Elements')}
                    </button>
                </div>
                <div className="page"> {this.getComponent(this.state.currenttab)}</div>
            </>
        );
    }
}
export default inject('checklistStore', 'commonStore')(withLocalization(observer(Checklist)));
