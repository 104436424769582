import React, { Component } from 'react';

import ConversationList from '../ConversationList';
import MessageList from '../MessageList';

import './Messenger.css';
import { observer } from 'mobx-react';

class Messenger extends Component {
    render() {   
        return (
            <div className="messenger">
                {/* <Toolbar
          title="Conversation Title"
          rightItems={[
            <ToolbarButton key="info" icon="ion-ios-information-circle-outline" />,
            <ToolbarButton key="video" icon="ion-ios-videocam" />,
            <ToolbarButton key="phone" icon="ion-ios-call" />
          ]}
        /> */}

                <div className="scrollable chatsidebar">
                    <ConversationList {...this.props} />
                </div>

                <div className="scrollable content">
                    <MessageList {...this.props} />
                </div>
            </div>
        );
    }
}

export default observer(Messenger);
