import React from "react";
import "./styles.css";

function MainHeading(props) {
  return (
    <div className={`mainHeading ${props.customClass} ${props.color}`}>
      <h3>{props.title}</h3>
      <p>{props.description}</p>
    </div>   
  );
}
export default MainHeading;