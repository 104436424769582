import React, { Component } from 'react';
import { PropTypes } from 'prop-types';

import AddMore from './AddMore';
import Summary from './Summary';
import SelectedArea from './SelectedArea';
import { getPos } from './Util';
import { DnDTypes } from './DnDTypes';

import { CellUnits, DATETIME_FORMAT, SummaryPos } from './index';

const supportTouch = 'ontouchstart' in window;

class ResourceEvents extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isSelecting: false,
            left: 0,
            width: 0,
        };
    }

    static propTypes = {
        resourceEvents: PropTypes.object.isRequired,
        schedulerData: PropTypes.object.isRequired,
        dndSource: PropTypes.object.isRequired,
        onSetAddMoreState: PropTypes.func,
        updateEventStart: PropTypes.func,
        updateEventEnd: PropTypes.func,
        moveEvent: PropTypes.func,
        movingEvent: PropTypes.func,
        conflictOccurred: PropTypes.func,
        subtitleGetter: PropTypes.func,
        eventItemClick: PropTypes.func,
        viewEventClick: PropTypes.func,
        viewEventText: PropTypes.string,
        viewEvent2Click: PropTypes.func,
        viewEvent2Text: PropTypes.string,
        newEvent: PropTypes.func,
        eventItemTemplateResolver: PropTypes.func,
    };

    componentDidMount() {
        const { schedulerData } = this.props;
        const { config } = schedulerData;
        if (config.creatable === true) {
            if (supportTouch) {
                // this.eventContainer.addEventListener('touchstart', this.initDrag, false);
            } else {
                this.eventContainer.addEventListener('mousedown', this.initDrag, false);
            }
        }
    }

    componentWillReceiveProps(np) {
        if (supportTouch) {
            // this.eventContainer.removeEventListener('touchstart', this.initDrag, false);
        } else {
            this.eventContainer.removeEventListener('mousedown', this.initDrag, false);
        }
        if (np.schedulerData.config.creatable) {
            if (supportTouch) {
                // this.eventContainer.addEventListener('touchstart', this.initDrag, false);
            } else {
                this.eventContainer.addEventListener('mousedown', this.initDrag, false);
            }
        }
    }

    initDrag = ev => {
        const { isSelecting } = this.state;
        if (isSelecting) return;
        if ((ev.srcElement || ev.target) !== this.eventContainer) return;

        ev.stopPropagation();

        const { resourceEvents } = this.props;
        if (resourceEvents.groupOnly) return;
        let clientX = 0;
        if (supportTouch) {
            if (ev.changedTouches.length == 0) return;
            const touch = ev.changedTouches[0];
            clientX = touch.pageX;
        } else {
            if (ev.buttons !== undefined && ev.buttons !== 1) return;
            clientX = ev.clientX;
        }

        const { schedulerData } = this.props;
        const cellWidth = schedulerData.getContentCellWidth();
        const pos = getPos(this.eventContainer);
        const startX = clientX - pos.x;
        const leftIndex = Math.floor(startX / cellWidth);
        const left = leftIndex * cellWidth;
        const rightIndex = Math.ceil(startX / cellWidth);
        const width = (rightIndex - leftIndex) * cellWidth;

        this.setState({
            startX,
            left,
            leftIndex,
            width,
            rightIndex,
            isSelecting: true,
        });

        if (supportTouch) {
            document.documentElement.addEventListener('touchmove', this.doDrag, false);
            document.documentElement.addEventListener('touchend', this.stopDrag, false);
            document.documentElement.addEventListener('touchcancel', this.cancelDrag, false);
        } else {
            document.documentElement.addEventListener('mousemove', this.doDrag, false);
            document.documentElement.addEventListener('mouseup', this.stopDrag, false);
        }
        document.onselectstart = function () {
            return false;
        };
        document.ondragstart = function () {
            return false;
        };
    };

    doDrag = ev => {
        ev.stopPropagation();

        let clientX = 0;
        if (supportTouch) {
            if (ev.changedTouches.length == 0) return;
            const touch = ev.changedTouches[0];
            clientX = touch.pageX;
        } else {
            clientX = ev.clientX;
        }
        const { startX } = this.state;
        const { schedulerData } = this.props;
        const { headers } = schedulerData;
        const cellWidth = schedulerData.getContentCellWidth();
        const pos = getPos(this.eventContainer);
        const currentX = clientX - pos.x;
        let leftIndex = Math.floor(Math.min(startX, currentX) / cellWidth);
        leftIndex = leftIndex < 0 ? 0 : leftIndex;
        const left = leftIndex * cellWidth;
        let rightIndex = Math.ceil(Math.max(startX, currentX) / cellWidth);
        rightIndex = rightIndex > headers.length ? headers.length : rightIndex;
        const width = (rightIndex - leftIndex) * cellWidth;

        this.setState({
            leftIndex,
            left,
            rightIndex,
            width,
            isSelecting: true,
        });
    };

    stopDrag = ev => {
        ev.stopPropagation();

        const { schedulerData, newEvent, resourceEvents } = this.props;
        const hasEventAddPermission = schedulerData.config.movable;
        const {
            headers, events, config, cellUnit, localeMoment
        } = schedulerData;
        const { leftIndex, rightIndex } = this.state;
        if (supportTouch) {
            document.documentElement.removeEventListener('touchmove', this.doDrag, false);
            document.documentElement.removeEventListener('touchend', this.stopDrag, false);
            document.documentElement.removeEventListener('touchcancel', this.cancelDrag, false);
        } else {
            document.documentElement.removeEventListener('mousemove', this.doDrag, false);
            document.documentElement.removeEventListener('mouseup', this.stopDrag, false);
        }
        document.onselectstart = null;
        document.ondragstart = null;

        const startTime = headers[leftIndex].time;
        let endTime = resourceEvents.headerItems[rightIndex - 1].end;
        if (cellUnit !== CellUnits.Hour) {
            endTime = localeMoment(resourceEvents.headerItems[rightIndex - 1].start)
                .hour(23)
                .minute(59)
                .second(59)
                .format(DATETIME_FORMAT);
        }
        const { slotId } = resourceEvents;
        const { slotName } = resourceEvents;

        this.setState({
            startX: 0,
            leftIndex: 0,
            left: 0,
            rightIndex: 0,
            width: 0,
            isSelecting: false,
        });

        let hasConflict = false;
        if (config.checkConflict) {
            const start = localeMoment(startTime);

            const end = localeMoment(endTime);

            events.forEach(e => {
                if (schedulerData._getEventSlotId(e) === slotId) {
                    const eStart = localeMoment(e.start);

                    const eEnd = localeMoment(e.end);
                    if (
                        (start >= eStart && start < eEnd)
                        || (end > eStart && end <= eEnd)
                        || (eStart >= start && eStart < end)
                        || (eEnd > start && eEnd <= end)
                    ) {
                        hasConflict = true;
                    }
                }
            });
        }

        if (hasConflict) {
            const { conflictOccurred } = this.props;
            if (conflictOccurred != undefined) {
                conflictOccurred(
                    schedulerData,
                    'New',
                    {
                        id: undefined,
                        start: startTime,
                        end: endTime,
                        slotId,
                        slotName,
                        title: undefined,
                    },
                    DnDTypes.EVENT,
                    slotId,
                    slotName,
                    startTime,
                    endTime
                );
            } else {
                console.error('Conflict occurred, set conflictOccurred func in Scheduler to handle it');
            }
        } else if (newEvent != undefined && hasEventAddPermission) {
            newEvent(schedulerData, slotId, slotName, startTime, endTime, null, resourceEvents);
        }
    };

    cancelDrag = ev => {
        ev.stopPropagation();

        const { isSelecting } = this.state;
        if (isSelecting) {
            document.documentElement.removeEventListener('touchmove', this.doDrag, false);
            document.documentElement.removeEventListener('touchend', this.stopDrag, false);
            document.documentElement.removeEventListener('touchcancel', this.cancelDrag, false);
            document.onselectstart = null;
            document.ondragstart = null;
            this.setState({
                startX: 0,
                leftIndex: 0,
                left: 0,
                rightIndex: 0,
                width: 0,
                isSelecting: false,
            });
        }
    };

    render() {
        const {
            resourceEvents, schedulerData, connectDropTarget, dndSource
        } = this.props;
        const hasEventAddPermission = schedulerData.config.movable;
        const {
            cellUnit, startDate, endDate, config, localeMoment
        } = schedulerData;
        const { isSelecting, left, width } = this.state;
        const cellWidth = schedulerData.getContentCellWidth();
        const cellMaxEvents = schedulerData.getCellMaxEvents();
        const rowWidth = schedulerData.getContentTableWidth();
        const DnDEventItem = dndSource.getDragSource();

        const selectedArea = isSelecting ? <SelectedArea {...this.props} left={left} width={width} /> : <div />;

        const eventList = [];

        resourceEvents.headerItems.forEach((headerItem, index) => {
            const isTop = config.summaryPos === SummaryPos.TopRight
                || config.summaryPos === SummaryPos.Top
                || config.summaryPos === SummaryPos.TopLeft;
            let detailsOFEvents = []
            if (headerItem.count > 0 || headerItem.summary != undefined) {

                const marginTop = resourceEvents.hasSummary && isTop ? 1 + config.eventItemLineHeight : 3;
                const renderEventsMaxIndex = headerItem.addMore === 0 ? cellMaxEvents : headerItem.addMoreIndex;
                headerItem.events.forEach((evt, idx) => {
                    if (idx < renderEventsMaxIndex && evt !== undefined && evt.render) {
                        let getSource = evt.eventItem.source === 'absence' || evt.eventItem.source === 'vacation';
                        let getEventItemLineHeight = getSource ? config.eventItemLineHeight - 7 : config.eventItemLineHeight;
                        let durationStart = localeMoment(startDate);
                        let durationEnd = localeMoment(endDate).add(1, 'days');
                        if (cellUnit === CellUnits.Hour) {
                            durationStart = localeMoment(startDate).add(config.dayStartFrom, 'hours');
                            durationEnd = localeMoment(endDate).add(config.dayStopTo + 1, 'hours');
                        }
                        // ======================================= overlapping issue corrections code starts ===============================//
                        detailsOFEvents.push(evt)
                        if (detailsOFEvents[0] && detailsOFEvents[0].eventItem && detailsOFEvents[0].eventItem.availability_type) {
                            if (evt.eventItem.availability_type == 'available' || evt.eventItem.availability_type == 'not available') {
                                const eventStart = localeMoment(evt.eventItem.start);
                                const eventEnd = localeMoment(evt.eventItem.end);
                                const isStart = eventStart >= durationStart;
                                const isEnd = eventEnd <= durationEnd;
                                const left = index * cellWidth + 2;


                                const width = evt.span * cellWidth - (index > 0 ? 5 : 6) > 0
                                    ? evt.span * cellWidth - 3
                                    : 0;
                                const top = marginTop + idx * getEventItemLineHeight;
                                const eventItem = (
                                    <DnDEventItem
                                        {...this.props}
                                        key={evt.eventItem.id}
                                        eventItem={evt.eventItem}
                                        isStart={isStart}
                                        isEnd={isEnd}
                                        isInPopover={false}
                                        left={left}
                                        width={width}
                                        top={top}
                                        leftIndex={index}
                                        rightIndex={index + evt.span}
                                    />
                                );
                                eventList.push(eventItem);
                            }
                            for (let i = 1; i < detailsOFEvents.length; i++) {
                                const eventStart = localeMoment(detailsOFEvents[i].eventItem.start);
                                const eventEnd = localeMoment(detailsOFEvents[i].eventItem.end);
                                const isStart = eventStart >= durationStart;
                                const isEnd = eventEnd <= durationEnd;
                                const left = index * cellWidth + 2;
                                const width = detailsOFEvents[i].span * cellWidth - (index > 0 ? 5 : 6) > 0
                                    ? detailsOFEvents[i].span * cellWidth - 3
                                    : 0;
                                const top = marginTop + (i - 1) * getEventItemLineHeight;
                                const eventItem = (
                                    <DnDEventItem
                                        {...this.props}
                                        key={detailsOFEvents[i].eventItem.id}
                                        eventItem={detailsOFEvents[i].eventItem}
                                        isStart={isStart}
                                        isEnd={isEnd}
                                        isInPopover={false}
                                        left={left}
                                        width={width}
                                        top={top}
                                        leftIndex={index}
                                        rightIndex={index + detailsOFEvents[i].span}
                                    />
                                );
                                eventList.push(eventItem);
                            }
                        }
                        else {
                            // ======================================= overlapping issue corrections code ends===============================//
                            const eventStart = localeMoment(evt.eventItem.start);
                            const eventEnd = localeMoment(evt.eventItem.end);
                            const isStart = eventStart >= durationStart;
                            const isEnd = eventEnd <= durationEnd;
                            const left = index * cellWidth + 2;
                            const width = evt.span * cellWidth - (index > 0 ? 5 : 6) > 0
                                ? evt.span * cellWidth - 3
                                : 0;
                            const top = marginTop + idx * getEventItemLineHeight;
                            const eventItem = (
                                <DnDEventItem
                                    {...this.props}
                                    key={evt.eventItem.id}
                                    eventItem={evt.eventItem}
                                    isStart={isStart}
                                    isEnd={isEnd}
                                    isInPopover={false}
                                    left={left}
                                    width={width}
                                    top={top}
                                    leftIndex={index}
                                    rightIndex={index + evt.span}
                                />
                            );
                            eventList.push(eventItem);
                        }
                    }
                });
                if (headerItem.addMore > 0) {
                    const left = index * cellWidth + (index > 0 ? 2 : 3);
                    const width = cellWidth - (index > 0 ? 5 : 6);
                    const top = marginTop + headerItem.addMoreIndex * config.eventItemLineHeight;
                    const addMoreItem = (
                        <AddMore
                            {...this.props}
                            key={headerItem.time}
                            headerItem={headerItem}
                            number={1}
                            left={left}
                            width={width}
                            top={top}
                            clickAction={this.onAddMoreClick}
                        />
                    );
                    eventList.push(addMoreItem);
                }
            }

            headerItem.summary = !!schedulerData.config.creatable;

            if (headerItem.summary && hasEventAddPermission) {
                const left = index * cellWidth + (index > 0 ? 2 : 3);
                const width = cellWidth - (index > 0 ? 5 : 6);
                const key = `${resourceEvents.slotId}_${headerItem.time}`;
                const handler = () => {
                    const { schedulerData, newEvent, resourceEvents } = this.props;
                    newEvent(schedulerData, resourceEvents.slotId, '', headerItem.start, null, null, resourceEvents);
                };
                const summary = (
                    <Summary
                        key={key}
                        schedulerData={schedulerData}
                        summary={{}}
                        left={left}
                        click={handler}
                        width={width}
                    />

                );
                eventList.push(summary);
            }
        });

        let classname = `event-container ${resourceEvents.slotId === 0 || resourceEvents.slotId === 'Pending' ? 'pending-row' : ''} ${hasEventAddPermission ? "" : "cursor-no-drop"}`;
        if (resourceEvents.slotId === schedulerData.config.currentUserId) {
            classname += ' current-row';
        }

        return (
            <tr>
                <td style={{ width: rowWidth }}>
                    {connectDropTarget(
                        <div
                            ref={this.eventContainerRef}
                            className={classname}
                            style={{ height: resourceEvents.rowHeight }}
                        >
                            {selectedArea}
                            {eventList}
                        </div>
                    )}
                </td>
            </tr>
        );
    }


    onAddMoreClick = headerItem => {
        const { onSetAddMoreState, resourceEvents, schedulerData } = this.props;
        if (onSetAddMoreState) {
            const { config } = schedulerData;
            const cellWidth = schedulerData.getContentCellWidth();
            const index = resourceEvents.headerItems.indexOf(headerItem);
            if (index !== -1) {
                let left = index * (cellWidth - 1);
                const pos = getPos(this.eventContainer);
                left += pos.x;
                const top = pos.y;
                const height = (headerItem.count + 1) * config.eventItemLineHeight + 20;
                onSetAddMoreState({
                    headerItem,
                    left,
                    top,
                    height,
                });
            }
        }
    };

    eventContainerRef = element => {
        this.eventContainer = element;
    };
}

export default ResourceEvents;