import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Col, Tab, Tabs } from 'react-bootstrap';
import _ from 'lodash';
import applicationRouter from '~/hoc/applicationRouter'

import withLocalization from '../../../hoc/withLocalization';

import DeviationUrgencyMarker from './DeviationUrgencyMarker';

class DeviationDashboardWidget extends Component {
    renderSingleList(data, title, filter, userId) {
        const config = _.get(this.props.commonStore, 'config.deviationConfig', {});
        let searchQuery = '?';
        if (filter === 'assigned_to_me') {
            searchQuery += `assigned_id=${userId}&`;
        }
        if (filter === 'assigned_by_me') {
            searchQuery += `reported_by_id=${userId}&`;
        }
        if (filter === 'complete') {
            searchQuery += `status=${userId}&`;
        }
        const { t } = this.props;
        if (!data || data.length === 0) return <span className='d-flex justify-content-center mt-1 w-100 fw-bold' style={{color:"#B2B7B2"}}>{t("No data found")}</span>;
        return (
            <>
                <div className="horizontal-scroll">
                    <table className="table table-project-small mt-0">
                        <thead>
                            <tr>
                                <th className="text-center">ID</th>
                                <th>{t('Project')}</th>
                                <th>{t('Subject')}</th>
                                <th>{t('Reported by')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map(row => (
                                <tr key={row.id}>
                                    <td className="d-flex justify-content-center align-items-center ">
                                        <DeviationUrgencyMarker config={config} t={t} urgency={row.urgency} />

                                        <NavLink to={`/admin/deviation/${row.id}`}>{row.id}</NavLink>
                                    </td>
                                    <td>{row.project_name}</td>
                                    <td>{row.subject}</td>
                                    <td>{row.reporter_name}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <h6 className='mt-2'>
                    <NavLink
                        to={{
                            pathname: '/admin/deviation/',
                            search: searchQuery,
                        }}
                    >
                        {t('View all')}: {title}
                    </NavLink>
                </h6>
            </>
        );
    }

    getTitle(tab, deviations) {
        const { t } = this.props;
        return `${t(tab.title)} (${deviations[tab.id].length || 0})`;
    }

    render() {
        const { t } = this.props;
        const { deviations } = this.props.data;
        const userId = _.get(this.props.userStore, 'currentUser.id', '');
        const userType = _.get(this.props.userStore, 'currentUser.user_type', '');

        let tabs = [
            {
                id: 'newDeviations',
                title: 'New to assign',
                filter: 'last',
            },
            {
                id: 'toApprove',
                title: 'Waiting for approval',
                filter: 'complete',
            },
            {
                id: 'reportedByYou',
                title: 'Reported by me',
                filter: 'assigned_by_me',
            },
            {
                id: 'yourDeviations',
                title: 'Assigned to me',
                filter: 'assigned_to_me',
            },
        ];
        if (userType === 'member') {
            tabs = [
                {
                    id: 'reportedByYou',
                    title: 'Reported by me',
                    filter: 'assigned_by_me',
                },
                {
                    id: 'yourDeviations',
                    title: 'Assigned to me',
                    filter: 'assigned_to_me',
                },
            ];
        }

        return (
            <Fragment>
                <Col sm={12} lg={12}>
                    <h4 className="card-title">
                        <NavLink to="/admin/deviation" className="dashboard-nav-link mx-2 mx-md-none">{t('Deviations')}</NavLink>
                    </h4>
                    <div className="dashboard-tabs-container mt-2">
                        <Tabs defaultActiveKey={tabs[0].id} id="tabs">
                            {tabs.map(tab => (
                                <Tab eventKey={tab.id} title={this.getTitle(tab, deviations)} key={tab.id}>
                                    {this.renderSingleList(deviations[tab.id], t(tab.title), tab.filter, userId)}
                                </Tab>
                            ))}
                        </Tabs>
                    </div>

                </Col>
            </Fragment>
        );
    }
}

export default inject('deviationStore', 'commonStore', 'userStore')(applicationRouter(withLocalization(observer(DeviationDashboardWidget))));
