import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

//components
import applicationRouter from '~/hoc/applicationRouter'
import withLocalization from '~/hoc/withLocalization';
import withDnDContext from '~/hoc/withDnDContext';

class MonthEventComponent extends Component {
    cropText = (str = '', size = 16) => {
        str = `${str}`;
        if (str.length > size) {
            return `${str.slice(0, size)}...`;
        }
        return str;
    };

    render() {
        const { userStore } = this.props;
        const { currentUser } = userStore;
        const isMember = currentUser.user_type === 'member';
        let circleStyle = {};
        let bgStyle = {};

        if (this.props.event.workLogStatus === 'none') {
            circleStyle = {
                color: '#447df7',
                fontSize: '8px'
            }
        }
        if (this.props.event.workLogStatus === 'LATE') {
            circleStyle = {
                color: 'red',
                fontSize: '8px'
            }
        }
        if (this.props.event.workLogStatus === 'NO SHOW') {
            circleStyle = {
                color: '#B4B4B4 ',
                fontSize: '8px'
            }
        }
        if (this.props.event.workLogStatus === 'ON TIME') {
            circleStyle = {
                color: '#73B539',
                fontSize: '8px'
            }
        }
        return (
            <div>
                {
                    !isMember && (
                        <div className="event_content" style={bgStyle} >
                            {
                                this.props.event.workLogStatus === 'none' && (
                                    <>
                                        <div className="none_Event" style={{ display: 'flex', justifyContent: 'space-between', fontFamily: 'Roboto' }}>
                                            <span><i className="fa fa-dot-circle-o none_circle" style={circleStyle}></i>
                                                {this.cropText(this.props.event.name)}
                                            </span>
                                            <span>
                                                {this.props.event.title.split('-')[0]}
                                            </span>
                                        </div>
                                    </>

                                )
                            }
                            {
                                (this.props.event.source === 'absence' || this.props.event.source === 'vacation') && (
                                    <div className="vacation_absent_event" style={{ fontFamily: 'Roboto' }}>
                                        {this.props.event.source}{', '}{this.props.event.name}
                                    </div>
                                )
                            }
                            {
                                this.props.event.workLogStatus === 'NO SHOW' && (
                                    <div className="noshow_Event" style={{ display: 'flex', justifyContent: 'space-between', fontFamily: 'Roboto' }}>
                                        <span><i className="fa fa-dot-circle-o noshow_circle" style={circleStyle}></i>
                                            {this.cropText(this.props.event.name)}
                                        </span>
                                        <span>
                                            {this.props.event.title.split('-')[0]}
                                        </span>
                                    </div>
                                )
                            }
                            {
                                this.props.event.workLogStatus === 'LATE' && (
                                    <div className="late_Event" style={{ display: 'flex', justifyContent: 'space-between', fontFamily: 'Roboto' }}>
                                        <span><i className="fa fa-dot-circle-o late_circle" style={circleStyle}></i>
                                            {this.cropText(this.props.event.name)}
                                        </span>
                                        <span>
                                            {this.props.event.title.split('-')[0]}
                                        </span>
                                    </div>
                                )
                            }
                            {
                                this.props.event.workLogStatus === 'ON TIME' && (
                                    <div className="ontime_Event" style={{ display: 'flex', justifyContent: 'space-between', fontFamily: 'Roboto' }}>
                                        <span><i className="fa fa-dot-circle-o ontime_circle" style={circleStyle}></i>
                                            {this.cropText(this.props.event.name)}
                                        </span>
                                        <span>
                                            {this.props.event.title.split('-')[0]}
                                        </span>
                                    </div>
                                )
                            }
                        </div>
                    )
                }
                {
                    isMember && (
                        <div className="event_content" style={bgStyle}  >
                            {
                                this.props.event.workLogStatus === 'none' && (
                                    <div className="none_Event" style={{ fontFamily: 'Roboto' }}>
                                        <span><i className="fa fa-dot-circle-o none_circle" style={circleStyle}></i></span>
                                        {this.props.event.title}
                                    </div>
                                )
                            }

                            {
                                (this.props.event.source === 'absence' || this.props.event.source === 'vacation') && (
                                    <div className="rbc-event-content" style={{ fontFamily: 'Roboto' }}>
                                        {this.props.event.title}
                                    </div>
                                )
                            }
                            {
                                this.props.event.workLogStatus === 'NO SHOW' && (
                                    <div className="noshow_Event" style={{ fontFamily: 'Roboto' }} >
                                        <span><i className="fa fa-dot-circle-o noshow_circle" style={circleStyle}></i></span>
                                        {this.props.event.title}
                                    </div>
                                )
                            }
                            {
                                this.props.event.workLogStatus === 'LATE' && (
                                    <div className="late_Event" style={{ fontFamily: 'Roboto' }} >
                                        <span><i className="fa fa-dot-circle-o late_circle" style={circleStyle}></i></span>
                                        {this.props.event.title}
                                    </div>
                                )
                            }
                            {
                                this.props.event.workLogStatus === 'ON TIME' && (
                                    <div className="ontime_Event" style={{ fontFamily: 'Roboto' }} >
                                        <span><i className="fa fa-dot-circle-o ontime_circle" style={circleStyle}></i></span>
                                        {this.props.event.title}
                                    </div>
                                )
                            }
                        </div>
                    )
                }
            </div>
        )
    }
}

export default inject('userStore')(
    withLocalization(applicationRouter(withDnDContext(observer(MonthEventComponent))))
);
