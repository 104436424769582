import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

//components
import applicationRouter from '~/hoc/applicationRouter'
import withRemoveDialog from '~/hoc/withRemoveDialog';
import ProjectsList from './ProjectsList';

class ProjectsPage extends Component {
    constructor(props) {
        super(props)
        this.onAdd = this.onAdd.bind(this)
    }

    onAdd(page = 0, isEdit = true, currentList) {
        if (this.props.commonStore.config?.biztype?.data?.allow_multiple_branches) {
            if (this.props.clientStore.currentEntity?.client?.max_branches_allowed == currentList.total) {
                return this.props.commonStore.addNotification(this.props.t('You have exceeded project/branch limit, Please Contact Time & Control'), null, 'error');
            }
        }
        this.props.router.navigate('/admin/projects/add', { state: { page, isEdit } });
    }

    render() {
        return (
            <ProjectsList
                onAdd={this.onAdd}
            />
        );
    }
}
export default inject('authStore', 'projectStore', 'clientStore', 'commonStore')(applicationRouter(withRemoveDialog(observer(ProjectsPage))));
