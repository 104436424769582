import React, { Component } from 'react';
import './ConversationSearch.css';

export default class ConversationSearch extends Component {
    state={
        searchText:''
    }

    render() {
        const rooms = this.props.rooms;    
        return (
            <div className="conversation-search">
                <input type="text" 
                    className="conversation-search-input" 
                    placeholder={this.props.t('Search') + ' ' +this.props.t('Project')}
                    onChange={(e)=>this.setState({searchText:e.target.value})}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {                            
                            this.props.search(e.target.value);
                        }
                    }}
                    list={'projectlist'}
                />
                <datalist id={'projectlist'}>
                    {rooms.map(room=>(
                        <option key={room.id}>{room.name}</option>
                    ))}                    
                </datalist>
                <i className='fa fa-search conversation-search-icon' 
                onClick={()=>this.props.search(this.state.searchText)}/>
            </div>
        );
    }
}
