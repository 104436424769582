import React from "react";
import { Link} from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

import Header from "../../../newComponents/Header";
import Footer from "../../../newComponents/Footer";
import MainHeading from "../../../newComponents/MainHeading";
import PageBanner from "../../../newComponents/PageBanner";
import ListFeau from "../../../newComponents/ListFeau";
import Contact from "../../../newComponents/Contact";

import BannerImg from "../../../assets/images/schedulePlanImg.png";
import AppleIcon from "../../../assets/images/appleIcon.svg";
import GoogleIcon from "../../../assets/images/googleIcon.svg";
import ValuableLogo from "../../../assets/images/valuableLogo.png";
import SchedulePlanImg1 from "../../../assets/images/schedulePlanImg1.svg";
import ChangeShiftImg from "../../../assets/images/changeShiftImg.png";
import ShiftSwapImg from "../../../assets/images/shiftSwapImg.png";
import SchedulePlanImg2 from "../../../assets/images/schedulePlanImg2.png";

//i18n
import { withTranslation } from "react-i18next"
import { inject, observer } from "mobx-react";

import config from "../../../library/config";

const SchedulePlan = inject('authStore', 'commonStore')(observer((props) => {
  return (
    <>
      <div className="landingPageWrapp frameworkApp">
        <Header features="current" />
        <article className="pageBannerBlock grdntBg noPatt py-60">
          <Container>
            <Row className="g-4 align-items-center">
              <Col lg>
                <MainHeading
                  title={<>{props.t("Schedule")} <span>{props.t("Plan")}</span></>}
                  description={props.t("Effective Scheduling is the baseline for all business success. Gain complete control of how your business performs by planning and setting the right scheduling for workers. Create seamless schedules with a simple and intuitive platform with multi-device access.")}
                />
                <div className="appBtns mt-4">
                  <Link onClick={e => config.redirectToAppStore(e, "apple")} className="btnCommon btnBig w-auto me-3 mb-2"><i className="d-inline-block pe-2"><img src={AppleIcon}  alt="Apple" /></i> {props.t("Apple")}</Link>
                  <Link onClick={e => config.redirectToAppStore(e, "google")} className="btnCommon btnTrans btnBig btnBlackTrans w-auto"><i className="d-inline-block pe-2"><img src={GoogleIcon} alt="Apple" /></i> {props.t("Google")}</Link>
                </div>
              </Col>
              <Col lg={1}></Col>
              <Col lg="auto">
                <PageBanner
                  src={BannerImg}
                  alt="Schedule Plan"
                />
              </Col>
            </Row>
          </Container>
          <div className="valuableLogo">
            <img src={ValuableLogo} alt="ValuableLogo" />
          </div>
        </article>
        <article className="abtInfoBlock leftPatt py-60">
          <Container>
            <Row className="g-4 g-xl-5 align-items-center">
              <Col lg={6}>
                <div className="text-center">
                  <img src={SchedulePlanImg1} alt="SchedulePlanImg1" />
                </div>
              </Col>
              <Col lg={6}>
                <MainHeading
                  title={<>{props.t("Proactive Scheduling")}<br/><span>{props.t("in One Click")}</span></>}
                  customClass="medium noPara m-0"
                />
                <div className="contentInfo">
                  <h5 className="my-2">{props.t("Single-Click Approval")}</h5>
                </div>
                <div className="text-left">
                  <p>{props.t("Time & Control recommends scheduling plans based on your business needs. Our solution reads and recognizes line-up data of your organization and recommends weekly and monthly schedules based on intelligent analysis. Approve the plan in a single click, and all the employees will be auto-notified of the new schedule.")}</p>
                </div>
              </Col>
            </Row>
          </Container>
        </article>
        <article className="abtInfoBlock grdntBg noPatt py-60">
          <Container>
            <Row className="g-4 g-xl-5 align-items-center">
              <Col lg={6}>
                <MainHeading
                  title={<>{props.t("Shift Switching")} <span>{props.t("Made Easier")}</span></>}
                  customClass="medium noPara m-0"
                />
                <div className="contentInfo my-4">
                  <p><b>{props.t("Seamless Substitution of Colleague Shifts")}</b></p>
                </div>
                <div className="list">
                  <ul>
                    <li>{props.t("Plan for bulk shifts on a weekly or monthly basis")}</li>
                    <li>{props.t("Customize the shift plans according to profession and work")}</li>
                    <li>{props.t("Stay informed about the monthly costs")}</li>
                    <li>{props.t("Forward employee schedules on email")}</li>
                    <li>{props.t("Instant scheduling for absent employees")}</li>
                    <li>{props.t("Single-point entry to add bonuses, incentives, and rewards")}</li> 
                    

                  </ul>
                </div>
                <div className="appBtns mt-5">
                  <Link onClick={e => config.redirectToAppStore(e, "apple")} className="btnCommon btnBig w-auto me-3 mb-2"><i className="d-inline-block pe-2"><img src={AppleIcon}  alt="Apple" /></i> {props.t("Apple")}</Link>
                  <Link onClick={e => config.redirectToAppStore(e, "google")} className="btnCommon btnTrans btnBig btnBlackTrans w-auto"><i className="d-inline-block pe-2"><img src={GoogleIcon} alt="Apple" /></i> {props.t("Google")}</Link>
                </div>
              </Col>
              <Col lg={6}>
                <div className="text-center">
                  <img src={ChangeShiftImg} alt="ChangeShiftImg" />
                </div>
              </Col>
            </Row>
          </Container>
        </article>
        <article className="abtInfoBlock noPatt py-60">
          <div className="rightImgFull">
            <img src={ShiftSwapImg} alt="ShiftSwapImg" />
          </div>
          <Container>
            <Row className="g-4 g-xl-5 align-items-center">
              <Col>
                <div className="my-5 py-5">
                  <div className="contentInfo">
                    <h5 className="mb-2">{props.t("Trade Shift")}</h5>
                  </div>
                  <MainHeading
                    title={<>{props.t("Hassle-Free Group")}<br/><span>{props.t("Shift Swap")}</span></>}
                    description={props.t("Access complete scheduling information from a single platform to manage, customize, and plan shifts in advance or according to circumstances.")}
                    customClass="medium"
                  />
                  <div className="listFeaRow">
                    <Row className="g-4">
                      <Col md={6}>
                        <ListFeau
                          title={props.t("Carefully Designed")}
                          description={props.t("Effective design supporting business operations.")}
                          color="blue"
                        />
                      </Col>
                      <Col md={6}>
                        <ListFeau
                          title={props.t("Choose a App")}
                          description={props.t("Download the app to streamline processes and utilize resources with ease.")}
                          color="green"
                        />
                      </Col>
                      <Col md={6}>
                        <ListFeau
                          title={props.t("Seamless Sync")}
                          description={props.t("Synchronize and update all scheduled data in real-time.")}
                          color="red"
                        />
                      </Col>
                      <Col md={6}>
                        <ListFeau
                          title={props.t("User Interactive")}
                          description={props.t("Contains an intuitive interface to boost productivity.")}
                          color="pink"
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </article>
        <article className="abtInfoBlock noPatt py-60">
          <div className="leftImgFull">
            <img src={SchedulePlanImg2} alt="SchedulePlanImg2" />
          </div>
          <Container>
            <Row className="g-4 g-xl-5 align-items-center mt-5">
              <Col>
                <div className="my-5 py-5">
                  <div className="contentInfo">
                    <h5 className="mb-2">{props.t("Assess Schedules and Auto-Notify")}</h5>
                  </div>
                  <MainHeading
                    title={<>{props.t("Smarter Scheduling with")}<br/><span>{props.t("Instant Checking")}</span></>}
                    description={props.t("Stay updated with employee availability and reduce no-shows. Check employee and manager availability before scheduling events and send automatic reminders to employees for future events.")}
                    customClass="medium m-0"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </article>
        <Contact/>
        <Footer/>
      </div>
    </>
  );
}))
export default withTranslation()(SchedulePlan);