import React, { Component } from 'react';
import { Dropdown, Row, Offcanvas, Modal } from 'react-bootstrap';
import moment from 'moment/min/moment-with-locales';
import { inject, observer } from 'mobx-react';
import { Link, NavLink } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

//components
import ShowNotifications from './ShowNotifications';
import applicationRouter from '~/hoc/applicationRouter';
import withLocalization from '~/hoc/withLocalization';
import routes from '../../routes';
import SidebarMobile from '../Sidebar/SidebarMobile';
import GenericDropdown from '../GenericLanguageDropdown/GenericDropdown';
import MultiAccountAdmin, { MultiAccountModes } from '../../containers/Admin/Users/MultiAccountAdmin.js';
import withRemoveDialog from '~/hoc/withRemoveDialog';

//elements
import Button from '../../components/CustomButton/CustomButton.jsx';
import LoadingSpinner from '../../elements/LoadingSpinner';

//utils
import customerFeature from '~/utils/customerFeature';

//assests
import UserProfileLogo from './../UserProfileLogo';
import listIcon from '../../assets/img/new_design/jsx_adminnavbar_svg/list.svg';
import whiteListIcon from '../../assets/img/new_design/jsx_adminnavbar_svg/white_list.svg';
import messageSvg from '../../assets/img/new_design/jsx_adminnavbar_svg/message_nav.svg';
import notificationSvg from '../../assets/img/new_design/jsx_adminnavbar_svg/notification_nav.svg';
import briefCaseIcon from '../../assets/img/new_design/jsx_adminnavbar_svg/brief_case.svg';
import whiteBriefCaseIcon from '../../assets/img/new_design/jsx_adminnavbar_svg/white_briefcase.svg';
import timesheetIcon from '../../assets/img/new_design/jsx_adminnavbar_svg/timesheet.svg';
import whiteTimesheetIcon from '../../assets/img/new_design/jsx_adminnavbar_svg/white_timesheet.svg';
import checkListIcon from '../../assets/img/new_design/jsx_adminnavbar_svg/checklist.svg';
import whiteCheckList from '../../assets/img/new_design/jsx_adminnavbar_svg/white_checklist.svg';
import userIcon from '../../assets/img/new_design/jsx_adminnavbar_svg/user.svg';
import whiteUser from '../../assets/img/new_design/jsx_adminnavbar_svg/white_user.svg';
import CloseModalIcon from '../../assets/img/CloseApproveModalIcon.svg';

//styles
import './AdminNavbar.css';
import { Label } from 'reactstrap';

// links that appear in navbar - they are separated from this component (navbar) so that we can redner them on responsive in sidebar as well

const extraLinkModules = [
    { name: 'Timesheet', value: '1', svg: timesheetIcon, whiteSvg: whiteTimesheetIcon, route_map: '/admin/timelogs' },
    { name: 'Users', value: '2', svg: userIcon, whiteSvg: whiteUser, route_map: '/admin/members' },
    { name: 'Projects', value: '3', svg: briefCaseIcon, whiteSvg: whiteBriefCaseIcon, route_map: '/admin/projects' },
    { name: 'Checklist', value: '4', svg: checkListIcon, whiteSvg: whiteCheckList, route_map: '/admin/checklist' },
    { name: 'Payroll report', value: '5', svg: listIcon, whiteSvg: whiteListIcon, route_map: '/admin/reports/payroll' },
];

// we import here the routes for dashboard pages (links that appear in sidebar) to set navbar's name
class AdminNavbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
            checked: false,
            radioValue: '1',
            optionValue: '1',
            width: window.innerWidth,
            sidebarshow: false,
            show_message_dropdown: false,
            show_notification_dropdown: false,
            show_multi_account_drop_down: false,
            hasMultiAccountAdminAccess: window.sessionStorage.getItem('multiAdminJwt'),
            showMultiAccountAmdinModal: false,
            mode: null,
            currentMultiAccount:null
        };
        this.showMessageNotfications = this.showMessageNotfications.bind(this);
        this.showNotifications = this.showNotifications.bind(this);
        this.closeNotificationCanvas = this.closeNotificationCanvas.bind(this);
        this.hideSidebar = this.handleclose.bind(this);
        this.showSidebar = this.handleBtnClick.bind(this);
        this.handleMultiAccountAdminActions = this.handleMultiAccountAdminActions.bind(this);
        this.handleMultiAccountAdminModeChange = this.handleMultiAccountAdminModeChange.bind(this);
    }
    componentDidMount() {
        this.updateDimensions();
        window.addEventListener('resize', this.updateDimensions.bind(this));
        if (this.state.hasMultiAccountAdminAccess) {
            this.loadAccountsWithMultiAdminAccess();
        }
    }

    loadAccountsWithMultiAdminAccess = async () => {
        try {
            await this.props.multiAccountAdminStore.getAccountListForAdmin();
            this.forceUpdate();
        }
        catch (err) {
            this.props.commonStore.addNotification(this.props.t(err || 'Error'), null, 'error');
        }

    }

    updateDimensions() {
        this.setState({ width: window.outerWidth });
    }

    showMessageNotfications() {
        this.props.userStore.showMessageNotificationDock();
        this.setState({ show_message_dropdown: false });
    }

    showNotifications() {
        this.props.userStore.showNotificationDock();
        this.setState({ show_notification_dropdown: false });
    }

    renderDropdownItem(item, source) {
        const { commonStore } = this.props;
        let dateTimeRules = commonStore.config.client && commonStore.config.client.data && commonStore.config.client.data.dateTimeRules
            ? { ...commonStore.config.client.data.dateTimeRules }
            : { short_date_format: 'DD.MM.YYYY', time_format: 'hh:mm' };
        dateTimeRules.short_date_format = dateTimeRules.short_date_format ? dateTimeRules.short_date_format : 'DD.MM.YYYY';
        dateTimeRules.time_format = dateTimeRules.time_format ? dateTimeRules.time_format : 'hh:mm';
        if (source === 'notifications') {
            // if (!item.data || !item.data.context) return item.message;
            return (
                <>
                    <div className="notify-symbol">
                        <i className="btn btn-danger">{item?.data?.context?.source?.split('')[0]?.toUpperCase() || 'N'} </i>
                    </div>
                    <div className="notify-text">
                        <p>{item.message}</p>
                        <span className="fw-bold">
                            {moment(new Date(item.created_at)).format(dateTimeRules.short_date_format)}
                        </span>
                        <span className="fw-bold mx-1">
                            {moment(new Date(item.created_at)).format(dateTimeRules.time_format)}
                        </span>
                    </div>
                </>
            );
        } else {
            if (item.data.context.source === 'chat') {
                return (
                    <>
                        <div className="notify-symbol">
                            <i className="btn btn-danger">
                                {item?.data?.context?.projectName
                                    ?.replace('and', '')
                                    .split(' ')
                                    .map((obj) => obj[0])
                                    .join('')
                                    .substring(0, 3)
                                    .toUpperCase() || 'C'}
                            </i>
                        </div>
                        <div className="notify-text">
                            <p>{item.data.context?.projectName || ''}</p>
                            <span className="msg">{item.message}</span>
                            <span className="fw-bold">
                                {moment(new Date(item.created_at)).format(dateTimeRules.short_date_format)}
                            </span>
                            <span className="fw-bold mx-1">
                                {moment(new Date(item.created_at)).format(dateTimeRules.time_format)}
                            </span>
                        </div>
                    </>
                );
            }
            if (item.data.context.source === 'privatechat') {
                return (
                    <>
                        <div className="notify-symbol">
                            <i className="btn btn-danger">
                                {item?.data?.context?.roomName
                                    ?.replace('and', '')
                                    .split(' ')
                                    .map((obj) => obj[0])
                                    .join('')
                                    .substring(0, 3)
                                    .toUpperCase() || 'C'}
                            </i>
                        </div>
                        <div className="notify-text">
                            <p>{item.data.context?.roomName || ''}</p>
                            <span className="msg">{item.message}</span>
                            <span className="fw-bold">
                                {moment(new Date(item.created_at)).format(dateTimeRules.short_date_format)}
                            </span>
                            <span className="fw-bold mx-1">
                                {moment(new Date(item.created_at)).format(dateTimeRules.time_format)}
                            </span>
                        </div>
                    </>
                );
            }
        }
    }

    closeNotificationCanvas(type) {
        if (type === 'messages') this.props.userStore.hideMessageNotificationDock();
        if (type === 'notifications') this.props.userStore.hideNotificationDock();
    }
    handleBtnClick = () => {
        this.props.userStore.showSidebar();
    };

    handleclose = () => {
        this.props.userStore.hideSidebar();
    };
    handledropdownToggle(value, dropdownType) {
        if (dropdownType === 'message') this.setState({ show_message_dropdown: value });
        if (dropdownType === 'notification') this.setState({ show_notification_dropdown: value });
        if (dropdownType === 'show_multi_account_drop_down') this.setState({ show_multi_account_drop_down: value });
    }

    getExtralinks = (user_type) => {
        if (!user_type || user_type !== 'member') return extraLinkModules;
        const filteredLinks = extraLinkModules.filter((ele) => ele.name !== 'Users')
        if (!(customerFeature('member_allow_see_projects'))) return filteredLinks.filter(ele => ele.name !== 'Projects')
        return filteredLinks;
    }

    handleMultiAccountAdminActions = (action, item, callback = null) => {
        const { userStore} = this.props;
        const { currentUser} = userStore;
        let _mode = null, _currentMultiAccount = null;
        switch (action) {
            case MultiAccountModes.ADD:
                _mode = MultiAccountModes.ADD;
                break;
            case MultiAccountModes.MANAGE:
                _mode = MultiAccountModes.MANAGE;
                break;
            case 'remove':
                return this.removeAccount(item.id, callback);
            case 'switch':
                if (!item.is_email_verified) {
                    _mode = MultiAccountModes.VERIFY_EMAIL;
                    break;
                }
                else if (item.is_jwt_token_expired) {
                    _mode = MultiAccountModes.RENEW_JWT;
                    _currentMultiAccount = item.secondary_client_admin_email;
                    break;
                }
                else {
                    if(item.secondary_admin_id !== currentUser.id)
                        this.switchAccount(item.id);
                    return;
                }
            default:
                return this.backToMainAccount();

        }
        return this.setState({ mode: _mode, currentMultiAccount: _currentMultiAccount, showMultiAccountAmdinModal: true });
    }

    removeAccount = async (_id, callback) => {
        try {
            return this.props.handleDelete(_id, (id) => {
                return this.props.multiAccountAdminStore.removeAccount(id).then(res => {
                    this.props.commonStore.addNotification(res.message, null, 'success');
                }).catch(err => {
                    this.props.commonStore.addNotification(err, null, 'error');
                }).finally(() => {
                    callback();
                })
            }, null, () => {
                callback();
            });
        }
        catch (err) {
            this.props.commonStore.addNotification(err, null, 'error');
        }
    }

    switchAccount = async (id) => {
        try {
            await this.props.multiAccountAdminStore.switchAccount(id).then(response => {
                if (response.accountDetails && response.accountDetails.jwt_token) {
                    this.props.commonStore.setToken(response.accountDetails.jwt_token);
                    this.props.userStore.pullUser();
                    window.location.href = `${window.location.origin}/admin`;
                }
            }).catch(err => {
                this.loadAccountsWithMultiAdminAccess();
                this.props.commonStore.addNotification(err, null, 'error');
            })
        }
        catch (err) {
            this.props.commonStore.addNotification(err, null, 'error');
        }
    }

    backToMainAccount = () => {
        try {
            if (!window.sessionStorage.getItem('multiAdminJwt')) {
                window.location.href = window.location.origin;
                return this.props.userStore.forgetUser()
            };
            const multiAccountJWT = window.sessionStorage.getItem('multiAdminJwt');
            this.props.commonStore.setToken(multiAccountJWT);
            this.props.userStore.pullUser();
            window.location.href = `${window.location.origin}/admin`;
        }
        catch (err) {
            this.props.commonStore.addNotification(err, null, 'error');
        }
    }

    handleMultiAccountAdminModeChange = (mode) => {
        this.setState({ mode: mode });
    }

    handleMultiAccountAdminModal = () => {
        this.setState({ showMultiAccountAmdinModal: !this.state.showMultiAccountAmdinModal, mode: null });
    }

    renderMultiAccountAdminDropDown = () => {
        const { t } = this.props;
        const { show_multi_account_drop_down, hasMultiAccountAdminAccess } = this.state;
        if (!hasMultiAccountAdminAccess) return null;
        const multiAccountJWT = window.sessionStorage.getItem('multiAdminJwt');
        const currentJWT = window.sessionStorage.getItem('jwt');
        return (
            <div className="header-common-dropdwn-style multi-account-dropdown-container mx-2" title={t('Manage Accounts')}>
                <Dropdown
                    show={show_multi_account_drop_down}
                    onToggle={(isOpen) => {
                        this.handledropdownToggle(isOpen, 'show_multi_account_drop_down');
                    }}
                >
                    <Dropdown.Toggle>
                        <i className="fa fa-users" ></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Header>
                            <div className='d-flex justify-content-space-between'>
                                <span
                                    onClick={() => this.handleMultiAccountAdminActions('add', null)}
                                    title={t('Add account')} >
                                    {t('Add')}
                                </span>
                                <span
                                    onClick={() => this.handleMultiAccountAdminActions('manage', null)}
                                    title={t('Manage Accounts')}>
                                    {t('Manage')}
                                </span>
                            </div>
                        </Dropdown.Header>
                        {this.props.multiAccountAdminStore.currentAccountListForAdmin.map(item =>
                            <Dropdown.Item
                                key={uuid()}
                                as={Button}
                                onClick={() => this.handleMultiAccountAdminActions('switch', item)}
                            >
                                <div className='multi-account-dropdown-item'>
                                    <span className='account text-capitalize'>{item.secondary_client_name}</span>
                                    <span className={`account-status ${item.is_email_verified ?
                                        item.is_jwt_token_expired ? 'text-warning' : 'text-success' : 'text-danger'}`}
                                    >
                                        {item.is_email_verified ?
                                            item.is_jwt_token_expired ?
                                                t('Expired') : t('Active')
                                            : t('Not verified')}
                                    </span>
                                </div>
                            </Dropdown.Item>
                        )}
                        {!this.props.multiAccountAdminStore.currentAccountListForAdmin.length &&
                            <Dropdown.Item
                                key={uuid()}
                                as={Label}
                            >
                                {t('No accounts found')}
                            </Dropdown.Item>
                        }
                        {multiAccountJWT !== currentJWT &&
                            <>
                                <Dropdown.Divider />
                                <Dropdown.Item
                                    key={uuid()}
                                    as={Button}
                                    onClick={() => this.handleMultiAccountAdminActions('back', null)}
                                >
                                    {t('Back To Main Account')}
                                </Dropdown.Item>
                            </>
                        }
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        );
    }

    render() {
        const { handleLogOut, t, userStore, commonStore } = this.props;
        if (!userStore.currentUser) return <LoadingSpinner />
        const { show_message_dropdown, show_notification_dropdown, showMultiAccountAmdinModal, mode, currentMultiAccount } = this.state;
        const {
            messageNotifications,
            dockNotifications,
            newNotificationsCount,
            messageNotificationsCount,
            isNotificationDockOpen,
            isMessageNotificationOpen,
            isSidebarOpen,
        } = userStore;
        let dateTimeRules = commonStore.config.client && commonStore.config.client.data && commonStore.config.client.data.dateTimeRules
            ? { ...commonStore.config.client.data.dateTimeRules }
            : { short_date_format: 'DD.MM.YYYY', time_format: 'hh:mm' };
        dateTimeRules.short_date_format = dateTimeRules.short_date_format ? dateTimeRules.short_date_format : 'DD.MM.YYYY';
        dateTimeRules.time_format = dateTimeRules.time_format ? dateTimeRules.time_format : 'hh:mm';

        const extraModuleLinks = this.getExtralinks(userStore?.currentUser?.user_type);

        return (
            <>
                <div className="MainWrapperAdmin py-3">
                    {/* Navbar for screen < 1300px */}
                    <Row className="row g-3 w-100 align-items-center justify-content-between d-custom-xl-none">
                        <div className="col-custom-auto">
                            <div className="toggle-sidebar-button">
                                <button className="btn-menubar" onClick={this.handleBtnClick}>
                                    <span className="bar1"></span>
                                    <span className="bar2"></span>
                                    <span className="bar3"></span>
                                </button>
                            </div>
                        </div>
                        <div className="col-custom-auto"></div>
                        <div className="col-custom-auto">
                            <div className='d-flex justify-content-center align-items-center'>
                                {this.renderMultiAccountAdminDropDown()}
                                <GenericDropdown />
                            </div>
                        </div>
                    </Row>

                    <Offcanvas
                        className="offcanvasOuter sidebarcanvas"
                        show={isSidebarOpen}
                        onHide={this.handleclose}
                        style={{ backgroundColor: '#fff' }}
                    >
                        <Offcanvas.Header className="offcanvasHeader" closeButton>
                            <Offcanvas.Title>
                                <div className="offcanvasLogo"></div>
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body className="offcanvasBody sidebarcanvasbody">
                            <SidebarMobile
                                {...this.props}
                                routes={routes}
                                hasImage
                                handleMiniClick={this.handleMiniClick}
                                handleCrossClick={this.handleCrossClick}
                                hideSidebar={this.handleclose}
                                handleLogOut={handleLogOut}
                            />
                        </Offcanvas.Body>
                    </Offcanvas>

                    {/* Off canvas for message notifications */}
                    <Offcanvas
                        backdrop="static"
                        placement="end"
                        className="notifications-offcanvas"
                        scroll="true"
                        show={isMessageNotificationOpen}
                        onHide={() => this.closeNotificationCanvas('messages')}
                    >
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title>{t('Chat Notifications')}</Offcanvas.Title>
                            <div className="btn-clear-notification">
                                <Button
                                    fill
                                    icon_sm_delete
                                    title="Clear"
                                    onClick={() => userStore.clearNotifications('messages')}
                                >
                                    <i className="fa fa-trash" />
                                </Button>
                            </div>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <ShowNotifications
                                notificationType="message-notifications"
                                closeNotificationCanvas={this.closeNotificationCanvas}
                            />
                        </Offcanvas.Body>
                    </Offcanvas>

                    {/* Off canvas for message notifications */}
                    <Offcanvas
                        backdrop="static"
                        className="notifications-offcanvas"
                        placement="end"
                        scroll="true"
                        show={isNotificationDockOpen}
                        onHide={() => this.closeNotificationCanvas('notifications')}
                    >
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title>{t('Notifications')}</Offcanvas.Title>
                            <div className="btn-clear-notification">
                                <Button
                                    fill
                                    icon_sm_delete
                                    title="Clear"
                                    onClick={() => userStore.clearNotifications('notifications')}
                                >
                                    <i className="fa fa-trash" />
                                </Button>
                            </div>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <ShowNotifications notificationType="notifications" />
                        </Offcanvas.Body>
                    </Offcanvas>

                    {/* Navbar for screen > 1300px */}
                    <Row className="g-0 g-lg-3 w-100 align-items-center justify-content-between">
                        {userStore?.currentUser?.user_type === 'super-admin' ? (
                            <>
                                <div className="col-md">{/* Block empty to set layout */}</div>
                                <div className="col-md-auto">
                                    <div className="d-none d-custom-lg-flex">
                                        <div className="btn-group flexMainNavWrap">
                                            <div className="d-none d-custom-xl-inline-block">
                                                <div className='d-flex justify-content-center align-items-center'>
                                                    <GenericDropdown />
                                                </div>
                                            </div>

                                            <div className="header-common-dropdwn-style mx-2">
                                                {/* Notification Dropdown Superadmin */}
                                                <Dropdown className="notify-dropdown">
                                                    <Dropdown.Toggle>
                                                        <img
                                                            src={notificationSvg}
                                                            alt="msgSvg"
                                                            width="18px"
                                                            height="18px"
                                                        />
                                                        {newNotificationsCount > 0 && (
                                                            <span className="notify-count-number">
                                                                {newNotificationsCount}
                                                            </span>
                                                        )}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Header>
                                                            {newNotificationsCount > 0 || dockNotifications?.length > 0 ? (
                                                                <div className='d-flex'>
                                                                    <div className="notify-count w-100">{`${t('You have')} ${newNotificationsCount} ${t('new messages')}`}</div>
                                                                    {newNotificationsCount > 5 || dockNotifications?.length > 5 ? (
                                                                        <div
                                                                            className="notify-view-all"
                                                                            onClick={this.showNotifications}
                                                                        >
                                                                            {t('View all')}
                                                                        </div>
                                                                    ) : <div role={"button"} className='clear-btn' onClick={() => userStore.clearNotifications('notifications')}>{t('Clear')}</div>}
                                                                </div>
                                                            ) : (
                                                                <div>{t(`${t('You have')} 0 ${t('new notifications')}`)}</div>
                                                            )}
                                                        </Dropdown.Header>
                                                        <div className="notification-list scrollable">
                                                            {dockNotifications && dockNotifications.length ? (
                                                                <>
                                                                    {dockNotifications
                                                                        .slice(0, 5)
                                                                        .map((item) => (
                                                                            <Dropdown.Item
                                                                                key={uuid()}
                                                                                as={Link}
                                                                                to={
                                                                                    `/admin/${item?.data?.context?.source}/${item?.data?.context?.id}` ||
                                                                                    ''
                                                                                }
                                                                                className={`notify-item ${item?.is_unread ? "unread" : "read"}`}
                                                                                onClick={() => this.props.userStore.readNotifications(item.id)}
                                                                            >
                                                                                {this.renderDropdownItem(
                                                                                    item,
                                                                                    'notifications'
                                                                                )}
                                                                            </Dropdown.Item>
                                                                        ))}
                                                                </>
                                                            ) : (
                                                                <div
                                                                    className="text-center fw-bold"
                                                                    style={{ color: '#E83030' }}
                                                                >
                                                                    {t('No new Notifications')}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>

                                        <div className="userProfileWrapper d-flex align-items-center">
                                            <div style={{ paddingLeft: '10px' }}>
                                                <UserProfileLogo width="32px" height="32px" borderRadius="44px" />
                                            </div>
                                            <Dropdown className="user-profile-dropdown">
                                                <Dropdown.Toggle>
                                                    <span className="user-name">
                                                        {userStore.currentUser?.first_name}{' '}
                                                        {userStore.currentUser?.last_name
                                                            ? userStore.currentUser.last_name.split('')[0]
                                                            : ''}
                                                    </span>
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Header>
                                                        <div className="d-flex align-items-center gap-3">
                                                            <UserProfileLogo />
                                                            <div>
                                                                <span className="user-name d-block">
                                                                    {userStore.currentUser.first_name}{' '}
                                                                    {userStore.currentUser.last_name
                                                                        ? userStore.currentUser.last_name.split('')[0]
                                                                        : ''}
                                                                </span>
                                                                <span className="user-role d-block">
                                                                    {' '}
                                                                    {userStore?.currentUser?.user_type}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </Dropdown.Header>
                                                    <div className="notification-list scrollable">
                                                        <Dropdown.Item>
                                                            <Link
                                                                to="/admin/profile"
                                                                className="user-profile-item d-flex align-items-center"
                                                            >
                                                                <i className="fa fa-user"></i>
                                                                {t('User profile')}
                                                            </Link>
                                                        </Dropdown.Item>
                                                        <Dropdown.Item>
                                                            <Link
                                                                to="/"
                                                                className="user-profile-item d-flex align-items-center"
                                                                onClick={handleLogOut}
                                                            >
                                                                <i
                                                                    className="fas fa-sign-out-alt"
                                                                    aria-hidden="true"
                                                                ></i>
                                                                {t('Log out')}
                                                            </Link>
                                                        </Dropdown.Item>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="col-md">
                                    <div className="header-functions-menu">
                                        {this.state.width > 991 && (
                                            <>
                                                {extraModuleLinks.map((headerOption) =>
                                                    <NavLink
                                                        to={headerOption.route_map}
                                                        key={uuid()}
                                                        className={({ isActive }) =>
                                                            isActive
                                                                ? 'header-functions-menu-item-active'
                                                                : 'header-functions-menu-item'
                                                        }
                                                    >
                                                        <img
                                                            src={headerOption.svg}
                                                            alt="navlinks"
                                                            width="20px"
                                                            height="20px"
                                                            className="header-functions-img"
                                                        />
                                                        {t(headerOption.name)}
                                                    </NavLink>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div className="col-md-auto">
                                    <div className="d-none d-custom-lg-flex">
                                        <div className="btn-group flexMainNavWrap">
                                            <div className="d-none d-custom-xl-inline-block">
                                                <div className='d-flex justify-content-center align-items-center'>
                                                    {this.renderMultiAccountAdminDropDown()}
                                                    <GenericDropdown />
                                                </div>
                                            </div>
                                            <div className="header-common-dropdwn-style me-1">
                                                {/* Message notification dropdown */}
                                                <Dropdown
                                                    className="notify-dropdown"
                                                    show={show_message_dropdown}
                                                    onToggle={(isOpen) => {
                                                        this.handledropdownToggle(isOpen, 'message');
                                                    }}
                                                >
                                                    <Dropdown.Toggle>
                                                        <img src={messageSvg} alt="msgSvg" width="18px" height="18px" />
                                                        {messageNotificationsCount > 0 && (
                                                            <span className="notify-count-number">
                                                                {messageNotificationsCount}
                                                            </span>
                                                        )}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Header>
                                                            {messageNotificationsCount > 0 || messageNotifications?.length > 0 ? (
                                                                <div className='d-flex'>
                                                                    <div className="notify-count w-100">{`${t('You have')} ${messageNotificationsCount} ${t('new messages')}`}</div>
                                                                    {messageNotificationsCount > 5 || messageNotifications?.length > 5 ? (
                                                                        <div
                                                                            className="notify-view-all text-end"
                                                                            onClick={this.showMessageNotfications}
                                                                        >
                                                                            {t('View all')}
                                                                        </div>
                                                                    ) : <div role={"button"} className='clear-btn' onClick={() => userStore.clearNotifications('messages')}>{t('Clear')}</div>}
                                                                </div>
                                                            ) : (
                                                                <div>{t(`${t('You have')} 0 ${t('new messages')}`)}</div>
                                                            )}
                                                        </Dropdown.Header>
                                                        <div className="notification-list scrollable">
                                                            {messageNotifications && messageNotifications.length ? (
                                                                <>
                                                                    {messageNotifications
                                                                        .slice(0, 5)
                                                                        .map((item) =>
                                                                            item.data.context.source ===
                                                                                'privatechat' ? (
                                                                                <Dropdown.Item
                                                                                    key={uuid()}
                                                                                    as={Link}
                                                                                    to={
                                                                                        item.data.context
                                                                                            .isChatRoomDeleted
                                                                                            ? ''
                                                                                            : `/admin/privatechat/${item.data.context?.room}`
                                                                                    }
                                                                                    className={`notify-item ${item?.is_unread ? "unread" : "read"}`}
                                                                                    onClick={() => this.props.userStore.readNotifications(item.id)}
                                                                                >
                                                                                    {this.renderDropdownItem(
                                                                                        item,
                                                                                        'messageNotifications'
                                                                                    )}
                                                                                </Dropdown.Item>
                                                                            ) : (
                                                                                <Dropdown.Item
                                                                                    key={uuid()}
                                                                                    as={Link}
                                                                                    to={
                                                                                        `/admin/chat/${item.data.context?.room}` ||
                                                                                        ''
                                                                                    }
                                                                                    className={`notify-item ${item?.is_unread ? "unread" : "read"}`}
                                                                                    onClick={() => this.props.userStore.readNotifications(item.id)}
                                                                                >
                                                                                    {this.renderDropdownItem(
                                                                                        item,
                                                                                        'messageNotifications'
                                                                                    )}
                                                                                </Dropdown.Item>
                                                                            )
                                                                        )}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <div
                                                                        className="text-center fw-bold"
                                                                        style={{ color: '#E83030' }}
                                                                    >
                                                                        {t('No Messages')}
                                                                    </div>
                                                                </>
                                                            )}
                                                        </div>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                            <div className="header-common-dropdwn-style mx-2">
                                                {/* Notification dropdown */}
                                                <Dropdown
                                                    className="notify-dropdown"
                                                    show={show_notification_dropdown}
                                                    onToggle={(isOpen) => {
                                                        this.handledropdownToggle(isOpen, 'notification');
                                                    }}
                                                >
                                                    <Dropdown.Toggle>
                                                        <img
                                                            src={notificationSvg}
                                                            alt="msgSvg"
                                                            width="18px"
                                                            height="18px"
                                                        />
                                                        {newNotificationsCount > 0 && (
                                                            <span className="notify-count-number">
                                                                {newNotificationsCount}
                                                            </span>
                                                        )}
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        <Dropdown.Header>
                                                            {newNotificationsCount > 0 || dockNotifications?.length > 0 ? (
                                                                <div className='d-flex'>
                                                                    <div className="notify-count w-100">{`${t('You have')} ${newNotificationsCount} ${t('new notifications')}`}</div>
                                                                    {newNotificationsCount > 5 || dockNotifications?.length > 5 ? (
                                                                        <div
                                                                            className="notify-view-all text-end"
                                                                            onClick={this.showNotifications}
                                                                        >
                                                                            {t('View all')}
                                                                        </div>
                                                                    ) : <div role={"button"} className='clear-btn' onClick={() => userStore.clearNotifications('notifications')}>{t('Clear')}</div>}
                                                                </div>
                                                            ) : (
                                                                <div>{t(`${t('You have')} 0 ${t('new notifications')}`)}</div>
                                                            )}
                                                        </Dropdown.Header>
                                                        <div className="notification-list scrollable">
                                                            {dockNotifications && dockNotifications.length ? (
                                                                <>
                                                                    {dockNotifications
                                                                        .slice(0, 5)
                                                                        .map((item) => (
                                                                            <Dropdown.Item
                                                                                key={uuid()}
                                                                                as={Link}
                                                                                to={`/admin/${item?.data?.context?.source}/${item?.data?.context?.id}`}
                                                                                className={`notify-item ${item?.is_unread ? "unread" : "read"}`}
                                                                                onClick={() => this.props.userStore.readNotifications(item.id)}
                                                                            >
                                                                                {this.renderDropdownItem(
                                                                                    item,
                                                                                    'notifications'
                                                                                )}
                                                                            </Dropdown.Item>
                                                                        ))}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <div
                                                                        className="text-center fw-bold"
                                                                        style={{ color: '#E83030' }}
                                                                    >
                                                                        {t('No new Notifications')}
                                                                    </div>
                                                                </>
                                                            )}
                                                        </div>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                        <div className="userProfileWrapper d-flex align-items-center">
                                            <div style={{ paddingLeft: '10px' }}>
                                                <UserProfileLogo width="32px" height="32px" borderRadius="44px" />
                                            </div>
                                            <Dropdown className="user-profile-dropdown">
                                                <Dropdown.Toggle>
                                                    <span className="user-name">
                                                        {userStore.currentUser?.first_name}{' '}
                                                        {userStore.currentUser?.last_name
                                                            ? userStore.currentUser.last_name.split('')[0]
                                                            : ''}
                                                    </span>
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Header>
                                                        <div className="d-flex align-items-center w-100">
                                                            <div className='w-25'>
                                                                <UserProfileLogo />
                                                            </div>
                                                            <div className='w-75 overflow-hidden'>
                                                                <span className="user-name d-block" title={`${userStore.currentUser.first_name} ${userStore.currentUser.last_name}`}>
                                                                    {`${userStore.currentUser.first_name} ${userStore.currentUser.last_name}`}
                                                                </span>
                                                                <span className="user-role d-block text-capitalize">
                                                                    {userStore.currentUser?.user_type}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </Dropdown.Header>
                                                    <div className="notification-list scrollable">
                                                        <Dropdown.Item>
                                                            <Link
                                                                to="/admin/profile"
                                                                className="user-profile-item d-flex align-items-center"
                                                            >
                                                                <i className="fa fa-user"></i>
                                                                {t('User profile')}
                                                            </Link>
                                                        </Dropdown.Item>
                                                        {userStore.currentUser.user_type === 'admin' ? (
                                                            <>
                                                                <Dropdown.Item>
                                                                    <Link
                                                                        to="/admin/companyprofile"
                                                                        className="user-profile-item d-flex align-items-center"
                                                                    >
                                                                        <i className="fa fa-user"></i>
                                                                        {t('Company profile')}
                                                                    </Link>
                                                                </Dropdown.Item>
                                                                <Dropdown.Item>
                                                                    <Link
                                                                        to="/admin/control_panel"
                                                                        className="user-profile-item d-flex align-items-center"
                                                                    >
                                                                        <i className="fa fa-cog"></i>
                                                                        {t('Control Panel')}
                                                                    </Link>
                                                                </Dropdown.Item>
                                                            </>
                                                        ) : (
                                                            <> </>
                                                        )}
                                                        {userStore.currentUser.user_type !== 'super-admin' ? (
                                                            <Dropdown.Item>
                                                                <Link
                                                                    to="/admin/files"
                                                                    className="user-profile-item d-flex align-items-center"
                                                                >
                                                                    <i className="fa fa-files-o"></i>
                                                                    {t('Documents')}
                                                                </Link>
                                                            </Dropdown.Item>
                                                        ) : (
                                                            <></>
                                                        )}
                                                        <Dropdown.Item >
                                                            <Link
                                                                to="/"
                                                                className="user-profile-item d-flex align-items-center"
                                                                onClick={handleLogOut}
                                                            >
                                                                <i
                                                                    className="fas fa-sign-out-alt"
                                                                    aria-hidden="true"
                                                                ></i>
                                                                {t('Log out')}
                                                            </Link>
                                                        </Dropdown.Item>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </Row>
                </div>
                <Modal
                    size={mode === 'manage' ? 'lg' : 'md'}
                    className="modal fade"
                    show={showMultiAccountAmdinModal}
                    onHide={this.handleMultiAccountAdminModal}
                >
                    <Modal.Header>
                        <Modal.Title className="w-100 text-center m-0">{t('Multi-Account Administration')}</Modal.Title>
                        <img
                            src={CloseModalIcon}
                            className="cursor-pointer"
                            onClick={this.handleMultiAccountAdminModal}
                        />
                    </Modal.Header>
                    <Modal.Body>
                        <MultiAccountAdmin
                            mode={mode}
                            currentMultiAccount={currentMultiAccount}
                            handleMultiAccountAdminActions={this.handleMultiAccountAdminActions}
                            handleMultiAccountAdminModeChange={this.handleMultiAccountAdminModeChange}
                        />
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

export default inject('commonStore', 'userStore', 'multiAccountAdminStore')(applicationRouter(withLocalization(withRemoveDialog((observer(AdminNavbar))))));
