import React, { Component } from 'react';
import applicationRouter from '~/hoc/applicationRouter'
import { inject, observer } from 'mobx-react';

import SubscriptionsList from './SubscriptionsList';

import withRemoveDialog from '~/hoc/withRemoveDialog';
import withLocalization from '~/hoc/withLocalization';

class Subscriptions extends Component {

    onAdd() {
        this.props.router.navigate('/admin/subscriptions/add');
    }

    render() {
        return (
            <SubscriptionsList
                onTableAction={(id, type) => this.onTableAction(id, type)}
                onAdd={() => this.onAdd()}
            />
        );
    }
}
export default inject('authStore', 'subscriptionStore', 'userStore', 'commonStore')(applicationRouter(withLocalization(withRemoveDialog(observer(Subscriptions)))));
