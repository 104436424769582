import React from "react";
import "./styles.css";

function InfoBox(props) {
  return (
    <div className="infoCol">
      <span>
        <img src={props.src} alt={props.title} />
      </span>
      <div className="infoColDes">
        <h4>{props.title}</h4>
        <p>{props.description}</p>
      </div>
    </div>   
  );
}
export default InfoBox;