import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Select from '../../../elements/Select';
import withLocalization from '../../../hoc/withLocalization';

const InternaluserFormIntegrationWidget = inject('commonStore', 'userStore')(withLocalization(observer(class InternaluserFormIntegrationWidget extends Component {
    constructor(props) {
        super(props);
        this.state ={
            dept: [],
            deptType: {}
        }
    }
    handleChange = (value) => {
        const { onChange } = this.props;
        onChange(value.value);
        this.setState({ deptType: value })
    }

    componentDidMount () {
        this.loadData();
    }

    async loadData () {
        if (this.props.commonStore.config?.integration_details?.product === 'tripletex'){
            const getDepartment = await this.props.userStore.getDepartmentForIntegration()
            const updatedDepartment = getDepartment.map(ele => {
                return { value: ele.id, label: ele.name}
            })
            this.setState({dept: updatedDepartment})
            if (this.props.userStore.currentEntity?.user?.data?.departmentIntegration) {
                const addedDepartment = updatedDepartment.find(ele => ele.value == this.props.userStore.currentEntity.user.data.departmentIntegration)
                this.setState({ deptType: addedDepartment })
            }
        }
    }

    render() {
        return (
            <>
                <Select className="min-width-100" options={this.state.dept} onChange={event => this.handleChange(event)} value={this.state.deptType}/>
            </>
        );
    }
})));

export const UserFormIntegrationWidget = observer(class UserFormIntegrationWidget extends Component {
    render() {
        return <InternaluserFormIntegrationWidget {...this.props} mode="managers" />;
    }
});
