import React, { Component } from 'react';
import Switch from 'react-switch';
import { inject, observer } from 'mobx-react';
import withLocalization from '../../../hoc/withLocalization';

const TrueFalseWidgets = inject('projectStore', 'userStore', 'commonStore')(withLocalization(observer(class TrueFalseWidgets extends Component {
    // class TrueFalceWidget extends Component {
    render() {
        const { value, onChange } = this.props;
        return (
            <div>
                <Switch checked={!!value}
                    onChange={checked => onChange(!!checked)}
                    onColor={"#2550AC"}
                    checkedIcon={false}
                    uncheckedIcon={false} />
            </div>
        );
    }
}

)));
export const TrueFalseWidget = observer(class TrueFalseWidget extends Component {
    render() {
        return <TrueFalseWidgets {...this.props} />;
    }
});

// export default TrueFalceWidget;
