import React from "react";
import { Link } from "react-router-dom";
import "./styles.css";

import ArrowIcon from "../../assets/images/arrowIcon.svg";

//i18n
import { withTranslation } from "react-i18next"

function Feature(props) {
  return (
    <div className={`feaCol ${props.customClass} ${props.border}`}>
      <div className={`feaColIcon ${props.color}`}>
        <i><img src={props.src} alt={props.title} /></i>
      </div> 
      <div className="feaColInfo">
        <h4>{props.title}</h4>
        <p>{props.description}</p>
        <Link to={props.link}><span>{props.t("Learn More")}</span> <img src={ArrowIcon} alt="Arrow" /></Link>
      </div>
    </div>   
  );
}
export default withTranslation()(Feature);