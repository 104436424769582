import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Viewer } from '@react-pdf-viewer/core';
import { Worker } from '@react-pdf-viewer/core';
import { OverlayTrigger, Tooltip, Row, Col } from 'react-bootstrap';

//components
import applicationRouter from '~/hoc/applicationRouter';
import withLocalization from '~/hoc/withLocalization';
import DocumentMultipleSelector from './DocumentMultipleSelector';
import DocumentMembers from './DocumentMembers';
import Signature from '../../../../components/Signature/Signature';

//elements
import Button from '../../../../components/CustomButton/CustomButton.jsx';
import { Input } from '../../../../elements/Input';
import CustomCheckBox from '../../../../components/CustomCheckbox/CustomCheckbox';

//styles
import '../../../../styles/css/styles.css';

//this component is used as both add and edit page for documents//

class DocumentTemplateAddEdit extends Component {
    state = {
        isLoad: false, //holds page while document_book initial vlaues are loading
        template: null, //used for passing props to DocumentMultiselector
        //isEmployee: true,//helps to restrict view and actions
        isSaved: false, //true if document_book is saved
        isReadable: false, //true if document_book mail is send and employee need to read
        documentPath: null, //keeps document_book report path
        isSaveAndSend: false, // true if send mail check_box checked
        isPreview: false, //true if document_book pdf is loaded
        signature: null,
        isSignatureDone: false, //if document is read and signature is done
        isPreviewing: false,
        isSelectAll: false, //for selecting or de-selecting all the document-options
    };

    loadData = async () => {
        //calls action based on props to initiate currentDocumentTemplate values//
        const { documentStore, id, add } = this.props;

        if (id && id > 0 && !add) {
            documentStore.loadDocumentTemplateById(id).then((data) => {
                if (documentStore.currentDocumentTemplate.document_read) {
                    this.setState(
                        {
                            template: documentStore.currentDocumentTemplate,
                            signature: documentStore.currentDocumentTemplate.document_read.signature,
                            isReadable: documentStore.currentDocumentTemplate.document_read.status ? false : true,
                            isSaved: true,
                            isSignatureDone: documentStore.currentDocumentTemplate.document_read.signature
                                ? true
                                : false,
                        },
                        () => {
                            //loading last document_book pdf for preview
                            this.previewDocument();
                            this.setState({
                                isLoad: true,
                            });
                        }
                    );
                } else {
                    this.setState(
                        {
                            template: documentStore.currentDocumentTemplate,
                            //isSent: true,
                            isSaved: true,
                        },
                        () => {
                            //loading last document_book pdf for preview
                            this.previewDocument();
                            this.setState({
                                isLoad: true,
                            });
                        }
                    );
                }
                this.checkSelectAll();
            });
        } else {
            documentStore.loadDefaultDocumentTemplate();
            this.setState(
                {
                    template: documentStore.currentDocumentTemplate,
                },
                () => {
                    this.setState({
                        isLoad: true,
                    });
                }
            );
        }
    };

    componentDidMount() {
        //calls loaddata and sets user type//
        // const { user_type } = this.props.userStore.currentUser;
        // if (user_type === "admin" || user_type === "super-admin" || user_type === "pm") {
        //     this.setState({ isEmployee: false })
        // }
        this.loadData();
    }

    handleTextChange = (name, value) => {
        //calls action to handle text changes//
        this.props.documentStore.handleDocumentTemplateChange(name, value);
        if (name === 'is_signature_required' || name === 'isobligatory') {
            this.checkSelectAll();
        }
    };

    handleButtonClick = (type) => {
        //handles action button clicks//
        if (type === 'save') {
            //saves document book//
            this.addDocumentTemplateData();
        } else if (type === 'send') {
            //sends document book created notification//
            //alert("Commented!")
            this.sendDocumentReadMailToMembers();
        } else if (type === 'read') {
            //sends document book read notification//
            this.updateDocumentReadStatus();
        } else if (type === 'preview') {
            //sends document book read notification//
            this.previewDocumentWithoutSave();
        } else {
            this.cancelButton();
        }
    };

    //Send document book created notification to all the members and pm//
    //who are yet to read the document//
    sendDocumentReadMailToMembers = async () => {
        const { documentStore, commonStore, t } = this.props;
        let data = {};
        data = {
            document_id: documentStore.currentDocumentTemplate.id,
        };

        await documentStore
            .sendDocumentReadMailToMembers(data)
            .then((response) => {
                if (response) {
                    commonStore.addNotification(t('Document Book Notification Mail Sent'), null, 'success');
                    return true;
                }
            })
            .catch((error) => {
                commonStore.addNotification(error.message || t('Error'), null, 'error');
            });
    };

    //Update read status and sends mails to pm and admins//
    updateDocumentReadStatus = async () => {
        const { documentStore, commonStore, t } = this.props;
        let document_book_id = documentStore.currentDocumentTemplate.id;
        if (
            this.state.template.is_signature_required &&
            (this.state.signature === undefined || this.state.signature === '')
        ) {
            commonStore.addNotification(t('Please Do Signature'), null, 'error');
            return;
        }
        let data = {
            document_id: document_book_id,
            status: 'read',
            signature: this.state.signature,
        };
        await documentStore
            .updateDocumentReadStatus(data)
            .then((response) => {
                commonStore.addNotification(t('Document Book Status Updated'), null, 'success');
                this.props.router.navigate(this.getListUrl());
                return true;
            })
            .catch((error) => {
                commonStore.addNotification(error.message || t('Error'), null, 'error');
            });
    };

    //add new document read data ******added this call in back-end//
    addDocumentRead = async () => {
        const { documentStore, commonStore, t } = this.props;
        let data = {};

        data = {
            id: 0,
            document_id: documentStore.currentDocumentTemplate.id,
            isobligatory: documentStore.currentDocumentTemplate.isobligatory,
        };

        await documentStore
            .addDocumentRead(data)
            .then((response) => {
                if (response) {
                    commonStore.addNotification(t('Document Read Data Added'), null, 'success');
                    return true;
                }
            })
            .catch((error) => {
                commonStore.addNotification(error.message || t('Error'), null, 'error');
            });
    };

    //previews document, works only after document_book is added//
    //shows the document in tab//
    previewDocument = async () => {
        const { commonStore, t, documentStore } = this.props;
        let id = documentStore.currentDocumentTemplate.id;
        commonStore.addNotification(t('Please Wait... Document Book Is Loading'), null, 'success');
        await documentStore.generateDocumentReportPreview(id).then((data) => {
            this.setState({ isPreview: true, documentPath: data });
        });
    };

    previewDocumentWithoutSave = async () => {
        const { commonStore, t, documentStore } = this.props;

        let documentTemplate = {
            template_id: documentStore.currentDocumentTemplate.id || 0,
            document_ids: documentStore.currentDocumentTemplate.template_documents,
            template_name: documentStore.currentDocumentTemplate.template_name.replaceAll(' ', '_-_'),
        };

        if (
            documentTemplate.template_name === '' ||
            documentTemplate.template_name === null ||
            documentTemplate.template_name === undefined
        ) {
            commonStore.addNotification(t('Please Enter Document Book Name'), null, 'error');
            return;
        }

        if (
            documentTemplate.document_ids === '' ||
            documentTemplate.document_ids === null ||
            documentTemplate.document_ids === undefined
        ) {
            commonStore.addNotification(t('Please Select Documents'), null, 'error');
            return;
        }

        commonStore.addNotification(t('Please Wait... Document Book Is Loading'), null, 'success');
        this.setState({isPreviewing: true});
        await documentStore.generateMultipleDocumentsPdfPreviewUrl(documentTemplate).then((data) => {
            this.setState({ isPreview: true, documentPath: data });
        });
    };

    addDocumentTemplateData = async () => {
        //calls action to save document//
        const { documentStore, commonStore, t, add } = this.props;
        let documentTemplate = {
            id: documentStore.currentDocumentTemplate.id,
            template_name: documentStore.currentDocumentTemplate.template_name,
            template_documents: documentStore.currentDocumentTemplate.template_documents,
            is_signature_required: documentStore.currentDocumentTemplate.is_signature_required,
            isobligatory: documentStore.currentDocumentTemplate.isobligatory,
            assigendMembers: documentStore.currentDocumentTemplate.assigendMembers,
        };

        if (
            documentTemplate.template_name === '' ||
            documentTemplate.template_name === null ||
            documentTemplate.template_name === undefined
        ) {
            commonStore.addNotification(t('Please Enter Document Book Name'), null, 'error');
            return;
        }

        if (
            documentTemplate.template_documents === '' ||
            documentTemplate.template_documents === null ||
            documentTemplate.template_documents === undefined
        ) {
            commonStore.addNotification(t('Please Select Documents'), null, 'error');
            return;
        }

        if (
            documentTemplate.assigendMembers === '' ||
            documentTemplate.assigendMembers === null ||
            documentTemplate.assigendMembers === undefined
        ) {
            commonStore.addNotification(t('Please Select Members'), null, 'error');
            return;
        }

        await documentStore
            .saveDocumentTemplateData(documentTemplate)
            .then((data) => {

                if (data) {
                    commonStore.addNotification(t('Document Book Saved'), null, 'success');
                    if (this.state.isSaveAndSend && add) {
                        this.sendDocumentReadMailToMembers();
                    }
                    this.setState({ isSaved: true });
                    this.props.router.navigate(this.getListUrl());
                }
            })
            .catch((error) => {
                commonStore.addNotification(error.message || t('Error'), null, 'error');
            });
    };

    getListUrl() {
        //returns template list URL//
        return '/admin/documents/templates';
    }

    cancelButton() {
        //redirects pages to list URL//
        // const { history } = this.props;
        // history.push(this.getListUrl());
        this.props.router.navigate(this.getListUrl());
    }

    checkSelectAll = () => {
        const { currentDocumentTemplate } = this.props.documentStore;
        const { isSaveAndSend } = this.state;
        const { add } = this.props;
        if (currentDocumentTemplate.is_signature_required === false || currentDocumentTemplate.isobligatory === false) {
            this.setState({ isSelectAll: false });
        } else if (add === true && isSaveAndSend === false) {
            this.setState({ isSelectAll: false });
        } else {
            this.setState({ isSelectAll: true });
        }
    };

    handleSelectAll = (value) => {
        this.setState({ isSelectAll: value, isSaveAndSend: value });
        this.props.documentStore.handleDocumentTemplateChange('is_signature_required', value);
        this.props.documentStore.handleDocumentTemplateChange('isobligatory', value);
    };

    render() {
        const { t, add } = this.props;
        const { user_type } = this.props.userStore.currentUser;
        const { currentDocumentTemplate } = this.props.documentStore;
        return (
            <div className="page" style={{ padding: 0, margin: 0 }}>
                <div className="page_content">
                    <form className="custom-form">
                        <Row className="mt-4">
                            <Input
                                className="custom-form__col"
                                disabled={user_type === 'member'}
                                label={`${t('Document Book')} *`}
                                placeholder={t('Document Book Name')}
                                type="text"
                                name="template_name"
                                value={currentDocumentTemplate.template_name}
                                onChange={(evt) => {
                                    this.handleTextChange('template_name', evt.target.value);
                                }}
                            />
                        </Row>

                        {this.state.isLoad && user_type !== 'member' && (
                            <>
                                <Row>
                                    <Col xs={6}>
                                        <DocumentMultipleSelector
                                            changeDocumentHandle={this.changeDocumentHandle}
                                            documents={this.state.template.template_documents}
                                        />
                                    </Col>
                                    <Col xs={6}>
                                        <DocumentMembers
                                            onChange={(selected) => this.handleTextChange('assigendMembers', selected)}
                                            value={currentDocumentTemplate.assigendMembers}
                                        />
                                    </Col>
                                </Row>
                                <Row className='mt-2'>
                                    <Col xs={8}>
                                        <Row>
                                            <Col xs={3}>
                                                <CustomCheckBox
                                                    label={`${t('Select All')}`}
                                                    isChecked={this.state.isSelectAll}
                                                    onChange={(evt) => this.handleSelectAll(evt)}
                                                />
                                            </Col>
                                            <Col xs={3}>
                                                <OverlayTrigger
                                                    key="top"
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="tooltip-top">
                                                            {t('Employees Have To Sign The Document')}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <CustomCheckBox
                                                        label={`${t('Sign Required')}`}
                                                        isChecked={currentDocumentTemplate.is_signature_required}
                                                        onChange={(evt) => {
                                                            this.handleTextChange('is_signature_required', evt);
                                                        }}
                                                    />
                                                </OverlayTrigger>
                                            </Col>
                                            <Col xs={3}>
                                                <OverlayTrigger
                                                    key="top"
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="tooltip-top">
                                                            {t('Obligatory For Employees To Read Document')}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <CustomCheckBox
                                                        label={`${t('Obligatory')}`}
                                                        isChecked={currentDocumentTemplate.isobligatory}
                                                        onChange={(evt) => {
                                                            this.handleTextChange('isobligatory', evt);
                                                        }}
                                                    />
                                                </OverlayTrigger>
                                            </Col>
                                            <Col xs={3}>
                                                {!this.state.isSaved && (
                                                    <OverlayTrigger
                                                        key="top"
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip-top">
                                                                {t('Send Email To Employee')}
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <CustomCheckBox
                                                            label={`${t('Send Mail')}`}
                                                            isChecked={this.state.isSaveAndSend}
                                                            onChange={(evt) => {
                                                                this.setState({ isSaveAndSend: evt }, () =>
                                                                    this.checkSelectAll()
                                                                );
                                                            }}
                                                        />
                                                    </OverlayTrigger>
                                                )}
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={4}>
                                        {this.state.isSaved && (
                                            <OverlayTrigger
                                                key="top"
                                                placement="top"
                                                overlay={
                                                    <Tooltip id="tooltip-top">{t('Send Email To Employee')}</Tooltip>
                                                }
                                            >
                                                <Button
                                                    wd
                                                    pullRight
                                                    style={{ marginLeft: '5px' }}
                                                    onClick={() => this.handleButtonClick('send')}
                                                >
                                                    {t('Send Mail')}
                                                </Button>
                                            </OverlayTrigger>
                                        )}
                                        <Button fill wd pullRight onClick={() => this.handleButtonClick('preview')}>
                                            {t('Preview')}
                                        </Button>
                                    </Col>
                                </Row>
                            </>
                        )}

                        {this.state.isPreview ? (
                            <div>
                                {user_type !== 'member' ? (
                                    <iframe title={'document preview'} className='w-100 mt-2' style={{height: '290mm' }} src={this.state.documentPath} />
                                ) : (
                                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.3.122/build/pdf.worker.min.js">
                                        <Viewer
                                            fileUrl={this.state.documentPath}
                                        // plugins={[defaultLayoutPluginInstance]}
                                        />
                                    </Worker>
                                )}
                            </div>
                        ) : (
                            <div
                                className="center-block"
                                style={{
                                    minHeight: '300px',
                                    paddingTop: '150px',
                                }}
                            >
                                {!add || this.state.isPreviewing
                                    ? `${t('Fetching Document Book Please Wait')}`
                                    : `${t('Please Add Documents And Save Document Book To Preview')}`}
                            </div>
                        )}
                    </form>
                </div>
                <div className="table-list__footer">
                    {this.state.isLoad && (user_type === 'member' || user_type === 'pm') && (
                        <div>
                            <div className="custom-form__row">
                                {this.state.template && (
                                    <div className="custom-form__col">
                                        <h4>{t('Signature')} </h4>
                                        {!this.state.isSignatureDone ? (
                                            <Signature
                                                onChange={(signature) => {
                                                    this.setState({
                                                        signature: signature,
                                                    });
                                                }}
                                                required
                                                width="100%"
                                                height={250}
                                                error={
                                                    this.state.template.is_signature_required && !this.state.signature
                                                }
                                            />
                                        ) : (
                                            <img
                                                src={this.state.signature}
                                                alt=""
                                                width="100%"
                                                height={250}
                                                style={{ border: '2px solid #F5F5F5' }}
                                                name="signature"
                                                alr="member_signature"
                                            />
                                        )}
                                    </div>
                                )}
                            </div>
                            <Button
                                fill
                                wd
                                pullRight
                                disabled={!this.state.isReadable}
                                onClick={() => this.handleButtonClick('read')}
                            >
                                {t('Read')}
                            </Button>
                        </div>
                    )}

                    {this.state.isLoad && user_type !== 'member' && (
                        <Button fill wd pullRight onClick={() => this.handleButtonClick('save')}>
                            {t('Save')}
                        </Button>
                    )}
                    <Button wd onClick={() => this.handleButtonClick('cancel')}>
                        {t('Cancel')}
                    </Button>
                </div>
            </div>
        );
    }
}

export default inject(
    'documentStore',
    'authStore',
    'userStore',
    'commonStore'
)(withLocalization(applicationRouter(observer(DocumentTemplateAddEdit))));
