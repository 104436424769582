import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Modal } from 'react-bootstrap';

//components
import GenericForm from '~/components/GenericForm/GenericForm';
import applicationRouter from '~/hoc/applicationRouter';
import withLocalization from '~/hoc/withLocalization';
import BizTypeDocuments from './BizTypeDocuments';

//services
import getSchema from '~/library/schemas/biztype';

//elements
import Button from '../../../components/CustomButton/CustomButton.jsx';
import LoadingSpinner from '~/elements/LoadingSpinner';

//assets
import CloseModalIcon from '../../../assets/img/CloseApproveModalIcon.svg';

const schema = getSchema();

const uiSchema = {
    'ui:field': 'layout',
    'ui:layout': [
        {
            name: { md: 12 },
            data: { md: 12, className: 'custom-layout-class' },
        },
    ],
    data: {
        allow_vacations: {
            'ui:widget': 'TrueFalse',
        },
        allow_absents: { 'ui:widget': 'TrueFalse' },
        allow_accepting: { 'ui:widget': 'TrueFalse' },
        pm_hide_tasks: { 'ui:widget': 'TrueFalse' },
        pm_hide_vacations: { 'ui:widget': 'TrueFalse' },
        pm_hide_absences: { 'ui:widget': 'TrueFalse' },
        pm_no_register: { 'ui:widget': 'TrueFalse' },
        member_allow_see_projects: { 'ui:widget': 'TrueFalse' },
        timelog_hide_kilometers: { 'ui:widget': 'TrueFalse' },
        timelog_hide_other: { 'ui:widget': 'TrueFalse' },
        timelog_hide_extra: { 'ui:widget': 'TrueFalse' },
        show_times_on_project: { 'ui:widget': 'TrueFalse' },
        hide_send_report: { 'ui:widget': 'TrueFalse' },
        presence_view: { 'ui:widget': 'TrueFalse' },
        admin_hide_absences: { 'ui:widget': 'TrueFalse' },
        allow_vacation_not_me: { 'ui:widget': 'TrueFalse' },
        reports_not_for_me: { 'ui:widget': 'TrueFalse' },
        reports_weekly: { 'ui:widget': 'TrueFalse' },
        locked_mode: { 'ui:widget': 'TrueFalse' },
        rest_report: { 'ui:widget': 'TrueFalse' },
        pm_allow_editing_all: { 'ui:widget': 'TrueFalse' },
        login_as_allowed: { 'ui:widget': 'TrueFalse' },
        disallow_absences_vacations_not_self: { 'ui:widget': 'TrueFalse' },
        timelog_hide_names: { 'ui:widget': 'TrueFalse' },
        gps_enabled: { 'ui:widget': 'TrueFalse' },
        timelog_no_edit_for_approved: { 'ui:widget': 'TrueFalse' },
        timelog_no_edit_for_done: { 'ui:widget': 'TrueFalse' },
        pm_no_shifts: { 'ui:widget': 'TrueFalse' },
        enable_deviations: { 'ui:widget': 'TrueFalse' },
        union_group: { 'ui:widget': 'TrueFalse' },
        allow_multiple_branches: { 'ui:widget': 'TrueFalse' },
        allow_system_document: { 'ui:widget': 'TrueFalse' },
    },
};

class BizTypesForm extends Component {
    state = {
        showDocumentModel: false,
        isLoad: false,
        bizId: null,
    };

    getListUrl() {
        return '/admin/biztypes';
    }

    loadData() {
        const { biztypeStore } = this.props;
        let getId = (this.props.router.location.state && this.props.router.location.state.biztype) || null;
        if (!getId) {
            return biztypeStore.returnDefaultNew();
        }
        biztypeStore.load(getId, !getId);
        this.setState({ bizId: getId }, () => {
            this.setState({
                isLoad: true,
            });
        });
    }

    componentWillMount() {
        this.props.biztypeStore.loading = true;
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(previousProps) {
        const { location } = this.props;
        if (location !== previousProps.location) {
            this.loadData();
        }
    }

    onSave(values) {
        const { biztypeStore, t, commonStore } = this.props;
        let getId = (this.props.router.location.state && this.props.router.location.state.biztype) || null;
        if (!values.name || values.name.trim() === '') {
            commonStore.addNotification(t('Please Enter Business Name'), null, 'error');
            return;
        }
        if (getId) {
            return biztypeStore.save(values, !getId).then((result) => {
                if (!result.biztype || !result.biztype.id) {
                    commonStore.addNotification(t(result.message || 'Error'), null, 'error');
                    return false;
                }
                commonStore.addNotification(t('Saved'), null, 'success');
                this.props.router.navigate(this.getListUrl());
                return true;
            });
        }
        return biztypeStore.save(values, !getId).then((result) => {
            if (!result.biztype || !result.biztype.id) {
                commonStore.addNotification(t(result.message || 'Error'), null, 'error');
                return false;
            }
            commonStore.addNotification(t('Saved'), null, 'success');
            this.props.router.navigate(this.getListUrl());
            return true;
        });
    }

    handleDocument = () => {
        this.setState({ showDocumentModel: true });
    };

    handleCloseDocumentModal = () => {
        this.setState({ showDocumentModel: false });
    };

    render() {
        const { biztypeStore, add, t } = this.props;
        const { loading, currentEntity } = biztypeStore;
        if (loading) return <LoadingSpinner />;

        return (
            <div className="primary-page">
                <GenericForm
                    entity={currentEntity.biztype}
                    uiSchema={uiSchema}
                    schema={schema}
                    translationScope="forms.biztype"
                    onSave={(values) => this.onSave(values)}
                    listUrl={this.getListUrl()}
                    isAdding={add}
                    isBizType={true && this.state.isLoad} //bizType documents can only be added after creating the business first//
                    handleDocument={this.handleDocument}
                />
                <Modal className="modal fade" show={this.state.showDocumentModel}>
                    <Modal.Header>
                        <Modal.Title className="w-100 text-center m-0">{t('Document Management')}</Modal.Title>
                        <img src={CloseModalIcon} alt="closeIcon" className="cursor-pointer" onClick={this.handleCloseDocumentModal} />
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.isLoad && <BizTypeDocuments biztypeId={this.state.bizId} />}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleCloseDocumentModal} className="btn-wd">
                            {t('Close')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default inject('biztypeStore', 'commonStore')(applicationRouter(withLocalization(observer(BizTypesForm))));
