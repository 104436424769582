import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/ck-build/ckeditor';

// components
import applicationRouter from '~/hoc/applicationRouter'
import withLocalization from '~/hoc/withLocalization';
import Button from '../../../components/CustomButton/CustomButton.jsx';

//assets
import LeftArrow from './../../../assets/img/left-arrow.svg'

const editorConfiguration = {
  title: '',
  toolbar: ['heading', '|',
    'alignment', '|',
    'bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript', '|', 'PageBreak',
    'link', '|',
    'bulletedList', 'numberedList', 'todoList',
    '-', // break point
    'fontfamily', 'fontColor', 'fontBackgroundColor', '|',
    'code', 'codeBlock', '|',
    'insertTable', '|',
    'outdent', 'indent', '|',
    'uploadImage', 'blockQuote', '|',
    'undo', 'redo'],
};

class ContractsEditor extends Component {

  constructor(props) {
    super(props);
    this.state = {
      editorContent: '',
      theme: 'snow',
      modules: {
        toolbar: [
          [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
          [{ size: [] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{ 'list': 'ordered' }, { 'list': 'bullet' },
          { 'indent': '-1' }, { 'indent': '+1' }],
          ['link', 'image', 'video'],
          ['clean']
        ],
        clipboard: {
          // toggle to add extra line breaks when pasting HTML:
          matchVisual: false,
        }
      },
      formats: [
        'header', 'font', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image', 'video'
      ],

      placeholder: 'Write Something',
    }
    this.handleSave = this.handleSave.bind(this);
    this.getListUrl = this.getListUrl.bind(this);
  }

  loadData() {
    const { contract_id, contractStore } = this.props;
    if (contract_id) {
      if (contractStore.contractEditorDetails && contractStore.contractEditorDetails.body)
        this.setState({ editorContent: contractStore.contractEditorDetails.body });
    }
  }

  getListUrl() {
    this.props.router.navigate("/admin/contract_templates");
  }

  handleTextChange(value) {
    this.setState({ editorContent: value });
  }
  handleSave() {
    const { contractStore, commonStore, t } = this.props;
    const { editorContent } = this.state;
    if (editorContent) {
      let getKeys = editorContent.match(/\[[^\]]*]/g);

      let payload = {
        id: contractStore.contractEditorDetails ? contractStore.contractEditorDetails.id : null,
        editorBody: editorContent,
        editorKeys: getKeys && getKeys.length ? getKeys : [],
        contract_id: contractStore.currentContractDetails.id
      }
      contractStore.saveEditorData(payload).then(response => {
        if (response.status) {
          commonStore.addNotification(t('Saved'), null, 'success');
          this.setState({ editorContent: '' });
          this.getListUrl();
        }
      })
    }
    else {
      commonStore.addNotification(t('Please Add Data'), null, 'error');
    }
  }

  handleCancelClick() {
    this.props.router.navigate("/admin/contract_templates");
  }

  render() {
    const { contract_type_selected, t, contractStore } = this.props;
    return (
      <div className='p-3'>
        <p className='cursor-pointer color-gray'>
          <img onClick={this.props.handleGoBack} src={LeftArrow} alt="left arrow" />
          &nbsp;{t('Go Back')}
        </p>
        <div className="table-list__header_contract">
          <Button fill wd onClick={this.handleSave}>
            {t('Save Now')}
          </Button>
          <Button onClick={this.getListUrl}>
            {t('Cancel')}
          </Button>
        </div>
        <div className='main-table'>
          <div className='row color-gray'>
            <div className='col-md-2'>
              <p>
                {t('Country')}
              </p>
            </div>
            <div className='col-md-2'>
              <p>
                {t('Contract Type')}
              </p>
            </div>
            <div className='col-md-2'>
              <p>
                {t('Module')}
              </p>
            </div>
            <div className='col-md-4'>
              <p>
                {t('Document Name')}
              </p>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-2'>
              <h6 >
                {contractStore.currentContractDetails.country || " "}
              </h6>
            </div>
            <div className='col-md-2'>
              <h6 className='text-overflow' title={contract_type_selected.label}>
                {contract_type_selected ? contract_type_selected.label : " "}
              </h6>
            </div>
            <div className='col-md-2'>
              <h6>
                {contractStore.currentContractDetails.module || ""}
              </h6>
            </div>
            <div className='col-md-4'>
              <h6 className='text-overflow' title={contractStore.currentContractDetails.name}>
                {contractStore.currentContractDetails.name || " "}
              </h6>
            </div>
          </div>
        </div>
        <h6 className='pt-3'>
          {t('Create New Contract')}
        </h6>
        <hr style={{ border: '0.5px solid gray' }} />
        <div className='my-2'>
          <h6 className='d-inline-block'>
            {t('Note')}
          </h6>
          &nbsp;:&nbsp;
          <span>
            {t('Anything between [ ] will be considered as keys.')}
          </span>
        </div>
        <div className='ckContracts mt-2'>
          <CKEditor
            editor={Editor}
            data={this.state.editorContent}
            config={editorConfiguration}
            onReady={editor => {
              this.loadData();
            }}
            onChange={(event, editor) => {
              const data = editor.getData();
              this.handleTextChange(data)
            }}
          />
        </div>
      </div>
    )
  }
}
export default inject('documentStore', 'authStore', 'userStore', 'commonStore', 'contractStore')(withLocalization(applicationRouter(observer(ContractsEditor))));
