import React from "react";
import "./styles.css";

function ContactInfo(props) {
  return (
    <div className={`conttCol d-md-flex align-items-center ${props.customClass}`}>
      <div className="conttColIcon">
        <i><img src={props.src} alt={props.alt} /></i>
      </div>
      <div className="conttColInfo mt-4 mt-md-0 ms-md-4">
        <h4>{props.title}</h4>
        <p className="m-0">{props.description}</p>
      </div>
    </div>   
  );
}
export default ContactInfo;