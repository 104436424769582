import React, { Fragment } from 'react';
import ObjectField from 'react-jsonschema-form/lib/components/fields/ObjectField';
import { retrieveSchema } from 'react-jsonschema-form/lib/utils';
import { Col } from 'react-bootstrap';

import withLocalization from '../../hoc/withLocalization';

@withLocalization
class LayoutFieldInner extends ObjectField {
    render() {
        const { uiSchema, idSchema } = this.props;
        const { definitions, fields, formContext } = this.props.registry;
        const { DescriptionField } = fields;
        const schema = retrieveSchema(this.props.schema, definitions);
        const title = schema.title === undefined ? '' : schema.title;
        const hideFrame = uiSchema['ui:layout:hideframe'];
        if (hideFrame) {
            return this.renderInternal();
        }
        return (
            <fieldset>
                {title && <legend>{title}</legend>}
                {schema.description ? (
                    <DescriptionField
                        id={`${idSchema.$id}__description`}
                        description={schema.description}
                        formContext={formContext}
                    />
                ) : null}
                {this.renderInternal()}
            </fieldset>
        );
    }

    renderInternal() {
        const { uiSchema, errorSchema, idSchema, disabled, readonly, onBlur, formData, t } = this.props;
        const { definitions, fields } = this.props.registry;
        const { SchemaField } = fields;
        const schema = retrieveSchema(this.props.schema, definitions);

        const layout = uiSchema['ui:layout'];
        const isFlex = !!uiSchema['ui:flex'];

        return (
            <Fragment>
                {layout.map((row, index) => {
                    return (
                        <div className={`row ${isFlex ? 'row-flex' : ''} ${row['ui:className'] || ''}`} key={index}>
                            {row['ui:subtitle'] && <div className="form-subtitle">{t(row['ui:subtitle'])}</div>}
                            {Object.keys(row).map((name, index) => {
                                if (name.indexOf('ui:') === 0) return;
                                const { render, doShow, ...rowProps } = row[name];
                                let style = {};
                                if (doShow && !doShow({ formData })) {
                                    style = { display: 'none' };
                                }
                                if (schema.properties[name]) {
                                    return (
                                        <Col {...rowProps} key={index} style={style}>
                                            <SchemaField
                                                name={name}
                                                required={this.isRequired(name)}
                                                schema={schema.properties[name]}
                                                uiSchema={uiSchema[name]}
                                                errorSchema={errorSchema[name]}
                                                idSchema={idSchema[name]}
                                                formData={formData[name]}
                                                onChange={this.onPropertyChange(name)}
                                                onBlur={onBlur}
                                                registry={this.props.registry}
                                                disabled={disabled}
                                                readonly={readonly}
                                            />
                                        </Col>
                                    );
                                }
                                let UIComponent = () => null;

                                if (render) {
                                    UIComponent = render;
                                }

                                return (
                                    <Col {...rowProps} key={index} style={style}>
                                        <UIComponent
                                            name={name}
                                            formData={formData}
                                            errorSchema={errorSchema}
                                            uiSchema={uiSchema}
                                            schema={schema}
                                            registry={this.props.registry}
                                        />
                                    </Col>
                                );
                            })}
                        </div>
                    );
                })}
            </Fragment>
        );
    }
}

export default class LayoutField extends React.Component {
    render() {
        return <LayoutFieldInner {...this.props} />;
    }
}
