import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import Header from "../../../newComponents/Header";
import Footer from "../../../newComponents/Footer";
import MainHeading from "../../../newComponents/MainHeading";
import PageBanner from "../../../newComponents/PageBanner";
import IconBox from "../../../newComponents/IconBox";
import Contact from "../../../newComponents/Contact";

import BannerImg from "../../../assets/images/checklistBnrImg.png";
import AppleIcon from "../../../assets/images/appleIcon.svg";
import GoogleIcon from "../../../assets/images/googleIcon.svg";
import ValuableLogo from "../../../assets/images/valuableLogo.png";
import CheckFeau1 from "../../../assets/images/checkFeau1.png";
import CheckFeau2 from "../../../assets/images/checkFeau2.png";
import CheckFeau3 from "../../../assets/images/checkFeau3.png";
import MobileImg from "../../../assets/images/mobileImg.png";
import ChecklistImg1 from "../../../assets/images/checklistImg1.png";
import ChecklistImg2 from "../../../assets/images/checklistImg2.png";
import PerformanceIcon from "../../../assets/images/performanceIcon.png";
import DocumentIcon from "../../../assets/images/documentIcon.png";
import InstructionIcon from "../../../assets/images/instructionIcon.png";
import ChecklistIcon from "../../../assets/images/checklistIcon.png";
import ChecklistIcon1 from "../../../assets/images/checklistIcon1.png";
import ChecklistIcon2 from "../../../assets/images/checklistIcon2.png";

//i18n
import { withTranslation } from "react-i18next"
import { inject, observer } from "mobx-react";

import config from "../../../library/config";

const Checklist = inject('authStore', 'commonStore')(observer((props) => {
  return (
    <>
      <div className="landingPageWrapp frameworkApp">
        <Header features="current" />
        <article className="pageBannerBlock grdntBg noPatt  py-60">
          <Container>
            <Row className="g-4 align-items-center">
              <Col lg={6}>
                <MainHeading
                  title={<>{props.t("Obligatory")} <span>{props.t("Checklist")}</span></>}
                  description={props.t("Digitize and optimize your company workflow with digital checklists and procedural updates. Do you want complete access to daily activities, monitor the tasks, and improve accountability? Time and Control Obligatory Checklist feature helps you to manage, upload files and save data digitally to make the processes hassle-free.")}
                />
                <div className="appBtns mt-4">
                  <Link onClick={e => config.redirectToAppStore(e, "apple")} className="btnCommon btnBig w-auto me-3 mb-2"><i className="d-inline-block pe-2"><img src={AppleIcon}  alt="Apple" /></i> {props.t("Apple")}</Link>
                  <Link onClick={e => config.redirectToAppStore(e, "google")} className="btnCommon btnTrans btnBig btnBlackTrans w-auto"><i className="d-inline-block pe-2"><img src={GoogleIcon} alt="Apple" /></i> {props.t("Google")}</Link>
                </div>
              </Col>
              <Col lg={6}>
                <PageBanner
                  src={BannerImg}
                  alt="Visitor Register"
                />
              </Col>
            </Row>
          </Container>
          <div className="valuableLogo">
            <img src={ValuableLogo} alt="" />
          </div>
        </article>
        <article className="abtInfoBlock noPatt  py-80">
          <Container>
            <Row className="g-4 align-items-center">
              <Col lg={7} xl={8}>
                <div className="contentInfo">
                  <h5 className="m-0">{props.t("Checklists with Time & Control")}</h5>
                </div>
                <MainHeading
                  title={<>{props.t("Stay Updated with Day-to-Day")} <span>{props.t("Progress with Digital Checklists")}</span></>}
                  description={props.t("Our checklist solution removes the hassles of handling paper and converts your daily task updates to checklists and digital forms. You can create, maintain, and audit checklists with a single interface.")}
                  customClass="medium m-0"
                />
              </Col>
              <Col lg={5} xl={4}>
                <div className="text-left">
                  <div className="d-flex align-items-center gap-3">
                    <div className="iconLeft">
                      <img src={CheckFeau1} alt=""/>
                    </div>
                    <h4 className="m-0">{props.t("Comprehensive Checklists")}</h4>
                  </div>
                  <div className="d-flex align-items-center gap-3 mt-4">
                    <div className="iconLeft">
                      <img src={CheckFeau2} alt=""/>
                    </div>
                    <h4 className="m-0">{props.t("Performance Reviews and Daily Tasks")}</h4>
                  </div>
                  <div className="d-flex align-items-center gap-3 mt-4">
                    <div className="iconLeft">
                      <img src={CheckFeau3} alt=""/>
                    </div>
                    <h4 className="m-0">{props.t("Single-Click Instruction Update")}</h4>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </article>
        <article className="rgstrBlock  py-60">
          <Container>
            <div className="rgstrInrBlock grdntBg">
              <Row className="g-4 g-lg-5 align-items-end">
                <Col lg={7}>
                  <div className="py-60 pt-0">
                    <div className="contentInfo">
                      <h5 className="m-0">{props.t("Central Management with Time & Control")}</h5>
                    </div>
                    <MainHeading
                      title={<>{props.t("A Single-Point Management")} <span>{props.t("Control for Checklists and Updates")}</span></>}
                      customClass="medium noPara"
                    />
                    <div className="list">
                      <ul>
                        <li>{props.t("Create, manage, and edit checklists from a single interface.")}</li>
                        <li>{props.t("Share checklists with everyone in a single click")}</li>
                        <li>{props.t("Create deviations with one click")}</li>
                        <li>{props.t("Sign and authenticate checklists to ensure completion")}</li>
                        <li>{props.t("Save checklists onto a single server")}</li>
                        <li>{props.t("Upload files and documents related to checklists")}</li>
                        <li>{props.t("Verify and mark checklist completion")}</li>
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col lg={5}>
                  <div className="rgstrImg text-center">
                    <img src={MobileImg} alt="" />
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </article>
        <article className="iconFeauBlock grdntBg noPatt  py-60">
          <Container>
            <div className="contentInfo text-center">
              <h5 className="m-0">{props.t("Workflow Optimization")}</h5>
            </div>
            <MainHeading
              title={<>{props.t("Digital Checklists")} <span>{props.t("for Effective Outcomes")}</span></>}
              description={props.t("Maintain Quality and Automate Workflows with Digital Checklists")}
              customClass="medium text-center"
            />
            <Row className="g-4">
              <Col md={6} lg={3}>
                <IconBox
                  src={PerformanceIcon}
                  title={props.t("Monthly Performance")}
                  description={props.t("Access monthly track records stored on the cloud.")}
                  color="blue"
                  customClass="style2"
                />
              </Col>
              <Col md={6} lg={3}>
                <IconBox
                  src={DocumentIcon}
                  title={props.t("Information Access")}
                  description={props.t("Identify the checklist, its purpose, and its components.")}
                  color="orange"
                  customClass="style2"
                />
              </Col>
              <Col md={6} lg={3}>
                <IconBox
                  src={InstructionIcon}
                  title={props.t("Easy Access to Lists")}
                  description={props.t("Single click access point to update and change instructions.")}
                  color="green"
                  customClass="style2"
                />
              </Col>
              <Col md={6} lg={3}>
                <IconBox
                  src={ChecklistIcon}
                  title={props.t("Update Checklists")}
                  description={props.t("Mark and alter checklists according to the task priority.")}
                  color="green"
                  customClass="style2"
                />
              </Col>
            </Row>
          </Container>
        </article>
        <article className="abtInfoBlock noPatt py-60">
          <Container>
            <Row className="g-4 align-items-center">
              <Col lg={6}>
                <div className="text-center">
                  <img src={ChecklistImg1} alt="" />
                </div>
              </Col>
              <Col lg={6}>
                <div className="contentInfo">
                  <h5 className="mb-2">{props.t("Better Response Times")}</h5>
                </div>
                <MainHeading
                  title={<>{props.t("Time & Control Automates")} <span>{props.t("Process Tracking")}</span></>}
                  customClass="medium noPara m-0"
                />
                <div className="contentInfo my-4">
                  <p>{props.t("Check it Now")}</p>
                </div>
                <div className="text-left">
                  <p className="m-0">{props.t("Let us help to enhance the task response time and automate the process tracking of every aspect with our well-organised checklists feature. With this, you will get notifications with every tick in the list and stay updated always.")}</p>
                </div>
              </Col>
            </Row>
          </Container>
        </article>
        <article className="abtInfoBlock noPatt ">
          <div className="hdngBg py-60">
            <Container>
              <div className="contentInfo text-center">
                <h5 className="m-0">{props.t("Information Gathering and Sorting")}</h5>
              </div>
              <MainHeading
                title={<>{props.t("Real-Time Information from")}<br/><span>{props.t("Staff and Field Employees")}</span></>}
                customClass="medium noPara text-center mb-0"
              />
            </Container>
          </div>
          <Container>
            <div className="mtop100 py-60">
              <Row className="g-4 g-xl-5">
                <Col md={6} lg={6}>
                  <div className="whiteBox">
                    <div className="mb-4">
                      <img src={ChecklistIcon1} alt="ChecklistIcon1"/>
                    </div>
                    <MainHeading
                      title={<>{props.t("TimeandControl")} <span>{props.t("Gather Information in Real-Time")}</span></>}
                      customClass="small noPara text-center"
                    />
                    <div className="text-center">
                      <p>{props.t("By shedding the paper trail of checklists and adopting digital ones, you can get updates on every task in real time quickly and easily. With this, you will always stay updated with what’s happening and where.")}</p>
                    </div>
                  </div>
                </Col>
                <Col md={6} lg={6}>
                  <div className="whiteBox">
                    <div className="mb-4">
                      <img src={ChecklistIcon2} alt="ChecklistIcon2"/>
                    </div>
                    <MainHeading
                      title={<>{props.t("TimeandControl")} <span>{props.t("Save on Operational Costs")}</span></>}
                      customClass="small noPara text-center"
                    />
                    <div className="text-center">
                      <p>{props.t("Digital checklists save the time, cost, and effort that will otherwise be spent on managing paper checklists. With Time and Contro, you can replace daily manual checklists with digital ones.")}</p>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </article>
        <article className="abtInfoBlock noPatt  py-60">
          <Container>
            <Row className="g-4 g-xl-5 align-items-center">
              <Col lg={6}>
                <div className="text-center">
                  <img src={ChecklistImg2} alt="" />
                </div>
              </Col>
              <Col lg={6}>
                <div className="contentInfo">
                  <h5 className="mb-2">{props.t("Secure Checklists")}</h5>
                </div>
                <MainHeading
                  title={<>{props.t("Access Checklists with")}<br/><span>{props.t("In-Built Security Measures")}</span></>}
                  customClass="medium noPara"
                />
                <div className="list">
                  <ul>
                    <li>{props.t("Insert QR Codes in checklists")}</li>
                    <li>{props.t("Send checklists to selected people")}</li>
                    <li>{props.t("Maintain privacy and security")}</li>
                    <li>{props.t("Centralize the checklist storage and processing")}</li>
                    <li>{props.t("Go-eco friendly with digital checklists")}</li>
                    <li>{props.t("Make decisions faster and quickly")}</li>
                    <li>{props.t("Protect checklists from unauthorized access")}</li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </article>
        <Contact/>
        <Footer/>
      </div>
    </>
  );
}))
export default withTranslation()(Checklist);