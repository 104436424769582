import { action, observable, makeObservable } from 'mobx';

import agent from '../agent';

class PresenceStore {
    currentList = [];

    requestParams = null;

    filters = {
        date: new Date(),
    };

    constructor() {
        makeObservable(this, {
            currentList: observable,
            requestParams: observable,
            filters: observable,
            setFilter: action,
            loadList: action
        });
    }

    setFilter(name, value) {
        const filters = Object.assign({}, this.filters);
        filters[name] = value;
        this.filters = filters;
    }

    loadList(params) {
        return agent.Timelogs.presenceList(params)
            .then(
                action(list => {
                    this.requestParams = params;
                    this.currentList = list;
                    return list;
                })
            )
            .catch(
                action(err => {
                    throw err;
                })
            );
    }
}

const _PresenceStore = new PresenceStore();
export default _PresenceStore;
