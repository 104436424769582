import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { v4 as uuid } from 'uuid';
import moment from 'moment/min/moment-with-locales';

//styles
import './css/style.css';

const getDayExpense = (expense, budget) => {
    const _expense = expense ? isNaN(parseFloat(expense)) ? 0 : parseFloat(expense) : 0;
    const _budget = budget ? isNaN(parseFloat(budget)) ? 0 : parseFloat(budget) : 0;
    if (!_budget) return 0;
    return ((_expense / _budget) * 100).toFixed(2);
}

const DayHeader = ({ day, style, budgetDetails, isAdminOrPm, t, handleHideShow, showBudgetModal, headerData, currentView, isSchedulePublishUnpublishFeatueEnabled, isScheduleShiftsCalculationsEnabled }) => {
    const _showOnlyDate = isAdminOrPm && isSchedulePublishUnpublishFeatueEnabled;
    const _formatedDate = day.format('DD');
    const _formatedDay = day.format('ddd');
    const _formatedDay_ = moment(day).format('YYYY-MM-DD');
    const _showBudgetBlock = budgetDetails && isAdminOrPm && !!currentView && isScheduleShiftsCalculationsEnabled;
    const _isDayPublished = headerData ? headerData.show_shifts_to_member ? true : false : true;
    const _isDayBudgetAdded = headerData?.budget_for_day > 0 ? true : false;
    const _budgetPercent = getDayExpense(headerData?.dayExpense, headerData?.budget_for_day);
    return (
        <th className="header3-text" style={style}>
            <div className='header-card'>
                <Row>
                    <Col xs={_showOnlyDate ? 8 : 12}
                        className="__dates_container">
                        <span className='__day' >
                            {_formatedDay}
                        </span>
                        <span className='__date'>
                            {_formatedDate}
                        </span>
                    </Col>
                    {_showOnlyDate &&
                        <Col xs={4}
                            className='__day_publish_container'>
                            <div title={_isDayPublished ? t("Click to Unpublish") : t("Click to Publish")}
                                onClick={() => handleHideShow(_formatedDay_, headerData?.show_shifts_to_member?.toString() ? !headerData.show_shifts_to_member : false, true)}>
                                <i className={_isDayPublished ? 'fa fa-check-circle _day_published' : 'fa fa-ban _day_unpublished'} />
                            </div>
                        </Col>
                    }
                </Row>
                {_showBudgetBlock &&
                    <div className='__date_totals_container'>
                        <div className={`d-flex ${!_isDayBudgetAdded ? 'justify-content-center' : 'justify-content-end'}`}>
                            {_isDayBudgetAdded &&
                                <span className='_day_budget_headers'>
                                    {t("Revenues")}
                                </span>
                            }
                            <span
                                className="__day_buget_details"
                                onClick={() => showBudgetModal(_formatedDay_)}>
                                {_isDayBudgetAdded ? headerData.budget_for_day : t("Enter Revenues")
                                }
                            </span>
                        </div>
                        {_isDayBudgetAdded &&
                            <div className='d-flex justify-content-end'>
                                <span className='_day_budget_headers' title={t('Salaries')}>
                                    {t('Salaries')}
                                </span>

                                <span className='__day_cost me-2'
                                    title={_isDayBudgetAdded ? headerData?.dayExpense > 0 ? headerData?.dayExpense : 0 : 0}>
                                    {_isDayBudgetAdded ? headerData?.dayExpense > 0 ? parseFloat(headerData.dayExpense).toFixed(2) : 0 : 0}
                                </span>
                                <span className={`__day_buget_percent ${_budgetPercent > 100 ? 'text-danger' : ''}`}
                                    title={_isDayBudgetAdded ? _budgetPercent : 0}
                                >
                                    {_isDayBudgetAdded ? _budgetPercent : 0}&nbsp;%
                                </span>
                            </div>
                        }
                    </div>
                }
            </div>
        </th>
    );
}

class HeaderView extends Component {
    constructor(props) {
        super(props);
    }
    static propTypes = {
        schedulerData: PropTypes.object.isRequired,
    };
    render() {
        const { schedulerData, userStore, currentView, scheduleStore, commonStore } = this.props;
        const { headers, config, localeMoment } = schedulerData;
        const cellWidth = schedulerData.getContentCellWidth();
        const isAdminOrPm = userStore.currentUser.user_type === 'admin' || userStore.currentUser.user_type === 'pm';
        const isSchedulePublishUnpublishFeatueEnabled = commonStore?.config?.client?.data?.basicRules.enableSchedulePublishUnpublishFeatue;
        const isScheduleShiftsCalculationsEnabled = commonStore?.config?.client?.data?.basicRules.enableScheduleShiftsCalculations;
        const headerHeight = schedulerData.getTableHeaderHeight(currentView && isScheduleShiftsCalculationsEnabled);
        let weekDayHeader = headers.map((item, index) => {
            const datetime = localeMoment(item.time);
            const _Date = moment(datetime.toDate());
            const isToday = moment().isSame(_Date, 'day');
            let style = {
                width: cellWidth,
                color: isToday ? config.currentDayColor : config.weekDayColor
            }
            if (item.nonWorkingTime) {
                style.color = config.nonWorkingTimeHeadColor;
                style.backgroundColor = config.nonWorkingTimeHeadBgColor;
            }
            if (index === headers.length - 1) {
                delete style.width;
            }
            style.backgroundColor = config.headerBackGroundColor;
            return <DayHeader
                key={uuid()}
                currentView={currentView} day={_Date} style={style}
                budgetDetails={{ cost: 20000, budgetPercent: 20 }}
                isAdminOrPm={isAdminOrPm}
                t={this.props.t}
                handleHideShow={this.props.handleHideShow}
                showBudgetModal={this.props.showBudgetModal}
                headerData={scheduleStore.currentWeekHeader.find(e => moment(e.for_date).isSame(_Date, 'date'))}
                isScheduleShiftsCalculationsEnabled={isScheduleShiftsCalculationsEnabled}
                isSchedulePublishUnpublishFeatueEnabled={isSchedulePublishUnpublishFeatueEnabled}
            />

        });
        return (
            <thead>
                <tr style={{ height: headerHeight }}>{weekDayHeader}</tr>
            </thead>
        );
    }
}
export default HeaderView;