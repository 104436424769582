import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import CustomRadio from '../../CustomRadio/CustomRadio';
import withLocalization from '../../../hoc/withLocalization';

const LeaveTypeMobx = inject('projectStore', 'userStore','commonStore')(withLocalization(observer(class LeaveTypeMobx extends Component {
    state = {
        leave_types :[{label:'Paid',_value:'paid'},{label:'Unpaid',_value:'unpaid'}]
    }
    render() {
        const { value, onChange, t,isDisabled } = this.props;                
        return (
            <div className="task-statuses">
                {this.state.leave_types.map(({ label, _value }) => (
                    <CustomRadio                        
                        name={label}
                        key={label}
                        onChange={() => onChange(_value)}
                        checked={value === _value}
                        option={label}
                        label={t(label)}
                        disabled={isDisabled?true:false}
                    />
                ))}
            </div>
        );
    }
})));

export const LeaveTypeWidgetOnEdit = observer(class LeaveTypeWidgetOnEdit extends Component {
    render() {
        return <LeaveTypeMobx {...this.props} isDisabled />;
    }
})

export const LeaveTypeWidget = observer(class LeaveTypeWidget extends Component {
    render() {
        return <LeaveTypeMobx {...this.props} />;
    }
})

