import React, { Component } from 'react';
import SignaturePad from 'react-signature-pad';

//assets
import SignatureIcon from '../../assets/img/SignatureIcon.svg';
import withLocalization from '../../hoc/withLocalization';

@withLocalization
class Signature extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showRefresh: false,
        };
    }

    componentDidMount() {
        const { initialSignature } = this.props;
        if (initialSignature) {
            this.signature.fromDataURL(initialSignature);
        }
    }

    componentDidUpdate(prevProps) {
        if (!!prevProps.initialSignature && !this.props.initialSignature) {
            this.handleClear();
        }
    }

    handleChange = () => {
        const { showRefresh } = this.state;
        const { onChange } = this.props;
        if (!showRefresh) {
            this.setState({
                showRefresh: true,
            });
        }
        onChange(this.signature.toDataURL());
    };

    handleClear = () => {
        const event = new CustomEvent('resize');
        window.dispatchEvent(event);

        this.setState({
            showRefresh: false,
        });
    };

    handleBegin = e => {
        const signature = this.signatureWrap.getElementsByClassName('m-signature-pad')[0];        
        // const cv = e.srcElement;//use target not srcElement creates issue in mobile devices//
        const cv = e.target;
        const isCorrectCanvas = cv.clientWidth - 10 < signature.offsetWidth && cv.clientWidth + 10 > signature.offsetWidth;
        if (cv.width === 0 || !isCorrectCanvas) {
            this.handleClear();
        }
    };

    render() {
        const {
            width, height, error, initialSignature, t, title
        } = this.props;
        const { showRefresh } = this.state;
        return (
            <div>
                {initialSignature && (
                    <div>
                        <img src={initialSignature} width={300} />
                    </div>
                )}

                <div className={`signature ${error ? 'signature_error' : ''}`}>
                    <span className="signature__label">{title?t(title):t('Signature')}</span>
                    <div className="signature__bg">
                        <img src={SignatureIcon} width={36} alt="Signature" />
                    </div>
                    <div
                        className="signature__panel form-control"
                        style={{ width: '100%', maxWidth: width || 600, height: height || 300 }}
                        ref={ref => {
                            this.signatureWrap = ref;
                        }}
                    >
                        <SignaturePad
                            ref={signature => {
                                this.signature = signature;
                            }}
                            onEnd={this.handleChange}
                            onBegin={this.handleBegin}
                        />
                    </div>
                    {showRefresh && (
                        <button className="signature__cancel" onClick={this.handleClear}>
                            <i className="fa fa-refresh" />
                        </button>
                    )}
                </div>
            </div>
        );
    }
}
export default Signature;
