import React, { Component } from 'react';
import deleteIcon from '../../../../assets/img/delete-icon.svg';
import { observer, inject } from 'mobx-react';
import withLocalization from '../../../../hoc/withLocalization';
import config from '~/library/config';

import { LazyLoadImage } from 'react-lazy-load-image-component'
import rightArrow from '../../../../assets/img/arrow-pointing-to-right.svg';
import PropertyBox from './PropertyBox';
import { Divider } from 'antd';




export class PropertyPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isComponentLoaded: false,
        }
    }
    componentDidMount() {


    }
    deleteDocPage = (evt, id) => {
        const { signatureStore,propertyStore } = this.props;
        propertyStore.deleteWidgetByPageId(id);
        propertyStore.saveWidget({ caseId: signatureStore.caseId, widgets: propertyStore.widgets })
        signatureStore.deleteDocPage(id).then((res) => {
            signatureStore.fetchAll(signatureStore.caseId)
        });
    }
    render() {
        const { signatureStore, propertyStore,t } = this.props;
        const activeWidget = propertyStore.activeWidget;

        return (

            <div className="property-panel">
                {activeWidget ?
                    <PropertyBox />
                    :

                    signatureStore.documentPages.map((page, index) => {
                        return (<div className="pageContainer" key={index}>

                            <div className="pageImg">
                                <LazyLoadImage className="propertyImg" draggable="false" src={`${config.UPLOADS_ROOT}/${page.page_path}`} />
                            </div>

                            <div className="pageInfo">
                                <div>{t('page:')} {index + 1}</div>
                                <div>
                                    <img src={deleteIcon} alt="delete icon" onClick={(evt) => { this.deleteDocPage(evt, page.id) }}></img>
                                </div>
                            </div>
                        </div>);
                    })
                }

            </div>
        )
    }
}

export default inject('signatureStore', 'propertyStore')(withLocalization(observer(PropertyPanel)));