import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import queryString from 'query-string';


// Components
import applicationRouter from '~/hoc/applicationRouter';
import withLocalization from '~/hoc/withLocalization';
import withRemoveDialog from '~/hoc/withRemoveDialog';
import GenericList from '../../../components/GenericList/GenericList';
import GenericFilter from '../../../components/GenericList/GenericFilter';

//elements
import Button from '../../../components/CustomButton/CustomButton.jsx';

//assets
import editIcon from '../../../assets/img/editing.svg';
import deleteIcon from '../../../assets/img/deleting.svg';
class ExtraDocList extends Component {
    componentDidMount() {
        const query = queryString.parse(this.props.router.location.search);
        Object.keys(query).forEach(key => {
            if (key === 'name') {
                this.props.extradocStore.setFilter(key, query[key]);
            }
        });
        this.props.extradocStore.onFilter();
    }


    renderTableActionButtons = id => {
        const {
            userStore: { currentUser },
        } = this.props;

        const userType = currentUser.user_type;
        return (
            <div className="actions-center">
                <Button icon_sm fill onClick={() => this.onDetails(id)}>
                    <img src={editIcon} alt={"edit button"} />
                </Button>
                {userType === 'admin' && (
                    <Button icon_sm_delete fill onClick={() => this.onDelete(id)}>
                        <img src={deleteIcon} alt="delete button" />
                    </Button>
                )}
            </div>
        );
    };

    onAdd() {
        const { mode } = this.props;
        this.props.router.navigate(`/admin/${mode}/add`);
    }

    onDetails(id) {
        const { mode } = this.props;
        this.props.router.navigate(`/admin/${mode}/${id}`);
    }

    onDelete = id => {
        const { mode } = this.props;
        return this.props.handleDelete(id, id => {
            return this.props.extradocStore.remove(id).then(res => {
                // this.props.extradocStore.resetLastListLoadTime();
                this.props.router.navigate(`/admin/${mode === 'internal' ? 'internal' : 'contracts'}`);
                return res;
            });
        });
    };


    render() {
        const {
            userStore: { currentUser },
        } = this.props;
        const {
            t, mode, isUnion
        } = this.props;
        const { filters, appliedFilters, loading } = this.props.extradocStore;
        const userType = currentUser.user_type;
        const allowAdd = userType !== 'member' || isUnion;
        return (
            <div>
                <div className="table-list__header">
                    {allowAdd && (
                        <Button fill wd onClick={() => this.onAdd()}>
                            <i className="fa fa-plus-circle fa-xl margin-right-10" />
                            {t('Add new')}
                        </Button>
                    )}
                </div>
                <GenericList
                    columns={[
                        {
                            Header: 'ID',
                            id: 'id',
                            accessor: 'id',
                            isSortable: true,
                            maxWidth: 40
                        },
                        {
                            Header: 'Name',
                            id: 'name',
                            accessor: ({ name, id, doc_type }) => (
                                <span title={name}>
                                    <NavLink
                                        to={`/admin/${doc_type === 'contracts' ? 'contracts' : 'internal'}/${id}`}
                                        className="list-main-column"
                                    >
                                        {name}
                                    </NavLink>
                                </span>
                            ),
                            isSortable: true,
                            minWidth: 600,
                            padddingLeft5: true
                        },
                        {
                            Header: 'Actions',
                            id: 'actions',
                            accessor: ({ id }) => this.renderTableActionButtons(id),
                            maxWidth: 80
                        },
                    ]}
                    filters={appliedFilters}
                    forceReload={this.props.extradocStore.deleteSuccess}
                    lastListLoadTime={this.props.extradocStore.lastListLoadTime}
                    header={(
                        <GenericFilter
                            filters={filters}
                            setFilter={(name, value) => this.props.extradocStore.setFilter(name, value)}
                            onFilter={() => this.props.extradocStore.onFilter()}
                            loading={loading}
                        />
                    )}
                    requestData={params => this.props.extradocStore.loadList(params, mode)}
                />

            </div>
        );
    }
}
export default inject('clientStore', 'commonStore', 'extradocStore', 'userStore')(applicationRouter(withLocalization(withRemoveDialog(observer(ExtraDocList)))));
