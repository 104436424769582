import { confirmAlert } from 'react-confirm-alert';
import React from 'react';
import applicationRouter from '~/hoc/applicationRouter'

import 'react-confirm-alert/src/react-confirm-alert.css';
import withLocalization from './withLocalization';

const withRemoveDialog = Component => {
    class remover extends React.Component {
        handleDelete = (id, onDelete, redirectUrl, onCancel) => {
            confirmAlert({
                title: this.props.t('Confirm to delete'),
                message: this.props.t('Are you sure to delete an item?'),
                buttons: [
                    {
                        label: this.props.t('Yes'),
                        onClick: () => {
                            onDelete(id).then(() => {
                                if (redirectUrl) this.props.history.push(redirectUrl);
                            });
                        },
                    },
                    {
                        label: this.props.t('No'),
                        onClick: () => {
                            if (onCancel) onCancel();
                        },
                    },
                ],
            });
        };

        render() {
            return <Component {...this.props} handleDelete={this.handleDelete} />;
        }
    }

    return withLocalization(applicationRouter(remover));
};

export default withRemoveDialog;
