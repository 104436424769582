import React from "react";
import { Link} from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

import Header from "../../../newComponents/Header";
import Footer from "../../../newComponents/Footer";
import MainHeading from "../../../newComponents/MainHeading";
import PageBanner from "../../../newComponents/PageBanner";
import IconBox from "../../../newComponents/IconBox";
import Contact from "../../../newComponents/Contact";

import BannerImg from "../../../assets/images/vacationAndAbsenceImg.png";
import AppleIcon from "../../../assets/images/appleIcon.svg";
import GoogleIcon from "../../../assets/images/googleIcon.svg";
import ValuableLogo from "../../../assets/images/valuableLogo.png";
import HolidayIcon from "../../../assets/images/holidayIcon.png";
import RulesIcon from "../../../assets/images/rulesIcon.png";
import FunctionsIcon from "../../../assets/images/functionsIcon.png";
import VacationDashImg from "../../../assets/images/vacationDashImg.png";
import FollowLawImg from "../../../assets/images/followImg.png";

//i18n
import { withTranslation } from "react-i18next"
import { inject, observer } from "mobx-react";

import config from "../../../library/config";


const VacationAndAbsence = inject('authStore', 'commonStore')(observer((props) => {
  return (
    <>
      <div className="landingPageWrapp frameworkApp">
        <Header features="current" />
        <article className="pageBannerBlock grdntBg noPatt  py-60">
          <Container>
            <Row className="g-4 align-items-center">
              <Col lg={6}>
                <MainHeading
                  title={<>{`${props.t("Vacation")} &`} <span>{props.t("Absence")}</span></>}
                  description={props.t("Our reliable Vacation and Absence management system has made it easy to track your employee’s leaving entitlements, an overview of their remaining holidays, sick-leaves, and comply with the complex leave regulations. Likewise, It helps to manage vacation requests, alter holidays plans, and execute dynamic synchronization with a simple request proficiently in any association.")}
                />
                <div className="appBtns mt-4">
                  <Link onClick={e => config.redirectToAppStore(e, "apple")} className="btnCommon btnBig w-auto me-3 mb-2"><i className="d-inline-block pe-2"><img src={AppleIcon}  alt="Apple" /></i> {props.t("Apple")}</Link>
                  <Link onClick={e => config.redirectToAppStore(e, "google")} className="btnCommon btnTrans btnBig btnBlackTrans w-auto"><i className="d-inline-block pe-2"><img src={GoogleIcon} alt="Apple" /></i> {props.t("Google")}</Link>
                </div>
              </Col>
              <Col lg="6">
                <PageBanner
                  src={BannerImg}
                  alt="Visitor Register"
                />
              </Col>
            </Row>
          </Container>
          <div className="valuableLogo">
            <img src={ValuableLogo} alt="" />
          </div>
        </article>
        <article className="iconFeauBlock  py-60">
          <Container>
            <div className="contentInfo text-center">
              <h5 className="m-0">{props.t("Tracking sick-leaves and absences")}</h5>
            </div>
            <MainHeading
              title={<>{props.t("Augment Work-Life Balance")}<br/><span>{props.t("with Prompt Leave Management")}</span></>}
              customClass="medium noPara text-center"
            />
            <Row className="g-4">
              <Col md={6} lg={4}>
                <IconBox
                  src={HolidayIcon}
                  title={props.t("Perfect Extra Holidays")}
                  description={props.t("With Time & Control, your employees will always have complete information about when they can take holidays and go out of their dream destination for a vacation.")}
                  color="blue"
                  customClass="style2"
                />
              </Col>
              <Col md={6} lg={4}>
                <IconBox
                  src={RulesIcon}
                  title={props.t("Predefined Absence Rules")}
                  description={props.t("Feed into the system the rules and regulations to automate employee attendance giving you more space to work on other business aspects and make calculations easier at the end of the month.")}
                  color="orange"
                  customClass="style2"
                />
              </Col>
              <Col md={6} lg={4}>
                <IconBox
                  src={FunctionsIcon}
                  title={props.t("Approval and Rejection Functions")}
                  description={props.t("Time & Control let you feed in functions to evaluate the leave application and decide based on fixed parameters.")}
                  color="green"
                  customClass="style2"
                />
              </Col>
            </Row>
          </Container>
        </article>
        <article className="abtInfoBlock noPatt  py-60">
          <div className="leftImgFull">
            <img src={VacationDashImg} alt="" />
          </div>
          <Container>
            <Row className="g-4 g-xl-5 align-items-center">
              <Col>
                <div className="my-5 py-5 ps-4">
                  <div className="contentInfo">
                    <h5 className="mb-2">{props.t("Plan Ahead with Accurate Information")}</h5>
                  </div>
                  <MainHeading
                    title={<>{props.t("Time and Control Provides")}<br/><span>{props.t("Complete Visibility in One Place")}</span></>}
                    customClass="medium noPara"
                  />
                  <div className="list">
                    <ul>
                      <li>{props.t("Check approved leaves and the balance")}</li>
                      <li>{props.t("Customize vacation and absence management")}</li>
                      <li>{props.t("Leave planning on any smart device")}</li>
                      <li>{props.t("Simplification of leave policy management")}</li>
                    </ul>
                  </div>
                  <div className="appBtns mt-4 pt-2">
                    <Link to="/" className="btnCommon btnBig w-auto me-3 mb-2"><i className="d-inline-block pe-2"><img src={AppleIcon}  alt="Apple" /></i> {props.t("Apple")}</Link>
                    <Link to="/" className="btnCommon btnTrans btnBig btnBlackTrans w-auto"><i className="d-inline-block pe-2"><img src={GoogleIcon} alt="Apple" /></i> {props.t("Google")}</Link>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </article>
        <article className="abtInfoBlock grdntBg leftPatt py-60">
          <div className="rightImgFull">
            <img src={FollowLawImg} alt="" />
          </div>
          <Container>
            <Row className="g-4 g-xl-5 align-items-center">
              <Col>
                <div className="my-5 py-5 ps-4">
                  <MainHeading
                    title={<>{props.t("Ensure Complete")} <span>{props.t("Compliance")}</span></>}
                    customClass="medium noPara"
                  />
                  <div className="text-left">
                    <p>{props.t("With Time & Control, you will get the additional advantage of mitigating compliance risks as it helps you implement leave, vacation, and remuneration policies efficiently.")} </p>
                    <p>{props.t("Stay connected to the leave policy server corresponding to the leave procedure with company guidelines while incorporating the benefits for the utilization of resources.")}</p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </article>
        {/* <article className="rgstrBlock wrapper py-60">
          <Container>
            <div className="rgstrInrBlock grdntBg">
              <Row className="g-4 g-lg-5 align-items-center">
                <Col lg={5}>
                  <div className="rgstrImg">
                    <img src={VacationFeatureImg} alt="" />
                  </div>
                </Col>
                <Col lg={7}>
                  <MainHeading
                    title={props.t("Features Documents")}
                    customClass="medium noPara m-0"
                  />
                  <div className="contentInfo">
                    <p className="my-3">{props.t("Timeandcontrol Is Always Available With Everything,")}</p>
                    <h5 className="m-0">{props.t("Timeandcontrol Is Always Available With Everything,")}</h5>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </article> */}
        <Contact/>
        <Footer/>
      </div>
    </>
  );
}))
export default withTranslation()(VacationAndAbsence);