import React from 'react';
import { MultiSelect } from "react-multi-select-component";

//stles
import './customSelectStyles.css';

const getSelectedObjects = (value, options) => {
    let selected = [];
    if (!value) return selected;
    const _value = typeof value === "string" ?
        value.trim().length ?
            value.split(',').map(ele => !isNaN(ele) ? Number(ele) : ele) : []
        : value.map(ele => !isNaN(ele) ? Number(ele) : ele);

    if (options.length && _value.length) {
        selected = options.filter(ele => !isNaN(ele.value) ? _value.includes(Number(ele.value)) : _value.includes(ele.value))
    }
    return selected;
}

const getSelectedIds = (selected) => {
    let selectedIds = []
    if (selected.length) {
        selectedIds = selected.map(ele => { return isNaN(ele.value) ? Number(ele.value) : ele.value })
    }
    return selectedIds;
}


const CustomMultiSelect = ({ options, value, onChange, className, returnObject = false, disabled, ...rest }) => {
    const _options = options.length ? options : [];
    const _value = getSelectedObjects(value, options);
    const customClasses = className ? className : "";
    return <MultiSelect
        options={_options}
        value={_value}
        onChange={(selected) => onChange(returnObject ? selected : getSelectedIds(selected))}
        className={`custom-multi-select ${customClasses} ${disabled ? "__disabled" : ""}`}
        disabled={disabled}
        {...rest}
    />;
};

export default CustomMultiSelect;
