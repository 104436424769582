import React, { Component }from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./styles.css";
import MainHeading from "../MainHeading";
import ContactInfo from "../ContactInfo";
import ContactForm from "../ContactForm";

import EuropeanIcon from "../../assets/images/europeanIcon.svg";
import USIcon from "../../assets/images/usIcon.svg";
import MailIcon from "../../assets/images/mailIcon.png";
import CallIcon from "../../assets/images/callIcon.png";

import { inject, observer } from "mobx-react";
import withLocalization from "../../hoc/withLocalization";
import applicationRouter from '~/hoc/applicationRouter'

class Contact extends Component {
  constructor(props) {
    super(props)
    this.state = {
      contactForm: {
          company: '',
          fullName: '',
          email: '',
          phone: '',
          comment: '',
          accept: false,
      },
  };
  }
  contactSubmit = (e) => {
    e.preventDefault();
    this.props.commonStore.sendContact(this.state.contactForm).then(() => {
        this.props.commonStore.addNotification(
            this.props.t('Your message has been sent, thank you.'),
            null,
            'success'
        );
        this.setState({
            contactForm: {
                company: '',
                fullName: '',
                email: '',
                phone: '',
                comment: '',
                accept: false,
            },
        });
        alert(this.props.t('Your message has been sent, thank you.'));
    });
};

handleFormChange = (name, value) => {
  const contactForm = Object.assign({}, this.state.contactForm);
  contactForm[name] = value;
  this.setState({
      contactForm,
  });
};
render() {
  return (
    <>
      <article className="contactBlock py-60">
        <Container>
          <Row className="g-4 g-lg-5 align-items-center">
            <Col lg={5} xl>
              <div className="hdngTag mb-2">
                <span>{this.props.t("Contact Us")}</span>
              </div>
              <MainHeading
                title={this.props.t("Ready to take your business to the next level?")}
                description={this.props.t("Join Time & Control today to simplify time and employee management Our Team will provide a seamless onboarding experience and excellent customer support.")}
                customClass="small"
              />
              <Row className="g-4 g-lg-5 mb-5 mb-lg-0">
                <Col sm={12}>
                  <ContactInfo
                    src={EuropeanIcon}
                    alt="EU"
                    title="EU"
                    description="Solgaardskog 1 1599 Moss, Norway"
                  />
                </Col>
                <Col sm={12}>
                  <ContactInfo
                    src={USIcon}
                    alt="USA"
                    title="USA"
                    description="70 East Old Country Road, Hicksville, NY 11801"
                  />
                </Col>
                <Col sm={12}>
                  <ContactInfo
                    src={MailIcon}
                    alt="Mail"
                    description={<><a href="mailto:support@tidogkontroll.no">support@tidogkontroll.no</a></>}
                    customClass="noTitle"
                  />
                </Col>
                <Col sm={12}>
                  <ContactInfo
                    src={MailIcon}
                    alt="Mail"
                    description={<><a href="mailto:info@timeandcontrol.com">info@timeandcontrol.com</a></>}
                    customClass="noTitle"
                  />
                </Col>
                <Col sm={12}>
                  <ContactInfo
                    src={CallIcon}
                    alt="Call"
                    description={<><a href="tel:0018552801212">001-855-280-1212</a></>}
                    customClass="noTitle"
                  />
                </Col>
              </Row>
            </Col>
            <Col lg={7} xl>
              <div className="homeCntctForm">
                <ContactForm onSubmit={this.contactSubmit} handleFormChange={this.handleFormChange} contactForm={this.state.contactForm}/>
              </div>
            </Col>
          </Row>
        </Container>
      </article>
    </>
  );
}
}
// export default withTranslation()(Contact)
export default inject('userStore','commonStore', 'monitorStore')(applicationRouter(withLocalization(observer(Contact))));