import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Modal } from 'react-bootstrap';

//components
import withLocalization from '../../../hoc/withLocalization';
import Button from './.././../../components/CustomButton/CustomButton.jsx';

//assets
import pdfIcon from '../../../assets/img/pdf-icon.svg';
import deleteIcon from '../../../assets/img/delete-icon.svg';
import editIcon from '../../../assets/img/edit-icon.svg';
import close from '../../../assets/img/close_icon.svg';


export class DocList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isEditMode: null,
      fileName: '',
      showDeleteModal: false,
      activeDocId: null,
    }
    this.showModal = this.showModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }


  openEditMode = (evt, fileName, id) => {
    this.setState({ isEditMode: id, fileName: fileName })
  }

  onFileNameChange = (evt) => {
    const docName = evt.target.value;
    this.setState({ fileName: docName })
  }

  handleSave(Id) {
    const { signatureStore, commonStore, t } = this.props
    if (this.state.fileName.trim()) {
      signatureStore.updateDocName({ id: signatureStore.caseId, unique_id: Id, og_name: this.state.fileName }).then((res) => {
        signatureStore.fetchAll(signatureStore.caseId);
        this.setState({ isEditMode: null });
      });
    } else {
      commonStore.addNotification(`${t("Document name")} ${t("is a required property")}`, null, 'error', 'tr', 3);
    }
  }

  onNameSave = (evt, Id) => {
    const { signatureStore, commonStore, t } = this.props
    if (evt.key === 'Enter' || evt.keyCode === 13) {
      if (this.state.fileName.trim()) {
        signatureStore.updateDocName({ id: signatureStore.caseId, unique_id: Id, og_name: this.state.fileName }).then((res) => {
          signatureStore.fetchAll(signatureStore.caseId);
          this.setState({ isEditMode: null });
        });
      } else {
        commonStore.addNotification(`${t("Document name")} ${t("is a required property")}`, null, 'error', 'tr', 3);
      }
    }
  }

  removeDoc = () => {
    const { activeDocId } = this.state;
    const {  signatureStore } = this.props;
    signatureStore.removeDoc(activeDocId).then((res) => {
        signatureStore.fetchAll(signatureStore.caseId);
        this.closeModal();
    })
  }

  showModal(evt,docId) {
    this.setState({ showDeleteModal: true,
      activeDocId:docId
    })
  }

  closeModal(){
    this.setState({ showDeleteModal: false,activeDocId:null})
  }

  render() {
    const { signatureStore,t } = this.props;
    const { showDeleteModal,activeDocId } = this.state;
    if (!(signatureStore.documentList && signatureStore.documentList.length)) {
      return <></>
    }
    return (
      <>
        <Modal show={showDeleteModal}  onHide={() => this.setState({ showDeleteModal: false })}>
          <Modal.Header>
            <Modal.Title className='modal_heading'>{this.props.t('Delete')}</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ height: '220px' }}>
            <div className='body_content'>
              <div className='delete_icon'>
                <img src={close} />
              </div>
              <div className='delete_text'>
                {t('Are you sure want to delete this document?')}
              </div>
              <div>
                <Button
                  fill
                  wd
                  onClick={this.removeDoc}
                  style={{marginRight:'20px'}}
                >
                  {t('Yes')}
                </Button>
                <Button
                  wd
                  onClick={this.closeModal}
                >
                  {t('No')}
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <div className="docTabHead">
          <div>{t('DOCUMENT NAME')}</div>
        </div>
        <div className="docList">
          {
            signatureStore.documentList.map(doc => {
              return (
                <div className={this.state.isEditMode === doc.unique_id?"docRowActive": "docRow"} key={doc.unique_id}>
                  <div className="typeIcon alingCen">
                    <img src={pdfIcon} alt="type icon" />
                  </div>
                  <div className="alingCen docEdit">
                    {this.state.isEditMode !== doc.unique_id && <span>{doc.og_name}</span>}
                    {this.state.isEditMode !== doc.unique_id && <span className="edIcon" onClick={(evt) => { this.openEditMode(evt, doc.og_name, doc.unique_id) }}>
                      <img src={editIcon} alt="edit icon"></img>
                    </span>}
                    {this.state.isEditMode === doc.unique_id && <input type="text" className="fileNameInput" value={this.state.fileName} onKeyUp={(evt) => { this.onNameSave(evt, doc.unique_id) }} onChange={this.onFileNameChange} />}
                    {this.state.isEditMode === doc.unique_id && <span onClick={() => this.handleSave(doc.unique_id)}><i className='fa fa-save saveIcon' title='save'></i></span>}
                  </div>
                  <div className="pageCount alingCen">
                    {t('page:')} {doc.pages}
                  </div>
                  <div className="docDel justifyEnd">
                    <img src={deleteIcon} alt="delete icon" onClick={(evt)=>this.showModal(evt,doc.unique_id)}></img>
                  </div>
                </div>
              )
            })
          }
        </div>
      </>
    )
  }
}

export default inject('commonStore', 'signatureStore')(withLocalization(observer(DocList)));