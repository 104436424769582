import React, { Component } from 'react'
import withLocalization from '~/hoc/withLocalization';
import Header from "../../../newComponents/Header";
import Footer from "../../../newComponents/Footer";
import './../../../publicComponents/style/style.css';
import applicationRouter from '~/hoc/applicationRouter'
import { inject, observer } from 'mobx-react';
import ScrollToTop from '../../../components/ScrollToTop';

// import One from './assets/images/one.png';

class PrivacyPolicy extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        const locale = window.sessionStorage.getItem('locale') ? window.sessionStorage.getItem('locale').replace('no', 'nb') : 'en'
        return (
            <>
                <ScrollToTop />
                <div className='main-body lp_user_terms'>
                    <div className='landingPageWrapp frameworkApp'>
                        <Header about="noNeeded" />
                    </div>
                    {locale !== 'nb'
                        ?
                        <>
                            <div className='container-fluid'>
                                <div className='row'>
                                    <div className='col-md-6 part-1'>
                                        <div className='policy-content'>
                                            <h3 className='mx-5 my-3'>{('Privacy')} <span>{('Policy')}</span></h3>
                                        </div>
                                        <div className="terms-content">
                                            <p className='terms'><b>{('Privacy, processing of')} <br /> {('personal data from the web and mobile application.')}</b></p>
                                            <p style={{ fontSize: "20px", fontWeight: "Bold" }}>{('Summary of Contents')}</p>
                                            <div className='index-title'> <p style={{ fontSize: "18px", fontWeight: "Bold" }}>{('Part 1')} <span className='pageno' style={{ fontWeight: "normal" }}>{('p.1-13')}</span></p></div>
                                            <ul>
                                                <li>
                                                    <a href="#Introduction">{('Introduction')} <span className='pageno'>{('p.1')}</span></a>
                                                </li>
                                                <li>
                                                    <a href="#WhoAreWe">{('Who are we?')} <span className='pageno'>{('p.2')}</span></a>
                                                </li>
                                                <li>
                                                    <a href="#DataProtectionPrinciples">{('Our Data Protection Principles')} <span className='pageno'>{('p.3')}</span></a>
                                                </li>
                                                <li>
                                                    <a href="#HowWeCollectInfo">{('How we collect your information?')} <span className='pageno'>{('p.4')}</span></a>
                                                </li>
                                                <li>
                                                    <a href="#PersonalData">{('What personal data we process?')} <span className='pageno'>{('p.5-6')}</span></a>
                                                </li>
                                                <li>
                                                    <a href="#HowDataIsUsed">{('How we use your data?')} <span className='pageno'>{('p.7-8')}</span></a>
                                                </li>
                                                <li>
                                                    <a href="#HowDataIsShared">{('How we share your personal data?')} <span className='pageno'>{('p.9')}</span></a>
                                                </li>
                                                <li>
                                                    <a href="#InternationalDataTrans">{('International data transfers')} <span className='pageno'>{('p.10')}</span></a>
                                                </li>
                                                <li>
                                                    <a href="#Security">{('Security')} <span className='pageno'>{('p.11')}</span></a>
                                                </li>
                                                <li>
                                                    <a href="#Conservation">{('Conservation')} <span className='pageno'>{('p.11')}</span></a>
                                                </li>
                                                {/* <li>
                                            <a href="#InfoCollection">{('How is information collected?')} <span className='pageno'>{('p.1-2')}</span></a>
                                        </li>
                                        <li>
                                            <a href="#Example">{('Example form')} <span className='pageno'>{('p.2-3')}</span></a>
                                        </li>
                                        <li>
                                            <a href="#InfoPurpose">{('The purpose of the information collected')} <span className='pageno'>{('p.3-4')}</span></a>
                                        </li>
                                        <li>
                                            <a href="#InfoStore">{('How is the information stored?')}<span className='pageno'>{('p.4-5')}</span></a>
                                        </li>
                                        <li>
                                            <a href="#InfoDelete">{('How is the information deleted?')}<span className='pageno'>{('p.6')}</span></a>
                                        </li>
                                        <li>
                                            <a href="#Disclosure">{('Disclosure of information to third parties?')}<span className='pageno'>{('p.7-8')}</span></a>
                                        </li> */}
                                                <li>
                                                    <a href="#Right">{('Your rights')}<span className='pageno'>{('p.12')}</span></a>
                                                </li>
                                                <li>
                                                    <a href="#OurContact">{('How to contact us')}<span className='pageno'>{('p.13')}</span></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className='col-md-6 part-2'>
                                        <div className='terms-use' id="Introduction">
                                            <h1>{('Introduction')}</h1>
                                            <div className='list-items'>
                                                <p>
                                                    {('This policy applies to all websites and mobile application that we own and operate, and all services we provide, including our products for tidogkontroll.no, norsktimeregister.no and timeandcontrol.com and any other programs or services we may provide (such as events or training).  In the words of this policy, we just call them our')}
                                                    {` "${('services')}." `}
                                                    {('When we say')}
                                                    {` "${('personal data')}", `}
                                                    {('we mean identifiable information about you, such as your name, email address, phone number, location information, bank account information, payment information, support requests, community comments, and so on. If you cannot be identified (for example, when personal data is collected and anonymized), this policy does not apply. We may update this policy from time to time. Where a change is important, we make sure we tell you – usually by emailing you.')}
                                                </p>
                                            </div>
                                        </div>
                                        <div className='terms-use' id="WhoAreWe">
                                            <h1>{('Who are we?')}</h1>
                                            <div className='list-items'>
                                                <p>
                                                    {('When we refer to')}
                                                    {` "${('we')}" `}
                                                    ({('or')}
                                                    {` "${('our')}" `}
                                                    {('or')}
                                                    {` "${('us')}"`}),
                                                    {` ${('it means Tid & Kontroll AS (registered in Norway with reg. no. 920 566 650 and address at Solgaardskog 1, 1599 Moss, Norway) and all its wholly owned subsidiaries. The head office is located in Norway, but we operate and have offices in other parts of the world. We offer an easy-to-use web-based and mobile platform for various industries.  If you would like to know more about what we do, please refer')}`} <a href="https://norsktimeregister.no" target="_blank">norsktimeregister.no</a> {("and")} <a href="https://timeandcontrol.com" target="_blank">timeandcontrol.com</a>
                                                </p>
                                            </div>
                                        </div>
                                        <div className='terms-use' id="DataProtectionPrinciples">
                                            <h1>{('Our Data Protection Principles')}</h1>
                                            <div className='list-items'>
                                                <p>
                                                    {('Our approach to data protection is built around four main principles. They are at the heart of everything we do in relation to personal data.')}
                                                </p>
                                                <ul style={{ fontSize: "18px" }}>
                                                    <li><b>{('Transparency')}</b>: {("We take a human approach to how we process personal data by being open, honest and transparent.")}</li>
                                                    <li><b>{('Activation')}</b>: {("We enable connections and efficient use of personal data to increase productivity and growth.")}</li>
                                                    <li><b>{('Security')}</b>: {("We champion the industry's leading approaches to securing the personal data entrusted to us.")}</li>
                                                    <li><b>{('Stewardship')}</b>: {("We accept the responsibility that comes with the processing of personal data.")}</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className='terms-use' id="HowWeCollectInfo">
                                            <h1>{('How we collect your information?')}</h1>
                                            <div className='list-items'>
                                                <p>
                                                    {('When you visit our websites or use our services or mobile application, we collect personal data. The ways in which we collect can be largely categorised into the following:')}
                                                </p>
                                                <ul style={{ fontSize: "18px" }}>
                                                    <li><b>{('Information you provide to us directly')}</b>: {("We collect some information about you automatically when you visit our websites or use our services, such as your IP address and device type. We also collect information when you navigate through our websites and services, including which pages you viewed and which links you clicked on. This information is useful to us as it helps us gain a better understanding of how you use our websites and services so that we can continue to provide the best experience for you (for example, by customising the content you see). Some of this information is collected using cookies and similar tracking technologies. To learn more about the types of cookies we use, why, and how you can control them, check our cookie policy on")} <a href="https://timeandcontrol.com/cookiepolicy" target="_blank">timeandcontrol.com/cookiepolicy</a></li>
                                                    <li><b>{('Information we collect automatically')}</b>: {("We enable connections and efficient use of personal data to increase productivity and growth.")}</li>
                                                    <li><b>{('Information we receive from third parties')}</b>: {("The majority of the information we collect is obtained directly from you. Sometimes we may collect personal data about you from other sources, such as publicly available materials or trusted third parties such as our marketing and research partners. We use this information to supplement the personal data we already hold about you to better inform, personalise and improve our services, and to validate the personal data you provide.")}</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className='terms-use' id="PersonalData">
                                            <h1>{('What personal data we process?')}</h1>
                                            <div className='list-items'>
                                                <p>
                                                    {("When you use our services without logging in, we process information based on cookies. As a rule, we will not be able to link this information to you as a person, unless you log in.")}
                                                </p>
                                                {/* <p>
                                            <a href="http://timeandcontrol.com" style={{ textDecoration: "underline" }}>{("http://timeandcontrol.com")}</a> {("and")} <a href="https://norsktimeregister.no" style={{ textDecoration: "underline" }}>{("https://norsktimeregister.no")}</a> {("Our app is used by employers and their employees, unions and their members.")}
                                        </p> */}
                                                <p>
                                                    {("When you log in to use our services, we process the following information in particular (as may be applicable to your use of our services and as may be provided by you):")}
                                                </p>
                                                {/* <p><b>{("Examples of information collected:")}</b></p> */}
                                                <div className='row'>
                                                    <div className='col-md-12'>
                                                        <ul style={{ fontSize: "19px" }}>
                                                            <li>{("Name")}</li>
                                                            <li>{("Contact details")}</li>
                                                            <li>{("Company address / invoice address")}</li>
                                                            <li>{("Information about your customer relationship")}</li>
                                                            <li>{("Your specified preferences, notifications and communication settings")}</li>
                                                            <li>{("Login information")}</li>
                                                            <li>{("Your communication with us")}</li>
                                                            <li>{("National ID number")}</li>
                                                            <li>{("Addresss")}</li>
                                                            <li>{("Description")}</li>
                                                            <li>{("Name and phone number of next of kin")}</li>
                                                            <li>{("Names of children and date of birth")}</li>
                                                            <li>{("Time sheets")}</li>
                                                            <li>{("Tasks")}</li>
                                                            <li>{("Available vacation days")}</li>
                                                            <li>{("Absence (self-reports and sick children)")}</li>
                                                            <li>{("Employment contract")}</li>
                                                            <li>{("Photos of work results")}</li>
                                                            <li>{("Digital signature")}</li>
                                                            <li>{("Location information")} : {("When you use our services, we may collect information about your location. With your permission, we may also collect information about your precise location using methods that include GPS, wireless networks, mobile towers, Wi-Fi access points and other sensors, such as gyroscopes, accelerometers and compasses. We collect your location from background to run our services and task only after you've provided the permission to access. This information is required depending upon requirement by your union. If your union wants you to stamp-in and stamp-out automatically based on work location than the application will automatically logs you in and out using GPS and Background location. Norsktimeregister app will help you to auto logg in/out based on your work location with the help of GPS and backgound location")}</li>
                                                            <li>{("Information about your use of the website, including which subpages are visited, browser settings and IP addresse")}</li>
                                                            <li>{("Any other information you may provide to us where we collect your personal data and how we process your personal data, performing a contract with you where we have legitimate interests to process your personal data and it is not overridden by your rights in accordance with legal obligation where we have your consent")}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <p>{("If we do not collect your personal data, we may not be able to give you full access to all of our services, and some features of our websites may not be available to you. If you do not have a relationship with us, but believe that we have entered your personal data on our websites or services, you must contact us by email for any questions about whether we have your personal data (if applicable, where you want to access, correct, change or request that the administrator delete your personal data).")}</p>
                                            </div>
                                        </div>
                                        <div className='terms-use' id="HowDataIsUsed">
                                            <h1>{('How we use your data?')}</h1>
                                            <div className='list-items'>
                                                <p>
                                                    {("First and foremost, we use your personal data to operate our websites and mobile application, and provide you with the services you have requested and to manage our relationship with you. We also use your personal data for other purposes, which may include the following:")}
                                                </p>
                                                <ol>
                                                    <li><b>{("Communicating with you")}</b>: {("This may include")}:
                                                        <ul>
                                                            <li>{("Provide you with information you have requested from us (such as training or educational materials) or information we must send to you")}</li>
                                                            <li>{("Operational communications, such as changes to our websites and services, security updates, or help to use our websites and services")}</li>
                                                            <li>{("Marketing communications (another product or service we think you are interested in) in accordance with your marketing preferences")}</li>
                                                            <li>{("Ask you for feedback or to participate in any surveys we conduct (with which we may engage a third party to assist).")}</li>
                                                        </ul>
                                                    </li>
                                                    <li><b>{("To provide you with customer support and billing")}</b>: {("This may include and help you resolve technical support issues or other issues related to the websites or services, either by email, in app support, or otherwise.")}</li>
                                                    <li><b>{("To improve our websites and services and develop new ones")}</b>: {("For example, by tracking and monitoring the use of websites and services so that we can continue to improve, or by performing technical analysis of our websites and services so that we can optimise the user experience and provide you with more efficient tools. To send you an invoice of a subscription that you have with us.")}</li>
                                                    <li><b>{("To protect")}</b>: {("So that we can detect and prevent irregular or malicious activity, and make sure that everyone who uses our websites and services complies with our Terms of Use.")}</li>
                                                    <li><b>{("To promote")}</b>: {("In addition to sending you marketing communications, we may also use your personal data to display targeted advertising to you online - through our own websites and services or via third party websites and their platforms.")}</li>
                                                    <li><b>{("To analyse, collect and report")}</b>: {("We may use the personal data we collect about you and other users of our websites and services (either directly or from third parties) to produce aggregated and anonymized analytics and reports that we may share publicly or with third parties.")}</li>
                                                </ol>
                                            </div>
                                        </div>
                                        <div className='terms-use' id="HowDataIsShared">
                                            <h1>{('How we share your personal data?')}</h1>
                                            <div className='list-items'>
                                                <p>
                                                    {("There will be times when we need to share your personal data with third parties.We will only disclose your personal data to:")}
                                                </p>
                                                <ul>
                                                    <li>{("Other companies in the Tid & Kontroll AS group of companies")}</li>
                                                    <li>{("Third-party service providers and partners who assist and enable us to use the personal data to, for example, support the provision of or provide functionality on the website or services, or to promote our goods and services to you")}</li>
                                                    <li>{("Supervisory authorities, law enforcement agencies, governments, courts or other third parties where we believe it is necessary to comply with applicable laws or regulations, or to exercise, establish or defend our legal rights. Where possible and appropriate, we will notify you of this type of disclosure")}</li>
                                                    <li>{("An actual or potential buyer (and its agents and advisers) in connection with an actual or proposed purchase, merger or acquisition of any part of our business")}</li>
                                                    <li>{("Other third parties when we have your consent to do so.")}</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className='terms-use' id="InternationalDataTrans">
                                            <h1>{('International data transfers')}</h1>
                                            <div className='list-items'>
                                                <p>
                                                    {("When we share data, it may be transferred to and processed in countries other than your country of residence, for example to countries located within the EU region. We put security measures in place to ensure that personal data remains protected. For persons in the EEA, this means that your data may be transferred outside the EEA. When your personal data is transferred outside the EEA, it is only transferred to countries identified as providing adequate protection for EEA data (such as New Zealand), or to third parties where we have approved transfer mechanisms in place to protect your Personal Data - e.g. by entering into the European Commission's SCCs or taking such other measures that may be required for compliance with the GDPR.")}
                                                </p>
                                            </div>
                                        </div>
                                        <div className='terms-use' id="Security">
                                            <h1>{('Security')}</h1>
                                            <div className='list-items'>
                                                <p>
                                                    {("Security is a priority for us when it comes to your personal data. We are committed to protecting your personal data and taking appropriate technical and organisational measures to ensure that it happens.")}
                                                </p>
                                            </div>
                                        </div>
                                        <div className='terms-use' id="Conservation">
                                            <h1>{('Conservation')}</h1>
                                            <div className='list-items'>
                                                <p>
                                                    {("How long we retain your personal data depends on what it is and whether we have an ongoing business, we must keep it (for example, to provide you with a service you have requested or to comply with applicable legal, tax or accounting requirements). We retain your personal data for as long as we have a relationship with you, and for a period afterwards where we have an ongoing business, we must keep it, in accordance with our data retention and practices policy. After that period, we will make sure that it is deleted or anonymized.")}
                                                </p>
                                            </div>
                                        </div>

                                        {/* <div className='terms-use' id="InfoCollection
                                    <h1>
                                        <b>{('How is information collected?')}</b>
                                    </h1>
                                    <div className='list-items'>
                                        <p>{("Norstimeregister collects information through forms on our website and mobile app. It is voluntary to provide this information. If you choose not to provide your personal data, we may be prevented from granting you access to the product or service. We ask you to contact your nearest manager, are you a member of the union, ask you to contact the union or the nearest trustee in your workplace.")}</p>
                                    </div>
                                </div>

                                <div className='terms-use' id="Example
                                    <h1>
                                        <b>{('Example form:')}</b>
                                    </h1>

                                    <div className='list-items'>
                                        <ul style={{ fontSize: "19px" }}>
                                            <li>{("Contact form")}</li>
                                            <li>{("When you want to try a product or service")}</li>
                                            <li>{("When purchasing products or services on our website")}</li>
                                            <li>{("When registering for webinars, courses and conferences")}</li>
                                            <li>{("When downloading documents such as eBooks from our website")}</li>
                                            <li>{("When you sign up to receive our newsletter or blog by email")}</li>
                                        </ul>
                                    </div>
                                </div>

                                <div className='terms-use' id="InfoPurpose
                                    <h1>
                                        <b>{('The purpose of the information collected')}</b>
                                    </h1>
                                    <div className='list-items'>
                                        <p>{("We create profiles on the background of the information collected in order to offer you the most relevant products and provide good customer service. The profiles are based solely on information that you voluntarily provide to us, we do not collect information for this purpose from third parties.")}</p>
                                        <ul style={{ fontSize: "19px" }}>
                                            <li>{("To provide access to our products and services")}</li>
                                            <li>{('In order to send relevant information')}</li>
                                            <li>{('To be able to contact and offer our products and services')}</li>
                                        </ul>
                                    </div>
                                </div>

                                <div className='terms-use' id="InfoStore
                                    <h1>
                                        <b>{('How is the information stored?')}</b>
                                    </h1>
                                    <div className='list-items'>
                                        <p>{("Information obtained through our website, mobile app (both ios and android) and sent by email to us and this information goes directly into the Norwegian time register and Time & Control application, our marketing department or seller contact us by email or by phone to offer our product.")}</p>
                                    </div>
                                </div>

                                <div className='terms-use' id="InfoDelete">
                                    <h5> {("PART 1")} </h5>
                                    <h1>
                                        <b>{("How is the information deleted?")}</b>
                                    </h1>
                                    <div className='list-items'>
                                        <p>{("Information is deleted upon request by contacting our customer service centre or by email.")}</p>
                                    </div>
                                </div>

                                <div className='terms-use' id="Disclosure">
                                    <h5> {("PART 1")} </h5>
                                    <h1>
                                        <b>{("Disclosure of information to third parties?")}</b>
                                    </h1>
                                    <div className='list-items'>
                                        <p>{("The personal data is not shared with third parties, but Time & Control may use subcontractors to provide all or part of the website. Such subcontractors may process personal data on behalf of Time & Control. Time & Control may use this information for other products.")}</p>
                                    </div>
                                </div> */}

                                        <div className='terms-use' id="Right">
                                            <h5> {("PART 1")} </h5>
                                            <h1>
                                                <b>{("Your rights")}</b>
                                            </h1>
                                            <div className='list-items'>
                                                <p>{("As a user of our websites, you have the right to demand access to the personal data that we process about you and how it is processed. You may also require rectification, erasure and restrictions on the processing of personal data in accordance with the Personal Data Act.")}</p>
                                                <p>{("Where the processing of personal data is based on consent, you can withdraw your consent at any time.")}</p>
                                                <p>{('If you believe that we have not complied with your rights under the Personal Data Act, you have the right to complain to the relevant supervisory authority. This is done by submitting a complaint to the Norwegian Data Protection Authority, contact information is available on')} <a href="www.datatilsynet.no">www.datatilsynet.no</a>.</p>
                                            </div>
                                        </div>

                                        <div className='terms-use' id="OurContact">
                                            <h5> {("PART 1")} </h5>
                                            <h1>
                                                <b>{("How to contact us")}</b>
                                            </h1>
                                            <div className='list-item'>
                                                <p>{("We're always eager to hear from you. If you are curious about what personal data we have about you or you have any questions or feedback to us on this policy, our websites or services, please get in touch. As a technology company, we prefer to communicate with you via email - this ensures that you are put in touch with the right person, in the right place, and in accordance with regulations. Our e-mail is")} <a href='mailto:post@tidogkontroll.no' >post@tidogkontroll.no</a> {("or")} <a href='mailto:info@timeandcontrol.com' >info@timeancontrol.com</a></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className='container-fluid'>
                                <div className='row'>
                                    <div className='col-md-12 part-2'>
                                        <div className='terms-use' id="Introduction">
                                            <h1 className='text-center'>Personvernerklæring</h1>
                                            <h2>{('Introduksjon')}</h2>
                                            <div className='list-items'>
                                                <p>
                                                    Denne erklæringen gjelder på alle nettsteder som vi eier og driver, og alle tjenester vi tilbyr, inkludert våre produkter for tidogkontroll.no, norsktimeregister.no og timeandcontrol.com og eventuelle andre programmer eller tjenester vi kan tilby (for eksempel arrangementer eller opplæring). I denne erklæringen, kaller vi dem bare våre "tjenester". Når vi sier "personopplysninger" mener vi identifiserbar informasjon om deg, for eksempel navn, e-postadresse, telefonnummer, bankkontooplysninger, betalingsinformasjon, brukerstøtteforespørsler, kommentarer og så videre. Hvis du ikke kan identifiseres (for eksempel når personopplysninger er samlet og anonymisert), gjelder denne erklæringen ikke. Vi kan oppdatere denne erklæringen fra tid til annen. Der en endring er viktig, sørger vi for å orientere deg om det - vanligvis ved å sende deg en e-post.
                                                </p>
                                            </div>

                                            <div id="WhoAreWe">
                                                <h2>{('Hvem er vi"?')}</h2>
                                                <div className='list-items'>
                                                    <p>
                                                        Når vi refererer til "vi" (eller "våre" eller "oss"), betyr det Tid & Kontroll AS (registrert i Norge med org.nr. 920 566 650 og adresse på Solgaardskog 1, 1599 Moss, Norge) og alle dets heleide datterselskaper. Hovedkontoret ligger i Norge, men vi opererer og har kontorer i andre deler av verden. Vi tilbyr en brukervennlig nettbasert og mobil plattform for ulike bransjer. Hvis du vil vite mer om hva vi gjør, kan du se <a href='https://norsktimeregister.no'>https://norsktimeregister.no</a> og <a href='https://timeandcontrol.com'>https://timeandcontrol.com</a>.
                                                    </p>
                                                </div>
                                            </div>
                                            <div id="DataProtectionPrinciples">
                                                <h2>{('Våre prinsipper for personvern')}</h2>
                                                <div className='list-items'>
                                                    <p>
                                                        Vår tilnærming til databeskyttelse er bygget opp rundt fire hovedprinsipper. De er i hjertet av alt vi gjør i forbindelse med personopplysninger.
                                                    </p>
                                                    <ul style={{ fontSize: "18px" }}>
                                                        <li><b>{('Åpenhet')}</b>: Vi tar en menneskelig tilnærming til hvordan vi behandler personopplysninger ved å være åpen, ærlig og gjennomsiktig.</li>
                                                        <li><b>{('Aktivering')}</b>: Vi muliggjør forbindelser og effektiv bruk av personopplysninger for å øke produktiviteten og veksten.</li>
                                                        <li><b>{('Sikkerhet')}</b>: Vi mester bransjens ledende tilnærminger for å sikre personopplysningene som er betrodd oss.</li>
                                                        <li><b>{('Forvaltning')}</b>: Vi aksepterer ansvaret som kommer med behandling av personopplysninger.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div id="HowWeCollectInfo">
                                                <h2>{('Hvordan samler vi dine opplysninger')}</h2>
                                                <div className='list-items'>
                                                    <p>
                                                        Når du besøker våre nettsider eller bruker våre tjenester, samler vi personopplysninger. Måtene vi samler på, kan i stor grad kategoriseres i følgende:
                                                    </p>
                                                    <ul style={{ fontSize: "18px" }}>
                                                        <li><b>Informasjon du gir oss direkte:</b>: Når du besøker eller bruker deler av våre nettsteder eller tjenester, kan vi be deg om å gi oss personlig informasjon. For eksempel ber vi om kontaktinformasjon når du registrerer deg for en gratis prøveversjon, svarer på f.eks. på en jobbsøknad eller et e-posttilbud, bli med på sosiale medier, delta i opplæring og arrangementer, kontakt oss med spørsmål eller be om brukerstøtte. Hvis du ikke vil gi oss personopplysninger, trenger du ikke, men det kan bety at du ikke kan bruke visse deler av våre nettsteder eller tjenester hvor slik informasjon er nødvendig for å levere nettsiden eller tjenesten.</li>
                                                        <li><b>Informasjon vi samler inn automatisk:</b>: Vi samler inn noen informasjon om deg automatisk når du besøker våre nettsteder eller bruker våre tjenester, som din IP-adresse og enhetstype. Vi samler også inn informasjon når du navigerer gjennom våre nettsteder og tjenester, inkludert hvilke sider du så på og hvilke linker du klikket på. Denne informasjonen er nyttig for oss, da det hjelper oss med å få en bedre forståelse av hvordan du bruker våre nettsteder og tjenester, slik at vi kan fortsette å gi den beste opplevelsen for deg (for eksempel ved å tilpasse innholdet du ser). Noen av disse opplysningene er samlet ved hjelp av informasjonskapsler og lignende sporingsteknologi. Hvis du vil finne ut mer om hvilke typer informasjonskapsler vi bruker, hvorfor, og hvordan du kan kontrollere dem, ta en titt på vår cookie policy.</li>
                                                        <li><b>Informasjon vi får fra tredjeparter:</b>: Flertallet av informasjonen vi samler inn får vi direkte fra deg. Noen ganger kan vi innhente personopplysninger om deg fra andre kilder, for eksempel offentlige tilgjengelige materialer eller pålitelige tredjeparter som våre markedsførings- og forskningspartnere. Vi bruker denne informasjonen til å supplere de personopplysningene vi allerede har om deg, for bedre å informere, tilpasse og forbedre våre tjenester, og å validere de personopplysningene du oppgir.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div id="PersonalData">
                                                <h2>{('Hvilke personopplysninger vi behandler')}</h2>
                                                <div className='list-items'>
                                                    <p>
                                                        Når du bruker våre Tjenester uten å logge deg inn, behandler vi opplysninger basert på informasjonskapsler (cookies). Vi vil som utgangspunkt ikke kunne knytte disse opplysningene til deg som person, med mindre du logger deg inn. Når du logger deg inn for å bruke våre Tjenester, behandler vi særlig følgende opplysninger (i den grad de gjelder for din bruk av tjenestene :
                                                    </p>
                                                    {/* <p>
                                            <a href="http://timeandcontrol.com" style={{ textDecoration: "underline" }}>{("http://timeandcontrol.com")}</a> {("and")} <a href="https://norsktimeregister.no" style={{ textDecoration: "underline" }}>{("https://norsktimeregister.no")}</a> {("Our app is used by employers and their employees, unions and their members.")}
                                        </p> */}
                                                    {/* <p>
                                                    {("When you log in to use our services, we process the following information in particular (as may be applicable to your use of our services and as may be provided by you):")}
                                                </p> */}
                                                    {/* <p><b>{("Examples of information collected:")}</b></p> */}
                                                    <div className='row'>
                                                        <div className='col-md-12'>
                                                            <ul style={{ fontSize: "19px" }}>
                                                                <li>{("Navn")}</li>
                                                                <li>{("Kontaktdetaljer")}</li>
                                                                <li>{("Firmaadresse/fakturaadresse")}</li>
                                                                <li>{("Opplysninger om kundeforholdet ditt")}</li>
                                                                <li>{("Dine angitte preferanser, varslinger og kommunikasjonsinnstillinger")}</li>
                                                                <li>{("Påloggingsinformasjon")}</li>
                                                                <li>{("Din kommunikasjon med oss")}</li>
                                                                <li>{("Fødselsnummer")}</li>
                                                                <li>{("Adresse")}</li>
                                                                <li>{("Postnummer")}</li>
                                                                <li>{("Poststed")}</li>
                                                                <li>{("Beskrivelse")}</li>
                                                                <li>{("Navn og telefonnummer pårørende")}</li>
                                                                <li>{("Navn til barn og fødselsdato")}</li>
                                                                <li>{("Timelister")}</li>
                                                                <li>{("Arbeidsoppgaver")}</li>
                                                                <li>{("Disponible feriedager")}</li>
                                                                <li>{("Fravær (egenmeldinger og sykt barn)")}</li>
                                                                <li>{("GPS-koordinater")}</li>
                                                                <li>{("Arbeidskontrakt")}</li>
                                                                <li>{("Bilder av utført arbeid")}</li>
                                                                <li>{("Digital signatur")}</li>
                                                                <li>{("Informasjon om din bruk av nettsiden, i form av blant annet hvilke undersider som besøkes, nettleserinstillinger og IP-adresse")}</li>
                                                                <li>{("Andre opplysninger du eventuelt måtte oppgi til oss")}</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <p>{("If we do not collect your personal data, we may not be able to give you full access to all of our services, and some features of our websites may not be available to you. If you do not have a relationship with us, but believe that we have entered your personal data on our websites or services, you must contact us by email for any questions about whether we have your personal data (if applicable, where you want to access, correct, change or request that the administrator delete your personal data).")}</p>
                                                </div>
                                            </div>
                                            <div id="HowDataIsUsed">
                                                <h2>{('Hvordan samler vi dine personopplysninger og hvordan behandler vi dine personopplysninger:')}</h2>
                                                <div className='list-items'>
                                                    <ul>
                                                        <li>å utføre en kontrakt med deg</li>
                                                        <li>hvor vi har legitime interesser til å behandle personopplysningene og de ikke er overstyrt av dine rettigheter</li>
                                                        <li>i henhold til lovlig forpliktelse</li>
                                                        <li>hvor vi har ditt samtykke</li>
                                                    </ul>
                                                </div>
                                                <p>
                                                    Hvis vi ikke samler dine personopplysninger, kan det hende vi ikke kan gi deg full tilgang til alle våre tjenester, og noen funksjoner på våre nettsteder er kanskje ikke tilgjengelige for deg. Hvis du ikke har et forhold til oss, men tror at vi har angitt dine personopplysninger på våre nettsteder eller tjenester, må du kontakte oss via epost for eventuelle spørsmål om vi har dine personopplysninger (evt. hva du vil ha tilgang til, rette, endre eller be om at administrator sletter dine personopplysninger).
                                                </p>
                                            </div>
                                            <div id="HowDataIsShared">
                                                <h2>{('Hvordan bruker vi dataene dine')}</h2>
                                                <div className='list-items'>
                                                    <p>
                                                        Først og fremst bruker vi dine personopplysninger til å drive våre nettsider og gi deg de tjenester du har bedt om, og for å administrere vårt forhold til deg. Vi bruker også dine personopplysninger til andre formål, som kan omfatte følgende:
                                                    </p>
                                                    <p>
                                                        <b>Å kommunisere med deg: Dette kan omfatte:</b>
                                                    </p>
                                                    <ul>
                                                        <li>Gi deg informasjon du har bedt om fra oss (som trening eller utdannelsesmateriale) eller informasjon vi må sende til deg</li>
                                                        <li>Operasjonell kommunikasjon, for eksempel endringer i våre nettsteder og tjenester, sikkerhetsoppdateringer, eller hjelp til å bruke våre nettsteder og tjenester</li>
                                                        <li>Markedsføringskommunikasjon (et annet produkt eller en tjeneste vi tror du er interessert i) i samsvar med markedsføringspreferansene dine</li>
                                                        <li>Spørre deg om tilbakemelding eller å delta i alle undersøkelser vi gjennomfører (som vi kan engasjere en tredjepart for å bistå med).</li>
                                                    </ul>
                                                    <p>
                                                        <b>For å gi deg kundesupport og fakturering:</b>
                                                    </p>
                                                    <ul>
                                                        <li>Dette kan omfatte og hjelpe deg med å løse problemer med teknisk støtte eller andre problemer knyttet til nettstedene eller tjenestene, enten via e-post, i app-støtte eller på annen måte.</li>
                                                    </ul>
                                                    <p>
                                                        <b>For å forbedre våre nettsteder og tjenester og utvikle nye:</b>
                                                    </p>
                                                    <ul>
                                                        <li>For eksempel ved å spore og overvåke bruken av nettsteder og tjenester, slik at vi kan fortsette å forbedre, eller ved å utføre teknisk analyse av våre nettsteder og tjenester slik at vi kan optimalisere brukeropplevelsen og gi deg mer effektive verktøy. For å sende deg faktura av et abonnement som du har hos oss.</li>
                                                    </ul>
                                                    <p>
                                                        <b>For å beskytte:</b>
                                                    </p>
                                                    <ul>
                                                        <li>Slik at vi kan oppdage og forhindre uregelmessig eller ondsinnet aktivitet, og sørge for at alle som bruker våre nettsteder og tjenester opptrer i henhold til våre bruksvilkår.</li>
                                                    </ul>
                                                    <p>
                                                        <b>For å markedsføre:</b>
                                                    </p>
                                                    <ul>
                                                        <li>I tillegg til å sende deg markedsføringskommunikasjon, kan vi også bruke dine personopplysninger til å vise målrettet annonsering til deg online - gjennom egne hjemmesider og tjenester eller via tredjeparts nettsteder og deres plattformer.</li>
                                                    </ul>
                                                    <p>
                                                        <b>For å analysere, samle og rapportere:</b>
                                                    </p>
                                                    <ul>
                                                        <li>Vi kan bruke personopplysningene vi samler om deg og andre brukere av våre nettsteder og tjenester (enten direkte eller fra tredje parter) for å produsere aggregerte og anonymiserte analyser og rapporter som vi kan dele offentlig eller med tredje parter.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div id="HowDataIsShared">
                                                <h2>{('Hvordan vi deler dine personopplysninger')}</h2>
                                                <div className='list-items'>
                                                    <p>
                                                        Det vil være tidspunkter når vi må dele dine personopplysninger med tredjeparter.
                                                    </p>
                                                    <p>
                                                        <b>Vi vil kun avsløre dine personopplysninger til:</b>
                                                    </p>
                                                    <ul>
                                                        <li>Andre selskaper i Tid & Kontroll AS-gruppen av selskaper</li>
                                                        <li>Tredjeparts tjenesteleverandører og partnere som bistår og gjør det mulig for oss å bruke personopplysningene til for eksempel å støtte levering av eller gi funksjonalitet på nettstedet eller tjenestene, eller å markedsføre våre varer og tjenester til deg</li>
                                                        <li>Tilsynsmyndigheter, rettshåndhevende organer, myndigheter, domstoler eller andre tredjeparter der vi mener det er nødvendig å overholde gjeldende lover eller forskrifter, eller å utøve, etablere eller forsvare våre juridiske rettigheter. Når det er mulig og hensiktsmessig, vil vi varsle deg om denne typen deling</li>
                                                        <li>En faktisk eller potensiell kjøper (og dens agenter og rådgivere) i forbindelse med et faktisk eller foreslått kjøp, fusjon eller oppkjøp av noen del av vår virksomhet</li>
                                                        <li>Eller andre tredjeparter der vi har ditt samtykke til å gjøre det.</li>
                                                    </ul>                                                
                                                </div>
                                            </div>
                                            <div id="InternationalDataTrans">
                                                <h2>{('Internasjonale dataoverføringer')}</h2>
                                                <div className='list-items'>
                                                    <p>
                                                        Når vi deler data, kan det overføres til og behandles i andre land enn landet du bor i, for eksempel til land som befinner seg innenfor EØS-området. Vi stiller sikkerhetstiltak på plass for å sikre at personopplysninger forblir beskyttet. For personer i EØS-området betyr dette at dataene dine kan overføres utenfor EØS-området. Når dine personopplysninger overføres utenfor EØS-området, blir det kun overført til land som er identifisert som å gi tilstrekkelig beskyttelse for EØS-data (som New Zealand), eller til tredjeparter der vi har godkjente overføringsmekanismer på plass for å beskytte dine personopplysninger – f.eks. ved å inngå Europakommisjonens SCC-er, eller ved å gjøre slike andre tiltak som kan være nødvendig i henhold til GDPR.
                                                    </p>
                                                </div>
                                            </div>
                                            <div id="Security">
                                                <h2>{('Sikkerhet')}</h2>
                                                <div className='list-items'>
                                                    <p>
                                                        Sikkerhet er en prioritet for oss når det gjelder dine personopplysninger. Vi er forpliktet til å beskytte dine personopplysninger og ha passende tekniske og organisatoriske tiltak for å sikre at det skjer.
                                                    </p>
                                                </div>
                                            </div>
                                            <div id="Conservation">
                                                <h2>{('Lagring')}</h2>
                                                <div className='list-items'>
                                                    <p>
                                                        Hvor lenge vi beholder dine personopplysninger, avhenger av hva det er og om vi har en pågående virksomhet, må vi beholde den (for eksempel å gi deg en tjeneste du har bedt om eller å overholde gjeldende lov-, skatte- eller regnskaps krav). Vi beholder dine personopplysninger så lenge vi har et forhold til deg, og i en periode etterpå der vi har en pågående virksomhet, må vi beholde den, i samsvar med våre retningslinjer for dataoppbevaring og praksis. Etter den perioden vil vi sørge for at den er slettet eller anonymisert.
                                                    </p>
                                                </div>
                                            </div>

                                            {/* <div id="InfoCollection
                                    <h2>
                                        <b>{('How is information collected?')}</b>
                                    </h2>
                                    <div className='list-items'>
                                        <p>{("Norstimeregister collects information through forms on our website and mobile app. It is voluntary to provide this information. If you choose not to provide your personal data, we may be prevented from granting you access to the product or service. We ask you to contact your nearest manager, are you a member of the union, ask you to contact the union or the nearest trustee in your workplace.")}</p>
                                    </div>
                                </div>

                                <div id="Example
                                    <h2>
                                        <b>{('Example form:')}</b>
                                    </h2>

                                    <div className='list-items'>
                                        <ul style={{ fontSize: "19px" }}>
                                            <li>{("Contact form")}</li>
                                            <li>{("When you want to try a product or service")}</li>
                                            <li>{("When purchasing products or services on our website")}</li>
                                            <li>{("When registering for webinars, courses and conferences")}</li>
                                            <li>{("When downloading documents such as eBooks from our website")}</li>
                                            <li>{("When you sign up to receive our newsletter or blog by email")}</li>
                                        </ul>
                                    </div>
                                </div>

                                <div id="InfoPurpose
                                    <h2>
                                        <b>{('The purpose of the information collected')}</b>
                                    </h2>
                                    <div className='list-items'>
                                        <p>{("We create profiles on the background of the information collected in order to offer you the most relevant products and provide good customer service. The profiles are based solely on information that you voluntarily provide to us, we do not collect information for this purpose from third parties.")}</p>
                                        <ul style={{ fontSize: "19px" }}>
                                            <li>{("To provide access to our products and services")}</li>
                                            <li>{('In order to send relevant information')}</li>
                                            <li>{('To be able to contact and offer our products and services')}</li>
                                        </ul>
                                    </div>
                                </div>

                                <div id="InfoStore
                                    <h2>
                                        <b>{('How is the information stored?')}</b>
                                    </h2>
                                    <div className='list-items'>
                                        <p>{("Information obtained through our website, mobile app (both ios and android) and sent by email to us and this information goes directly into the Norwegian time register and Time & Control application, our marketing department or seller contact us by email or by phone to offer our product.")}</p>
                                    </div>
                                </div>

                                <div id="InfoDelete">
                                    <h5> {("PART 1")} </h5>
                                    <h2>
                                        <b>{("How is the information deleted?")}</b>
                                    </h2>
                                    <div className='list-items'>
                                        <p>{("Information is deleted upon request by contacting our customer service centre or by email.")}</p>
                                    </div>
                                </div>

                                <div id="Disclosure">
                                    <h5> {("PART 1")} </h5>
                                    <h2>
                                        <b>{("Disclosure of information to third parties?")}</b>
                                    </h2>
                                    <div className='list-items'>
                                        <p>{("The personal data is not shared with third parties, but Time & Control may use subcontractors to provide all or part of the website. Such subcontractors may process personal data on behalf of Time & Control. Time & Control may use this information for other products.")}</p>
                                    </div>
                                </div> */}

                                            <div id="Right">
                                                <h2><b>{("Dine rettigheter")}</b></h2>
                                                <div className='list-items'>
                                                    <p>Som bruker av nettsidene våre har du rett til å kreve innsyn i personopplysningene som vi behandler om deg og hvordan de behandles. Du kan også kreve retting, sletting og begrensninger i behandlingen av personopplysninger i henhold til personopplysningsloven.</p>
                                                    <p>Hvor behandlingen av personopplysninger er basert på samtykke, kan du til enhver tid trekke samtykket tilbake.</p>
                                                    <p>Dersom du mener at vi ikke har overholdt dine rettigheter i henhold til personopplysningsloven, har du rett til å klage til den aktuelle tilsynsmyndigheten. Dette gjøres ved å sende klage til Datatilsynet, kontaktinformasjon er tilgjengelig på <a href="www.datatilsynet.no">www.datatilsynet.no</a>.</p>
                                                </div>
                                            </div>

                                            <div id="OurContact">
                                                <h2><b>{("Hvordan kontakte oss")}</b></h2>
                                                <div className='list-item'>
                                                    <p>Vi er alltid ivrige etter å høre fra deg. Hvis du er nysgjerrig på hvilke personopplysninger vi har om deg eller du har spørsmål eller tilbakemelding til oss på denne erklæringen, våre nettsteder eller tjenester, vennligst ta kontakt. Som teknologibedrift foretrekker vi å kommunisere med deg via e-post - dette sikrer at du blir satt i kontakt med riktig person, på riktig sted, og i samsvar med regelverk. Vår e-post er <a href='mailto:info@timeandcontrol.com' >info@timeancontrol.com</a>.</p>
                                                    {/* <p>{("We're always eager to hear from you. If you are curious about what personal data we have about you or you have any questions or feedback to us on this policy, our websites or services, please get in touch. As a technology company, we prefer to communicate with you via email - this ensures that you are put in touch with the right person, in the right place, and in accordance with regulations. Our e-mail is")} </p> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    <div className='landingPageWrapp frameworkApp'>
                        <Footer />
                    </div>
                </div>
            </>
        )
    }
}

export default inject('authStore', 'userStore')(applicationRouter(withLocalization(observer(PrivacyPolicy))));
