import VisitorAdd from './containers/Admin/Visitors/VisitorAdd';
import visitorIcon from './assets/img/profile-2user.svg';

const visitorRoutes = [
    {
        path: '/',
        layout: '/admin',
        icon: visitorIcon,
        name: 'Visitor Register',
        component: VisitorAdd,
        order: 0.5,
        allowed: user => {
            if (!user) return false;
            if (user.user_type === 'super-admin') return false;
            if (user.user_type === 'member') return false;
            return true;
        }
    }
];
export default visitorRoutes;
