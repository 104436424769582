import { observable, action, makeObservable } from 'mobx';

// import getBase64 from '~/utils/getBase64';
import agent from '../agent';

class BizTypeStore {
    currentList = [];

    currentEntity = {};

    updatingErrors = null;

    updating = false;

    loading = false;

    requestParams = null;

    deleteSuccess = false;

    lastListLoadTime = null;

    constructor() {
        makeObservable(this, {
            currentList: observable,
            currentEntity: observable,
            updatingErrors: observable,
            updating: observable,
            loading: observable,
            requestParams: observable,
            deleteSuccess: observable,
            lastListLoadTime: observable,
            loadList: action,
            returnDefaultNew: action,
            load: action,
            save: action,
            remove: action,
            currentBizTypeDocumentList: observable,
            getbiztypeDocuments: action,
            addBizTypeDocument: action
        });
    }

    loadList(params) {
        return agent.BizTypes.list(params)
            .then(
                action(list => {
                    this.requestParams = params;
                    list.time = new Date();
                    this.currentList = list;
                    this.lastListLoadTime = list.time;
                    return list;
                })
            )
            .catch(
                action(err => {
                    throw err;
                })
            );
    }

    returnDefaultNew(params) {
        this.currentEntity = { biztype: { name: '', data: {} } };
        this.loading = false;
    }

    load(id) {
        this.loading = true;
        return agent.BizTypes.load(id)
            .then(
                action(response => {
                    if (!response.biztype.data) response.biztype.data = {};
                    this.currentEntity = response;
                    this.loading = false;
                    return response;
                })
            )
            .catch(
                action(err => {
                    this.loading = false;
                    throw err;
                })
            );
    }

    save(values, isAdd) {
        this.updating = true;
        return agent.BizTypes.save(values, isAdd)
            .then(
                action(user => {
                    this.currentEntity = user;
                    return user;
                })
            )
            .catch(
                action(err => {
                    this.updating = false;
                    this.updatingErrors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            );
    }

    async remove(id) {
        await agent.BizTypes.remove(id);
        this.lastListLoadTime = new Date();
        this.deleteSuccess = true;
        return 1;
    }

    //for biztype_documents//
    currentBizTypeDocumentList = [];

    getbiztypeDocuments(id) {
        //calls get method from biztype_documents API//
        //takes 1 arguments, type - integer, id//
        //returns document list//
        return agent.BizTypes
            .getBizTypeDocumentsById(id)
            .then(
                action((list) => {
                    return list;
                })
            )
            .catch(
                action((error) => {
                    throw error;
                })
            );
    }

    addBizTypeDocument(data) {
        return agent.BizTypes.saveBizTypeDocuments(data).then(
            action((response)=>{
                return response;
            })
        )
        .catch(
            action((error)=>{
                throw error;
            })
        )
    }
}

const _BizTypeStore = new BizTypeStore();
export default _BizTypeStore;
