import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

//components//
import applicationRouter from '~/hoc/applicationRouter'
import MonitoringList from './MonitoringList';
import withRemoveDialog from '~/hoc/withRemoveDialog';

class Monitoring extends Component {
    //added state to maintain the genericList and genericForm states in parent component//
    constructor(props) {
        super(props);
        this.state = {
            mode: null,
            editId: 0,
            page: 0,
            historyId: 0,
            updateId: 0,
            isPageAddedOrEdited: false,
        };
        this.onAdd = this.onAdd.bind(this);
    }

    //removed URL pushing//
    onAdd() {
        this.props.router.navigate('/admin/monitor/add');
    }

    //method to maintain react-table page in parent component//
    handlePageChange = (page, isEdit) => {
        this.setState({ page: page, isPageAddedOrEdited: isEdit });
    }


    render() {
        const { page, isPageAddedOrEdited } = this.state;
        return (
            <div className="monitor-content">
                <MonitoringList
                    onAdd={() => this.onAdd()}
                    handlePageChange={(page, isEdit) => this.handlePageChange(page, isEdit)}
                    page={page}
                    isPageAddedOrEdited={isPageAddedOrEdited}
                />
            </div>
        );
    }
}
export default inject('authStore', 'userStore')(applicationRouter(withRemoveDialog(observer(Monitoring))));
