import React, { Component } from 'react';
import applicationRouter from '~/hoc/applicationRouter'
import { inject, observer } from 'mobx-react';
import VacationsList from './VacationsList';

class VacationsPage extends Component {
    constructor(props) {
        super(props)
        this.onAdd = this.onAdd.bind(this)
    }
    onAdd(page = 0, isEdit = true) {
        this.props.router.navigate('/admin/vacations/add', { state: { page, isEdit } });
    }
    render() {
        return (
            <VacationsList
                onAdd={this.onAdd}
            />
        );
    }
}
export default inject('authStore', 'vacationStore', 'userStore')(applicationRouter(observer(VacationsPage)));
