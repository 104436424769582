import React from 'react';
import { inject, observer } from 'mobx-react';

// Components
import applicationRouter from '~/hoc/applicationRouter';
import withLocalization from '~/hoc/withLocalization';
import ListErrors from '../../components/ListErrors';


class ForgotPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            errors: [],
            isMailSent: false
        };
    }

    componentWillUnmount() {
        const { authStore } = this.props;
        authStore.reset();
    }

    handleEmailChange = (e) => {
        this.setState({ email: e.target.value });
    };

    handleSubmitForm = (e) => {
        const { authStore } = this.props;
        e.preventDefault();
        authStore.resetPassword(this.state.email).then((result) => {
            if (result) {
                this.setState({ isMailSent: true });
            } else {
                this.setState({ errors: ['Incorrect email'] });
            }
        });
    };

    render() {
        const { t } = this.props;
        const { errors, isMailSent, email } = this.state;

        return (
            <div>
                <div className="fs16 forogottext">
                    {this.props.t(
                        'This will send you an email with reset password. Please check your email inbox'
                    )}.
                </div>
                {!isMailSent ?
                    <>
                        <ListErrors errors={errors} />
                        <form onSubmit={this.handleSubmitForm} className="login-container">
                            <div className="row mt-12">
                                <div className="col-md-12 col-lg-12 col-sm-12">
                                    <div className="form-group business-name">
                                        <label for="companyName" className="big-text">
                                            {t('Email')}
                                        </label>
                                        <label className="red-star">*</label>
                                        <p className="small-text st">{t('Add email address')}</p>
                                        <input
                                            type="text"
                                            value={email}
                                            className="form-control login-input mb_25 mt-12"
                                            placeholder={t('Email address')}
                                            required
                                            onChange={this.handleEmailChange}
                                        />
                                    </div>
                                </div>
                            </div>

                            <button className="btn btn-block fwBold fs18 loginbutton" type="submit">
                                {t('Submit')}
                            </button>
                        </form>
                    </>
                    :
                    <>
                        <div className="forgot-email-sent">
                            {this.props.t('Email has been sent. Please check your email inbox')}.
                        </div>
                    </>
                }

            </div>
        );
    }
}

export default inject('authStore')(applicationRouter(withLocalization(observer(ForgotPassword))));
