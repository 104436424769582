import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment/min/moment-with-locales'; // 'moment/min/moment-with-locales';

//components
import applicationRouter from '~/hoc/applicationRouter';
import withRemoveDialog from '~/hoc/withRemoveDialog';
import GenericFilter from '../../../components/GenericList/GenericFilter.js';
import withLocalization from '~/hoc/withLocalization';
import GenericList from '~/components/GenericList/GenericList';
import ServiceRequestsForm from './ServiceRequestsForm.js';
import ServiceRequestExtendForm from './ServiceRequestExtendForm.js';

//elements
import Button from '../../../components/CustomButton/CustomButton.jsx';

//utils
import { shortDateFormat } from '~/utils/dateFormat';
import { statusMap, absence_and_vacation_statuses } from '~/utils/normalStatuses';

//assets
import Edit from '../../../assets/img/editing.svg';
import Extend from '../../../assets/img/Extend.svg';
import Delete from '../../../assets/img/deleting.svg';
import CloseModalIcon from '../../../assets/img/CloseApproveModalIcon.svg'
import { confirmAlert } from 'react-confirm-alert';

const getColumns = (isCustomerMode, dateTimeRules, renderTableActionButtons) => {
    const col = [
        {
            Header: 'From Date | To Date',
            id: 'id',
            accessor: ({ from_date, to_date }) => `${shortDateFormat(from_date, dateTimeRules)} | ${shortDateFormat(moment(to_date), dateTimeRules)}`,
            isSortable: true,
        },
        {
            Header: 'Customer',
            id: 'project_email',
            accessor: ({ project_email }) => <span>{project_email}</span>,
            minWidth: 140,
            textAlign: 'start'
        },
        {
            Header: 'Project',
            id: 'project_name',
            accessor: ({ project_name }) => <span>{project_name}</span>,
            isSortable: true,
        },
        {
            Header: 'Service',
            id: 'task_name',
            accessor: ({ task_name }) => <span>{task_name}</span>,
            isSortable: true,
        },
        {
            Header: 'Total Member',
            id: 'no_of_member',
            accessor: ({ no_of_member }) => <span>{no_of_member}</span>,
        },
        {
            Header: 'Actions',
            id: 'actions',
            accessor: (full) => renderTableActionButtons(full.id, full, dateTimeRules),
        },
    ];

    if (isCustomerMode){
        col.splice(1, 1)
    }
    return col
}


class ServiceRequestsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showStatusUpdateModal: false,
            showExtendRequestModal: false,
            update_id: null
        };
    }

    handleTableButtonAction = (id, type, full) => {
        if (type === 'edit') this.props.router.navigate(`/customer/${id}`, { state: { customer: id } });
        if (type === 'remove') {
            let confirmText = 'Are you sure to delete an item?'
            if (full.status === 'approved')
                confirmText = 'You have shifts created through this service request. Deleting this service request will also delete the shifts. Are you sure?';
            confirmAlert({
                title: this.props.t('Confirm to delete'),
                message: this.props.t(confirmText),
                buttons: [
                    {
                        label: this.props.t('Yes'),
                        onClick: () => {
                            return this.props.serviceRequestStore.remove(id).then((res) => {
                                this.props.commonStore.addNotification(this.props.t('Deleted'), null, 'error');
                                this.props.router.navigate('/admin/serviceRequests');
                            });
                        },
                    },
                    {
                        label: this.props.t('No'),
                        onClick: () => {
                        },
                    },
                ],
            });
        }
        if (type === 'extend') {
            this.setState({ showExtendRequestModal: true, update_id: id });
        }
    };

    renderServiceRequestModal = (id) => {
        this.setState({
            showStatusUpdateModal: true,
            update_id: id
        });
    };

    handleCloseServiceRequestModal = () => {
        this.setState({
            showStatusUpdateModal: false
        });
    };

    handleCloseExtendRequestModal = () => {
        this.setState({ showExtendRequestModal: false });
    }

    renderTableActionButtons = (id, full, dateTimeRules) => {
        const user_type = this.props.userStore?.currentUser?.user_type ?? false;
        const { t } = this.props;
        let statusText = full.status;
        if (!statusText) statusText = 'active';
        statusText = statusMap[statusText] || '';
        return (
            <div className="actions-center actions-center__center">
                <div
                    className={`status-block status-${full.status || 'active'}${user_type === 'member' ? ' status-no-click-for-member' : ''
                        }`}
                    onClick={() => (user_type === 'pm' || user_type === 'admin') && this.renderServiceRequestModal(id)}
                >
                    {statusText.toLowerCase() === 'pending' || !(full.status_updated_by && full.status_updated_on) ?
                        <div className='text-capitalize'>{t(statusText)}</div>
                        :
                        <>
                            <div className="text-capitalize">
                                {full.status_updated_by &&
                                    moment(new Date(full.status_updated_on)).format(dateTimeRules?.short_date_format ? dateTimeRules?.short_date_format : 'DD-MM-YYYY')}
                            </div>
                            <div className="text-capitalize timelogs-text-shrading">
                                {full.status_updated_by_name}
                            </div>
                        </>
                    }
                </div>
                {user_type && user_type !== 'member' &&
                    <OverlayTrigger
                        key="view_project"
                        placement="top"
                        overlay={<Tooltip id="tooltip-top">{full.status === 'approved' ? `${t("Extend")} ${t("Request")}` : t('For Extending the service request, Please approve the service request and try again')}</Tooltip>}
                    >
                        <Button icon_sm fill onClick={() => this.handleTableButtonAction(id, 'extend')} disabled={full.status !== 'approved'}>
                            <img src={Extend} alt="extend" />
                        </Button>
                    </OverlayTrigger>
                }
                {!user_type &&
                    <OverlayTrigger
                        key="view_project"
                        placement="top"
                        overlay={<Tooltip id="tooltip-top">{t("Edit")} {t("Request")}</Tooltip>}
                    >
                        <Button icon_sm fill onClick={() => this.handleTableButtonAction(id, 'edit')}>
                            <img src={Edit} alt="edit" />
                        </Button>
                    </OverlayTrigger>
                }
                {user_type &&
                    <Button icon_sm_delete fill onClick={() => this.handleTableButtonAction(id, 'remove', full)}>
                        <img src={Delete} alt="delete" />
                    </Button>
                }
            </div>
        );
    };

    render() {
        const { t, commonStore, userStore, serviceRequestStore, authStore } = this.props;
        const { loading, filters, appliedFilters, lastListLoadTime } = serviceRequestStore;
        const { showExtendRequestModal, showStatusUpdateModal } = this.state;
        const user_type = userStore?.currentUser?.user_type;
        const { config } = commonStore;
        const dateTimeRules =
            config.client && config.client.data && config.client.data.dateTimeRules
                ? config.client.data.dateTimeRules
                : false;

        const cols = getColumns(!!window.sessionStorage.getItem('cusToken'), dateTimeRules, this.renderTableActionButtons)
        console.log(cols)

        return (
            <Fragment>
                <div className='d-flex'>
                    {window.sessionStorage.getItem('cusToken') &&
                        <div className='d-md-flex align-items-center custom-customer-header mb-md-0 mb-3'>
                            {t('Welcome')} <span className='fw-600 text-capitalize ps-1 pe-1'>{authStore?.custConfig?.customerDetails?.email},</span> {t('to The customer portal of')} <span className='text-capitalize fw-600 pe-1 ps-1'>{authStore?.custConfig?.clientDetails?.name}!</span>
                        </div>
                    }
                </div>
                <GenericList
                    tableHeader={this.props.t('Service Requests')}
                    columns={cols}
                    filters={appliedFilters}
                    lastListLoadTime={lastListLoadTime}
                    header={
                        <GenericFilter
                            filters={filters}
                            setFilter={(name, value) => serviceRequestStore.setFilter(name, value)}
                            onFilter={() => serviceRequestStore.onFilter()}
                            loading={loading}
                            hasProjectFilter={user_type && true}
                            hasStatusFilter={true}
                            statusOptions={absence_and_vacation_statuses}
                        />
                    }
                    requestData={(params) => serviceRequestStore.loadList({ ...params, user_type })}
                >
                    <OverlayTrigger
                        placement="bottom"
                        overlay={
                            <Tooltip id="tooltip-top">
                                {t("By clicking here, you can create a new service request")}
                            </Tooltip>
                        }
                    >
                        <i className="fa fa-info-circle text-muted d-flex align-items-center" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                    </OverlayTrigger>

                    <Button fill wd onClick={() => this.props.onAdd()}>
                        <i className="fa fa-plus-circle fa-xl margin-right-10" />
                        {t('Add new')}
                    </Button>
                </GenericList>

                <Modal
                    size="xl"
                    show={showStatusUpdateModal}
                    onHide={this.handleCloseServiceRequestModal}
                >
                    <Modal.Header className="set_ModalHeader">
                        <Modal.Title className='w-100 text-center custom-modal-title'>
                            {t('Request')} {t('Details')}
                        </Modal.Title>
                        <img
                            src={CloseModalIcon}
                            className="cursor-pointer"
                            onClick={() => this.handleCloseServiceRequestModal()}
                            alt='close-btn'
                        />
                    </Modal.Header>
                    <Modal.Body>
                        <ServiceRequestsForm
                            update_id={this.state.update_id}
                            handleCloseServiceRequestModal={this.handleCloseServiceRequestModal}
                            disableInput
                        />
                    </Modal.Body>
                </Modal>

                <Modal
                    size="xl"
                    show={showExtendRequestModal}
                    onHide={this.handleCloseExtendRequestModal}
                >
                    <Modal.Header className="set_ModalHeader">
                        <Modal.Title className='w-100 text-center custom-modal-title'>
                            {t('Request')} {t('Details')}
                        </Modal.Title>
                        <img
                            src={CloseModalIcon}
                            className="cursor-pointer"
                            onClick={() => this.handleCloseExtendRequestModal()}
                            alt='close-btn'
                        />
                    </Modal.Header>
                    <Modal.Body style={{ minHeight: '52vh' }} className='mb-4'>
                        <ServiceRequestExtendForm
                            handleCloseExtendRequestModal={this.handleCloseExtendRequestModal}
                            update_id={this.state.update_id}
                        />
                    </Modal.Body>
                </Modal>
            </Fragment>
        );
    }
}
export default inject(
    'userStore',
    'subscriptionStore',
    'serviceRequestStore',
    'commonStore',
    'authStore'
)(applicationRouter(withLocalization(withRemoveDialog(observer(ServiceRequestsList)))));