import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

//components
import applicationRouter from '~/hoc/applicationRouter'
import withLocalization from '~/hoc/withLocalization';
import TableWidget from '../TableWidget.js';


class TaskRules extends Component {
    constructor(props) {
        super(props);
        this.state = {
            taskRules: [],
        }
        this.loadData = this.loadData.bind(this);       
        this.handleUserDataChange = this.handleUserDataChange.bind(this);       
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = () => {
        const { currentEntity } = this.props.clientStore;
        if (currentEntity.client.data && currentEntity.client.data.task_statuses){
            const task_statuses = currentEntity.client.data.task_statuses;
            this.setState({                
                taskRules: task_statuses
            })
        }
    }

    handleUserDataChange = (name,value) =>{
        const {currentEntity} = this.props.clientStore;
        if(currentEntity.client.data.task_statuses){
            currentEntity.client.data.task_statuses=value;
        }
    }

    render() {
        const { taskRules } = this.state;
        const { t } = this.props;
        return (
            <div>
                <div className="mt-2 custom-card">
                    <h6>{t('Task statuses')}</h6>
                    <TableWidget
                        headers={['code', 'Label']}
                        metaData = {[{field:'code',type:'text',placeholder:'code', required: true, unique: true},
                            {field:'name',type:'text',placeholder:'Label', required: true, unique: true}]}                        
                        data={taskRules}
                        onChange = {data => this.handleUserDataChange('taskRules',data)}
                    />
                </div>
            </div>
        );
    }
}
export default inject('clientStore')(applicationRouter(withLocalization(observer(TaskRules))));
