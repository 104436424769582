import React,{useState} from "react";
import { Container, Row, Col} from "react-bootstrap";
import Header from "../../../newComponents/Header";
import Footer from "../../../newComponents/Footer";
import MainHeading from "../../../newComponents/MainHeading";
import PageBanner from "../../../newComponents/PageBanner";
import OfficeInfoCol from "../../../newComponents/OfficeInfo";
import ContactForm from "../../../newComponents/ContactForm";

import BannerImg from "../../../assets/images/contactImg.jpg";
import europeanImg from "../../../assets/images/europeanImg.png";
import unitedStatesImg from "../../../assets/images/unitedStatesImg.png";

//i18n
import { withTranslation } from "react-i18next"
import { inject, observer } from "mobx-react";
// import withLocalization from "../../../hoc/withLocalization";

const Contact = inject('authStore', 'commonStore')(observer((props) => {
  const [contactForm,setContactForm] = useState({
    company: '',
    fullName: '',
    email: '',
    phone: '',
    comment: '',
    accept: false,
})
 
const contactSubmit = (e) => {
    e.preventDefault();
    props.commonStore.sendContact(contactForm).then(() => {
        props.commonStore.addNotification(
            props.t('Your message has been sent, thank you.'),
            null,
            'success'
        );
        setContactForm(
            {
                company: '',
                fullName: '',
                email: '',
                phone: '',
                comment: '',
                accept: false,
        });
        alert(props.t('Your message has been sent, thank you.'));
    });
};

const handleFormChange = (name, value) => {
  setContactForm({...contactForm,[name]:value})
};
  return (
    <>
      <div className="landingPageWrapp frameworkApp">
        <Header contact="current" />
        <article className="pageBannerBlock grdntBg lp_wrapper py-60">
          <Container>
            <Row className="g-4 align-items-center">
              <Col lg>
                <MainHeading
                  title={<>{props.t("Connect")} <span>{props.t("With Us")}</span></>}
                  description={props.t("Have Queries? Get in touch with us using any of the below-provided resources and avail the best assistance from our experts!")}
                />
              </Col>
              <Col lg={1}></Col>
              <Col lg="auto">
                <PageBanner
                  src={BannerImg}
                  alt="Visitor Register"
                  customClass="imgBorder"
                />
              </Col>
            </Row>
          </Container>
        </article>
        <article className="cntctLctnBlock lp_wrapper py-60">
          <Container>
            <MainHeading
              title={props.t("Our offices")}
              customClass="noPara text-center medium"
            />
            <Row className="g-4">
              <Col md>
                <OfficeInfoCol
                  src={europeanImg}
                  name="EU"
                  location="Solgaardskog 1 1599 Moss, Norway"
                />
              </Col>
              <Col md>
                <OfficeInfoCol
                  src={unitedStatesImg}
                  name="USA"
                  location="70 East Old Country Road, Hicksville, NY 11801"
                />
              </Col>
            </Row>
          </Container>
        </article>
        <article className="cntctFormBlock grdntBg lp_wrapper py-80">
          <Container>
            <div className="cntctFormRow contentInfo">
              <h3 className="text-center">{props.t("Reach Out to Us")}</h3>
              <p className="text-center mb-4">{props.t("If you are thinking about using Time & Control as your business management system or if you simply want more information, we would be pleased to assist you.")}</p>
              <ContactForm onSubmit={contactSubmit} handleFormChange={handleFormChange} contactForm={contactForm}/>
            </div>
          </Container>
        </article>
        <Footer/>
      </div>
    </>
  );
}))
export default withTranslation()(Contact)