import { observable, action, makeObservable } from 'mobx';
import agent from '../agent';
import _ from 'lodash';

class propertyStore {
    constructor() {
        makeObservable(this, {
            isActive: observable,
            activeWidget: observable,
            widgets: observable,
            activeRecipient: observable,
            setActiveRecipient: action,
            closeActivePanel: action,
            setActiveWidget: action,
            addWidget: action,
            updateWidgetRnd: action,
            updateWidgetProperty: action,
            fetchAllWidget: action,
            deleteWidget: action,
            updateWidgetData: action,
            deleteWidgetByRecipientId: action,
            deleteWidgetByPageId: action,
            saveWidget: action,
            resetProperties: action
        });
    }

    isActive = false;
    activeWidget = null;
    widgets = [];
    activeRecipient = null;

    setActiveRecipient(recipient) {
        this.activeRecipient = recipient;
    }

    closeActivePanel() {
        this.activeWidget = null;
    }

    setActiveWidget(widget) {
        this.activeWidget = widget;
    }

    addWidget(widgetInfo) {
        if (widgetInfo.length) {
            this.widgets.push(...widgetInfo);
        } else {
            this.widgets.push(widgetInfo);
        }
        this.activeWidget = _.cloneDeep(widgetInfo);
    }

    updateWidgetRnd(id, widgetInfo) {
        let index = this.widgets.findIndex((widget) => widget.widgetId === id);
        let widgetData = {
            height: widgetInfo.height,
            width: widgetInfo.width,
            widgetX: widgetInfo.x,
            widgetY: widgetInfo.y,
        };
        this.widgets[index] = { ...this.widgets[index], ...widgetData };
        this.activeWidget = this.widgets[index];
    }

    updateWidgetProperty(data) {
        const activeWidget = _.cloneDeep(this.activeWidget);

        for (let key in data) {
            if (activeWidget.hasOwnProperty(key)) {
                activeWidget[key] = data[key];
            }
        }

        const index = this.widgets.findIndex((widget) => widget.widgetId === this.activeWidget.widgetId);

        this.widgets[index] = activeWidget;
    }
    fetchAllWidget(data) {
        return agent.Signature.fetchAllDocs(data)
            .then(
                action((res) => {
                    if (res.sign_document.widgets) {
                        this.widgets = res.sign_document.widgets;
                    }
                    return res;
                })
            )
            .catch(
                action((err) => {
                    throw err;
                })
            );
    }
    deleteWidget(id) {
        this.widgets = this.widgets.filter((widget) => widget.widgetId !== id);
    }

    updateWidgetData(wid, field, data) {
        const index = this.widgets.findIndex((widget) => widget.widgetId === wid.widgetId);
        this.widgets[index] = { ...this.widgets[index], [field]: data };
    }

    deleteWidgetByRecipientId(id) {
        this.widgets = this.widgets.filter((widget) => widget.recipientId !== id);
    }
    deleteWidgetByPageId(id) {
        this.widgets = this.widgets.filter((widget) => widget.pageId !== id);
    }

    saveWidget(data) {
        return agent.Signature.addWidgets(data)
            .then(
                action((widget) => {
                    // this.currentEntity = user.shift;
                    return widget;
                })
            )
            .catch(
                action((err) => {
                    // this.updating = false;
                    this.updatingErrors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            );
    }

    resetProperties() {
        this.activeWidget = null;
        this.widgets = [];
        this.activeRecipient = null;
    }
}

const _propertyStore = new propertyStore();
export default _propertyStore;
