import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import withLocalization from '../../../../hoc/withLocalization';
import { Rnd } from 'react-rnd';



export class LabelWidget extends Component {
    constructor(props) {
        super(props);
        this.state = {
            x: this.props.info.widgetX || 0,
            y: this.props.info.widgetY || 0,
            width: this.props.info.width || '140px',
            height: this.props.info.height || '45px',
        }
    }

    setActiveWidget(evt, widget) {
        const { propertyStore } = this.props;
        propertyStore.closeActivePanel();
        propertyStore.setActiveWidget(widget)

    }
    onDragStop = (evt, position, at) => {
        const { propertyStore, info } = this.props;
        const DEFAULT_WIDTH = 150;
        const DEFAULT_HEIGHT = 50;
        const updatedWidth = (propertyStore.activeWidget && propertyStore.activeWidget.width) ? propertyStore.activeWidget.width : ( this.state.width ? this.state.width : DEFAULT_WIDTH);
        const updatedHeight = (propertyStore.activeWidget && propertyStore.activeWidget.height) ? propertyStore.activeWidget.height : ( this.state.height ? this.state.height : DEFAULT_HEIGHT);
        const widInfo = { x: position.x, y: position.y,width:updatedWidth, height:updatedHeight  };
        propertyStore.updateWidgetRnd(info.widgetId, widInfo)
    }

    onResizeStop = (e, direction, ref, delta, position) => {
        const widInfo = { x: position.x, y: position.y, width: ref.style.width, height: ref.style.height }

        const { propertyStore, info } = this.props;
        propertyStore.updateWidgetRnd(info.widgetId, widInfo)
    }

    hexToRgbA(hex) {
        var c;
        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
            c = hex.substring(1).split('');
            if (c.length == 3) {
                c = [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c = '0x' + c.join('');
            return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',.4)';
        }
        throw new Error('Bad Hex');
    }

    render() {
        const { x, y, width, height } = this.state;
        const data = this.props.info.data ? this.props.info.data : '';
        const widgetColor = this.hexToRgbA(this.props.info ? this.props.info.colorCode : "#ffd65b");
        return (
            <>
                <Rnd
                    minHeight={45}
                    minWidth={100}
                    default={{
                        x,
                        y,
                        width,
                        height
                    }}
                    onDragStop={this.onDragStop}
                    onResizeStop={this.onResizeStop}
                    bounds="parent"
                    disableDragging={this.props.locked}
                    enableResizing={!this.props.locked}

                >
                    <div className="TextboxCon-date" onClick={(evt) => { this.setActiveWidget(evt, this.props.info) }}>
                        <input
                         value={data}
                         disabled={true} 
                         style={this.props.view ? {border: 'none', overflow: 'hidden', background: 'transparent'} : this.props.owner ? { backgroundColor: 'transparent' } :  { backgroundColor: widgetColor }}
                        />
                    </div>
                </Rnd>
            </>
        )

    }
}

export default inject('propertyStore', 'signatureStore')(withLocalization(observer(LabelWidget)));