import React from "react";
import "./styles.css";

function Office(props) {
  return (
    <div className="officeInfoCol">
      <div className="officeInfoImg">
        <img src={props.src} alt={props.name} />
      </div>
      <div className="officeInfoDes">
        <h3>{props.name}</h3>
        <p>{props.location}</p>
      </div>
    </div>   
  );
}
export default Office;