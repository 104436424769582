import React, { Component } from 'react';
import applicationRouter from '~/hoc/applicationRouter'
import { inject, observer } from 'mobx-react';

import BizTypesList from './BizTypesList';
import BizTypesForm from './BizTypesForm';

import withRemoveDialog from '~/hoc/withRemoveDialog';

class BizTypesPage extends Component {
    onAdd() {
        const { history } = this.props;
        this.props.router.navigate('/admin/biztypes/add');
    }

    // onTableAction(id, type) {        
    //     const { history,commonStore,t } = this.props;
    //     if (type === 'remove') 
    //         return this.props.handleDelete(id, id => this.props.biztypeStore.remove(id).then(res =>{
    //             commonStore.addNotification(t('Deleted'), null, 'error');
    //             this.props.router.navigate('/admin/biztypes/');
    //         }));            
              
    //         this.props.router.navigate(`/admin/biztypes/${id}`);
    // }

    // editingId = () => {
    //     let id = null;
    //     if (this.props.location.pathname !== this.props.match.path) {
    //         id = this.props.location.pathname.replace(this.props.match.path, '');
    //         id = id.replace('/', '');
    //     }
    //     return { editing: !!id, add: id === 'add', id };
    // };

    render() {
        // const mode = this.editingId();
        return (
            <>
                {/* {!mode.editing && ( */}
                    <BizTypesList
                        // onTableAction={(id, type) => this.onTableAction(id, type)}
                        onAdd={() => this.onAdd()}
                    />
                {/* )} */}
                {/* {mode.editing && <BizTypesForm id={mode.id} add={mode.add} />} */}
            </>
        );
    }
}
export default inject('authStore', 'biztypeStore')(applicationRouter(withRemoveDialog(observer(BizTypesPage))));
