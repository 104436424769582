import React, { Component } from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

export class FlexCard extends Component {
    render() {
        const {
            title, desc, children, footer, icon, additionalImg
        } = this.props;
        return (
            <div className="flex-card">
                <div className="flex-card__header-wrap">
                    <div className="flex-card__header">
                        {!!title && 
                            <div className='d-flex align-items-center justify-content-between'>
                                <h5 className="flex-card__title">{title}</h5>
                                {!!additionalImg && additionalImg}
                            </div>
                        }
                        {!!desc && <span className="flex-card__desc" title={desc}>{desc}</span>}
                    </div>
                    {!!icon && <div className="flex-card__header-icon">{icon}</div>}
                </div>
                {!!children && <div className="flex-card__content">{children}</div>}
                {!!footer && <div className="flex-card__footer">{footer}</div>}
            </div>
        );
    }
}

export default FlexCard;
