import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

import Header from "../../../newComponents/Header";
import Footer from "../../../newComponents/Footer";
import MainHeading from "../../../newComponents/MainHeading";
import PageBanner from "../../../newComponents/PageBanner";
import Feature from "../../../newComponents/Feature2";
import Contact from "../../../newComponents/Contact";

import BannerImg from "../../../assets/images/digitalSignatureImg.png";
import AppleIcon from "../../../assets/images/appleIcon.svg";
import GoogleIcon from "../../../assets/images/googleIcon.svg";
import ValuableLogo from "../../../assets/images/valuableLogo.png";
import Icon1 from "../../../assets/images/manageIcon1.svg";
import Icon2 from "../../../assets/images/pdfIcon.svg";
import Icon3 from "../../../assets/images/privacyPolicyIcon.svg";
import Icon4 from "../../../assets/images/allAppIcon.svg";
import Icon5 from "../../../assets/images/blockChainIcon.svg";
import digitalSignatureImg2 from "../../../assets/images/digitalSignatureImg2.png";
import digitalSignatureMainImg from "../../../assets/images/digitalSignatureMainImg.png";
import digitalSignatureImg3 from "../../../assets/images/digitalSignatureImg3.png";
import RegisterImg from "../../../assets/images/registerImg.png";
import TCLogo from "../../../assets/images/tcLogo.svg";

//i18n
import { withTranslation } from "react-i18next"
import { inject, observer } from "mobx-react";

import config from "../../../library/config";

const DigitalSignature = inject('authStore', 'commonStore')(observer((props) => {
  return (
    <>
      <div className="landingPageWrapp frameworkApp">
        <Header features="current" />
        <article className="pageBannerBlock grdntBg noPatt  py-60 pb-0">
          <Container>
            <Row className="g-4 align-items-end">
              <Col lg={6}>
                <div className="py-60 pt-0">
                  <MainHeading
                    title={<>{props.t("Digital")} <span>{props.t("Signature")}</span></>}
                    description={props.t("Make work faster, verify offer letters, and authenticate reports alongside the assessment and acceptance of agreements with the ultimate digital signature attribute of Time & Control. Utilization of this smart and optimal solution will not only eliminate all the legwork required for document signing but also make the process seamless, expeditious, and secure.")}
                  />
                  <div className="appBtns mt-4">
                    <Link onClick={e => config.redirectToAppStore(e, "apple")} className="btnCommon btnBig w-auto me-3 mb-2"><i className="d-inline-block pe-2"><img src={AppleIcon}  alt="Apple" /></i> {props.t("Apple")}</Link>
                    <Link onClick={e => config.redirectToAppStore(e, "google")} className="btnCommon btnTrans btnBig btnBlackTrans w-auto"><i className="d-inline-block pe-2"><img src={GoogleIcon} alt="Apple" /></i> {props.t("Google")}</Link>
                  </div>
                </div>
              </Col>
              <Col lg={6}>
                <PageBanner
                  src={BannerImg}
                  alt="Digital Signature"
                />
              </Col>
            </Row>
          </Container>
          <div className="valuableLogo">
            <img src={ValuableLogo} alt="" />
          </div>
        </article>
        <article className="feau2Block  py-80">
          <Container>
            <div className="contentInfo text-center">
              <h5 className="m-0">{props.t("There’s No Better Way Than This")}</h5>
            </div>
            <MainHeading
              title={<>{props.t("Smart Digital")}<span> {props.t("Signatures")}</span></>}
              description={props.t("Speed Up the Work and Increase Efficiency")}
              customClass="medium text-center"
            />
            <Row className="g-4 align-items-center">
              <Col md={6}>
                <Feature
                  src={Icon1}
                  title={props.t("Manage it, Sign it, Save it")}
                  description={props.t("Get signatures from anywhere, anytime and authenticate the documentation in a jiffy. Time & Control gives you the power to complete agreements and never miss any document, as everything is securely stored on the server.")}
                  color="blue"
                />
              </Col>
              <Col md={6}>
                <Feature
                  src={Icon2}
                  title={props.t("Sign PDF or Word Docs")}
                  description={props.t("Upload PDFs or Word documents on the server and send them for a seamless signature process. Time & Control Digital Signature is built for a modern and distributed organization allowing you to get things done quickly and efficiently.")}
                  color="orange"
                />
              </Col>
              <Col md={6}>
                <Feature
                  src={Icon3}
                  title={props.t("Private and Confidential")}
                  description={props.t("We respect your privacy and have built a system to ensure the confidentiality of the documents sent via Time & Control. Our ESIGN and UETA-compliant system enable us to function across borders and ensure that every bit of information is encrypted.")}
                  color="green"
                />
              </Col>
              <Col md={6}>
                <Feature
                  src={Icon4}
                  title={props.t("All-Encompassing App")}
                  description={props.t("One platform within Time & Control allows you to get digital signatures from a wide gamut of tasks and processes. Use our solution to get signatures on contracts, offer letters, agreements, appointment letters, and much more.")}
                  color="pink"
                />
              </Col>
              <Col md={6}>
                <Feature
                  src={Icon5}
                  title={props.t("Blockchain Enabled")}
                  description={props.t("To enhance the security and sanctity of your documents, we use blockchain technology to track, verify, and secure all documents. With blockchain, we can increase the speed of work and reduce compliance costs.")}
                  color="red"
                />
              </Col>
              <Col md={6}>
                <div className="prsnImg">
                  <img src={digitalSignatureImg2} alt="" />
                </div>
              </Col>
            </Row>
          </Container>
        </article>
        <article className="abtInfoBlock noPatt ">
          <Container>
            <div className="abtMainImg mt-4 mt-lg-5" style={{backgroundImage: `url(${digitalSignatureMainImg})`}}></div>
          </Container>
        </article>
        <article className="whyBlock grdntBg  py-80">
          <Container>
            <Row className="g-4 align-items-center">
              <Col lg={6} xl={6}>
                <div className="text-center">
                  <img src={digitalSignatureImg3} alt="" />
                </div>
              </Col>
              <Col lg={6} xl={6}>
                <div className="contentInfo mb-3">
                  <h5>{props.t("Premium Digital Signature Solution")}</h5>
                </div>
                <MainHeading
                  title={<>{props.t("Why")} <span>{props.t("use Digital Signature?")}</span></>}
                  customClass="medium noPara mb-4"
                />
                <div className="list">
                  <ul>
                    <li>{props.t("No need to reserve documents manually.")}</li>
                    <li>{props.t("Cut short on the printing and filing expenditures.")}</li>
                    <li>{props.t("Cultivate automatic backups of all the documents.")}</li>
                    <li>{props.t("Seamless authentication approach for all industries.")}</li>
                    <li>{props.t("Amplify long-term client relationships.")}</li>
                    <li>{props.t("Sign documents that include legal validity & lawful binding.")}</li>
                    <li>{props.t("Boost work flexibility, efficiency and productivity.")}</li>
                    </ul>
                </div>
                <div className="appBtns mt-4 pt-2">
                  <Link onClick={e => config.redirectToAppStore(e, "apple")} className="btnCommon btnBig w-auto me-3 mb-2"><i className="d-inline-block pe-2"><img src={AppleIcon}  alt="Apple" /></i> {props.t("Apple")}</Link>
                  <Link onClick={e => config.redirectToAppStore(e, "google")} className="btnCommon btnTrans btnBig btnBlackTrans w-auto"><i className="d-inline-block pe-2"><img src={GoogleIcon} alt="Apple" /></i> {props.t("Google")}</Link>
                </div>
              </Col>
            </Row>
          </Container>
        </article>
        <article className="rgstrBlock py-60 mb-5">
          <Container>
            <div className="rgstrInrBlock grdntBg">
              <Row className="g-4 g-lg-5 align-items-center">
                <Col lg={5}>
                  <div className="rgstrImg">
                    <img src={RegisterImg} alt="Registration" />
                  </div>
                </Col>
                <Col lg={7}>
                  <div className="mb-4">
                    <img src={TCLogo} alt="TC Logo" />
                  </div>
                  <MainHeading
                    title={props.t("Go Extra Mile and Reach Desired Success With Our Solution")}
                    customClass="medium noPara m-0"
                  />
                  <div className="contentInfo">
                    <p className="m-0">{props.t("Save Extra Costs, Time and Work")}</p>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </article>
        <Contact/>
        <Footer/>
      </div>
    </>
  );
}))
export default withTranslation()(DigitalSignature);