import React from "react";
import { Link } from "react-router-dom";
import "./styles.css";

import ArrowIcon from "../../assets/images/arrowIcon.svg";

//i18n
import { withTranslation } from "react-i18next"

function PReason(props) {
  return (
    <div className={`reasonCol ${props.customClass}  ${props.color}`}>
      <div className="reasonColIcon">
        <i><img src={props.src} alt={props.title} /></i>
      </div>
      <div className="reasonColInfo">
        <h4>{props.title}</h4>
        <p>{props.description}</p>
        <Link className="d-none" to={props.link}><span>{props.t("Learn More")}</span> <img src={ArrowIcon} alt="Arrow" /></Link>
      </div>
    </div>   
  );
}
export default withTranslation()(PReason);