import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { inject, observer } from 'mobx-react';

//components
import withLocalization from '~/hoc/withLocalization';

//elements
import LoadingSpinner from '~/elements/LoadingSpinner';

//utils
import { getFormattedAddress } from '../../../utils/commonMethods';
import { getAddressFormat } from '../../../utils/dateFormat';

//assets
import AddressBookIcon from '~/assets/img/AddressBookIcon.svg';
import TaskIcon from '~/assets/img/TaskTemplate.svg'
import MapTaskIcon from '~/assets/img/MapTask.svg'
import ProjectIcon from '~/assets/img/new_design/jsx_adminnavbar_svg/brief_case.svg';

function TaskViewDetailCard({ icon, title, dynoCol, subtext }) {
    return (
        <Row>
            <Col md={dynoCol ? dynoCol : 2} className={'d-flex align-items-center p-0'}>
                {icon &&
                    <img src={icon} alt='taskTemplate' width={25} height={25} />
                }
            </Col>
            <Col md={dynoCol ? 12 - dynoCol : 10}>
                <Row>
                    <span title={title} className='task_template_view_texts text-overflow'>
                        {title}
                    </span>
                </Row>
                <Row>
                    <span title={subtext} className='task_template_view_sub_texts text-overflow'>
                        {subtext}
                    </span>
                </Row>
            </Col>
        </Row>
    )
}
class TaskTemplateForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            projectId: props.projectId,
            taskTemplateUUID: props.taskTemplateId,
            taskTemplateView: {}
        }
    }

    componentDidMount() {
        this.loadData();
    }

    async loadData() {
        const { projectId, taskTemplateUUID } = this.state;
        const { taskTemplateStore } = this.props;
        const data = await taskTemplateStore.loadTempletView(taskTemplateUUID, projectId);
        this.setState({ taskTemplateView: data.taskTemplate }, () => this.setState({ isLoading: false }))
    }

    render() {
        const { t, commonStore } = this.props;
        const { config } = commonStore;
        const { projectId, taskTemplateUUID, isLoading, taskTemplateView } = this.state;

        if (!projectId || !taskTemplateUUID) return <>{t("Missing Id")}</>

        if (isLoading) <LoadingSpinner />

        const dateTimeRules = config.client && config.client.data && config.client.data.dateTimeRules ? config.client.data.dateTimeRules : false;
        const taskTemplateAddress = getFormattedAddress({
            post_number: taskTemplateView.post_number,
            post_place: taskTemplateView.post_place,
            address: taskTemplateView.address
        }, getAddressFormat(dateTimeRules))


        return (
            <div className='px-5 py-2'>
                <Row >
                    <Col md={6}>
                        {TaskViewDetailCard({
                            icon: ProjectIcon,
                            title: `${t('Project')} ${t('Name')}`
                            , subtext: taskTemplateView.project
                        })}
                    </Col>
                    <Col md={6}>
                        {TaskViewDetailCard({
                            icon: TaskIcon,
                            title: `${t('Task')} ${t('Name')}`,
                            subtext: taskTemplateView.name
                        })}
                    </Col>

                </Row>
                <Row className='mt-4 border-top-fat-line'>
                    <Col xs={12} md={6} className='mt-3'>
                        {TaskViewDetailCard({
                            icon: MapTaskIcon,
                            title: t('Location'),
                            subtext: taskTemplateAddress
                        })}
                    </Col>
                </Row>
                <Row className='mt-4 border-top-fat-line'>
                    <Col xs={12} className='mt-3'>
                        {TaskViewDetailCard({
                            dynoCol: 1,
                            icon: AddressBookIcon,
                            title: `${taskTemplateView?.task_members?.split(',').length || ""} ${t('Members')} ${t('Assigned')}`,
                            subtext: taskTemplateView.members
                        })}
                    </Col>
                </Row>
            </div>

        )
    }

}

export default inject('taskTemplateStore', 'commonStore')(withLocalization(observer(TaskTemplateForm)));