import { observable, action, computed, makeObservable } from 'mobx';

import agent from '../agent';

const currentEntityInit = {
    status: 'new',
    doc_type: 'internal',
    data: { disableAvailableForEverybody: true },
};
class ExtraDocStore {
    currentList = [];

    currentEntity = currentEntityInit;

    updatingErrors = null;

    updating = false;

    loading = false;

    requestParams = null;

    deleteSuccess = false;

    lastListLoadTime = null;

    filters = {
        name: '',
    };

    appliedFilters = {
        name: '',
    };

    constructor() {
        makeObservable(this, {
            currentList: observable,
            currentEntity: observable,
            updatingErrors: observable,
            updating: observable,
            loading: observable,
            requestParams: observable,
            deleteSuccess: observable,
            lastListLoadTime: observable,
            filters: observable,
            appliedFilters: observable,
            getPreparedData: computed,
            setFilter: action,
            onFilter: action,
            loadList: action,
            load: action,
            save: action,
            remove: action,
            resetLastListLoadTime: action,
            handleChange: action,
            handleChangeFull: action,
            returnDefaultNew: action
        });
    }

    get getPreparedData() {
        const {
            id, name, attachments, description, doc_type
        } = this.currentEntity;
        const data = this.currentEntity.data || {};

        const { history, ...preparedData } = data;
        return {
            id,
            name,
            attachments,
            description,
            doc_type,
            data: preparedData,
        };
    }

    setFilter(name, value) {
        const filters = Object.assign({}, this.filters);
        filters[name] = value;
        this.filters = filters;
    }

    onFilter() {
        this.appliedFilters = Object.assign({}, this.filters);
    }

    loadList(params, mode) {
        params.mode = mode;
        return agent.ExtraDoc.list(params)
            .then(
                action(list => {
                    this.requestParams = params;
                    list.time = new Date();
                    this.lastListLoadTime = list.time;
                    this.currentList = list;
                    return list;
                })
            )
            .catch(
                action(err => {
                    throw err;
                })
            );
    }

    load(id) {
        this.loading = true;
        return agent.ExtraDoc.load(id)
            .then(
                action(({ extradoc }) => {
                    this.currentEntity = extradoc;
                    this.loading = false;
                })
            )
            .catch(
                action(err => {
                    this.returnDefaultNew();
                    this.loading = false;
                    throw err;
                })
            );
    }

    save(values, isAdd) {
        this.updating = true;
        if (!values.data) values.data = {};

        return agent.ExtraDoc.save(values, isAdd)
            .then(
                action(({ extradoc }) => {
                    this.currentEntity = extradoc;
                    this.updating = false;
                    return extradoc;
                })
            )
            .catch(
                action(err => {
                    this.updating = false;
                    throw err.response && err.response.body && err.response.body.error;
                })
            );
    }

    async remove(id) {
        await agent.ExtraDoc.remove(id);
        this.deleteSuccess = true;
        return 1;
    }

    resetLastListLoadTime() {
        this.lastListLoadTime = new Date();
    }

    handleChange = (name, value) => {
        // const extradoc = { ...this.currentEntity };
        const extradoc = JSON.parse(JSON.stringify(this.currentEntity));
        if (name.split('.').length === 1) {
            extradoc[name] = value;
        }
        if (name.split('.').length === 2) {
            const firstName = name.split('.')[0];
            const secondName = name.split('.')[1];
            if (!(extradoc[firstName])) extradoc[firstName] = {};
            extradoc[firstName][secondName] = value;
        }
        this.currentEntity = JSON.parse(JSON.stringify(extradoc));
    };

    handleChangeFull(obj = {}) {
        this.currentEntity = { ...this.currentEntity, ...obj };
    }

    returnDefaultNew(params) {
        if (params) {
            this.currentEntity = {
                ...JSON.parse(JSON.stringify(currentEntityInit)),
                ...JSON.parse(JSON.stringify(params)),
            };
        } else {
            this.currentEntity = {
                ...JSON.parse(JSON.stringify(currentEntityInit)),
                // ...JSON.parse(JSON.stringify(params)),
            };
        }
        this.loading = false;

        return this.currentEntity;
    }
}

const _ExtraDocStore = new ExtraDocStore()
export default _ExtraDocStore;
