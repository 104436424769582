import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Header from "../../../newComponents/Header";
import Footer from "../../../newComponents/Footer";
import MainHeading from "../../../newComponents/MainHeading";
import PageBanner from "../../../newComponents/PageBanner";
import InfoBox from "../../../newComponents/InfoBox";
import Feature from "../../../newComponents/Feature";

import BannerImg from "../../../assets/images/aboutImg.png";
import CompleteSolutionsImg from "../../../assets/images/completesSolutionsImg.png";
import MissionIcon from "../../../assets/images/missionIcon.png";
import AboutMainImg from "../../../assets/images/aboutBigImg.jpg";
import TimeIcon from "../../../assets/images/timeIcon.svg";
import ScheduleIcon from "../../../assets/images/scheduleIcon.svg";
import MessageIcon from "../../../assets/images/messageIcon.svg";
import GlobeImg from "../../../assets/images/globeImg.png";
import SimplicityIcon from "../../../assets/images/simplicityIcon.png";
import BestPracticeIcon from "../../../assets/images/bestPracticeIcon.png";

//i18n
import { withTranslation } from "react-i18next"
import { inject, observer } from "mobx-react";

const About = inject('authStore', 'commonStore')(observer((props) => {
  return (
    <>
      <div className="landingPageWrapp frameworkApp">
        <Header about="current" />
        <article className="pageBannerBlock abtBtnBlk  grdntBg lp_wrapper py-60 pb-0">
          <Container>
            <Row className="g-4 align-items-center">
              <Col lg>
                <MainHeading
                  title={<>{props.t("About")} <span>{props.t("Us")}</span></>}
                  description={props.t("We are a team of experts who are working professionals to offer optimal business solutions like Time & Control for HR & Workforce Management system and seamless functionality. Take advantage of our amazing services to efficiently manage your projects with minimum risk and high revenues in all fields")}
                />
              </Col>
              <Col lg={1}></Col>
              <Col lg="auto">
                <PageBanner
                  src={BannerImg}
                  alt="Visitor Register"
                />
              </Col>
            </Row>
          </Container>
        </article>
        <article className="abtInfoBlock lp_wrapper py-60 pb-0">
          <Container>
            <Row className="g-4 align-items-center">
              <Col lg={6}>
                <MainHeading
                  title={props.t("What We Provide?")}
                  description={props.t("Your customer relies on you to provide timely updates on the project and reach their deadlines. However, unconnected systems are the root cause of errors and change orders. Here is where we come up with our innovative ideas such as Time & Control that only simplifies procedures, and promptly handles schedule changes, but also ameliorates other organizational processes.")}
                  customClass="medium"
                />
              </Col>
              <Col lg={6}>
                <div className="text-center">
                  <img src={CompleteSolutionsImg} alt="Time and Control Provides Completes Solutions" />
                </div>
              </Col>
            </Row>
            <Row className="g-4 g-xxl-5 align-items-center mt-2">
              <Col lg={6}>
                <InfoBox
                  src={MissionIcon}
                  title={props.t("Our Vision")}
                  description={props.t("We help organizations to manage their processes more quickly and accomplish their objectives effectively. Our main vision is to offer the ultimate solution that helps to do all manual management processes within an instant time with Automatic process.")}
                />
              </Col>
              <Col lg={6}>
                <InfoBox
                  src={MissionIcon}
                  title={props.t("Our Goal")}
                  description={props.t("Our primary goal is to assist the industries in the utilization of their resources, increase safety, and effective maintenance of compliance and other processes while working in all fields.")}
                />
              </Col>
            </Row>
            <div className="abtMainImg mt-4 mt-lg-5" style={{backgroundImage: `url(${AboutMainImg})`}}></div>
          </Container>
        </article>
        <article className="whyBlock grdntBg lp_wrapper py-80">
          <Container>
            <MainHeading
              title={props.t("Why Time & Control?")}
              description={props.t("To manage the workforce and resources with ease, our Time & Control software comes into action in any organization. Below are some of the key reasons to choose it.")}
              customClass="text-center medium"
            />
            <Row className="g-4">
              <Col lg={6} xl={3}>
                <Feature
                  src={TimeIcon}
                  title={props.t("Easy Accessibility")}
                  description={props.t("Get easy access to the real-time updates of your team while continuing working to save time and cost.")}
                  link="/"
                  color="blue"
                  customClass="noLink"
                />
              </Col>
              <Col lg={6} xl={3}>
                <Feature
                  src={ScheduleIcon}
                  title={props.t("Go Paperless")}
                  description={props.t("Go digital allows businesses to streamline processes & better security of important documents while contracting & collaborating.")}
                  link="/"
                  color="orange"
                  customClass="noLink"
                />
              </Col>
              <Col lg={6} xl={3}>
                <Feature
                  src={MessageIcon}
                  title={props.t("Easy Installation")}
                  description={props.t("Easy to install and free to opt within the sophisticated gadgets to get essential management information with few clicks from anywhere.")}
                  link="/"
                  color="green"
                  customClass="noLink"
                />
              </Col>
              <Col lg={6} xl={3}>
                <Feature
                  src={MessageIcon}
                  title={props.t("High Collaboration")}
                  description={props.t("From contract to closing projects, move more quickly with its real-time cooperation, which also helps to gather critical metadata along the way.")}
                  link="/"
                  color="pink"
                  customClass="noLink"
                />
              </Col>
            </Row>
          </Container>
        </article>
        <article className="abtInfoBlock lp_wrapper py-80">
          <Container>
            <Row className="g-4 align-items-center">
              <Col lg={6}>
                <MainHeading
                  title={props.t("We would love to take care of you.")}
                  description={props.t("We comprehend and adore that there is more to life. Our efforts with Time and Control features are as applicable to keep your projects organized, and on schedule which contributes to your work and life balance too.")}
                  customClass="medium"
                />
              </Col>
              <Col lg={6}>
                <div className="text-center">
                  <img src={GlobeImg} alt="Time and Control Provides Completes Solutions" />
                </div>
              </Col>
            </Row>
            <Row className="g-4 g-lg-5 align-items-center mt-2">
              <Col lg={6}>
                <InfoBox
                  src={SimplicityIcon}
                  title={props.t("User Friendliness")}
                  description={props.t("We make every possible effort to make our platform adaptive for a better user experience. By analyzing the customer feedback, current market needs, and need for convenience, we blend everything to develop an easy-to-use application for our users.")}
                />
              </Col>
              <Col lg={6}>
                <InfoBox
                  src={BestPracticeIcon}
                  title={props.t("Latest Trends")}
                  description={props.t("We adopt the latest trends and functionality when developing apps so that it meets the modern needs of our current users. Our apps are quite pleasing and next-gen to use. We analyzed the current market trends and competitors to bring a solution that is avant-garde.")}
                />
              </Col>
            </Row>
          </Container>
        </article>
        <Footer/>
      </div>
    </>
  );
}))
export default withTranslation()(About);