import { observable, action, makeObservable } from 'mobx';
import agent from '../agent';

class MonitorStore {
    currentList = [];

    requestParams = null;

    currentEntity = {};

    loading = false;

    updating = false;
    currentGridDataId = null;

    deleteItemIndex = new Set();

    unit = "°C";

    constructor() {
        makeObservable(this, {
            currentList: observable,
            requestParams: observable,
            currentEntity: observable,
            loading: observable,
            updating: observable,
            deleteItemIndex: observable,
            unit: observable,
            setDeletedItemIndex: action,
            deleteProductIndex: observable,
            setDeletedProductIndex: action,
            resetDeleteIndex: action,
            currentGridDataId: observable,
            resetCurrentGridDataId: action,
            lastListLoadTime: observable,
            tableListDataHistory: observable,
            resetLastListLoadTime: action,
            filters: observable,
            appliedFilters: observable,
            tableMeta: observable,
            tableListData: observable,
            setTableListData: action,
            setFilter: action,
            onFilter: action,
            loadDataSync: action,
            loadList: action,
            load: action,
            edit: action,
            loadGrid: action,
            loadGridpdf: action,
            save: action,
            onGridSave: action,
            onGridUpdate: action,
            generateMonitorReport: action
        });
    }

    setDeletedItemIndex(i) {
        this.deleteItemIndex.add(i);
    }

    deleteProductIndex = new Set();

    setDeletedProductIndex(i) {
        this.deleteProductIndex.add(i);
    }

    resetDeleteIndex() {
        this.deleteItemIndex = new Set();
        this.deleteProductIndex = new Set()
    }

    resetCurrentGridDataId() {
        this.currentGridDataId = null;
    }

    lastListLoadTime = null;

    tableListDataHistory = [];

    resetLastListLoadTime() {
        this.lastListLoadTime = new Date();
    }

    filters = {
        status: '',
        name: '',
    };

    appliedFilters = {
        status: '',
        name: '',
    };

    tableMeta = {
        products: [],
        items: []
    };

    tableListData = [];


    setTableListData(tableData) {
        this.tableListData = tableData;
    }

    setFilter(name, value, clearFilter) {
        const filters = Object.assign({}, this.filters);
        filters[name] = value;
        this.filters = filters;
        if(clearFilter){
            this.filters = {}
        }
    }

    onFilter() {
        this.appliedFilters = Object.assign({}, this.filters);
        // requestParams.filters = this.filters;
        // this.loadList(requestParams);
    }

    loadDataSync(res) {
        this.currentEntity = res;
        this.tableMeta = res.metadata
    }

    loadList(params) {
        return agent.Monitor.list(params)
            .then(
                action(list => {
                    this.requestParams = params;
                    list.time = new Date();
                    this.lastListLoadTime = list.time;
                    this.currentList = list;
                    return list;
                })
            )
            .catch(
                action(err => {
                    throw err;
                })
            );
    }
    load(id) {
        this.loading = true;
        return agent.Monitor.load(id)
            .then(
                action(response => {
                    
                    this.currentEntity = response;
                    this.tableMeta = response.metadata
                    this.loading = false;
                    return response;
                })
            )
            .catch(
                action(err => {
                    this.loading = false;
                    throw err;
                })
            );
    }
    edit(id, data) {
        this.loading = true;
        return agent.Monitor.edit(id, data)
            .then(
                action(response => {
                   
                    this.currentEntity = response;
                    this.tableMeta = response.metadata
                    this.loading = false;
                    return response;
                })
            )
            .catch(
                action(err => {
                    this.loading = false;
                    throw err;
                })
            );
    }



    loadGrid(id) {
        this.loading = true;
        return agent.Monitor.loadGridData(id, this.appliedFilters)
            .then(
                action(response => {
                    const monitor_data = response.monitor_data;
                    let tableHistoryData = {}
                    let tableListDataHistory=[];
                    this.tableListDataHistory = []
                    this.currentEntity = response.monitor;
                    let tableData = {};
                    let tableListData = [];
                    if (monitor_data.length) {
                        monitor_data.forEach(tabData => {
                            const dataKeys = Object.keys(tabData.data);
                            if (dataKeys.length) {
                               tableHistoryData.p_name = tabData.p_name;
                               tableHistoryData.name = tabData.name;
                               tableHistoryData.for_date = tabData.for_date;
                               tableHistoryData.unit = tabData.unit;
                               dataKeys.forEach((data) => {
                                    tableData = tabData.data[data]
                                    tableData.meta = tabData.metadata
                                    tableListData = [...tableListData, tableData]
                                })
                                tableHistoryData.tableListData = tableListData;
                                tableListDataHistory = [...tableListDataHistory, tableHistoryData]
                                tableListData = [];
                                tableHistoryData = {}
                            }
                        });
                        this.tableListDataHistory = tableListDataHistory;
                    }
                    this.loading = false;
                    return response;
                })
            )
            .catch(
                action(err => {
                    this.loading = false;
                    throw err;
                })
            );
    }
    loadGridpdf(id, payload) {
        this.loading = true;
        return agent.Monitor.getPDF(id, payload)
            .then(
                action(response => {
                    const monitor_data = response.monitor_data;
                    let tableHistoryData = {}
                    let tableListDataHistory=[];
                    this.currentEntity = response.monitor;
                    let tableData = {};
                    let tableListData = [];
                    if (monitor_data.length) {
                        monitor_data.forEach(tabData => {
                            const dataKeys = Object.keys(tabData.data);
                            if (dataKeys.length) {
                               tableHistoryData.p_name = tabData.p_name;
                               tableHistoryData.name = tabData.name;
                               tableHistoryData.client_name = tabData.client_name;
                               tableHistoryData.reg_no = tabData.reg_no;
                               tableHistoryData.for_date = tabData.for_date;
                               tableHistoryData.unit = tabData.unit;
                               dataKeys.forEach((data) => {
                                    tableData = tabData.data[data]
                                    tableData.meta = tabData.metadata
                                    tableListData = [...tableListData, tableData]
                                })
                                tableHistoryData.tableListData = tableListData;
                                tableListDataHistory = [...tableListDataHistory, tableHistoryData]
                                tableListData = [];
                                tableHistoryData = {}
                            }
                        });
                        this.tableListDataHistory = tableListDataHistory;
                    }
                    this.loading = false;
                    return response;
                })
            )
            .catch(
                action(err => {
                    this.loading = false;
                    throw err;
                })
            );
    }

    save(values, isAdd) {
        this.updating = true;
        return agent.Monitor.save(values, isAdd)
            .then(
                action(user => {
                    this.currentEntity = user;
                    return user;
                })
            )
            .catch(
                action(err => {
                    this.updating = false;
                    this.updatingErrors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            );
    }

    onGridSave(values, isAdd) {
       
        return agent.Monitor.saveGrid(values, isAdd)
            .then(
                action(user => {
                    
                    return user;
                })
            )
            .catch(
                action(err => {
                    this.updating = false;
                    this.updatingErrors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            );
    }

    onGridUpdate(id) {
        this.updating = true;
        return agent.Monitor.getGridData(id)
            .then(
                action(response => {
                   
                    const monitor_data = response.monitor_data;
                    this.currentEntity = response.monitor;
                    this.tableMeta = response.monitor.metadata;
                    this.unit = response.monitor.unit
                    let tableData = {};
                    let tableListData = [];
                    if (monitor_data.length) {
                        monitor_data.forEach(tabData => {
                            this.currentGridDataId = tabData.id
                            if (tabData.data) {
                                Object.keys(tabData.data).forEach((data) => {
                                    tableData = tabData.data[data]
                                    tableData.meta = tabData.metadata
                                    tableListData = [...tableListData, tableData]
                                })
                            }
                        });
                       
                        this.tableListData = tableListData;
                        this.loading = false;
                    } 
                    this.updating = false;
                    return response;
                })
            )
            .catch(
                action(err => {
                    this.updating = false;
                    this.updatingErrors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            );
    }

    async generateMonitorReport(id) {
        //calls get method from document_template API//
        //takes 1 arguments, type - integer, id//
        //generates reports of documents in template//
        return agent.Monitor.report(id)
            .then(
                action((response) => {
                    return true;
                })
            )
            .catch(
                action((error) => {
                    throw error;
                })
            );
    }
}

const _MonitorStore = new MonitorStore();
export default _MonitorStore;