import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Row, Col, OverlayTrigger, Tooltip, Tab } from 'react-bootstrap'

//components//
import applicationRouter from '~/hoc/applicationRouter'
import withLocalization from '~/hoc/withLocalization';
import TableWidget from '../TableWidget.js';

//elements//
import { NumberInput } from '../../../../elements/Input';
import Select from '../../../../elements/Select';
import SwitchWidget from '../SwitchWidget';

const autoTimelogOptionsV2 = [{ value: 'gps', label: 'Auto Clock in/out', title: 'Auto Clock in/out' },
{ value: 'enabled', label: 'Allow clock in/out both manually and with start and finish buttons', title: 'Allow clock in/out both manually and with start and finish buttons' },
{ value: 'autoschedule', label: 'Auto log working hours according to schedule', title: 'Auto log working hours according to schedule' },
{ value: 'gpsmanual', label: 'Allow members to log hours with start and finish buttons only', title: 'Allow members to log hours with start and finish buttons only' },
]

const anomalyReasonExplainatoryNotes = {
    offSiteClockIn: "The action of an employee recording their start time for work while being away from the designated work location",
    offSiteClockOut: "The action of an employee recording the end of their work period while being away from the designated work location",
    earlyClockIn: "The action of an employee recording their start time for work before the scheduled or expected time",
    earlyClockOut: "The action of an employee recording the end of their work period before the scheduled or expected time",
    lateClockIn: "The action of an employee recording their start time for work after the scheduled or expected time",
    lateClockOut: "The action of an employee recording the end of their work period after the scheduled or expected time"
}

class LoginRules extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loginRules: {
                autoTimelogs: 'enabled',
                gpsAllowedDistance: 0,
                startBufferTimeForClockIn: 5,
                endBufferTimeForClockOut: 5,
                pmManagesOvertime: false,
                trackAnomalies: false,
                disableMobileAccess: false,
                autoClockOutAsPerScheduleNotGPS: false,
                anomalyTrackerReasons: null,
            },
            subTab: null,
        }
        this.handleChange = this.handleChange.bind(this);
        this.loadData = this.loadData.bind(this);
        this.handleUserDataChange = this.handleUserDataChange.bind(this);
        this.translateLabels = this.translateLabels.bind(this);
        this.handleInputRange = this.handleInputRange.bind(this);
        this.handleSubTabChange = this.handleSubTabChange.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = () => {
        const { currentEntity } = this.props.clientStore;
        let { loginRules, subTab } = this.state;
        if (currentEntity.client.data && currentEntity.client.data.loginRules) {
            const _loginRules = Object.assign({}, currentEntity.client.data.loginRules);
            loginRules.autoTimelogs = _loginRules.autoTimelogs && _loginRules.autoTimelogs !== 'disabled' ? _loginRules.autoTimelogs : 'enabled';
            loginRules.gpsAllowedDistance = _loginRules.gpsAllowedDistance ? _loginRules.gpsAllowedDistance : 0;
            loginRules.startBufferTimeForClockIn = !isNaN(_loginRules.startBufferTimeForClockIn) && _loginRules.startBufferTimeForClockIn > -1 ? _loginRules.startBufferTimeForClockIn : 5;
            loginRules.endBufferTimeForClockOut = !isNaN(_loginRules.endBufferTimeForClockOut) && _loginRules.endBufferTimeForClockOut > -1 ? _loginRules.endBufferTimeForClockOut : 5;
            loginRules.pmManagesOvertime = _loginRules.pmManagesOvertime ? _loginRules.pmManagesOvertime : false;
            loginRules.trackAnomalies = _loginRules.trackAnomalies ? _loginRules.trackAnomalies : false;
            loginRules.disableMobileAccess = _loginRules.disableMobileAccess ? _loginRules.disableMobileAccess : false;
            loginRules.autoClockOutAsPerScheduleNotGPS = _loginRules.autoClockOutAsPerScheduleNotGPS ? _loginRules.autoClockOutAsPerScheduleNotGPS : false;
            loginRules.anomalyTrackerReasons = _loginRules.anomalyTrackerReasons ? _loginRules.anomalyTrackerReasons : null;
            if (loginRules.anomalyTrackerReasons) subTab = Object.keys(loginRules.anomalyTrackerReasons)[0];
            this.setState({ loginRules, subTab })
        }
    }

    handleUserDataChange = (name, value) => {
        const { currentEntity } = this.props.clientStore;
        if (currentEntity.client.data.loginRules) {
            currentEntity.client.data.loginRules[name] = value;
        } else {
            let loginRules = {};
            loginRules[name] = value;
            currentEntity.client.data.loginRules = loginRules;
        }
    }

    handleChange = (name, value) => {
        const { loginRules } = this.state;
        loginRules[name] = value;
        this.handleUserDataChange(name, value)
        this.setState({ loginRules });
    }

    translateLabels() {
        const { t } = this.props;
        autoTimelogOptionsV2.forEach(item => {
            item.label = t(item.title)
        })
    }

    handleInputRange = (name, e) => {
        const { loginRules } = this.state;
        const value = Math.ceil(e.target.value / 5) * 5;
        loginRules[name] = value;
        this.handleUserDataChange(name, value)
        this.setState({ loginRules })
    }

    handleReasonChange = (name, value) => {
        const { loginRules } = this.state;
        if (value.length) {
            for (let i = 0; i < value.length; i++) {
                if (!value[i].code) {
                    value[i].code = 'custom';
                    value[i].sendNotification = false;
                    value[i].value = 'custom';
                    value[i].autoAdjust = false;
                }
            }
        }
        loginRules.anomalyTrackerReasons[name] = value;
        this.handleUserDataChange('anomalyTrackerReasons', loginRules.anomalyTrackerReasons);
        this.setState({ loginRules })
    }

    handleSubTabChange = (tabName) => {
        this.setState({ subTab: tabName })
    }

    renderNotes = (autoTimelogs) => {
        const { t } = this.props;
        switch (autoTimelogs) {
            case 'gps': return (
                <>
                    <div className='font-size-15'>- {t(`Control panel setting must be turned on for all users to use auto login function`)}.</div>
                    <div className='font-size-15'>- {t(`Admin can revoke auto login for a user in their user profile`)}.</div>
                    <div className='font-size-15'>- {t(`Admin sets the GPS location radius for auto login, e.g. 5-10 meters`)}.</div>
                    <div className='font-size-15'>- {t(`Admin can set a buffer time (e.g. 5-10 minutes) before or after scheduled start time for auto login`)}.</div>
                    <div className='font-size-15'>- {t(`If user is within GPS radius and buffer time, auto login is triggered and a push notification confirms login. Otherwise can clock in/out by using start/end function in the mobile app`)}.</div>
                </>
            );

            case 'enabled':
                return (
                    <div className='font-size-15'>
                        {t(`This function enables users to register their working hours either by clicking the start/finish button in the app or manually entering their working hours at the end of the day. Unless the user has disabled GPS on their mobile device, the system will capture GPS location data. The admin can make GPS obligatory under Basic Rules, which works well if the address is added for the tasks and projects`)}.
                    </div>
                )
            case 'autoschedule': return (
                <div className='font-size-15'>
                    {t(`If this function is enabled by the admin and schedules have been created for all employees, the system will automatically register work hours. The system will record work hours based on the set schedules regardless of whether the employees are present at work or not. Time & Control follows the schedule/shift plan`)}.
                </div>
            )
            case 'gpsmanual': return (
                <div className='font-size-15'>
                    {t(`This option allows members of the app to log their work hours simply by clicking the start and finish buttons within the app. Please ensure that you have registered the site address for tasks and projects to fully benefit from this function`
                    )}.
                </div>
            );
        }
    }

    addExtraItemsForActionCol = (item) => {
        const { t } = this.props
        if (!item.autoAdjust && !item.sendNotification) return <></>

        return (
            <div className='text-primary-color'>
                {item.autoAdjust &&
                    <i className='fa fa-clock-o' title={t("Hours will be adjusted as per schedule")} />
                }
                {item.sendNotification &&
                    <i className='fa fa-bell' title={t("Manager(s) will be notified")} />
                }
            </div>
        )
    }

    renderAnomalyTrackerReasons = (anomalyTrackerReasons) => {
        const { t } = this.props;
        const { subTab } = this.state;
        return (
            <Tab.Container activeKey={subTab} id="LoginRulesTabContainer" onSelect={(key) => { }} >
                <div className="custom-card">
                    <Row>
                        <Col sm={3}>
                            <ul className="list-group">
                                {Object.keys(anomalyTrackerReasons).map((key) => {
                                    return (
                                        <li key={key} className={subTab === `${key}` ? 'list-group-item active cursor-pointer' : 'list-group-item cursor-pointer'}
                                            onClick={() => this.handleSubTabChange(key)}>{t(key)}</li>
                                    )
                                })}
                            </ul>
                        </Col>
                        <Col sm={9}>
                            <Tab.Content>
                                {Object.keys(anomalyTrackerReasons).map((key) => {
                                    return (
                                        <Tab.Pane eventKey={key} key={key}>
                                            <div className='font-size-15 mt-1'><b>{t('Note')}: </b> {t(anomalyReasonExplainatoryNotes[key])}.</div>
                                            <TableWidget
                                                headers={['Reason']}
                                                metaData={[{ field: 'reason', type: 'text', placeholder: 'Add reason', required: true }]}
                                                data={anomalyTrackerReasons[key]}
                                                onChange={data => this.handleReasonChange(key, data)}
                                                addExtraItemsForActionCol={this.addExtraItemsForActionCol}
                                            />
                                        </Tab.Pane>
                                    )
                                })}
                            </Tab.Content>
                        </Col>
                    </Row>
                </div>
            </Tab.Container>
        )
    }

    render() {
        const { loginRules, subTab } = this.state;
        const { t } = this.props;
        this.translateLabels()
        return (
            <div>
                <Row>
                    <Col xs={12} sm={6}>
                        <label className="control-label">{t('Clocking options')}</label>
                        <Select
                            options={autoTimelogOptionsV2}
                            value={autoTimelogOptionsV2.find(item => item.value === loginRules.autoTimelogs)}
                            onChange={selectedItem => {
                                if (selectedItem.value !== "gps" && loginRules.pmManagesOvertime)
                                    this.handleChange('pmManagesOvertime', false);
                                if (selectedItem.value === "autoschedule" && loginRules.trackAnomalies)
                                    this.handleChange('trackAnomalies', false);
                                if ((selectedItem.value === "gps" || selectedItem.value === "autoschedule") && loginRules.autoClockOutAsPerScheduleNotGPS) {
                                    this.handleChange("autoClockOutAsPerScheduleNotGPS", false);
                                }
                                this.handleChange('autoTimelogs', selectedItem.value)
                            }}
                            style={{ width: '100%' }}
                        />
                    </Col>
                    <Col xs={12} sm={6} className='mt-4 mt-sm-0'>
                        <NumberInput
                            label={`${t('GPS max allowed distance (m)')}`}
                            name="gpsAllowedDistance"
                            value={loginRules.gpsAllowedDistance}
                            onChange={evt => {
                                this.handleChange('gpsAllowedDistance', parseInt(evt.target.value, 10));
                            }}
                        />
                    </Col>
                </Row>
                {loginRules.autoTimelogs === 'gps' &&
                    (<Row className='mt-4'>
                        <Col xs={12} sm={6}>
                            <label className="control-label">{t('Clock in buffer time (In Minutes)')}</label>
                            <div className="slider-parent" style={{ position: "relative" }}>
                                <input type="range" min="0" max="60"
                                    onChange={event => this.handleInputRange('startBufferTimeForClockIn', event)}
                                    value={loginRules.startBufferTimeForClockIn}
                                />
                                <div className="buble">
                                    {loginRules.startBufferTimeForClockIn} {t("Minute(s)")}
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} sm={6} className='mt-2 mt-sm-0'>
                            <label className="control-label">{t('Clock out buffer time (In Minutes)')}</label>
                            <div className="slider-parent" style={{ position: "relative" }}>
                                <input type="range" min="0" max="60"
                                    onChange={event => this.handleInputRange('endBufferTimeForClockOut', event)}
                                    value={loginRules.endBufferTimeForClockOut}
                                />
                                <div className="buble">
                                    {loginRules.endBufferTimeForClockOut} {t("Minute(s)")}
                                </div>
                            </div>
                        </Col>
                        <Col xs={2} sm={1} className='mt-1'>
                            <SwitchWidget
                                value={loginRules.pmManagesOvertime}
                                onChange={checked => this.handleChange('pmManagesOvertime', checked)} />
                        </Col>
                        <Col xs={10} sm={11}>
                            <label className="control-label">{t('Allow PM to manage overtime')}</label>
                            <OverlayTrigger
                                placement="bottom"
                                overlay={
                                    <Tooltip id="tooltip-top">
                                        {t(`This function allows only the project manager (PM) to register overtime in the system. Employees must inform the PM or admin when they work overtime`)}.
                                    </Tooltip>
                                }
                            >
                                <i className="fa fa-info-circle text-muted" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                            </OverlayTrigger>
                        </Col>
                    </Row>)
                }

                <Row className='mt-4'>
                    <Col xs={2} sm={1} className='mt-1'>
                        <SwitchWidget
                            value={loginRules.disableMobileAccess}
                            onChange={checked => this.handleChange('disableMobileAccess', checked)} />
                    </Col>
                    <Col xs={10} sm={11} md={5}>
                        <label className="control-label">{t('Block all members from using services on mobile apps.')}</label>
                        <OverlayTrigger
                            placement="bottom"
                            overlay={
                                <Tooltip id="tooltip-top">
                                    {t(`If enabled, this rule restricts all members from accessing the services on mobile apps. To give specific members the ability to use the mobile app, please update the members profile accordingly`)}.
                                </Tooltip>
                            }
                        >
                            <i className="fa fa-info-circle text-muted" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                        </OverlayTrigger>
                    </Col>
                    {loginRules.autoTimelogs !== 'autoschedule' && loginRules.autoTimelogs !== 'gps' &&
                        (<>
                            <Col xs={2} sm={1} className='mt-1'>
                                <SwitchWidget
                                    value={loginRules.autoClockOutAsPerScheduleNotGPS}
                                    onChange={checked => this.handleChange('autoClockOutAsPerScheduleNotGPS', checked)} />
                            </Col>
                            <Col xs={10} sm={11} md={5}>
                                <label className="control-label">{t('Enable auto clock out as per schedule end time')}</label>
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                        <Tooltip id="tooltip-top">
                                            {t("If enabled, the system will auto clock out members according to their scheduled end time and notify Admin/PM by mail")}.
                                        </Tooltip>
                                    }
                                >
                                    <i className="fa fa-info-circle text-muted" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                                </OverlayTrigger>
                            </Col>
                        </>)
                    }
                </Row>

                {loginRules.autoTimelogs !== 'autoschedule' && (<Row className='mt-4'>
                    <Col xs={2} sm={1} className='mt-1'>
                        <SwitchWidget
                            value={loginRules.trackAnomalies}
                            onChange={checked => this.handleChange('trackAnomalies', checked)} />
                    </Col>
                    <Col xs={10} sm={11}>
                        <label className="control-label">{t('Track and show anomalies in timesheet')}.</label>
                        <OverlayTrigger
                            placement="right"
                            overlay={
                                <Tooltip id="tooltip-top">
                                    {t("Allow system to track anomalies while clock-in/clock-out and show in timesheet")}.
                                </Tooltip>
                            }
                        >
                            <i className="fa fa-info-circle text-muted" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                        </OverlayTrigger>
                    </Col>
                    {loginRules.trackAnomalies && loginRules.anomalyTrackerReasons && this.renderAnomalyTrackerReasons(loginRules.anomalyTrackerReasons)}
                </Row>)
                }
                <div className='mt-3'>
                    <b>{t('Note')}</b> ({t(autoTimelogOptionsV2.find(item => item.value === loginRules.autoTimelogs).label)})
                    <div>{this.renderNotes(loginRules.autoTimelogs)}</div>
                </div>
            </div>
        );
    }
}
export default inject('clientStore', 'commonStore', 'userStore')(applicationRouter(withLocalization(observer(LoginRules))));
