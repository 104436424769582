import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { LazyLoadImage } from 'react-lazy-load-image-component'
import {v4 as uuid} from 'uuid';

//components
import withLocalization from '../../../hoc/withLocalization';
import SignWidget from './Widgets/SignWidget';
import TextboxWidget from './Widgets/TextboxWidget';
import DateWidget from './Widgets/DateWidget';
import InitialWidget from './Widgets/InitialWidget';
import LabelWidget from './Widgets/LabelWidget';
import RecipientNameWidget from './Widgets/RecipientNameWidget';
import SelectBoxWidget from './Widgets/SelectBoxWidget';
import CheckBoxWidget from './Widgets/CheckBoxWidget';
import RadioWidget from './Widgets/RadioWidget';

//config
import config from '~/library/config';

let saveWidgetTimeout;
export class PrepareDoc extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        document.body.classList.add("prepareBody");
        const { signatureStore, propertyStore, recipientStore } = this.props;
        propertyStore.fetchAllWidget(signatureStore.caseId);
        recipientStore.fetchAllRecipients(signatureStore.caseId)
    }

    componentWillUnmount() {
        document.body.classList.remove("prepareBody");
        if (saveWidgetTimeout) {
            clearInterval(saveWidgetTimeout);
        }

    }

    allowDrag = (evt) => {
        evt.preventDefault()
    }
    
    dropWidget = (evt, pageId) => {
        const { propertyStore, signatureStore } = this.props;
        const activevRec = propertyStore.activeRecipient;
        const widgetId = uuid();
        const widgetName = evt.dataTransfer.getData("text/plain");
        
        if (evt.target.nodeName === "DIV") {
            return
        }

        let imgRect = evt.target.getBoundingClientRect();
        let imgX = evt.clientX - imgRect.left;
        let imgY = evt.clientY - imgRect.top;


        if (widgetName === "Signature") {
            if ((imgX + 150) > evt.target.width) {
                imgX = evt.target.width - 150;
            }
            if ((imgY + 70) > evt.target.height) {
                imgY = evt.target.height - 70;
            }

            propertyStore.addWidget({
                widgetId,
                pageId: pageId,
                widgetX: imgX,
                widgetY: imgY,
                width: 150,
                height: 70,
                name: widgetName,
                data: null,
                required: true,
                colorCode: activevRec.color_code,
                recipientId: activevRec.id
            });
        }
        if (widgetName === "Text") {
            let ln = sessionStorage.getItem('locale');
            let localeText = "Enter Text";
            switch(ln){
                case 'en' : localeText = "Enter Text";break;
                case 'no' : localeText = "Skriv inn tekst";break;
                case 'es' : localeText = "Ingrese texto";break;
                case 'gr' : localeText = "Εισαγάγετε κείμενο";break;
                case 'lt' : localeText = "Įveskite tekstą";break;
                case 'pl' : localeText = "Wprowadź tekst";break;
                case 'ru' : localeText =  "Введите текст";break;
                case 'se' : localeText = "Skriv text";break;
                case 'default' : localeText = "Enter Text";break;
              }

            if ((imgX + 140) > evt.target.width) {
                imgX = evt.target.width - 140;
            }
            if ((imgY + 45) > evt.target.height) {
                imgY = evt.target.height - 45;
            }

            propertyStore.addWidget({
                widgetId,
                placeholder: "Enter Text",
                pageId: pageId,
                widgetX: imgX,
                widgetY: imgY,
                width: 140,
                height: 45,
                name: widgetName,
                data: null,
                required: true,
                colorCode: activevRec.color_code,
                recipientId: activevRec.id
            });
        }

        if (widgetName === "Initial") {
            if ((imgX + 100) > evt.target.width) {
                imgX = evt.target.width - 100;
            }
            if ((imgY + 70) > evt.target.height) {
                imgY = evt.target.height - 70;
            }

            propertyStore.addWidget({
                widgetId,
                pageId: pageId,
                widgetX: imgX,
                widgetY: imgY,
                width: 100,
                height: 70,
                name: widgetName,
                data: null,
                required: false,
                colorCode: activevRec.color_code,
                recipientId: activevRec.id
            });
        }

        if (widgetName === "Date") {

            if ((imgX + 140) > evt.target.width) {
                imgX = evt.target.width - 140;
            }
            if ((imgY + 45) > evt.target.height) {
                imgY = evt.target.height - 45;
            }

            propertyStore.addWidget({
                widgetId,
                pageId: pageId,
                widgetX: imgX,
                widgetY: imgY,
                width: 140,
                height: 45,
                name: widgetName,
                data: null,
                required: false,
                colorCode: activevRec.color_code,
                recipientId: activevRec.id
            });
        }

        if (widgetName === "Label") {
            let ln = sessionStorage.getItem('locale');
            let localeText = "Enter Text";
            switch(ln){
                case 'en' : localeText = "Enter Text";break;
                case 'no' : localeText = "Skriv inn tekst";break;
                case 'es' : localeText = "Ingrese texto";break;
                case 'gr' : localeText = "Εισαγάγετε κείμενο";break;
                case 'lt' : localeText = "Įveskite tekstą";break;
                case 'pl' : localeText = "Wprowadź tekst";break;
                case 'ru' : localeText =  "Введите текст";break;
                case 'se' : localeText = "Skriv text";break;
                case 'default' : localeText = "Enter Text";break;
              }

            if ((imgX + 170) > evt.target.width) {
                imgX = evt.target.width - 170;
            }
            if ((imgY + 45) > evt.target.height) {
                imgY = evt.target.height - 45;
            }

            propertyStore.addWidget({
                widgetId,
                pageId: pageId,
                widgetX: imgX,
                widgetY: imgY,
                width: 170,
                height: 45,
                name: widgetName,
                data: "Enter Text",
                // data: localeText,
                required: false,
                colorCode: activevRec.color_code,
                recipientId: activevRec.id
            });
        }

        if (widgetName === "Recipient") {

            if ((imgX + 170) > evt.target.width) {
                imgX = evt.target.width - 170;
            }
            if ((imgY + 45) > evt.target.height) {
                imgY = evt.target.height - 45;
            }

            propertyStore.addWidget({
                widgetId,
                pageId: pageId,
                widgetX: imgX,
                widgetY: imgY,
                width: 170,
                height: 45,
                name: widgetName,
                data: activevRec.user_name,
                required: false,
                colorCode: activevRec.color_code,
                recipientId: activevRec.id
            });
        }

        if (widgetName === "Select") {

            if ((imgX + 190) > evt.target.width) {
                imgX = evt.target.width - 190;
            }
            if ((imgY + 40) > evt.target.height) {
                imgY = evt.target.height - 40;
            }

            propertyStore.addWidget({
                widgetId,
                pageId: pageId,
                options: [this.props.t("Select")],
                widgetX: imgX,
                widgetY: imgY,
                width: 190,
                height: 40,
                name: widgetName,
                data: null,
                required: false,
                colorCode: activevRec.color_code,
                recipientId: activevRec.id
            });
        }
        if (widgetName === "Checkbox") {

            if ((imgX + 30) > evt.target.width) {
                imgX = evt.target.width - 30;
            }
            if ((imgY + 30) > evt.target.height) {
                imgY = evt.target.height - 30;
            }


            propertyStore.addWidget({
                widgetId,
                pageId: pageId,
                checkBoxes: [{
                    id: this.generateTimeStempNumber(),
                    label: `Checkbox ${this.generateTimeStempNumber()}`,
                    value: false
                }],
                widgetX: imgX,
                widgetY: imgY,
                width: 30,
                height: 30,
                name: widgetName,
                data: null,
                required: false,
                colorCode: activevRec.color_code,
                recipientId: activevRec.id
            });
        }

        if (widgetName === "Radio") {

            if ((imgX + 30) > evt.target.width) {
                imgX = evt.target.width - 30;
            }
            if ((imgY + 30) > evt.target.height) {
                imgY = evt.target.height - 30;
            }

            propertyStore.addWidget({
                widgetId,
                pageId: pageId,
                radioButtons: [{
                    id: this.generateTimeStempNumber(),
                    label: `Radio Button ${this.generateTimeStempNumber()}`,
                    value: false
                },
                {
                    id: this.generateTimeStempNumber() + '1',
                    label: `Radio Button ${this.generateTimeStempNumber() + '1'}`,
                    value: false
                }
                ],
                widgetX: imgX,
                widgetY: imgY,
                width: 30,
                height: 30,
                name: widgetName,
                data: null,
                radioGroup: `Group${this.generateTimeStempNumber()}`,
                required: false,
                colorCode: activevRec.color_code,
                recipientId: activevRec.id
            });
        }



        if (saveWidgetTimeout) {
            clearInterval(saveWidgetTimeout);
        }
        const data = { caseId: signatureStore.caseId, widgets: propertyStore.widgets }
        saveWidgetTimeout = setInterval(() => {
            propertyStore.saveWidget(data);
        }, 10000)

    }

    generateTimeStempNumber() {
        let now = new Date();
        let timestamp = now.getFullYear().toString(); // 2022
        timestamp += (now.getMonth < 9 ? '0' : '') + now.getMonth().toString(); // JS months are 0-based, so +1 and pad with 0's
        timestamp += ((now.getDate < 10) ? '0' : '') + now.getDate().toString();
        timestamp += ((now.getDate < 10) ? '0' : '') + now.getHours().toString();
        timestamp += ((now.getDate < 10) ? '0' : '') + now.getSeconds().toString();

        return timestamp;

    }
    getWidgetFromData(widget, pageId) {
        if (widget.pageId === pageId) {
            if (widget.name === "Signature") {
                return <SignWidget key={widget.widgetId} info={widget} locked={false} />
            } else if (widget.name === "Text") {
                return <TextboxWidget key={widget.widgetId} info={widget} locked={false} />
            } else if (widget.name === "Initial") {
                return <InitialWidget key={widget.widgetId} info={widget} />
            } else if (widget.name === "Date") {
                return <DateWidget key={widget.widgetId} info={widget} resize={false} locked={false} />
            } else if (widget.name === "Label") {
                return <LabelWidget key={widget.widgetId} info={widget} resize={false} locked={false} />
            } else if (widget.name === "Recipient") {
                return <RecipientNameWidget key={widget.widgetId} info={widget} resize={false} locked={false} />
            } else if (widget.name === "Select") {
                return <SelectBoxWidget key={widget.widgetId} edit={1} info={widget} resize={false} locked={false} />
            } else if (widget.name === "Checkbox") {
                return <CheckBoxWidget key={widget.widgetId} info={widget} resize={false} locked={false} />
            } else if (widget.name === "Radio") {
                return <RadioWidget key={widget.widgetId} info={widget} resize={false} locked={false} />
            }
            else {
                return ''
            }
        } else {
            return ''
        }
    }
    render() {
        const { signatureStore, propertyStore } = this.props;
        return (
            <>
                <div className="prepareImgCon">
                    {signatureStore.documentPages.map(page => {
                        return (
                            <React.Fragment key={page.id}>
                                <div className="prepareImg" onDragOver={this.allowDrag} onDrop={(evt) => { this.dropWidget(evt, page.id) }}>
                                    <LazyLoadImage src={`${config.UPLOADS_ROOT}/${page.page_path}`} draggable="false" />
                                    {
                                        propertyStore.widgets.map((widget) => {
                                            return (
                                                this.getWidgetFromData(widget, page.id)
                                            )
                                        })
                                    }
                                </div>

                                <div className="imgDeivider"></div>
                            </React.Fragment>
                        )
                    })
                    }
                </div>
            </>
        )
    }
}
export default inject('commonStore', 'signatureStore', 'propertyStore', 'recipientStore')(withLocalization(observer(PrepareDoc)));