import React, { Component, Fragment } from 'react';
import moment from 'moment/min/moment-with-locales';
import { Modal, Badge, Row, Col, Table, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import { v4 as uuid } from 'uuid';

//HoCs and components
import applicationRouter from '~/hoc/applicationRouter'
import withLocalization from '../../../hoc/withLocalization';
import Button from '../../../components/CustomButton/CustomButton.jsx';
import DatePicker from '../../../components/DatePicker/DatePicker';
import { MembersWidget } from '../../../components/GenericForm/Widgets/UsersWidget';
import { ProjectsWidget } from '../../../components/GenericForm/Widgets/ProjectsWidget';
import { TasksWidget } from '../../../components/GenericForm/Widgets/TasksWidget';
import Checkbox from '../../../components/CustomCheckbox/CustomCheckbox';
import TimePickerInput from '../../../components/TimePicker';
import CheckboxButton from '../../../components/CustomButtonCheckbox/CustomButtonCheckbox';
import { CurrentUserGroupsWidget } from '../../../components/GenericForm/Widgets/UserGroupWidget';

//elements
import SwitchWidget from '../ControlPanel/SwitchWidget.js';
import CustomMultiSelect from '../../../elements/CustomMultiSelect';
import RequiredStar from '../../../elements/RequiredStar.js';
import LoadingSpinner from '../../../elements/LoadingSpinner';
import Input, { Textarea, NumberInput } from '../../../elements/Input';


//utils
import { getMinutesFromTimeInput, minutesToTimeInput } from '../../../utils/timeCalc';
import { longDateFormat, getShortDateFormat, shortDateFormat, timeFormat } from '../../../utils/dateFormat';
import { getStatusText } from '../../../utils/scheduleUtils';
import customerFeature from '~/utils/customerFeature';

//assets
import CloseModalIcon from '../../../assets/img/CloseApproveModalIcon.svg';

//styles
import "./Schedule.css";

const defaultDays = [{ day: 'Mon', value: 1 }, { day: 'Tue', value: 2 },
{ day: 'Wed', value: 3 }, { day: 'Thu', value: 4 },
{ day: 'Fri', value: 5 }, { day: 'Sat', value: 6 },
{ day: 'Sun', value: 0 }];

const ShowAssignedShifts = (assigned_shifts, dateTimeRules, t) => {
    if (!assigned_shifts || !assigned_shifts.length) return null;
    return (
        <Table className='w-100' striped>
            <thead>
                <tr className='w-100 px-4'>
                    <th width={'25%'}>{t('Assignee Name')}</th>
                    <th width={'25%'}>{t('Employee role')}</th>
                    <th width={'25%'}>{t('Assigned on')}</th>
                    <th width={'25%'}>{t('Work log status')}</th>
                </tr>
            </thead>
            <tbody>
                {assigned_shifts.map((ele, idx) => (
                    <tr key={idx} className='text-capitalize mt-1 px-4'>
                        <td width={'25%'} className=''><div className='d-flex align-items-center min-height-20 ps-2'>{ele.assignee_name}</div></td>
                        <td width={'25%'} className='timelog_array_details'>{ele.employee_type === 'Z_none' ? t('Others') : t(ele.employee_type)}</td>
                        <td width={'25%'} className='timelog_array_details'>{shortDateFormat(ele.assigned_on, dateTimeRules)}</td>
                        <td width={'25%'} className='timelog_array_details'>{ele.work_log_status}</td>
                    </tr>
                ))}
            </tbody>
        </Table>
    )
}

class ShiftForm extends Component {
    constructor(props) {
        super(props);
        let _isShiftPublished = this.getIfShiftIsPublished(props.stub.for_date);
        this.isShiftAllowanceEnabled = props?.commonStore?.config?.client?.data?.extraPayments?.some(ele => ele.code === 'shift_allowance' && ele.isActive) || false;
        this.isEmergencyShiftEnabled = props?.commonStore?.config?.client?.data?.extraPayments?.some(ele => ele.code === 'emergency_shifts' && ele.isActive) || false;
        this.state = {
            availability: '',
            data: {
                for_date: props.stub.for_date,
                assignee_id: props.stub.assignee_id //this.props.stub.assignee_id
            },
            isShiftPublished: _isShiftPublished,
            users: [],
            valuesToFilter: props.stub.valuesToFilter ? props.stub.valuesToFilter : [],
            isModalShowForChooseMemberForShiftReplace: false,
            rolesOptions: props.commonStore.config.client.data.roles.map((ele, index) => { return { value: index, label: ele.name, code: ele.code } }),
            selectedRoles: [],
            shift_creation_count: Number(1),
            selectedRolesObject: [],
            selectedDays: [],
            isShiftAllowance: false,
            assignEmergencyShiftToEmployeeForTheWeek: false,
            shiftAllowance: []
        }
    }

    componentWillMount() {
        const { scheduleStore } = this.props;
        scheduleStore.loading = true;
    }

    componentDidMount() {
        this.loadData();
    }

    getIfShiftIsPublished = (for_date) => {
        let getDayData = this.props.scheduleStore.currentWeekHeader.find(item => moment(item.for_date).isSame(moment(for_date), 'date'));
        return getDayData !== undefined ? getDayData.show_shifts_to_member : true;
    }

    handleRun = async (action) => {
        const { scheduleStore, t, commonStore, handleHideShow, finish } = this.props;
        const { isShiftPublished, data, isSwapShiftRequest } = this.state;
        const entity = scheduleStore.currentEntity;
        const isSchedulePublishUnpublishFeatueEnabled = commonStore?.config?.client?.data?.basicRules.enableSchedulePublishUnpublishFeatue;
        const prevShiftPublished = this.getIfShiftIsPublished(data.for_date);
        if (action !== 'cancel' && action !== 'delete' && (!entity.project_id || !entity.task_id)) {
            commonStore.addNotification(t('Please select project and task'), null, 'error');
            return;
        }
        if (action === 'save' && isSchedulePublishUnpublishFeatueEnabled && isShiftPublished !== prevShiftPublished) {
            const __forDate = moment(entity.for_date).format('YYYY-MM-DD');
            await handleHideShow(__forDate, isShiftPublished, false);
        }
        if (isSwapShiftRequest) {
            await finish(action)
        } else {
            if (isSchedulePublishUnpublishFeatueEnabled && isShiftPublished === false) {
                scheduleStore.changeEntity('do_notify', isShiftPublished);
            }
            await finish(action)
        }
    }

    handleChange = (name, value) => {
        const { scheduleStore } = this.props;
        scheduleStore.changeEntity(name, value);
        const tempdata = {
            for_date: '',
            assignee_id: '',
            from_date: '',
            to_date: ''
        }
        if (name === 'for_date') {
            tempdata.for_date = value;
            const _selectedDays = !scheduleStore.currentEntity.assignee_id ?
                defaultDays.filter(day => !this.checkIfDayIsDisabled(day.value)).map(e => { return e.value }) : null;
            if (_selectedDays) scheduleStore.changeEntity("selectedDays", _selectedDays);
            this.setState((prevState) => {
                let data = Object.assign({}, prevState.data);
                data.for_date = tempdata.for_date;
                return { data, selectedDays: _selectedDays }
            }, () => { this.getAvailabilityData(this.state.data) })
            this.setState({ isShiftPublished: this.getIfShiftIsPublished(value) })
        }
        if (name === 'assignee_id') {
            tempdata.assignee_id = value;
            this.setState((prevState) => {
                let data = Object.assign({}, prevState.data);
                data.assignee_id = tempdata.assignee_id;
                return { data }
            }, () => { this.getAvailabilityData(this.state.data) })
        }
        if (name === 'to_date') {
            tempdata.to_date = value;
            const _selectedDays = !scheduleStore.currentEntity.assignee_id ?
                defaultDays.filter(day => !this.checkIfDayIsDisabled(day.value)).map(e => { return e.value }) : null;
            if (_selectedDays) scheduleStore.changeEntity("selectedDays", _selectedDays);
            this.setState((prevState) => {
                let data = Object.assign({}, prevState.data);
                data.for_date = tempdata.for_date;
                return { data, selectedDays: _selectedDays }
            })
        }
        if (name === 'shift_creation_count') {
            this.setState({ shift_creation_count: value })
        }
    };

    applyRoleFilter = (value) => {
        const clientRoles = this.state.rolesOptions;
        let _userOptions = value.map((ele) => clientRoles[ele]);
        this.setState({ selectedRoles: value, selectedRolesObject: _userOptions })
        this.handleChange('data.invited_ids', []);
    }

    handleUserChange = (value) => {
        this.handleChange('assignee_id', value);
    };

    handleTaskChange = (value) => {
        this.handleChange('task_id', value);
    };

    checkAll = (value) => {
        let _selectedDays = [];
        if (value) {
            _selectedDays = defaultDays.filter(day => !this.checkIfDayIsDisabled(day.value)).map(e => { return e.value })
        }
        this.setState({ selectedDays: _selectedDays }, () => {
            this.props.scheduleStore.changeEntity('selectedDays', this.state.selectedDays)
        });
    }

    handleCheck = (evt, value) => {
        let _selectedDays = this.state.selectedDays;
        if (evt) {
            _selectedDays.push(value)
        } else {
            _selectedDays = _selectedDays.filter(e => e !== value)
        }
        this.setState({ selectedDays: _selectedDays }, () => {
            this.props.scheduleStore.changeEntity('selectedDays', this.state.selectedDays)
        });
    }

    async loadData() {
        const { scheduleStore, id, add, stub, commonStore } = this.props;
        const isSchedulePublishUnpublishFeatueEnabled = commonStore?.config?.client?.data?.basicRules.enableSchedulePublishUnpublishFeatue;
        const _roleOptions = [...this.state.rolesOptions, { value: this.state.rolesOptions.length, label: 'Others', code: 'z_none' }];
        this.setState({
            rolesOptions: _roleOptions,
            selectedRoles: _roleOptions.map((e, index) => { return index }),
            selectedDays: [moment(stub.for_date).day()]
        })
        if (isSchedulePublishUnpublishFeatueEnabled && this.state.isShiftPublished === false) {
            stub.do_notify = false;
        }
        if (add) {
            this.getAvailabilityData(stub);
            return scheduleStore.returnDefaultNew(stub);
        }
        await scheduleStore.load(id);
        const entity = scheduleStore.currentEntity;
        await this.getAvailabilityData(entity);
        if (this.state.isShiftPublished !== this.getIfShiftIsPublished(entity.for_date)) {
            this.setState({ isShiftPublished: this.getIfShiftIsPublished(entity.for_date) }, () => {
                if (isSchedulePublishUnpublishFeatueEnabled && this.state.isShiftPublished === false) {
                    scheduleStore.changeEntity('do_notify', false);
                }
            })
        }
        return;
    }

    async getAvailabilityData(stub) {
        const { scheduleStore } = this.props;
        const avaData = { start_date: stub && stub.for_date, end_date: stub && stub.for_date, user_id: stub && stub.assignee_id, loadShiftAllowance: true };
        if (avaData) {
            const data = await scheduleStore.getAvailability(avaData);
            if (data) {
                const tempdata = {
                    for_date: data.for_date,
                    assignee_id: data.user_id
                }
                this.setState({ availability: data, data: tempdata });
            }
            else {
                this.setState({ data: { ...this.state.data, assignee_id: stub.assignee_id } })
            }
        }
    }

    getStatusText() {
        const { scheduleStore, t } = this.props;
        const entity = scheduleStore.currentEntity;
        const statusText = getStatusText(entity);
        return <div style={{ color: '#ffffff' }}>{t(statusText)}</div>;
    }

    acceptProposed(id) {
        this.handleChange('assignee_id', id);
    }

    checkIfDayIsDisabled = (index) => {
        const entity = this.props.scheduleStore.currentEntity;
        if (moment(entity.to_date).diff(entity.for_date, 'day') > 6 || index === 7) return false;
        let from_date = moment(entity.for_date);
        let to_date = moment(entity.to_date);
        if (entity.for_date === entity.to_date) return !(from_date.day() === index);

        let currentWeekStartDate = moment(entity.for_date).day() !== 0 ? moment(entity.for_date).startOf('week').add(-1, 'day') : moment(entity.for_date);
        let currrentDay = null;

        if (from_date.week() !== to_date.week() && from_date.day() > to_date.day() && from_date.day() !== index) {
            currrentDay = moment(currentWeekStartDate.add(7 + index, 'days'));
        }
        else {
            if (index === 0 && from_date.week() === to_date.week()) {
                currrentDay = moment(currentWeekStartDate.add(index + 7, 'days'));
            } else {
                currrentDay = moment(currentWeekStartDate.add(index, 'days'));
            }
        }
        return !currrentDay.isBetween(from_date, to_date, 'date', "[]");
    }

    handleProposePeopleChange = (users) => {
        this.setState({ users });
    }

    sendShiftToReplace = async () => {
        try {
            if (!this.state.users.length) {
                return this.props.commonStore.addNotification(this.props.t("Please Select atleast one member to replace the shift with."), null, 'error');
            }
            await this.props.finish('send-shift-request-for-swap-shift', this.state.users);
            this.setState({ isModalShowForChooseMemberForShiftReplace: false })
        }
        catch (err) {
            console.error(err)
        }
    }

    render() {
        const { scheduleStore, commonStore, t, userStore, isAvailibiltyFeatureEnabled,
            add, responseData, currentView, stub, id } = this.props;
        const pm_no_shifts = customerFeature('pm_no_shifts');

        const entity = scheduleStore.currentEntity;
        let { resources } = scheduleStore;
        const { availability, selectedRoles, rolesOptions, selectedRolesObject, users, isModalShowForChooseMemberForShiftReplace,
            isShiftPublished, shift_creation_count, valuesToFilter, selectedDays, data } = this.state;
        let userOptionsDupWithoutUserGroupFilter = [];
        resources = _.filter(resources, (r) => !!r.id);
        if (entity.project_id) {
            resources = _.filter(resources, (r) => r.project_ids.indexOf(parseInt(entity.project_id, 10)) >= 0);
        }

        if (selectedRoles?.length !== rolesOptions.length) {
            let filter = [];
            selectedRolesObject.forEach(ele => {
                resources.forEach(item => {
                    if (item.employee_type === ele.code)
                        filter.push(item)
                })
            })
            resources = filter;
        }

        userOptionsDupWithoutUserGroupFilter = [...resources];
        if (entity.isPendingShift && entity.user_group > 0) {
            resources = resources.filter(ele => ele.user_groups.includes(entity.user_group));
        }
        const isMember = userStore.currentUser.user_type === 'member';
        const memberWithSameProject = scheduleStore.currentProjectMember;
        const userOptions = resources.map((r) => {
            return { value: r.id, label: r.name };
        });
        const memberWithName_SameProject = memberWithSameProject?.map((r) => {
            return { value: r.id, label: r.first_name + ' ' + r.last_name };
        });
        const dateTimeRules = commonStore?.config?.client?.data?.dateTimeRules ?? false;
        const format = dateTimeRules?.time_format ?? 'hh:mm';
        const isSchedulePublishUnpublishFeatueEnabled = commonStore?.config?.client?.data?.basicRules?.enableSchedulePublishUnpublishFeatue;
        const showMultiDayPendingForm = entity.isPendingShift && add;
        const emergencyShift = resources?.find(ele => ele.id === data?.assignee_id && ele.emergency_id);
        const isEditable = add || !!entity.is_editable;
        return (
            <Fragment>
                <Modal size="xl" show onHide={() => this.handleRun('cancel')}>
                    <Modal.Header className="set_ModalHeader">
                        <Modal.Title className='w-100 text-center custom-modal-title'>
                            {t(isEditable ? 'Create / edit shift' : 'Shift Details')}
                        </Modal.Title>
                        <img
                            src={CloseModalIcon}
                            className="cursor-pointer"
                            onClick={() => this.handleRun('cancel')}
                            alt='close-btn'
                        />
                    </Modal.Header>
                    {scheduleStore?.loading ?
                        <Modal.Body>
                            <LoadingSpinner />
                        </Modal.Body>
                        :
                        <Modal.Body>
                            <div className="row">
                                <div className="col-6 col-md-2" style={{ width: 'fit-content' }} >
                                    <div className="form-group field field-string ">
                                        <label className="control-label fs15">
                                            {t('From')}
                                        </label>
                                        <TimePickerInput
                                            style={{ width: 150 }}
                                            value={minutesToTimeInput(entity.start_time, false)}
                                            format={format === "hh:mm" ? "HH:mm" : format}
                                            onChange={value => this.handleChange('start_time', getMinutesFromTimeInput(value))}
                                            className="worklog-input"
                                            disabled={!isEditable}
                                        />
                                    </div>
                                </div>
                                <div className="col-6 col-md-2 ps-0" style={{ width: 'fit-content' }}>
                                    <div className="form-group field field-string">
                                        <label className="control-label fs15">
                                            {t('To')}
                                        </label>
                                        <TimePickerInput
                                            style={{ width: 150 }}
                                            value={minutesToTimeInput(entity.end_time, false)}
                                            format={format === "hh:mm" ? "HH:mm" : format}
                                            onChange={value => this.handleChange('end_time', getMinutesFromTimeInput(value))}
                                            className="worklog-input"
                                            disabled={!isEditable}
                                        />
                                    </div>
                                </div>
                                <div className={`col-6 ${showMultiDayPendingForm ? 'col-md-2' : 'col-md-4'} ps-0`}>
                                    <div className={"form-group field field-string"}>
                                        <label className="control-label fs15">{t(showMultiDayPendingForm ? 'From Date' : 'Select Date')}<RequiredStar /> </label>
                                        <DatePicker
                                            selected={new Date(entity.for_date)}
                                            onChange={(date) => this.handleChange('for_date', date)}
                                            dateFormat={showMultiDayPendingForm ? getShortDateFormat(dateTimeRules) : longDateFormat(dateTimeRules)}
                                            disabled={isMember || !isEditable}
                                        />
                                    </div>
                                </div>
                                {showMultiDayPendingForm &&
                                    <div className="col-6 col-md-2 ps-0">
                                        <div className={"form-group field field-string"}>
                                            <label className="control-label fs15">{t('To Date')}<RequiredStar /> </label>
                                            <DatePicker
                                                selected={new Date(entity.to_date)}
                                                onChange={(date) => this.handleChange('to_date', date)}
                                                dateFormat={getShortDateFormat(dateTimeRules)}
                                                disabled={isMember || !isEditable}
                                                minDate={new Date(entity.for_date)}
                                            />
                                        </div>
                                    </div>
                                }
                                <div className="col-md-4 ps-0 mt-1">
                                    <div className='d-flex justify-content-space-between'>
                                        <div className='d-flex flex-column align-items-start'>
                                            <div className="text-center fw-bold fs15">{t('status')}</div>
                                            <Badge className={`waiting_badge ${entity.isPendingShift || add ? 'pending' : ''} `}> {this.getStatusText()}</Badge>
                                        </div>
                                        {entity.isPendingShift && !add &&
                                            <div className='d-flex align-items-end'>
                                                <Checkbox
                                                    isChecked={!!(_.get(scheduleStore, 'currentEntity.data.addBonus'))}
                                                    label={t('Add bonus')}
                                                    onChange={() => this.handleChange('data.addBonus', !(_.get(scheduleStore, 'currentEntity.data.addBonus')))}
                                                    disabled={isMember || !isEditable}
                                                    wrapperClassName={'shift-chekbox-style font-size-12'}
                                                />
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="tooltip-top">
                                                            {t("Shifts with bonus will enable extra payment to employees for the hours logged under that shift, it will reflect in Payroll reports")}.
                                                        </Tooltip>
                                                    }
                                                >
                                                    <i className="fa fa-info-circle text-muted mb-1 ms-2" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                                                </OverlayTrigger>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            {showMultiDayPendingForm &&
                                <div className="row m-0 mt-3">
                                    <div className="d-flex p-0">
                                        <div className='d-flex'>
                                            <label className="control-label fs15 mt-1">
                                                {t('Weekdays')}<RequiredStar />
                                            </label>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip id="tooltip-top">
                                                        {t("Please select the weekdays for which you want to create shifts")}.
                                                    </Tooltip>
                                                }
                                            >
                                                <i className="fa fa-info-circle text-muted mt-1 mx-2" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                                            </OverlayTrigger>
                                            {defaultDays.map((item, index) => (
                                                <CheckboxButton
                                                    key={`${index}`}
                                                    isChecked={selectedDays?.includes(item.value)}
                                                    onChange={evt => this.handleCheck(evt, item.value)}
                                                    label={t(item.day)}
                                                    isRightPadding={10}
                                                    isSatSun={index === 5 ? true : index === 6 ? true : false}
                                                    className={index === 7 ? '' : 'overflow-property'}
                                                    disabled={this.checkIfDayIsDisabled(item.value)}
                                                />
                                            ))}
                                            <CheckboxButton
                                                key="check_all"
                                                isChecked={selectedDays?.length &&
                                                    (
                                                        (moment(entity.to_date).diff(entity.for_date, 'days') === selectedDays.length - 1)
                                                        ||
                                                        (moment(entity.to_date).diff(entity.for_date, 'days') > 6 && selectedDays.length === 7)
                                                    )
                                                }
                                                onChange={evt => this.checkAll(evt)}
                                                label={t('Check All')}
                                                isRightPadding={10}
                                                disabled={this.checkIfDayIsDisabled(7)}
                                            />
                                        </div>
                                        <div className='ms-5 mt-1 d-flex'>
                                            <Checkbox
                                                isChecked={!!(_.get(scheduleStore, 'currentEntity.data.addBonus'))}
                                                label={t('Add bonus')}
                                                onChange={() => this.handleChange('data.addBonus', !(_.get(scheduleStore, 'currentEntity.data.addBonus')))}
                                                disabled={isMember}
                                                wrapperClassName={'shift-chekbox-style fs15'}
                                            />
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip id="tooltip-top">
                                                        {t("Shifts with bonus will enable extra payment to employees for the hours logged under that shift, it will reflect in Payroll reports")}.
                                                    </Tooltip>
                                                }
                                            >
                                                <i className="fa fa-info-circle text-muted mt-1 ms-2" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="row mt-3">
                                <div className={responseData?.length ? "col-md-8 col-lg-8 mb-7 fontStyles" : "col-md-12 col-lg-12 mb-3 fontStyles"}>
                                    {isAvailibiltyFeatureEnabled && !!availability &&
                                        <div key={uuid()} className='row'>
                                            {(entity.assignee_id === availability.user_id && moment(entity.for_date).isSame(moment(availability.for_date), 'date')) &&
                                                <Row>
                                                    <Col xs={4} md={3} lg={2}>
                                                        <label className="control-label availability-status-title">{t('Availability Status')}</label>
                                                    </Col>
                                                    <Col xs={8} md={9} lg={10}>
                                                        {(availability.availability_type === 'available' && !availability.from) &&
                                                            <label className="availablility-status green_border">{t('Available for Full Day.')}</label>
                                                        }
                                                        {(availability.availability_type === 'available' && availability.from && availability.to) &&
                                                            <label className="availablility-status orange_border">{t("Available from")} {timeFormat(availability.from, dateTimeRules)} {t("to")} {timeFormat(availability.to, dateTimeRules)}</label>
                                                        }
                                                        {(availability.availability_type === 'not available' && !availability.from) &&
                                                            <label className="availablility-status red_border">{t("Unavailable for Full Day.")}</label>
                                                        }
                                                        {(availability.availability_type === 'not available' && availability.from && availability.to) &&
                                                            <label className="availablility-status ligth_red_border">{t("Unavailable from")} {timeFormat(availability.from, dateTimeRules)} {t("to")} {timeFormat(availability.to, dateTimeRules)}</label>
                                                        }
                                                    </Col>
                                                </Row>
                                            }
                                        </div>
                                    }
                                    <div className="row gy-2">
                                        <div className="col-6">
                                            <div className="form-group field field-string">
                                                <label className="control-label fs15">{t('Project')}<RequiredStar /></label>
                                                {(isMember || !isEditable) ?
                                                    <Input
                                                        value={entity.assignedProjectName}
                                                        disabled={true}
                                                    />
                                                    :
                                                    <ProjectsWidget
                                                        value={entity.project_id || null}
                                                        onChange={e => this.handleChange('project_id', e)}
                                                        applyOnFirstLoad={entity.assignee_id > 0}
                                                        disabled={isMember || !isEditable}
                                                        filterByUser={entity.assignee_id}
                                                        pmMode={userStore.currentUser.user_type === 'pm' ? 'manager' : null}
                                                    />
                                                }
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group field field-string">
                                                <label className="control-label fs15">{t('Task')}<RequiredStar /></label>
                                                {(isMember || !isEditable) ?
                                                    <Input
                                                        value={entity.assignedTaskName}
                                                        disabled={true}
                                                    />
                                                    : <>
                                                        {!!entity.assignee_id && (
                                                            <TasksWidget
                                                                className="form-control"
                                                                onChange={(value, full) => this.handleTaskChange(value, full)}
                                                                value={entity.task_id}
                                                                defaultIfOne
                                                                defaultByGeneric
                                                                filterByUser={entity.assignee_id}
                                                                filterByProject={entity.project_id || true}
                                                                disabled={isMember || !isEditable}
                                                            />
                                                        )}
                                                        {!entity.assignee_id && (
                                                            <TasksWidget
                                                                className="form-control"
                                                                onChange={(value, full) => this.handleTaskChange(value, full)}
                                                                value={entity.task_id}
                                                                defaultIfOne
                                                                defaultByGeneric
                                                                filterByProject={entity.project_id || true}
                                                                disabled={isMember || !isEditable}
                                                            />
                                                        )}
                                                    </>
                                                }
                                            </div>
                                        </div>
                                        {!entity.isPendingShift &&
                                            <>
                                                <div className="col-6 my-2">
                                                    <div className="form-group field field-string">
                                                        <label className="control-label">
                                                            {t('Assigned to')}
                                                            <RequiredStar />
                                                            {!entity.assignee_id && !entity.task_id &&
                                                                <span className='text-danger fw-600 font-size-10 ms-2'>
                                                                    ({t('Please Select Project First')})
                                                                </span>
                                                            }
                                                        </label>
                                                        {(isMember || !isEditable) ?
                                                            <Input
                                                                value={entity.assignedMemberName}
                                                                disabled={true}
                                                            />
                                                            :
                                                            <>
                                                                {!pm_no_shifts && !isMember && (
                                                                    <MembersWidget
                                                                        key={uuid()}
                                                                        className="form-control"
                                                                        onChange={(value, full) => this.handleUserChange(value, full)}
                                                                        value={entity.assignee_id}
                                                                        defaultIfOne
                                                                        disabled={isMember || !isEditable || !entity.task_id}
                                                                        valuesToFilter={valuesToFilter}
                                                                        placeholder={t('Select Member')}
                                                                        reactSelectClassname={"custom-select-max-height-200"}
                                                                        options={{ project: { project_id: entity.project_id } }}
                                                                    />
                                                                )}
                                                                {entity.assignee_id > 0 && (pm_no_shifts || isMember) && (
                                                                    <div className='shift-assignee'>
                                                                        {userStore.currentUser.first_name} {userStore.currentUser.last_name}
                                                                    </div>
                                                                )}
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                                {stub.valuesToFilter && !!stub.valuesToFilter.length &&
                                                    <div className="col-4 d-flex align-items-center pt-2">
                                                        <SwitchWidget
                                                            onChange={checked => {
                                                                this.setState({ valuesToFilter: !checked ? [] : stub.valuesToFilter })
                                                            }}
                                                            value={!!valuesToFilter.length}
                                                            className={'mt-2'}
                                                            disabled={!isEditable}
                                                        />
                                                        <label className="control-label ms-2">{currentView === 2 ? t('Filter employees by group') : t('Filter employees by title')}</label>
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={
                                                                <Tooltip id="tooltip-top">
                                                                    {t("Enable this switch to display only filtered employees in the group or title; disable it to show all employees")}.
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <i className="fa fa-info-circle text-muted ms-2" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                                                        </OverlayTrigger>
                                                    </div>
                                                }
                                                {!entity.isPendingShift && !pm_no_shifts &&
                                                    <div className="col-4">
                                                        <label className="control-label">{t('Group')}</label>
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={
                                                                <Tooltip id="tooltip-top">
                                                                    {t("Please select employee group and assign to shift, and the shift will be organized according to that in group view. You can change view from drop-down on top-left")}.
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <i className="fa fa-info-circle text-muted ms-2" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                                                        </OverlayTrigger>
                                                        {!entity.task_id &&
                                                            <span className='text-danger fw-600 font-size-10 ms-2'>
                                                                ({t('Please Select Project First')})
                                                            </span>
                                                        }
                                                        {(isMember || !isEditable) ?
                                                            <Input
                                                                value={entity.assignedGroupName}
                                                                disabled={true}
                                                            />
                                                            :
                                                            <CurrentUserGroupsWidget
                                                                user_id={entity.assignee_id}
                                                                value={entity.user_group}
                                                                onChange={(value) => this.handleChange('user_group', value)}
                                                                disabled={isMember || !isEditable || !entity.task_id}
                                                                isMember={isMember}
                                                            />
                                                        }
                                                    </div>
                                                }
                                                <div className={"col-12 mt-2"}>
                                                    <div className="d-flex justify-content-start align-items-end h-75">
                                                        <div className=''>
                                                            <Checkbox
                                                                isChecked={!!(_.get(scheduleStore, 'currentEntity.data.addBonus'))}
                                                                label={t('Add bonus')}
                                                                onChange={() => this.handleChange('data.addBonus', !(_.get(scheduleStore, 'currentEntity.data.addBonus')))}
                                                                disabled={isMember || !isEditable}
                                                                wrapperClassName={'shift-chekbox-style'}
                                                            />
                                                        </div>
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={
                                                                <Tooltip id="tooltip-top">
                                                                    {t("Shifts with bonus will enable extra payment to employees for the hours logged under that shift, it will reflect in Payroll reports")}.
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <i className="fa fa-info-circle text-muted mb-1 ms-2" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                                                        </OverlayTrigger>
                                                        {!isMember && entity.assignee_id && (
                                                            <div className='d-flex justify-content-space-between ml-10'>
                                                                <div className='me-3 d-flex align-items-end'>
                                                                    <Checkbox
                                                                        isChecked={scheduleStore.currentEntity.isShiftAllowance || scheduleStore.shiftAllowance ? true : false}
                                                                        onChange={(checked) => this.handleChange('isShiftAllowance', checked)}
                                                                        label={t('Shift Allowance')}
                                                                        disabled={scheduleStore.shiftAllowance || !this.isShiftAllowanceEnabled || !isEditable}
                                                                    />
                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        overlay={
                                                                            <Tooltip id="tooltip-top">
                                                                                {t("Add shift allowance to give additional allowance to employee for the week. Shift allowance can be viewed and managed from 'Shift Allowance' tab in Schedule header, if not enabled please contact administrator")}.
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <i className="fa fa-info-circle text-muted mb-1 ms-2" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                                                                    </OverlayTrigger>
                                                                </div>
                                                                <div className='me-3 d-flex align-items-end'>
                                                                    <Checkbox
                                                                        isChecked={!!scheduleStore.currentEntity.assignEmergencyShiftToEmployeeForTheWeek || emergencyShift ? true : false}
                                                                        onChange={(checked) => this.handleChange('assignEmergencyShiftToEmployeeForTheWeek', checked)}
                                                                        label={t('Emergency Shift(On-call)')}
                                                                        disabled={emergencyShift || !this.isEmergencyShiftEnabled || !isEditable}
                                                                    />
                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        overlay={
                                                                            <Tooltip id="tooltip-top">
                                                                                {t("Add employees for emegency shifts and let them know that are emergency shift (On Call) for the week and for every emegency shift they will recieve additional payments, if not enabled please contact administrator")}.
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <i className="fa fa-info-circle text-muted mb-1 ms-2" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                                                                    </OverlayTrigger>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </div>
                                    <div className="row mt-3">
                                        {entity.isPendingShift && !isMember &&
                                            <>
                                                <div className='col-6 mb-3'>
                                                    <label className="control-label">{t('Filter employees by title')}</label>
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip-top">
                                                                {t("Please select titles to filter employees, in the invite employee dropdown")}.
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <i className="fa fa-info-circle text-muted ms-2" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                                                    </OverlayTrigger>
                                                    <CustomMultiSelect
                                                        options={this.state.rolesOptions}
                                                        value={this.state.selectedRoles || []}
                                                        onChange={(selected) => {
                                                            this.applyRoleFilter(selected);
                                                        }}
                                                        className={"custom-multi-select-max-height-150"}
                                                        overrideStrings={{
                                                            selectSomeItems: t('Select Titles'),
                                                            allItemsAreSelected: t('All Titles are selected'),
                                                            search: t('Search'),
                                                            selectAll: t('Select All'),
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-3">
                                                    <label className="control-label">{t('Group')}</label>
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip-top">
                                                                {t("Use the 'Select Group' function to automatically bulk add employees as invitees within that group")}.
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <i className="fa fa-info-circle text-muted ms-2" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                                                    </OverlayTrigger>
                                                    <CurrentUserGroupsWidget
                                                        user_id={0}
                                                        value={entity.user_group}
                                                        onChange={(value) => {
                                                            this.handleChange('user_group', value);
                                                            if (value > 0) {
                                                                const userGroupFilteredUsersIds = userOptionsDupWithoutUserGroupFilter.filter(ele => ele.user_groups.includes(value)).map(ele => ele.id);
                                                                if (userGroupFilteredUsersIds.length) {
                                                                    this.props.commonStore.addNotification(`${t('Group employees are added as invitees')}`, null, 'success');
                                                                    this.handleChange('data.invited_ids', userGroupFilteredUsersIds);
                                                                }
                                                            }
                                                            else {
                                                                this.handleChange('data.invited_ids', []);
                                                                this.props.commonStore.addNotification(`${t('All invitees are removed')}`, null, 'warning');
                                                            }
                                                        }}
                                                        isMember={isMember}
                                                    />
                                                </div>
                                            </>
                                        }
                                        <div className="col-8">
                                            <div className="form-group field field-string">
                                                {isMember && entity.assignee_id > 0 && (
                                                    <div className='mb-3'>
                                                        <label className="control-label">{t('Propose people')}</label>
                                                        <CustomMultiSelect
                                                            options={userOptions}
                                                            value={entity.data.proposed || []}
                                                            onChange={(id) => {
                                                                this.handleChange('data.proposed', id);
                                                            }}
                                                        />
                                                    </div>
                                                )}
                                                {entity.isPendingShift && !isMember && (
                                                    <div className="mb-3">
                                                        <div className="form-group field field-string">
                                                            <label className="control-label">{t('Invite people')}</label>
                                                            {!userOptions.length &&
                                                                <span className='text-danger fw-600 font-size-10 ms-2'>{t('There seems to be an issue displaying assigned employees for this project.')}</span>
                                                            }
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={
                                                                    <Tooltip id="tooltip-top">
                                                                        {t("Please select employees to invite to work on this shift")}.
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <i className="fa fa-info-circle text-muted ms-2" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                                                            </OverlayTrigger>
                                                            <CustomMultiSelect
                                                                options={userOptions}
                                                                value={scheduleStore.currentEntity.data.invited_ids || []}
                                                                onChange={(id) => {
                                                                    this.handleChange('data.invited_ids', id);
                                                                }}
                                                                className={"custom-multi-select-max-height-150"}
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        {entity.isPendingShift && !isMember &&
                                            <>
                                                <div className='col-2 offset-2'>
                                                    <label className="control-label">{t('Shift Count')}</label>
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip-top">
                                                                {t("To create multiple shifts, please increase count accordingly. Maximum available shift count is calculated by number of available members")}.
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <i className="fa fa-info-circle text-muted ms-2" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                                                    </OverlayTrigger>
                                                    <NumberInput
                                                        value={entity.shift_creation_count || shift_creation_count}
                                                        onChange={e => {
                                                            if (Number(e.target.value) < 0 || Number(e.target.value) > resources.length)
                                                                return
                                                            this.handleChange('shift_creation_count', Number(e.target.value))
                                                        }}
                                                        required
                                                    />
                                                </div>
                                                <div className={`d-flex justify-content-space-between`}>
                                                    <span className='fw-600 fs-10 text-success'>{`${t('Shifts assigned')}: ${entity.assigned_shifts?.length || 0}`}</span>
                                                    <span className='fw-600 fs-10'>{`${t('Max Shift Count can be')} ${resources.length}`}</span>
                                                </div>
                                                {ShowAssignedShifts(entity.assigned_shifts, dateTimeRules, t)}
                                            </>
                                        }
                                    </div>
                                    <div className='row mt-3'>
                                        <div className="col-12">
                                            <label className="control-label fs15">{t('Comment')}</label>
                                            <Textarea
                                                label=""
                                                value={entity.comment || ''}
                                                style={{ width: '100%', height: '50%' }}
                                                onChange={(e) => this.handleChange('comment', e.target.value)}
                                                placeholder={t('Enter comments here')}
                                                timelog_modal={"_timelog"}
                                                disabled={!isEditable}
                                            />
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-between align-items-center mt-4'>
                                        {isEditable &&
                                            <>
                                                <div>
                                                    {!isMember && !!entity.assignee_id && (
                                                        <Button fill wd
                                                            style={{ margin: "0 1em" }}
                                                            onClick={() => this.handleRun('save')}
                                                        >
                                                            {t('Save')}
                                                        </Button>
                                                    )}
                                                    {!isMember && !entity.assignee_id && (
                                                        <Button fill wd
                                                            style={{ margin: "0 1em" }}
                                                            onClick={() => this.handleRun('save_pending')}
                                                        >
                                                            {t('Save')}
                                                        </Button>
                                                    )}
                                                    {isMember && !entity.assignee_id && (
                                                        <Button fill wd
                                                            type="submit"
                                                            style={{ margin: "0 1em" }}
                                                            onClick={() => this.handleRun('member_accept')}
                                                        >
                                                            <i className="fa fa-hand-paper-o" /> {t('Get this shift')}
                                                        </Button>
                                                    )}
                                                    {isMember && !!entity.assignee_id && (
                                                        <Button btn_reject
                                                            style={{ margin: "0 1em" }}
                                                            onClick={() => this.handleRun('member_reject')}
                                                        >
                                                            <i className="fa fa-times" /> {t('Reject this shift')}
                                                        </Button>
                                                    )}
                                                    <Button wd style={{ margin: "0 1em" }}
                                                        className="btn-primary outline"
                                                        type="submit"
                                                        onClick={() => this.handleRun('cancel')}>
                                                        {t('Close')}
                                                    </Button>
                                                </div>
                                                <div className='ml-30 d-flex justify-content-space-between align-items-center'>
                                                    {!isMember && !!entity.assignee_id && (
                                                        <>
                                                            {isSchedulePublishUnpublishFeatueEnabled &&
                                                                <div className='me-3'>
                                                                    <Checkbox
                                                                        isChecked={this.state.isShiftPublished}
                                                                        onChange={(checked) => this.setState({ isShiftPublished: checked }, () => {
                                                                            this.props.scheduleStore.changeEntity('do_notify', checked);
                                                                        })}
                                                                        label={this.props.t('Publish')}
                                                                    />
                                                                </div>
                                                            }
                                                            <Checkbox
                                                                isChecked={this.props.scheduleStore.currentEntity.do_notify}
                                                                onChange={(checked) => this.handleChange('do_notify', checked)}
                                                                label={this.props.t('Notify user')}
                                                                disabled={!this.state.isShiftPublished}
                                                            />
                                                        </>
                                                    )}
                                                    <div className="d-flex align-items-center">
                                                        {!!entity.id && !isMember && (
                                                            <Button btn_reject
                                                                onClick={() => this.handleRun('delete')}
                                                                style={{ margin: "0 1em" }}
                                                            >
                                                                {t('Delete')}
                                                            </Button>
                                                        )}
                                                    </div>
                                                </div>
                                            </>}
                                    </div>
                                    {!isMember && !!entity.assignee_id && isSchedulePublishUnpublishFeatueEnabled &&
                                        <div className="text-muted shift_publish_note">
                                            <span>
                                                {isEditable ?
                                                    isShiftPublished ?
                                                        scheduleStore.currentEntity.do_notify ?
                                                            t('Please note that all shifts for this day will be published and members will be notified')
                                                            : t('Please note that all shifts for this day will be published')
                                                        :
                                                        t('Please note that all shifts for this day will not be published and members will not be notified')

                                                    :
                                                    t("You are viewing this shift in readonly mode as you don't authorization to modify it.")
                                                }
                                            </span>
                                        </div>
                                    }
                                </div>
                                {/*=========================== Code For Adding Multiple Shift at a time (Starts)============================ */}
                                {/*=========================== For Showing Shift detail which PM/Admin are adding ========================== */}
                                {responseData?.length > 0 &&
                                    <div className="col-md-4 col-lg-4 multiple_shift_seperator">
                                        {responseData?.map((ele, index) => (
                                            <div key={uuid()}
                                                className="mt-2 p-1 border-radius-10"
                                                style={{ backgroundColor: !(index % 2 === 0) ? "#FFFFFF" : "#FAFAFA", border: "1px solid rgba(0, 0, 0, 0.125)" }} >
                                                <div className='ml-10'>
                                                    <strong style={{ color: '#2550AC' }}>
                                                        {ele.assignedMemberName}
                                                    </strong>
                                                </div>
                                                <div className="d-flex  justify-content-start flex-wrap" style={{ fontSize: '12px', paddingLeft: '10px' }}>
                                                    <div className='me-2'>
                                                        {shortDateFormat(ele.for_date, dateTimeRules)}
                                                    </div>
                                                    <div className='me-2' style={{ border: "1px solid rgba(0, 0, 0, 0.125)" }}></div>
                                                    <div className='d-flex align-items-end'>
                                                        {timeFormat(ele.start_time, dateTimeRules)} - {timeFormat(ele.end_time, dateTimeRules)}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                }
                                {/*=========================== Code For Adding Multiple Shift at a time (ends)===================   ========= */}
                            </div>
                        </Modal.Body>
                    }
                </Modal>
                <Modal show={isModalShowForChooseMemberForShiftReplace} onHide={() => this.setState({ isModalShowForChooseMemberForShiftReplace: false })}>
                    <Modal.Header closeButton className="set_ModalHeader">
                        <Modal.Title>{t('Select Member to change the shift with')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{ minHeight: 350 }}>
                            <label className="control-label">{t('Choose People whom you want to give your shift')}</label>
                            <CustomMultiSelect
                                options={memberWithName_SameProject ? memberWithName_SameProject : userOptions}
                                value={users}
                                onChange={selected => this.handleProposePeopleChange(selected)}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        {id && isMember &&
                            <Button
                                icon
                                type="submit"
                                onClick={() => this.sendShiftToReplace()}
                                disabled={entity.status === 'pending-swap' ? true : false}
                            >
                                {t('Send Request to members')}
                            </Button>
                        }
                        <Button className="btn-primary outline" wd icon type="submit" onClick={() => this.setState({ isModalShowForChooseMemberForShiftReplace: false })}>
                            {t('Cancel')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Fragment >
        );
    }
}

export default inject('userStore', 'commonStore', 'scheduleStore')(applicationRouter(withLocalization(observer(ShiftForm))));