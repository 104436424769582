import React, { Component } from 'react';
import applicationRouter from '~/hoc/applicationRouter'
import { inject, observer } from 'mobx-react';
import { Row, Col } from 'react-bootstrap';
import withLocalization from '~/hoc/withLocalization';
import Input, { InputGroup, NumberInput } from '../../../../elements/Input';
import Select from '../../../../elements/Select';

const countries = [
    { value: "United States", label: "United States", title: "United States", code: "+1", flag: "US" },
    { value: "Russia", label: "Russia", title: 'Russia', code: '+7', flag: 'RU' },
    { value: "Greek", label: "Greek", title: 'Greek', code: '+30', flag: 'GR' },
    { value: "Sweden", label: "Sweden", title: 'Sweden', code: '+46', flag: 'SE' },
    { value: "Norway", label: "Norway", title: "Norway", code: "+47", flag: 'NO' },
    { value: "Poland", label: "Poland", title: 'Poland', code: '+48', flag: 'PL' },
    { value: "Spanish", label: "Spanish", title: 'Spanish', code: '+34', flag: 'ES' },
    { value: "Lithuania", label: "Lithuania", title: 'Lithuania', code: '+370', flag: 'LT' }
];

const shortDateFormat = [
    { value: 'DD.MM.YYYY', label: 'DD.MM.YYYY', title: 'DD.MM.YYYY' },
    { value: 'DD/MM/YYYY', label: 'DD/MM/YYYY', title: 'DD/MM/YYYY' },
    { value: 'DD-MM-YYYY', label: 'DD-MM-YYYY', title: 'DD-MM-YYYY' },
];

const longDateFormat = [
    { value: 'EEEE MMMM dd, yyyy', label: 'Day Month Date, Year (EEEE MMMM DD, YYYY)', title: 'Day Month Date, Year (EEEE MMMM DD, YYYY)' },
    { value: 'MMMM dd', label: 'Month Date (MMMM DD)', title: 'Month Date (MMMM DD)' },
];

const timeFormat = [
    { value: 'hh:mm', label: '24 hours format (hh:mm)', title: '24 hours format (hh:mm)' },
    { value: 'hh:mm a', label: '12 hours format (hh:mm a)', title: '12 hours format (hh:mm a)' },
];

const totalTimeFormat = [
    { value: 'decimal', label: 'Decimal', title: 'Decimal' },
    { value: 'minutes', label: 'Analog', title: 'Analog' },
];

const addressFormat = [
    { value: 'address,pincode,city', label: 'Address,Zipcode,City', title: 'Address,Zipcode,City' },
    { value: 'address,city,pincode', label: 'Address,City,Zipcode', title: 'Address,City,Zipcode' },
];

const currencies = [
    { value: 'nok', label: 'Norwegian krone (nok)', title: 'Norwegian krone (nok)' },
    { value: '$', label: 'US Dollar ($)', title: 'US Dollar ($)' },
];

const distanceFormat = [
    { value: 'km', label: 'Kilometer (KM)', title: 'Kilometer (KM)' },
    { value: 'miles', label: 'Miles', title: 'Miles' },
];

class DateTimeRules extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dateTimeRules: {
                country: "Norway",
                country_code: "+47",
                short_date_format: "DD.MM.YYYY",
                long_date_format: "EEEE MMMM dd, yyyy",
                time_format: "hh:mm",
                total_time_format: 'decimal',
                address_format: "address,pincode,city",
                currency: "nok",
                distance_unit: "km",
                currency_decimal_places: 2,
                country_flag: "NO"
            },
        };
        this.handleChange = this.handleChange.bind(this);
        this.loadData = this.loadData.bind(this);
        this.handleUserDataChange = this.handleUserDataChange.bind(this);
        this.translateLabels = this.translateLabels.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = () => {
        const { currentEntity } = this.props.clientStore;
        const { dateTimeRules } = this.state;
        if (currentEntity.client.data && currentEntity.client.data.dateTimeRules) {
            const _dateTimeRules = Object.assign({}, currentEntity.client.data.dateTimeRules);
            dateTimeRules.country = _dateTimeRules.country ? _dateTimeRules.country : "Norway";
            dateTimeRules.country_code = _dateTimeRules.country_code ? _dateTimeRules.country : 47;
            dateTimeRules.short_date_format = _dateTimeRules.short_date_format ? _dateTimeRules.short_date_format : "DD.MM.YYYY";
            dateTimeRules.long_date_format = _dateTimeRules.long_date_format ? _dateTimeRules.long_date_format : "EEEE MMMM dd, yyyy";
            dateTimeRules.time_format = _dateTimeRules.time_format ? _dateTimeRules.time_format : "hh:mm";
            dateTimeRules.total_time_format = _dateTimeRules.total_time_format ? _dateTimeRules.total_time_format : "decimal";
            dateTimeRules.address_format = _dateTimeRules.address_format ? _dateTimeRules.address_format : "address,pincode,city";
            dateTimeRules.currency = _dateTimeRules.currency ? _dateTimeRules.currency : "nok";
            dateTimeRules.distance_unit = _dateTimeRules.distance_unit ? _dateTimeRules.distance_unit : "km";
            dateTimeRules.currency_decimal_places = _dateTimeRules.currency_decimal_places ? _dateTimeRules.currency_decimal_places : 2;
            dateTimeRules.country_flag = _dateTimeRules.country_flag ? _dateTimeRules.country_flag : "NO";
            this.setState({ dateTimeRules })
        }
    };

    handleUserDataChange = (name, value) => {
        const { currentEntity } = this.props.clientStore;
        if (currentEntity.client.data.dateTimeRules) {
            currentEntity.client.data.dateTimeRules[name] = value;
        } else {
            let dateTimeRules = {};
            dateTimeRules[name] = value;
            currentEntity.client.data.dateTimeRules = dateTimeRules;
        }
    };

    handleChange = (name, value) => {
        const { dateTimeRules } = this.state;
        dateTimeRules[name] = value;
        this.handleUserDataChange(name, value);
        this.setState({ dateTimeRules });
    };

    translateLabels() {
        const { t } = this.props;
        countries.map((item) => {
            item.label = t(item.title);
        });
        shortDateFormat.map((item) => {
            item.label = t(item.title);
        });
        longDateFormat.map((item) => {
            item.label = t(item.title);
        });
        timeFormat.map((item) => {
            item.label = t(item.title);
        });
        totalTimeFormat.map((item) => {
            item.label = t(item.title);
        });
        addressFormat.map((item) => {
            item.label = t(item.title);
        });
        currencies.map((item) => {
            item.label = t(item.title);
        });
        distanceFormat.map((item) => {
            item.label = t(item.title);
        });
    }

    render() {
        const { dateTimeRules } = this.state;
        const { t } = this.props;
        this.translateLabels();
        return (
            <div>
                <Row>
                    <h6 >{t('Country settings')}</h6>
                    <Col sm={6}>
                        <label className="control-label">{t('Country')}</label>
                        <Select
                            options={countries}
                            value={countries.find(item => item.value === dateTimeRules.country)}
                            onChange={selectedItem => {
                                this.handleChange('country', selectedItem.value);
                                this.handleChange('country_code', selectedItem.code);
                                this.handleChange('country_flag', selectedItem.flag);
                            }}
                            style={{ width: '100%' }}
                        />
                    </Col>
                    <Col sm={6}>
                        <label className="control-label">{t('Country Code')}</label>
                        <InputGroup
                            code={countries.find((item) => item.value === dateTimeRules.country).code}
                            flag={countries.find((item) => item.value === dateTimeRules.country).flag}
                            style={{ width: '100%' }}
                            disabled={true}
                        />
                    </Col>
                </Row>
                <Row style={{ marginTop: '2rem' }}>
                    <h6 >{t('Date format settings')}</h6>
                    <Col sm={6}>
                        <label className="control-label">{t('Short Date Format')}</label>
                        <Select
                            options={shortDateFormat}
                            value={shortDateFormat.find((item) => item.value === dateTimeRules.short_date_format)}
                            onChange={(selectedItem) => this.handleChange('short_date_format', selectedItem.value)}
                            style={{ width: '100%' }}
                        />
                    </Col>
                    <Col sm={6}>
                        <label className="control-label">{t('Long Date Format')}</label>
                        <Select
                            options={longDateFormat}
                            value={longDateFormat.find((item) => item.value === dateTimeRules.long_date_format)}
                            onChange={(selectedItem) => this.handleChange('long_date_format', selectedItem.value)}
                            style={{ width: '100%' }}
                        />
                    </Col>
                </Row>
                <Row style={{ marginTop: '2rem' }}>
                    <h6 >{t('Time format settings')}</h6>
                    <Col sm={6}>
                        <label className="control-label">{t('Time Format')}</label>
                        <Select
                            options={timeFormat}
                            value={timeFormat.find((item) => item.value === dateTimeRules.time_format)}
                            onChange={(selectedItem) => this.handleChange('time_format', selectedItem.value)}
                            style={{ width: '100%' }}
                        />
                    </Col>
                    <Col sm={6}>
                        <label className="control-label">{t('Total Time Format')}</label>
                        <Select
                            options={totalTimeFormat}
                            value={totalTimeFormat.find((item) => item.value === dateTimeRules.total_time_format)}
                            onChange={(selectedItem) => this.handleChange('total_time_format', selectedItem.value)}
                            style={{ width: '100%' }}
                        />
                    </Col>
                </Row>
                <Row style={{ marginTop: '2rem' }}>
                    <h6 >{t('Currency format settings')}</h6>
                    <Col sm={6}>
                        <label className="control-label">{t('Currency Symbol')}</label>
                        <Select
                            options={currencies}
                            value={currencies.find((item) => item.value === dateTimeRules.currency)}
                            onChange={(selectedItem) => this.handleChange('currency', selectedItem.value)}
                            style={{ width: '100%' }}
                        />
                    </Col>
                    <Col sm={6}>
                        <NumberInput
                            label={`${t('Currency decimal places')}`}
                            value={dateTimeRules.currency_decimal_places}
                            onChange={(e) => {if(e.target.value >=0 && e.target.value < 5)this.handleChange('currency_decimal_places', e.target.value)}}
                        />
                    </Col>
                </Row>
                <Row style={{ marginTop: '2rem' }}>
                    <h6 >{t('Distance and address format settings')}</h6>
                    <Col sm={6}>
                        <label className="control-label">{t('Distance Unit')}</label>
                        <Select
                            options={distanceFormat}
                            value={distanceFormat.find((item) => item.value === dateTimeRules.distance_unit)}
                            onChange={(selectedItem) => this.handleChange('distance_unit', selectedItem.value)}
                            style={{ width: '100%' }}
                        />
                    </Col>
                    <Col sm={6}>
                        <label className="control-label">{t('Address Format')}</label>
                        <Select
                            options={addressFormat}
                            value={addressFormat.find((item) => item.value === dateTimeRules.address_format)}
                            onChange={(selectedItem) => this.handleChange('address_format', selectedItem.value)}
                            style={{ width: '100%' }}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}
export default inject('clientStore', 'commonStore', 'userStore')(applicationRouter(withLocalization(observer(DateTimeRules))));
