import { cloneDeep } from "lodash";
import { extraPaymentWageTypes } from '../../utils/constants';
import { translateLabels } from '../../utils/commonMethods';

export const schema = {
    title: 'Extra Payments',
    type: 'object',
    required: ['name', 'extraPaymentType', 'fullDay', 'fromTime', 'toTime', 'daysOfWeek', 'employmentTypes'],
    properties: {
        code: { type: 'string' },
        name: { type: ['string'], title: 'Name', format: 'name(Only a-z, 0-9 and % are allowed)' },
        extraPaymentType: { type: ['string'], title: 'Wage Type', default: 'amount', anyOf: [] },
        payrollWageType: {
            type: ['string', 'null'], title: 'Payroll Supplement Type',
            anyOf: [
                {
                    title: 'Select payroll supplement',
                    key: 'Select payroll supplement',
                    enum: ['null'],
                }
            ], default: 'null'
        },
        fullDay: {
            type: 'boolean',
            title: 'Mark as Holiday',
            default: false,
            anyOf: [
                {
                    title: 'Yes',
                    enum: [true],
                },
                {
                    title: 'No',
                    enum: [false],
                },
            ],
        },
        fromTime: { type: ['integer'], title: 'Start time', default: 0, readOnly: false },
        toTime: { type: ['integer'], title: 'End time', default: 0, readOnly: false },
        amount: { type: ['number'], title: 'Amount Or %', default: 0 },
        daysOfWeek: { type: ['string'], title: 'Days of week', default: '0,1,2,3,4,5,6' },
        task: { type: ['number'], title: 'Task', default: 0 },
        userGroups: { type: 'string', title: 'User groups', default: 'all' },
        employmentTypes: {
            type: 'string', title: 'Employee type',
            anyOf: [
                {
                    title: 'All',
                    key: 'All',
                    enum: ['all'],
                },
                {
                    title: 'Employee with fixed salary',
                    key: 'Employee with fixed salary',
                    enum: ['fixed'],
                },
                {
                    title: 'Employee with hourly salary',
                    key: 'Employee with hourly salary',
                    enum: ['hourly'],
                }
            ], default: 'all'
        },
        description: { type: ['string', 'null'], title: 'Description' },
        isNotDeletable: { type: 'boolean', default: false },
        isActive: { type: 'boolean', title: 'Active', default: true }
    },
};



export const getSchema = ({ isNotDeletable, code, payrollWagetypes, translate }) => {
    const _schema = cloneDeep(schema);
    for (let wageType of extraPaymentWageTypes) {
        _schema.properties.extraPaymentType.anyOf.push({
            title: wageType.label,
            key: wageType.label,
            enum: [wageType["value"]]
        })
    }
    if (payrollWagetypes && payrollWagetypes.length) {
        for (let _payrollWagetype of payrollWagetypes) {
            _schema.properties.payrollWageType.anyOf.push({
                title: _payrollWagetype.label,
                key: _payrollWagetype.label,
                enum: [_payrollWagetype["value"]],
            })
        }
    }

    if (isNotDeletable) {
        _schema.properties.name.readOnly = true;
        _schema.properties.extraPaymentType.readOnly = true;
        _schema.properties.task.readOnly = true;
        switch (code) {
            case 'weekends': {
                _schema.properties.fromTime.readOnly = true;
                _schema.properties.toTime.readOnly = true;
                _schema.properties.fullDay.readOnly = true;
                _schema.properties.userGroups.readOnly = true;
                _schema.properties.employmentTypes.readOnly = true;
                break;
            }
            case 'emergency_shifts': {
                _schema.properties.fullDay.readOnly = true;
                _schema.properties.userGroups.readOnly = true;
                _schema.properties.employmentTypes.readOnly = true;
                break;
            }
            case 'shift_allowance': {
                _schema.properties.daysOfWeek.readOnly = true;
                _schema.properties.fromTime.readOnly = true;
                _schema.properties.toTime.readOnly = true;
                _schema.properties.fullDay.readOnly = true;
                _schema.properties.userGroups.readOnly = true;
                _schema.properties.employmentTypes.readOnly = true;
                _schema.properties.amount.readOnly = true;
                break;
            }
            default:
                break;
        }
    }

    if (_schema.properties.extraPaymentType.anyOf) {
        translateLabels(_schema.properties.extraPaymentType.anyOf, translate);
    }
    if (_schema.properties.employmentTypes.anyOf) {
        translateLabels(_schema.properties.employmentTypes.anyOf, translate);
    }
    if(_schema.properties.payrollWageType.anyOf){
        translateLabels(_schema.properties.payrollWageType.anyOf, translate);
    }
    return _schema;
};

export default getSchema;
