import React from 'react';
import { inject } from 'mobx-react';

const restrictedForMember = ['project-add', 'project-edit', 'project-delete', 'chat-configure'];
const restrictedForPM = ['project-delete'];
const bizTyped = ['show_times_on_project'];

const withPermissions = Component => {
    class permissions extends React.Component {
        allowed = (op, id) => {
            const { commonStore, userStore } = this.props;
            const { config } = commonStore;
            const bizType = !config || !config.biztype || !config.biztype.data ? {} : config.biztype.data;
            if (userStore.currentUser.user_type === 'member') {
                if (restrictedForMember.indexOf(op) >= 0) return false;
            }
            if (userStore.currentUser.user_type === 'pm') {
                if (restrictedForPM.indexOf(op) >= 0) return false;
            }
            if (bizTyped.indexOf(op) >= 0 && !bizType[op]) return false;
            return true;
        };

        render() {
            // const { lang, translations } = store.getState().i18nState;

            return <Component {...this.props} aclAllowed={(op, id) => this.allowed(op, id)} />;
        }
    }

    return inject('commonStore', 'userStore')(permissions);
};

export default withPermissions;
