import React, { Component } from 'react';

import { Input } from '../../elements/Input';
import trashIcon from '../../assets/img/trash.svg';
import Button from '../CustomButton/CustomButton.jsx';
import withLocalization from '../../hoc/withLocalization';
import plusIcon from '../../assets/img/plusIcon.svg'

@withLocalization
class DynamicList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            value: '',
        };
    }

    componentDidMount() {
        const { initData } = this.props;
        this.setState({
            items: initData || [],
        });
    }

    addItem = e => {
        e.preventDefault();
        const state = Object.assign({}, this.state);
        const { items, value } = state;
        const { onItemsChange } = this.props;
        items.push(value);
        state.value = '';
        this.setState(state, () => {
            if (onItemsChange) onItemsChange(items)
        });
    };

    removeItem = index => {
        const state = Object.assign({}, this.state);
        const { onItemsChange } = this.props;
        state.items = state.items.filter((item, i) => i !== index);
        this.setState(state, () => {
            if (onItemsChange) onItemsChange(state.items)
        });
    };

    handleSave = () => {
        this.props.onSave(this.state.items);
    };

    handleChange = value => {
        const state = Object.assign({}, this.state);
        state.value = value;
        this.setState(state);
    };

    render() {
        const { value, items } = this.state;
        const { label, placeholder, t } = this.props;
        return (
            <form className="dynamic-list" onSubmit={this.addItem}>
                {!items.length && <div className="dynamic-list__top mb-4">{t('No rows added')}</div>}
                <div className="dynamic-list__left">
                    <Input
                        label={label}
                        value={value}
                        onChange={e => this.handleChange(e.target.value)}
                        placeholder={placeholder}
                        className="timelog-array__input"
                        required
                    />
                    <div className="dynamic-list__buttons">
                        <Button fill dynamic_lg type="submit">
                            <div className='d-flex justify-content-center'>
                                <i className='d-flex margin-right-10'>
                                    <img className='me-2' src={plusIcon} alt='cross' />
                                </i>
                                {this.props.t('Save and add new')}
                            </div>
                        </Button>
                        {/* <Button fill wd icon onClick={this.handleSave}><img src={saveIcon} style={{paddingBottom: 3, marginRight: 5}} alt="Save"/>{t('Save')}</Button> */}
                    </div>
                </div>
                {!!items.length && (
                    <div className="dynamic-list__right">
                        <div className='timelog-custom-table-label'>List view</div>
                        <div className="timelog-array__table">
                            {items.map((item, index) => (
                                <div className="custom-list-table-row mt-2" key={index}>
                                    <div className="timelog-array__col" style={{ width: '25%' }}>
                                        {index + 1}
                                    </div>
                                    <div
                                        className="timelog-array__col timelog-array__col_center"
                                        style={{ width: '45%' }}
                                    >
                                        {item}
                                    </div>
                                    <div className="timelog-array__col timelog-array__col_right" style={{ width: '30%' }}>
                                        <button
                                            type="button"
                                            className="timelog-array__col-action"
                                            onClick={() => this.removeItem(index)}
                                        >
                                            <img src={trashIcon} alt="Remove Item" />
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </form>
        );
    }
}

export default DynamicList;
