import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Row, Col, Tab } from 'react-bootstrap';

import applicationRouter from '~/hoc/applicationRouter'
import withLocalization from '~/hoc/withLocalization';
import KilometerRules from './KilometerRules';
import VacationTypes from './VacationTypes';
import AbsentTypes from './AbsentTypes';
import ExpenseTypes from './ExpenseTypes';
import ExtraworkTypes from './ExtraworkTypes';
import SubscriptionRules from './SubscriptionRules';
import UserGroups from './UserGroups';

class ExtendedRules extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subTab: 'kilometerRules',
        }
        this.handleSubTabChange = this.handleSubTabChange.bind(this);
        this.handleUserDataChange = this.handleUserDataChange.bind(this);
    }

    handleSubTabChange = (tabName) => {
        this.setState({ subTab: tabName })
    }

    handleUserDataChange = (name, value) => {
        const { currentEntity } = this.props.clientStore;
        if (currentEntity.client.data.extendedRules) {
            currentEntity.client.data.extendedRules[name] = value;
        }
    }

    render() {
        const { subTab } = this.state;
        const { t } = this.props;
        return (
            <Tab.Container activeKey={subTab} id="ExtendedTabContainer" onSelect={(key) => { }}>
                <div className="custom-card">
                    <Row>
                        <Col sm={3}>
                            <ul className="list-group" >
                                <li className={subTab === 'kilometerRules' ? 'list-group-item active cursor-pointer' : 'list-group-item cursor-pointer'}
                                    onClick={() => this.handleSubTabChange('kilometerRules')}>{t('Rules for Kilometers')}</li>
                                <li className={subTab === 'vacationTypes' ? 'list-group-item active cursor-pointer' : 'list-group-item cursor-pointer'}
                                    onClick={() => this.handleSubTabChange('vacationTypes')}>{t('Leave types')}</li>
                                {/* <li className={subTab === 'absentTypes' ? 'list-group-item active cursor-pointer' : 'list-group-item cursor-pointer'}
                                    onClick={() => this.handleSubTabChange('absentTypes')}>{t('Absence types')}</li> */}
                                <li className={subTab === 'expenseTypes' ? 'list-group-item active cursor-pointer' : 'list-group-item cursor-pointer'}
                                    onClick={() => this.handleSubTabChange('expenseTypes')}>{t('Expense types')}</li>
                                <li className={subTab === 'extraworkTypes' ? 'list-group-item active cursor-pointer' : 'list-group-item cursor-pointer'}
                                    onClick={() => this.handleSubTabChange('extraworkTypes')}>{t('Extra Work Types')}</li>
                                <li className={subTab === 'userGroups' ? 'list-group-item active cursor-pointer' : 'list-group-item cursor-pointer'}
                                    onClick={() => this.handleSubTabChange('userGroups')}>{t('User groups')}</li>
                                <li className={subTab === 'subscriptionRules' ? 'list-group-item active cursor-pointer' : 'list-group-item cursor-pointer'}
                                    onClick={() => this.handleSubTabChange('subscriptionRules')}>{t('Subscription Rules')}</li>
                            </ul>
                        </Col>
                        <Col sm={9}>
                            <Tab.Content >
                                <Tab.Pane eventKey="kilometerRules">
                                    <KilometerRules
                                        handleUserDataChange={this.handleUserDataChange} />
                                </Tab.Pane>
                                <Tab.Pane eventKey="vacationTypes">
                                    <VacationTypes
                                        handleUserDataChange={this.handleUserDataChange} />
                                </Tab.Pane>
                                {/* <Tab.Pane eventKey="absentTypes">
                                    <AbsentTypes 
                                    handleUserDataChange = {this.handleUserDataChange}/>
                                </Tab.Pane> */}
                                <Tab.Pane eventKey="expenseTypes">
                                    <ExpenseTypes
                                        handleUserDataChange={this.handleUserDataChange} />
                                </Tab.Pane>
                                <Tab.Pane eventKey="extraworkTypes">
                                    <ExtraworkTypes
                                        handleUserDataChange={this.handleUserDataChange} />
                                </Tab.Pane>
                                <Tab.Pane eventKey="userGroups">
                                    <UserGroups
                                        handleUserDataChange={this.handleUserDataChange} />
                                </Tab.Pane>
                                <Tab.Pane eventKey="subscriptionRules">
                                    <SubscriptionRules
                                        handleUserDataChange={this.handleUserDataChange} />
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </div>
            </Tab.Container>
        );
    }
}
export default inject('clientStore')(applicationRouter(withLocalization(observer(ExtendedRules))));
