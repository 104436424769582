import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import withLocalization from '../../../../hoc/withLocalization';
import { Rnd } from 'react-rnd';
import { Transports } from '@sentry/browser';

export class CheckBoxWidget extends Component {
    constructor(props) {
        super(props);
        this.state = {
            x: this.props.info.widgetX || 0,
            y: this.props.info.widgetY || 0,
            width: this.props.info.width || '30px',
            height: this.props.info.height || '30px',
        }
    }
    setActiveWidget(evt, widget) {
        const { propertyStore } = this.props;
        propertyStore.closeActivePanel();
        propertyStore.setActiveWidget(widget)

    }
    onDragStop = (evt, position, at) => {
        const { propertyStore, info } = this.props;
        const DEFAULT_WIDTH = 35;
        const DEFAULT_HEIGHT = 35;
        const updatedWidth = (propertyStore.activeWidget && propertyStore.activeWidget.width) ? propertyStore.activeWidget.width : ( this.state.width ? this.state.width : DEFAULT_WIDTH);
        const updatedHeight = (propertyStore.activeWidget && propertyStore.activeWidget.height) ? propertyStore.activeWidget.height : ( this.state.height ? this.state.height : DEFAULT_HEIGHT);
        const widInfo = { x: position.x, y: position.y,width:updatedWidth, height:updatedHeight  };
        propertyStore.updateWidgetRnd(info.widgetId, widInfo)
    }

    onResizeStop = (e, direction, ref, delta, position) => {
        const widInfo = { x: position.x, y: position.y, width: ref.style.width, height: ref.style.height }

        const { propertyStore, info } = this.props;
        propertyStore.updateWidgetRnd(info.widgetId, widInfo)
    }

    onCheckChange(evt){
        if(this.props.edit){
          
            const { propertyStore, signatureStore } = this.props;
            const  checkBox = this.props.info.checkBoxes;
            const index = checkBox.findIndex((ele)=> ele.label === evt.target.value); 
            checkBox[index].value = evt.target.checked;
            
            propertyStore.updateWidgetData(this.props.info,'checkBoxes',checkBox);
            const data = { caseId: signatureStore.caseId, widgets: propertyStore.widgets };
          //  propertyStore.saveWidget(data);
        }
    }
    hexToRgbA(hex) {
        var c;
        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
            c = hex.substring(1).split('');
            if (c.length == 3) {
                c = [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c = '0x' + c.join('');
            return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',.4)';
        }
        throw new Error('Bad Hex');
    }

    render() {
        const { x, y, width, height } = this.state;
        const widgetColor = this.hexToRgbA(this.props.info ? this.props.info.colorCode : "#ffd65b");
        return (
            <>
                <Rnd
                    minHeight={30}
                    minWidth={30}
                    default={{
                        x,
                        y,
                        width,
                        height,
                    }}
                    onDragStop={this.onDragStop}
                    onResizeStop={this.onResizeStop}
                    bounds="parent"
                    disableDragging={this.props.locked}
                    enableResizing={!this.props.locked}

                >
                    <div className="CheckboxCon" onClick={(evt) => { this.setActiveWidget(evt, this.props.info) }}>
                        {
                            this.props.info.checkBoxes.map((value) => {
                                return (
                                    <div key={value.id} className="chkBox" style={ this.props.view ?  {backgroundColor: "transparent" } : this.props.owner ? {backgroundColor: "transparent" } : { backgroundColor: widgetColor }}>
                                        <input type="checkbox" disabled={this.props.disable ? true : false} value={value.label} style={{margin: "0",height: "16px",width: "16px"}} onChange={(evt)=>{ this.onCheckChange(evt) }} defaultChecked={value.value}/>
                                    </div>)
                            })
                        }
                    </div>
                </Rnd>
            </>
        )

    }
}

export default inject('propertyStore', 'signatureStore')(withLocalization(observer(CheckBoxWidget)));