import React, { Component } from 'react';
import applicationRouter from '~/hoc/applicationRouter';
import { inject, observer } from 'mobx-react';

import Button from '../../../components/CustomButton/CustomButton.jsx';
import withRemoveDialog from '~/hoc/withRemoveDialog';
import withLocalization from '~/hoc/withLocalization';
import GenericList from '~/components/GenericList/GenericList';
import Edit from '../../../assets/img/editing.svg';
import Delete from '../../../assets/img/deleting.svg';
// Elements

class BizTypesList extends Component {
    handleTableButtonAction = (id, type) => {
        // this.props.onTableAction(id, type);
        if (type === 'edit') this.props.router.navigate(`/admin/biztypes/${id}`, { state: { biztype: id } });
        if (type === 'remove') {
            return this.props.handleDelete(id, (id) => {
                return this.props.biztypeStore.remove(id).then((res) => {
                    this.props.biztypeStore.resetLastListLoadTime();
                    return res;
                });
            });
        }
    };

    renderTableActionButtons = (id) => (
        <div className="actions-center actions-center__center">
            <Button icon_sm fill onClick={() => this.handleTableButtonAction(id, 'edit')}>
                <img src={Edit} alt={"edit button"} />
            </Button>
            <Button icon_sm_delete fill onClick={() => this.handleTableButtonAction(id, 'remove')}>
                <img src={Delete} alt="delete button" />
            </Button>
        </div>
    );

    renderHeader = () => <div className="header-meta"></div>;

    render() {
        return (
            <GenericList
                tableHeader={this.props.t('Business Types')}
                columns={[
                    {
                        Header: 'Name',
                        id: 'name',
                        accessor: ({ name }) => <span>{name}</span>,
                        isSortable: true,
                    },
                    {
                        Header: 'Operation',
                        id: 'operation',
                        accessor: ({ id }) => this.renderTableActionButtons(id),
                    },
                ]}
                lastListLoadTime={this.props.biztypeStore.lastListLoadTime}
                forceReload={this.props.biztypeStore.deleteSuccess}
                header={this.renderHeader()}
                requestData={(params) => this.props.biztypeStore.loadList(params)}
            >
                <Button fill wd onClick={() => this.props.onAdd()}>
                    <i className="fa fa-plus-circle fa-xl margin-right-10" />
                    {this.props.t('Add new')}
                </Button>
            </GenericList>
        );
    }
}
export default inject('biztypeStore')(applicationRouter(withLocalization(withRemoveDialog(observer(BizTypesList)))));
