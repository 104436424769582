import React, { Component } from 'react';
import applicationRouter from '~/hoc/applicationRouter'
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import moment from 'moment/min/moment-with-locales';
import { getMinutesFromTimeInput, minutesToTimeInput } from '../../../utils/timeCalc';
import '../../../styles/css/styles.css';

import withLocalization from '~/hoc/withLocalization';
import withRemoveDialog from '~/hoc/withRemoveDialog';
import CheckBoxWidget from '../../../components/GenericForm/Widgets/CheckBoxWidget';
import Select from '../../../elements/Select';
import DatePicker from '../../../components/DatePicker/DatePicker';
import { longDateFormat } from '../../../utils/dateFormat';
import { FormControl } from 'react-bootstrap';
import TimePickerInput from '../../../components/TimePicker';
import { getDay } from 'date-fns';
import Switch from 'react-switch';
import { Textarea } from '../../../elements/Input';


class ScheduleAddAvailability extends Component {
    state = {
        from: moment(new Date()).startOf('month').toDate(),
        to: moment(new Date()).toDate(),
        from_date: new Date(),
        to_date: new Date(),
        checklist: {},
        showErrors: false,
        isProjectMembers: false,
        projectMembers: [],
        selectedMember: '',
        all:false,
        selected_date:null,
        isAddAvailability: false,
        isChecked: false,
        days : ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
        dates : [],
        data : this.props.selectedRowData ? this.props.selectedRowData : {"client_id": null,"user_id": null,"availability": [],"from_date": "","to_date": "", "request_type": null,"isToTimePicker" : false },
    }

    onDaysSelectionChange(index, value) {
        let newData = this.state.data;
        switch (value) {
            case true:
                delete newData.availability[index].from;
                delete newData.availability[index].to;
                break;
            case false:
                newData.availability[index].from = 0;
                newData.availability[index].to = 0;
                break;
            default:
                break;
        }
        this.setState({data : newData});
        this.props.setData(newData);
    }

    onAvailabilitySelectionChange(checked, index) {
        let newData = this.state.data;
        if(checked){
            newData.availability[index].availability_type = 'available';
        } else {
            newData.availability[index].availability_type = 'not available';
        }
        this.setState({data : newData});
        this.props.setData(newData);
    }

    handleWeekDateChange = (name, value) => {
        let newData = this.state.data;
        // newData[name] = moment(value).format("YYYY-MM-DD");
        newData[name] = value;
        this.setState({data : newData});
        this.props.setData(newData);
        this.getDatesInRange(newData.from_date, newData.to_date);
    };

    setInputValues = (index, name, value) => {
        let newData = this.state.data;
        newData.availability[index][name] = value;
        if(newData.availability[index]['from']){
            newData.availability[index]['isToTimePicker'] = true;
        }
        this.setState({data : newData});
        this.props.setData(newData);
    };

    disableWeekends = current => {
        return current.day() === 0 && current.day() === 6;
    }

    getDatesInRange = (startDate, endDate) => {
        const date = new Date(startDate.getTime());
        const dates = [];
        while (date <= endDate) {
          dates.push(new Date(date));
          date.setDate(date.getDate() + 1);
        }
        let newData = this.state.data;
        newData.availability = [];
        dates.map(date => {
            let temp = date;
            newData.availability.push({for_date : temp, availability_type: 'available'});
        });
        this.setState({data : newData, dates : dates});
        this.props.setData(newData);

    }

    getDayWorkingDayCircle = (date) => {
        const {days} = this.state;
        const day = new Date(date.for_date).getDay();
        const isWorkingDay =  day != 0 &&  day != 6;

        return(
          isWorkingDay ?  <span className='workingDayCircle me-2'></span> : <span className='workingDayCircle me-2' style={{background: 'red'}}></span>
        )
    }

    getDay = (date) => {
        const {days} = this.state;
        return days[new Date(date.for_date).getDay()];
    }

    handleRequestTypeChange(name, value) {
        let newData = this.state.data;
        newData[name] = value && value.value;
        this.setState({data : newData});
        this.props.setData(newData);
    };

    render() {
        const { data } = this.state;
        const { t, commonStore } = this.props;
        const { config } = commonStore ;
        const dateTimeRules = config.client && config.client.data && config.client.data.dateTimeRules ? config.client.data.dateTimeRules : false;
        const format = dateTimeRules && dateTimeRules.time_format ? dateTimeRules.time_format : 'hh:mm';

        return(
            <div className='availability-card'>
                <div className='avlbty-info'>
                    <div>
                        <Select
                            // value={this.state.data.request_type}
                            options={[{ label: 'Weekly', value: 'weekly' }, { label: 'Repeating', value: 'repeating' }]}
                            placeholder={this.state.data.request_type ? t(this.state.data.request_type) : t("Availability Type")}
                            name="request_type"
                            onChange={(evt) => { this.handleRequestTypeChange('request_type', evt) }}
                        />
                    </div>
                    <div>
                        <DatePicker
                            isValidDate={this.disableWeekends}
                            placeholder="From Week"
                            selected={data.from_date && new Date(data.from_date)}
                            onChange={(date) => this.handleWeekDateChange('from_date', date)}
                            dateFormat={longDateFormat(dateTimeRules)}
                            filterDate={date => getDay(date) === 1}
                        />
                    </div>
                    <div>
                        <DatePicker
                            isValidDate={this.disableWeekends}
                            placeholder="To Week"
                            selected={data.to_date && new Date(data.to_date)}
                            onChange={(date) => this.handleWeekDateChange('to_date', date)}
                            dateFormat={longDateFormat(dateTimeRules)}
                            filterDate={date => getDay(date) === 0}
                        />
                    </div>
                </div>
                {data.availability.map((date, index) => {
                    return (
                        <div className='availability-days row-flex' style={{color: '#3abda9'}} key={index}>
                            {date && <div className='col-lg-2 col-md-2 col-sm-12 col-xs-12 d-flex align-items-center'>
                                {this.getDayWorkingDayCircle(date)}
                                <span className='title'>{t(this.getDay(date))}
                                </span>
                            </div>}
                            <div className='col-lg-2 col-md-2 col-sm-12 col-xs-12'>
                                <span className='title'>
                                    <CheckBoxWidget 
                                        value={data.availability[index].from != undefined && data.availability[index].from >= 0? false : true} 
                                        onChange={(value) => this.onDaysSelectionChange(index, value)} 
                                        label="All day" />
                                </span>
                            </div>
                            <div className='col-lg-3 col-md-3 col-sm-12 col-xs-12'>
                            <label>
                                <span className='switchLbl'>{t('Available')}</span>
                                <Switch onChange={(checked) => this.onAvailabilitySelectionChange(checked, index)}
                                    height={32} handleDiameter={24}
                                    uncheckedIcon={<div className='switchTxt'>Off</div>}
                                    checkedIcon={<div className='switchTxt'>On</div>}
                                    onColor='#9EE985' offColor='#E2EBDF'
                                    checked={data.availability[index].availability_type === 'available' ? true : false} 
                                        
                                 />
                            </label>
                            </div>
                            <div className='col-lg-5 col-md-5 col-sm-12 col-xs-12'>
                                <div className='title'>
                                    
                                    <Textarea
                                        rows={1}
                                        placeholder={t('Comments')}
                                        
                                        onChange={(e) => this.setInputValues(index, 'comment', e.target.value)}
                                        value={data.availability[index].comment && data.availability[index].comment}
                                    />
                                    {data.availability[index] && data.availability[index].from != undefined && data.availability[index].from >= 0 &&
                                    data.availability[index].to != undefined && data.availability[index].to  >= 0 && 
                                    <span className='row-flex mt-10'>
                                        <div className='pe-3'>
                                        <TimePickerInput       
                                            value={data.availability[index].from ? minutesToTimeInput(data.availability[index].from, false) : `00:00`}
                                            format={format=="hh:mm"?"HH:mm":format}
                                            onChange={(value) => this.setInputValues(index, 'from', getMinutesFromTimeInput(value))}
                                        />
                                        </div>

                                        {data.availability[index]['isToTimePicker'] ? 
                                        <TimePickerInput       
                                            value={data.availability[index].to ? minutesToTimeInput(data.availability[index].to, false) : `00:00`}
                                            format={format=="hh:mm"?"HH:mm":format}
                                            onChange={(value) => this.setInputValues(index, 'to', getMinutesFromTimeInput(value))}
                                        />
                                        :
                                        <TimePickerInput       
                                            value={data.availability[index].to ? minutesToTimeInput(data.availability[index].to, false) : `00:00`}
                                            format={format=="hh:mm"?"HH:mm":format}
                                            onChange={(value) => this.setInputValues(index, 'to', getMinutesFromTimeInput(value))}
                                            disabled
                                        />
                                        }
                                    </span>}
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }
}

export default inject('checklistStore', 'authStore', 'userStore', 'commonStore', 'scheduleStore')(
    applicationRouter(withLocalization(withRemoveDialog(observer(ScheduleAddAvailability))))
);
