import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

//components
import withLocalization from '../../../hoc/withLocalization';
import withRemoveDialog from '~/hoc/withRemoveDialog';
import applicationRouter from '../../../hoc/applicationRouter';
import RecipientsList from './Recipients/recipientList';
import GenericList from '../../../components/GenericList/GenericList';
import GenericFilter from '../../../components/GenericList/GenericFilter.js';

//services
import config from '~/library/config';

//elements
import Button from '../../../components/CustomButton/CustomButton.jsx';
import DeleteLoader from '../../../elements/DeleteLoader.js';

//utils
import { optionStatus } from '../../../utils/constants';

//assest
import Edit from '../../../assets/img/editing.svg';
import deleteIcon from '../../../assets/img/deleting.svg';

//styles
import 'bootstrap/dist/css/bootstrap.min.css';
import './Widgets/CaseList.css';

class CreateDoc extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showrecipientList: false,
            caseId: null,
            currentStatus: 'All',
            RecipientsList: '',
            nameFilter: '',
            recipients_status: undefined,
            isDeletingCase: false,
        };
    }

    navigateToHistory = (id, name, date) => {
        this.props.router.navigate(`/admin/signdochistory`, {
            state: {
                caseId: id,
                caseName: name,
                caseDate: date,
                parent: 'CaseList',
            },
        });
    };

    handleDownload = (data, id) => {
        window.open(`${config.API_ROOT}/sign_documents/completed_signing_document/${encodeURIComponent(id)}`);
    };

    handleDoSignature = (data, id) => {
        const { signatureStore, commonStore, t } = this.props;
        signatureStore
            .generateSignatureLink(id)
            .then((res) => {
                window.open(`${res.Link}`);
            })
            .catch((err) => {
                commonStore.addNotification(t(err.message), null, 'error');
            });
    };

    handleRemoveDoc = (case_id) => {
        const { commonStore, t } = this.props;
        return this.props.handleDelete(case_id, (case_id) => {
            this.setState({ isDeletingCase: true });
            return this.props.signatureStore
                .removeCase(case_id)
                .then((res) => {
                    this.props.signatureStore.resetLastListLoadTime();
                    commonStore.addNotification(t('Deleted'), null, 'error');
                    this.setState({ isDeletingCase: false });
                })
                .catch((err) => {
                    commonStore.addNotification(t(err), null, 'error');
                    this.setState({ isDeletingCase: false });
                });
        });
    };

    renderTableActionButtons = (id, data) => {
        const { t } = this.props;
        return (
            <div className="actions-center" key={`sign-${id}`}>
                {data.status !== 'draft' && (
                    <OverlayTrigger
                        key="GRID-History"
                        placement="top"
                        overlay={<Tooltip id="tooltip-top">{t('History')}</Tooltip>}
                    >
                        <Button
                            onClick={() => this.navigateToHistory(id, data.data[0].og_name, data.send_date)}
                            fill
                            icon_sm
                        >
                            <i className="fa fa-history fa-lg" />
                        </Button>
                    </OverlayTrigger>
                )}
                {data.status === 'pending' && this.props.userStore.currentUser.id !== data.created_by && (
                    <OverlayTrigger
                        key="GRID-Signatue"
                        placement="top"
                        overlay={<Tooltip id="tooltip-top">{t('Signatue')}</Tooltip>}
                    >
                        <Button onClick={() => this.handleDoSignature(data, id)} fill icon_sm>
                            <img src={Edit} alt={'edit button'} />
                        </Button>
                    </OverlayTrigger>
                )}
                {data.status === 'completed' && (
                    <OverlayTrigger
                        key="GRID-Download"
                        placement="top"
                        overlay={<Tooltip id="tooltip-top">{t('Download')}</Tooltip>}
                    >
                        <Button onClick={() => this.handleDownload(data, id)} fill icon_sm>
                            <i className="fa fa-download fa-lg" />
                        </Button>
                    </OverlayTrigger>
                )}

                <Button onClick={() => this.handleRemoveDoc(id)} fill icon_sm_delete>
                    <img src={deleteIcon} alt="delete button" />
                </Button>
            </div>
        );
    };

    handlerecipientList = (recipients, id) => {
        if (recipients === 0) {
            return 0;
        } else {
            this.setState({ showrecipientList: !this.state.showrecipientList, caseId: id });
        }
    };

    onStatusChange = (value) => {
        const { signatureStore } = this.props;
        signatureStore.onFilter('status', value.value);
        this.setState({ currentStatus: value });
    };
    handleFilterChange = (e) => {
        const { signatureStore } = this.props;
        signatureStore.setFilter('name', e.target.value);
        this.setState({ nameFilter: e.target.value });
    };
    sendToSignature(data) {
        const { signatureStore } = this.props;
        if (data.status === 'draft') {
            signatureStore.setCaseId(data.id);
            sessionStorage.setItem('CaseId', data.id);
            this.props.router.navigate('/admin/signupload');
        }
    }

    navigateToSignupload = () => {
        this.props.router.navigate('/admin/signupload');
    };

    render() {
        const { t } = this.props;
        const { commonStore } = this.props;
        const dateTimeRules =
            commonStore.config.client && commonStore.config.client.data && commonStore.config.client.data.dateTimeRules
                ? commonStore.config.client.data.dateTimeRules
                : false;
        const { filters, appliedFilters } = this.props.signatureStore;

        return (
            <>
                <GenericList
                    tableHeader={this.props.t('Digital Signature')}
                    columns={[
                        {
                            Header: 'Name',
                            id: 'name',
                            accessor: (data) => (
                                <span
                                    onClick={() => {
                                        this.sendToSignature(data);
                                    }}
                                    className={
                                        data.status === 'draft' ? 'draft_signdoc_name list-main-column' : 'not_draft_signdoc_name'
                                    }
                                >
                                    {data.name}
                                </span>
                            ),
                            isSortable: true,
                        },
                        {
                            Header: 'Created By',
                            id: 'created_by_full_name',
                            accessor: 'created_by_full_name',
                        },
                        {
                            Header: 'Status',
                            id: 'status',
                            accessor: ({ status, recipients_status, recipients }) => (
                                <div
                                    className={recipients_status === 0 && recipients === 0 ? 'progress' : 'progress'}
                                    style={{
                                        height: '30px',
                                        color: 'white',
                                        borderRadius: '14px',
                                        verticalAlign: 'middle',
                                        lineHeight: '30px',
                                        userSelect: 'none',
                                    }}
                                >
                                    <div
                                        className={
                                            status === 'completed'
                                                ? 'progress-bar'
                                                : status === 'expired'
                                                    ? 'expired_color'
                                                    : status === 'pending' && recipients_status === '0'
                                                        ? 'pending'
                                                        : status === 'draft'
                                                            ? 'draft'
                                                            : recipients_status === (recipients >= 1 && recipients_status >= 1) ||
                                                                recipients_status / recipients
                                                                ? 'progress-bar-primary'
                                                                : ''
                                        }
                                        role="progressbar"
                                        style={{
                                            width:
                                                status === 'completed'
                                                    ? '100%'
                                                    : status === 'expired'
                                                        ? '100%'
                                                        : status === 'pending' && recipients_status === '0'
                                                            ? '100%'
                                                            : status === 'draft'
                                                                ? '100%'
                                                                : recipients_status === (recipients_status === recipients) ||
                                                                    (recipients >= 1 && recipients_status >= 1) ||
                                                                    recipients_status / recipients
                                                                    ? ((recipients_status / recipients) * 100).toFixed(0) + '%'
                                                                    : '',
                                        }}
                                    >
                                        {status === 'completed'
                                            ? t('Completed')
                                            : status === 'expired'
                                                ? t('Expired')
                                                : status === 'pending' && recipients_status === '0'
                                                    ? t('Pending')
                                                    : status === 'draft'
                                                        ? t('Draft')
                                                        : recipients_status === (recipients_status === recipients) ||
                                                            (recipients >= 1 && recipients_status >= 1) ||
                                                            recipients_status / recipients
                                                            ? ((recipients_status / recipients) * 100).toFixed(0) + '%'
                                                            : ''}
                                    </div>
                                </div>
                            ),
                            maxWidth: 120,
                            isSortable: true,
                        },
                        {
                            Header: 'Recipients',
                            id: 'recipients',
                            accessor: ({ recipients, id }) => (
                                <div className="d-flex justify-content-center cursor-pointer">
                                    <span
                                        className="recipient-count-badge"
                                        onClick={() => {
                                            this.handlerecipientList(recipients, id);
                                        }}
                                    >
                                        {recipients}
                                    </span>
                                </div>
                            ),
                            isSortable: true,
                            maxWidth: 120,
                        },
                        {
                            Header: 'Sent Date',
                            id: 'send_date',
                            accessor: ({ send_date }) => (
                                <span>
                                    {send_date ? moment(send_date).format(dateTimeRules.short_date_format) : ''}
                                </span>
                            ),
                            isSortable: true,
                        },
                        {
                            Header: 'Expiring On',
                            id: 'expiry_date',
                            accessor: ({ expiry_date }) => (
                                <span>
                                    {expiry_date ? moment(expiry_date).format(dateTimeRules.short_date_format) : ''}
                                </span>
                            ),
                            isSortable: true,
                        },
                        {
                            Header: 'Action',
                            id: 'action',
                            accessor: (data) => this.renderTableActionButtons(data.id, data),
                        },
                    ]}
                    filters={appliedFilters}
                    lastListLoadTime={this.props.signatureStore.lastListLoadTime}
                    header={
                        <GenericFilter
                            filters={filters}
                            setFilter={(name, value) => this.props.signatureStore.setFilter(name, value)}
                            onFilter={() => {
                                this.props.signatureStore.onFilter();
                            }}
                            hasStatusFilter={true}
                            statusOptions={optionStatus}
                        />
                    }
                    requestData={(params) => this.props.signatureStore.getSignDocument(params)}
                >
                    <Button fill wd onClick={this.navigateToSignupload}>
                        <i className="fa fa-plus-circle fa-xl margin-right-10" />
                        {t('Create Document')}
                    </Button>
                </GenericList>
                {this.state.isDeletingCase && <DeleteLoader t={t} />}
                <RecipientsList
                    caseId={this.state.caseId}
                    show={this.state.showrecipientList}
                    handlerecipientList={this.handlerecipientList}
                ></RecipientsList>
            </>
        );
    }
}

export default inject(
    'signatureStore',
    'userStore',
    'commonStore'
)(applicationRouter(withLocalization(withRemoveDialog(observer(CreateDoc)))));
