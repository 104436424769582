import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

//components
import applicationRouter from '~/hoc/applicationRouter'
import withLocalization from '~/hoc/withLocalization';

//elements
import LoadingSpinner from '~/elements/LoadingSpinner';

const fieldsToShow = [
    {
        title: 'Project Info',
        items: [
            { id: 'phone', name: 'Phone' },
            { id: 'email', name: 'Email' },
            { id: 'contact_person', name: 'Owner' },
            { id: 'Manager', name: 'Manager' },
        ],
    },
    {
        title: 'Details',
        items: [
            { id: 'address', name: 'Address' },
            { id: 'My_est_hours', name: 'Agreement hours' },
            { id: 'My_est_work', name: 'Agreement work' },
            { id: 'My_employment_grade', name: 'My employment grade' },
            { id: 'status', name: 'Status' },
        ],
    },
];

class ProjectsViewForm extends Component {
   
    async loadData() {
        const {projectStore, id,} = this.props;
        await projectStore.load(id, false);
    }

    componentWillMount() {
        this.props.projectStore.loading = true;
    }

    componentDidMount() {
        this.loadData();
    }

    render() {
        const { projectStore, t } = this.props;
        const { loading, currentEntity } = projectStore;

        if (loading) return <LoadingSpinner />;

        const { project } = currentEntity;

        return (
            <div className="primary-page">
                <h3>{project.name}</h3>
                {fieldsToShow.map((group, index) => (
                    <div className="panel view-group" key={`${index}`}>
                        <h4>{t(group.title)}</h4>
                        {group.items.map((item, index2) => (
                            <div className="view-row" key={`${index2}`}>
                                <span className="view-title"> {t(item.name)}</span>
                                <span className="view-value"> {project[item.id]}</span>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        );
    }
}

export default inject('projectStore', 'commonStore')(applicationRouter(withLocalization(observer(ProjectsViewForm))));
