import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Input from '../../../elements/Input';
import { confirmAlert } from 'react-confirm-alert';
import Button from '../../../components/CustomButton/CustomButton.jsx';
import withLocalization from '../../../hoc/withLocalization';
import moment from 'moment';

const InternalTripleTexWidget = inject('clientStore', 'commonStore')(withLocalization(observer(class InternalTripleTexWidget extends Component {
    constructor(props) {
        super(props);
        this.state = {
            client_id: this.props.clientStore.currentEntity.client.id,
            cus_token: this.props.clientStore.currentEntity.client?.integration_details?.cus_token,
            emp_token: this.props.clientStore.currentEntity.client?.integration_details?.emp_token,
            session_expiry: moment(new Date()).add(28, 'days').format('YYYY-MM-DD'),
            packageName: '',
            product: this.props?.payrollProduct,
            active: this.props.clientStore.currentEntity.client?.integration_details?.active
        };
    }
    handleChange = (name, value) => {
        this.setState({ [name] : value})
    }

    onValidate = async () => {
        const getPackageDetails = await this.props.clientStore.getTripleTexToken(this.state)
        this.setState({packageName: getPackageDetails.packageName, active: true})
        if (getPackageDetails.isValidated) {
            this.props.commonStore.addNotification(this.props.t('Token verified successfully'), null, 'success')
        }
    }

    resetIntegration = async () => {
        const { product, emp_token, cus_token, session_expiry } = this.state
        try {
            const str = `${this.props.t(`Do You want to Reset the integration with`)} ${product}`
            confirmAlert({
                title: `${this.props.t('Are You Sure')} ?`,
                message: str,
                buttons: [
                    {
                        label: this.props.t('Yes'),
                        onClick: async () => {
                            await this.props.clientStore.getTripleTexToken({ resetIntegration: true, product, emp_token, cus_token, session_expiry }).then((res) => {
                                this.props.commonStore.addNotification(this.props.t('Integration Reset Successful'), null, 'success');
                                this.setState({ emp_token: "", product: "", active: false })
                            })
                            await this.props.clientStore.load(this.state.client_id)
                        },
                    },
                    {
                        label: this.props.t('No')
                    },
                ],
            })

        }
        catch (err) {
            this.props.commonStore.addNotification(this.props.t(err || 'error'), null, 'error');
        }
    }

    render() {
        return (
            <>
                <Input
                    className={'mt-4'}
                    label={this.props.t('Consumer Token')}
                    name="cus_token"
                    placeholder={this.props.t("Enter Consumer Token")}
                    value={this.state.cus_token}
                    onChange={(event) => this.handleChange('cus_token', event.target.value)}
                    disabled={this.state.active}
                />
                <Input
                    className={'mt-4'}
                    label={this.props.t('Employee Token')}
                    name="emp_token"
                    placeholder={this.props.t("Enter Employee Token")}
                    value={this.state.emp_token}
                    onChange={(event) => this.handleChange('emp_token', event.target.value)}
                    disabled={this.state.active}
                />
                <Input
                    className={'mt-4'}
                    label={this.props.t('TripleTex Plan')}
                    name="tripleTex_plan"
                    placeholder={this.props.t("TripleTex Plan")}
                    value={this.state.packageName}
                    disabled={true}
                />
                <div className='d-flex justify-content-between mt-4'>
                    <Button className="btn btn-fill" onClick={this.onValidate}>
                        {this.props.t('Validate Token')}
                    </Button>
                    <Button className="btn" onClick={this.resetIntegration} title={this.props.t('It will Reset your connection with current Intgration')}>
                        {this.props.t('Reset Integration')}
                    </Button>
                </div>
            </>
        );
    }
})));

export const TripleTexWidget = observer(class TripleTexWidget extends Component {
    render() {
        return <InternalTripleTexWidget {...this.props} mode="managers" />;
    }
});
