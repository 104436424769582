import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Container, Row, Col, Button } from "react-bootstrap";

//components
import withLocalization from '~/hoc/withLocalization';
import applicationRouter from '~/hoc/applicationRouter'
import Header from '../../../newComponents/Header';
import Footer from '../../../newComponents/Footer';
import MainHeading from '../../../newComponents/MainHeading';
import OTPInput from '../../../elements/OtpInput';

//elements
import LoadingSpinner from '~/elements/LoadingSpinner';


//styles
import "./styles.css";

//assets
import userWithOTPIcon from "../../../assets/images/userPassword.svg";
import mailSuccessIcon from "../../../assets/images/mailSuccessIcon.svg";
import sendIcon from "../../../assets/images/sendIcon.svg";
import banIcon from "../../../assets/images/banIcon.svg"

export class MultiAccountAdministrationEmailVerification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLinkValid: true,
            isLoading: true,
            isOTPExpired: true,
            token: null,
            isEmailVerified: false,
            requesteeName: "",
            requestorName: "",
            requestorEmail: "",
            requestorClientName: "",
            otp: "",
            hasError: ""
        };
    }

    componentDidMount() {
        this.loadData();
    }

    async loadData() {
        try {
            const search = this.props.router.location.search;
            if (search.trim() === '') {
                window.location.href = window.location.origin;
            } else {
                const encryptedData = new URLSearchParams(search).get('id');
                const { response } = await this.props.multiAccountAdminStore.checkMultiAccountAdminVerificationLink(encryptedData);
                this.setState({
                    isLinkValid: true,
                    isOTPExpired: response.is_email_otp_expired,
                    token: response.token,
                    isEmailVerified: response.is_email_verified,
                    requesteeName: response.requestee_name,
                    requestorName: response.requestor_name,
                    requestorEmail: response.requestor_email,
                    requestorClientName: response.requestor_client_name,
                    isLoading: false
                });
            }
        } catch (err) {
            this.setState({ isLinkValid: false, isLoading: false });
            this.props.commonStore.addNotification(this.props.t(err || 'Error'), null, 'error');
        }
    }

    handleOTPOnChangeHandler = (event) => {
        let value = event.target.value;
        this.setState({ otp: value });
    }

    handleSubmit = async (action, otp) => {
        const { token } = this.state;
        if (action === 'verify') {
            await this.props.multiAccountAdminStore.verifyMultiAccountEmail({ otp, token }).then(res => {
                this.setState({ isEmailVerified: true });
            }).catch(err => {
                console.error({ err })
                if (err?.response?.body?.error)
                    this.setState({ hasError: err.response.body.error })
                this.props.commonStore.addNotification(err, null, 'error');
            });
        } else {
            await this.props.multiAccountAdminStore.generateVerificationOtp({ token }).then(res => {
                this.setState({ isOTPExpired: false, token: res.token });
            }).catch(err => {
                this.props.commonStore.addNotification(err, null, 'error');
            });
        }
    }

    renderForm = () => {
        const { t } = this.props;
        const { isLoading, isLinkValid, isEmailVerified, isOTPExpired } = this.state;
        if (isLoading) return <LoadingSpinner />

        if (!isLinkValid) {
            return (
                <div className='multi-account-admin-container'>
                    <div className='image-container'>
                        <img src={banIcon} alt="user with otp" />
                    </div>
                    <span>
                        {t("This link is not valid, please retry with valid link")}
                    </span>
                </div>
            )
        }

        if (isEmailVerified) {
            return (
                <div className='multi-account-admin-container'>
                    <div className='image-container'>
                        <img src={mailSuccessIcon} alt="mail success" />
                    </div>
                    <span>
                        {t("Email verification is successful")}
                        <br/><span className='inner_text'>&nbsp;{this.state.requestorName}</span>&nbsp;
                        {t("Will be able to access this account for multi-account administration")}
                    </span>
                </div>
            )
        }

        if (isOTPExpired) {
            return (
                <div className='multi-account-admin-container'>
                    <div className='image-container'>
                        <img src={sendIcon} alt="send otp" />
                    </div>
                    <span>
                        {t("OTP is expired, please try again")}
                    </span>
                    <Button className="btnCommon btnBig mt-2" variant="primary"
                        onClick={this.handleSubmit}
                    >
                        {t("Resend OTP")}
                    </Button>
                </div>
            )
        }

        return (
            <>
                <div className='multi-account-admin-container'>
                    <div className='image-container'>
                        <img src={userWithOTPIcon} alt="user with otp" />
                    </div>
                    <span>
                        {`${t("Hi")},`} &nbsp;
                        <span className='inner_text'>{this.state.requesteeName}</span>&nbsp;
                        {t("please verify your email for allowing multi-account administration access to")} 
                        <span className='inner_text'>&nbsp;{this.state.requestorName}</span>&nbsp;
                        <br/>
                        ({t("Email")}:&nbsp;<span className='inner_text'>{this.state.requestorEmail}</span>,&nbsp;{t("Company Name")}:&nbsp; 
                        <span className='inner_text'>{this.state.requestorClientName})</span>
                    </span>
                </div>
                <Row className='justify-content-center'>
                    <OTPInput length={6}
                        type="number"
                        onSubmit={(otp) => this.handleSubmit('verify', otp)}
                        hasError={this.state.hasError}
                        t={t}
                    >
                        <Button className="btnCommon btnBig mt-3" variant="primary" type='submit'
                        >
                            {t("Verify")}
                        </Button>
                    </OTPInput>
                    <span className='disclaimer_text mt-3'>
                        <b>{t("Note")}:&nbsp;</b>{t('By verifying this request, you will be providing account access for multi-account administration. Please note that if you are not aware of this request or requestor, please contact support')}
                    </span>
                </Row>
            </>
        )
    }


    render() {
        const { t } = this.props;
        return (
            <div className="landingPageWrapp frameworkApp">
                <Header />
                <article className="passwordUpdateBlock grdntBg lp_wrapper">
                    <Container>
                        <Row className="mt-2 mb-2">
                            <Col xs={{ span: 10, offset: 1 }} md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }}>
                                <div className="cntctForm commonForm form-container" style={{ margin: 'auto' }}>
                                    <MainHeading
                                        title={<>{t("Email")} <span>{t("Verification")}</span></>}
                                        customClass="text-center mb-1 noPara"
                                    />
                                    {this.renderForm()}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </article>
                <Footer />
            </div>
        );
    }
}

export default inject('commonStore', 'multiAccountAdminStore')(applicationRouter(withLocalization(observer(MultiAccountAdministrationEmailVerification))));