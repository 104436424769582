import React from "react";
import "./styles.css";

function CoreBox(props) {
  return (
    <div className={`coreCol ${props.customClass}`}>
      <div className={`coreColIcon ${props.color}`}>
        <i><img src={props.src} alt={props.title} /></i>
      </div>
      <div className="coreColInfo">
        <h4>{props.title}</h4>
        <p>{props.description}</p>
      </div>
    </div>   
  );
}
export default CoreBox;