import React, { Component, Fragment } from "react";
import { Modal } from 'react-bootstrap';
import { inject, observer } from "mobx-react";

//components
import GenericList from "../../../../components/GenericList/GenericList";
import withLocalization from "../../../../hoc/withLocalization";

//assets
import RecClose from "./../../../../assets/img/CloseApproveModalIcon.svg";

//styles
import "../Widgets/CaseList.css"

export class recipientList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showrecipientList: true,
        }
    }

    render() {
        const { t } = this.props;
        return (
            <Fragment>
                <Modal lg show={this.props.show} onHide={this.state.handlerecipientList}>
                    <Modal.Header>
                        <Modal.Title>{t('Recipients')}</Modal.Title>
                        <img src={RecClose} className="cursor-pointer" alt="close" onClick={() => { this.props.handlerecipientList() }} />
                    </Modal.Header>
                    <Modal.Body>
                        {!this.props.caseId && <div>{t('No data found')}</div>}
                        {this.props.caseId && <GenericList
                            columns={[
                                {
                                    Header: 'Name',
                                    id: 'user_name',
                                    isSortable: true,
                                    accessor: ({user_name})=><span>{user_name}</span>,
                                    maxWidth:80,
                                    textAlign:'start'
                                },
                                {
                                    Header: 'Email',
                                    id: 'email',
                                    sortable: true,
                                    accessor: ({email})=><span>{email}</span>,
                                    minWidth:120
                                },
                                {
                                    Header: 'Status',
                                    id: 'status',
                                    isSortable: true,
                                    accessor: 'status',
                                    maxWidth: 60 
                                },
                                {
                                    Header: 'Type',
                                    id: 'signing_type',
                                    accessor: 'signing_type',
                                    maxWidth: 60 
                                },
                            ]}
                            requestData={(params) => this.props.recipientStore.fetchAllRecipients(this.props.caseId)}
                        />
                        }
                    </Modal.Body>
                </Modal>
            </Fragment>
        )
    }
}

export default inject('recipientStore', 'signatureStore')(withLocalization(observer(recipientList)));