import L from "leaflet";
import { createControlComponent } from "@react-leaflet/core";
import "leaflet-routing-machine";
import { leafletStringToLocation, locationToString } from '../../../utils/geo';


const createRoutineMachineLayer = (props) => {
    const { taskStore, commonStore } = props;
    const {lat : originLat, lng: originLng} = leafletStringToLocation(commonStore?.config?.client?.gps_data);
    const {lat : destinationLat, lng: destinationLng} = leafletStringToLocation(taskStore?.currentEntity?.task?.gps_data);
    const instance = L.Routing.control({
        waypoints: [
            L.latLng(originLat, originLng),
            L.latLng(destinationLat, destinationLng)
        ],
        lineOptions: {
            styles: [{ color: "blue", weight: 4 }]
        },
        show: false,
        addWaypoints: false,
        routeWhileDragging: true,
        draggableWaypoints: true,
        fitSelectedRoutes: true,
        showAlternatives: false,
        createMarker: function () {
        }
    });
    return instance;
};

const RoutingMachine = createControlComponent(createRoutineMachineLayer);

export default RoutingMachine;
