import React, { Component } from 'react';
import applicationRouter from '~/hoc/applicationRouter'
import { inject, observer } from 'mobx-react';
import moment from 'moment/min/moment-with-locales';
import Button from '../../../components/CustomButton/CustomButton.jsx';

import DatePicker from '../../../components/DatePicker/DatePicker';
import withLocalization from '~/hoc/withLocalization';
import { longDateFormat } from '../../../utils/dateFormat';
// import customerFeature from '~/utils/customerFeature';

class MonitorGridFilter extends Component {
    setFilterDate(name, name1, value) {
        this.props.setFilter(name1, value);
        this.props.setFilter(name, moment(new Date(value)).format('YYYY-MM-DD'));
    }

    filterGrid = () => {
        const { onFilter, monitorStore } = this.props;
        onFilter()
        if (this.props.id) {
            monitorStore.loadGrid(this.props.id)
        }
    }

    componentWillUnmount() {
        this.props.monitorStore.setFilter("", "", true)
        this.props.monitorStore.onFilter()
    }

    render() {
        const {
            filters, loading, t
        } = this.props;
        const { fromDate1, toDate1 } = filters;
        const { config } = this.props.commonStore
        const dateTimeRules = config.client && config.client.data && config.client.data.dateTimeRules ? config.client.data.dateTimeRules : false;
        return (
            // <div className='monitoring-history-container'>
            <div className='sub-history-container'>
                <div className='date-filter col-md-8'>
                    <label>{t('From Date')}</label>
                    <div className='monitor_filter_width_240'>
                        <DatePicker
                            selected={fromDate1}
                            onChange={(date) => this.setFilterDate('fromDate', 'fromDate1', date)}
                            dateFormat={longDateFormat(dateTimeRules)}
                            placeholder={t('From')}
                            style={{minWidth: 15}}
                        />
                    </div>
                    <label>{t('To Date')}</label>
                    <div className='monitor_filter_width_240'>
                        <DatePicker
                            selected={toDate1}
                            onChange={(date) => this.setFilterDate('toDate', 'toDate1', date)}
                            dateFormat={longDateFormat(dateTimeRules)}
                            placeholder={t('From')}
                        />
                    </div>
                    <Button fill wd onClick={() => this.filterGrid()} disabled={loading}>
                        {t('Filter')}
                    </Button>
                </div>
            </div>
            // </div>
        );
    }
}

export default inject('monitorStore', 'userStore', 'commonStore')(withLocalization(applicationRouter(observer(MonitorGridFilter))));
