import React, { Fragment } from 'react';
import TimePicker from 'react-time-picker';
import './timePicker.scss'

export function TimePickerInput(props) {
  const { value, onChange, style, format, className, disabled } = props;
  return (
    <Fragment>
      <div className={className ? className : ""}>
        <TimePicker
          onChange={e => { onChange(e) }}
          value={value}
          disableClock
          clearIcon={null}
          clearAriaLabel={"n/a"}
          style={style}
          format={format}
          disabled={disabled}
        />
      </div>
    </Fragment>
  );
}

export default TimePickerInput;