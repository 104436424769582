export const schema = {
    title: 'Vacation',
    type: 'object',
    required: ['vacation_type', 'user_id', 'from', 'to', 'total_days'],
    properties: {
        id: { type: 'integer' },
        user_id: { type: ['integer'], title: 'User', readOnly: true },
        client_id: { type: ['integer'], title: 'Client' },
        project_id: { type: ['integer'], title: 'Project', default: 0 },
        status: { type: ['string', 'null'], title: 'Status', default: 'active' },
        vacation_type: { type: ['string'], title: 'Type' },
        total_days: { type: ['integer'], title: 'Total days', readOnly: true },
        from: { type: ['string'], format: 'date', title: 'From date' },
        to: { type: ['string'], format: 'date', title: 'To date' },
        attachments: { type: ['string', 'null'], title: 'Attachments' },
    },
};

export const getSchema = () => schema;

export default getSchema;
