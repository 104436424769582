import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

//components
import applicationRouter from '~/hoc/applicationRouter'
import withRemoveDialog from '~/hoc/withRemoveDialog';
import withLocalization from '~/hoc/withLocalization';
import ClientsList from './ClientsList';

class ClientsPage extends Component {

    render() {
        const { userStore, t } = this.props;
        const userType = userStore.currentUser.user_type;
        if (userType != 'super-admin') {
            return <div>{t('Not Allowed')}</div>;
        }

        return <ClientsList />
    }
}
export default inject('authStore', 'clientStore', 'userStore', 'commonStore')(applicationRouter(withLocalization(withRemoveDialog(observer(ClientsPage)))));
