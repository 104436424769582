import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { confirmAlert } from 'react-confirm-alert';
import Input from '../../../elements/Input';
import Button from '../../../components/CustomButton/CustomButton.jsx';
import withLocalization from '../../../hoc/withLocalization';

const InternalVismaWidget = inject('clientStore', 'commonStore')(withLocalization(observer(class InternalVismaWidget extends Component {
    constructor(props) {
        super(props);
        this.state = {
            emp_token: this.props.clientStore.currentEntity.client?.integration_details?.emp_token,
            product: this.props?.payrollProduct,
            active: this.props.clientStore.currentEntity.client?.integration_details?.active
        };
    }
    handleChange = (name, value) => {
        this.setState({ [name]: value })
    }

    onValidate = async () => {
        try {
            await this.props.clientStore.saveVismaDetails(this.state)
            this.props.commonStore.addNotification(this.props.t('Token verified successfully'), null, 'success');
            this.setState({active: true});
        }
        catch (err) {
            this.props.commonStore.addNotification(this.props.t(err || 'error'), null, 'error');
        }
    }

    resetIntegration = async () => {
        const { product, emp_token } = this.state
        try {
            const str = `${this.props.t(`Do You want to Reset the integration with`)} ${product}`
            confirmAlert({
                title: `${this.props.t('Are You Sure')} ?`,
                message: str,
                buttons: [
                    {
                        label: this.props.t('Yes'),
                        onClick: async () => {
                            await this.props.clientStore.saveVismaDetails({ resetIntegration: true, product, emp_token })
                            this.props.commonStore.addNotification(this.props.t('Integration Reset Successful'), null, 'success');
                            this.setState({ emp_token: "", product: "", active: false })
                            await this.props.clientStore.load(this.props.clientStore.currentEntity.client.id)
                        },
                    },
                    {
                        label: this.props.t('No')
                    },
                ],
            })
        }
        catch (err) {
            this.props.commonStore.addNotification(this.props.t(err || 'error'), null, 'error');
        }
    }

    render() {
        return (
            <>
                <Input
                    className={'mt-4'}
                    label={'Tenant ID'}
                    name="emp_token"
                    placeholder={this.props.t("Enter Tenant ID")}
                    value={this.state.emp_token}
                    onChange={(event) => this.handleChange('emp_token', event.target.value)}
                    disabled={this.state.active}
                />
                <div className='d-flex justify-content-between mt-4'>
                    <Button className="btn btn-fill" onClick={this.onValidate}>
                        {this.props.t('Validate Token')}
                    </Button>
                    <Button className="btn" onClick={this.resetIntegration} title={this.props.t('It will Reset your connection with current available Intgration')}>
                        {this.props.t('Reset Integration')}
                    </Button>
                </div>
            </>
        );
    }
})));

export const VismaWidget = observer(class VismaWidget extends Component {
    render() {
        return <InternalVismaWidget {...this.props} mode="managers" />;
    }
});
