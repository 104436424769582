import React from "react";
import { Link} from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { Swiper, SwiperSlide, } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import 'swiper/swiper.min.css';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import 'swiper/scss/scrollbar';

import Header from "../../../newComponents/Header";
import Footer from "../../../newComponents/Footer";
import MainHeading from "../../../newComponents/MainHeading";
import PageBanner from "../../../newComponents/PageBanner";
import CoreBox from "../../../newComponents/CoreBox";
import ClientItem from "../../../newComponents/Client";
import Contact from "../../../newComponents/Contact";

import BannerImg from "../../../assets/images/timeAndRegisterImg.png";
import AppleIcon from "../../../assets/images/appleIcon.svg";
import GoogleIcon from "../../../assets/images/googleIcon.svg";
import ValuableLogo from "../../../assets/images/valuableLogo.png";
import Icon1 from "../../../assets/images/icon1.svg";
import Icon2 from "../../../assets/images/icon2.svg";
import Icon3 from "../../../assets/images/icon3.svg";
import Icon4 from "../../../assets/images/icon4.svg";
import Icon5 from "../../../assets/images/icon5.svg";
import Icon6 from "../../../assets/images/icon6.svg";
import Client1 from "../../../assets/images/client1.png";
import Client2 from "../../../assets/images/client2.png";
import Client3 from "../../../assets/images/client3.png";
import Client4 from "../../../assets/images/client4.png";
import Client5 from "../../../assets/images/client5.png";
import Client6 from "../../../assets/images/client6.png";
import SolutionImg from "../../../assets/images/solutionImg.png";
import RegisterImg from "../../../assets/images/registerImg.png";
import TCLogo from "../../../assets/images/tcLogo.svg";
import SliderImg1 from "../../../assets/images/slideImg1.jpg";
import SliderImg2 from "../../../assets/images/slideImg2.jpg";
import SliderImg3 from "../../../assets/images/slideImg3.jpg";

//i18n
import { withTranslation } from "react-i18next"
import { inject, observer } from "mobx-react";

import config from "../../../library/config";


const TimeRegister = inject('authStore', 'commonStore')(observer((props) => {
  return (
    <>
      <div className="landingPageWrapp frameworkApp">
        <Header features="current" />
        <article className="pageBannerBlock grdntBg  py-60">
          <Container>
            <Row className="g-4 align-items-center">
              <Col lg>
                <MainHeading
                  title={<>{props.t("Ultimate Features of")} <span>{props.t("Time and Control")}</span></>}
                  description={props.t("We come with a robust app that contains unique features you can't imagine. Try the app and enjoy the multiple solutions for flawless enterprises and productive results.")}
                />
                <div className="appBtns mt-4">
                  <Link onClick={e => config.redirectToAppStore(e, "apple")} className="btnCommon btnBig w-auto me-3 mb-2"><i className="d-inline-block pe-2"><img src={AppleIcon}  alt="Apple" /></i> {props.t("Apple")}</Link>
                  <Link onClick={e => config.redirectToAppStore(e, "google")} className="btnCommon btnTrans btnBig btnBlackTrans w-auto"><i className="d-inline-block pe-2"><img src={GoogleIcon} alt="Apple" /></i> {props.t("Google")}</Link>
                </div>
              </Col>
              <Col lg={1}></Col>
              <Col lg="auto">
                <PageBanner
                  src={BannerImg}
                  alt="Time Register"
                />
              </Col>
            </Row>
          </Container>
          <div className="valuableLogo">
            <img src={ValuableLogo} alt="" />
          </div>
        </article>
        <article className="coreBusnsBlock  py-80">
          <Container>
            <Row className="g-4 align-items-center">
              <Col md={6} lg={4}>

              </Col>
            </Row>
            <Row className="g-4 align-items-center">
              <Col lg={6}>
                <MainHeading
                  title={<>{props.t("How Time & Control")}<span> {props.t("Best in the Market?")}</span></>}
                  customClass="medium noPara m-0"
                />
                <div className="contentInfo">
                  <h5 className="m-0">{props.t("Focus on Core Business")}</h5>
                </div>
              </Col>
              <Col lg={6}>
                <div className="text-left">
                  <p>{props.t("The management software you choose has to be adaptable and able to fulfill all of your requirements.")}</p>
                  <p>{props.t("Our app has features that make it simpler to manage projects and more convenient for consumers to be satisfied.")}</p>
                  <p>{props.t("The following are some of the characteristics that make it unique from others:")}</p>
                </div>
              </Col>
            </Row>
            <Row className="g-4 g-xxl-5 mt-5">
              <Col md={6} lg={4}>
                <CoreBox
                  src={Icon1}
                  title={props.t("Work Order Management")}
                  description={props.t("Designed to make it simpler to retrieve essential project information whether users are at the office or on the job site")}
                  color="blue"
                  customClass="style2"
                />
              </Col>
              <Col md={6} lg={4}>
                <CoreBox
                  src={Icon2}
                  title={props.t("Tracking of Incidents")}
                  description={props.t("Data that is available to the public enhances team collaboration, protects individuals, and makes consumers more satisfied.")}
                  color="blue"
                />
              </Col>
              <Col md={6} lg={4}>
                <CoreBox
                  src={Icon3}
                  title={props.t("Effortless integrations")}
                  description={props.t("Your team now has quick insight over projects, resources, and personnel thanks to seamless changes and integrations that occur in real-time.")}
                  color="blue"
                  customClass="style2"
                />
              </Col>
              <Col md={6} lg={4}>
                <CoreBox
                  src={Icon4}
                  title={props.t("Prioritize tasks")}
                  description={props.t("Our scheduling software helps prioritize and schedule activities. Depending on your project's size and phase, you may need to transition between a due date and a priority system.")}
                  color="blue"
                  customClass="style2"
                />
              </Col>
              <Col md={6} lg={4}>
                <CoreBox
                  src={Icon5}
                  title={props.t("Cost-manpower analysis")}
                  description={props.t("Optimizing on-site labor starts with a clear understanding of your project. Subcontractors can utilize a smartphone app to submit work and costs while finishing a task.")}
                  color="blue"
                  customClass="style2"
                />
              </Col>
              <Col md={6} lg={4}>
                <CoreBox
                  src={Icon6}
                  title={props.t("Customize work management")}
                  description={props.t("Custom task statuses allow the correct personnel adjustable user rights to execute your project on time. This flexibility allows you to alter permissions so additional users may edit and verify work.")}
                  color="blue"
                  customClass="style2"
                />
              </Col>
            </Row>
          </Container>
        </article>
        <article className="clientBlock  py-60">
          <Container>
            <div className="clientSldr">
              <Swiper modules={[Navigation, Pagination, Scrollbar, A11y]} navigation spaceBetween={30} slidesPerView={4} centeredSlides={false} loop={false}
                breakpoints={{
                  320: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                  },
                  576: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                  },
                  768: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                  },
                  992: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                  },
                  1200: {
                    slidesPerView: 5,
                    spaceBetween: 20,
                  },
                  1400: {
                    slidesPerView: 6,
                    spaceBetween: 30,
                  },
                }}
              >
                <SwiperSlide>
                  <ClientItem
                    name="PRISM HR"
                    src={Client1}
                    link="javascript:void(0);"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <ClientItem
                    name="PAYROLL"
                    src={Client2}
                    link="javascript:void(0);"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <ClientItem
                    name="WAVE ADVISORS"
                    src={Client3}
                    link="javascript:void(0);"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <ClientItem
                    name="GUSTO"
                    src={Client4}
                    link="javascript:void(0);"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <ClientItem
                    name="APS"
                    src={Client5}
                    link="javascript:void(0);"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <ClientItem
                    name="ADP"
                    src={Client6}
                    link="javascript:void(0);"
                  />
                </SwiperSlide>
              </Swiper>
            </div>
          </Container>
        </article>
        <article className="smplSolBlock  py-80">
          <div className="py-80">
            <Container>
              <Row className="g-4 align-items-center mt-5">
                <Col lg={6} className="d-none d-lg-inline-block"></Col>
                <Col lg={6}>
                  <div className="contentInfo">
                    <h5 className="mb-2">{props.t("Digital benefits for contractors and builders")}</h5>
                    <MainHeading
                      title={props.t("Zero-based budgets, schedules and actuals for each project")}
                      customClass="noPara m-0"
                    />
                    <p>{props.t("Job-Cost Reports are issued monthly.")}</p>
                    <p>{props.t("Obstacles, Work Order Amendments, and Billing")}</p>
                    <p>{props.t("Easy Cash flow, and Overheads Centralized Procurement Projections")}</p>
                    <p>{props.t("Logs of machinery and equipment usage")}</p>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="solutionImg">
            <img src={SolutionImg} alt="solution"/>
          </div>
        </article>
        <article className="rgstrBlock wrapper py-80 pb-0">
          <Container>
            <div className="rgstrInrBlock grdntBg">
              <Row className="g-4 g-lg-5 align-items-center">
                <Col lg={5}>
                  <div className="rgstrImg">
                    <img src={RegisterImg} alt="Registration" />
                  </div>
                </Col>
                <Col lg={7}>
                  <div className="mb-4">
                    <img src={TCLogo} alt="TC Logo" />
                  </div>
                  <MainHeading
                    title={props.t("Go Extra Mile and Reach Desired Success With Our Solution")}
                    customClass="medium noPara m-0"
                  />
                  <div className="contentInfo">
                    <p className="m-0">{props.t("Save Extra Costs, Time and Work")}</p>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </article>
        <article className="prfBlock  py-80 pb-0">
          <Container>
            <div className="py-40">
              <Row className="g-4 g-lg-5">
                <Col lg={5}>
                  <MainHeading
                    title={<>{props.t("Project dashboards &")} <span>{props.t("construction analytics")}</span></>}                  
                    customClass="noPara m-0"
                  />
                </Col>
                <Col lg={1} className="d-none d-lg-inline-block"></Col>
                <Col lg={6}>
                  <div className="contentInfo">
                    <h5 className="mb-2">{props.t("Overheads and Cost to Completion Estimates")}</h5>
                  </div>
                  <MainHeading
                    title={props.t("Keep track of your team's use and client time.")}
                    description={props.t("Track project progress, profitability, income and costs, and resource allocation. Turn every quotation, order, or job report into an invoice and be paid on time, every time. Custom Approvals and User Access Control Integrations")}
                    customClass="medium m-0"
                  />
                </Col>
              </Row>
            </div>
          </Container>
          <div className="prfSldr mt-5">
            <Swiper modules={[Navigation, Pagination, Scrollbar, A11y]} navigation spaceBetween={0} slidesPerView={3.4} centeredSlides={false} loop={true}
              breakpoints={{
                320: {
                  slidesPerView: 2,
                  spaceBetween: 0,
                },
                576: {
                  slidesPerView: 2,
                  spaceBetween: 0,
                },
                768: {
                  slidesPerView: 2.4,
                  spaceBetween: 0,
                },
                992: {
                  slidesPerView: 3,
                  spaceBetween: 0,
                },
                1200: {
                  slidesPerView: 3.4,
                  spaceBetween: 0,
                },
              }}
            >
              <SwiperSlide>
                <div className="prfSldImg" style={{backgroundImage: `url(${SliderImg1})`}}></div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="prfSldImg" style={{backgroundImage: `url(${SliderImg2})`}}></div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="prfSldImg" style={{backgroundImage: `url(${SliderImg3})`}}></div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="prfSldImg" style={{backgroundImage: `url(${SliderImg1})`}}></div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="prfSldImg" style={{backgroundImage: `url(${SliderImg2})`}}></div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="prfSldImg" style={{backgroundImage: `url(${SliderImg3})`}}></div>
              </SwiperSlide>
            </Swiper>
          </div>
        </article>
        <Contact/>
        <Footer/>
      </div>
    </>
  );
}))
export default withTranslation()(TimeRegister);