import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment/min/moment-with-locales';

//components
import applicationRouter from '~/hoc/applicationRouter';
import CustomCheckbox from '../../../components/CustomCheckbox/CustomCheckbox';
import { MembersWidget } from '../../../components/GenericForm/Widgets/UsersWidget';
import DatePicker from '../../../components/DatePicker/DatePicker';
import { ProjectsWidget } from '../../../components/GenericForm/Widgets/ProjectsWidget';
import withLocalization from '~/hoc/withLocalization';

//services
import config from '~/library/config';
import agent from '~/library/agent';

//elements
import Button from '~/components/CustomButton/CustomButton.jsx';

//utils
import customerFeature from '~/utils/customerFeature';
import { longDateFormat } from '../../../utils/dateFormat';

class RestaurantReport extends Component {
    state = {
        email: null,
        dateRange: { start: moment(new Date()).startOf('month'), end: moment(new Date()) },
        sending: false,
        approved_only: true,
        include_tasks: false,
        include_corrections: true,
        include_currently_at_work: true,
        include_ssn: true,
        iframeURL: 'about:blank',
        users: [],
        project: { value: '', label: 'All' },
        all_members: false,
        is_show_project_tax_number: false,
        group_report_by_member:false
    };

    getReportMode() {
        return 'restaurant';
    }

    loadData() {
        const { userStore } = this.props;
        const userSelect = customerFeature('reports_not_for_me');
        this.setState({
            dateRange: { start: moment(new Date()).startOf('month'), end: moment(new Date()) },
            weekStart: moment(new Date()).add(-7, 'days'),
        });
        if (!userSelect) {
            this.setState({ users: [userStore.currentUser.id] });
        }
    }

    componentDidMount() {
        this.loadData();
    }

    onSelect = (date, field) => {
        const dr = this.state.dateRange;
        dr[field] = moment(date);
        this.setState({ dateRange: dr });
    };

    onProjectChange = (id, user) => {
        this.setState({ project: user, is_show_project_tax_number: user.value == '' ? false : this.state.is_show_project_tax_number });
    };

    onCheckboxChange = (name, value) => {
        this.setState({ [name]: value });
    };

    applyForm = (params = {}) => {
        const mode = this.getReportMode();
        const { t, userStore, commonStore } = this.props;
        if (this.state.users.length <= 0 && mode !== 'vacation' && !this.state.all_members) {
            commonStore.addNotification(t('Choose users'), null, 'error');
            return;
        }
        const hydrated = {
            user_id: userStore.currentUser.id,
            mode,
            mail: !!params.email,
            pdf: params.pdf,
            all_members: this.state.all_members,
            project: this.state.project.value,
            projectname: this.state.project.label,
            approved_only: this.state.approved_only,
            include_tasks: this.state.include_tasks,
            include_corrections: this.state.include_corrections,
            include_currently_at_work: this.state.include_currently_at_work,
            include_ssn: this.state.include_ssn,
            users: this.state.users.join(','),
            start: this.state.dateRange.start.format('YYYY-MM-DD'),
            end: this.state.dateRange.end.format('YYYY-MM-DD'),
            is_show_project_tax_number: this.state.is_show_project_tax_number,
            group_report_by_member: this.state.group_report_by_member
        };
        hydrated.preview = !params.pdf;
        hydrated.authorization = commonStore.token;
        if (params.pdf) {
            if (!this.state.email) {
                window.open(`${config.API_ROOT}/dashboard/send_report${agent.convertToGetParams(hydrated)}`);
            }
            userStore
                .sendReport(hydrated)
                .then((res) => {
                    this.setState({ sending: false });
                    commonStore.addNotification(t('Sent'), null, 'success');
                })
                .catch((err) => {
                    this.setState({ sending: false });
                    commonStore.addNotification(err.message || t('Error'), null, 'error');
                });
        } else {
            this.setState({
                iframeURL: `${config.API_ROOT}/dashboard/send_report${agent.convertToGetParams(hydrated)}`,
            });
        }
    };

    onMemberChange = (id, user) => {
        const users = user ? user.map((u) => u.value) : [];
        this.setState({ users });
    };

    render() {
        const { t } = this.props;
        const { sending } = this.state;
        const userSelect = customerFeature('reports_not_for_me');
        const { config } = this.props.commonStore;
        const dateTimeRules =
            config.client && config.client.data && config.client.data.dateTimeRules
                ? config.client.data.dateTimeRules
                : false;
        return (
            <div>
                <div className="panel panelReports m-1 p-3">
                    <h4 className="reportsTitle">{t('User report')}</h4>
                    <div className="row">
                        <div className="col-md-6">
                            <DatePicker
                                selected={this.state.dateRange.start.toDate()}
                                onChange={(date) => this.onSelect(date, 'start')}
                                dateFormat={longDateFormat(dateTimeRules)}
                                label={t('From')}
                            />
                        </div>
                        <div className="col-md-6">
                            <DatePicker
                                selected={this.state.dateRange.end.toDate()}
                                onChange={(date) => this.onSelect(date, 'end')}
                                dateFormat={longDateFormat(dateTimeRules)}
                                label={t('To')}
                            />
                        </div>
                    </div>
                    <div className="mt-4 row">
                        <div className="col-md-6 col-12">
                            <CustomCheckbox
                                label={t('Approved only')}
                                onChange={(v) => this.onCheckboxChange('approved_only', v)}
                                isChecked={this.state.approved_only}
                            />
                            <CustomCheckbox
                                label={t('Include tasks')}
                                onChange={(v) => this.onCheckboxChange('include_tasks', v)}
                                isChecked={this.state.include_tasks}
                            />
                            <CustomCheckbox
                                label={t('Include') + ' ' + t('Date of birth no.')}
                                onChange={v => this.onCheckboxChange('include_ssn', v)}
                                isChecked={this.state.include_ssn}
                            />
                        </div>
                        <div className='col-md-6 col-12'>
                            <CustomCheckbox
                                label={t('Group Report by Member')}
                                onChange={v => this.onCheckboxChange('group_report_by_member', v)}
                                isChecked={this.state.group_report_by_member}
                                
                            />
                            <CustomCheckbox
                                label={t('Include Corrections')}
                                onChange={v => this.onCheckboxChange('include_corrections', v)}
                                isChecked={this.state.include_corrections}
                            />
                            <CustomCheckbox
                                label={t('Include currently at work')}
                                onChange={v => this.onCheckboxChange('include_currently_at_work', v)}
                                isChecked={this.state.include_currently_at_work}
                            />
                        </div>
                    </div>
                    <br />
                    {userSelect && (
                        <CustomCheckbox
                            label={t('All members')}
                            onChange={(v) => this.onCheckboxChange('all_members', v)}
                            isChecked={this.state.all_members}
                        />
                    )}
                    <div className="mt-2">
                        {userSelect && (
                            <MembersWidget
                                isMulti
                                value={this.state.users}
                                onChange={(id, user) => this.onMemberChange(id, user)}
                            />
                        )}
                    </div>
                    <div>
                        <h5>{t('Project')}</h5>
                        {config?.biztype?.data?.allow_multiple_branches ?
                            <div className='d-flex'>
                                <div className='col-md-6'>
                                    <ProjectsWidget
                                        value={this.state.project.value}
                                        onChange={(id, user) => this.onProjectChange(id, user)}
                                        allowAll
                                    />
                                </div>
                                <div className='col-md-6 ml-10'>
                                    <CustomCheckbox
                                        label={t('Show Project/Branches Details')}
                                        onChange={(v) => this.onCheckboxChange('is_show_project_tax_number', v)}
                                        isChecked={this.state.is_show_project_tax_number}
                                        className={'mt-2'}
                                        disabled={this.state.project.value === '' ? true : false}
                                    />
                                </div>
                            </div>
                            :
                            <ProjectsWidget
                                value={this.state.project.value}
                                onChange={(id, user) => this.onProjectChange(id, user)}
                                allowAll
                            />
                        }
                    </div>
                    <div className="did-not-work__buttons">
                        {sending && <div>Sending</div>}
                        {!sending && (
                            <Button fill variant="primary" onClick={() => this.applyForm()}>
                                {t('Apply')}
                            </Button>
                        )}
                        <Button variant="primary" onClick={() => this.applyForm({ pdf: true })}>
                            {t('Download as PDF')}
                        </Button>
                    </div>
                </div>
                <div className="panel panelReports m-1 p-3">
                    <iframe style={{ width: '100%', height: '90vh', border: 0 }} src={this.state.iframeURL} />
                </div>
            </div>
        );
    }
}

export default inject(
    'dashboardStore',
    'userStore',
    'commonStore'
)(withLocalization(applicationRouter(observer(RestaurantReport))));
