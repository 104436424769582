import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Switch from 'react-switch';

//components
import withLocalization from '../../../hoc/withLocalization';


const ActiveInactiveWidgets = inject('projectStore', 'userStore', 'commonStore')(withLocalization(observer(class ActiveInactiveWidgets extends Component {
    render() {
        const { value, onChange, options } = this.props;
        const valueInBool = options?.valueInBool || false;
        return (
            <div>
                <Switch
                    checked={valueInBool ? value : value === 'active'}
                    onChange={checked => onChange(valueInBool ? checked : checked ? 'active' : 'inactive')}
                    onColor={"#2550AC"}
                    checkedIcon={false}
                    uncheckedIcon={false}
                />
            </div>
        );
    }
})));
export const ActiveInactiveWidget = observer(class ActiveInactiveWidget extends Component {
    render() {
        return <ActiveInactiveWidgets {...this.props} />;
    }
});
// export default ActiveInactiveWidget;
