import React from 'react';
import { inject, observer } from 'mobx-react';

import withLocalization from '../../hoc/withLocalization';

class Register extends React.Component {
    componentWillUnmount() {
        const { authStore } = this.props;
        authStore.reset();
    }

    handleInputChange = (e) => {
        this.props.authStore.formChange(e.target.name, e.target.value);
    };

    handleSubmitForm = (e) => {
        e.preventDefault();
        const { authStore, history } = this.props;
        const { values } = authStore;
        const errors = [];
        if (values.username.length < 5) {
            errors.push('Username is too short');
        }
        if (values.password.length < 5) {
            errors.push('Passwords is too short');
        }
        if (values.password !== values.confirmPassword) {
            errors.push('Passwords do not match');
        }
        if (!Object.keys(errors).length) {
            authStore.register().then(() => {
                const lastPage = sessionStorage.getItem('lastPage');
                if (lastPage) {
                    sessionStorage.removeItem('lastPage');
                    history.replace(lastPage);
                } else {
                    history.replace('/login');
                }
            });
        } else {
            this.props.authStore.setError(errors);
        }
    };

    render() {
        const { authStore, t } = this.props;
        const { values,} = authStore;
        return (
            
            <form className="login-container">
                <div className="row">
                    <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                        <div className="form-group business-name">
                            <label for="companyName" className="big-text">
                                {t('User Name')}
                            </label>
                            <label className="red-star">*</label>
                            <p className="small-text st">{t('Please add your user name.')}</p>
                            <input
                                type="text"
                                name="username"
                                value={values.username}
                                onChange={this.handleInputChange}
                                className="form-control login-input mb_25 mt-12"
                                placeholder={t('Company Name')}
                                required
                            />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                        <div className="form-group business-name">
                            <label for="companyName" className="big-text">
                                {t('Email')}
                            </label>
                            <label className="red-star">*</label>
                            <p className="small-text st">{t('Please add your  email.')}</p>
                            <input
                                type="text"
                                name="email"
                                value={values.email}
                                onChange={this.handleInputChange}
                                className="form-control login-input mb_25 mt-12"
                                placeholder={t('Company Name')}
                                required
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                        <div className="form-group business-name">
                            <label for="companyName" className="big-text">
                                {t('Password')}
                            </label>
                            <label className="red-star">*</label>
                            <p className="small-text st">{t('Please add your password.')}</p>
                            <input
                                type="text"
                                name="password"
                                value={values.password}
                                onChange={this.handleInputChange}
                                className="form-control login-input mb_25 mt-12"
                                placeholder={t('Company Name')}
                                required
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                        <div className="form-group business-name">
                            <label for="companyName" className="big-text">
                                {t('Confirm Password')}
                            </label>
                            <label className="red-star">*</label>
                            <p className="small-text st">{t('Please add your confirm password.')}</p>
                            <input
                                type="text"
                                name="confirmPassword"
                                value={values.confirmPassword}
                                onChange={this.handleInputChange}
                                className="form-control login-input mb_25 mt-12"
                                placeholder={t('Company Name')}
                                required
                            />
                        </div>
                    </div>
                </div>
                <div className="form-check mb-30">
                    <input
                        type="checkbox"
                        name="accept"
                        checked={values.accept}
                        value={values.accept}
                        onChange={this.handleInputChange}
                        className="form-check-input"
                        id="exampleCheck1"
                    />
                    <label className="form-check-label2" for="exampleCheck1">
                        &nbsp; {t('I accept the')} &nbsp;
                        <a>{t('Terms & Conditions')}</a>
                    </label>
                </div>
                <button className="btn btn-block fwBold fs18 loginbutton" onClick={this.contactSubmit}>
                    SIGN UP
                </button>
            </form>
        );
    }
}
export default inject('authStore')(withLocalization(observer(Register)));
