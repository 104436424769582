import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Viewer } from '@react-pdf-viewer/core';
// import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { Worker } from '@react-pdf-viewer/core';
import { Row, Col } from 'react-bootstrap'


//components
import applicationRouter from '~/hoc/applicationRouter'
import withLocalization from '~/hoc/withLocalization';
import DocumentEditor from './DocumentEditor';
import AttachmentsWidget from '../../../../components/GenericForm/Widgets/AttachmentsWidget';

//elements
import Button from '../../../../components/CustomButton/CustomButton.jsx';
import Radio from '../../../../components/CustomRadio/CustomRadio.jsx';
import { Input } from '../../../../elements/Input';
import LoadingSpinner from '../../../../elements/LoadingSpinner';


//services
import config from '~/library/config';

//styles
import '../../../../styles/css/styles.css';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

//this component is used as both add and edit for documents//
class DocumentAddEdit extends Component {
    state = {
        isEmployee: true,
        isPDF: false,
        isSaved: false,
        pdfFilePath: null,
        viewPdf: false,
        attachments: '',
        numPages: null,
        pageNumber: 1,
        toggleSaveBtn: false,
        isLoading:true
        //isSent: false,
    }

    loadData = async () => {
        //calls action based on props to initiate currentDocument values//
        const { documentStore, id, add, onTabJumped } = this.props;
        if (id && id > 0 && !add && !onTabJumped) {
            await documentStore.loadDocumentById(id).then(
                (response) => {
                    if (documentStore.currentDocument.ispdf) {
                        this.setState({
                            isPDF: true,
                            attachments: documentStore.currentDocument.pdf_attachment,
                            pdfFilePath: `${config.API_ROOT}/attachments/${documentStore.currentDocument.pdf_attachment}`,
                            viewPdf: true
                        })
                    }
                }
            );
            //this.setState({ isSent: true })
            //this.setState({ isSaved: true })
        }
        else {
            documentStore.loadDefaultDocument();
        }
        this.setState({isLoading:false})
    }

    getListUrl() {
        //returns document list URL//
        return '/admin/documents';
    }

    cancelClick() {
        //redirects pages to list URL//
        // const { history } = this.props;
        this.props.router.navigate(this.getListUrl());
    }

    componentDidMount() {
        const { user_type } = this.props.userStore.currentUser;
        this.loadData();
        if (user_type == "admin" || user_type == "super-admin") {
            this.setState({ isEmployee: false })
        }
    }

    handleTextChange = (name, value) => {
        //calls action to handle text changes//
        this.props.documentStore.handleDocumentChange(name, value);
    }

    handleButtonClick = (type) => {
        if (type === 'save') {
            this.setState({ toggleSaveBtn: true });
            this.addDocumentData().then(()=>{
                this.setState({ toggleSaveBtn: false });
            });
        }
        // else if (type === 'send') {
        //     this.sendDocument();
        // } else if (type === 'read') {
        //     this.updateDocumentReadStatus();
        // }
    }

    // updateDocumentReadStatus = async () => {
    //     const { documentStore, commonStore, t } = this.props;
    //     let document_id = documentStore.currentDocument.id;

    //     await documentStore.sendDocumentReadStatus(document_id).then(
    //         (response) => {
    //             if (response) {
    //                 commonStore.addNotification(t('Document Book Status Updated'), null, 'success');
    //                 return true;
    //             }
    //         })
    //         .catch(
    //             (error) => {
    //                 commonStore.addNotification(error.message || t('Error'), null, 'error');
    //             }
    //         )
    // }

    // sendDocument = async () => {
    //     const { documentStore, commonStore, t } = this.props;
    //     let data = {
    //         id: 0,
    //         document_id: documentStore.currentDocument.id,
    //     }

    //     await documentStore.sendDocument(data).then(
    //         (response) => {
    //             if (response) {
    //                 commonStore.addNotification(t('Document Book Send'), null, 'success');
    //                 return true;
    //             }
    //         })
    //         .catch(
    //             (error) => {
    //                 commonStore.addNotification(error.message || t('Error'), null, 'error');
    //             }
    //         )
    // }


    //handle attachemnt change and get filepath//
    handleAttachmentchange = (attachmentId) => {
        this.setState({ attachments: attachmentId }, () => {
            this.loadAttachment(attachmentId)
        })
    }

    //loads pdf attachment to viwer state variable//
    loadAttachment = async (attachmentId) => {
        this.setState({ pdfFilePath: `${config.API_ROOT}/attachments/${attachmentId}`, viewPdf: true })
    }

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
    }

    addDocumentData = async () => {
        //calls action to save document//
        const { documentStore, commonStore, t } = this.props;
        let document = null;

        //allow only either PDF or doc in one record//
        if (this.state.isPDF) {
            document = {
                id: documentStore.currentDocument.id,
                document_name: documentStore.currentDocument.document_name,
                document_description: "",
                ispdf: this.state.isPDF,
                pdf_attachment: this.state.attachments,
            }
        } else {
            document = {
                id: documentStore.currentDocument.id,
                document_name: documentStore.currentDocument.document_name,
                document_description: documentStore.currentDocument.document_description,
                ispdf: this.state.isPDF,
                pdf_attachment: "",
            };
        }

        if (document.document_name === "" || document.document_name == null || document.document_name == undefined) {
            commonStore.addNotification(t('Please Enter Document Name'), null, 'error');
            return;
        }

        if (!this.state.isPDF && (document.document_description === "" || document.document_description == null || document.document_description == undefined)) {
            commonStore.addNotification(t("Cannot Add Blank Document"), null, 'error');
            return;
        }

        if (this.state.isPDF && (this.state.attachments === "" || this.state.attachments == null || this.state.attachments == undefined)) {
            commonStore.addNotification(t("No PDF Uploaded"), null, 'error');
            return;
        }

        await documentStore.saveDocumentData(document).then((data) => {
            // const { history } = this.props;
            if (data) {
                commonStore.addNotification(t('Document Saved'), null, 'success');
                // if (!this.state.isSent) {
                //     this.sendDocument();
                // }
                this.props.router.navigate(this.getListUrl());
                return true;
            }
        }).catch((error) => {
            commonStore.addNotification(error.message || t('Error'), null, 'error');
        })
    }

    render() {
        //renders the input and documentEditor component for adding or editing documents//

        const { t } = this.props;
        const { currentDocument } = this.props.documentStore;
        const { toggleSaveBtn, isLoading } = this.state;
        if(isLoading) return <LoadingSpinner/>

        return (
            <div className="page">
                <div className="page__content">
                    <form className="custom-form">
                        <div className="custom-form__row mt-4">
                            <Input
                                disabled={this.state.isEmployee}
                                className="custom-form__col"
                                label={`${t('Document Name')} *`}
                                placeholder={t('Document Name')}
                                type="text"
                                name="document_name"
                                value={currentDocument.document_name}
                                onChange={evt => {
                                    this.handleTextChange('document_name', evt.target.value);
                                }}
                            />
                        </div>
                        {/* {!this.state.isEmployee && */}
                        {false &&
                            <div className="custom-form__row center-block">
                                <div className="custom-form__row" style={{ margin: 10 }}>
                                    <Radio
                                        label={t('Document')}
                                        checked={!this.state.isPDF}
                                        //disabled={!this.props.add}
                                        onChange={() => this.setState(prevState => ({ isPDF: !prevState.isPDF }))} />
                                </div>
                                <div className="custom-form__row" style={{ margin: 10 }}>
                                    <Radio
                                        label={t('PDF')}
                                        //disabled={!this.props.add}
                                        checked={this.state.isPDF}
                                        onChange={() => this.setState(prevState => ({ isPDF: !prevState.isPDF }))} />
                                </div>
                            </div>
                        }

                        <Row className="justify-content-center">
                            {/* {this.state.isPDF ? ( */}
                            {false ? (
                                <div>
                                    <div className="attachment-box" style={{ width: '100%' }}>
                                        <span className="attachment-box__label">{t('Upload PDF')}</span>
                                        <AttachmentsWidget
                                            value={this.state.attachments}
                                            onChange={attachIds => this.handleAttachmentchange(attachIds)}
                                            options={{
                                                imageContext: {
                                                    existingAttachment: this.state.attachmentsList,
                                                    fileType: 'docs',
                                                    id: 0,
                                                    model: 'Image',
                                                },
                                            }}
                                        />
                                    </div>
                                    <div style={{ border: '1px solid rgba(0, 0, 0, 0.3)', height: 600, width: 800 }}>
                                        {this.state.viewPdf ? (
                                            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.3.122/build/pdf.worker.min.js">
                                                <Viewer
                                                    fileUrl={this.state.pdfFilePath}
                                                // plugins={[defaultLayoutPluginInstance]} 
                                                />

                                            </Worker>
                                        ) : (
                                            <p>No pdf selected</p>
                                        )}
                                    </div>
                                </div>
                            ) : (
                                (this.props.add || currentDocument.document_description) && <DocumentEditor
                                    name="document_description"
                                    value={currentDocument.document_description}
                                    onChange={value => { 
                                        this.handleTextChange('document_description', value) 
                                    }}
                                    isEmployee={this.state.isEmployee}
                                />
                            )
                            }
                        </Row>
                    </form>
                </div>
                <Row className='mt-40'>
                    {!this.state.isEmployee &&//== true ? (
                        // <Button fill wd pullRight onClick={() => this.handleButtonClick('read')}>
                        //     {t('Read')}
                        // </Button>)
                        // : (
                        <Col className='text-start'>
                            <Button fill wd
                                disabled={toggleSaveBtn}
                                onClick={() => this.handleButtonClick('save')}>
                                {t('Save')}
                            </Button>
                            {/* {this.state.isSent &&
                                    <Button wd pullRight onClick={() => this.handleButtonClick('send')}>
                                        {t('Send')}
                                    </Button>
                                } */}
                        </Col>
                        //)
                    }
                    <Col  className='text-end'>
                        <Button wd onClick={() => this.cancelClick()}>
                            {t('Cancel')}
                        </Button>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default inject('documentStore', 'authStore', 'userStore', 'commonStore')(withLocalization(applicationRouter(observer(DocumentAddEdit))));


