import React from "react";
import "./styles.css";

import CheckIcon from "../../assets/images/checkIcon.svg";

//i18n
import { withTranslation } from "react-i18next"

function ListFeau(props) {
  return (
    <div className={`listFeaCol ${props.customClass}`}>
      <div className={`listFeaIcon ${props.color}`}>
        <span><img src={CheckIcon} alt={props.title} /></span>
      </div>
      <div className="listFeaInfo">
        <h4>{props.title}</h4>
        <p>{props.description}</p>
      </div>
    </div>   
  );
}
export default withTranslation()(ListFeau);