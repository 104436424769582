import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { cloneDeep } from 'lodash';

//components
import withLocalization from '~/hoc/withLocalization';
import GenericForm from '~/components/GenericForm/GenericForm';

//elements
import LoadingSpinner from '~/elements/LoadingSpinner';

//schema
import getSchema from '~/library/schemas/task';

const defaultUiSchema = {
    'ui:field': 'layout',
    'ui:layout:hideframe': true,
    'ui:layout': [
        {
            name: { xs: 6, },
            address: { xs: 6 },
        },
        {
            'ui:className': 'mt-2',
            post_place: { xs: 6 },
            post_number: { xs: 6 },
        },
        {
            'ui:className': 'mt-2',
            gps_data: { xs: 12 },
        },
        {
            'ui:className': 'mt-2',
            task_members: { xs: 6 },
            est_hours: { xs: 6 }
        },
        {
            'ui:className': 'mt-2',
            description: { md: 12 },
        }
    ],
    gps_data: {
        'ui:widget': 'GpsCoordinatesWidget',
    },
    name: {
        'ui:placeholder': 'Task Template name',
    },
    description: {
        'ui:widget': 'textarea',
        'classNames': 'textarea-100'
    },
    task_members: {
        'ui:widget': 'MembersWidgetMultipleSelect'
    }
};


class TaskTemplateForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            schema: getSchema({ mode: props.mode }),
            uiSchema: defaultUiSchema,
        };
        this.props.taskTemplateStore.loading = true;
    }

    componentDidMount() {
        this.loadData();
    }

    getListUrl() {
        return '/admin/projects';
    }

    async loadData() {
        const { taskTemplateStore, projectId, taskTemplateUUID, mode } = this.props;
        this.setState({
            schema: getSchema({
                config: this.props.commonStore.config,
                mode: mode
            }),
        });

        if (!taskTemplateUUID) {
            this.setState({ uiSchema: this.prepareSchema(defaultUiSchema, null) });
            return taskTemplateStore.returnDefaultNew({ project_id: projectId });
        }
        const taskTemplate = await taskTemplateStore.load(taskTemplateUUID, projectId);
        this.setState({ uiSchema: this.prepareSchema(defaultUiSchema, taskTemplate.taskTemplate) });
    }

    prepareSchema(_schema) {
        const schema = cloneDeep(_schema);
        schema.task_members = {
            'ui:widget': 'MembersWidgetMultipleSelect',
            'ui:project': {
                project_id: this.props.mode !== 'task_template' ? this.props.projectId : 0
            }
        }
        return schema;
    }

    async recalcGps(e) {
        return this.props.taskTemplateStore.recalcGpsForTask(e);
    }

    onSave(values) {
        const { taskTemplateStore, t, commonStore } = this.props;
        const clientData = commonStore.config && commonStore.config.client && commonStore.config.client.data;
        const isGPSObligatory = clientData.basicRules.workinghourGPSObligatory;
        if (isGPSObligatory) {
            if (values.gps_data == null || values.gps_data == undefined || values.gps_data == '') {
                commonStore.addNotification(t('Please add address.'), null, 'error');
                return false;
            }
        }

        return taskTemplateStore.save(values).then(result => {
            if (!result.taskTemplate || !result.taskTemplate.id) {
                commonStore.addNotification(t(result.message || 'Error'), null, 'error');
                return false;
            }
            commonStore.addNotification(t('Saved'), null, 'success');
            this.getBack()
            return true;
        })
    }

    getBack = () => {
        this.props.handleCloseForm();
    }

    render() {
        const { taskTemplateStore, taskTemplateUUID } = this.props;
        const { loading, currentEntity } = taskTemplateStore;
        const { schema, uiSchema } = this.state;

        if (loading) {
            return <LoadingSpinner />;
        }

        return (
            <div className="primary-page">
                <GenericForm
                    entity={currentEntity.taskTemplate}
                    uiSchema={uiSchema}
                    recomputeFields={['gps_data', 'data.addressCache']}
                    schema={schema}
                    forceRefresh={!!currentEntity.taskTemplate.gps_data}
                    onChange={async data => this.recalcGps(data)}
                    translationScope="forms.task"
                    onSave={values => this.onSave(values)}
                    listUrl={this.getListUrl()}
                    isAdding={!taskTemplateUUID}
                    goBack={() => this.getBack()}
                />
            </div>
        );
    }
}

export default inject('taskTemplateStore', 'commonStore', 'userStore')(withLocalization(observer(TaskTemplateForm)));
