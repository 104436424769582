import React, { Component } from 'react';
import { totalTimeFormat } from '../../utils/dateFormat';
import { getMinutesFromTimeInput } from '../../utils/timeCalc'
export function OvertimeDisplay({ data, dateTimeRules, config, _total_hours_overtime, t }) {
    const overtimes = [];

    if (config.client.data.basicRules.overtimeAutomaticMode && _total_hours_overtime) {
        return (
            <div style={{ lineHeight: '10px', fontSize: '9px' }}>
                <div className='d-flex flex-column'>
                    <div>{t('Automatic')}</div>
                    {config.client.data.basicRules.overtimeCalcRules.calc_percent}%: {totalTimeFormat(_total_hours_overtime, dateTimeRules)}
                </div>
            </div>
        )
    }
    if (!data) {
        return null;
    }
    Object.keys(data).forEach(key => {
        overtimes.push(data[key]);
    });
    return (
        <div style={{ lineHeight: '10px', fontSize: '9px' }}>
            {overtimes.map((row, index) => (
                <div key={index}>
                    {/* {row.name}: {row.value} */}
                    {row.name}: {totalTimeFormat(getMinutesFromTimeInput(row.value), dateTimeRules)}
                </div>
            ))}
        </div>
    );
}
