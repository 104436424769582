import { getTimeFormat, dateToTimeFormat, shortDateFormat } from '~/utils/dateFormat';
import { statusMap } from '~/utils/normalStatuses';
import { CircleExclamationSGV } from '../../../utils/constants';

function resolver({ params }) {
    const { eventItem, start, end, statusColor, t, dateTimeRules } = params;
    const multiplePendingShifts = eventItem.shiftCount && eventItem.shiftCount > 1 ? true : false;
    let bigText = `${eventItem.source === 'shift' ? t('Scheduled') + ":" : ""}${start.format(getTimeFormat(dateTimeRules, true))} - ${end.format(getTimeFormat(dateTimeRules, true))}${eventItem.addBonus ? ` ${t('with bonus')}` : ''} ${multiplePendingShifts ? `||${t('Shift Count')}: ` + eventItem.shiftCount : ''}`;
    const createdByText = eventItem.created_by_name && eventItem.created_by_name.trim() && eventItem.source === 'shift' ? t('Created By') + ": " + eventItem.created_by_name.toUpperCase() : false;
    let titleText = `${eventItem.projectName} / ${eventItem.taskName}`;
    if (eventItem.source === 'vacation') {
        bigText = t('Vacation');
        titleText = '';
    }
    if (eventItem.source === 'absence') {
        bigText = t('Absence');
        titleText = '';
    }
    if (eventItem.availability_type === 'available') {
        titleText = t('Available');
        bigText = `${eventItem.from ? eventItem.from + ' -' + eventItem.to : ''} `;
    }
    if (eventItem.availability_type === 'not available') {
        titleText = t('Not Available');
        bigText = `${eventItem.from ? eventItem.from + ' -' + eventItem.to : ''} `;
    }
    if (eventItem.from == '12:00 am' && eventItem.to == '12:00 am') {
        bigText = ''
    }

    return (
        <div className="scheduler-popup">
            <div className="scheduler-popup__header">
                <div className="status-dot" style={{ backgroundColor: statusColor }} />
                <span>{titleText}</span>
            </div>
            <div className="scheduler-popup__content">{bigText}</div>
            {createdByText &&
                <div className="scheduler-popup__contentText">{createdByText}</div>
            }
            {eventItem.isCopied &&
                <div className="scheduler-popup__contentText">
                    {`${t('Copied')}: ${shortDateFormat(eventItem.isCopied.date, dateTimeRules)}-${dateToTimeFormat(eventItem.isCopied.date, dateTimeRules)}`}
                </div>
            }
            {!!eventItem.timelogCount && eventItem.timelogEntries.map((ele, i) =>
                <div key={`ps_ele_${i}`} className="scheduler-popup__content"
                    style={{ color: !ele.timelogLoggedFromDiffLocation ? '#2550AC' : '#FF4747' }}>
                    {t("Timesheet")}#{i + 1}:{ele.timelogTitle}({t(statusMap[ele.timelogStatus])})
                    {ele.timelogLoggedFromDiffLocation &&
                        <span>{CircleExclamationSGV({ width: 14, fillColor: "#FF4747" })} {t('Different task')}</span>}
                </div>)
            }
        </div>
    );
}

export default resolver;
