import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Form, Button } from "react-bootstrap";
import "./styles.css";

import SubmitIcon from "../../assets/images/submitIcon.svg";

//i18n
import { withTranslation } from "react-i18next"

function ContactForm({t,onSubmit,handleFormChange,contactForm}) {
  return (
    <>
      <div className="cntctForm commonForm">
        <Form onSubmit={onSubmit}>
          <Form.Group className="mb-4" controlId="formContactName">
            <Form.Label>{t("Business Name")}*</Form.Label>
            <Form.Control type="text" placeholder={t("Please add your company name.")} value={contactForm.company} onChange={(e) => handleFormChange('company', e.target.value)} required/>
          </Form.Group>
          <Row>
            <Col lg={6}>
              <Form.Group className="mb-4" controlId="formContactEmail">
                <Form.Label>{t("Full name")}*</Form.Label>
                <Form.Control type="text" placeholder={t("Type full name.")} value={contactForm.fullName} onChange={(e) => handleFormChange('fullName', e.target.value)} required/>
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mb-4" controlId="formContactPhone">
                <Form.Label>{t("Email")}*</Form.Label>
                <Form.Control type="email" placeholder={t("Add email.")} value={contactForm.email} onChange={(e) => handleFormChange('email', e.target.value)} required/>
              </Form.Group>
            </Col>
          </Row>
          <Form.Group className="mb-4" controlId="formContactMobileNumber">
            <Form.Label>{t("Phone Number (optional)")}*</Form.Label>
            <Form.Control type="text" placeholder={t("Please enter your mobile number.")} value={contactForm.phone} onChange={(e) => handleFormChange('phone', e.target.value)}/>
          </Form.Group>
          <Form.Group className="mb-4" controlId="formContactMessage">
            <Form.Label>{t("Message")}*</Form.Label>
            <Form.Control as="textarea" rows={3} placeholder={t("Write something……")} value={contactForm.comment} onChange={(e) => handleFormChange('comment', e.target.value)} required/>
          </Form.Group>
          <Form.Group className="mb-4" controlId="formContactCheckbox">
            <Form.Check type="checkbox" label={<>{t("I accept the")} <Link to="/terms">{t("Terms & Conditions")}</Link></>} onChange={(e,checked) => handleFormChange('accept', e.target.checked)} required/>
          </Form.Group>
          <div className="formBtn text-center">
            <Button className="btnCommon btnBig" variant="primary" type="submit"><i className="d-inline-block pe-4"><img src={SubmitIcon} alt="Submit" /></i> {t("Submit")}</Button>
          </div>
        </Form>
      </div>
    </>
  );
}
export default withTranslation()(ContactForm)