import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

//components
import withLocalization from '../../../hoc/withLocalization';

//utils
import config from '~/library/config';
import getBase64 from '~/utils/getBase64';

//assets
import fileuploadIcon from '../../../assets/img/fileupload_icon.svg';


const ImagesUploaderInternal = inject('projectStore', 'userStore', 'commonStore')(withLocalization(observer(class ImagesUploaderInternal extends Component {
    getUrl = img => {
        return `${config.UPLOADS_API_ENDPOINT}/${img}`;
    };

    onUpload = (e) => {
        const { onChange, options, userStore } = this.props;
        const file = e.target.files[0];
        const { name } = file;

        getBase64(file)
            .then(imgData => {
                userStore
                    .fileUpload({
                        to_model: options.imageContext.model,
                        to_id: options.imageContext.id,
                        user_id: userStore.currentUser.id,
                        filename: name,
                        filetype: options.imageContext.fileType,
                        content: imgData,
                    })
                    .then(newId => {
                        onChange(`${newId}`);
                    });
                this.fileInput.value = '';
            })
            .catch(error => {
            });
    };

    render() {
        const { value } = this.props;
        const image = value;
        const isDashedBg = this.props?.options?.imageContext?.isDashedBg || false
        return (
            <div className="uploads">
                {
                    isDashedBg ? (
                        <>
                            <div className='uploads__dashed_image_container' style={{ border: (!image ? '1px dashed #4066B6' : 'none') }}>
                                <img src={!image ? fileuploadIcon : this.getUrl(image)} alt={"upload image"}
                                    onClick={() => this.fileInput.click()}
                                    className={!image ? 'uploads__dashed_default_image' : 'uploads__dashed_image'}
                                    role="button"
                                />
                            </div>

                        </>
                    ) : (
                        <>
                            <div className='uploads__image_container'>
                                <img src={!image ? "/images/user_profile_default.svg" : this.getUrl(image)} alt={"upload image"}
                                    onClick={() => this.fileInput.click()}
                                    className="uploads__image"
                                    role="button"
                                />
                                <img src="/images/camera.svg" alt={"upload image"}
                                    onClick={() => this.fileInput.click()}
                                    className="uploads__camera_icon"
                                    role="button"
                                />
                            </div>

                        </>
                    )
                }
                <input
                    style={{ display: 'none' }}
                    type="file"
                    accept="image/*"
                    ref={fileInput => {
                        this.fileInput = fileInput;
                    }}
                    onChange={this.onUpload}
                    multiple={false}
                />
            </div>
        );
    }
}
)));

export const ImageUploadWidget = observer(class ImageUploadWidget extends Component {
    render() {
        return <ImagesUploaderInternal {...this.props} />;
    }
})

