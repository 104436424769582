import { observable, action, reaction, makeObservable } from 'mobx';
import { toast } from 'react-toastify';
import agent from '../agent';

class CommonStore {
    constructor() {
        makeObservable(this, {
            locale: observable,
            connectionError: observable,
            config: observable,
            token: observable,
            appLoaded: observable,
            currentGitRev: observable,
            getGitRev: action,
            setGitRev: action,
            setToken: action,
            setAppLang: action,
            setAppLoaded: action,
            getConfig: action,
            log: action,
            addNotification: action,
            setLocale: action,
            getUsername: action,
            sendContact: action,
        });

        reaction(
            () => this.token,
            token => {
                if (token) {
                    window.sessionStorage.setItem('jwt', token);
                } else {
                    window.sessionStorage.removeItem('jwt');
                    window.sessionStorage.removeItem('multiAdminJwt');
                }
            }
        );
    }

    locale = window.sessionStorage.getItem('locale') || 'no';
    connectionError = false;
    config = {};
    token = window.sessionStorage.getItem('jwt');
    appLoaded = false;
    currentGitRev = null;

    async getGitRev() {
        try {
            const rev = await agent.Users.gitRev();
            return rev.rev;
        } catch (e) {
            return 'local';
        }
    }

    setGitRev(rev) {
        this.currentGitRev = rev;
    }

    setToken(token) {
        this.token = token;
        if (token) this.setAppLoaded();
    }

    setAppLang(lang) {
        this.locale = lang;
    }

    setAppLoaded(isReload = true) {
        if (!this.token && isReload) {
            this.appLoaded = true;
            return null;
        }
        return agent.Auth.config()
            .then(
                action(config => {
                    this.config = config;
                    this.connectionError = false;
                    this.appLoaded = true;
                    return config;
                })
            )
            .catch(e => {
                this.connectionError = true;
                this.appLoaded = true;
            });
    }

    getConfig() {
        return this.config;
    }

    log(log) {
        agent.Auth.log(log);
    }

    /**
     * Create a notification displayed with `react-notification-system`
     *
     * @param message Message of the notification.
     * @param [title=null] Title of the notification.
     * @param [level="info"] Level of the notification. Available: success, error, warning and info.
     * @param [position="tc"] Position of the notification. Available: tr (top right), tl (top left), tc (top center), br (bottom right), bl (bottom left), bc (bottom center).
     * @param [autoDismiss=5] Delay in seconds for the notification go away. Set this to 0 to not auto-dismiss the notification.
     */
    addNotification(message, title = null, level = 'info', position = 'top-right', autoDismiss = 1000) {
        const uid = new Date().getTime().toString();
        toast(message, {
            toastId: uid,
            type: level,
            position: position,
            autoClose: autoDismiss,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    }

    setLocale(lang) {
        agent.Auth.setLang(lang);
    }

    getUsername(id) {
        if (!this.config || !this.config.pms) return '';
        return this.config.pms[id];
    }

    sendContact(params) {
        return agent.Dashboard.contact(params);
    }
}

const _CommonStore = new CommonStore();
export default _CommonStore;
