const STATUS_TEXTS = {
    active: 'Active',
    inactive: 'Inactive',
    manual: 'Manual',
    auto: 'Auto',
    invited: 'Invited',
    pending: 'Pending',
    approved: 'Approved',
    rejected: 'Rejected',
    draft: 'Draft',
    sick_leave: 'Sick Leave',
    swap_shift: 'Swap Shift'
};
export default {
    STATUS_TEXTS,
};
