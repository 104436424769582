import React, { Component } from 'react'
import Icon from 'antd/lib/icon';
import Button from '~/components/CustomButton/CustomButton.jsx';
import CopyIcon from '../../../assets/img/copy_icon.svg';
import Select from '../../../elements/Select'

class MonthCustomHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            options: [{ value: '1', label: this.props.t('Week') }, { value: '2', label: this.props.t('Month') }],
            showInfo: false
        }
    }

    navigate = action => {
        this.props.onNavigate(action)
    }
    startCopyToNextMonth() {
        this.props.handleCopyMonth();
    }
    toggleHover(check) {
        this.setState({ showInfo: check });
    }
    render() {
        const { options, showInfo } = this.state;
        const { isMember } = this.props;
        return (
            <div className="customize_toolbar">
                <div className='rbc-toolbar'>
                    <span className="rbc-btn-group">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Select options={options}
                                defaultValue={options[1]}
                                style={{ width: '120px' }}
                                onChange={event => this.props.onViewChange(event)}
                            />
                            <div style={{ marginLeft: '5px' }}>
                                <div style={{ display: 'flex' }}>
                                    <span style={{ fontSize: '11px' }}><i className="fa fa-dot-circle-o none_circle" style={{ color: '#73B539', fontSize: '8px' }}></i>{this.props.t('On Time')}</span>
                                    <span style={{ fontSize: '11px' }}><i className="fa fa-dot-circle-o none_circle" style={{ color: 'red', fontSize: '8px' }}></i>{this.props.t('Late')}</span>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <span style={{ fontSize: '11px' }}><i className="fa fa-dot-circle-o none_circle" style={{ color: '#447df7', fontSize: '8px' }}></i>{this.props.t('Current Status')}</span>
                                    <span style={{ fontSize: '11px' }}><i className="fa fa-dot-circle-o none_circle" style={{ color: '#B4B4B4', fontSize: '8px' }}></i>{this.props.t('No Show')}</span>
                                </div>
                            </div>
                        </div>
                        <div style={{
                                borderRadius: '10px',
                                backgroundColor: '#F9FAF5',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-evenly'
                            }}
                        >
                            <Button schedule onClick={() => this.navigate('PREV')}>
                                <Icon type="left" className="icon-nav" />
                            </Button>
                            <span className="rbc-toolbar-label fw-bold">
                                {this.props.t(this.props.label)}
                            </span>
                            <Button schedule onClick={() => this.navigate('NEXT')}>
                                <Icon type="right" className="icon-nav" />
                            </Button>
                        </div>
                        {!isMember && (
                            <div>
                                <span style={{ marginRight: '10px', position: 'relative', marginRight: '20px' }}>
                                    <i className="fa fa-info-circle" style={{ color: '#447df7' }} onMouseEnter={() => this.toggleHover(true)} onMouseLeave={() => this.toggleHover(false)} aria-hidden="true"></i>
                                    <div className={showInfo ? 'info_box' : 'hide_info_box'} >
                                        {this.props.t('Monthly calendar is displaying start time. Click on time slot to see more.')}
                                    </div>
                                </span>
                                <Button id="schedule-bulk-edit" className="month_btn"
                                    onClick={() => this.props.handleCopyMonth()}
                                    style={{ marginRight: '15px' }}>
                                    <img src={CopyIcon} alt="Copy Icon" />
                                    &nbsp;{this.props.t('Copy')}&nbsp;{this.props.t('Month')}
                                </Button>
                                <Button id="schedule-bulk-edit" className="month_btn"
                                    onClick={() => this.props.handleBulkEvent()}>
                                    {this.props.t('Bulk shift')}
                                </Button>
                            </div>
                        )}
                    </span>
                </div>
            </div>
        )
    }
}

export default MonthCustomHeader
