import React, { Component } from 'react';
import {v4 as uuid} from 'uuid';

class CustomRadio extends Component {
    render() {
        const {
            label, option, withColor, onChange, name, ...rest
        } = this.props;
        const key = uuid();
        let labelStyle = {};

        if (withColor) {
            labelStyle = {
                color: withColor,
            };
        }
        return (
            <div className={`radio ${withColor ? 'with-color' : ''}`}>
                <input id={key} name={name} onChange={onChange} type="radio" value={option} {...rest} />
                <label  htmlFor={key} style={labelStyle}>
                    <span>{label}</span>
                </label>
            </div>
        );
    }
}

export const Radio = props => {
    const {
        onChange, checked, value, name, label, ...rest
    } = props;
    return (
        <label className={`radio-widget ${checked ? 'radio-widget_checked' : ''}`} {...rest}>
            <input type="radio" name={name} checked={checked} onChange={onChange} value={value} />
            {label}
        </label>
    );
};

export default CustomRadio;
