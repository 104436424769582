import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { inject, observer } from 'mobx-react';
import { Col, Container, Row } from 'react-bootstrap';
import moment from 'moment/min/moment-with-locales';

//components
import withLocalization from '../../hoc/withLocalization';
import applicationRouter from '~/hoc/applicationRouter'
import ScheduleMember from './Schedule/ScheduleMember';
import TimelogDashboardWidget from './Timelogs/TimelogDashboardWidget';
import DeviationDashboardWidget from './Deviation/DeviationDashboardWidget';
import CustomCircularProgressbar from '../../components/ProgressBar/CircularProgressBar';
import RectangularCardIcon from '../../components/Card/RectangularCardIcon';
import RectangularCard from '../../components/Card/RectangularCard';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';


//elements
import LoadingSpinner from '~/elements/LoadingSpinner';
import Button from '../../components/CustomButton/CustomButton.jsx';

//sevices
import config from '~/library/config';

//utils
import { totalTimeFormat, get_currency } from '~/utils/dateFormat';
import { minutesToTimeInput } from '~/utils/timeCalc';
import { getPercentageFromNumberValues, getPercentage } from '../../utils/commonMethods';

//assets
import overtimeHours from '../../assets/img/new_design/overtimeHours.svg';
import totalCostsSvg from '../../assets/img/new_design/totalCosts.svg';
import totalHours from '../../assets/img/new_design/totalHours.svg'

//styles
import './dashboard.css';

ChartJS.register(ArcElement, Tooltip, Legend);
class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            setPage: 0,
            moreShifts: false,
            from: moment(new Date()).startOf('month').toDate(),
            to: moment(new Date()).toDate(),
            activeProject: null,
            showSchedules: 6,
        }
        this.handleSeeMore = this.handleSeeMore.bind(this);
        this.handleSeeLess = this.handleSeeLess.bind(this);
    }


    componentDidMount() {
        this.loadData();
    }

    async loadData() {
        const { dashboardStore, commonStore } = this.props;
        const { from, to } = this.state;
        await dashboardStore.load({
            from: moment(from).format('YYYY-MM-DD'),
            to: moment(to).format('YYYY-MM-DD'),
            scheduleParams: { page: 0, pagesize: 6 }
        });
        if (commonStore.config.client.data.isFirstTimeLogin === true) {
            this.props.router.navigate('/admin/control_panel')
        }
    }
    redirectToAnotherPage(idx, dataCount) {
        if (!dataCount) return;
        switch (idx) {
            case 0:
                this.props.router.navigate('/admin/timelogs', { state: { dashboardRedirected: 'draft' } })
                break;
            case 1:
                this.props.router.navigate('/admin/timelogs', { state: { dashboardRedirected: 'logs_today' } })
                break;
            case 2:
                this.props.router.navigate('/admin/members', { state: { dashboardRedirected: 'users_not_at_work' } })
                break;
            case 3:
                this.props.router.navigate('/admin/timelogs', { state: { dashboardRedirected: 'active' } })
                break;
            default:
                break;
        }
    }

    renderStatusCards(dataObject, isTitleVisible, isAdminBlock = false) {
        const { t } = this.props;
        return (
            <>
                {
                    isTitleVisible && (
                        <Row className='mb-4'>
                            <NavLink to="/admin/clients">
                                <span className='dashboard-title mx-2 p-0'>
                                    {t(dataObject.title)} {t('Status')}
                                </span>
                            </NavLink>

                        </Row>
                    )
                }

                <Row className='mx-4 mx-md-0'>
                    {dataObject.metaData.map((metaDataHeader, index) =>
                        <Col key={uuid()} className={isAdminBlock ? 'd-flex justify-content-center d-md-block col-lg-3 col-sm-6 col-10 offset-sm-0 offset-1' : 'd-flex justify-content-center justify-content-md-start'}>
                            <RectangularCard backgoundClass={`rectangle-card-bg-${dataObject.backgroundColor[index]}`} key={uuid()} className='w-100'>
                                <Row className='align-items-center'>
                                    <Col xs={5} sm={5}>
                                        <span className='d-flex justify-content-center dashboard-progressBar-header-text text-center mt-2'>
                                            {t(metaDataHeader)}
                                        </span>
                                        <span onClick={() => this.redirectToAnotherPage(index, dataObject.data[index])} className={`d-flex justify-content-center cursor-pointer dashboard-progressBar-value dashboard-progressBar-value-color-${dataObject.dataColors[index]}`}>
                                            {dataObject.data[index]}
                                        </span>
                                    </Col>
                                    <Col xs={7} sm={7}>
                                        <span className='d-flex justify-content-center'>
                                            <CustomCircularProgressbar
                                                percentage={dataObject.dataInPercentatge[index]}
                                                pathColor={dataObject.dataColors[index]}
                                                rotationStart={(index + 1) * 2}
                                            />
                                        </span>
                                    </Col>
                                </Row>
                            </RectangularCard>
                        </Col>
                    )
                    }
                </Row>
            </>
        )
    }

    renderSuperAdmin() {
        const { dashboardStore } = this.props;
        const { currentData } = dashboardStore;
        if (!currentData.clients || !currentData.clients[0].active || !currentData.users[0].active || !currentData.usersCounters[0].working_now) {
            return null;
        }
        const clientStatusData = {
            "title": "Clients",
            "metaData": ["Active", "Inactive"],
            "data": [Number(currentData.clients[0].active), Number(currentData.clients[0].inactive)],
            "dataInPercentatge": getPercentageFromNumberValues([Number(currentData.clients[0].active), Number(currentData.clients[0].inactive)]),
            "dataColors": ["32a852", "E83030"],
            "backgroundColor": ["C3E7C3", "F3DDDD"]
        }
        const userStatusData = {
            "title": "Users",
            "metaData": ["Active", "Inactive"],
            "data": [Number(currentData.users[0].active), Number(currentData.users[0].inactive)],
            "dataInPercentatge": getPercentageFromNumberValues([Number(currentData.users[0].active), Number(currentData.users[0].inactive)]),
            "dataColors": ["32a852", "E83030"],
            "backgroundColor": ["C3E7C3", "F3DDDD"]
        }
        const userWorklogStatusData = {
            "title": "Members",
            "metaData": ["Working now", "Gone home", "Not at work"],
            "data": [Number(currentData.usersCounters[0].working_now), Number(currentData.usersCounters[0].gone_home), Number((currentData.usersCounters[0].all_users - currentData.usersCounters[0].gone_home) - currentData.usersCounters[0].working_now)],
            "dataInPercentatge": getPercentageFromNumberValues([Number(currentData.usersCounters[0].working_now), Number(currentData.usersCounters[0].gone_home), Number((currentData.usersCounters[0].all_users - currentData.usersCounters[0].gone_home) - currentData.usersCounters[0].working_now)]),
            "dataColors": ["51459E", "ffa534", "EE2222"],
            "backgroundColor": ["E1E1E1", "ffa534", "F3DDDD"]
        }
        return (
            <Container fluid>
                <Row className='mt-4'>
                    <Col xs={12} md={6} lg={4}>
                        {this.renderStatusCards(clientStatusData, true)}
                    </Col>
                    <Col xs={12} md={6} lg={4}>
                        {this.renderStatusCards(userStatusData, true)}
                    </Col>
                    <Col xs={12} md={6} lg={4}>
                        {this.renderStatusCards(userWorklogStatusData, true)}
                    </Col>
                </Row>
            </Container>
        );
    }

    renderMember() {
        const { t, dashboardStore, userStore, commonStore } = this.props;
        const { currentData } = dashboardStore;
        const dashboard = currentData;
        const { data: biztypeData } = commonStore.config.biztype;
        let rate = userStore.currentUser.hourly_rate;
        let earned = 0;
        const dateTimeRules = commonStore.config.client && commonStore.config.client.data && 
                              commonStore.config.client.data.dateTimeRules ? 
                              commonStore.config.client.data.dateTimeRules : false;
        const isWeeklyKey = commonStore.config.client && commonStore.config.client.data && 
                            commonStore.config.client.data.basicRules &&
                            commonStore.config.client.data.basicRules.allowWeeklyManualOvertimeRegister ?
                            commonStore.config.client.data.basicRules.allowWeeklyManualOvertimeRegister : false
        if (rate && dashboard.grandtotals
            && (userStore.currentUser.payment_mode !== 'fixed')) {
            earned = parseInt(
                (rate * (dashboard.grandtotals.total_normal + dashboard.grandtotals.total_overtime)) / 60 + currentData.grandtotals.shiftAllowanceTotal
            );
            earned = `${get_currency(dateTimeRules) === '$' ? `${get_currency(dateTimeRules)} ${earned}` : `${earned} ${get_currency(dateTimeRules)}`}`;
            rate = `${rate} / hrs`;
        }
        else if (userStore.currentUser.payment_mode === 'fixed') {
            earned = `${get_currency(dateTimeRules) === '$' ? `${get_currency(dateTimeRules)} ${rate}` : `${rate} ${get_currency(dateTimeRules)}`}`;
            rate = `${rate} / fixed`;
        }
        else {
            earned = '?';
            rate = t('Rate not set');
        }
        return (
            <>
                <Container className='dashboard-container' fluid style={{ paddingRight: "0px", paddingLeft: "0px" }}>
                    <Row key="main" className="dashboard-stats mx-4 mx-md-0">
                        <TimelogDashboardWidget />
                        <Col lg={3} sm={6}>
                            <RectangularCard isFullImage="true" backgoundClass="rectangle-card-bg-white">
                                {
                                    commonStore.config.client.image ? (
                                        <img
                                            src={`${config.UPLOADS_API_ENDPOINT}/${commonStore.config.client.image}`}
                                            className="img-fluid card-bg-img"
                                            alt={"client"}
                                        />
                                    ) : (
                                        <div className='d-flex align-items-center fw-bold card-bg-img justify-content-center'>{commonStore.config.client.name}</div>
                                    )
                                }
                            </RectangularCard>
                        </Col>
                        {dashboard.grandtotals && (
                            <Col lg={3} sm={6}>
                                <RectangularCard backgoundClass="rectangle-card-bg-DFF7E9">
                                    <Row>
                                        <Col xs={5} sm={5} md={5}>
                                            <RectangularCardIcon backgoundClass="rectangle-card-icon-bg-C2EDE9">
                                                <img src={overtimeHours} alt="overtimeHours" />
                                            </RectangularCardIcon>
                                        </Col>
                                        <Col xs={7} sm={7} md={7}>
                                            <span className='d-block text-header'>
                                                <span className='d-block text-header'>{t('Total Hours')}</span> {t('Month') + (isWeeklyKey? ' | ' + t('Week'):'')}
                                            </span>
                                            <span className='d-block text-body'>
                                                <small>
                                                    {`${totalTimeFormat(dashboard.grandtotals.total_normal || 0, dateTimeRules)}`}
                                                    { isWeeklyKey ? ` | ${totalTimeFormat(dashboard.userWeeklyTotalDetails.total_weekly_hours_in_mins || 0, dateTimeRules )}` :''}
                                                </small>hrs
                                            </span>
                                            <span className='d-block text-footer color-0A89FF'>
                                                {`${totalTimeFormat(
                                                    dashboard.grandtotals.total_overtime || 0,
                                                    dateTimeRules
                                                )} ${t('Overtime')}`}
                                            </span>
                                        </Col>
                                    </Row>
                                </RectangularCard>
                            </Col>
                        )}

                        {dashboard.grandtotals && (
                            <Col lg={3} sm={6}>
                                <RectangularCard backgoundClass="rectangle-card-bg-F6DFDF">
                                    <Row>
                                        <Col xs={5} sm={5} md={5}>
                                            <RectangularCardIcon backgoundClass="rectangle-card-icon-bg-FEB8B8">
                                                <img src={totalCostsSvg} alt="totalCosts" />
                                            </RectangularCardIcon>
                                        </Col>
                                        <Col xs={7} sm={7} md={7}>
                                            <span className='d-block text-header'>
                                                {t('Earning this month')}
                                            </span>
                                            <span className='d-block text-body'>
                                                {<small>{`${earned}`}</small>}
                                            </span>
                                            <span className='d-block text-footer color-FF8383'>
                                                {`${rate}`}
                                            </span>
                                        </Col>
                                    </Row>
                                </RectangularCard>
                            </Col>
                        )}
                    </Row>
                    {!!biztypeData.enable_deviations && !biztypeData.union_group && (
                        <Row key="deviations" className="deviation">
                            <DeviationDashboardWidget data={dashboard} />
                        </Row>
                    )}

                    <Row key="schedule">
                        <Col sm={12} lg={12} className="dashboard-schedule">
                            <div className="card-block">
                                <div className="content">
                                    <h4 className='dashboard-title mx-2'>{t('Schedule for next 2 weeks')}</h4>
                                    <ScheduleMember />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }

    handleSeeMore() {
        const { dashboardStore: { todayAtWork }, dashboardStore, commonStore, t } = this.props;
        let pageLength = Math.floor(todayAtWork.length / 6);
        dashboardStore.getDashboardSchedules({ page: pageLength, pagesize: 6 }).then(resp => {
            if (resp && resp.length < 6) {
                commonStore.addNotification(t('All schedules are fetched'), null, 'success');
                this.setState({ moreShifts: true });
            }
            if (resp && resp.length === 0) {
                commonStore.addNotification(t('All schedules are fetched'), null, 'success');
            }
        });
    }

    handleSeeLess() {
        const { dashboardStore } = this.props;
        dashboardStore.handleShowLess();
        this.setState({ moreShifts: false })
    }

    getShiftStatus(shift) {
        const { t } = this.props;
        if (shift.status === 'ok') {
            return (
                <td >
                    <span style={{ background: "#C3E7C3", color: "#008000", borderRadius: "13px", padding: "8px" }}>
                        {t(`shift_status_${shift.status}`)}
                    </span>
                </td>
            )
        }
        if (shift.status === 'no-records') {
            return (
                <td >
                    <span style={{ background: "rgba(239, 188, 188, 0.8)", color: "#FF0000", borderRadius: "10px", padding: "8px" }}>
                        {t(`shift_status_${shift.status}`)}
                    </span>
                </td>
            )
        }
        if (shift.status === 'working') {
            return (
                <td style={{ color: "blue" }}>
                    <span style={{ background: "#EFF1E4", color: "#2550AC", borderRadius: "13px", padding: "8px" }}>
                        {t(`shift_status_${shift.status}`)}
                    </span>
                </td>
            )
        }
    }

    renderScheduleData() {
        const { t, dashboardStore: { todayAtWork, tableLoading } } = this.props;
        const { moreShifts } = this.state;
        if (tableLoading) {
            return <LoadingSpinner />;
        }
        if (todayAtWork.length === 0) {
            return (
                <div className='fw-600 text-start ms-2' style={{ color: "#B2B7B2" }}>
                    {t('No schedule')} {t('Today')}
                </div>
            )
        }
        let disableBtn = Math.floor(todayAtWork.length / 6) ? false : true;
        return (
            <div className="horizontal-scroll_wrap">
                <div className="horizontal-schedule-scroll">

                    <div className='scrollable' style={{ overflowY: 'auto', maxHeight: '400px' }}>
                        <table className="table table-dashboard-schedule mt-2" >
                            <thead>
                                <tr>
                                    <th className='text-start'>{t('Name')}</th>
                                    <th>{t('Work Today')}</th>
                                    <th>{t('Schedule')}</th>
                                    <th>{t('status')}</th>
                                </tr>
                            </thead>
                            <tbody className='dashboard-schedule-table-body' >
                                {todayAtWork.length > 0 && todayAtWork.map((shift) => (
                                    <tr
                                        className="table-project-small__row"
                                        key={shift.assignee_id}
                                    >
                                        {/* <td className="text-center">{shift['assignee_id']}</td> */}
                                        <td className='text-start'>{shift.username}</td>
                                        <td>{minutesToTimeInput(shift.sum, false)}</td>
                                        <td>
                                            {minutesToTimeInput(shift.min_start_time, true)} -{' '}
                                            {minutesToTimeInput(shift.max_end_time, true)}
                                        </td>
                                        {/* <td>{t(`shift_status_${shift.status}`)}</td> */}
                                        {this.getShiftStatus(shift)}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {todayAtWork.length > 0 && todayAtWork.length >= 6 &&
                        <div className='d-flex justify-content-between'>
                            <Button className="mx-1 mx-md-0" disabled={(todayAtWork.length <= 6) ? true : false} onClick={this.handleSeeLess}>{t('See Less')}</Button>
                            <Button className="mx-1 mx-md-0" disabled={moreShifts || disableBtn} onClick={this.handleSeeMore}>{t('See More')}</Button>
                        </div>
                    }
                </div>
            </div>
        )
    }

    renderAdmin() {
        const { t, dashboardStore: { currentData, todayAtWork }, commonStore, userStore } = this.props;
        const usersCounters = currentData.usersCounters ? currentData.usersCounters[0] : {};
        if (!todayAtWork) return null;
        const totalNormal = currentData.overallWorkhours ? currentData.overallWorkhours[0].total_normal : {};
        const totalOvertimes = currentData.overallWorkhours ? currentData.overallWorkhours[0].total_overtime : {};
        let totalCosts = currentData.overallWorkhours ? currentData.overallWorkhours[0].total_costs : {};
        let totalCostsOvertime = currentData.overallWorkhours ? currentData.overallWorkhours[0].total_costs_overtime : {};
        let totalFixedCosts = currentData.overallWorkhours ? currentData.overallWorkhours[0].total_fixed_costs : {};
        const { data: biztypeData } = commonStore.config.biztype;
        const userType = userStore.currentUser.user_type;
        const dateTimeRules = commonStore.config.client && commonStore.config.client.data && commonStore.config.client.data.dateTimeRules ? 
                              commonStore.config.client.data.dateTimeRules : false;

        if (!totalCosts) {
            totalCosts = !totalFixedCosts ? 0 : parseInt(totalFixedCosts, 10);
        } else {
            totalCosts = parseInt(totalCosts, 10) + (!totalFixedCosts ? 0 : parseInt(totalFixedCosts, 10));
        }
        if (!totalCostsOvertime) {
            totalCostsOvertime = 0;
        } else {
            totalCostsOvertime = parseInt(totalCostsOvertime, 10);
        }
        if (!currentData.projects) {
            return null;
        }
        const userWorklogStatusData = {
            "title": "Members",
            "metaData": ["Working now", "Gone home", "Not at work", "Pending Timesheet"],
            "data": [Number(usersCounters.working_now), Number(usersCounters.gone_home), Number((usersCounters.all_users - usersCounters.gone_home) - usersCounters.working_now), Number(usersCounters.pending_timesheet)],
            "dataInPercentatge": [...getPercentageFromNumberValues([Number(usersCounters.working_now), Number(usersCounters.gone_home), Number((usersCounters.all_users - usersCounters.gone_home) - usersCounters.working_now)]), getPercentage(Number(usersCounters.all_timesheet), Number(usersCounters.pending_timesheet))],
            "dataColors": ["51459E", "7a7b83", "EE2222", "ffa534"],
            "backgroundColor": ["E1E1E1", "ffa534", "F3DDDD", "ffa534"]
        }

        const greetings = "Time & Control";

        return (
            <>
                <Container className='dashboard-container ps-0' fluid >
                    <Row>
                        <span className='dashboard-title mx-2'>{t('Dashboard')}</span>
                        <p className='dashboard-greeting mx-2'>{t('Hi')} {userStore.currentUser.first_name}! {t('Welcome')} {t('Back')} {t('To')} {greetings}</p>
                    </Row>
                    <Row className='mx-4 mx-md-0'>
                        {userType === 'pm' && <TimelogDashboardWidget />}
                        <Col lg={3} sm={{ span: 6, offset: 0 }} xs={{ span: 10, offset: 1 }} className="d-flex justify-content-center d-md-block" >
                            <RectangularCard isFullImage="true" backgoundClass="rectangle-card-bg-white">
                                {
                                    commonStore.config.client.image ? (
                                        <img
                                            src={`${config.UPLOADS_API_ENDPOINT}/${commonStore.config.client.image}`}
                                            className="img-fluid card-bg-img"
                                            alt='client'
                                        />
                                    ) : (
                                        <div className='d-flex align-items-center fw-bold card-bg-img justify-content-center'>{commonStore.config.client.name}</div>
                                    )
                                }
                            </RectangularCard>
                        </Col>
                        <Col lg={3} sm={{ span: 6, offset: 0 }} xs={{ span: 10, offset: 1 }} className="d-flex justify-content-center d-md-block" >
                            <RectangularCard backgoundClass="rectangle-card-bg-FFF6E6">
                                <Row>
                                    <Col xs={5} sm={5} md={5}>
                                        <RectangularCardIcon backgoundClass="rectangle-card-icon-bg-FFE7BD">
                                            <img src={totalHours} alt="totalHours" />
                                        </RectangularCardIcon>
                                    </Col>
                                    <Col xs={7} sm={7} md={7}>
                                        <span className='d-block text-header'>
                                            {t('Total Hours')}
                                        </span>
                                        <span className='d-block text-body'>
                                            {totalTimeFormat(totalNormal || 0, dateTimeRules)}hrs
                                        </span>
                                        <span className='d-block text-footer color-FEAA15'>
                                            {t('This month')}
                                        </span>
                                    </Col>
                                </Row>
                            </RectangularCard>
                        </Col>
                        <Col lg={3} sm={{ span: 6, offset: 0 }} xs={{ span: 10, offset: 1 }} className="d-flex justify-content-center d-md-block">
                            <RectangularCard backgoundClass="rectangle-card-bg-DFF7E9">
                                <Row>
                                    <Col xs={5} sm={5} md={5}>
                                        <RectangularCardIcon backgoundClass="rectangle-card-icon-bg-C2EDE9">
                                            <img src={overtimeHours} alt="overtimeHours" />
                                        </RectangularCardIcon>
                                    </Col>
                                    <Col xs={7} sm={7} md={7}>
                                        <span className='d-block text-header'>
                                            {t('Overtime')}
                                        </span>
                                        <span className='d-block text-body'>
                                            {totalTimeFormat(totalOvertimes || 0, dateTimeRules)}hrs
                                        </span>
                                        <span className='d-block text-footer color-0A89FF'>
                                            {t('This month')}
                                        </span>
                                    </Col>
                                </Row>
                            </RectangularCard>

                        </Col>
                        {userType !== 'pm' && (
                            <Col lg={3} sm={{ span: 6, offset: 0 }} xs={{ span: 10, offset: 1 }} className="d-flex justify-content-center d-md-block">
                                <RectangularCard backgoundClass="rectangle-card-bg-F6DFDF">
                                    <Row>
                                        <Col xs={5} sm={5} md={5}>
                                            <RectangularCardIcon backgoundClass="rectangle-card-icon-bg-FEB8B8">
                                                <img src={totalCostsSvg} alt="totalCosts" />
                                            </RectangularCardIcon>
                                        </Col>
                                        <Col xs={7} sm={7} md={7}>
                                            <span className='d-block text-header'>
                                                {t('Total Costs')}
                                            </span>
                                            <span className='d-block  text-body'>
                                                {get_currency(dateTimeRules) === '$' ?
                                                    `${get_currency(dateTimeRules)} ${totalCosts + totalCostsOvertime}`
                                                    : `${totalCosts + totalCostsOvertime} ${get_currency(dateTimeRules)}`}
                                            </span>
                                            <span className='d-block text-footer color-FF8383'>
                                                {`${t('Overtime')} ${get_currency(dateTimeRules) === '$' ?
                                                    `${get_currency(dateTimeRules)} ${totalCostsOvertime}`
                                                    : `${totalCostsOvertime} ${get_currency(dateTimeRules)}`}`}
                                            </span>
                                        </Col>
                                    </Row>
                                </RectangularCard>
                            </Col>
                        )}
                    </Row>
                    <Row>
                        <Col md={12} >
                            <div className="card-header-with-button">
                                <h4 className="card-title mx-2">
                                    <NavLink to="/admin/members" className="dashboard-nav-link">{t('Members')}</NavLink>
                                </h4>
                            </div>
                            <div className="d-flex mt-3 flex-column align-items-center d-md-block">
                                {this.renderStatusCards(userWorklogStatusData, false, true)}
                            </div>
                        </Col>

                    </Row>
                    <Row>
                        <Col xs={12} sm={12}>
                            <div className="">
                                <div className="card-header-with-button">
                                    <h4 className="card-title mx-2">
                                        <NavLink to="/admin/schedule" className="dashboard-nav-link">{t('Users Schedules')}</NavLink>
                                    </h4>
                                </div>
                                <div>
                                    {
                                        this.renderScheduleData()
                                    }
                                </div>
                            </div>
                        </Col>
                    </Row>
                    {!!biztypeData.enable_deviations && !biztypeData.union_group && (
                        <Row key="Deviation1" className="deviation">
                            <DeviationDashboardWidget data={currentData} />
                        </Row>
                    )}
                </Container>
            </>
        );
    }

    componentWillUnmount() {
        this.props.dashboardStore.resetLoader();
    }

    render() {
        const { dashboardStore, userStore } = this.props;
        const { loading } = dashboardStore;
        if (loading) {
            return <LoadingSpinner />;
        }
        const userType = userStore.currentUser.user_type;
        if (userType === 'super-admin') {
            return this.renderSuperAdmin();
        }
        if (userType === 'pm' || userType === 'admin') {
            return this.renderAdmin();
        }
        return this.renderMember();
    }
}
export default inject('dashboardStore', 'userStore', 'commonStore')(withLocalization(applicationRouter(observer(Dashboard))));
