import React from "react";
import { Link} from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

import Header from "../../../newComponents/Header";
import Footer from "../../../newComponents/Footer";
import MainHeading from "../../../newComponents/MainHeading";
import PageBanner from "../../../newComponents/PageBanner";
import CoreBoxNew from "../../../newComponents/CoreBoxNew";
import Testimonial from "../../../newComponents/Testimonial";
import Contact from "../../../newComponents/Contact";

import BannerImg from "../../../assets/images/communicationBnrImg.png";
import AppleIcon from "../../../assets/images/appleIcon.svg";
import GoogleIcon from "../../../assets/images/googleIcon.svg";
import ValuableLogo from "../../../assets/images/valuableLogo.png";
import Icon1 from "../../../assets/images/commFea1.png";
import Icon2 from "../../../assets/images/commFea2.png";
import Icon3 from "../../../assets/images/commFea3.png";
import Icon4 from "../../../assets/images/commFea4.png";
import ProffesionalImg from "../../../assets/images/proffesionalImg.png";
import EmpowerEmployeeImg from "../../../assets/images/empowerEmployeeImg.png";
import ProfessionalManImg from "../../../assets/images/professionalManImg.png";

//i18n
import { withTranslation } from "react-i18next"
import { observer ,inject} from "mobx-react";

import config from "../../../library/config";

const Communication = inject('authStore', 'commonStore')(observer((props) => {

  return (
    <>
      <div className="landingPageWrapp frameworkApp">
        <Header features="current" />
        <article className="pageBannerBlock grdntBg noPatt py-60">
          <Container>
            <Row className="g-4 align-items-center">
              <Col lg={5}>
                <MainHeading
                  title={<>{props.t("Features")} <span>{props.t("Communication")}</span></>}
                  description={props.t("Teamwork bears fruit when the team communicates seamlessly. The Time and Control communication feature brings your team together, allowing them to converse across departments, offices, and even time zones. Communicate flexibly with everyone in the company and even outside. With our tool, you can send media, messages, and files over an encrypted connection.")}
                />
                <div className="appBtns mt-4">
                  <Link onClick={e => config.redirectToAppStore(e, "apple")} className="btnCommon btnBig w-auto me-3 mb-2"><i className="d-inline-block pe-2"><img src={AppleIcon}  alt="Apple" /></i> {props.t("Apple")}</Link>
                  <Link onClick={e => config.redirectToAppStore(e, "google")} className="btnCommon btnTrans btnBig btnBlackTrans w-auto"><i className="d-inline-block pe-2"><img src={GoogleIcon} alt="Apple" /></i> {props.t("Google")}</Link>
                </div>
              </Col>
              <Col lg={1}></Col>
              <Col lg={6}>
                <PageBanner
                  src={BannerImg}
                  alt="Visitor Register"
                />
              </Col>
            </Row>
          </Container>
          <div className="valuableLogo">
            <img src={ValuableLogo} alt="" />
          </div>
        </article>
        <article className="coreBusnsBlock  py-60">
          <Container>
            <div className="contentInfo text-center">
              <h5 className="m-0">{props.t("Communication")}</h5>
            </div>
            <MainHeading
              title={<>{props.t("Secure & Reliable")}<br/><span>{props.t("Communication Channel")}</span></>}
              customClass="medium noPara text-center"
            />
            <Row className="g-4 g-xxl-5">
              <Col md={6} lg={6}>
                <CoreBoxNew
                  src={Icon1}
                  title={props.t("Fits your Needs")}
                  description={props.t("Time and Control lets you take charge of your communication and create a virtual network of employees.")}
                  count="1"
                  color="blue"
                  customClass="style2"
                />
              </Col>
              <Col md={6} lg={6}>
                <CoreBoxNew
                  src={Icon2}
                  title={props.t("Multiple Devices")}
                  description={props.t("You can connect and communicate via multiple devices with Time and Control and keep all your communication in one place.")}
                  count="2"
                  color="orange"
                />
              </Col>
              <Col md={6} lg={6}>
                <CoreBoxNew
                  src={Icon3}
                  title={props.t("Intuitive Interface")}
                  description={props.t("Discover new ways to communicate with your teammates on our intuitive interface. Find the functions you need easily.")}
                  count="3"
                  color="green"
                  customClass="style2"
                />
              </Col>
              <Col md={6} lg={6}>
                <CoreBoxNew
                  src={Icon4}
                  title={props.t("Improved Communication")}
                  description={props.t("Use creative control systems to enhance your communication channels, methods, and means for greater control.")}
                  count="4"
                  color="pink"
                  customClass="style2"
                />
              </Col>
            </Row>
          </Container>
        </article>
        <article className="abtInfoBlock grdntBg emailPatt  py-60 pb-0">
          <div className="rightImgFull">
            <img src={ProffesionalImg} alt="" />
          </div>
          <Container>
            <Row className="g-4 g-xl-5 align-items-center">
              <Col>
                <div className="my-5 py-5 ps-4">
                  <MainHeading
                    title={<>{props.t("Improve the Work Quality")} <span>{props.t("and Productivity")}</span></>}
                    customClass="medium noPara"
                  />
                  <div className="text-left">
                    <p>{props.t("Time & Control give everyone in the company a single source to share files, information, and communication with each other.")} </p>
                    <p>{props.t("Everyone on the team can communicate from a single interface, stay connected, and make decisions quickly to deliver quality work to the clients with the best approaches.")}</p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </article>
        <article className="abtInfoBlock headphonePatt py-60 pb-0">
          <Container>
            <Row className="g-4 g-xl-5 align-items-center">
              <Col lg={6} xl={6}>
                <div className="text-center">
                  <img src={EmpowerEmployeeImg} alt="" />
                </div>
              </Col>
              <Col lg={6} xl={6}>
                <div className="my-5 py-5 ps-4">
                  <MainHeading
                    title={<>{props.t("Collaborate, Communicate, and Schedule with a Dedicated Work")} <span>{props.t("Communication App and Platform")}</span></>}
                    customClass="medium noPara m-0"
                  />
                  <div className="contentInfo">
                    <h5 className="my-4">{props.t("Improve the way you achieve your goals")}</h5>
                  </div>
                  <div className="text-left">
                    <p>{props.t("Our meaningful and effective communication method in industries contributes to work efficiency, decision-making, the proficiency to deliver on-time projects and to take an organisation to heights by achieving its objectives.")}</p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </article>
        <article className="abtInfoBlock grdntBg noPatt mt-5 py-60 pb-0">
          <div className="rightImgFull">
            <img src={ProfessionalManImg} alt="" />
          </div>
          <Container>
            <Row className="g-4 g-xl-5 align-items-center">
              <Col>
                <div className="my-5 py-5 ps-4">
                  <div className="contentInfo mb-3">
                    <h5>{props.t("Communication")}</h5>
                  </div>
                  <MainHeading
                    title={<>{props.t("Bring Everyone")} <span>{props.t("Together")}</span></>}
                    customClass="medium noPara"
                  />
                  <div className="list">
                    <ul>
                      <li>{props.t("Create channels and groups")}</li>
                      <li>{props.t("Send personal messages")}</li>
                      <li>{props.t("Use interactive communication components")}</li>
                      <li>{props.t("Archive messages and files")}</li>
                      <li>{props.t("Track employees performance")}</li>
                      <li>{props.t("Faster decision making")}</li>
                      <li>{props.t("Enhance teamwork and productivity")}</li>
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </article>
        <article className=" py-60">
          <Testimonial/>
        </article>
        <Contact/>
        <Footer/>
      </div>
    </>
  );
}))
export default withTranslation()(Communication);