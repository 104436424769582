import { useEffect, useState } from 'react';
import { inject } from 'mobx-react';
import { Table, Row, Col } from 'react-bootstrap';
import { v4 as uuid } from 'uuid';

//components
import withLocalization from '~/hoc/withLocalization';
import withRemoveDialog from '~/hoc/withRemoveDialog';

//elements
import CustomMultiSelect from '../../../elements/CustomMultiSelect';
import Button from '../../../components/CustomButton/CustomButton.jsx';
import RequiredStar from '../../../elements/RequiredStar.js';
import LoadingSpinner from '../../../elements/LoadingSpinner.js';

//assets
import Edit from '../../../assets/img/editing.svg';
import AddressBookIcon from '../../../assets/img/AddressBookIcon.svg';
import Delete from '../../../assets/img/deleting.svg';

//styles
import './styles.css';


const renderAssigneeInGroup = (userList, t) => {
    if (!userList || !userList.assigned_users || !userList.assigned_users.length) return (
        <span className='font-size-12 fw-bold'>{t("No employee assigned, please add employees to group")}</span>
    )
    return (
        <div className='group-management-assignee_list'>
            {userList.assigned_users.map(ele => {
                return <span key={uuid()}>{ele.label}</span>
            })}
        </div>
    )
}

const checkIfGroupInUse = (group) => {
    if (!group || ((!group.assigned_users || !group.assigned_users.length) && !group.use_count)) return false;
    return true;
}

const ManageGroupsWidget = (props) => {
    const { t } = props;
    const currentUser = props.userStore.currentUser;
    const [userGroupByUserAndUsageDetails, setUserGroupByUserAndUsageDetails] = useState({});
    const [userOptions, setUserOptions] = useState([]);
    const [editing, setEditing] = useState(0);
    const [isSubmitBtnClicked, setSubmitBtnStatus] = useState(false);
    const [loading, setLoadingStatus] = useState(false);
    const [currentEditing, setCurrentEditing] = useState({ code: "", name: '', assigned_users: [], allow_code_update: true, allow_name_update: true });

    useEffect(() => {
        setLoadingStatus(true);
        loadData();
    }, []);

    const loadData = async (resetForm = false) => {
        const data = await props.userGroupStore.getGroupByUserAndUsageDetails();
        const users = await props.userStore.loadLookup('members', '');
        const parsedList = users.map(ele => { return { value: ele.id, label: ele.fullname } })
        setUserGroupByUserAndUsageDetails(data);
        setUserOptions(parsedList);
        setLoadingStatus(false);
        if (resetForm) {
            setSubmitBtnStatus(false);
            setEditing(0);
            setCurrentEditing({ code: "", name: '', assigned_users: [], allow_code_update: true, allow_name_update: true });
        }
    }

    const handleChange = (name, value) => {
        if (name === "code") {
            const pattern = /^[0-9]+$/;
            if (!pattern.test(value)) return;
        }
        setCurrentEditing({
            ...currentEditing,
            [name]: value
        })
    }

    const handleSubmit = () => {
        if (!currentEditing.code) {
            return props.commonStore.addNotification(t('Please enter code'), null, 'warning');
        }

        if (!currentEditing.name || !currentEditing.name.trim()) {
            return props.commonStore.addNotification(t('Please enter name'), null, 'warning');
        }
        setSubmitBtnStatus(true);
        addUpdatedGroupDetailsAndAssignees();
    }

    const addUpdatedGroupDetailsAndAssignees = async () => {
        await props.userGroupStore.addOrUpdateUserGroupAndAssignees({
            code: currentEditing.code,
            name: currentEditing.name,
            users: currentEditing.assigned_users
        }, editing === 0).then(res => {
            props.commonStore.addNotification(t(res.message), null, 'success');
            loadData(true);
        }).catch(err => {
            props.commonStore.addNotification(err.message, null, 'error');
            setSubmitBtnStatus(false);
        });
    }

    const removeGroup = async (code) => {
        await props.userGroupStore.removeUserGroup(code).then(res => {
            props.commonStore.addNotification(t(res.message), null, 'success');
            loadData(true);
        }).catch(err => {
            props.commonStore.addNotification(err.message, null, 'error');
            setLoadingStatus(false);
        });
    }

    const handleGroupActions = (type, data, index) => {
        switch (type) {
            case 'assign':
                setEditing(index);
                setCurrentEditing({
                    code: data.code,
                    name: data.name,
                    assigned_users: data.assigned_users.map(ele => ele.value),
                    allow_code_update: !checkIfGroupInUse(data),
                    allow_name_update: false,
                });
                break;
            case "edit":
                setEditing(index);
                setCurrentEditing({
                    code: data.code,
                    name: data.name,
                    assigned_users: data.assigned_users.map(ele => ele.value),
                    allow_code_update: !checkIfGroupInUse(data),
                    allow_name_update: true,
                });
                break;
            case "delete":
                setLoadingStatus(true);
                removeGroup(data.code);
                break;
            default:
                console.info("Default case");
        }
    }

    return (
        <Row>
            <Row xs={12} className='group-management-header'>
                <Col xs={6} >
                    <input type={"number"} name={"Code"} placeholder={t('Enter Code')} required
                        title={"Enter Unique Numeric Codes"}
                        value={currentEditing.code}
                        disabled={!currentEditing.allow_code_update}
                        onChange={evt => handleChange("code", evt.target.value)}
                    />
                    <RequiredStar />
                    <input type={"text"} name={"Name"} placeholder={t('Enter Name')} required
                        value={currentEditing.name}
                        disabled={!currentEditing.allow_name_update}
                        onChange={evt => handleChange("name", evt.target.value)}
                    />
                    <RequiredStar />
                </Col>
                <Col xs={4} >
                    <CustomMultiSelect
                        options={userOptions}
                        onChange={selected => handleChange("assigned_users", selected)}
                        className={'w-100 custom-multi-select-max-height-200'}
                        value={currentEditing.assigned_users}
                        overrideStrings={{
                            selectSomeItems: t('Assign Employees'),
                            allItemsAreSelected: t('All Employees are selected'),
                            search: t('Search'),
                            selectAll: t('Select All'),
                        }}
                    />
                </Col>
                <Col xs={2}>
                    <Button fill wd onClick={handleSubmit} disabled={isSubmitBtnClicked}>
                        {!currentEditing.code ?
                            <>
                                <i className="fa fa-plus-circle fa-xl margin-right-10" /> {t('Add new')}
                            </>
                            :
                            <>
                                <i className="fa fa-save fa-xl margin-right-10" /> {t('Save')}
                            </>
                        }
                    </Button>
                </Col>
            </Row>
            < Table className='w-100' responsive hover>
                <thead>
                    <tr className='w-100 px-4 my-2'>
                        <th width={'5%'}>{t('#')}</th>
                        <th width={'20%'}>{t('Code')} | {t('Name')}</th>
                        <th width={'40%'} className='text-start'>{t('Assigned To')}</th>
                        <th width={'10%'} className='text-start'>{t('Shift Usage')}</th>
                        <th width={'25%'}>{t('Actions')}</th>
                    </tr>
                </thead>
                <tbody>
                    {loading ?
                        <tr>
                            <td colSpan={5}>
                                <LoadingSpinner />
                            </td>
                        </tr> :
                        Object.values(userGroupByUserAndUsageDetails).length ? Object.values(userGroupByUserAndUsageDetails).map((ele, idx) => (
                            <tr key={uuid()}
                                className='text-capitalize mt-1 px-4'
                                style={!editing ? {} : editing === (idx + 1) ? { border: '2px solid blue' } : { opacity: '0.6' }}>
                                <td width={'5%'} className='timelog_array_details'>{idx + 1}</td>
                                <td width={'15%'} className='timelog_array_details'>{ele.code} | {ele.name}</td>
                                <td width={'45%'} className='timelog_array_details text-start'>{renderAssigneeInGroup(ele, t)}</td>
                                <td width={'10%'} className='timelog_array_details text-center'>{ele.use_count}</td>
                                <td width={'25%'} className='text-center'>
                                    <Button icon_sm fill me2
                                        title={t('Assign Members')}
                                        onClick={() => handleGroupActions('assign', ele, idx + 1)}
                                        disabled={!!editing}>
                                        <img src={AddressBookIcon} alt={'addressbook'} />
                                    </Button>

                                    <Button icon_sm fill me2
                                        title={t('Update Details')}
                                        onClick={() => handleGroupActions('edit', ele, idx + 1)}
                                        disabled={!!editing}>
                                        <img src={Edit} alt={'edit'} />
                                    </Button>

                                    <Button icon_sm_delete fill
                                        onClick={() => handleGroupActions('delete', ele)}
                                        disabled={currentUser.user_type !== 'admin' || checkIfGroupInUse(ele) || !!editing}
                                        title={currentUser.user_type !== 'admin' ? t('Only Admin can remove groups') : checkIfGroupInUse(ele) ? t('Group in use, can not be deleted') : t('Delete')}
                                    >
                                        <img src={Delete} alt="delete" />
                                    </Button>
                                </td>
                            </tr>
                        )) :
                            <tr>
                                <td colSpan={5} className='pt-4'>
                                    <h5>
                                        <b>{t("No groups found. Please add user groups and assign members")}.</b>
                                    </h5>
                                    <span>
                                        <b>{t("Note")}</b>:&nbsp;{t("Groups helps you organise and manage teams easily")}.
                                    </span>
                                </td>
                            </tr>
                    }
                </tbody>
            </Table >
        </Row>

    )
}

export default inject('commonStore', 'userGroupStore', 'userStore')(withLocalization(withRemoveDialog(ManageGroupsWidget)));

