import moment from 'moment/min/moment-with-locales';
import { minutesToTimeInputV2 } from './timeCalc'
export const shortDateFormat = (date, dateTimeRules) => {
    let shortDateFormat = "MM-DD-YYYY";
    if (dateTimeRules && dateTimeRules.short_date_format) shortDateFormat = dateTimeRules.short_date_format
    return moment(date).format(shortDateFormat)
};

export const longDateFormat = (dateTimeRules) => {
    let longDateFormat = "EEEE MMMM dd, yyyy"
    if (dateTimeRules && dateTimeRules.long_date_format) longDateFormat = dateTimeRules.long_date_format
    return longDateFormat
};

export const timeFormat = (input,dateTimeRules) => {
    let timeFormat = "hh:mm";
    if(dateTimeRules && dateTimeRules.time_format) timeFormat = dateTimeRules.time_format;
    return minutesToTimeInputV2(input, timeFormat)
};

export const dateToTimeFormat = (date, dateTimeRules) => {
    let timeFormat = "hh:mm";
    if (dateTimeRules && dateTimeRules.time_format) timeFormat = dateTimeRules.time_format
    return moment(date).format(timeFormat)
};

export const totalTimeFormat = (allMinutes, dateTimeRules) => {
    let timeRules = 'decimal'
    if(dateTimeRules && dateTimeRules.total_time_format) timeRules = dateTimeRules.total_time_format;
    const decimalInsteadOfMinutes = timeRules === 'decimal' ? true : false
    if (allMinutes && allMinutes.toString().indexOf(':') >= 0) return allMinutes;
    const hours = Math.floor(+Math.abs(allMinutes) / 60);
    const minutes = Math.floor(+Math.abs(allMinutes) % 60);
    if (decimalInsteadOfMinutes) {
        const minutesDecimal = parseInt((minutes * 100) / 60);
        return `${hours}.${minutesDecimal < 10 ? '0' : ''}${minutesDecimal}`;
    }
    return hours > 0 || minutes > 0
        ? `${hours < 10 && hours >= 0 ? `0${hours}` : hours}:${minutes < 10 && minutes >= 0 ? `0${minutes}` : minutes
        }`
        : '00:00';
};

export const get_currency = (dateTimeRules) => {
    let currency_type = "nok";
    if (dateTimeRules && dateTimeRules.currency) currency_type = dateTimeRules.currency;
    return currency_type
};

export const distanceUnitType = (dateTimeRules) => {
    let distanceUnit = "km";
    if (dateTimeRules && dateTimeRules.distance_unit) distanceUnit = dateTimeRules.distance_unit;
    return distanceUnit
}

export const currencyDecimalSize = (dateTimeRules) => {
    let currencyDecimal = 2;
    if(dateTimeRules && dateTimeRules.currency_decimal_places) currencyDecimal = Number(dateTimeRules.currency_decimal_places);
    return currencyDecimal
}

export const getShortDateFormat = (dateTimeRules) => {
    let shortDateFormat = "MM-DD-YYYY";
    if (dateTimeRules && dateTimeRules.short_date_format) shortDateFormat = dateTimeRules.short_date_format
    return shortDateFormat;
};

export const getTimeFormat = (dateTimeRules, isMoment = false) => {
    let timeFormat = "hh:mm";
    if(dateTimeRules && dateTimeRules.time_format) timeFormat = dateTimeRules.time_format;
    if(isMoment) return timeFormat === "hh:mm" ? "HH:mm" : "hh:mm A";
    return timeFormat;
};
export const getCountryCode = (dateTimeRules) => {
    let countryCode = "+47";
    if (dateTimeRules && dateTimeRules.country_code) countryCode = dateTimeRules.country_code;
    return countryCode;
}

export const getAddressFormat = (dateTimeRules) => {
    let address_format = 'address,pincode,city';
    if (dateTimeRules && dateTimeRules.address_format) address_format = dateTimeRules.address_format;
    return address_format
}
export const weekDayIndex = {
    "0": 1,
    "1": 2,
    "2": 3,
    "3": 4,
    "4": 5,
    "5": 6,
    "6": 0
}
