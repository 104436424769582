import React, { Component } from 'react';
import applicationRouter from '~/hoc/applicationRouter'
import { inject, observer } from 'mobx-react';
import _ from 'lodash';

import customerFeature from '../../../utils/customerFeature';

import DeviationList from './DeviationList';

import withRemoveDialog from '~/hoc/withRemoveDialog';
import withLocalization from '~/hoc/withLocalization';

class DeviationPage extends Component {
    onAdd = () => {
        this.props.router.navigate('/admin/deviation/add');
    };

    onDeleteListItem = id => {
        const { commonStore, t } = this.props;
        return this.props.handleDelete(id, id => {
            return this.props.deviationStore.remove(id).then(res => {
                commonStore.addNotification(t('Deviation') + ' ' + t('Deleted'), null, 'error');
                this.props.deviationStore.resetLastListLoadTime();
                return res;
            });
        });
    };

    onDelete = id => {
        return this.props.handleDelete(id, id => {
            return this.props.deviationStore.remove(id).then(res => {
                this.props.router.navigate('/admin/deviation'); 
                return res;
            });
        });
    };

    onDetails(id) {
        this.props.router.navigate(`/admin/deviation/${id}`,{state:{deviation:id}});
    }

    render() {
        const config = _.get(this.props.commonStore, 'config.deviationConfig', {});
        // const mode = this.editingId();
        const isUnionMode = customerFeature('union_group');
        return (
            // <div className={`main-content ${mode.editing && !mode.add ? 'main-content_fixed-height' : ''}`}>
            <>
                {/* {!mode.editing && ( */}
                    <DeviationList
                        config={config}
                        isUnionMode={isUnionMode}
                        onDetails={id => this.onDetails(id)}
                        onAdd={id => this.onAdd(id)}
                        onDelete={id => this.onDeleteListItem(id)}
                    />
                {/* // )} */}
                {/* {mode.editing && (
                    <DeviationForm
                        isUnionMode={isUnionMode}
                        onDelete={this.onDelete}
                        config={config}
                        id={mode.id}
                        add={mode.add}
                    />
                )} */}
            </>
        );
    }
}
export default inject('authStore', 'deviationStore', 'userStore', 'commonStore')(applicationRouter(withLocalization(withRemoveDialog(observer(DeviationPage)))));
