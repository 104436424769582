import { observable, action, makeObservable } from 'mobx';
import * as Sentry from '@sentry/browser';

import agent from '../agent';

import userStore from './userStore';
import timelogStore from './timelogStore';

const TIMEOUT = 15; // * 100;

class LockedStore {
    constructor() {
        makeObservable(this, {
            modes: observable,
            isLoading: observable,
            emulatingUser: observable,
            timer: observable,
            timeout: observable,
            currentMode: observable,
            totalSpentToday: observable,
            totalBreakToday: observable,
            currentList: observable,
            currentProject: observable,
            currentLoginData: observable,
            currentTimelog: observable,
            switchMode: action,
            startLockedMode: action,
            stopLockedMode: action,
            loginWithPin: action,
            userAction: action,
            startTimer: action,
            stopTimer: action,
            loadStatusList: action,
            sendReportByMail: action,
            sendSms: action
        });
    }

    modes = {
        LOCKED_MENU: 'locked_menu',
        UNLOCKED: 'unlocked',
        EXITING: 'exiting',
        STATUS: 'status',
        USERPAGE: 'userpage',
    };

    isLoading = false;

    emulatingUser = null;

    timer = null;

    timeout = TIMEOUT;

    currentMode = this.modes.LOCKED_MENU;

    totalSpentToday = 0;

    totalBreakToday = 0;

    currentList = null;

    currentProject = null;

    currentLoginData = null;

    currentTimelog = null;

    switchMode(mode) {
        this.currentMode = mode;
    }

    startLockedMode(password, project) {
        return agent.Locked.startLock({ password, project }).then(() => {
            userStore.pullUser();
            this.currentProject = { value: project.value, label: project.label };
            this.currentMode = this.modes.LOCKED_MENU;
        });
    }

    stopLockedMode(password) {
        return agent.Locked.endLock({ password }).then(() => {
            userStore.pullUser();
            this.currentMode = this.modes.UNLOCKED;
        });
    }

    async loginWithPin(data) {
        const user = await agent.Locked.loginWithPin(data);
        this.emulatingUser = user.user;
        this.totalSpentToday = user.totals.totalHours;
        this.totalBreakToday = user.totals.totalBreak;
        this.currentTimelog = null;
        // this.recalcTimers();
        const currentDraft = await timelogStore.getCurrentDraftForLocked(data.project.value, user.user.id);
        if (currentDraft.timelog) {
            this.currentTimelog = currentDraft.timelog;
        }
        this.currentLoginData = currentDraft;
        this.startTimer();
        this.currentMode = this.modes.USERPAGE;
        return currentDraft;
    }

    async userAction(data) {
        // start-over, start, start-break, stop-break, done, close-previous
        const { action, project } = data;

        if (action === 'start' || action === 'start-over') {
            this.currentTimelog = await timelogStore.getPossibleDraftId({
                project_id: project.value,
                user_id: this.emulatingUser.id,
                source: 'stamped',
            });
        }
        if (action === 'start-break') {
            this.currentTimelog = await timelogStore.breakByButton(data.currentTimelog.id, 'start');
        }
        if (action === 'stop-break') {
            this.currentTimelog = await timelogStore.breakByButton(data.currentTimelog.id, 'stop');
            this.totalBreakNow = this.currentTimelog.break;
        }
        if (action === 'done') {
            this.currentTimelog = await timelogStore.finishByButton(data.currentTimelog, { stamped: true });
            this.totalBreakNow = this.currentTimelog.break;
        }
        if (action === 'close-previous') {
            await timelogStore.finishByButton(this.currentLoginData.previous, { stamped: true });
            this.currentTimelog = await timelogStore.getPossibleDraftId({
                project_id: project.value,
                user_id: this.emulatingUser.id,
                source: 'stamped',
            });
        }

        return;
    }

    startTimer() {
        try {
            if (this.timer) {
                try {
                    clearInterval(this.timer);
                    this.timer = null;
                } catch (e) {
                    Sentry.captureException(e);
                }
            }
            this.timeout = TIMEOUT;
            this.timer = setInterval(() => {
                try {
                    this.timeout = this.timeout - 1;
                    // this.recalcTimers();
                    if (this.timeout < 0) {
                        // 20 sec
                        clearInterval(this.timer);
                        this.timer = null;
                        if (this.currentMode !== this.modes.EXITING && this.currentMode !== this.modes.UNLOCKED) {
                            this.currentMode = this.modes.LOCKED_MENU;
                        }
                        this.emulatingUser = null;
                    }
                } catch (e2) {
                    Sentry.captureException(e2);
                }
            }, 1000);
        } catch (e1) {
            Sentry.captureException(e1);
        }
    }

    stopTimer() {
        if (this.timer) {
            clearInterval(this.timer);
            this.timer = null;
        }
        this.timeout = TIMEOUT;
    }

    loadStatusList(params) {
        return agent.Locked.loadList(params)
            .then(
                action(list => {
                    this.currentList = list;
                    return list;
                })
            )
            .catch(
                action(err => {
                    throw err;
                })
            );
    }

    sendReportByMail(params) {
        return agent.Locked.sendReportByMail(params)
            .then(
                action(list => {
                    return list;
                })
            )
            .catch(
                action(err => {
                    throw err;
                })
            );
    }

    sendSms(params) {
        return agent.Locked.sendSms(params)
            .then(
                action(list => {
                    return list;
                })
            )
            .catch(
                action(err => {
                    throw err;
                })
            );
    }
}

const _LockedStore = new LockedStore();
export default _LockedStore
