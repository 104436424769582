import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { confirmAlert } from 'react-confirm-alert';
import { Col, Tooltip, OverlayTrigger } from 'react-bootstrap';

//components
import applicationRouter from '~/hoc/applicationRouter'
import withLocalization from '../../../hoc/withLocalization';
import Button from '../../../components/CustomButton/CustomButton.jsx';
import FlexCard from '~/components/Card/FlexCard.jsx';

//utils
import { minutesToTimeInput } from '../../../utils/timeCalc';
import { shortDateFormat } from '../../../utils/dateFormat';

class TimelogDashboardWidget extends Component {
    constructor(props) {
        super(props);
        this.state = {
            status: null,
            emergencyShiftDeatils: {},
            employeeIsAssignedForEmergencyShift: false
        };
    }

    componentDidMount() {
        this.loadData();
    }

    async loadData() {
        const { timelogStore } = this.props;
        const status = await timelogStore.getCurrentDraftOrSkip('', '', true);
        this.setState({ status, emergencyShiftDeatils: status.emergencyShiftDeatils, employeeIsAssignedForEmergencyShift: status.employeeIsAssignedForEmergencyShift });
        return null;
    }

    async handleStop() {
        const { timelogStore, commonStore, t } = this.props;
        const timelog = await timelogStore.finishByButton(this.state.status.timelog, {});
        if (timelog.hasError) {
            const { errorMessage } = timelog;
            confirmAlert({
                title: t('Timesheet hours exceeded!'),
                message: errorMessage,
                buttons: [
                    {
                        label: t('Auto Adjust'),
                        onClick: async () => {
                            return timelogStore.finishByButton(this.state.status.timelog, { autoAdjust: true }).then(response => {
                                commonStore.addNotification(t('Your timelog is now ready to save'), null, 'success');
                                this.props.router.navigate(`/admin/timelogs/${response.id}`, { state: { timelogs: response.id } });
                            })
                        },
                    },
                    {
                        label: t('Cancel'),
                        onClick: () => {
                            this.props.router.navigate(`/admin/timelogs/${this.state.status.timelog.id}`, { state: { timelogs: this.state.status.timelog.id } });
                        },
                    }
                ],
            });
        } else {
            commonStore.addNotification(t('Your timelog is now ready to save'), null, 'success');
            this.props.router.navigate(`/admin/timelogs/${timelog.id}`, { state: { timelogs: timelog.id } });
        }
    }

    async handleStart() {
        const {
            timelogStore, commonStore, t
        } = this.props;
        const { status } = this.state;
        if (!status.timelog && !status.shift) {
            commonStore.addNotification(t('Please choose the task and click Start'), null, 'warning');
            this.props.router.navigate('/admin/timelogs/add');
            return;
        }
       
        const draft = await timelogStore.getPossibleDraftId({
            project_id: status.shift.project_id,
            task_id: status.shift.task_id,
            shift_id: status.shift.shift_id
        });
        this.setState({ status: draft });
        this.loadData();
        return null;
    }

    renderStartButton() {
        const { t } = this.props;
        return (
            <div className="flex-card-footer-buttons">
                <Button fill style={{ width: '100%' }} onClick={() => this.handleStart()}>
                    {t('Start')}
                </Button>
            </div>
        );
    }

    async onPause() {
        const { status } = this.state;
        const { timelogStore } = this.props;
        const isPaused = status.timelog.tracker_status === 'break';
        await timelogStore.breakByButton(status.timelog.id, isPaused ? 'stop' : 'start', false);
        this.loadData();
    }

    renderStopAndBreak = () => {
        const { status } = this.state;
        const { t } = this.props;
        const { data: clientData } = this.props.commonStore.config.client;
        const hideBreakButton = clientData.basicRules && clientData.basicRules.hideBreakButton ? clientData.basicRules.hideBreakButton : false;
        const isPaused = status.timelog.tracker_status === 'break';
        return (
            <div className="flex-card-footer-buttons">
                <Button fill onClick={() => this.handleStop()}>
                    {t('Finish')}
                </Button>
                {!hideBreakButton &&
                    <Button onClick={() => this.onPause()}>{isPaused ? t('Finish break') : t('Start break')}</Button>
                }
            </div>
        );
    };

    render() {
        const { t, commonStore } = this.props;
        const { status, emergencyShiftDeatils, employeeIsAssignedForEmergencyShift } = this.state;
        if (!status) return null;
        let emergencyShift = '';
        if (employeeIsAssignedForEmergencyShift) {
            const dateTimeRules = commonStore.config.client && commonStore.config.client.data && commonStore.config.client.data.dateTimeRules
                ? commonStore.config.client.data.dateTimeRules
                : false;
            emergencyShift = (
                <OverlayTrigger
                    key="bottom"
                    placement="bottom"
                    overlay={
                        <Tooltip id="tooltip-bottom">
                            <div>{t("You have been assigned to handle emergency shifts(on-call)")}</div>
                            {`${t('From')} - ${shortDateFormat(emergencyShiftDeatils?.fromDate, dateTimeRules)} | ${t('To')} - ${shortDateFormat(emergencyShiftDeatils?.toDate, dateTimeRules)}`}
                        </Tooltip>
                    }
                >
                    <div className='font-size-12 text-bg-danger border-radius-10 custom-padding-timelog-widget'>{t('On Call')}</div>
                </OverlayTrigger>
            )
        }
        return (
            <Fragment>
                <Col sm={6} lg={3}>
                    {!status.timelog && !status.shift && (
                        <FlexCard
                            title={t('Timelog')}
                            desc={t('No work right now')}
                            icon={<i className="pe-7s-clock text-warning" />}
                            footer={this.renderStartButton()}
                            additionalImg={emergencyShift}
                        />
                    )}
                    {!!status.shift && !status.timelog && (
                        <FlexCard
                            title={t('Timelog')}
                            desc={`${status.shift.project_name} / ${status.shift.task_name}`}
                            icon={<i className="pe-7s-clock text-warning" />}
                            footer={this.renderStartButton()}
                            additionalImg={emergencyShift}
                        />
                    )}
                    {!!status.timelog && (
                        <FlexCard
                            title={t('Timelog')}
                            desc={`${t('Started at')} ${minutesToTimeInput(status.timelog.from, true)}`}
                            icon={<i className="pe-7s-clock text-success" />}
                            footer={this.renderStopAndBreak()}
                            additionalImg={emergencyShift}
                        />
                    )}
                </Col>
            </Fragment>
        );
    }
}

export default inject('timelogStore', 'commonStore', 'userStore')(applicationRouter(withLocalization(observer(TimelogDashboardWidget))));
