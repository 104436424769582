import React from "react";
import { Link } from "react-router-dom";
import "./styles.css";

function Client(props) {
  return (
    <div className="clientCol">
      <Link to={props.link}>
        <img src={props.src} alt={props.name} />
      </Link>
    </div>  
  );
}
export default Client;