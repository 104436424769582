import React, { Component, Fragment, createRef } from 'react';
import { inject, observer } from 'mobx-react';
import { Modal } from 'react-bootstrap';
import {
    MapContainer, Marker, Popup, TileLayer
} from 'react-leaflet';
import { Icon } from 'leaflet';

//components
import withLocalization from '../../../hoc/withLocalization';
import RoutingMachine from './RoutingMachine';

//utils
import { leafletStringToLocation, locationToString } from '../../../utils/geo';

//elements
import LoadingSpinner from '~/elements/LoadingSpinner';

//styles
import 'leaflet/dist/leaflet.css';

//assets
import CloseModalIcon from '../../../assets/img/CloseApproveModalIcon.svg';


const DEFAULT_COORDS = { lat: 59.9141521, lng: 10.7463433 };

const InternalGpsCoordinatesWidget = inject('taskStore', 'projectStore', 'userStore', 'commonStore')(withLocalization(observer(class InternalGpsCoordinatesWidget extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mapOpen: false,
            intermediateCoords: props.value ? leafletStringToLocation(props.value) : null,
        };
    }

    mapRef = createRef();

    showMap() {
        this.setState({ mapOpen: true });
    }

    handleClick(e) {
        this.setState({ intermediateCoords: e.latlng });
    }

    submitCoords() {
        this.setState({ mapOpen: false });
        this.props.onChange(locationToString(this.state.intermediateCoords));
    }

    handleLocationFound(e) {
        //TODO something
    }

    static getDerivedStateFromProps(props, state) {
        if (!state.intermediateCoords && props.value) {
            state.intermediateCoords = leafletStringToLocation(props.value);
        }
        return state;
    }

    render() {
        const {
            value, onChange, className, t, taskStore, projectStore, isRouteBased, commonStore
        } = this.props;
        if (isRouteBased && !taskStore.currentEntity?.task?.gps_data) {
            return
        }
        const { mapOpen, intermediateCoords } = this.state;
        let geoValue = null;
        try {
            geoValue = leafletStringToLocation(value);
        } catch (e) { }
        if (!geoValue || !geoValue.lat || Number.isNaN(geoValue.lat) || !geoValue.lng || Number.isNaN(geoValue.lng)) {
            geoValue = null;
        }

        let originIcon = null, destinationIcon = null;
        if (!!isRouteBased) {
            originIcon = new Icon({
                iconUrl: '/images/map-icon-green.png',
                iconSize: [38, 50],
                iconAnchor: [22, 50],
                popupAnchor: [-3, -76],
                shadowUrl: '/images/map-icon-shadow.png',
                shadowSize: [68, 95],
                shadowAnchor: [22, 94],
            });
            destinationIcon = new Icon({
                iconUrl: '/images/map-icon.png',
                iconSize: [38, 50],
                iconAnchor: [22, 50],
                popupAnchor: [-3, -76],
                shadowUrl: '/images/map-icon-shadow.png',
                shadowSize: [68, 95],
                shadowAnchor: [22, 94],
            });
        }
        const gpsNowLoading = taskStore?.gpsNowLoading || projectStore?.gpsNowLoading;
        const myIcon = new Icon({
            iconUrl: '/images/map-icon.png',
            iconSize: [38, 50],
            iconAnchor: [22, 50],
            popupAnchor: [-3, -76],
            shadowUrl: '/images/map-icon-shadow.png',
            shadowSize: [68, 95],
            shadowAnchor: [22, 94],
        });
        const marker = intermediateCoords ? (
            <Marker position={intermediateCoords} icon={myIcon}>
                <Popup>{t('Here')}</Popup>
            </Marker>
        ) : null;

        return (
            <Fragment>
                <Modal size="lg" show={!!mapOpen} onHide={() => this.setState({ mapOpen: false })}>
                    <Modal.Header>
                        <Modal.Title>{this.props.t('Choose on map')}</Modal.Title>
                        <img
                            src={CloseModalIcon}
                            className="cursor-pointer"
                            alt='close icon'
                            onClick={() => this.setState({ mapOpen: false })}
                        />
                    </Modal.Header>

                    <Modal.Body style={{ height: '500px' }}>
                        <div style={{ height: '400px', width: '100%' }}>
                            <MapContainer
                                center={geoValue || DEFAULT_COORDS}
                                length={4}
                                onClick={e => this.handleClick(e)}
                                onLocationfound={e => this.handleLocationFound(e)}
                                ref={this.mapRef}
                                zoom={13}
                            >
                                <TileLayer
                                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                                {!isRouteBased && marker}
                                {!!isRouteBased && commonStore?.config?.client?.gps_data &&
                                    <>
                                        <Marker position={leafletStringToLocation(commonStore?.config?.client?.gps_data)} icon={originIcon}>
                                            <Popup>{t('Company')} {t('Location')}</Popup>
                                        </Marker>
                                        <Marker position={leafletStringToLocation(taskStore.currentEntity?.task?.gps_data)} icon={destinationIcon}>
                                            <Popup>{t('Task Location')}</Popup>
                                        </Marker>
                                        <RoutingMachine {...this.props} />
                                    </>
                                }
                            </MapContainer>
                            <div style={{ width: '100%', padding: '10px', display: 'flex' }}>
                                <div style={{ width: '200px', paddingTop: '7px', paddingRight: '10px' }}>
                                    {t('Please click on the map to set coordinates')}
                                </div>
                                <div style={{ width: '200px', paddingTop: '7px', paddingRight: '10px' }}>
                                    {intermediateCoords
                                        ? `${intermediateCoords.lat}, ${intermediateCoords.lng}`
                                        : t('Not set')}
                                </div>

                                <button className="btn btn-fill" onClick={() => this.submitCoords()}>
                                    {t('Set cooordinates')}
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <div className='gps_coordinate_widget'>
                    <div className='position-relative w-75'>
                        <input
                            className={className || 'form-control time-input'}
                            type="text"
                            value={value || ''}
                            placeholder={t('GPS')}
                            onChange={e => onChange(e.target.value)}
                            readOnly
                        />
                        <div className='position-absolute gps_check_icon_wrapper'>
                            {gpsNowLoading && <LoadingSpinner noMargin />}
                            {value && !gpsNowLoading && (
                                <i className="pe-7s-check" />
                            )}
                        </div>
                    </div>
                    <div className='ml-10 w-25'>
                        <a role="button" onClick={() => this.showMap()}>{t('View Map')}</a>
                    </div>
                </div>
            </Fragment>
        );
    }
})));

export const GpsCoordinatesWidget = observer(class GpsCoordinatesWidget extends Component {
    render() {
        return <InternalGpsCoordinatesWidget {...this.props} mode="managers" />;
    }
});

export const GpsCoordinatesWithRoutesWidget = observer(class GpsCoordinatesWithRoutesWidget extends Component {
    render() {
        return <InternalGpsCoordinatesWidget {...this.props} mode="managers" isRouteBased={true} />;
    }
});
