import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Row, Col } from 'react-bootstrap'

//components
import applicationRouter from '~/hoc/applicationRouter';
import withLocalization from '~/hoc/withLocalization';

//elements
import Select from '../../../../elements/Select';

const breakMinOptions = [{ value: "0", label: '0 minute' }, { value: "30", label: '30 minutes' }, 
{ value: "60", label: '60 minutes' }, { value: "90", label: '90 minutes' }]

class RulesForBreakTime extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rulesForBreakTime: {
                minutes: "0",
                hoursBefore30MinBreak: 0,
                hoursBefore60MinBreak: 0,
            },
            maxHoursForAutoLogout:13
        }
        this.handleSelect = this.handleSelect.bind(this)
        this.handleInputRange = this.handleInputRange.bind(this)
        this.loadData = this.loadData.bind(this);
    }

    loadData = () => {
        const { currentEntity } = this.props.clientStore;
        const { rulesForBreakTime } = this.state;
        if (currentEntity.client.data && currentEntity.client.data.basicRules) {
            const basicRules = Object.assign({}, currentEntity.client.data.basicRules);            
            rulesForBreakTime.minutes = basicRules.breakTimeRules.minutes;            
            this.setState({ 
                rulesForBreakTime,
                maxHoursForAutoLogout : basicRules.maxHoursForAutoLogout ? basicRules.maxHoursForAutoLogout : 13
             })
        }
    }

    componentDidMount() {
        this.loadData()
    }

    handleSelect = (name, value) => {
        const { rulesForBreakTime } = this.state;
        rulesForBreakTime[name] = value;
        this.props.handleUserDataChange('breakTimeRules', rulesForBreakTime);
        this.props.updateBreakMins(value);
        this.setState({ rulesForBreakTime });
    }

    handleInputRange = (e) => {      
        e.persist();        
        if(e.target.value < 12 || e.target.value > 23) return;
        let _maxHoursForAutoLogout = e.target.value;
        this.props.handleUserDataChange('maxHoursForAutoLogout', _maxHoursForAutoLogout);
        this.setState({ maxHoursForAutoLogout: _maxHoursForAutoLogout });
    }

    render() {
        const { rulesForBreakTime, maxHoursForAutoLogout } = this.state;
        const { t } = this.props;
        return (
            <div>
                <Row className='mt-20'>
                    <Col sm={4}>
                        <label className="control-label">{t('Break time')}</label>
                        <Select
                            options={breakMinOptions}
                            value={breakMinOptions.find(item => item.value === rulesForBreakTime.minutes)}
                            onChange={time => this.handleSelect('minutes', time.value)}
                            style={{ width: '100%' }}
                        />
                    </Col>                    
                    <Col sm={8}>
                        <label className="control-label">{t('Maximum allowed hours before auto finish working log')}</label>                        
                        <div className="slider-parent">
                            <input type="range" min="12" max="23" 
                                onChange={event => this.handleInputRange(event)} 
                                value={maxHoursForAutoLogout}
                            />
                            <div className="buble">
                                {maxHoursForAutoLogout} {t('Hours')}
                            </div>
                        </div>
                    </Col>
                </Row>                
                <Row style={{ marginTop: '2rem' }}>
                    <span><b>{t('Note')}-</b>&nbsp;{t(`In this section, the admin can decide how much time should be deducted as a break from the daily registered work hours. The admin can also add the respective business's work time and set the maximum number of hours after which users' work log should end. This is useful in case users forget to end their working day. The admin will receive an email notifying them of how many users forgot to log out on a working day`)}.</span>
                </Row >
            </div>
        );
    }
}
export default inject('clientStore', 'commonStore', 'userStore')(applicationRouter(withLocalization(observer(RulesForBreakTime))));
