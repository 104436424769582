import React, { Component } from 'react';
import { inject } from 'mobx-react';

//components
import withLocalization from '~/hoc/withLocalization';
import applicationRouter from '~/hoc/applicationRouter'
import ServiceRequestsList from './ServiceRequestsList';

class ServiceRequests extends Component {

    onAdd() {
        if (window.sessionStorage.getItem('cusToken'))
            this.props.router.navigate('/customer/add');
        else
            this.props.router.navigate('/admin/serviceRequests/add');
    }

    render() {
        return (
            <ServiceRequestsList
                onTableAction={(id, type) => this.onTableAction(id, type)}
                onAdd={() => this.onAdd()}
            />
        )
    }
}

export default inject('authStore')(applicationRouter(withLocalization(ServiceRequests)));