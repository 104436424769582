import React from 'react';
import {
    Accordion as DefaultAccordion,
    AccordionItem as DefaultAccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton,
} from 'react-accessible-accordion';

export const Accordion = ({ children, ...rest }) => {
    return (
        <DefaultAccordion {...rest} allowMultipleExpanded>
            {children}
        </DefaultAccordion>
    );
};

export const AccordionItem = ({ title, uuid, children }) => (
    <DefaultAccordionItem uuid={uuid} className='mt-3'>
        <AccordionItemHeading>
            <AccordionItemButton>
                {title}
                <div className="accordion__icon">
                    <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.05844 0L12.9416 0C13.1518 0.000955482 13.357 0.0694624 13.5313 0.196858C13.7056 0.324255 13.8411 0.504819 13.9208 0.715718C14.0004 0.926618 14.0206 1.15838 13.9787 1.3817C13.9368 1.60502 13.8348 1.80987 13.6856 1.97035L7.75465 8.39991C7.65584 8.50791 7.53829 8.59363 7.40876 8.65213C7.27924 8.71062 7.14031 8.74074 7 8.74074C6.85969 8.74074 6.72076 8.71062 6.59124 8.65213C6.46171 8.59363 6.34416 8.50791 6.24535 8.39991L0.314411 1.97035C0.165159 1.80987 0.0631517 1.60502 0.0212895 1.3817C-0.0205726 1.15838 -0.000410403 0.926618 0.0792274 0.715718C0.158865 0.504819 0.294402 0.324255 0.468698 0.196858C0.642995 0.0694624 0.848224 0.000955482 1.05844 0V0Z" fill="#B3B3B3" />
                    </svg>
                </div>
            </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>{children}</AccordionItemPanel>
    </DefaultAccordionItem>
);
