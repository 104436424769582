import { observable, action, makeObservable } from 'mobx';

import agent from '../agent';

import userStore from './userStore';
import commonStore from './commonStore';

class AuthStore {
    inProgress = false;

    errors = undefined;

    frontErrors = undefined;

    values = {
        username: '',
        password: '',
        name: '',
        confirmPassword: '',
        email: '',
        userType: 'client',
    };

    additionalData = {};

    custConfig = {};

    constructor() {
        makeObservable(this, {
            inProgress: observable,
            errors: observable,
            frontErrors: observable,
            values: observable,
            additionalData: observable,
            custConfig: observable,
            formChange: action,
            setUsername: action,
            setEmail: action,
            setPassword: action,
            setData: action,
            setError: action,
            reset: action,
            login: action,
            loginAs: action,
            register: action,
            logout: action,
            resetPassword: action
        });
    }

    formChange(name, value) {
        this.values[name] = value;
    }

    setUsername(username) {
        this.values.username = username;
    }

    setEmail(email) {
        this.values.email = email;
    }

    setPassword(password) {
        this.values.password = password;
    }

    setData(name, value) {
        this.additionalData[name] = value;
    }

    setError(err) {
        this.frontErrors = err;
    }

    reset() {
        this.values = {
            username: '',
            password: '',
            name: '',
            confirmPassword: '',
            email: '',
            userType: 'client',
        };
        this.errors = null;
        this.frontErrors = null;
        this.additionalData = {};
    }

    login() {
        this.inProgress = true;
        this.errors = undefined;
        return agent.Auth.login(this.values.email, this.values.password)
            .then(({ user }) => commonStore.setToken(user.token))
            .then(() => userStore.pullUser())
            .catch(
                action(err => {
                    if(err && err.response && err.response.body && err.response.body && err.response.body.errors){
                        this.errors= err.response.body.errors.error;
                    }
                    throw err;
                })
            )
            .finally(
                action(() => {
                    this.inProgress = false;
                })
            );
    }

    loginAs(id) {
        this.inProgress = true;
        this.errors = undefined;
        return agent.Auth.loginAs(id)
            .then(response => {
                if (response.user && response.user.token) {
                    commonStore.setToken(response.user.token);
                    userStore.pullUser();
                    userStore.removeMultiAdminToken();
                } else if (response && response.status === 'requested') {
                    throw new Error('Request to user is sent by email, you will be notified when one accepts');
                }
            })
            .catch(
                action(err => {
                    this.errors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            )
            .finally(
                action(() => {
                    this.inProgress = false;
                })
            );
    }

    register() {
        this.inProgress = true;
        this.errors = undefined;
        return agent.Auth.register(this.values)
            .catch(
                action(err => {
                    this.errors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            )
            .finally(
                action(() => {
                    this.inProgress = false;
                })
            );
    }

    logout() {
        commonStore.setToken(undefined);
        userStore.forgetUser();
        return Promise.resolve();
    }

    resetPassword(email) {
        return agent.Auth.resetPassword(email).catch(e => {
            return false;
        });
    }

    signInWithEmail(value) {
        return agent.ServiceRequests.signInWithEmail(value)
            .catch(
                action(err => {
                    this.errors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            )
    }

    loginWithOtp(value) {
        return agent.ServiceRequests.loginWithOtp(value)
            .then(
                action(res => {
                    window.sessionStorage.setItem('cusToken', res.token)
                    return true
                })
            )
            .catch(
                action(err => {
                    this.errors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            )
    }

    loadCustConfig() {
        return agent.ServiceRequests.loadCustConfig()
            .then(
                action(res => {
                    this.custConfig = res;
                })
            )
            .catch(
                action(err => {
                    this.errors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            )
    }
}

const _AuthStore = new AuthStore();
export default _AuthStore;
