/*eslint-disable*/
import React, { Component } from 'react';
import { Collapse } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { sortBy } from 'lodash';
import { inject, observer } from 'mobx-react';

//components
import applicationRouter from '~/hoc/applicationRouter';
import withLocalization from '~/hoc/withLocalization';

//elements
import LoadingSpinner from '../../elements/LoadingSpinner';

//services
import config from '../../library/config';

//assets
import defaultAvatar from '~/assets/img/faces/face-0.jpg';
import newlogo from '../../assets/img/new-logo.png';

var ps;

class SidebarMobile extends Component {
    constructor(props) {
        super(props);
        const { routes } = props;
        this.state = {
            ...this.getCollapseStates(routes),
            isUserOpen: false,
            isDocumentsOpen: false,
            isWindows: navigator.platform.indexOf('Win') > -1 ? true : false,
            width: window.innerWidth,
        };
    }

    hideMenu() {
        this.props.userStore.hideSidebar();
    }

    // this creates the intial state of this component based on the collapse routes
    // that it gets through this.props.routes
    getCollapseStates = (routes) => {
        let initialState = {};
        routes.map((prop, key) => {
            if (prop.collapse) {
                initialState = {
                    [prop.collapseId]: this.getCollapseInitialState(prop.views),
                    ...this.getCollapseStates(prop.views),
                    ...initialState,
                };
            }
            return null;
        });
        return initialState;
    };
    // this verifies if any of the collapses should be default opened on a rerender of this component
    // for example, on the refresh of the page,
    // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
    getCollapseInitialState(routes) {
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
                return true;
            } else if (window.location.href.indexOf(routes[i].path) !== -1) {
                return true;
            }
        }
        return false;
    }
    // this function creates the links and collapses that appear in the sidebar (left menu)
    createLinks = (_routes) => {
        const { chatStore, clientStore } = this.props;
        const routes = sortBy(_routes, (r) => {
            return r.order || 1;
        });
        const client = clientStore?.currentEntity?.client
        return routes.map((prop, key) => {
            if (prop.redirect) {
                return null;
            }
            if (prop.allowed && !prop.allowed(this.props.userStore.currentUser, client)) return null;
            if (prop.hide) return null;
            const showBadge = !!(prop.name === 'Chat' && chatStore.totalUnreads);
            if (prop.collapse) {
                const isGroupAllowed = prop.views.reduce((result, menuItem) => {
                    if (!menuItem.allowed) {
                        return true;
                    }
                    if (menuItem.allowed && menuItem.allowed(this.props.userStore.currentUser, client)) {
                        return true;
                    }
                    return result;
                }, false);
                if (!isGroupAllowed) {
                    return null;
                }
                var st = {};
                st[prop['collapseId']] = !this.state[prop.collapseId];
                return (
                    <li className={this.getCollapseInitialState(prop.views) ? 'active' : ''} key={key}>
                        <a
                            className="nav-link mainNavLink"
                            href="/"
                            onClick={(e) => {
                                e.preventDefault();
                                this.setState(st);
                            }}
                        >
                            <span className="d-flex">
                                <img src={prop.icon} className="sidebarIcon" />
                                <div>
                                    {this.props.t(prop.name)}
                                    <b className={this.state[prop.collapseId] ? 'caret rotate-180' : 'caret'} />
                                </div>
                            </span>
                        </a>
                        <Collapse in={this.state[prop.collapseId]}>
                            <ul className="nav">{this.createLinks(prop.views)}</ul>
                        </Collapse>
                    </li>
                );
            }
            if (prop.url) {
                let url = prop.url;
                if (url === 'RULES') {
                    if (
                        !this.props.commonStore.config ||
                        !this.props.commonStore.config.client ||
                        !this.props.commonStore.config.client.data ||
                        !this.props.commonStore.config.client.data.basicRules
                    ) {
                        return null;
                    }
                    const { data: clientData } = this.props.commonStore.config.client;
                    url = clientData.basicRules.rulesLink;
                }
                return (
                    <li className={this.activeRoute(prop.layout + prop.path)} key={key}>
                        <a target="_blank" href={url} className="nav-link" onClick={() => this.hideMenu()}>
                            {prop.icon ? (
                                <span className="d-flex">
                                    <img src={prop.icon} className="sidebarIcon" />
                                    <p>{this.props.t(prop.name)}</p>
                                </span>
                            ) : (
                                <span className="d-flex">
                                    <span className="sidebar-mini">{prop.mini}&nbsp;</span>
                                    <span className="sidebar-normal" title={this.props.t(prop.name)}>
                                        {this.props.t(prop.name)}
                                    </span>
                                </span>
                            )}
                        </a>
                    </li>
                );
            }
            return (
                <li className={this.activeRoute(prop.layout + prop.path)} key={key}>
                    <NavLink to={prop.layout + prop.path} className="nav-link" onClick={() => this.hideMenu()}>
                        {prop.icon ? (
                            <span className="d-flex">
                                <img src={prop.icon} className="sidebarIcon" />
                                <div className="d-flex">
                                    {this.props.t(prop.name)}
                                    {showBadge && (
                                        <div className="chat-badge">
                                            {chatStore.totalUnreads + chatStore.totalPrivateUnreads}
                                        </div>
                                    )}
                                </div>
                            </span>
                        ) : (
                            <span className="d-flex">
                                <span className="sidebar-mini">{prop.mini}&nbsp;</span>
                                <span className="sidebar-normal" title={this.props.t(prop.name)}>
                                    {this.props.t(prop.name)}
                                </span>
                            </span>
                        )}
                    </NavLink>
                </li>
            );
        });
    };
    // verifies if routeName is the one active (in browser input)
    activeRoute = (routeName) => {
        return this.props.router.location.pathname === routeName ? 'active' : '';
    };
    // if the windows width changes CSS has to make some changes
    // this functions tell react what width is the window
    updateDimensions() {
        this.setState({ width: window.innerWidth });
    }
    componentDidUpdate() {
        const { userStore, clientStore } = this.props;
        if (userStore.currentUser && userStore.currentUser.client_id && !this.props.clientStore.currentEntity.client) {
            clientStore.load(userStore.currentUser.client_id);
        }
        if (navigator.platform.indexOf('Win') > -1) {
            setTimeout(() => {
                if (ps) ps.update();
            }, 350);
        }
    }
    componentDidMount() {
        const { userStore, clientStore } = this.props;
        if (userStore.currentUser && userStore.currentUser.client_id) {
            clientStore.load(userStore.currentUser.client_id);
        }
        this.updateDimensions();
        // add event listener for windows resize
        window.addEventListener('resize', this.updateDimensions.bind(this));
        // if you are using a Windows Machine, the scrollbars will have a Mac look
        /*if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.sidebarWrapper, {
        suppressScrollX: true,
        suppressScrollY: false
      });
    }*/
    }
    componentWillUnmount() {
        // we need to destroy the false scrollbar when we navigate
        // to a page that doesn't have this component rendered
        /*if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }*/
    }

    handleDashboardLoad = (e) =>{
        e.preventDefault();
        const host = window.location.hostname;
        const port = window.location.port;
        const url = host === 'localhost'? `http://${host}:${port}`: `https://${host}`;
        window.location.assign(url)
    }

    render() {
        if (this.props.userStore && this.props.userStore.currentUser && this.props.userStore.currentUser) {
            const user = this.props.userStore.currentUser;
            if (user.is_now_locked) return null;
        }
        const { routes } = this.props;
        let avatar = defaultAvatar;
        if (this.props.userStore.currentUser && this.props.userStore.currentUser.image) {
            avatar = `${config.UPLOADS_API_ENDPOINT}/${this.props.userStore.currentUser.image}`;
        }
        if(!this.props.userStore.currentUser) return <LoadingSpinner/>;
        return (
            <div className="new-sidebar-class sidebarMobile">
                <div className="logo">
                    <NavLink to="/" className="simple-text logomain" onClick={this.handleDashboardLoad}>
                        <img src={newlogo} alt="react-logo" id="logoimg" />
                    </NavLink>
                </div>

                <div className="sidebar-wrapper" ref="sidebarWrapper">
                    <ul className="nav">
                        {this.createLinks(routes)}
                        <li>
                            <NavLink to="/" className="nav-link" onClick={this.props.handleLogOut}>
                                <span className="d-flex">
                                    <i
                                        className="fas fa-sign-out-alt fa-xl mt-1 text-danger"
                                        aria-hidden="true"
                                    ></i>
                                    <div className="d-flex">
                                        {this.props.t('Log out')}
                                    </div>
                                </span>
                            </NavLink>
                        </li>
                    </ul>
                    <br />
                    <br />
                    <br />
                </div>
            </div >
        );
    }
}

export default inject(
    'userStore',
    'commonStore',
    'chatStore',
    'clientStore'
)(applicationRouter(withLocalization(observer(SidebarMobile))));
