import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { Modal, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { v4 as uuid } from 'uuid';

//components
import applicationRouter from '~/hoc/applicationRouter';
import withLocalization from '../../../hoc/withLocalization';
import Button from '../../../components/CustomButton/CustomButton.jsx';
import Checkbox from '../../../components/CustomCheckbox/CustomCheckbox';
import { MembersWidget } from '../../../components/GenericForm/Widgets/UsersWidget';

//elements
import RequiredStar from '../../../elements/RequiredStar.js';

//assets
import CloseModalIcon from '../../../assets/img/CloseApproveModalIcon.svg';

class ModalMoveOrCopy extends Component {
    constructor(props) {
        super(props);
        this.isShiftAllowanceEnabled = props?.commonStore?.config?.client?.data?.extraPayments?.some(ele => ele.code === 'shift_allowance' && ele.isActive) || false;
        this.isEmergencyShiftEnabled = props?.commonStore?.config?.client?.data?.extraPayments?.some(ele => ele.code === 'emergency_shifts' && ele.isActive) || false;
        this.state = {
            notify: false,
            shiftAllowance: false,
            emergencyCall: false
        };
    }

    updateMovingData(toUser) {
        this.props.updateMovingData(toUser);
    }

    async handleRun(action) {
        if (action !== 'cancel' && !this.props.movingData.isShiftToPending && !this.props.movingData.toUser) {
            return this.props.commonStore.addNotification(this.props.t("Please select an employee as asignee to perform action"), null, 'warning', 'top-right', 2000);
        }
        this.props.decide(action, this.state);
    }

    render() {
        const { t, movingData } = this.props;
        return (
            <Fragment>
                <Modal dialogClassName="lg" className="lg shift_modal" size="lg" show onHide={() => this.handleRun('cancel')}>
                    <Modal.Header className="set_ModalHeader">
                        <Modal.Title className='w-100 text-center custom-modal-title'>{t('With selected shift')}</Modal.Title>
                        <img
                            src={CloseModalIcon}
                            className="cursor-pointer"
                            onClick={() => this.handleRun('cancel')}
                        />
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            {!!movingData.currentView && !movingData.isShiftToPending && (
                                <Row className='mx-2' style={{ minHeight: '200px' }}>
                                    <Col xs={4} md={3} className='mt-2'>
                                        <label className="control-label">
                                            {t('Assign to')}
                                            <RequiredStar />
                                        </label>
                                    </Col>
                                    {!!movingData.fromUser &&
                                        <Col xs={4} md={4} className='mt-2'>
                                            <Checkbox
                                                isChecked={movingData.fromUser && movingData.fromUser === parseInt(movingData.toUser)}
                                                onChange={() => this.updateMovingData({ to: movingData.fromUser })}
                                                label={this.props.t('Current employee')}
                                            />
                                        </Col>
                                    }
                                    <Col xs={4} md={5}>
                                        <MembersWidget
                                            key={uuid()}
                                            className="form-control"
                                            value={movingData.toUser || null}
                                            onChange={(value) => this.updateMovingData({ toUser: value })}
                                            valuesToFilter={movingData.availableAssigneess}
                                            placeholder={t('Select Employee')}
                                            reactSelectClassname={"custom-select-max-height-200"}
                                        />
                                    </Col>
                                    {!movingData.fromUser && !!movingData.groupOrTitleName &&
                                        <Col xs={4} md={4} className='mt-2'>
                                            <p className='fw-bold font-size-15'>{movingData.currentView === 1 ? t("Title") : t("Group")}:&nbsp;{movingData.groupOrTitleName}</p>
                                        </Col>
                                    }
                                    <p>
                                        <b>{t("Note")}: </b>
                                        {t(`Select the shift to move/copy to, if you want to assign to same employee then check the self checkbox, Otherwise to assign other employee select from employee drop down`)}
                                    </p>
                                    {!!movingData.fromUser && movingData.fromUser === movingData.toUser && movingData.addUserToGroup &&
                                        <p style={{ fontStyle: 'italic', fontSize: '12px' }}>
                                            {t(`We observe that employee is not in same group as the shift, no worries system will automatically add employee to group`)}
                                        </p>
                                    }
                                </Row>
                            )}
                            {!movingData.isShiftToPending ?
                                <Row className={!!movingData.currentView ? 'text-end' : 'text-center'}>
                                    <div className='d-flex justify-content-around'>
                                        <Checkbox
                                            isChecked={this.state.notify}
                                            onChange={checked => this.setState({ notify: checked })}
                                            label={this.props.t('Notify user')}
                                        />

                                        {(this.isEmergencyShiftEnabled || this.isShiftAllowanceEnabled) && !!movingData.fromUser && parseInt(movingData.fromUser) !== parseInt(movingData.toUser) &&
                                            <>
                                                <div className='d-flex'>
                                                    <Checkbox
                                                        isChecked={this.state.shiftAllowance}
                                                        onChange={checked => this.setState({ shiftAllowance: checked })}
                                                        label={this.props.t('Shift Allowance')}
                                                        disabled={!this.isShiftAllowanceEnabled}
                                                    />
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip-shift_allowance">
                                                                {t("Enable this to also Move/Copy Shift Allowance if found in the system")}.
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <i className="fa fa-info-circle text-muted mt-1 ms-2" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                                                    </OverlayTrigger>
                                                </div>
                                                <div className='d-flex'>
                                                    <Checkbox
                                                        isChecked={this.state.emergencyCall}
                                                        onChange={checked => this.setState({ emergencyCall: checked })}
                                                        label={this.props.t('Emergency Call(On-Call)')}
                                                        disabled={!this.isEmergencyShiftEnabled}
                                                    />
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip-emergency_shift">
                                                                {t("Enable this to also Move/Copy Emergency Shift(On-Call) if found in the system")}.
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <i className="fa fa-info-circle text-muted mt-1 ms-2" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                                                    </OverlayTrigger>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </Row>
                                :
                                <Row>
                                    <h4 className='text-center fw-bold'>{t("Shift To Open")}</h4>
                                    {(this.isEmergencyShiftEnabled || this.isShiftAllowanceEnabled) &&
                                        <p className="font-size-15 fw-semibold">{t("Note")}:&nbsp;
                                            {t("If the employee is assigned for shift allowance or emergency shift (on-call), these will not be moved to open. Please remove them manually if necessary")}.</p>
                                    }
                                </Row>
                            }
                            <div className='shift-move-btns my-4'>
                                <Button fill wd icon
                                    type="submit"
                                    style={{ backgroundColor: '#2550AC', marginRight: 30 }}
                                    onClick={() => this.handleRun('move')}
                                >
                                    <i className="fa fa-arrows-alt" /> {t('Move')}
                                </Button>
                                <Button fill wd icon
                                    type="submit"
                                    style={{ backgroundColor: '#16C51D', marginRight: 30 }}
                                    onClick={() => this.handleRun('copy')}
                                >
                                    <i className="fa fa-clone" /> {t('Copy')}
                                </Button>
                                <Button wd fill icon
                                    type="submit"
                                    onClick={() => this.handleRun('cancel')}
                                    style={{ backgroundColor: '#FF0000' }}
                                >
                                    <i className="fa fa-times" /> {t('Cancel')}
                                </Button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </Fragment>
        );
    }
}

export default inject('timelogStore', 'commonStore', 'userStore')(applicationRouter(withLocalization(observer(ModalMoveOrCopy))));
