import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Header from "../../../newComponents/Header";
import Footer from "../../../newComponents/Footer";
import MainHeading from "../../../newComponents/MainHeading";
import PageBanner from "../../../newComponents/PageBanner";
import { NumberInput } from "../../../elements/Input";
import BannerImg from "../../../assets/images/visitorRegisterImg.jpg";
import SubmitIcon from "../../../assets/images/submitIcon.svg";

//i18n
import { withTranslation } from "react-i18next"
import { inject, observer } from "mobx-react";
import { serverValidation } from "../../../library/core";

import email from '../../../assets/img/email.png';

const VisitorRegister = inject('authStore', 'commonStore', 'visitorStore')(observer((props) => {
  const { currentClientVisitor } = props.visitorStore
  const [isRegistered, setIsRegistered] = useState(false)
  const [code, setCode] = useState("")
  const [error, setError] = useState("")
  const [clientVisitorId, setClientVisitorId] = useState(0)

  const loadData = async () => {
    //calls action based on props to initiate currentVisitor values//
    const { visitorStore } = props;
    visitorStore.loadDefaultClientVisitor();
  }

  useEffect(() => {
    loadData()
  }, [])

  const handleTextChange = (name, value) => {
    //calls action to handle text changes//
    props.visitorStore.handleClinetVisitorChange(name, value);
  }

  const handleButtonClick = (e, type) => {
    e.preventDefault()
    if (type === 'add')
      addVisitor();
    if (type === 'verify')
      verifyVisitorClient();
  }

  const addVisitor = async () => {
    const { currentClientVisitor } = props.visitorStore;
    const { t } = props;
    let clientVisitor = {};

    clientVisitor.business_name = currentClientVisitor.business_name;
    clientVisitor.business_id = currentClientVisitor.business_id;
    clientVisitor.contact_person_name = currentClientVisitor.contact_person_name;
    clientVisitor.contact_person_email = currentClientVisitor.contact_person_email;
    clientVisitor.username = currentClientVisitor.username;
    clientVisitor.phone = currentClientVisitor.phone;

    if (clientVisitor.business_name.trim() === "" || clientVisitor.business_name === null || clientVisitor.business_name === undefined) {
      setError('Please Enter Business Name')
      return;
    }

    if (!clientVisitor.business_id.length > 0 || clientVisitor.business_id === null || clientVisitor.business_id === undefined) {
      setError('Please Enter Business ID')
      return;
    }

    if (!clientVisitor.business_id.match(serverValidation('tax'))) {
      setError('Business ID should contain 9 digits')
      return;
    }

    if (clientVisitor.contact_person_name.trim() === "" || clientVisitor.contact_person_name === null || clientVisitor.contact_person_name === undefined) {
      setError('Please Enter Contact Person Name')
      return;
    }

    if (clientVisitor.username.trim() === "" || clientVisitor.username === null || clientVisitor.username === undefined) {
      setError('Please Enter Username')
      return;
    }

    if (clientVisitor.contact_person_email.trim() === "" || clientVisitor.contact_person_email === null || clientVisitor.contact_person_email === undefined) {
      setError('Please Enter Contact Person Email')
      return;
    }

    if (clientVisitor.phone.trim() === "" || clientVisitor.phone === null || clientVisitor.phone === undefined) {
      setError('Please Enter Phone no')
      return;
    }

    if (!clientVisitor.phone.match(serverValidation('phone'))) {
      setError('Invalid phone number')
      return;
    }
    if (!clientVisitor.contact_person_email.match(serverValidation('email'))) {
      setError('Invalid email')
      return;
    }

    await props.visitorStore.saveClientVisitorData(clientVisitor).then((data) => {
      if (data.error) {
        alert(t(data.error));
        return;
      }
      if (data) {
        setIsRegistered(true);
        setError("");
        setClientVisitorId(data.id);
        return true;
      }
    }).catch((error) => {
      alert(error.message);
    })
  }

  const verifyVisitorClient = async () => {
    const { t } = props;

    if (code.length !== 6) {
      setError('Please enter 6 digit code to verify');
      return;
    }

    const verificationData = {
      id: clientVisitorId,
      code: Number(code)
    }

    await props.visitorStore.verifyClientVisitorData(verificationData).then((data) => {
      if (data.error) {
        alert(t(data.error));
        return;
      }
      setIsRegistered(false);
      setError("");
      setClientVisitorId(0);
      props.visitorStore.loadDefaultClientVisitor()
      alert(props.t('Registration successful. Credentials will be sent to your email ID.'));
      return true;
    }).catch((error) => {
      alert(error.message);
    })
  }



  return (
    <>
      {!isRegistered ?
        <div className="landingPageWrapp frameworkApp">
          <Header visitor="current" />
          <article className="pageBannerBlock grdntBg py-60">
            <Container>
              <Row className="g-4 align-items-center">
                <Col lg>
                  <MainHeading
                    title={<>{props.t("Visitor")} <span>{props.t("Register")}</span></>}
                    description={props.t("Utilize Time and Control, the top-rated management platform for all types of industries, and take command of the building project for absolute results with reduced risk and safeguards revenues.")}
                    customClass="m-0"
                  />
                </Col>
                <Col lg={1}></Col>
                <Col lg="auto">
                  <PageBanner
                    src={BannerImg}
                    alt="Visitor Register"
                    customClass="imgBorder"
                  />
                </Col>
              </Row>
            </Container>
          </article>
          <article className="vstrFormBlock  py-60">
            <Container>
              <Row className="g-4 align-items-center">
                <Col lg={5}>
                  <MainHeading
                    title={props.t("Visitor Registration")}
                    customClass="medium noPara mb-4"
                  />
                  <div className="contentInfo">
                    <h5 className="mb-5">{props.t("Has your business visitor registered? If not, get started!")}</h5>
                    <p>{props.t("Seize real-time supervision software to streamline the process.")}</p>
                    <p>{props.t("Automate the tasks and boost workflow within the site.")}</p>
                    <p>{props.t("Impose Covid - 19 restrictions without expenditures.")}</p>
                  </div>
                </Col>
                <Col lg={7}>
                  <div className="vstrForm commonForm boxShadow">
                    <MainHeading
                      title={`${props.t("Register Now")}!`}
                      description={props.t("Get a test run and accumulate Time and Control solutions for your organization with no cost!")}
                      customClass="text-center small mb-4"
                    />
                    <Form onSubmit={(e) => handleButtonClick(e, "add")}>
                      <Row>
                        <Col lg={6}>
                          <Form.Group className="mb-4" controlId="formRegisterBName">
                            <Form.Label>{props.t("Business Name")}*</Form.Label>
                            <Form.Control type="text" placeholder={props.t("Enter company name.")} value={currentClientVisitor.business_name} onChange={evt => { handleTextChange('business_name', evt.target.value) }} />
                          </Form.Group>
                        </Col>
                        <Col lg={6}>
                          <Form.Group className="mb-4" controlId="formContactPhone">
                            <Form.Label>{props.t("The Business ID")}*</Form.Label>
                            <Form.Control type="number" placeholder={props.t("Given by the PRH/Tax Admin.")} value={currentClientVisitor.business_id} onChange={evt => { if (evt.target.value.length < 10) handleTextChange('business_id', evt.target.value) }} />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={6}>
                          <Form.Group className="mb-4" controlId="formContactEmail">
                            <Form.Label>{props.t("Contact person")}*</Form.Label>
                            <Form.Control type="text" placeholder={props.t("Type contact person.")} value={currentClientVisitor.contact_person_name} onChange={evt => { handleTextChange('contact_person_name', evt.target.value) }} />
                          </Form.Group>
                        </Col>
                        <Col lg={6}>
                          <Form.Group className="mb-4" controlId="formContactPhone">
                            <Form.Label>{props.t("Email")}*</Form.Label>
                            <Form.Control type="email" placeholder={`${props.t("Enter Email")}.`} value={currentClientVisitor.contact_person_email} onChange={evt => { handleTextChange('contact_person_email', evt.target.value) }} />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Form.Group className="mb-4" controlId="formContactMessage">
                        <Form.Label>{props.t("User name")}*</Form.Label>
                        <Form.Control type="text" placeholder={props.t("Enter user name.")} value={currentClientVisitor.username} onChange={evt => { handleTextChange('username', evt.target.value) }} />
                      </Form.Group>
                      <Form.Group className="mb-4" controlId="formContactMessage">
                        <Form.Label>{props.t("Phone Number")}*</Form.Label>
                        <Form.Control type="text" placeholder={props.t("Enter mobile number.")} value={currentClientVisitor.phone} onChange={evt => { handleTextChange('phone', evt.target.value) }} />
                      </Form.Group>
                      <div className="formBtn text-center">
                        <Button className="btnCommon btnBig" variant="primary" type="submit"><i className="d-inline-block pe-2 pe-lg-4"><img src={SubmitIcon} alt="" /></i> {props.t("Get Free Link Now")}</Button>
                      </div>
                      <div className='col-md-6'>
                        <div className="custom-form__row">
                          {error !== "" && <span className='text-danger'>{props.t(error)}</span>}
                        </div>
                      </div>
                    </Form>
                  </div>
                </Col>
              </Row>
            </Container>
          </article>
          <Footer />
        </div>
        :
        <Row className="justify-content-center align-items-center">
          <div className='col-sm-12 col-md-4 col-lg-4'>
            <div className='row-flex' style={{ justifyContent: 'center', marginTop: '50px' }}>
              <img src={email} alt='emailImage' />
            </div>
            <div className='row-flex' style={{ justifyContent: 'center' }}>
              <h2 className='visitor-registration-msg'>{props.t('Thank You')}</h2>
            </div>
            <div className='row-flex text-center'>
              <span style={{ margin: '20px 0' }}>{props.t('We have sent you the') + ' '}
                <strong>{props.t('6 digit confirmation code')}</strong>
                {' ' + props.t('to your Email ID, so please enter received code and verify')}
              </span>
            </div>
            <div className='row-flex' style={{ justifyContent: 'center' }}>
              <NumberInput
                placeholder={props.t('Enter Code')}
                name="code"
                value={code}
                onChange={evt => {
                  setCode(evt.target.value);
                }}
                customClassses="bg-secondary text-center text-white"
              />
            </div>
            <div className='row-flex' style={{ justifyContent: 'center', marginTop: '5px' }}>
              {error !== "" && <span className='text-danger'>{props.t(error)}</span>}
            </div>
            <div className='row-flex' style={{ justifyContent: 'center', marginTop: '20px' }}>
              <Button fill onClick={(e) => handleButtonClick(e, 'verify')}>{props.t('Verify Email')}</Button>
            </div>
          </div>
        </Row>
      }
    </>
  );
}))
export default withTranslation()(VisitorRegister)