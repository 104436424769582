import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment/min/moment-with-locales'; // 'moment/min/moment-with-locales';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';

//components
import withLocalization from '~/hoc/withLocalization';
import withRemoveDialog from '~/hoc/withRemoveDialog';
import applicationRouter from '~/hoc/applicationRouter';
import GenericList from '~/components/GenericList/GenericList';
import GenericFilter from '../../../components/GenericList/GenericFilter.js';
import ModalEditor from '../Timelogs/ModalAccept';
import AbsencesTotals from './AbsencesTotals';
import AbsenceReportDownloadModal from './AbsenceReportDownloadModal';
import UserProfileLogo from '../../../components/UserProfileLogo/index.js';

//elements
import Button from '../../../components/CustomButton/CustomButton.jsx';

//services
import config from '~/library/config';
import agent from '~/library/agent';

//utils
import { statusMap } from '~/utils/normalStatuses';
import { shortDateFormat } from '~/utils/dateFormat';
import { absence_and_vacation_statuses, leave_type_status } from '~/utils/normalStatuses';
import customerFeature from '~/utils/customerFeature';
import { serverTimeFormat } from '~/library/core';
import { getIntegrationIconWithStatus } from '~/utils/integrationUtils';

//assets
import editIcon from '../../../assets/img/editing.svg';
import deleteIcon from '../../../assets/img/deleting.svg';
import CloseModalIcon from '../../../assets/img/CloseApproveModalIcon.svg';
import { confirmAlert } from 'react-confirm-alert';

class AbsencesList extends Component {
    constructor(props) {
        super(props);
        const { page, isEdit } = this.props.router.location.state || {};
        this.state = {
            accepting: null,
            acceptanceChanged: false,
            selected: null,
            start: '',
            end: '',
            dateRange: { start: moment(new Date()).startOf('month'), end: moment(new Date()) },
            all_members: '',
            users: '',
            type: '',
            page: page ? page : 0,
            isPageAddedOrEdited: isEdit ? isEdit : false
        };
        this.handlePageChange = this.handlePageChange.bind(this);
    }

    componentDidMount() {
        this.setState({
            start: this.state.dateRange.start.format('YYYY-MM-DD'),
            end: this.state.dateRange.end.format('YYYY-MM-DD'),
        });
    }

    handleTableButtonAction = (id, type, isSelfAbsence) => {
        if (type === 'edit') this.props.router.navigate(`/admin/absences/${id}`,
            { state: { absence: id, page: this.state.page, isEdit: this.state.isPageAddedOrEdited } });
        if (type === 'remove') {
            return this.props.handleDelete(id, (id) => {
                return this.props.absenceStore.remove(id).then((res) => {
                    this.props.absenceStore.resetLastListLoadTime(new Date());
                    // if (res.sickLeaveResetToken) {
                    //     this.userSickLeaveResetPopUp(res.sickLeaveResetToken, isSelfAbsence);
                    // }
                    return res;
                });
            });
        }
    };

    userSickLeaveResetPopUp = (token, isSelfAbsence) => {
        confirmAlert({
            title: this.props.t("Reset employee's sick leave cycle?"),
            message: this.props.t("Do you wish to reset the employee's sick leave cycle as there are no more paid sick leave?"),
            buttons: [
                {
                    label: this.props.t('Yes'),
                    onClick: () => {
                        return this.props.userStore.resetUserSickLeaveStartDate(token).then((res2) => {

                            if (isSelfAbsence) {
                                this.props.userStore.pullUser().then((res3) => {
                                    this.props.commonStore.addNotification(this.props.t("Sick leave cycle reset successful"), null, 'success');
                                    return res3;
                                });
                            } else {
                                this.props.commonStore.addNotification(this.props.t("Sick leave cycle reset successful"), null, 'success');
                                return res2;
                            }

                        })
                            .catch((err) => {
                                this.props.commonStore.addNotification(err, null, 'error');
                            })
                            ;
                    },
                },
                {
                    label: this.props.t('No'),
                    onClick: () => { },
                },
            ],
        })
    }

    componentWillUnmount() {
        this.props.absenceStore.resetLastListLoadTime(null);
    }

    renderTableActionButtons = (id, full) => {
        const { t, commonStore, userStore } = this.props;
        const { config } = commonStore;
        const { user_type, id: user_id } = userStore.currentUser;
        const showStatus = (user_type === 'pm' || user_type === 'admin') && customerFeature('allow_accepting');
        const allow = (user_type === 'pm' || user_type === 'admin') && customerFeature('pm_allow_editing_all');
        let statusText = full.status;
        if (!statusText) statusText = 'active';
        statusText = statusMap[statusText] || '';
        const isSelfAbsence = full.user_id === user_id;
        return (
            <div className="actions-center">
                {getIntegrationIconWithStatus(config, full, t)}
                {customerFeature('allow_accepting') && (
                    <div
                        className={`status-block status-${full.status || 'active'}${user_type === 'member' ? ' status-no-click-for-member' : ''}`}
                        onClick={() => (showStatus ? this.handleAccept(id, full) : null)}
                    >
                        {full.status.toLowerCase() === 'active' ||
                            !(full.status_changed_by && full.status_changed_on) ? (
                            <div className="text-capitalize">{t(statusText)}</div>
                        ) : (
                            <>
                                <div className="text-capitalize">
                                    {full.status_changed_by &&
                                        moment(new Date(full.status_changed_on)).format(serverTimeFormat(true))}
                                </div>
                                <div className="text-capitalize">
                                    {this.props.commonStore.getUsername(full.status_changed_by)}
                                </div>
                            </>
                        )}
                    </div>
                )}

                <OverlayTrigger
                    key="absence_edit"
                    placement="top"
                    overlay={
                        <Tooltip id="tooltip-top">
                            {t('Edit Absence')}
                        </Tooltip>
                    }
                >
                    <Button icon_sm fill
                        onClick={() => this.handleTableButtonAction(id, 'edit', isSelfAbsence)}>
                        <img src={editIcon} alt={'edit button'} />
                    </Button>
                </OverlayTrigger>

                {allow &&
                    <OverlayTrigger
                        key="absence_delete"
                        placement="top"
                        overlay={
                            <Tooltip id="tooltip-top">
                                {t('Remove Absence')}
                            </Tooltip>
                        }
                    >
                        <Button icon_sm_delete fill
                            onClick={() => this.handleTableButtonAction(id, 'remove', isSelfAbsence)}>
                            <img src={deleteIcon} alt="delete button" />
                        </Button>
                    </OverlayTrigger>

                }
            </div>
        );
    };

    handleAccept(id, full) {
        this.setState({
            accepting: {
                ids: [id],
                count: 1,
                entity: 'absence',
            },
        });
    }

    onSelectionChanged(ids) {
        this.setState({ selected: ids });
    }

    onMassAccept() {
        const ids = [];
        if (!this.state.selected) return;
        Object.keys(this.state.selected).forEach((id) => {
            if (this.state.selected[id]) ids.push(id);
        });
        this.setState({
            acceptanceChanged: false,
            accepting: {
                ids,
                count: ids.length,
                entity: 'absence',
            },
        });
    }

    afterMassAccept() {
        this.props.absenceStore.resetLastListLoadTime(new Date());
        this.setState({ accepting: null, acceptanceChanged: true });
    }

    handleAbsenceReportDownload = () => {
        this.setState({
            showDownloadReport: true,
        });
    };

    handleClosesDownloadReportModal = () => {
        this.setState({
            showDownloadReport: false,
            all_members: '',
            users: '',
            type: '',
        });
    };

    applyDownloadReportForm = () => {
        const { t } = this.props;
        const {currentUser} = this.props.userStore;            
        if(currentUser.user_type && currentUser.user_type == 'member'){
            this.state.users = `${currentUser.id}`
        }

        if (this.state.users.length <= 0 && !this.state.all_members && this.props.userStore.currentUser.user_type != 'member') {
            this.props.commonStore.addNotification(t('Choose users'), null, 'error');
            return;
        }

        if (!this.state.type) {
            let text = t('Select') + ' ' + t('Absence types');
            this.props.commonStore.addNotification(text, null, 'error');
            return;
        }

        let hydratedPayload = {
            start: this.state.start,
            end: this.state.end,
            all_members: this.state.all_members,
            users: this.props.userStore.currentUser.user_type === 'member' ? this.props.userStore.currentUser.id : this.state.users,
            type: this.state.type,
            authorization: this.props.commonStore.token,
        };

        window.open(`${config.API_ROOT}/absences/report/main${agent.convertToGetParams(hydratedPayload)}`);
        this.handleClosesDownloadReportModal();
    };

    handleDownloadFormInputChange = (key, value) => {
        this.setState({ [key]: value });
    };

    //handles generic list page change//
    handlePageChange = (page, isEdit) => {
        this.setState({ page, isPageAddedOrEdited: isEdit });
    };


    getAbsenceTypesSortingOptions = () => {
        const { config } = this.props.commonStore;
        const { t } = this.props;
        const absence_type = config && config.client
            && config.client.data
            && config.client.data.extendedRules
            && config.client.data.extendedRules.absent_types ?
            config.client.data.extendedRules.absent_types : [];
        if (!absence_type.length) return [];
        const absenceTypesSortingOptions = [];
        absence_type.forEach((type) => {
            leave_type_status.forEach((status) => {
                absenceTypesSortingOptions.push({ value: `${type.code},${status.code}`, label: `${t(type.name)} - ${t(status.name)}` });
            })
        });
        absenceTypesSortingOptions.unshift({ value: '', label: t('All Leaves') });
        return absenceTypesSortingOptions;
    }

    render() {
        const { loading, filters, appliedFilters } = this.props.absenceStore;
        const { user_type, id: user_id } = this.props.userStore.currentUser;
        const { t } = this.props;
        const { accepting, page, isPageAddedOrEdited } = this.state;
        const showStatus = user_type === 'pm' && customerFeature('allow_accepting');
        const { config } = this.props.commonStore;
        const dateTimeRules =
            config.client && config.client.data && config.client.data.dateTimeRules
                ? config.client.data.dateTimeRules
                : false;
        return (
            <Fragment>
                <Modal size="lg" show={!!accepting} onHide={() => this.setState({ accepting: null })}>
                    <Modal.Header>
                        <Modal.Title className="w-100 text-center m-0">{this.props.t('Accept/reject')}</Modal.Title>
                        <img
                            src={CloseModalIcon}
                            alt='closeIcon'
                            className="cursor-pointer"
                            onClick={() => this.setState({ accepting: null })}
                        />
                    </Modal.Header>

                    <Modal.Body>
                        <ModalEditor params={accepting} afterSave={() => this.afterMassAccept()} />
                    </Modal.Body>
                </Modal>
                {(user_type === 'member' || user_type === 'pm') && <AbsencesTotals user_id={user_id} for_date={moment()} absenceStatus={null} />}
                <>
                    <GenericList
                        columns={[
                            user_type === 'member'
                                ? null
                                : {
                                    Header: '',
                                    id: 'user_image',
                                    accessor: ({ user_image }) => (
                                        <UserProfileLogo image_id={user_image ? user_image : false} />
                                    ),
                                    maxWidth: 60,
                                },
                            user_type === 'member'
                                ? null
                                : {
                                    Header: 'Member',
                                    id: 'first_name',
                                    isSortable: true,
                                    accessor: ({ first_name, last_name }) => (
                                        <span>{first_name + ' ' + last_name}</span>
                                    ),
                                    textAlign: 'start',
                                },
                            {
                                Header: 'Project',
                                id: 'project_name',
                                isSortable: true,
                                accessor: ({ project_number, project_name }) => <span>
                                    {project_name ? ((project_number ? `${project_number} | ` : '') + project_name) : "-"}
                                </span>,
                            },
                            {
                                Header: 'Type',
                                id: 'absence_type',
                                accessor: ({ absence_type, leave_type }) => (
                                    <span>
                                        {t(absence_type)} / {leave_type === 'paid' ? t('Paid') : t('Not paid')}
                                    </span>
                                ),
                            },
                            {
                                Header: 'From date',
                                id: 'from',
                                isSortable: true,
                                maxWidth: 90,
                                accessor: ({ from }) => <span>{shortDateFormat(from, dateTimeRules)}</span>,
                            },
                            {
                                Header: 'To date',
                                id: 'to',
                                isSortable: true,
                                maxWidth: 90,
                                accessor: ({ to }) => <span>{shortDateFormat(to, dateTimeRules)}</span>,
                            },
                            {
                                Header: 'Total days',
                                id: 'total_days',
                                maxWidth: 90,
                                accessor: ({ total_days }) => <span>{total_days}</span>,
                            },
                            {
                                Header: 'Actions',
                                id: 'actions',
                                sortable: false,
                                accessor: (full) => this.renderTableActionButtons(full.id, full),
                            },
                        ]}
                        header={
                            <GenericFilter
                                filters={filters}
                                setFilter={(name, value) => this.props.absenceStore.setFilter(name, value)}
                                onFilter={() => this.props.absenceStore.onFilter()}
                                hasDateFilters={true}
                                hasStatusFilter={true}
                                statusOptions={absence_and_vacation_statuses}
                                hasCustomFilter={true}
                                customFilterOptions={this.getAbsenceTypesSortingOptions()}
                                customFilterName={{ value: 'absence_type', label: 'All Leaves' }}
                            />
                        }
                        tableHeader={this.props.t('Absents')}
                        filters={appliedFilters}
                        onSelectionChanged={(selection) => this.onSelectionChanged(selection)}
                        cutomCheckBoxClass={"top-position"}
                        allowCheckboxes={showStatus}
                        showStatus={showStatus}
                        onChangeStatus={() => this.onMassAccept()}
                        loading={loading}
                        lastListLoadTime={this.props.absenceStore.lastListLoadTime}
                        forceReload={this.props.absenceStore.deleteSuccess || this.state.acceptanceChanged}
                        requestData={(params) => this.props.absenceStore.loadList(params)}
                        handlePageChange={(page, isEdit) => this.handlePageChange(page, isEdit)}
                        page={page} //new page props to maintain page from parent//
                        isPageAddedOrEdited={isPageAddedOrEdited} //to load same page after edit//
                    >
                        <>
                            <Button fill wd onClick={() => this.handleAbsenceReportDownload()}>
                                <i className="fa fa-table fa-xl margin-right-10" />
                                {this.props.t('Download Excel')}
                            </Button>
                            <Button fill wd onClick={() => this.props.onAdd(page, isPageAddedOrEdited)}>
                                <i className="fa fa-plus-circle fa-xl margin-right-10" />
                                {this.props.t('Add new')}
                            </Button>
                        </>
                    </GenericList>
                </>
                <Modal
                    className="modal fade"
                    show={this.state.showDownloadReport}
                    onHide={this.handleClosesDownloadReportModal}
                >
                    <Modal.Header>
                        <Modal.Title className="w-100 text-center m-0">{t('Download Absence Report')}</Modal.Title>
                        <img
                            src={CloseModalIcon}
                            alt='closeIcon'
                            className="cursor-pointer"
                            onClick={this.handleClosesDownloadReportModal}
                        />
                    </Modal.Header>
                    <Modal.Body className='absenceReportModalBody'>
                        <AbsenceReportDownloadModal
                            handleDownloadFormInputChange={this.handleDownloadFormInputChange}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.applyDownloadReportForm} className="btn-wd btn-fill">
                            {t('Save')}
                        </Button>
                        <Button variant="secondary" onClick={this.handleClosesDownloadReportModal} className="btn-wd">
                            {t('Close')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Fragment>
        );
    }
}
export default inject(
    'absenceStore',
    'userStore',
    'commonStore'
)(applicationRouter(withLocalization(withRemoveDialog(observer(AbsencesList)))));
