export const schema = {
    title: 'Absence',
    type: 'object',
    required: ['absence_type', 'user_id', 'from', 'to', 'total_days', 'leave_type'],
    properties: {
        id: { type: 'integer' },
        user_id: { type: ['integer'], title: 'User', readOnly: true },
        client_id: { type: ['integer'], title: 'Client' },
        child: { type: ['string', 'null'], title: 'Child' },
        status: { type: ['string', 'null'], title: 'Status', default: 'active' },
        absence_type: { type: ['string'], title: 'Type' },
        total_days: { type: ['integer'], title: 'Total days', readOnly: true, default: 0 },
        absence_payment_per_day: { type: ['integer'], title: 'Enter Payment' },
        absence_total_payment: { type: ['number'], title: 'Total Payment', readOnly: true },
        from: { type: ['string'], format: 'date', title: 'From date' },
        to: { type: ['string'], format: 'date', title: 'To date' },
        attachments: { type: ['string', 'null'], title: 'Attachments' },
        leave_type: { type: ['string'], title: 'Leave Type', default: 'paid' },
        employement_grade: { type: 'number', title: 'Leave Percentage (%)', default: 100 },
        hours: { type: 'number', title: 'Hours', readOnly: true, default: 0 },
        total_hours: { type: 'number', title: 'Total Hours', readOnly: true, default: 0 },
        project_id: { type: ['integer'], title: 'Project', default: 0 },
        data: {
            type: 'object',
            title: '',
            properties: {
                doctor_certification_date: {
                    type: ['string'],
                    format: 'date',
                    title: 'Medical Certificate Date',
                },
                medical_certification_percent: {
                    type: 'integer',
                    title: 'Medical certificate percentage',
                    default: 100
                },
            }
        }
    },
};

export const getSchema = (props) => {
    if (props && props.user_type && props.user_type === "member") {
        schema.properties.absence_payment_per_day.readOnly = true;
    }
    if (props?.config?.integration_details?.product !== 'visma') {
        delete schema.properties.data;
    }
    return schema
};

export default getSchema;
