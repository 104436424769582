import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

//components
import withLocalization from '~/hoc/withLocalization';
import applicationRouter from '~/hoc/applicationRouter'

//elements
import LoadingSpinner from '~/elements/LoadingSpinner';
import GenericForm from '~/components/GenericForm/GenericForm';

//utils
import getSchema from '~/library/schemas/client';

const schema = getSchema(true);

const uiSchema = {
    'ui:field': 'layout',
    'ui:layout': [
        {
            image: { sm: 4 },
            name: { xs: 12, sm: 6, className: 'd-block d-sm-flex justify-content-center align-items-center mt-2 mt-sm-0' },
        },
        {
            max_users: { xs: 12, sm: 4, className: 'mt-2' },
            renew_date: { xs: 12, sm: 4, className: 'mt-2' },
            tax_number: { xs: 12, sm: 4, className: 'mt-2' },
        },
        {
            address: { xs: 12, sm: 4, className: 'mt-2' },
            post_place: { xs: 12, sm: 4, className: 'mt-2' },
            post_number: { xs: 12, sm: 4, className: 'mt-2' },
            gps_data: { xs: 8, md: 4, },
        },
    ],
    image: {
        'ui:widget': 'ImageUpload',
        'ui:imageContext': {
            model: 'Client',
            fileType: 'logo',
            id: 0,
            isDashedBg:true,
        },
    },
    renew_manual: {
        'ui:widget': 'Radio',
    },
    gps_data: {
        'ui:widget': 'GpsCoordinatesWidget',
    },
};

class CompanyProfile extends Component {
    loadData() {
        const { clientStore, userStore } = this.props;
        clientStore.load(userStore.currentUser.client_id, false);
    }

    componentDidMount() {
        this.loadData();
    }

    componentWillMount() {
        this.props.clientStore.setLoading(true);
    }

    onSave(values) {
        const {
            clientStore, t, commonStore
        } = this.props;
        return clientStore.save(values, false).then(result => {
            if (!result.client || !result.client.id) {
                // some error
                commonStore.addNotification(t('Error'), null, 'error');
                return false;
            }
            commonStore.addNotification(t('Saved'), null, 'success');
            this.props.router.navigate('/admin')
            return true;
        });
    }

    prepareSchema(_schema) {
        _schema.properties.tax_number.readOnly = true;
        _schema.properties.max_users.readOnly = true;
        return _schema;
    }

    listUrl(){
        const { user_type } = this.props.userStore.currentUser;
        if(user_type === 'super-admin') return '/admin/clients';
        return '/admin';
    }

    async recalcGps(e) {
        return this.props.clientStore.recalcGpsForClient(e);
    }

    render() {
        const { clientStore, add } = this.props;
        const { loading, currentEntity } = clientStore;
        if (loading) return <LoadingSpinner />;

        return (
            <GenericForm
                entity={currentEntity.client}
                uiSchema={uiSchema}
                schema={this.prepareSchema(schema)}
                translationScope="forms.client"
                onSave={values => this.onSave(values)}
                listUrl={this.listUrl()}
                isAdding={add}
                recomputeFields={['gps_data']}
                onChange={async (data) => this.recalcGps(data)}
            />
        );
    }
}

export default inject('clientStore', 'commonStore', 'userStore')(applicationRouter(withLocalization(observer(CompanyProfile))));
