import React from "react";
import "./styles.css";
//i18n
import { withTranslation } from "react-i18next"

function Feature2(props) {
  return (
    <div className={`fea2ColOuter ${props.color}`}>
      <div className={`fea2Col ${props.customClass}`}>
        <div className={`fea2ColIcon ${props.color}`}>
          <i><img src={props.src} alt={props.title} /></i>
        </div> 
        <div className="fea2ColInfo">
          <h4>{props.title}</h4>
          <p>{props.description}</p>
        </div>
      </div>
    </div>
  );
}
export default withTranslation()(Feature2);