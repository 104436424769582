import './customCard.css';

function RectangularCardIcon(props){

    return(
        <div className={`rectangle-card-icon ${props.backgoundClass ? props.backgoundClass : 'rectangle-card-icon-bg-white'}`}>
            {props.children}
        </div>
    )
}

export default RectangularCardIcon