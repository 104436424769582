import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import withLocalization from '~/hoc/withLocalization';
import CustomRadio from '../../CustomRadio/CustomRadio';

const TaskStatusesMobx = inject('taskStore', 'userStore')(withLocalization(observer(class TaskStatusesMobx extends Component {
    render() {
        const { value, onChange } = this.props;
        const { client } = this.props.commonStore.config;
        const statuses = client ? client.data.task_statuses : false;
        if (!statuses) {
            return null;
        }
        return (
            <div className="task-statuses d-flex justify-content-md-between flex-wrap">
                {statuses.map(({ code, name }) => (
                    <CustomRadio
                        number={`${code}_${name}`}
                        name={code}
                        key={code}
                        onChange={() => onChange(code)}
                        checked={value === code}
                        option={code}
                        label={name}
                    />
                ))}
            </div>
        );
    }
})));
export const TaskStatuses = observer(class TaskStatuses extends Component {
    render() {
        return <TaskStatusesMobx {...this.props} mode="managers" />;
    }
});