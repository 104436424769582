import { CircularProgressbarWithChildren ,buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './customProgressBar.css'

function CustomCircularProgressbar(props) {
    const percentage = props.percentage?props.percentage:0,
    strokeWidth = 25,
    rotation = 0.5 + (1 - percentage / 100) / (props.rotationStart?props.rotationStart:2),
    strokeLinecap = 'butt',
    transition = percentage === 0 ? "none" : "stroke-dashoffset 0.5s ease 0s",
    pathColor = props.pathColor?`#${props.pathColor}`:'#EE2222';    
    return (
        <div className='circular-progress-bar-wrapper' style={{width: '75%'}}>
            <CircularProgressbarWithChildren 
                value={percentage}                
                strokeWidth={strokeWidth}
                styles={buildStyles({
                    strokeLinecap: strokeLinecap,                    
                    rotation: rotation,                    
                    pathColor: pathColor,
                    pathTransition: transition
                })}
            >
                <strong>{percentage}%</strong>
            </CircularProgressbarWithChildren>
        </div>
    );
}

export default CustomCircularProgressbar;