import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Container, Row, Col, Form, Button } from "react-bootstrap";

//components
import withLocalization from '~/hoc/withLocalization';
import applicationRouter from '~/hoc/applicationRouter'
import Header from '../../../newComponents/Header';
import Footer from '../../../newComponents/Footer';
import PageBanner from '../../../newComponents/PageBanner';
import MainHeading from '../../../newComponents/MainHeading';

//elements
import LoadingSpinner from '~/elements/LoadingSpinner';
import RequiredStar from '~/elements/RequiredStar';

//styles
import "./styles.css";

//assets
import BannerImg from "../../../assets/images/timeAndRegisterImg.png";
import SubmitIcon from "../../../assets/images/submitIcon.svg";
import Select from '../../../elements/Select';
import OTPInput from '../../../elements/OtpInput';

export class LoginAsCustomer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clientOptions: [],
            isLoading: false,
            errorText: {
                securePassword: "",
                confirmSecurePassword: ""
            },
            token: "",
            showOtpPage: false,
            showAllowedClient: false,
            client: null,
            email: '',
            otp: '',
            timeRemaining: 0,
            isResendEnabled: false,
            receivedTimer: 120
        };
    }

    componentDidMount() {
        this.loadData();
    }

    componentWillUnmount() {
        if (this.interval)
            clearInterval(this.interval);
    }

    loadData = async () => {
        if (this.props.router?.location?.search?.split('=')[1])
            this.setState({ email: this.props.router?.location?.search?.split('=')[1] })
    }

    submitForm = async (otp) => {
        const { client, email } = this.state;
        const { authStore } = this.props;
        try {
            authStore
                .loginWithOtp({ otp, client: client ? client.value : '', email })
                .then((res) => {
                    this.props.router.navigate('/customer');
                })
                .catch((e) => {
                    console.error(e);
                });
        }
        catch (err) {
            console.error({ err });
        }
    }

    submitEmail = () => {
        const { email } = this.state;
        const { serviceRequestStore } = this.props;
        serviceRequestStore.showAvailableClients(email)
            .then((clients) => {
                const _state = {
                    ...this.state,
                    clientOptions: clients.clients,
                    client: clients.clients && clients.clients.length === 1 ? clients.clients[0] : '',
                }
                this.setState(_state, () => {
                    this.setState({ showAllowedClient: true })
                })
            })
            .catch((e) => {
                console.error(e);
            });
    }

    handleGetOTP = () => {
        const { email, client } = this.state;
        const { authStore } = this.props;
        authStore
            .signInWithEmail({ email, client: client ? client.value : '' })
            .then(res => {
                this.setState({ showOtpPage: true, receivedTimer: res.resendWait }, () => {
                    this.startTimer();
                });
            })
            .catch((e) => {
                console.error(e);
            });
    }

    startTimer = () => {
        const resendTime = this.state.receivedTimer;
        this.interval = setInterval(() => {
            const currentTime = Date.now();
            const timeLeft = resendTime - currentTime;

            if (timeLeft <= 0) {
                clearInterval(this.interval);
                this.setState({ timeRemaining: 0, isResendEnabled: true });
            } else {
                this.setState({ timeRemaining: Math.ceil(timeLeft / 1000) });
            }
        }, 1000);
    }

    handleFormChange = (name, value) => {
        this.setState({ [name]: value })
    }

    resendOtp = () => {
        this.setState({ isResendEnabled: false }, this.handleGetOTP);
    }

    renderForm = () => {
        const { t } = this.props;
        const { email, isLoading, showOtpPage, clientOptions, showAllowedClient, isResendEnabled, timeRemaining, client } = this.state;
        if (isLoading) return <LoadingSpinner />;
        return (
            <>
                <p>{t("For the security of your account, please take a moment to verify your email address")}.</p>
                <Row>
                    <Col xs={12} md={12}>
                        {showOtpPage ?
                            <>
                                <OTPInput
                                    length={4}
                                    type="number"
                                    onSubmit={(otp) => this.submitForm(otp)}
                                    hasError={this.state.hasError}
                                    t={t}
                                >
                                    <Button className="btnCommon btnBig mt-3" variant="primary" type='submit'>
                                        {t("Verify")}&nbsp;{t("OTP")}
                                    </Button>
                                </OTPInput>
                                <div className='d-flex justify-content-center mt-2'>
                                    {isResendEnabled ?
                                        <Button variant="link" onClick={this.resendOtp}>{t('Resend OTP')}</Button> :
                                        <span>
                                            {!timeRemaining ? '' : `${t(`Wait for`)} : ${timeRemaining} ${t('seconds to Resend')} ${t('OTP')}`}</span>
                                    }
                                </div>
                                <p className='text-justify mt-3'>
                                    <li>{t("An email containing a One-Time Password (OTP) has been sent to your registered address. Please use the OTP to complete the verification process")}!</li>
                                </p>
                                <p className='text-justify'>
                                    <li>{t("If you do not find the email in your inbox, kindly check your Spam or Junk folder")}.</li>
                                </p>
                            </>
                            :
                            <>
                                <Form autoComplete='off'>
                                    {!showAllowedClient ?
                                        <>
                                            <Form.Group className="col-md-6 w-100" controlId="formSetEmail">
                                                <Form.Label>{t("Email")}<RequiredStar /></Form.Label>
                                                <Form.Control
                                                    type="email"
                                                    value={email}
                                                    name="email"
                                                    placeholder={t("Please enter Email")}
                                                    onChange={(e) => this.handleFormChange('email', e.target.value)}
                                                    autoComplete='off' required />
                                            </Form.Group>
                                            <div className='d-flex justify-content-center'>
                                                <Button className="btnCommon btnBig mt-2" variant="primary" onClick={() => this.submitEmail()}>
                                                    <i className="d-inline-block pe-2"><img src={SubmitIcon} alt="Submit" /></i> {t("Submit")}
                                                </Button>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <Form.Group className="mb-4" controlId="formSetClient">
                                                <Form.Label>{t("Choose client")}<RequiredStar /></Form.Label>
                                                <Select
                                                    value={client}
                                                    options={clientOptions}
                                                    onChange={(selected) => this.handleFormChange('client', selected)}
                                                    className={'customer-login-form'}
                                                    placeholder={t("Please choose client")}
                                                />
                                            </Form.Group>
                                            <div className='d-flex justify-content-center'>
                                                <Button className="btnCommon btnBig mt-2" variant="primary" onClick={() => this.handleGetOTP()}>
                                                    <i className="d-inline-block pe-2"><img src={SubmitIcon} alt="Submit" /></i> {t("Continue")}
                                                </Button>
                                            </div>
                                        </>}
                                    <p className='text-justify mt-3'><li>{t("Please ensure that the email you enter is registered with your selected client to avoid any issues")}!</li></p>
                                </Form>
                            </>
                        }
                    </Col>
                </Row>
            </>
        )
    }


    render() {
        const { t } = this.props;
        return (
            <div className="landingPageWrapp frameworkApp">
                <Header closeModalCallBack={() => this.setState({ showOtpPage: false })} />
                <article className="passwordUpdateBlock grdntBg lp_wrapper">
                    <Container>
                        <Row className="align-items-center">
                            <Col lg={{ order: 1, span: 8 }} xl={7} xxl={6}>
                                <div className="cntctForm commonForm form-container m-0">
                                    <MainHeading
                                        title={<>{t("Welcome")} {t("To")} <span>{t("Time & Control")}</span></>}
                                        customClass="text-center medium noPara"
                                    />
                                    {this.renderForm()}
                                </div>
                            </Col>
                            <Col lg={{ order: 1, span: 4 }} xl={5} xxl={6} className='banner-container'>
                                <PageBanner
                                    src={BannerImg}
                                    alt="Time Register"
                                />
                            </Col>
                        </Row>
                    </Container>
                </article>
                <Footer />
            </div>
        );
    }
}

export default inject('commonStore', 'authStore', 'serviceRequestStore')(applicationRouter(withLocalization(observer(LoginAsCustomer))));