import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment/min/moment-with-locales'; // 'moment/min/moment-with-locales';

//components
import applicationRouter from '~/hoc/applicationRouter';
import withLocalization from '~/hoc/withLocalization';
import GenericList from '~/components/GenericList/GenericList';
import GenericFilter from '../../../components/GenericList/GenericFilter.js';

//elements
import Button from '../../../components/CustomButton/CustomButton.jsx';

//utils
import { shortDateFormat } from '~/utils/dateFormat';

//assets
import Edit from '../../../assets/img/editing.svg';

class MonitoringList extends Component {
    state = {
        accepting: null,
        acceptanceChanged: false,
        selected: null,
        start: '',
        end: '',
        dateRange: { start: moment(new Date()).startOf('month'), end: moment(new Date()) },
        all_members: '',
        users: '',
        type: '',
    };

    componentDidMount() {
        this.setState({
            start: this.state.dateRange.start.format('YYYY-MM-DD'),
            end: this.state.dateRange.end.format('YYYY-MM-DD'),
        });
    }

    handleTableButtonAction = (id, type) => {
        if (type === 'edit') this.props.router.navigate(`/admin/monitor/${id}`, { state: { monitoring: id } });
        if (type === 'gridEdit')
            this.props.router.navigate(`/admin/monitor/addGrid/${id}`, { state: { monitoring: id } });
        if (type === 'history')
            this.props.router.navigate(`/admin/monitor/viewGrid/${id}`, { state: { monitoring: id } });
    };

    renderTableActionButtons = (id, full) => {
        const { t } = this.props;
        return (
            <div className="actions-center">
                <Button icon_sm fill onClick={() => this.handleTableButtonAction(id, 'edit')}>
                    <img src={Edit} alt="edit" />
                </Button>
                <OverlayTrigger key="GRID" placement="bottom" overlay={<Tooltip id="tooltip-top">{t('Grid')}</Tooltip>}>
                    <Button icon_sm fill onClick={() => this.handleTableButtonAction(id, 'gridEdit')}>
                        <i className="fa fa-table" />
                    </Button>
                </OverlayTrigger>
                <OverlayTrigger
                    key="HISTORY"
                    placement="bottom"
                    overlay={<Tooltip id="tooltip-top">{t('History')}</Tooltip>}
                >
                    <Button icon_sm fill onClick={() => this.handleTableButtonAction(id, 'history')}>
                        <i className="fa fa-history" />
                    </Button>
                </OverlayTrigger>
            </div>
        );
    };

    onSelectionChanged(ids) {
        this.setState({ selected: ids });
    }

    handleClosesDownloadReportModal = () => {
        this.setState({
            all_members: '',
            users: '',
            type: '',
        });
    };

    handleDownloadFormInputChange = (key, value) => {
        this.setState({ [key]: value });
    };

    //handles generic list page change//
    handlePageChange = (page, isEdit) => {
        this.props.handlePageChange(page, isEdit);
    };

    render() {
        const { loading, filters, appliedFilters } = this.props.monitorStore;
        const { t } = this.props;
        const { config } = this.props.commonStore;
        const dateTimeRules =
            config.client && config.client.data && config.client.data.dateTimeRules
                ? config.client.data.dateTimeRules
                : false;

        return (
            <GenericList
                tableHeader={this.props.t('Monitoring')}
                columns={[
                    {
                        Header: 'Date',
                        id: 'created_at',
                        accessor: ({ created_at }) => (
                            <span>{created_at && shortDateFormat(created_at, dateTimeRules)}</span>
                        ),
                        isSortable: true,
                    },
                    {
                        Header: 'Name',
                        id: 'name',
                        accessor: ({ name }) => <span>{name}</span>,
                        isSortable: true,
                    },
                    {
                        Header: 'Name of Person',
                        id: 'user_id',
                        accessor: ({ first_name, last_name }) => (
                            <span title={first_name + ' ' + last_name}>
                                {first_name} {last_name}
                            </span>
                        ),
                        isSortable: true,
                    },
                    {
                        Header: 'Project',
                        id: 'project_name',
                        accessor: ({ project_name }) => <span>{project_name}</span>,
                    },
                    // {
                    //     Header: 'Created On',
                    //     id: 'created_at',
                    //     accessor: ({ created_at }) => (
                    //         <span>{created_at && shortDateFormat(created_at, dateTimeRules)}</span>
                    //     ),
                    // isSortable: true,
                    // },
                    {
                        Header: 'Updated On',
                        id: 'updated_at',
                        accessor: ({ updated_at }) => updated_at && shortDateFormat(updated_at, dateTimeRules),
                        isSortable: true,
                    },
                    {
                        Header: 'Actions',
                        id: 'actions',
                        accessor: (full) => this.renderTableActionButtons(full.id, full),
                        maxwidth: 70
                    },
                ]}
                onSelectionChanged={(selection) => this.onSelectionChanged(selection)}
                lastListLoadTime={this.props.monitorStore.lastListLoadTime}
                header={
                    <GenericFilter
                        filters={filters}
                        setFilter={(name, value) => this.props.monitorStore.setFilter(name, value)}
                        onFilter={() => this.props.monitorStore.onFilter()}
                        loading={loading}
                        hasProjectFilter={true}
                        hasDateFilters={true}
                    />
                }
                forceReload={this.props.monitorStore.deleteSuccess || this.state.acceptanceChanged}
                filters={appliedFilters}
                requestData={(params) => this.props.monitorStore.loadList(params)}
                handlePageChange={(page, isEdit) => this.handlePageChange(page, isEdit)}
                page={this.props.page} //new page props to maintain page from parent//
                isPageAddedOrEdited={this.props.isPageAddedOrEdited} //to load same page after edit//
            >
                <Button fill wd onClick={() => this.props.onAdd()}>
                    <i className="fa fa-plus-circle fa-xl margin-right-10" />
                    {t('Add new')}
                </Button>
            </GenericList>
        );
    }
}
export default inject(
    'userStore',
    'commonStore',
    'monitorStore'
)(applicationRouter(withLocalization(observer(MonitoringList))));
