import React, { Component } from 'react';
import applicationRouter from '~/hoc/applicationRouter'
import { inject, observer } from 'mobx-react';

import '../../../../styles/css/styles.css';
import checklist_sign from '../../../../assets/img/sign-logo.svg'
import clock_checklist from '../../../../assets/img/clock-checklist.svg'
import photo_checklist from '../../../../assets/img/photo-checklist.svg'
// import checklist_arrow from '../../../../assets/img/checklist-arrow.svg'


import withRemoveDialog from '~/hoc/withRemoveDialog';
import withLocalization from '~/hoc/withLocalization';
// Components
import CustomCheckbox from '../../../../components/CustomCheckbox/CustomCheckbox';

class ChecklistTemplateTreeView extends Component {
    state = {
        data: {},
        elements: []
    };

    componentDidMount() {
        this.setState({ data: this.props.data, paddingLeft: this.props.paddingLeft });
        if (this.props.data.elements && this.props.data.elements.length > 0) {
            this.setState({ elements: this.props.data.elements });
        }
    }

    onCheckboxChange = async (dataTemp, name, value) => {
        if (name === "all_required") {
            this.setState({
                data: {
                    ...dataTemp,
                    "siginig_required": value,
                    "due_date_required": value,
                    "photo_required": value,
                    "all_required": value
                }
            });
            dataTemp["siginig_required"] = value
            dataTemp["due_date_required"] = value
            dataTemp["photo_required"] = value
            dataTemp["all_required"] = value
        } else {
            this.setState({
                data: {
                    ...dataTemp,
                    [name]: value,
                }
            });
            dataTemp[name] = value
        }
        this.props.checklistStore.updateChecklistElement(dataTemp).then(() => {
        });;
    };

    onParentCheckboxChange = async (dataTemp, name, value) => {
        let updatedData = await this.recursiveSearch(dataTemp, name, value);
        this.setState({
            data: {
                ...dataTemp,
                [name]: value,
            }
        });
        dataTemp[name] = value
        this.props.checklistStore.updateChecklistElement(updatedData).then(() => {
        });
    };

    recursiveSearch = async (obj, name, value) => {

        let finalData = obj;
        finalData.elements = [];
        obj.elements.forEach(item => {
            if (item.elements.length > 0) {
                item[name] = value;
            } else {
                this.recursiveSearch(item, name, value);
            }
            finalData.elements.push(item);
        });

        return finalData;
    };

    render() {
        return (
            <div className='mt-3'>
                {this.props.sub && this.props.sub === true ? (
                    <div className="row mb-1" style={{ color: 'black' }}>
                        <div className="col-md-9 form-group ">
                            <div className='d-flex'>
                                <i className="fa fa-bars mt-1" aria-hidden="true"></i>
                                <div className='font-set-2 ml-10'>{this.state.data.name}</div>
                            </div>
                            <div className='col-md-11 ml-30'>{this.state.data.comment}</div>
                        </div>
                    </div>
                ) : (
                    this.state.data.elements && this.state.data.elements.length > 0 ? (
                        <div className="mb-2 row" style={{ paddingLeft: this.state.paddingLeft }}>
                            <div className='col-md-8'>
                                <div className="form-group reportsTitle">
                                    {this.state.data.name}
                                </div>
                                <div className='mt-2'>
                                    {this.state.data.comment}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className='row'>
                                    <div className="form-check col-md-3 flex-box">
                                        <CustomCheckbox
                                            onChange={v => this.onCheckboxChange(this.state.data, 'all_required', v)}
                                            isChecked={!!this.state.data.all_required}
                                            style={{ fontWeight: 'bold' }}
                                            className="font-weight-bold"
                                            inline
                                            disabled={this.props.isDisable}
                                            labelClassName={'checkList-checkbox-bottom'}
                                        />
                                    </div>
                                    <div className="form-check col-md-3 flex-box">
                                        <CustomCheckbox
                                            onChange={v => this.onCheckboxChange(this.state.data, 'siginig_required', v)}
                                            isChecked={!!this.state.data.siginig_required}
                                            style={{ fontWeight: 'bold' }}
                                            className="font-weight-bold"
                                            inline
                                            disabled={this.props.isDisable}
                                            labelClassName={'checkList-checkbox-bottom'}
                                        />
                                        <img src={checklist_sign} alt='sign'/>
                                    </div>
                                    <div className="form-check col-md-3 flex-box">
                                        <CustomCheckbox
                                            onChange={v => this.onCheckboxChange(this.state.data, 'due_date_required', v)}
                                            isChecked={!!this.state.data.due_date_required}
                                            style={{ fontWeight: 'bold', display: 'inline!important' }}
                                            className="font-weight-bold"
                                            inline
                                            labelClassName={'checkList-checkbox-bottom'}
                                        />
                                        <img src={clock_checklist} alt='checklist_clock'/>
                                    </div>
                                    <div className="form-check col-md-3 flex-box">
                                        <CustomCheckbox
                                            onChange={v => this.onCheckboxChange(this.state.data, 'photo_required', v)}
                                            isChecked={!!this.state.data.photo_required}
                                            style={{ fontWeight: 'bold' }}
                                            className="font-weight-bold"
                                            inline
                                            labelClassName={'checkList-checkbox-bottom'}
                                        />
                                        <img src={photo_checklist} alt='checklist_photo'/>
                                    </div>
                                </div>
                            </div>
                        </div>

                    ) : (
                        <div className="row" style={{ paddingLeft: this.state.paddingLeft }}>
                            <div className="col-md-8 form-group">
                                <i className="fa fa-lg fa-tasks" aria-hidden="true"></i>
                                <div className="elementtreechecklist reportsTitle">{this.state.data.name}</div>
                                <div className='ml-30'>{this.state.data.comment}</div>
                            </div>
                            <div className="col-md-4">
                                <div className='row'>
                                    <div className="form-check col-md-3 flex-box">
                                        <CustomCheckbox
                                            onChange={v => this.onCheckboxChange(this.state.data, 'all_required', v)}
                                            isChecked={!!this.state.data.all_required}
                                            style={{ fontWeight: 'bold' }}
                                            className="font-weight-bold"
                                            inline
                                            disabled={this.props.isDisable}
                                            labelClassName={'checkList-checkbox-bottom'}
                                        />
                                    </div>
                                    <div className="form-check col-md-3 flex-box">
                                        <CustomCheckbox
                                            onChange={v => this.onCheckboxChange(this.state.data, 'siginig_required', v)}
                                            isChecked={!!this.state.data.siginig_required}
                                            style={{ fontWeight: 'bold' }}
                                            className="font-weight-bold"
                                            inline
                                            labelClassName={'checkList-checkbox-bottom'}
                                        />
                                        <i className="fa fa-signature" aria-hidden="true"></i>
                                    </div>
                                    <div className="form-check col-md-3 flex-box">
                                        <CustomCheckbox
                                            onChange={v => this.onCheckboxChange(this.state.data, 'due_date_required', v)}
                                            isChecked={!!this.state.data.due_date_required}
                                            style={{ fontWeight: 'bold', display: 'inline!important' }}
                                            className="font-weight-bold"
                                            inline
                                            labelClassName={'checkList-checkbox-bottom'}
                                        />
                                        <img src={clock_checklist} alt='checklist-clock'/>
                                    </div>
                                    <div className="form-check col-md-3 flex-box">
                                        <CustomCheckbox
                                            onChange={v => this.onCheckboxChange(this.state.data, 'photo_required', v)}
                                            isChecked={!!this.state.data.photo_required}
                                            style={{ fontWeight: 'bold' }}
                                            className="font-weight-bold"
                                            inline
                                            labelClassName={'checkList-checkbox-bottom'}
                                        />
                                        <img src={photo_checklist} alt='checklist_photo'/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                )
                }

                <div>
                    {this.state.elements.map((item, index) => (
                        item.elements && item.elements.length > 0 ? (
                            <ChecklistTemplateTreeView
                                data={item}
                                headingcolor="#F6F6F6"
                                key={item.id}
                                sub={true}
                                paddingLeft={this.state.paddingLeft + 30}
                            />
                        ) : (
                            <div className="" style={{ color: 'black', paddingLeft: this.state.paddingLeft }}>
                                <div className="col-md-9 form-group">
                                    <i className="fa fa-lg fa-tasks" aria-hidden="true"></i>
                                    <div className="elementtreechecklist">{item.name}</div>
                                    <div className='ml-30'>{item.comment}</div>
                                </div>
                            </div>
                        )
                    ))}
                </div>
            </div>
        );
    }
}

export default inject('checklistStore', 'authStore', 'userStore', 'commonStore')(
    applicationRouter(withLocalization(withRemoveDialog(observer(ChecklistTemplateTreeView))))
);
