import React, { Component, Fragment } from 'react';
import { PropTypes } from 'prop-types';
import Icon from 'antd/lib/icon';

// Components
import UserProfileLogo from '../UserProfileLogo';

// utils
import { totalTimeFormat, currencyDecimalSize } from '../../utils/dateFormat';
import { getMinutes } from '../../utils/timeCalc'

class ResourceView extends Component {
    constructor(props) {
        super(props);
    }

    static propTypes = {
        schedulerData: PropTypes.object.isRequired,
        contentScrollbarHeight: PropTypes.number.isRequired,
        slotClickedFunc: PropTypes.func,
        slotItemTemplateResolver: PropTypes.func,
        toggleExpandFunc: PropTypes.func,
    };

    cropText = (str = '', size = 16) => {
        str = `${str}`;
        if (str.length > size) {
            return `${str.slice(0, size)}...`;
        }
        if (str === 'Pending')
            return this.props.t(str)
        return str;
    };

    textFirstLetr = (str) => {
        var matches = str.match(/\b(\w)/g);
        var first = matches[0];
        var finalword = first;
        if (matches[1]) {
            finalword = finalword + matches[1];
        }
        finalword = finalword.toUpperCase()
        return finalword;
    }

    render() {
        const {
            schedulerData,
            contentScrollbarHeight,
            slotClickedFunc,
            slotItemTemplateResolver,
            toggleExpandFunc,
            allowResourceSelection,
            filteredRenderData,
            config,
            isMember,
            dateTimeRules,
            t
        } = this.props;
        const resourceTableWidth = schedulerData.getResourceTableWidth();
        const slotTxtWidth = resourceTableWidth - 55;
        const width = schedulerData.getResourceTableWidth() - 2;
        const paddingBottom = contentScrollbarHeight;
        const currency = config.client.data?.dateTimeRules?.currency;

        const resourceList = filteredRenderData.map((item) => {
            const indents = [];
            for (let i = 0; i < item.indent; i++) {
                indents.push(<span key={`es${i}`} className="expander-space" />);
            }
            let indent = <span key={`es${item.indent}`} className="expander-space" />;
            if (item.hasChildren) {
                indent = item.expanded ? (
                    <Icon
                        type="minus-square"
                        key={`es${item.indent}`}
                        className=""
                        onClick={() => {
                            if (toggleExpandFunc) toggleExpandFunc(schedulerData, item.slotId);
                        }}
                    />
                ) : (
                    <Icon
                        type="plus-square"
                        key={`es${item.indent}`}
                        className=""
                        onClick={() => {
                            if (toggleExpandFunc) toggleExpandFunc(schedulerData, item.slotId);
                        }}
                    />
                );
            }
            indents.push(indent);

            const a =
                slotClickedFunc != undefined ? (
                    <span className="slot-cell">
                        <a
                            className="slot-text"
                            title={item.slotName}
                            alt={item.slotName}
                            onClick={() => {
                                slotClickedFunc(schedulerData, item);
                            }}
                        >
                            {this.cropText(item.slotName)}
                        </a>
                    </span>
                ) : (
                    <div className="slot-cell" >
                        {!allowResourceSelection && indents}
                        {item.slotName === 'Pending' ? (
                            <span className='slot-text-pending'>{this.cropText(item.slotName)}</span>
                        ) : (
                            <span className="d-flex align-items-center">
                                {!this.props.currentView &&
                                    <span className='p-1 d-flex'>
                                        <UserProfileLogo
                                            image_id={item.slotImage ? item.slotImage : false}
                                            width="34px"
                                            height="34px"
                                            border={item.slotLoggedIn ? '3px solid #3cbd58' : ''}
                                            className={item.slotLoggedIn ? 'is_user_logged_in' : ''}
                                        />
                                        {item.slotEmergencyFromDate && item.slotEmergencyToDate &&
                                            <div className='position-absolute ms-3 text-bg-danger border-radius-10 font-size-10 mt-20 custom-padding-schedule-indicator cursor-pointer' onClick={() => this.props.handleOnCallClick(item)}>
                                                {t('On Call')}
                                            </div>
                                        }
                                    </span>
                                }
                                <span>
                                    <span style={{ width: `${slotTxtWidth}px` }} className='slot-text'>{this.cropText(item.slotName)}</span>
                                    {!isMember && item?.slotTotalHours > 0 ?
                                        <div className='slot-text-time slot-text-big-screen' title={totalTimeFormat(item?.slotTotalHours, dateTimeRules)}>
                                            {totalTimeFormat(item?.slotTotalHours, dateTimeRules)}
                                            {item.slotPrice ? ' - ' : ''}
                                            {item.slotPrice && currency === '$' ? currency : ''}
                                            {item.slotPrice ? Number(((item?.slotTotalHours / 60) * item.slotPrice)).toFixed(currencyDecimalSize(dateTimeRules)) : ''}
                                            {item.slotPrice && currency !== '$' ? currency : ''}
                                        </div> : ''
                                    }
                                </span>
                            </span>
                        )}

                    </div>
                );
            const resourceRoleView =
                slotClickedFunc != undefined ? (
                    <span className="slot-cell">
                        <a
                            className="slot-text"
                            title={item.slotName}
                            alt={item.slotName}
                            onClick={() => {
                                slotClickedFunc(schedulerData, item);
                            }}
                        >
                            {this.cropText(item.slotName)}
                        </a>
                    </span>
                ) : (
                    <div className="slot-cell h-100" >
                        {!allowResourceSelection && indents}
                        {item.slotName === 'Pending' ? (
                            <span className='slot-text-pending d-flex align-items-center justify-content-start h-100 ml-10'>{this.cropText(item.slotName)}</span>
                        ) : (
                            <span>
                                <span className={`${item?.slotTotalDetails?.total_normal_hours ? 'align-items-center' : 'align-items-end'} h-50 d-flex ml-10`}>
                                    <div className='slot-text-count' title={item.slotIds && item.slotIds.length ? `Employees: ${item.slotIds.length}` : item.slotType == -1 ? t("All") : t("No employee found")}>
                                        {item.slotIds && item.slotIds.length ? item.slotIds.length : item.slotType == -1 ? t("NA") : "0"}
                                    </div>
                                    <div style={{ width: `${slotTxtWidth}px` }} className='text-start ml-10 slot-text text-capitalize'>{this.cropText(item.slotName)}</div>
                                </span>
                                <span className='h-50 text-start'>
                                    {this.props.currentView ?
                                        <div className='slot-text-time slot-text-big-screen flex-column' title={totalTimeFormat(item?.slotTotalDetails?.total_normal_hours, dateTimeRules)}>
                                            <div className='ml-10'>
                                                {item?.slotTotalDetails?.total_normal_hours ? totalTimeFormat(item?.slotTotalDetails?.total_normal_hours * 60, dateTimeRules) + " " + 'hr' : ''}
                                                {item?.slotTotalDetails?.total_cost ? ' - ' : ''}
                                                {item?.slotTotalDetails?.total_cost && currency === '$' ? currency : ''}
                                                {item?.slotTotalDetails?.total_cost ? Number(item?.slotTotalDetails?.total_cost).toFixed(currencyDecimalSize(dateTimeRules)) : ''}
                                                {item?.slotTotalDetails?.total_cost && currency !== '$' ? currency : ''}
                                            </div>
                                            {item?.slotTotalDetails?.total_overtime_hours ?
                                                <div className='text-danger ml-10'>
                                                    {item?.slotTotalDetails?.total_overtime_hours ? totalTimeFormat(item?.slotTotalDetails?.total_overtime_hours * 60, dateTimeRules) : ''} hr
                                                    {item?.slotTotalDetails?.total_ovetime_cost ? ' - ' : ''}
                                                    {item?.slotTotalDetails?.total_ovetime_cost && currency === '$' ? currency : ''}
                                                    {item?.slotTotalDetails?.total_ovetime_cost ? Number(item?.slotTotalDetails?.total_ovetime_cost).toFixed(currencyDecimalSize(dateTimeRules)) : ''}
                                                    {item?.slotTotalDetails?.total_ovetime_cost && currency !== '$' ? currency : ''}
                                                </div>
                                                : ''
                                            }
                                        </div> : ''
                                    }
                                </span>
                            </span>
                        )}

                    </div>
                );
            let slotItem = (
                <div title={item.slotName} className={this.props.currentView ? `overflow-text header2-text text-left h-100` : `overflow-text header2-text text-left`}>
                    {this.props.currentView ? resourceRoleView : a}
                </div>
            );
            if (slotItemTemplateResolver) {
                const temp = slotItemTemplateResolver(
                    schedulerData,
                    item,
                    slotClickedFunc,
                    width,
                    'overflow-text header2-text'
                );
                if (temp) slotItem = temp;
            }


            // strange bug :(
            let tdStyle = { height: item.slotId !== 0 ? item.rowHeight : item.rowHeight };
            if (item.groupOnly) {
                tdStyle = {
                    ...tdStyle,
                    backgroundColor: schedulerData.config.groupOnlySlotColor,
                };
            }

            let classname = `resource-row ${item.slotId === 0 || item.slotId === 'Pending' ? 'pending-resource' : ''}`;
            if (item.slotId === schedulerData.config.currentUserId) {
                classname += ' current-resource';
            }
            return (
                <Fragment key={item.slotId}>
                    <tr className={classname}>
                        <td data-resource-id={item.slotId} style={tdStyle}>
                            {slotItem}
                        </td>
                    </tr>
                </Fragment>
            );
        })

        return (
            <div style={{ paddingBottom }}>
                <table className="resource-table">
                    <tbody>{resourceList}</tbody>
                </table>
            </div>
        );
    }
}

export default ResourceView;
