import React from 'react';
import PropTypes from 'prop-types';
import { getYear, getMonth, getMonthStart } from '@wojtekmaj/date-utils';

import Flex from '../Flex';

import { getDayOfWeek } from '../shared/dates';
import { formatWeekday, formatShortWeekday as defaultFormatShortWeekday } from '../shared/dateFormatter';
import { isCalendarType } from '../shared/propTypes';

const className = 'react-calendar__month-view__weekdays';

const formatReallyShort = (n) => {
    if (n.length > 3) return n.substring(0, 3);
    return n;
};

export default function Weekdays(props) {
    const { calendarType, formatShortWeekday = defaultFormatShortWeekday, locale, onMouseLeave } = props;

    const anyDate = new Date();
    const beginOfMonth = getMonthStart(anyDate);
    const year = getYear(beginOfMonth);
    const monthIndex = getMonth(beginOfMonth);

    const weekdays = [];

    for (let weekday = 1; weekday <= 7; weekday += 1) {
        const weekdayDate = new Date(year, monthIndex, weekday - getDayOfWeek(beginOfMonth, calendarType));

        const abbr = formatWeekday(locale, weekdayDate);

        weekdays.push(
            <div key={weekday} className={`${className}__weekday`}>
                <abbr aria-label={abbr} title={abbr} style={{borderBottom:'none !important'}}>
                    {formatReallyShort(props.t(formatShortWeekday(locale, weekdayDate).replace('.', '')))}
                </abbr>
            </div>
        );
    }

    return (
        <Flex className={className} count={7} onFocus={onMouseLeave} onMouseOver={onMouseLeave} style={{marginBottom:'12px'}}>
            {weekdays}
        </Flex>
    );
}

Weekdays.propTypes = {
    calendarType: isCalendarType.isRequired,
    formatShortWeekday: PropTypes.func,
    locale: PropTypes.string,
    onMouseLeave: PropTypes.func,
};
