import React, { Component } from 'react';
import Switch from 'react-switch';

class SwitchWidget extends Component {
    render() {
        const { value, onChange , disabled, className} = this.props;
        return (
            <div className={className ? className : null}>
                <Switch checked={value}  height={20} width={40} uncheckedIcon={false} checkedIcon={false} onColor={'#2550AC'} onChange={checked => onChange(checked)} disabled={disabled}/>                
            </div>
        );
    }
}

export default SwitchWidget;
